import { Box, Switch, Typography } from '@mui/material';
import { UpdateBidsConfig } from '../../types/update-bids';
import { CurrencyInput } from '../inputs/currency-input';

interface MinMaxInputProps {
  updateConfig: UpdateBidsConfig;
  setUpdateConfig: React.Dispatch<React.SetStateAction<UpdateBidsConfig>>;
}

const MinMaxInput = ({ updateConfig, setUpdateConfig }: MinMaxInputProps) => {
  const operator = updateConfig.bidUpdateOperator;
  if (operator === 'decrease') {
    return (
      <Box display="flex" alignItems={'center'} justifyContent={'space-between'} maxWidth={'220px'} mt={3}>
        <Switch
          checked={updateConfig.shouldApplyMinBid}
          color="primary"
          size="small"
          onChange={(event) => {
            setUpdateConfig((prevState) => ({
              ...prevState,
              shouldApplyMinBid: event.target.checked
            }));
          }}
        />
        <Typography>Min Bid</Typography>
        <CurrencyInput
          name="minBid"
          onChange={(event) => {
            setUpdateConfig((prevState) => ({
              ...prevState,
              minBid: Number(event.target.value)
            }));
          }}
          value={updateConfig.minBid}
          disabled={!updateConfig.shouldApplyMinBid}
          sx={{ ml: 1, maxWidth: '100px' }}
          error={updateConfig.minBid < 0.3}
          size="small"
        />
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems={'center'} justifyContent={'space-between'} maxWidth={'220px'} mt={3}>
      <Switch
        checked={updateConfig.shouldApplyMaxBid}
        color="primary"
        size="small"
        onChange={(event) => {
          setUpdateConfig((prevState) => ({
            ...prevState,
            shouldApplyMaxBid: event.target.checked
          }));
        }}
      />
      <Typography>Max Bid</Typography>
      <CurrencyInput
        name="maxBid"
        value={updateConfig.maxBid}
        error={updateConfig.maxBid < 0.3}
        onChange={(event) => {
          setUpdateConfig((prevState) => ({
            ...prevState,
            maxBid: Number(event.target.value)
          }));
        }}
        disabled={!updateConfig.shouldApplyMaxBid}
        sx={{ ml: 1, maxWidth: '100px' }}
        size="small"
      />
    </Box>
  );
};

export default MinMaxInput;
