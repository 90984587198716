import { Chip, Link, Typography } from '@mui/material';
import { caseInsensitiveEquals } from '../../../../../../shared/utilities/string-utilities';
import { AmazonExpressionTypes } from '../../../../../types/targeting-refinements';

interface AutoTargetCellProps {
  expression: any;
}

const getExpressionLabelValue = (expression: any) => {
  const expressionType = expression[0].type;

  if (caseInsensitiveEquals(expressionType, AmazonExpressionTypes.asinSubstituteRelated)) {
    return 'Substitutes';
  }

  if (caseInsensitiveEquals(expressionType, AmazonExpressionTypes.asinAccessoryRelated)) {
    return 'Complements';
  }

  if (caseInsensitiveEquals(expressionType, AmazonExpressionTypes.queryHighRelMatches)) {
    return 'Close match';
  }

  if (caseInsensitiveEquals(expressionType, AmazonExpressionTypes.queryBroadRelMatches)) {
    return 'Loose match';
  }
};

const AutoTargetCell = ({ expression }: AutoTargetCellProps) => {
  return <Typography fontSize={14}>{getExpressionLabelValue(expression)}</Typography>;
};

export default AutoTargetCell;
