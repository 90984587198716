import * as React from 'react';
import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { KEYWORD_STATUS_OPTIONS } from '../../../../../shared/types/walmart-sams-club/keyword';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  },
  '&.Enabled': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.Paused': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  }
}));

interface StatusProps {
  status: string;
}

const Status = React.memo((props: StatusProps) => {
  const { status } = props;

  const statusObject = KEYWORD_STATUS_OPTIONS.find((option) => option.value === status.toLowerCase());

  if (!statusObject) {
    return null;
  }

  const Icon = statusObject.icon;

  return <StyledChip className={status} icon={<Icon className="icon" />} size="small" label={status} variant="outlined" />;
});

export function renderStatus(params: GridRenderCellParams) {
  if (params.value == null) {
    return '';
  }

  return <Status status={params.value} />;
}
