import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGridPremium,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowEditStopReasons,
  GridRowParams
} from '@mui/x-data-grid-premium';
import {
  dateColumnType,
  GridEditDateCell
} from '../../../shared/components/grid-components/renderer/campaign/date-cell-components';
import { useCallback, useEffect, useState } from 'react';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import dayjs, { Dayjs } from 'dayjs';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  rowModesModel: GridRowModesModel;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, rowModesModel, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [...oldRows, { id, startDate: '', endDate: '', dailyBudget: null, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'startDate' }
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
        disabled={Object.values(rowModesModel).some((m) => m?.mode === GridRowModes.Edit)}
      >
        Add Override
      </Button>
    </GridToolbarContainer>
  );
}

interface CampaignDailyBudgetOverrideGridProps {
  campaignId: number;
  currentDailyBudget: number;
}

export default function CampaignDailyBudgetOverrideGrid({
  campaignId,
  currentDailyBudget
}: CampaignDailyBudgetOverrideGridProps) {
  const {
    getCampaignDailyBudgetOverrides,
    createCampaignDailyBudgetOverride,
    updateCampaignDailyBudgetOverride,
    deleteCampaignDailyBudgetOverride
  } = useAdsApi();

  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const tomorrow = dayjs().add(1, 'day');
  const [minimumEndDate, setMinimumEndDate] = useState<Dayjs>();

  const initialize = async () => {
    const response = await getCampaignDailyBudgetOverrides(campaignId);
    setRows(response.body);
  };

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (params: GridRowParams<any>) => () => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const response = await deleteCampaignDailyBudgetOverride(id.toString());

    if (response.success) {
      setRows((oldRows) => oldRows.filter((row) => row.id !== id));
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);

    if (editedRow!.isNew) {
      setRows((oldRows) => oldRows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const isCreate = newRow?.isNew;
    const apiCall = isCreate ? createCampaignDailyBudgetOverride : updateCampaignDailyBudgetOverride;

    const response = await apiCall({
      id: newRow.id,
      campaignId: campaignId,
      startDate: newRow.startDate instanceof Date ? newRow.startDate.toISOString().split('T')[0] : newRow.startDate,
      endDate: newRow.endDate instanceof Date ? newRow.endDate.toISOString().split('T')[0] : newRow.endDate,
      dailyBudget: newRow.dailyBudget
    });

    if (response.success) {
      const updatedRow = { ...newRow, isNew: false };
      setRows((oldRows) => oldRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'startDate',
      headerName: 'Start Date',
      ...dateColumnType,
      renderEditCell: (params) => {
        return <GridEditDateCell minDate={tomorrow} setMinimumEndDate={setMinimumEndDate} {...params} />;
      },
      width: 140,
      type: 'string',
      filterable: false,
      editable: true
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      ...dateColumnType,
      renderEditCell: (params) => {
        return <GridEditDateCell minDate={minimumEndDate} {...params} />;
      },
      width: 140,
      type: 'string',
      filterable: false,
      editable: true
    },
    {
      field: 'dailyBudget',
      headerName: 'Daily Budget',
      type: 'number',
      width: 140,
      align: 'left',
      headerAlign: 'left',
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            disabled={Object.values(rowModesModel).some((m) => m?.mode === GridRowModes.Edit)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
            disabled={Object.values(rowModesModel).some((m) => m?.mode === GridRowModes.Edit)}
          />
        ];
      }
    }
  ];

  return (
    <div onClick={(event) => (event.target = document.body)}>
      <Box
        sx={{
          height: 430,
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}
      >
        <DataGridPremium
          disableRowGrouping
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar
          }}
          slotProps={{
            toolbar: { setRows, rows, rowModesModel, setRowModesModel }
          }}
        />
      </Box>
    </div>
  );
}
