import { Box, styled } from '@mui/material';
import {
  DataGridPremium,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRowClassNameParams,
  GridRowIdGetter,
  GridRowModesModel
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { MutableRefObject, useCallback, useState } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { listOfMonths } from '../../../shared/types/budget';
import { BudgetGroupGridToolbar } from './budget-group-grid-toolbar';
import { Month } from '../../../shared/utilities/date-utilities';

// This is a hack to highlight the background of a cell that doesnt' have the cellContent Html element in it
function hexToRGBA(hex: any, alpha = 1) {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const StyledBudgetGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .total-row': {
    borderTop: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral?.[800] : theme.palette.neutral?.[100],
    fontWeight: 500
  },
  '& .total-row .over-budget': {
    backgroundColor: 'red'
  },
  '& .total-cell': {
    borderLeft: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral?.[800] : theme.palette.neutral?.[100],
    fontWeight: 500
  },
  '& .MuiDataGrid-row': {
    '& .user-editing-cell.numeric-edit-cell:not(.MuiDataGrid-cell--editing, .total-cell)': {
      position: 'relative', // This is required for the positioning of the pseudo-element
      '&::before': {
        // This creates a pseudo-element as a fake child
        content: '""', // Necessary for pseudo-elements
        position: 'absolute',
        top: '10%', // Adjust based on your needs
        left: '10%', // Adjust based on your needs
        width: '80%', // Adjust to control the size of the highlighted area
        height: '80%', // Adjust to control the size of the highlighted area
        backgroundColor:
          theme.palette.mode === 'light'
            ? hexToRGBA(theme.palette.neutral?.[200], 0.3) // Assuming .5 opacity for example
            : hexToRGBA(theme.palette.neutral?.[600], 0.3),
        borderRadius: '10%' // Adjust to control the shape of the highlight
        // You can add more styles here to customize the appearance of your "fake child"
      }
    }
  },
  '& .highlight-cell': {
    animation: 'glow ease-in-out 1.5s infinite',
    backgroundColor: `${theme.palette.primary.dark}`
  },
  [`@keyframes glow`]: {
    '0%': {
      backgroundColor: `${theme.palette.primary.dark}99`
    },
    '100%': {
      backgroundColor: `${theme.palette.primary.dark}0D`
    }
  }
}));

export interface AmazonBudgetGridProps {
  apiRef: MutableRefObject<GridApiPremium>;
  calendarYear: number;
  processRowUpdate?: (newRow: any, oldRow: any) => any;
  rows: any[];
  columns: GridColDef[];
  initialState: GridInitialStatePremium | undefined;
  getRowId?: GridRowIdGetter<any> | undefined;
  loading?: boolean;
  className?: string;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  getCellClassName?: (params: GridCellParams) => string;
  saveGridConfig?: (update: any) => void;
  canEdit: boolean;
  checkboxSelection: boolean;
  bottomMargin?: number;
  bulkActions?: React.ReactNode;
  experimentalFeatures?: any;
  unstable_cellSelection?: boolean;
  unstable_splitClipboardPastedText?: (text: string) => string[][] | null;
}

export default function AmazonBudgetGrid({
  apiRef,
  calendarYear,
  rows,
  columns,
  initialState,
  bottomMargin = 0,
  processRowUpdate,
  getRowId,
  loading,
  className,
  getRowClassName,
  checkboxSelection,
  getCellClassName,
  bulkActions,
  saveGridConfig,
  canEdit,
  experimentalFeatures,
  unstable_cellSelection,
  unstable_splitClipboardPastedText
}: AmazonBudgetGridProps) {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const editableMonths: Month[] = listOfMonths.filter((_, index) => {
    return calendarYear > currentYear || (calendarYear === currentYear && index >= currentMonth);
  });

  //adapted from example given by mui-x team in this github issue: https://github.com/mui/mui-x/issues/2186
  const handleCellClick = useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: params.isEditable ? GridCellModes.Edit : GridCellModes.View }
        }
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleColumnResize = useCallback((newModel: any) => {
    const currentState = apiRef.current.exportState();

    saveGridConfig?.({
      ...currentState,
      preferencePanel: {
        open: false
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ height: `calc(100% - ${bottomMargin}px)` }} className={className}>
      <StyledBudgetGrid
        disableColumnMenu
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onColumnResize={handleColumnResize}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        disableRowSelectionOnClick
        onCellDoubleClick={handleCellClick}
        loading={loading}
        checkboxSelection={checkboxSelection}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: BudgetGroupGridToolbar
        }}
        slotProps={{
          toolbar: {
            bulkActions: bulkActions
          }
        }}
        getRowId={getRowId}
        initialState={initialState}
        getRowClassName={getRowClassName}
        getCellClassName={(params) => {
          if (getCellClassName) {
            getCellClassName(params);
          }

          if (canEdit && editableMonths.includes(params.field as Month) && params.row?.budgetItemName !== 'TOTAL') {
            return 'user-editing-cell';
          }

          return '';
        }}
        hideFooter={true}
        isCellEditable={(params) => {
          return canEdit && editableMonths.includes(params.field as Month) && params.row?.budgetItemName !== 'TOTAL';
        }}
        pinnedColumns={{ right: ['TOTAL'] }}
        experimentalFeatures={experimentalFeatures}
        unstable_cellSelection={unstable_cellSelection}
        unstable_splitClipboardPastedText={unstable_splitClipboardPastedText}
        clipboardCopyCellDelimiter={','}
      />
    </Box>
  );
}
