import { Box, Skeleton, Typography, Divider, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { DaypartingSchedule, DAYS, listOfDays, Point, AmazonDaypartingStates } from '../../../shared/types/day-parting';
export interface HourlySchedulesContainerProps {
  isLoading: boolean;
  daySchedules: {
    [key: number]: {
      [key: number]: AmazonDaypartingStates;
    };
  };
  setDaySchedules: Dispatch<SetStateAction<DaypartingSchedule>>;
  stats: any;
  shouldShowOutOfBudgetHour?: boolean;
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
}

const HourLabels = () => {
  return (
    <Box display={'flex'} ml={'43px'}>
      {Array.from(Array(24).keys()).map((hour) => (
        <Box key={hour}>
          <Box height="28px" width="28px" key={`box-${hour}`}>
            <Typography sx={{ fontSize: '12px' }} key={`hour-${hour}`}>
              {String(hour).padStart(2, '0')}
            </Typography>
          </Box>
          <Divider key={`divider-${hour}`} orientation="vertical" flexItem />
        </Box>
      ))}
    </Box>
  );
};

const DayLabels = () => {
  return (
    <Grid item display="flex" flexDirection={'column'} justifyContent="space-evenly">
      {listOfDays.map((day, index) => {
        return (
          <Typography key={index} variant="h6" component="div" width="50px">
            {day}
          </Typography>
        );
      })}
    </Grid>
  );
};

export const HourlySchedulesContainer: FC<HourlySchedulesContainerProps> = (props: HourlySchedulesContainerProps) => {
  const { isLoading, daySchedules, setDaySchedules, stats, shouldShowOutOfBudgetHour, isDragging, setIsDragging } = props;

  const [firstClicked, setFirstClicked] = useState<Point>({ day: DAYS.Monday, hour: 1 });
  const [firstClickedValue, setFirstClickedValue] = useState<AmazonDaypartingStates>(AmazonDaypartingStates.Disabled);
  const [lastClicked, setLastClicked] = useState<Point>({ day: DAYS.Monday, hour: 1 });

  const [daySchedulesBeforeDrag, setDaySchedulesBeforeDrag] = useState<DaypartingSchedule>(daySchedules);

  const handleUpdateSchedule = () => {
    const newDaySchedules = cloneDeep(daySchedulesBeforeDrag || daySchedules);

    const { day: firstDay, hour: firstHour } = firstClicked;
    const { day: lastClickedDay, hour: lastClickedHour } = lastClicked;

    const minX = Math.min(firstDay, lastClickedDay);
    const maxX = Math.max(firstDay, lastClickedDay);
    const minY = Math.min(firstHour, lastClickedHour);
    const maxY = Math.max(firstHour, lastClickedHour);

    for (let i = minX; i <= maxX; i++) {
      for (let j = minY; j <= maxY; j++) {
        newDaySchedules[i][j] = firstClickedValue;
      }
    }

    setDaySchedules(newDaySchedules);
  };

  const getSquareColor = (day: number, hour: number) => {
    let isCapOutHour = stats.some((stat: any) => stat.dayOfWeek === day && stat.hour === hour);

    if (isCapOutHour && shouldShowOutOfBudgetHour) {
      return 'warning.main';
    }

    if (daySchedules[day][hour] === AmazonDaypartingStates.Enabled) {
      return 'primary.main';
    }

    return 'gray';
  };

  const HourCell = (props: { dayNumber: number; hour: string }) => {
    const { dayNumber, hour } = props;

    return (
      <Box
        onMouseDown={() => {
          setFirstClicked({ day: dayNumber, hour: Number(hour) });
          setFirstClickedValue(
            daySchedules[dayNumber][Number(hour)] === AmazonDaypartingStates.Enabled
              ? AmazonDaypartingStates.Disabled
              : AmazonDaypartingStates.Enabled
          );
          setDaySchedulesBeforeDrag(cloneDeep(daySchedules));
          setLastClicked({ day: dayNumber, hour: Number(hour) });
          setIsDragging(true);
        }}
        key={`${dayNumber}-checkbox${hour}`}
        onMouseEnter={() => {
          if (isDragging) {
            setLastClicked({ day: dayNumber, hour: Number(hour) });
          }
        }}
        sx={{
          margin: '1px',
          ...(!isDragging && {
            ':hover': { border: '1px solid black', borderRadius: '5px' }
          }),
          bgcolor: getSquareColor(dayNumber, Number(hour)),
          width: '26px',
          height: '26px'
        }}
      ></Box>
    );
  };

  const HoursOfDayContainer = (props: {
    dayNumber: number;
    scheduleOfDay: {
      [key: number]: string;
    };
  }) => {
    const { dayNumber, scheduleOfDay } = props;
    return (
      <Box display="flex" alignItems="center" key={`${dayNumber}-days-of-week${listOfDays[dayNumber]}`} flexDirection="row">
        {Object.keys(scheduleOfDay).map((hour) => {
          return (
            <Box key={`${dayNumber}-box${hour}`}>
              <HourCell dayNumber={dayNumber} hour={hour} />
            </Box>
          );
        })}
      </Box>
    );
  };

  useEffect(() => {
    if (isDragging) {
      handleUpdateSchedule();
    }
  }, [lastClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Skeleton variant="rectangular" width={600} height={200} />
        </Box>
      ) : (
        <Box mt={2}>
          <HourLabels />
          <Grid container>
            <DayLabels />
            <Grid item>
              {Object.values(daySchedules).map((scheduleOfDay, dayNumber) => {
                return <HoursOfDayContainer dayNumber={dayNumber} scheduleOfDay={scheduleOfDay} key={dayNumber} />;
              })}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
