import UpdateKeywordBids from './update-keyword-bids';
import { Divider } from '@mui/material';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import useAdsApi from '../../../../hooks/use-walmart-sams-club-api';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import IconButtonWithTooltip from '../../../icon-button-with-tooltip';
import { buildUpdateKeywordRequest } from '../../../../types/walmart-sams-club/keyword';

interface KeywordBulkActionsProps {
  setIsLoading: (loading: boolean) => void;
  disableUpdateKeywordBids?: boolean;
}

const KeywordBulkActions = ({ setIsLoading, disableUpdateKeywordBids }: KeywordBulkActionsProps) => {
  const apiRef = useGridApiContext();

  const { updateKeywords } = useAdsApi();

  const updateKeywordState = async (state: 'Enabled' | 'Paused') => {
    setIsLoading(true);

    const selectedKeywords = Array.from(apiRef.current.getSelectedRows().values());

    const updatedKeywords = selectedKeywords.map((keyword: any) => {
      return {
        ...keyword,
        state: state
      };
    });

    const keywordRequestObjects = updatedKeywords.map((keyword: any) => {
      return buildUpdateKeywordRequest(keyword);
    });

    const response = await updateKeywords(updatedKeywords[0]?.profileId, keywordRequestObjects);

    if (response.success) {
      apiRef.current.updateRows(updatedKeywords);
    }

    apiRef.current.setRowSelectionModel([]);

    setIsLoading(false);
  };

  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (
    params: GridRowSelectionModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  return (
    <>
      <IconButtonWithTooltip
        handleClick={() => updateKeywordState('Enabled')}
        tooltipText={selectionModelHasRows ? 'Enable Keywords' : 'Select one or more Keywords to enable actions'}
        icon={<PlayArrowIcon />}
        isDisabled={!selectionModelHasRows}
      />
      <IconButtonWithTooltip
        handleClick={() => updateKeywordState('Paused')}
        tooltipText={selectionModelHasRows ? 'Pause Keywords' : 'Select one or more Keywords to enable actions'}
        icon={<PauseIcon />}
        isDisabled={!selectionModelHasRows}
      />
      <Divider orientation="vertical" flexItem />
      {!disableUpdateKeywordBids && (
        <UpdateKeywordBids setIsLoading={setIsLoading} selectionModelHasRows={selectionModelHasRows} />
      )}
    </>
  );
};

export default KeywordBulkActions;
