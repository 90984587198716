import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export function renderCampaignBudgetUsage(params: GridRenderCellParams<any, number, any>) {
  if (params.value == null || params.value === undefined) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Tooltip title={`No budget Usage Data Available for ${params.row.name}`} arrow>
          <InfoIcon color="warning" sx={{ opacity: '60%' }} />
        </Tooltip>
      </Box>
    );
  }

  // If the aggregated value does not have the same unit as the other cell
  // Then we fall back to the default rendering based on `valueGetter` instead of rendering a progress bar.
  if (params.aggregation && !params.aggregation.hasCellUnit) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      <CircularProgress
        variant="determinate"
        value={params.value ?? 0}
        sx={{
          circle: {
            strokeLinecap: 'round'
          },
          color: (theme) =>
            (params.value ?? 0) < 50
              ? theme.palette.success.dark
              : (params.value ?? 0) < 80
                ? theme.palette.warning.dark
                : theme.palette.error.dark,
          boxShadow: (theme) => `0 0 0 5px ${theme.palette.divider} inset`,
          borderRadius: '50%'
        }}
        thickness={5}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,

          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="caption" component="div" color="text.primary">
          {`${Math.round(params.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
