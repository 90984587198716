import React from 'react';
import { Box } from '@mui/material';
import HourCell from './hour-cell';
import { DaypartingMode, DaypartingSchedule, DaypartingScheduleState, Point } from './day-parting-types';

interface DayScheduleRowProps {
  dayNumber: number;
  scheduleOfDay: { [key: number]: string };
  bidValue: number;
  daySchedules: DaypartingSchedule;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateSchedule: () => void;
  isCapOutHour: (day: number, hour: number) => boolean;
  setFirstClicked: React.Dispatch<React.SetStateAction<Point>>;
  setFirstClickedValue: React.Dispatch<React.SetStateAction<DaypartingScheduleState>>;
  setDaySchedulesBeforeDrag: React.Dispatch<React.SetStateAction<DaypartingSchedule>>;
  shouldShowOutOfBudgetHour: boolean;
  setLastClicked: React.Dispatch<React.SetStateAction<Point>>;
  currentMode: string;
}

const DayScheduleRow: React.FC<DayScheduleRowProps> = ({
  dayNumber,
  scheduleOfDay,
  bidValue,
  daySchedules,
  isDragging,
  setIsDragging,
  handleUpdateSchedule,
  isCapOutHour,
  setFirstClicked,
  setFirstClickedValue,
  shouldShowOutOfBudgetHour,
  setDaySchedulesBeforeDrag,
  setLastClicked,
  currentMode: currentMode
}) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection="row"
    sx={{
      pointerEvents: bidValue === 0 && currentMode.toLowerCase() === DaypartingMode.BidDecrease.toLowerCase() ? 'none' : 'auto'
    }}
  >
    {Object.keys(scheduleOfDay).map((hour) => (
      <Box key={`${dayNumber}-box${hour}`}>
        <HourCell
          dayNumber={dayNumber}
          hour={hour}
          bidValue={bidValue}
          setFirstClicked={setFirstClicked}
          setFirstClickedValue={setFirstClickedValue}
          setDaySchedulesBeforeDrag={setDaySchedulesBeforeDrag}
          daySchedules={daySchedules}
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour}
          handleUpdateSchedule={handleUpdateSchedule}
          isCapOutHour={isCapOutHour}
          setLastClicked={setLastClicked}
          currentMode={currentMode}
        />
      </Box>
    ))}
  </Box>
);

export default DayScheduleRow;
