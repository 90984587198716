import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import type { SeverityPillColor } from '../../../../walmart/components/severity-pill';
import { SeverityPill } from '../../../../walmart/components/severity-pill';
import { Media, MediaStatus } from '../../../types/walmart-sams-club/media';
import { MediaErrorDetailModal } from './media-error-detail-modal';
import { MediaVideoPlayerModal } from './media-video-player';

const statusMap: Record<MediaStatus, SeverityPillColor> = {
  LIVED: 'success',
  AVAILABLE: 'success',
  PENDING: 'warning',
  FAILED: 'error'
};

interface MediaListTableProps {
  medias: Media[];
  search: any;
}

export const MediaListTable: FC<MediaListTableProps> = (props: MediaListTableProps) => {
  const { medias, search, ...other } = props;

  const [error, setError] = useState<string | undefined>(undefined);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);

  const handleViewErrorDetails = (error: string) => {
    setError(error);
  };

  const handleCloseErrorDetails = () => {
    setError(undefined);
  };

  const handleViewVideoPlayer = (videoUrl: string) => {
    setVideoUrl(videoUrl);
  };

  const handleCloseVideoPlayer = () => {
    setVideoUrl(undefined);
  };

  return (
    <div {...other}>
      <Table>
        <TableBody>
          {medias.map((media) => {
            const mediaName = media.name;
            const statusColor = statusMap[media.status] || 'warning';

            return (
              <TableRow
                hover
                key={media.mediaId}
                onClick={() => handleViewVideoPlayer(media.videoUrl)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <img style={{ height: '80px', width: '80px' }} src={media.thumbnail} />
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="body1">{mediaName}</Typography>
                    <Typography color="text.secondary" variant="body2">
                      Media Id: {media.mediaId}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {media.status === 'FAILED' && !!media.details && (
                    <Button variant="text" sx={{ mr: 2 }} color="secondary" onClick={() => handleViewErrorDetails(media.details)}>
                      View Error Details
                    </Button>
                  )}
                  <SeverityPill color={statusColor}>{media.status}</SeverityPill>
                </TableCell>
              </TableRow>
            );
          })}
          {medias.length === 0 ? (
            <TableRow>
              <TableCell colSpan={2}>
                <Typography align="center" color="textSecondary" variant="body1">
                  {`No media available with status '${search}'`}
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
      {videoUrl ? <MediaVideoPlayerModal onClose={handleCloseVideoPlayer} open={!!videoUrl} videoUrl={videoUrl} /> : null}
      {!!error ? <MediaErrorDetailModal onClose={handleCloseErrorDetails} open={!!error} error={error} /> : null}
    </div>
  );
};

MediaListTable.propTypes = {
  medias: PropTypes.array.isRequired
};
