import { GridColDef } from '@mui/x-data-grid-premium';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { MetricColumnField } from './metric-columns';
import { renderProductCell } from '../renderer/renderProductCell';

export const PRODUCTS_OVERVIEW_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product Name',
    width: 300,
    minWidth: 300,
    sortable: true,
    filterable: true,
    renderCell: renderProductCell
  },
  {
    field: MetricColumnField.Cost,
    headerName: 'Cost',
    width: 100,
    minWidth: 100,
    headerAlign: 'left',
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'percentOfTotalCost',
    headerName: 'Spend %',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `${params.value}%`
  },
  {
    field: 'orderedRevenue',
    headerName: 'Ordered Revenue',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `$${params.value}`
  },
  {
    field: 'percentOfTotalOrderedRevenue',
    headerName: 'Ordered Revenue %',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `${params.value}%`
  },
  {
    field: 'totalAdvertisingCostOfSale',
    headerName: 'TACoS',
    description: 'Total Advertising Cost of Sale',
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: 'weeksOfSupplyOnHand',
    headerName: 'Weeks of Supply',
    type: 'number',
    width: 130,
    minWidth: 80
  }
];
