import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { formatAsCurrency } from '../../../utilities/numeric-format-utilities';

export const renderAsCurrencyFormatter = (params: GridValueFormatterParams<any>) => {
  if (!params) {
    return formatAsCurrency(0);
  }

  const { value } = params;
  return isNaN(parseInt(value)) ? value : formatAsCurrency(Number(value));
};
