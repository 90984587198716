import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Link, Menu, MenuItem, Tooltip, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Platform } from '../../../../shared/types/platforms';
import { Link as RouterLink } from 'react-router-dom';
import useDialog from '../../../../shared/hooks/use-dialog';
import RenameDialog from '../../../../shared/components/rename-dialog';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import AmazonDayparting from '../../day-parting/amazon-day-parting';

export const renderNameWithActions = (retailerPlatform: any) => (params: any) => {
  return <NameActionCell retailerPlatform={retailerPlatform} {...params} />;
};

export const NameActionCell = (params: any) => {
  const { updateCampaign } = useAmazonApi();
  const apiRef = useGridApiContext();

  const [isHovering, setIsHovering] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { toggle: toggleRenameDialog, isShowing: renameDialogIsShowing } = useDialog();
  const { toggle: toggleDaypartingDialog, isShowing: dayPartingDialogIsShowing } = useDialog();

  const handleActionMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuAnchorEl(actionMenuAnchorEl ? null : event.currentTarget);
  };

  const handleDaypartingButtonClick = () => {
    toggleDaypartingDialog();
    handleActionMenuClose();
  };

  const open = Boolean(actionMenuAnchorEl);

  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
    setIsHovering(false);
  };

  useEffect(() => {
    setIsHovering(false);
  }, [dayPartingDialogIsShowing]);

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        width: '100%',
        height: '100%',
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <Link
        to={`/${Platform.AMAZON.value}/campaigns/overview?campaignId=${params.row.campaignId}&profileId=${params.row.profileId}`}
        sx={{
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whitespace: 'nowrap',
          color: 'text.primary'
        }}
        component={RouterLink}
      >
        {params.value}
      </Link>
      {isHovering ? (
        <>
          <Box>
            <Tooltip title="Dayparting" placement="bottom">
              <IconButton aria-label="dayparting" size="small" color="primary" onClick={handleDaypartingButtonClick}>
                <ScheduleIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Actions" placement="top">
              <IconButton onClick={handleActionMenuIconClick} size="small" color="primary">
                <MoreVertIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            id="action-menu"
            anchorEl={actionMenuAnchorEl}
            open={open}
            onClose={handleActionMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                toggleRenameDialog();
                handleActionMenuClose();
              }}
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleActionMenuClose();
              }}
            >
              <Link
                to={`/${Platform.AMAZON.value}/targets?campaignId=${params.row.campaignId}&profileId=${params.row.profileId}`}
                sx={{
                  textDecoration: 'none',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whitespace: 'nowrap',
                  color: 'text.primary'
                }}
                component={RouterLink}
              >
                Targets
              </Link>
            </MenuItem>
          </Menu>
        </>
      ) : null}
      {renameDialogIsShowing && (
        <RenameDialog
          isOpen={renameDialogIsShowing}
          toggleRenameDialog={toggleRenameDialog}
          entityType={'Campaign'}
          row={params.row}
          entityNameKey="name"
          currentEntityName={params.row.name}
          updateEntityFunction={updateCampaign}
          apiRef={apiRef}
        />
      )}
      {dayPartingDialogIsShowing && (
        <AmazonDayparting
          isOpen={dayPartingDialogIsShowing}
          toggle={toggleDaypartingDialog}
          campaignName={params.row.name}
          campaignId={params.row.campaignId}
          profileId={params.row.profileId}
        />
      )}
    </Box>
  );
};
