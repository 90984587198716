import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import AmazonAddNegativeKeywords from './add-negative-keywords';
import { createCampaignNegativeTargetBuilderRequest } from '../../../../types/negative-target';
import { useSnackbar } from 'notistack';

interface CreateNegativeKeywordsDialogProps {
  open: boolean;
  toggle: () => void;
  campaignId: string | null;
  campaignType?: string;
  refreshNegativeTargets?: () => void;
}

const CreateCampaignNegativeKeywordsDialog: React.FC<CreateNegativeKeywordsDialogProps> = ({
  open,
  toggle,
  campaignId,
  refreshNegativeTargets
}) => {
  const [negativeKeywords, setNegativeKeywords] = useState<string[]>([]);

  const { createCampaignNegativeTargets } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (negativeKeywords && negativeKeywords.length > 0) {
        const campaignNegativeKeywordsRequestBody = createCampaignNegativeTargetBuilderRequest(negativeKeywords, campaignId);
        const campaignResponse = await createCampaignNegativeTargets(campaignNegativeKeywordsRequestBody);

        if (campaignResponse.success) {
          enqueueSnackbar(`Campaign Negative Keywords created successfully!`, { variant: 'success' });
          refreshNegativeTargets?.();
        } else {
          enqueueSnackbar(campaignResponse.errorMessage, { variant: 'error' });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(`Error occurred: ${error?.message}`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      toggle();
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Container maxWidth="md">
      <Dialog open={open} disablePortal fullWidth maxWidth="lg" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <DialogTitle>Negative Keywords</DialogTitle>
        <DialogContent>
          <Box>
            <AmazonAddNegativeKeywords
              negativeKeywords={negativeKeywords}
              setNegativeKeywords={(keywords: string[]): void => {
                setNegativeKeywords(keywords);
              }}
            />
          </Box>
          <Box>
            <Typography variant="caption" color="text.secondary" component={'div'}>
              <strong>Campaign Negative Keywords: </strong>
              {negativeKeywords?.length}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Add Negative Keywords
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default CreateCampaignNegativeKeywordsDialog;
