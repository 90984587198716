import { Link, MenuItem } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Link as RouterLink } from 'react-router-dom';
import { LinkCellWithVerticalEllipsis } from '../../../../../shared/components/grid-components/renderer/linkCellWithVerticalEllipsis';
import { PlatformValue } from '../../../../../shared/types/platforms';
import { ExtendedGridRenderCellParams } from '../../../../../shared/types/render-cell-types';

export const renderAdNameWithActions = (retailerPlatform: PlatformValue) => (params: GridRenderCellParams) => {
  return <AdNameActionCell retailerPlatform={retailerPlatform} {...params} />;
};

const AdNameActionCell = (params: ExtendedGridRenderCellParams) => {
  const queryParams = `?profileId=${params.row.profileId}&campaignId=${params.row.campaignId}&adGroupId=${params.row.adGroupId}`;
  const textLinkUrl = `/${params.retailerPlatform}/keywords${queryParams}`;
  const menuItemLinkUrl = `/${params.retailerPlatform}/negative-keywords${queryParams}`;

  const menuItems: JSX.Element[] = [
    <MenuItem key={`${params.id}-negative-keywords`}>
      <Link
        to={menuItemLinkUrl}
        sx={{
          textDecoration: 'none',
          color: 'text.primary'
        }}
        component={RouterLink}
      >
        Negative Keywords
      </Link>
    </MenuItem>
  ];

  return <LinkCellWithVerticalEllipsis linkUrl={textLinkUrl} actionMenuItems={menuItems} {...params} />;
};
