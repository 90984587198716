type Refinement = { id: string; name: string };
export type ExpressionItem = { type: string; value: string };
export type MatchType = {
  exact: boolean;
  expanded: boolean;
};

export const UserAddedRefinementsKeys = {
  ageRanges: 'ageRanges',
  genres: 'genres',
  brands: 'brands',
  reviewRatingBetween: 'reviewRatingBetween',
  priceBetween: 'priceBetween',
  priceLessThan: 'priceLessThan',
  priceGreaterThan: 'priceGreaterThan',
  isPrimeShippingEligible: 'isPrimeShippingEligible'
} as const;

export const AmazonExpressionTypes = {
  ageRanges: 'asinAgeRangeSameAs',
  genres: 'asinGenreSameAs',
  brands: 'asinBrandSameAs',
  asinReviewRatingBetween: 'asinReviewRatingBetween',
  asinPriceBetween: 'asinPriceBetween',
  asinBrandSameAs: 'asinBrandSameAs',
  asinReviewRatingLessThan: 'asinReviewRatingLessThan',
  asinReviewRatingGreaterThan: 'asinReviewRatingGreaterThan',
  asinPriceLessThan: 'asinPriceLessThan',
  asinPriceGreaterThan: 'asinPriceGreaterThan',
  asinIsPrimeShippingEligible: 'asinIsPrimeShippingEligible',
  asinCategorySameAs: 'asinCategorySameAs',
  asinSameAs: 'asinSameAs',
  asinExpandedFrom: 'asinExpandedFrom',
  asinSubstituteRelated: 'asinSubstituteRelated',
  asinAccessoryRelated: 'asinAccessoryRelated',
  queryHighRelMatches: 'queryHighRelMatches',
  queryBroadRelMatches: 'queryBroadRelMatches',
  exactProduct: 'exactProduct',
  similarProduct: 'similarProduct',
  relatedProduct: 'relatedProduct',
  lookback: 'lookback',
  audienceSameAs: 'audienceSameAs'
} as const;

export interface UserAddedRefinements {
  ageRanges: Refinement[];
  genres: Refinement[];
  brands: Refinement[];
  reviewRatingBetween: { start: number; end: number };
  priceBetween: { start: number; end: number };
  priceLessThan: number;
  priceGreaterThan: number;
  isPrimeShippingEligible: boolean;
}

export const defaultUserAddedRefinements: UserAddedRefinements = {
  ageRanges: [],
  brands: [],
  genres: [],
  reviewRatingBetween: { start: 0, end: 5 },
  priceBetween: { start: 0, end: 0 },
  priceLessThan: 0,
  priceGreaterThan: 0,
  isPrimeShippingEligible: false
};
