import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import AddedKeywordsTable from '../../../../shared/components/added-keywords-table';
import { CurrencyInput } from '../../../../shared/components/inputs/currency-input';
import { AmazonCampaignBuilderItemDto, MatchType, State } from '../../../types/campaign-builder-request';
import { AMAZON_TARGET_MINIMUM_BID } from '../../../types/target';

interface ItemCarouselProps {
  items: AmazonCampaignBuilderItemDto[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const ItemCarousel: React.FC<ItemCarouselProps> = (props: ItemCarouselProps) => {
  const { items, setFieldValue } = props;
  const [keywords, setKeywords] = useState<string>('');
  const [matchTypes, setMatchTypes] = useState<MatchType[]>([MatchType.EXACT]);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const [addedItems, setAddedItems] = useState<AmazonCampaignBuilderItemDto[]>([]);
  const [bid, setBid] = useState(AMAZON_TARGET_MINIMUM_BID);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (items) {
      setAddedItems(items);
    }
  }, [items]);

  const handleAddKeywords = async () => {
    if (!keywords) {
      enqueueSnackbar('Please add keywords', { variant: 'warning' });
      return;
    }

    const keywordDtoArray = matchTypes.flatMap((matchType) =>
      keywords.split('\n').map((keywordText) => ({
        matchType: matchType,
        state: State.ENABLED,
        bid: bid,
        keywordText: keywordText.trim()
      }))
    );

    const newItems = [...items];
    newItems[currentItemIndex] = {
      ...newItems[currentItemIndex],
      keywords: [...newItems[currentItemIndex].keywords, ...keywordDtoArray]
    };
    setFieldValue('items', newItems);
    setKeywords('');
  };

  const handleRemoveKeywordsForItem = () => {
    const newItems = [...items];

    newItems[currentItemIndex] = {
      ...newItems[currentItemIndex],
      keywords: []
    };
    setFieldValue('items', newItems);
  };

  const handleMatchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (matchTypes.includes(event.target.value as MatchType)) {
      setMatchTypes(matchTypes.filter((matchType) => matchType !== event.target.value));
    } else {
      setMatchTypes([...matchTypes, event.target.value as MatchType]);
    }
  };

  const handleNext = () => {
    setCurrentItemIndex((prevIndex) => (prevIndex + 1 < addedItems.length ? prevIndex + 1 : 0));
  };

  const handlePrev = () => {
    setCurrentItemIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : addedItems.length - 1));
  };

  return (
    <Box display="flex" sx={{}}>
      <Box p={2} sx={{ mt: 2, width: '100%' }}>
        <Box sx={{}}>
          {addedItems.length > 0 ? (
            <>
              <Box sx={{ my: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IconButton onClick={handlePrev} color="primary">
                    <ChevronLeftIcon />
                  </IconButton>
                  <Card variant="outlined" sx={{ p: 1, flex: 1 }}>
                    <List>
                      <ListItem key={'one'}>
                        <ListItemAvatar>
                          <Avatar
                            alt={`${addedItems[currentItemIndex].title} item image`}
                            src={addedItems[currentItemIndex].imageUrl}
                            variant="square"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography>ASIN: {addedItems[currentItemIndex].asin}</Typography>}
                          secondary={<Typography>Title: {addedItems[currentItemIndex].title}</Typography>}
                        />
                      </ListItem>
                    </List>
                  </Card>
                  <IconButton onClick={handleNext} color="primary">
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider sx={{ my: 4 }} />
              <Box sx={{}}>
                <Stack mb={2}>
                  <CurrencyInput
                    size="small"
                    label="Bid"
                    name="bid"
                    sx={{ maxWidth: '180px' }}
                    value={bid}
                    onChange={(event) => setBid(Number(event.target.value))}
                  />
                  <Box display={'flex'}>
                    {Object.values(MatchType).map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox checked={matchTypes.includes(option)} onChange={handleMatchTypeChange} value={option} />
                        }
                        label={option.charAt(0) + option.slice(1).toLowerCase()}
                      />
                    ))}
                    <Box
                      sx={{
                        mr: 'auto'
                      }}
                    ></Box>
                    <Button variant="outlined" onClick={handleRemoveKeywordsForItem}>
                      Remove All Keywords For This Item
                    </Button>
                  </Box>
                </Stack>
              </Box>
              <Grid container justifyContent={'space-around'} alignItems={'center'}>
                <Grid item xs={3} sx={{}}>
                  <TextField
                    id="keywords"
                    label="Keywords"
                    placeholder="Enter keywords separated by commas"
                    value={keywords}
                    multiline
                    rows={15}
                    sx={{ width: '100%' }}
                    inputProps={{ style: { fontSize: '12px' } }}
                    onChange={(e) => setKeywords(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sx={{ height: '100%' }}>
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{}}>
                    <Button variant="outlined" size="small" aria-label="move all right" onClick={handleAddKeywords}>
                      <Typography variant="h6" fontWeight={'bold'}>
                        ≫
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={8} sx={{}}>
                  <AddedKeywordsTable keywords={addedItems[currentItemIndex].keywords} setKeywords={setKeywords} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography>Add items in the step above to add keywords.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ItemCarousel;
