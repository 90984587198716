import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import {
  PlacementMultiplierRequestObject,
  PlacementMultipliers,
  PlatformMultiplierRequestObject,
  PlatformMultipliers
} from '../../types/bid-multipliers';
import { CampaignPlacement } from './placements';
import { PlacementMultipliersContainer } from './placement-multipliers';
import { PlatformMultipliersContainer } from './platform-multipliers';

import { useSnackbar } from 'notistack';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { PlacementsRequestObject, Placements } from '../../types/placement';
import Fab from '@mui/material/Fab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface BidMultiplierDialogProps {
  toggle: () => void;
  isShowing: boolean;
  handleButtonClick: () => void;
  profileId?: any;
  campaignId: any;
  campaignName: string;
  targetingType: string;
}

export const BidMultiplierDialog = (props: BidMultiplierDialogProps) => {
  const { toggle, isShowing, handleButtonClick, campaignId, profileId, campaignName, targetingType } = props;
  const {
    getPlatformMultipliers,
    updatePlatformMultipliers,
    getPlacementMultipliers,
    updatePlacementMultipliers,
    getPlacements,
    createPlacements
  } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [showFab, setShowFab] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [loading, setLoading] = useState(false);
  const [platformLoading, setPlatformLoading] = useState(false);
  const [placementLoading, setPlacementLoading] = useState(false);
  const [campaignPlacementLoading, setCampaignPlacementLoading] = useState(false);

  const [platformMultipliers, setPlatformMultipliers] = useState<PlatformMultipliers>({ App: 0, Desktop: 0, Mobile: 0 });
  const [placementMultipliers, setPlacementMultipliers] = useState<PlacementMultipliers>({
    BuyBox: 0,
    SearchIngrid: 0,
    HomePage: 0,
    StockUp: 0
  });
  const [placements, setPlacements] = useState<Placements>({
    buyBoxPlacement: false,
    itemCarouselPlacement: false,
    searchCarouselPlacement: false
  });

  const handlePlacementChange = async (event: { target: { checked: boolean; name: string } }) => {
    setPlacements({ ...placements, [event.target.name]: event.target.checked });
  };

  const filterMultiplierKeysBasedOnTargetingType = (key: string) => {
    return !(targetingType === 'Manual' && (key === 'HomePage' || key === 'StockUp'));
  };

  const savePlatformMultipliers = async (): Promise<{ success: boolean; errorMessage: string }> => {
    const requestObject: PlatformMultiplierRequestObject[] = Object.keys(platformMultipliers).map((key: any) => {
      return {
        campaignId: campaignId,
        platformType: key,
        multiplier: platformMultipliers[key as keyof PlatformMultipliers]
      };
    });

    const response = await updatePlatformMultipliers(requestObject);

    return { success: response.success, errorMessage: response.errorMessage || '' };
  };

  const savePlacementMultipliers = async (): Promise<{ success: boolean; errorMessage: string }> => {
    const requestObject: PlacementMultiplierRequestObject[] = Object.keys(placementMultipliers)
      .filter(filterMultiplierKeysBasedOnTargetingType)
      .map((key: any) => {
        return {
          campaignId: campaignId,
          placementType: key,
          multiplier: placementMultipliers[key as keyof PlacementMultipliers]
        };
      });

    const response = await updatePlacementMultipliers(requestObject);

    return { success: response.success, errorMessage: response.errorMessage || '' };
  };

  const saveCampaignPlacements = async (): Promise<{ success: boolean; errorMessage: string }> => {
    if (targetingType.toLowerCase() === 'auto') {
      return { success: true, errorMessage: '' };
    }

    const requestObject: PlacementsRequestObject = {
      itemBuybox: placements.buyBoxPlacement,
      itemCarousel: placements.itemCarouselPlacement,
      searchCarousel: placements.searchCarouselPlacement
    };

    const response = await createPlacements(campaignId, profileId, requestObject);

    return { success: response.success, errorMessage: response.errorMessage || '' };
  };

  const handleSaveButtonClick = async () => {
    setLoading(true);

    const { success: platformSuccess, errorMessage: platformErrorMessage } = await savePlatformMultipliers();
    const { success: placementSuccess, errorMessage: placementErrorMessage } = await savePlacementMultipliers();
    const { success: campaignPlacementSuccess, errorMessage: campaignPlacementErrorMessage } = await saveCampaignPlacements();

    const errorMessages = [platformErrorMessage, placementErrorMessage, campaignPlacementErrorMessage];

    if (platformSuccess && placementSuccess && campaignPlacementSuccess) {
      setLoading(false);
      enqueueSnackbar('Multipliers and Placements updated successfully', { variant: 'success' });
      handleButtonClick();
      return;
    }

    for (let errorMessage of errorMessages) {
      if (errorMessage !== '') {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    }

    setLoading(false);
    handleButtonClick();
  };

  const scrollToBottom = () => {
    contentRef.current?.scrollTo({
      top: contentRef.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    if (!contentRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

    if (scrollTop + clientHeight < scrollHeight - 50) {
      setShowFab(true);
    } else if (scrollHeight === clientHeight) {
      setShowFab(false);
    } else {
      setShowFab(false);
    }
  };

  // When window size changes, check if the Fab should be displayed
  useEffect(() => {
    handleScroll();
  }, [contentRef.current?.scrollHeight, contentRef.current?.clientHeight]);

  useEffect(() => {
    const initializePlatformMultipliers = async () => {
      setPlatformLoading(true);

      const response = await getPlatformMultipliers(campaignId);

      if (response.success) {
        const newMultipliers = { ...platformMultipliers };
        response?.body.map((item: { platformType: 'App' | 'Desktop' | 'Mobile'; multiplier: number }) => {
          newMultipliers[item.platformType] = item.multiplier;
        });
        setPlatformMultipliers(newMultipliers);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setPlatformLoading(false);
    };

    const initializePlacementMultipliers = async () => {
      setPlacementLoading(true);

      const response = await getPlacementMultipliers(campaignId);

      if (response.success) {
        const newMultipliers = { ...placementMultipliers };
        response?.body.map((item: { placementType: 'BuyBox' | 'SearchIngrid' | 'HomePage' | 'StockUp'; multiplier: number }) => {
          newMultipliers[item.placementType] = item.multiplier;
        });
        setPlacementMultipliers(newMultipliers);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setPlacementLoading(false);
    };

    const initializePlacements = async () => {
      setCampaignPlacementLoading(true);

      const response = await getPlacements(campaignId, profileId);

      if (response.success) {
        setPlacements({
          buyBoxPlacement: response.body?.itemBuybox,
          itemCarouselPlacement: response.body?.itemCarousel,
          searchCarouselPlacement: response.body?.searchCarousel
        });
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setCampaignPlacementLoading(false);
    };

    initializePlatformMultipliers();
    initializePlacementMultipliers();
    initializePlacements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={isShowing} onClose={toggle} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h5" component="div">
          Bid Multipliers & Placements {!!campaignName ? `- ${campaignName}` : ''}
        </Typography>
      </DialogTitle>
      <DialogContent ref={contentRef} onScroll={handleScroll}>
        <List>
          <PlatformMultipliersContainer
            loading={platformLoading}
            platformMultipliers={platformMultipliers}
            setPlatformMultipliers={setPlatformMultipliers}
          />
          <Box sx={{ my: 3 }} />
          <PlacementMultipliersContainer
            loading={placementLoading}
            targetingType={targetingType}
            placementMultipliers={placementMultipliers}
            buyBoxEnabled={placements.buyBoxPlacement}
            setPlacementMultipliers={setPlacementMultipliers}
          />
          <Box sx={{ my: 3 }} />
          <CampaignPlacement
            loading={campaignPlacementLoading}
            placements={placements}
            handlePlacementChange={handlePlacementChange}
            disableSwitches={targetingType !== 'Manual'}
          />
        </List>
        {showFab && (
          <Tooltip title="Scroll down" placement="top" arrow>
            <Fab
              size="small"
              color="primary"
              aria-label="scroll to bottom"
              onClick={scrollToBottom}
              sx={{
                position: 'absolute',
                bottom: '10px',
                right: '50%',
                transform: 'translateX(50%)',
                backgroundColor: 'primary.main',
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
              }}
            >
              <KeyboardArrowDownIcon />
            </Fab>
          </Tooltip>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleButtonClick}>Cancel</Button>
        <Button variant="contained" onClick={handleSaveButtonClick}>
          Save
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
