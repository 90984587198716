import { SvgIconTypeMap } from '@mui/material';
import Chip from '@mui/material/Chip';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import * as React from 'react';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  },
  '&.Live': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.Enabled': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.Scheduled': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Proposal': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Paused': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  }
}));

type CampaignStatusOption = {
  label: string;
  value: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
};

interface StatusProps {
  status: string;
  campaignStatusOptions: CampaignStatusOption[];
}

const Status = React.memo((props: StatusProps) => {
  const { status, campaignStatusOptions } = props;

  const statusObject = campaignStatusOptions.find((option) => option.value === status.toLowerCase());

  if (!statusObject) {
    return null;
  }

  const Icon = statusObject.icon;

  return <StyledChip className={status} icon={<Icon className="icon" />} size="small" label={status} variant="outlined" />;
});

export const renderCampaignStatus = (campaignStatusOptions: CampaignStatusOption[]) => (params: GridRenderCellParams) => {
  if (params.value == null) {
    return '';
  }

  return <Status campaignStatusOptions={campaignStatusOptions} status={params.value} />;
};
