import { Tabs, Tab, Container, Typography, Box, Card } from '@mui/material';
import Page from '../../../shared/components/page';
import React, { FC, useEffect, useState } from 'react';

import AutomatedActionsView from './automated-actions-view';
import { enqueueSnackbar } from 'notistack';
import useAmazonApi from '../../hooks/use-amazon-api';
import { ProfileAutocomplete } from '../../../shared/components/page-components/profile-auto-complete';
import { useSearchParams } from 'react-router-dom';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import AutomatedActionsCreation from './automated-action-creation';

export interface ActionParameters {
  updateType: string;
  updateOperator: string;
  value: number;
  minValue: number;
  maxValue: number;
  status: string;
}

const AutomatedActions: FC = () => {
  const { getProfilesWithoutMetrics } = useAmazonApi();
  const [profiles, setProfiles] = useState<any[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');

  useEffect(() => {
    const fetchProfiles = async () => {
      const response = await getProfilesWithoutMetrics();

      if (response.success) {
        setProfiles(response.body.records);
      } else {
        enqueueSnackbar(
          'Error fetching profiles, please try refreshing the page. If the problem persists, please contact support.',
          { variant: 'error' }
        );
      }
    };

    fetchProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleProfileChange = (event: any, value: any) => {
    if (!value?.profileId) {
      searchParams.delete('profileId');
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...searchParams,
        profileId: `${value.profileId}`
      });
    }
  };

  return (
    <Page>
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Automated Actions</Typography>
          <Tabs value={tabIndex} onChange={handleChange}>
            <Tab label="View Actions" />
            <Tab label="Create Action" />
          </Tabs>
        </Box>
        <Box>
          <ProfileAutocomplete profiles={profiles} handleProfileChange={handleProfileChange} profileId={profileId} />
        </Box>

        {profileId && profiles.length > 0 ? (
          tabIndex === 0 ? (
            <AutomatedActionsView profileId={profileId} />
          ) : (
            <AutomatedActionsCreation profileId={profileId} setTabIndex={setTabIndex} />
          )
        ) : (
          <Card
            sx={{
              my: 5,
              height: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              <NorthWestIcon /> To start, please select a profile.
            </Typography>
          </Card>
        )}
      </Container>
    </Page>
  );
};

export default AutomatedActions;
