import { getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { dateColumnType } from '../../../../shared/components/grid-components/renderer/campaign/date-cell-components';
import { renderCampaignNameWithActions } from '../../../../shared/components/grid-components/renderer/campaign/renderNameWithActions';
import { renderReviewStatus } from '../../../../shared/components/grid-components/renderer/campaign/renderReviewStatus';
import { renderCampaignStatus } from '../../../../shared/components/grid-components/renderer/campaign/renderStatus';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { Platform } from '../../../../shared/types/platforms';
import { ACTIVE_STATUSES } from '../../../../shared/types/walmart-sams-club-citrusad/status';
import {
  campaignStatusOptions as WALMART_CAMPAIGN_STATUS_OPTIONS,
  WalmartSamsClubCampaignType
} from '../../../../shared/types/walmart-sams-club/campaign';
import { renderBiddingStrategy } from '../renderer/campaign/render-bidding-strategy';
import { renderCampaignActions } from '../renderer/campaign/renderActiveToggle';
import { renderDailyBudget } from '../renderer/campaign/renderDailyBudget';
import { renderBidMultiplier } from '../renderer/keyword-harvesting/renderBidMultiplierCell';
import { METRIC_COLUMNS } from './metric-columns';

const gridCampaignTypes = [
  {
    label: 'Sponsored Products',
    value: 'sponsoredProducts'
  },
  {
    label: 'Search Brand Amplifier',
    value: 'sba'
  },
  {
    label: 'Video',
    value: 'video'
  }
];

export const CAMPAIGN_COLUMNS: ExtendedGridColDef[] = [
  {
    field: 'isActiveInVector',
    headerName: 'Active',
    filterable: false,
    width: 80,
    resizable: false,
    align: 'center',
    renderCell: renderCampaignActions
  },
  {
    field: 'name',
    headerName: 'Campaign Name',
    width: 200,
    minWidth: 125,
    editable: false,
    renderCell: renderCampaignNameWithActions(Platform.WALMART.value)
  },
  {
    field: 'campaignId',
    headerName: 'Campaign ID',
    type: 'number',
    width: 100,
    minWidth: 100,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'campaignType',
    headerName: 'Campaign Type',
    type: 'singleSelect',
    valueOptions: [
      WalmartSamsClubCampaignType.SponsoredProducts,
      WalmartSamsClubCampaignType.Sba,
      WalmartSamsClubCampaignType.Video
    ],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 120,
    minWidth: 120,
    valueFormatter: ({ value }) =>
      value ? gridCampaignTypes.find((campaignType) => campaignType.value.toLowerCase() === value.toLowerCase())?.label : value
  },
  {
    field: 'targetingType',
    headerName: 'Targeting Type',
    type: 'singleSelect',
    valueOptions: ['Manual', 'Auto'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 100,
    minWidth: 90
  },
  {
    field: 'status',
    headerName: 'Active Status',
    type: 'singleSelect',
    valueOptions: ACTIVE_STATUSES,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    renderCell: renderCampaignStatus(WALMART_CAMPAIGN_STATUS_OPTIONS),
    width: 140,
    minWidth: 120
  },
  {
    field: 'reviewStatus',
    headerName: 'Review Status',
    type: 'singleSelect',
    valueOptions: ['Pending', 'InProgress', 'Complete', 'Cancelled'],
    renderCell: renderReviewStatus,
    width: 140,
    minWidth: 120
  },
  {
    field: 'biddingStrategy',
    headerName: 'Bidding Strategy',
    filterable: false,
    sortable: false,
    editable: false,
    disableColumnMenu: true,
    width: 150,
    minWidth: 130,
    align: 'left',
    renderCell: renderBiddingStrategy
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    ...dateColumnType,
    width: 140,
    minWidth: 120,
    type: 'string',
    filterable: false,
    editable: true
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    ...dateColumnType,
    width: 140,
    minWidth: 120,
    type: 'string',
    filterable: false,
    editable: true
  },
  {
    field: 'budgetType',
    headerName: 'Budget Type',
    filterable: false,
    width: 100,
    editable: false
  },
  {
    field: 'dailyBudget',
    headerName: 'Daily Budget',
    width: 140,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    renderCell: renderDailyBudget
  },
  {
    field: 'recommendedDailyBudgetLast7Days',
    headerName: 'Recommended Daily Budget',
    width: 220,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    description:
      'The recommended daily budget is calculated based on the last 7 days of spend assuming a 20 hour day. This is the amount that should be spent daily to maximize the campaign performance.',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'estimatedMissedAdSpend20HourDay',
    headerName: 'Estimated Missed Spend',
    width: 220,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    description: 'The estimated missed ad spend is calculated assuming a 20 hour day.',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'estimatedMissedAttributedRevenue20HourDay',
    headerName: 'Estimated Missed Sales',
    width: 220,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    description: 'The estimated missed attributed revenue is calculated assuming a 20 hour day.',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'totalSpend',
    headerName: 'Total Spend',
    width: 100,
    minWidth: 90,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'totalBudget',
    headerName: 'Total Budget',
    width: 100,
    minWidth: 90,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'profileId',
    headerName: 'Profile ID',
    type: 'number',
    width: 90,
    minWidth: 60,
    editable: false,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'bidMultiplier',
    headerName: 'Bid Multiplier',
    width: 100,
    minWidth: 70,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: renderBidMultiplier
  },
  ...METRIC_COLUMNS
];
