const MultiItemMultiTargetIcon = ({ fill }: any) => (
  <svg viewBox="0 0 1016 580" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M468 12C468 5.37258 473.373 0 480 0H536C542.627 0 548 5.37258 548 12V68C548 74.6274 542.627 80 536 80H480C473.373 80 468 74.6274 468 68V12Z"
      fill={fill}
    />
    <path
      d="M112 181C112 174.373 117.373 169 124 169H180C186.627 169 192 174.373 192 181V237C192 243.627 186.627 249 180 249H124C117.373 249 112 243.627 112 237V181Z"
      fill={fill}
    />
    <path
      d="M112 360C112 353.373 117.373 348 124 348H180C186.627 348 192 353.373 192 360V416C192 422.627 186.627 428 180 428H124C117.373 428 112 422.627 112 416V360Z"
      fill={fill}
    />
    <path
      d="M468 357C468 350.373 473.373 345 480 345H536C542.627 345 548 350.373 548 357V413C548 419.627 542.627 425 536 425H480C473.373 425 468 419.627 468 413V357Z"
      fill={fill}
    />
    <path
      d="M468 185C468 178.373 473.373 173 480 173H536C542.627 173 548 178.373 548 185V241C548 247.627 542.627 253 536 253H480C473.373 253 468 247.627 468 241V185Z"
      fill={fill}
    />
    <path
      d="M824 360C824 353.373 829.373 348 836 348H892C898.627 348 904 353.373 904 360V416C904 422.627 898.627 428 892 428H836C829.373 428 824 422.627 824 416V360Z"
      fill={fill}
    />
    <path
      d="M822 186C822 179.373 827.373 174 834 174H890C896.627 174 902 179.373 902 186V242C902 248.627 896.627 254 890 254H834C827.373 254 822 248.627 822 242V186Z"
      fill={fill}
    />
    <path
      d="M112 515C112 508.373 117.373 503 124 503H180C186.627 503 192 508.373 192 515V571C192 577.627 186.627 583 180 583H124C117.373 583 112 577.627 112 571V515Z"
      fill={fill}
    />
    <path
      d="M468 512C468 505.373 473.373 500 480 500H536C542.627 500 548 505.373 548 512V568C548 574.627 542.627 580 536 580H480C473.373 580 468 574.627 468 568V512Z"
      fill={fill}
    />
    <path
      d="M824 515C824 508.373 829.373 503 836 503H892C898.627 503 904 508.373 904 515V571C904 577.627 898.627 583 892 583H836C829.373 583 824 577.627 824 571V515Z"
      fill={fill}
    />
    <path
      d="M224 515C224 508.373 229.373 503 236 503H292C298.627 503 304 508.373 304 515V571C304 577.627 298.627 583 292 583H236C229.373 583 224 577.627 224 571V515Z"
      fill={fill}
    />
    <path
      d="M580 512C580 505.373 585.373 500 592 500H648C654.627 500 660 505.373 660 512V568C660 574.627 654.627 580 648 580H592C585.373 580 580 574.627 580 568V512Z"
      fill={fill}
    />
    <path
      d="M936 515C936 508.373 941.373 503 948 503H1004C1010.63 503 1016 508.373 1016 515V571C1016 577.627 1010.63 583 1004 583H948C941.373 583 936 577.627 936 571V515Z"
      fill={fill}
    />
    <path
      d="M0 515C0 508.373 5.37258 503 12 503H68C74.6274 503 80 508.373 80 515V571C80 577.627 74.6274 583 68 583H12C5.37258 583 0 577.627 0 571V515Z"
      fill={fill}
    />
    <path
      d="M356 512C356 505.373 361.373 500 368 500H424C430.627 500 436 505.373 436 512V568C436 574.627 430.627 580 424 580H368C361.373 580 356 574.627 356 568V512Z"
      fill={fill}
    />
    <path
      d="M712 515C712 508.373 717.373 503 724 503H780C786.627 503 792 508.373 792 515V571C792 577.627 786.627 583 780 583H724C717.373 583 712 577.627 712 571V515Z"
      fill={fill}
    />
    <line x1="509" y1="80" x2="509" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="509" y1="251" x2="509" y2="345" stroke={fill} strokeWidth="6" />
    <line x1="153" y1="409" x2="153" y2="503" stroke={fill} strokeWidth="6" />
    <line x1="509" y1="406" x2="509" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="865" y1="409" x2="865" y2="503" stroke={fill} strokeWidth="6" />
    <line x1="153.744" y1="425.441" x2="41.7437" y2="505.441" stroke={fill} strokeWidth="6" />
    <line x1="508.777" y1="75.8976" x2="150.777" y2="171.898" stroke={fill} strokeWidth="6" />
    <line x1="506.807" y1="77.1106" x2="864.807" y2="177.111" stroke={fill} strokeWidth="6" />
    <line x1="509.744" y1="422.441" x2="397.744" y2="502.441" stroke={fill} strokeWidth="6" />
    <line x1="865.744" y1="425.441" x2="753.744" y2="505.441" stroke={fill} strokeWidth="6" />
    <line x1="153.722" y1="425.543" x2="270.722" y2="507.543" stroke={fill} strokeWidth="6" />
    <line x1="509.722" y1="422.543" x2="626.722" y2="504.543" stroke={fill} strokeWidth="6" />
    <line x1="865.722" y1="425.543" x2="982.722" y2="507.543" stroke={fill} strokeWidth="6" />
    <path d="M153 248.939L152.999 347" stroke={fill} strokeWidth="6" />
    <line x1="865" y1="253" x2="865" y2="355" stroke={fill} strokeWidth="6" />
  </svg>
);

export default MultiItemMultiTargetIcon;
