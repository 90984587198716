import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Link, Menu, MenuItem, Tooltip, IconButton } from '@mui/material';
import { useState } from 'react';
import { Platform } from '../../../../shared/types/platforms';
import { Link as RouterLink } from 'react-router-dom';
import useDialog from '../../../../shared/hooks/use-dialog';
import RenameDialog from '../../../../shared/components/rename-dialog';
import useCitrusAdApi from '../../../../shared/hooks/use-citrusad-api';
import { useGridApiContext } from '@mui/x-data-grid-premium';

export const renderNameWithActions = (retailerPlatform: any) => (params: any) => {
  return <NameActionCell retailerPlatform={retailerPlatform} {...params} />;
};

export const NameActionCell = (params: any) => {
  const { updateCampaign } = useCitrusAdApi(Platform?.CITRUS_AD?.value);
  const apiRef = useGridApiContext();

  const [isHovering, setIsHovering] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { toggle: toggleRenameDialog, isShowing: renameDialogIsShowing } = useDialog();

  const handleActionMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuAnchorEl(actionMenuAnchorEl ? null : event.currentTarget);
  };

  const open = Boolean(actionMenuAnchorEl);

  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
    setIsHovering(false);
  };

  const updateCampaignName = (data: any) => {
    const payload = {
      productCampaign: {
        name: data.name
      },
      mask: 'name'
    };
    return updateCampaign(params.row.id, payload);
  };

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        width: '100%',
        height: '100%',
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <Link
        to={`/${Platform.CITRUS_AD.value}/campaigns/overview?campaignId=${params.row.id}&profileId=${params.row.teamId}&namespaceId=${params.row.namespaceId}`}
        sx={{
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whitespace: 'nowrap',
          color: 'text.primary'
        }}
        component={RouterLink}
      >
        {params.value}
      </Link>
      {isHovering ? (
        <>
          <Box>
            <Tooltip title="Actions" placement="top">
              <IconButton onClick={handleActionMenuIconClick} size="small" color="primary">
                <MoreVertIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            id="action-menu"
            anchorEl={actionMenuAnchorEl}
            open={open}
            onClose={handleActionMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                toggleRenameDialog();
                handleActionMenuClose();
              }}
            >
              Rename
            </MenuItem>
          </Menu>
        </>
      ) : null}
      {renameDialogIsShowing && (
        <RenameDialog
          isOpen={renameDialogIsShowing}
          toggleRenameDialog={toggleRenameDialog}
          entityType={'Campaign'}
          row={params.row}
          entityNameKey="name"
          currentEntityName={params.row.name}
          updateEntityFunction={updateCampaignName}
          apiRef={apiRef}
        />
      )}
    </Box>
  );
};
