import { IconButton } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import * as React from 'react';

function SnackbarCloseButton({ snackKey }: any) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton sx={{ color: 'white' }} onClick={() => closeSnackbar(snackKey)}>
      <IconClose />
    </IconButton>
  );
}

export default SnackbarCloseButton;
