/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { TextField, IconButton, Select, MenuItem, Button, FormControl, InputLabel, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Filter } from '../../../../shared/types/pageable';
import { Entities } from './entityInfo';

interface Operator {
  id: string;
  label: string;
}

const operators: Record<string, Operator[]> = {
  string: [
    { id: 'Equals', label: 'Equals' },
    { id: 'Contains', label: 'Contains' }
  ],
  number: [
    { id: 'Equals', label: 'Equals' },
    { id: 'NotEquals', label: 'NotEquals' },
    { id: 'LessThan', label: 'Less Than' },
    { id: 'GreaterThan', label: 'Greater Than' },
    { id: 'GreaterThanOrEquals', label: 'Greater Than Or Equals' },
    { id: 'LessThanOrEquals', label: 'Less Than Or Equals' }
  ],
  boolean: [{ id: 'Equals', label: 'Is' }]
};

interface CustomDataSelectorProps {
  setFilterCriteria: React.Dispatch<React.SetStateAction<Filter[]>>;
  filterCriteria: Filter[];
  entityType: string;
}
const CustomDataSelector: React.FC<CustomDataSelectorProps> = ({ setFilterCriteria, filterCriteria, entityType }) => {
  const handleRowChange = (index: number, key: keyof Filter, value: string) => {
    const newRows = [...filterCriteria];
    newRows[index][key] = value;
    setFilterCriteria(newRows);
  };

  const handleAddRow = () => {
    setFilterCriteria([...filterCriteria, { column: '', comparator: '', value: '' }]);
  };

  const handleDeleteRow = (index: number) => {
    const newRows = filterCriteria.filter((_, i) => i !== index);
    setFilterCriteria(newRows);
  };

  return (
    <Box sx={{ mt: 1 }}>
      {filterCriteria.map((row, index) => (
        <Box key={index} style={{ display: 'flex', gap: '15px', flexWrap: 'wrap', paddingBottom: 10 }}>
          <FormControl style={{ width: '30%' }}>
            <InputLabel>Property</InputLabel>
            <Select value={row.column} onChange={(e) => handleRowChange(index, 'column', e.target.value as string)}>
              {Entities[entityType].map((column) => (
                <MenuItem key={column.id} value={column.id}>
                  {column.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: '30%' }}>
            <InputLabel>Operator</InputLabel>
            <Select
              value={row.comparator}
              onChange={(e) => handleRowChange(index, 'comparator', e.target.value as string)}
              disabled={!row.column}
            >
              {operators[Entities[entityType].find((column) => column.id === row.column)?.dataType || '']?.map((operator) => (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {row.comparator === 'Is' ? (
            <FormControl style={{ width: '30%' }}>
              <InputLabel>Value</InputLabel>
              <Select
                value={row.value}
                onChange={(e) => handleRowChange(index, 'value', e.target.value as string)}
                disabled={!row.comparator}
              >
                <MenuItem key={'enabled'} value={'enabled'}>
                  Enabled
                </MenuItem>
                <MenuItem key={'paused'} value={'paused'}>
                  Paused
                </MenuItem>
                <MenuItem key={'archived'} value={'archived'}>
                  Archived
                </MenuItem>
                <MenuItem key={'pending'} value={'pending'}>
                  Pending
                </MenuItem>
                <MenuItem key={'draft'} value={'draft'}>
                  Draft
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              style={{ width: '30%' }}
              label="Value"
              value={row.value}
              onChange={(e) => handleRowChange(index, 'value', e.target.value)}
              disabled={!row.comparator}
            />
          )}
          <IconButton sx={{ ml: 1.5 }} onClick={() => handleDeleteRow(index)} size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      ))}
      <Button onClick={handleAddRow} variant="contained" color="primary" style={{ left: '42%' }}>
        +
      </Button>
    </Box>
  );
};

export default CustomDataSelector;
