import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useState } from 'react';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { useLocation, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useGridApiContext } from '@mui/x-data-grid-premium';

interface RemoveCampaignsFromBudgetGroupDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  refreshCampaigns: () => void;
}

export const RemoveCampaignsFromBudgetGroupDialog = (props: RemoveCampaignsFromBudgetGroupDialogProps) => {
  const { toggle, isShowing, dynamicKey, refreshCampaigns } = props;
  const { removeCampaignsFromBudgetGroup } = useAmazonApi();
  const location = useLocation();
  const apiRef = useGridApiContext();

  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);

  const handleSaveButtonClick = async () => {
    setBackDropOpen(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);
    const response = await removeCampaignsFromBudgetGroup(selectedCampaignIds);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      // On the budget groups overview we need to refresh because the rows should be removed from the table after removing them from the budget group
      // On the campaigns grid we can just update the rows to update the budget group name since the row should remain in the table
      if (location.pathname.includes('budgetGroups')) {
        refreshCampaigns();
      } else {
        apiRef.current.updateRows(
          selectedCampaignIds.map((campaignId) => ({
            campaignId: campaignId,
            campaignBudgetGroupId: null,
            campaignBudgetGroupName: 'Unassigned'
          }))
        );
      }

      enqueueSnackbar('Campaigns successfully removed from budget group.', { variant: 'success' });
    }

    apiRef.current.setRowSelectionModel([]);
    setBackDropOpen(false);
    toggle();
  };

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="sm" sx={{ transition: 'height 1s' }}>
      <DialogTitle>Create Budget Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to remove the selected campaigns from their current budget group?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSaveButtonClick} variant="contained" color="primary">
          Yes I'm sure
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
