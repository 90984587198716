import { GridColDef, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';
import {
  formatValue,
  preProcessBudgetItemEditCellProps,
  renderEditBudgetItem
} from '../renderer/budget-manager/renderEditBudgetItem';
import { debounce } from 'ts-debounce';
import { listOfMonths } from '../../../../shared/types/budget';
import { useEffect, useState } from 'react';

const MONTH_COLUMNS: GridColDef[] = [
  ...listOfMonths.map((month) => ({
    field: month,
    headerClassName: `header--validate-monthly-aggregate--${month}`,
    headerName: month.toUpperCase().slice(0, 3),
    editable: true,
    width: 100,
    type: 'number',
    cellClassName: () => 'numeric-edit-cell',
    valueFormatter: ({ value }: any) => formatValue(value),
    renderEditCell: renderEditBudgetItem
  }))
];

const debouncedPreProcessBudgetItemEditCellProps = debounce(preProcessBudgetItemEditCellProps, 200); // This is to prevent the validation from firing too often

export const createBudgetColumns = (
  isValidMonthlyBudget: (campaignId: number, month: number, year: number, monthlyBudget: number) => Promise<ResponseObject>
) => {
  const createdBudgetColumns: GridColDef[] = [
    { field: 'budgetItemName', headerName: 'BUDGET ITEMS', width: 150 },
    ...MONTH_COLUMNS.map((monthColumn, index) => ({
      ...monthColumn,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        debouncedPreProcessBudgetItemEditCellProps(params, index, isValidMonthlyBudget)
    })),
    {
      field: 'TOTAL',
      headerName: 'TOTAL',
      cellClassName: 'total-cell',
      type: 'number',
      valueGetter: (params: any) => {
        let total = 0;
        listOfMonths.forEach((month) => {
          total += params.row[month];
        });
        return total;
      },
      valueFormatter: ({ value }: any) => formatValue(value)
    }
  ];

  return createdBudgetColumns;
};

export const createCampaignBudgetColumns = (
  isValidMonthlyBudget: (campaignId: number, month: number, year: number, monthlyBudget: number) => Promise<ResponseObject>
) => {
  const createdBudgetColumns: GridColDef[] = [
    { field: 'campaignName', headerName: 'Campaign Name', width: 150 },
    ...MONTH_COLUMNS.map((monthColumn: GridColDef, index: number) => ({
      ...monthColumn,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        debouncedPreProcessBudgetItemEditCellProps(params, index, isValidMonthlyBudget)
    })),
    {
      field: 'TOTAL',
      headerName: 'TOTAL',
      cellClassName: 'total-cell',
      type: 'number',
      valueGetter: (params: any) => {
        let total = 0;
        listOfMonths.forEach((month) => {
          total += params.row[month];
        });
        return total;
      },
      valueFormatter: ({ value }: any) => formatValue(value)
    }
  ];

  return createdBudgetColumns;
};
