import { InfoOutlined, PanoramaFishEyeOutlined, RemoveRedEyeOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';

interface OutOfBudgetButtonProps {
  shouldShowOutOfBudgetHour: boolean;
  setShouldShowOutOfBudgetHour: (shouldShowOutOfBudgetHour: boolean) => void;
}

export const OutOfBudgetButton = (props: OutOfBudgetButtonProps) => {
  const { shouldShowOutOfBudgetHour, setShouldShowOutOfBudgetHour } = props;

  return (
    <Box display="flex" alignItems={'center'} marginTop={2}>
      <Box
        sx={{
          mr: 'auto'
        }}
      ></Box>
      <Box display="flex" alignItems={'center'}>
        <Button
          variant={'outlined'}
          color={shouldShowOutOfBudgetHour ? 'secondary' : 'primary'}
          size="small"
          onClick={() => setShouldShowOutOfBudgetHour(!shouldShowOutOfBudgetHour)}
          sx={{ mr: 1, gap: '0.5rem' }}
        >
          {shouldShowOutOfBudgetHour ? <Visibility /> : <VisibilityOff />} OOB
          <Tooltip
            title={
              'Out of Budget (OOB) hour is the hour that a campaign hit its daily budget on the specified day in the last week.'
            }
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'background.paper',
                  color: 'text.primary',
                  boxShadow: 1
                }
              }
            }}
            placement="top"
          >
            <InfoOutlined sx={{ color: 'text.disabled', width: '1rem' }} />
          </Tooltip>
        </Button>
      </Box>
    </Box>
  );
};
