import { Backdrop, Card, CardContent, CircularProgress, Container, Skeleton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../../shared/components/page';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { useAdsApi } from '../../../../shared/hooks/use-citrusad-api';
import { CampaignDetails } from './campaign-details';
import { OverviewAds } from './overview-ads';
import { OverviewKeywords } from './overview-keywords';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';

export const CampaignOverview: FC = () => {
  const { pageable, setPageable } = usePageable();
  const { enqueueSnackbar } = useSnackbar();
  const { getCampaignOverview, updateCampaign } = useAdsApi();
  const { dateSettings, saveDateConfig } = useDateConfig();

  const [searchParams] = useSearchParams();

  const profileId = searchParams.has('profileId') ? searchParams.get('profileId') : null;
  const campaignId = searchParams.has('campaignId') ? searchParams.get('campaignId') : null;
  const namespaceId = searchParams.has('namespaceId') ? searchParams.get('namespaceId') : null;

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [campaign, setCampaign] = useState<any>({} as any);

  const [campaignOverview, setCampaignOverview] = useState<any>();
  const [campaignProductMetrics, setCampaignProductMetrics] = useState<any>();
  const [campaignSearchTermsMetrics, setCampaignSearchTermsMetrics] = useState<any>();

  const [campaignTargetTypeIsKeyword, setCampaignTargetTypeIsKeyword] = useState<boolean>(false);

  const fetchCampaignOverview = async () => {
    if (!campaignId || !namespaceId || !profileId) {
      return;
    }

    setIsInitialLoading(true);

    const response = await getCampaignOverview(campaignId, profileId, namespaceId);

    if (response.success) {
      setCampaignOverview({
        ...response.body
      });
      setCampaign(response.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsInitialLoading(false);
  };

  useEffect(() => {
    fetchCampaignOverview();
  }, [pageable, dateSettings.beginDate, dateSettings.endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!campaignOverview) {
      return;
    }

    determineCampaignTargetType();
  }, [campaignOverview]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateCampaign = async (updatedCampaign: any) => {
    setIsUpdateLoading(true);

    const response = await updateCampaign(updatedCampaign.campaignId, updatedCampaign.body);

    if (response.success) {
      const data = flattenObject(response?.body?.productCampaign);
      setCampaignOverview(data);
      enqueueSnackbar('Campaign updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsUpdateLoading(false);
  };

  const determineCampaignTargetType = () => {
    if (!campaignOverview || !campaignOverview.targets || campaignOverview.targets.length === 0) {
      return;
    }

    setCampaignTargetTypeIsKeyword(campaignOverview.targets[0]?.targetType === 'Keyword');
  };

  function renderCampaignDetails() {
    if (isInitialLoading) {
      return <Skeleton width={'100%'} height={50} />;
    }

    if (!campaignOverview) {
      return;
    }

    return <CampaignDetails campaign={campaignOverview} updateCampaign={handleUpdateCampaign} setCampaign={setCampaign} />;
  }

  function flattenObject(obj: any, parentKey = '', result: any = {}) {
    for (const key in obj) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[key] = obj[key];
      }
    }
    return result;
  }

  function renderProducts() {
    if (isInitialLoading) {
      return <Skeleton width={'100%'} height={50} />;
    }

    if (!campaignId) {
      return null;
    }

    return <OverviewAds campaignId={campaignId} />;
  }

  function renderKeywords() {
    if (isInitialLoading) {
      return <Skeleton width={'100%'} height={50} />;
    }

    if (!campaignId) {
      return null;
    }

    return <OverviewKeywords campaignId={campaignId} />;
  }

  return (
    <Page title={`Campaign: ${campaign?.name}`}>
      <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px', marginBottom: '50px' }}>
        <Stack spacing={6}>
          <Card>
            <CardContent>{renderCampaignDetails()}</CardContent>
          </Card>
          {renderProducts()}
          {renderKeywords()}
        </Stack>
      </Container>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isUpdateLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};

export default CampaignOverview;
