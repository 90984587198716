import { Button, Card, CircularProgress, Link, ListItem, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

interface Brand {
  id: string;
  name: string;
}

interface BrandSearchResults {
  products: any[];
  handleExcludeProduct: (product: any) => void;
  addedNegativeTargetingClauses?: any;
  isLoading?: boolean;
  height?: number;
}

function renderRow(
  { index, style }: ListChildComponentProps,
  searchResults: any,
  addedNegativeTargetingClauses: any,
  handleExcludeProduct: any
) {
  const product = searchResults[index];

  const isDisabled = addedNegativeTargetingClauses?.some((negativeTargetingClause: any) => {
    return negativeTargetingClause.expression[0].value === product.asin;
  });

  return (
    <>
      <ListItem style={style} key={product.id} divider>
        <Box display="flex" justifyContent={'flex-start'} alignItems={'center'} width={'100%'}>
          <Box height={50} width={50} mr={2}>
            <img src={product.imageUrl} height={50} width={50} style={{ marginRight: 5 }} />
          </Box>
          <Stack>
            <Link
              href={`https://www.amazon.com/dp/${product.asin}`}
              target="_blank"
              rel="noopener"
              sx={{
                fontSize: '12px',
                WebkitLineClamp: 2,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {product.itemName}
            </Link>
            <Typography
              sx={{
                fontSize: '12px'
              }}
            >
              ASIN: {product.asin}
            </Typography>
          </Stack>
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button sx={{ fontSize: '11px' }} size="small" onClick={() => handleExcludeProduct(product)} disabled={isDisabled}>
            Exclude
          </Button>
        </Box>
      </ListItem>
    </>
  );
}

export default function ProductSearchResultsList(props: BrandSearchResults) {
  const { products, handleExcludeProduct, addedNegativeTargetingClauses, isLoading, height } = props;

  if (isLoading) {
    return (
      <Card
        sx={{ width: '100%', height: height ?? 380, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        variant="outlined"
      >
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card sx={{ width: '100%', height: height ?? 298, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} variant="outlined">
      <FixedSizeList height={298} width="100%" itemSize={80} itemCount={products.length}>
        {(props) => renderRow(props, products, addedNegativeTargetingClauses, handleExcludeProduct)}
      </FixedSizeList>
    </Card>
  );
}
