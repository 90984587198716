import { Container, Stack, Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useAdsApi } from '../../../shared/hooks/use-citrusad-api';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CITRUSAD_PROFILE_COLUMNS } from '../../components/grid-components/column-configurations/profile-columns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';

const Profiles: FC = () => {
  const { getTeams } = useAdsApi(Platform.CITRUS_AD.value);
  const { enqueueSnackbar } = useSnackbar();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [profiles, setProfiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.CITRUSAD_PROFILE,
    breadCrumbParams: { profileId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    return oldRow;
  }, []);

  useEffect(() => {
    setBreadcrumbParams({ profileId });
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    const fetchProfiles = async () => {
      setIsLoading(true);

      const response = await getTeams(pageable, dateSettings.beginDate, dateSettings.endDate);

      if (response.success) {
        setProfiles(response.body.records);
        setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchProfiles();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Profiles">
      <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h4">Profiles/Teams</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
          </Box>
          {!initialSettings || settingsLoading ? (
            <DataGridPremium
              rows={[]}
              columns={CITRUSAD_PROFILE_COLUMNS}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
              key={uuidv4()}
              disableRowGrouping
            />
          ) : (
            <PerformanceGrid
              processRowUpdate={processRowUpdate}
              loading={isLoading}
              rows={profiles}
              columns={CITRUSAD_PROFILE_COLUMNS}
              initialState={initialSettings?.config}
              saveGridConfig={saveGridConfig}
              dateConfig={{
                dateSettings: dateSettings,
                dateSettingsLoading: dateSettingsLoading,
                saveDateConfig: saveDateConfig
              }}
              getRowId={(row: any) => row.profileId}
              handleFilterModelChange={handleFilterModelChange}
              handleSortModelChange={handleSortModelChange}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              rowCount={rowCount}
            />
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default Profiles;
