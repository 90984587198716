const SingleItemMultiTargetIcon = ({ fill }: any) => (
  <svg viewBox="0 0 1016 580" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M468 12C468 5.37258 473.373 0 480 0H536C542.627 0 548 5.37258 548 12V68C548 74.6274 542.627 80 536 80H480C473.373 80 468 74.6274 468 68V12Z"
      fill={fill}
    />
    <path
      d="M112 12C112 5.37258 117.373 0 124 0H180C186.627 0 192 5.37258 192 12V68C192 74.6274 186.627 80 180 80H124C117.373 80 112 74.6274 112 68V12Z"
      fill={fill}
    />
    <path
      d="M824 12C824 5.37258 829.373 0 836 0H892C898.627 0 904 5.37258 904 12V68C904 74.6274 898.627 80 892 80H836C829.373 80 824 74.6274 824 68V12Z"
      fill={fill}
    />
    <path
      d="M468 186C468 179.373 473.373 174 480 174H536C542.627 174 548 179.373 548 186V242C548 248.627 542.627 254 536 254H480C473.373 254 468 248.627 468 242V186Z"
      fill={fill}
    />
    <path
      d="M112 186C112 179.373 117.373 174 124 174H180C186.627 174 192 179.373 192 186V242C192 248.627 186.627 254 180 254H124C117.373 254 112 248.627 112 242V186Z"
      fill={fill}
    />
    <path
      d="M824 186C824 179.373 829.373 174 836 174H892C898.627 174 904 179.373 904 186V242C904 248.627 898.627 254 892 254H836C829.373 254 824 248.627 824 242V186Z"
      fill={fill}
    />
    <path
      d="M468 357C468 350.373 473.373 345 480 345H536C542.627 345 548 350.373 548 357V413C548 419.627 542.627 425 536 425H480C473.373 425 468 419.627 468 413V357Z"
      fill={fill}
    />
    <path
      d="M112 357C112 350.373 117.373 345 124 345H180C186.627 345 192 350.373 192 357V413C192 419.627 186.627 425 180 425H124C117.373 425 112 419.627 112 413V357Z"
      fill={fill}
    />
    <path
      d="M824 357C824 350.373 829.373 345 836 345H892C898.627 345 904 350.373 904 357V413C904 419.627 898.627 425 892 425H836C829.373 425 824 419.627 824 413V357Z"
      fill={fill}
    />
    <path
      d="M468 512C468 505.373 473.373 500 480 500H536C542.627 500 548 505.373 548 512V568C548 574.627 542.627 580 536 580H480C473.373 580 468 574.627 468 568V512Z"
      fill={fill}
    />
    <path
      d="M112 512C112 505.373 117.373 500 124 500H180C186.627 500 192 505.373 192 512V568C192 574.627 186.627 580 180 580H124C117.373 580 112 574.627 112 568V512Z"
      fill={fill}
    />
    <path
      d="M824 512C824 505.373 829.373 500 836 500H892C898.627 500 904 505.373 904 512V568C904 574.627 898.627 580 892 580H836C829.373 580 824 574.627 824 568V512Z"
      fill={fill}
    />
    <path
      d="M580 512C580 505.373 585.373 500 592 500H648C654.627 500 660 505.373 660 512V568C660 574.627 654.627 580 648 580H592C585.373 580 580 574.627 580 568V512Z"
      fill={fill}
    />
    <path
      d="M224 512C224 505.373 229.373 500 236 500H292C298.627 500 304 505.373 304 512V568C304 574.627 298.627 580 292 580H236C229.373 580 224 574.627 224 568V512Z"
      fill={fill}
    />
    <path
      d="M936 512C936 505.373 941.373 500 948 500H1004C1010.63 500 1016 505.373 1016 512V568C1016 574.627 1010.63 580 1004 580H948C941.373 580 936 574.627 936 568V512Z"
      fill={fill}
    />
    <path
      d="M356 512C356 505.373 361.373 500 368 500H424C430.627 500 436 505.373 436 512V568C436 574.627 430.627 580 424 580H368C361.373 580 356 574.627 356 568V512Z"
      fill={fill}
    />
    <path
      d="M0 512C0 505.373 5.37258 500 12 500H68C74.6274 500 80 505.373 80 512V568C80 574.627 74.6274 580 68 580H12C5.37258 580 0 574.627 0 568V512Z"
      fill={fill}
    />
    <path
      d="M712 512C712 505.373 717.373 500 724 500H780C786.627 500 792 505.373 792 512V568C792 574.627 786.627 580 780 580H724C717.373 580 712 574.627 712 568V512Z"
      fill={fill}
    />
    <line x1="511" y1="80" x2="511" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="155" y1="80" x2="155" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="867" y1="80" x2="867" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="511" y1="254" x2="511" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="155" y1="254" x2="155" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="867" y1="254" x2="867" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="509" y1="406" x2="509" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="153" y1="406" x2="153" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="865" y1="406" x2="865" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="509.744" y1="422.441" x2="397.744" y2="502.441" stroke={fill} strokeWidth="6" />
    <line x1="153.744" y1="422.441" x2="41.7437" y2="502.441" stroke={fill} strokeWidth="6" />
    <line x1="865.744" y1="422.441" x2="753.744" y2="502.441" stroke={fill} strokeWidth="6" />
    <line x1="509.722" y1="422.543" x2="626.722" y2="504.543" stroke={fill} strokeWidth="6" />
    <line x1="153.722" y1="422.543" x2="270.722" y2="504.543" stroke={fill} strokeWidth="6" />
    <line x1="865.722" y1="422.543" x2="982.722" y2="504.543" stroke={fill} strokeWidth="6" />
  </svg>
);

export default SingleItemMultiTargetIcon;
