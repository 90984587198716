import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface CreateReviewDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const CancelReviewDialog = ({ open, onClose, onSubmit }: CreateReviewDialogProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Create Review</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a review</DialogContentText>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button variant="contained" onClick={onSubmit}>
            Cancel Review
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
