import { Box, BoxProps, styled, useTheme } from '@mui/material';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GridAggregationModel,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridPinnedColumns,
  GridRowIdGetter,
  GridRowSelectionModel,
  GridSortModel,
  gridClasses,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { debounce, filter, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { DateConfigUIProps } from '../../../../shared/hooks/use-date-config';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { DEFAULT_ROW_COUNT, ROWS_PER_PAGE_OPTIONS } from '../../../../shared/types/pageable';
import { AUTOSIZE_OPTIONS } from '../../../../shared/components/performance-grid/autosize-options';
import { CustomColumnMenuComponent } from '../../../../shared/components/performance-grid/custom-column-menu';
import { CustomGridColumnHeaderFilterIconButton } from '../../../../shared/components/performance-grid/custom-filter-button';
import { PerformanceGridFooter } from '../../../../shared/components/performance-grid/performance-grid-footer';
import { PerformanceGridToolbar } from './products-performance-grid-toolbar';

export interface PerformanceGridProps {
  processRowUpdate: (newRow: any, oldRow: any) => any;
  rows: any[];
  columns: ExtendedGridColDef[];
  initialState: GridInitialStatePremium | undefined;
  dateConfig?: DateConfigUIProps;
  getRowId?: GridRowIdGetter<any> | undefined;
  loading?: boolean;
  className?: string;
  getRowClassName?: (params: any) => string;
  isCellEditable?: (params: GridCellParams) => boolean;
  bulkActions?: JSX.Element;
  saveGridConfig?: (update: any, caller?: string) => void;
  handleFilterModelChange?: (newModel: GridFilterModel, searchableColumns?: string[]) => void;
  handleSortModelChange?: (newModel: GridSortModel) => void;
  handlePageChange?: (newPage: number) => void;
  handlePageSizeChange?: (newPageSize: number) => void;
  rowCount?: number;
  aggregatedTotals?: any;
  disableGridDateRangePicker?: boolean;
  bottomMargin?: number;
  allowQuickFilterSearch?: boolean;
  gridFilterModel?: GridFilterModel;
  columnVisibilityModel?: GridColumnVisibilityModel;
  otherDataGridProps?: Readonly<Record<string, any>>;
  customProps?: Readonly<Record<string, any>>;
}

const StyledBox = styled(({ className, ...props }: BoxProps) => <Box {...props} className={className} />)(({ theme }) => ({
  '& .MuiDataGrid-row': {
    '&:hover': {
      '& .numeric-edit-cell:not(.MuiDataGrid-cell--editing)': {
        paddingLeft: '4px',
        paddingRight: '4px',
        '& .MuiDataGrid-cellContent': {
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.neutral?.[200] : theme.palette.neutral?.[600],
          borderRadius: '4px',
          width: '100%',
          minHeight: '60%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '4px 5px 4px 2px'
        }
      }
    }
  }
}));

const columnHeadersStyles = {
  [`.${gridClasses.columnSeparator}, .${gridClasses['columnSeparator--resizing']}`]: {
    visibility: 'visible',
    width: 'auto'
  }
};
const columnHeaderStyles = {
  [`& .${gridClasses.iconButtonContainer}`]: {
    visibility: 'visible',
    width: 'auto'
  },
  [`& .${gridClasses.menuIcon}`]: {
    width: 'auto',
    visibility: 'visible'
  }
};

const StyledDataGridPremium = styled(DataGridPremium)(({ theme }) => ({
  '& .MuiDataGrid-cell:focus': {
    outline: 'none'
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
    outline: 'none'
  },
  'cell--editing': {
    justifyContent: 'center',
    '& .MuiDataGrid-editInputCell input': {
      border: '1px solid lightgrey',
      width: '100%',
      marginLeft: '3px',
      marginRight: '3px',
      borderRadius: '4px',
      padding: '7px 3px 7px 5px'
    }
  },
  [`.${gridClasses.columnHeader}:not(.${gridClasses['columnHeader--filtered']}) .${gridClasses.filterIcon}`]: {
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter
    })
  },
  [`.${gridClasses.columnHeaders}:hover`]: columnHeadersStyles,
  [`.${gridClasses.columnHeader}:hover`]: columnHeaderStyles,
  [`.${gridClasses.columnHeader}:not(.${gridClasses['columnHeader--filtered']}):hover .${gridClasses.filterIcon}`]: {
    opacity: 0.5
  }
}));

export default function PerformanceGrid({
  rows,
  columns,
  initialState,
  processRowUpdate,
  getRowId,
  loading,
  className,
  getRowClassName,
  isCellEditable,
  bulkActions,
  saveGridConfig,
  handleFilterModelChange: handleFilterModelChangeFromProps,
  handleSortModelChange: handleSortModelChangeFromProps,
  handlePageChange: handlePageChangeFromProps,
  handlePageSizeChange: handlePageSizeChangeFromProps,
  rowCount: rowCount,
  aggregatedTotals,
  disableGridDateRangePicker,
  bottomMargin: bottomMarginFromProps,
  dateConfig,
  allowQuickFilterSearch,
  gridFilterModel: gridFilterModelFromProps,
  columnVisibilityModel: columnVisibilityModelFromProps,
  otherDataGridProps,
  customProps
}: PerformanceGridProps) {
  const apiRef = useGridApiRef();
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_ROW_COUNT,
    page: 0
  });
  const [aggregationModel, setAggregationModel] = useState<GridAggregationModel>({} as GridAggregationModel);
  const [aggregationFunctions, setAggregationFunctions] = useState<any>({ ...GRID_AGGREGATION_FUNCTIONS });

  const [filterModel, setFilterModel] = useState<GridFilterModel>(gridFilterModelFromProps ?? { items: [] });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    columnVisibilityModelFromProps ?? {}
  );

  useEffect(() => {
    if (!gridFilterModelFromProps || isEqual(gridFilterModelFromProps, filterModel)) {
      return;
    }

    handleFilterModelChange(gridFilterModelFromProps);
  }, [gridFilterModelFromProps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!columnVisibilityModelFromProps || isEqual(columnVisibilityModelFromProps, columnVisibilityModel)) {
      return;
    }

    handleColumnVisibilityModelChange(columnVisibilityModelFromProps);
  }, [columnVisibilityModelFromProps]); // eslint-disable-line react-hooks/exhaustive-deps

  const bottomMargin = bottomMarginFromProps ?? 100;

  const debouncedSave = debounce(() => {
    const currentState = apiRef.current.exportState();
    saveGridConfig?.({
      ...currentState,
      preferencePanel: {
        open: false
      }
    });
  }, 500);

  const initializeTotalAggregationModelAndFunctions = () => {
    if (!aggregatedTotals) {
      return;
    }

    const aggregatedTotal = (aggregatedTotals: any) => ({
      apply: (params: any) => {
        return aggregatedTotals[params.field];
      },
      label: '',
      columnTypes: ['number']
    });

    setAggregationFunctions({
      ...aggregationFunctions,
      total: aggregatedTotal(aggregatedTotals)
    });

    let newAggregationModel = { ...aggregatedTotals };

    for (let key in newAggregationModel) {
      newAggregationModel[key] = 'total';
    }

    setAggregationModel(newAggregationModel);
  };

  const initializePageableParamsFromInitialState = () => {
    if (!initialState) {
      return;
    }

    const filterState = initialState?.filter?.filterModel;
    const sortState = initialState?.sorting?.sortModel;

    if (filterState) {
      handleFilterModelChange(filterState);
    }

    if (sortState) {
      handleSortModelChange(sortState);
    }
  };

  const initializeCustomAggregationFunctions = () => {
    if (
      !!customProps &&
      ['customAggregationFunctions', 'customAggregationModel'].every((key) => Object.keys(customProps).includes(key))
    ) {
      setAggregationFunctions({
        ...aggregationFunctions,
        ...customProps.customAggregationFunctions
      });
      setAggregationModel({
        ...aggregationModel,
        ...customProps.customAggregationModel
      });
    }
  };

  useEffect(() => {
    initializePageableParamsFromInitialState();
    initializeCustomAggregationFunctions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initializeTotalAggregationModelAndFunctions();
  }, [aggregatedTotals]); // eslint-disable-line react-hooks/exhaustive-deps

  // Adapted from example given by mui-x team in this github issue: https://github.com/mui/mui-x/issues/2186
  const handleCellClick = useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: params.isEditable ? GridCellModes.Edit : GridCellModes.View }
        }
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleColumnVisibilityModelChange = useCallback((newModel: GridColumnVisibilityModel) => {
    const currentState = apiRef.current.exportState();

    saveGridConfig?.({
      ...currentState,
      columns: {
        ...currentState.columns,
        columnVisibilityModel: newModel
      },
      preferencePanel: {
        open: false
      }
    });

    setColumnVisibilityModel(newModel);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePinnedColumnsModelChange = useCallback((newModel: GridPinnedColumns) => {
    const currentState = apiRef.current.exportState();

    saveGridConfig?.({
      ...currentState,
      preferencePanel: {
        open: false
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleColumnOrderChange = useCallback((newModel: GridColumnOrderChangeParams, event: any, details: any) => {
    const currentState = apiRef.current.exportState();

    saveGridConfig?.({
      ...currentState,
      preferencePanel: {
        open: false
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleColumnResize = useCallback((newModel: any) => {
    debouncedSave();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleColumnAutoResize = useCallback(() => {
    apiRef.current.autosizeColumns(AUTOSIZE_OPTIONS);
    debouncedSave();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterModelChange = useCallback((newModel: GridFilterModel) => {
    const currentState = apiRef.current.exportState();

    const searchableColumns = columns
      .filter((column) => column.searchable === true && (column.filterable === undefined || column.filterable === true))
      .map((column) => column.field);

    // fire effect chain for pageable
    handleFilterModelChangeFromProps?.(newModel, searchableColumns);

    // locally set filter model
    setFilterModel(newModel);

    let filterKey = 'filterModel';

    saveGridConfig?.({
      ...currentState,
      filter: {
        filterModel: newModel
      },
      preferencePanel: {
        open: false
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSortModelChange = useCallback((newModel: GridSortModel) => {
    const currentState = apiRef.current.exportState();

    handleSortModelChangeFromProps?.(newModel);

    saveGridConfig?.({
      ...currentState,
      preferencePanel: {
        open: false
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePaginationChange = (newModel: GridPaginationModel) => {
    const currentState = apiRef.current.exportState();

    handlePageSizeChangeFromProps?.(newModel.pageSize);
    handlePageChangeFromProps?.(newModel.page);

    setPaginationModel(newModel);

    saveGridConfig?.({
      ...currentState,
      preferencePanel: {
        open: false
      }
    });
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const theme = useTheme();

  return (
    <StyledBox style={{ height: `calc(100% - ${bottomMargin}px)` }} className={className}>
      <StyledDataGridPremium
        sx={{
          '& .MuiDataGrid-row, & .MuiDataGrid-cell': {
            borderLeft: 'none',
            borderRight: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.neutral?.[800]
          },
          '& .MuiDataGrid-columnHeader': {
            borderLeft: 'none',
            borderRight: 'none'
          }
        }}
        disableRowGrouping
        rows={rows}
        columns={columns}
        autosizeOptions={AUTOSIZE_OPTIONS}
        apiRef={apiRef}
        checkboxSelection
        sortingOrder={['desc', 'asc', null]}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onPinnedColumnsChange={handlePinnedColumnsModelChange}
        onColumnOrderChange={handleColumnOrderChange}
        onColumnResize={handleColumnResize}
        onCellClick={handleCellClick}
        loading={loading}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: PerformanceGridToolbar,
          columnHeaderFilterIconButton: CustomGridColumnHeaderFilterIconButton,
          footer: () => <PerformanceGridFooter selectionModel={rowSelectionModel} onColumnAutoResize={handleColumnAutoResize} />,
          columnMenu: CustomColumnMenuComponent
        }}
        slotProps={{
          toolbar: {
            apiRef,
            setFilterButtonEl,
            bulkActions,
            disableGridDateRangePicker,
            dateConfig,
            allowQuickFilterSearch
          },
          panel: {
            anchorEl: filterButtonEl
          },
          filterPanel: {
            logicOperators: [GridLogicOperator.And]
          }
        }}
        disableRowSelectionOnClick
        getRowId={getRowId}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(newSelectionModel) => {
          setRowSelectionModel(newSelectionModel);
        }}
        initialState={initialState}
        getRowClassName={getRowClassName}
        isCellEditable={isCellEditable}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
        onSortModelChange={handleSortModelChange}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        onPaginationModelChange={handlePaginationChange}
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
        aggregationFunctions={aggregationFunctions}
        {...otherDataGridProps}
      />
    </StyledBox>
  );
}
