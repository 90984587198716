import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material';
import {
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  gridFilterModelSelector
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useEffect, useState } from 'react';
import { DateConfigUIProps } from '../../hooks/use-date-config';
import GridDateRangePicker from '../grid-components/date-picker/grid-date-picker';
import { useCurrentRetailerPlatform } from '../../hooks/use-current-retailer-platform';
import { Platform } from '../../types/platforms';

const defaultDateConfig = {
  dateSettings: {},
  dateSettingsLoading: false,
  saveDateConfig: () => {}
};

export const PerformanceGridToolbar = (props: GridToolbarProps) => {
  const {
    apiRef,
    setFilterButtonEl,
    bulkActions,
    disableGridDateRangePicker,
    dateConfig = defaultDateConfig,
    allowQuickFilterSearch = false
  } = props;

  const { dateSettings, dateSettingsLoading, saveDateConfig } = dateConfig as DateConfigUIProps;

  const [isMounted, setIsMounted] = useState(false); // Track mount state
  const currentRetailerPlatform = useCurrentRetailerPlatform();

  useEffect(() => {
    setIsMounted(true); // Component has mounted
    return () => {
      setIsMounted(false); // Component is unmounting
    };
  }, []);

  const filterModel = gridFilterModelSelector(apiRef);

  const updateGridDateRange = (dateRange: DateRange<Date>, newDatePickerIncrementInDays: number) => {
    if (isMounted && dateRange[0] && dateRange[1]) {
      saveDateConfig(dateRange, newDatePickerIncrementInDays);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexWrap={'nowrap'}
        style={{ borderBottom: '1px solid #ccc', paddingBottom: '4px', paddingTop: '4px' }}
      >
        {allowQuickFilterSearch && (
          <Box sx={{ ml: 1.5 }}>
            <GridToolbarQuickFilter debounceMs={200} sx={{ ml: 2 }} />
          </Box>
        )}
        {bulkActions && (
          <>
            {currentRetailerPlatform !== Platform.AMAZON.value && (
              <Typography variant="h6" sx={{ ml: 2, mr: 2 }}>
                Actions:
              </Typography>
            )}
            {bulkActions}
          </>
        )}
        <Box
          sx={{
            mr: 'auto'
          }}
        ></Box>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        {!disableGridDateRangePicker && !dateSettingsLoading ? (
          <Box marginLeft="10px" marginRight="10px">
            <GridDateRangePicker
              onChange={(newDateRange, newDatePickerIncrementInDays) =>
                updateGridDateRange(newDateRange, newDatePickerIncrementInDays || 7)
              }
              initialDateRange={[new Date(dateSettings.beginDate), new Date(dateSettings.endDate)]}
              initialIncrementInDays={dateSettings.datePickerIncrementInDays}
            />
          </Box>
        ) : null}
      </Grid>
      <Grid container alignItems={'center'}>
        {filterModel?.items.length > 0 ? (
          <Box>
            {filterModel?.items.map((item, index) => {
              if (item.value === undefined || item.value === null || item.value === '') {
                return;
              }

              return (
                <Chip
                  key={index}
                  sx={{ margin: '10px' }}
                  label={`${item.field} ${item.operator} ${item.value}`}
                  onDelete={() => apiRef.current.deleteFilterItem(item)}
                  onClick={() => apiRef.current.showFilterPanel()}
                />
              );
            })}
          </Box>
        ) : null}
      </Grid>
    </>
  );
};
