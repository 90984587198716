import { Grid, Stack, Box, FormControlLabel, Checkbox, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { keywordMatchTypes } from '../types/walmart-sams-club/campaign';
import AddedKeywordsTable from './added-keywords-table';
import { CurrencyInput } from './inputs/currency-input';

interface AddKeywordsProps {
  keywords: any;
  setKeywords: (keywords: any) => void;
  onBidChange?: (bid: any) => void;
  minBid?: number;
  onlyExact?: boolean;
  isBidEditable?: boolean;
}

export default function AddKeywords(props: AddKeywordsProps) {
  const MINIMUM_BID = 0.3;
  const { keywords, setKeywords, minBid, onlyExact, isBidEditable = false, onBidChange } = props;
  const [matchTypes, setMatchTypes] = useState(['exact']);
  const [userEnteredKeywords, setUserEnteredKeywords] = useState('');
  const [bid, setBid] = useState(minBid || MINIMUM_BID);

  const addKeywords = () => {
    const parsedKeywords = userEnteredKeywords
      .split('\n')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)
      .map((keyword) => keyword.toLowerCase());

    const objectifiedKeywords = matchTypes.flatMap((matchType) =>
      parsedKeywords.map((keyword: string) => ({
        keywordText: keyword,
        matchType: matchType,
        bid,
        id: `${keyword}-${matchType}`
      }))
    );

    const newKeywords = objectifiedKeywords.filter(
      (keyword: any) =>
        !keywords.some(
          (objKeyword: any) => objKeyword.keywordText === keyword.keywordText && objKeyword.matchType === keyword.matchType
        )
    );

    setUserEnteredKeywords('');
    setKeywords([...keywords, ...newKeywords]);
  };

  const handleMatchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (matchTypes.includes(event.target.value)) {
      setMatchTypes(matchTypes.filter((matchType) => matchType !== event.target.value));
    } else {
      setMatchTypes([...matchTypes, event.target.value]);
    }
  };

  useEffect(() => {
    if (minBid) {
      setBid(minBid);
    }
  }, [minBid]);

  const isBidValid = () => {
    if (minBid) {
      return bid >= minBid;
    }

    return bid >= MINIMUM_BID;
  };

  const handleBidChange = (val: any) => {
    setBid(Number(val));
    onBidChange ? onBidChange(Number(val)) : '';
  };

  return (
    <Grid container direction="column" mt={2}>
      <Stack mb={2}>
        <CurrencyInput
          size="small"
          label="Bid"
          name="bid"
          error={!isBidValid()}
          helperText={!isBidValid() ? `Bid must be at least $${minBid || MINIMUM_BID}` : ' '}
          sx={{ maxWidth: '180px' }}
          value={bid}
          onChange={(event) => handleBidChange(event.target.value)}
        />
        <Box display={'flex'}>
          {!onlyExact ? (
            keywordMatchTypes.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox checked={matchTypes.includes(option.value)} onChange={handleMatchTypeChange} value={option.value} />
                }
                label={option.label}
              />
            ))
          ) : (
            <FormControlLabel
              key={keywordMatchTypes[0].value}
              control={
                <Checkbox checked={matchTypes.includes(keywordMatchTypes[0].value)} disabled value={keywordMatchTypes[0].value} />
              }
              label={keywordMatchTypes[0].label}
            />
          )}
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button variant="outlined" onClick={() => setKeywords([])}>
            Remove All Keywords
          </Button>
        </Box>
      </Stack>
      <Grid item>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Grid item>
            <TextField
              id="campaignItemIds"
              label="Keywords"
              onChange={(event) => {
                setUserEnteredKeywords(event.target.value);
              }}
              placeholder="Enter one keyword per line"
              inputProps={{
                style: { fontSize: '14px' }
              }}
              multiline
              value={userEnteredKeywords}
              sx={{ mt: 1, width: '320px' }}
              rows={15}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5, mr: 1, ml: 1 }}
                variant="outlined"
                size="small"
                aria-label="move all right"
                onClick={addKeywords}
              >
                <Typography variant="h6" fontWeight={'bold'}>
                  ≫
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <AddedKeywordsTable keywords={keywords} setKeywords={setKeywords} isBidEditable={!!isBidEditable} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
