import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../hooks/use-auth';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { useSnackbar } from 'notistack';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { logout } = useAuth();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async (): Promise<void> => {
    try {
      onClose?.();
      logout();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout.', { variant: 'error' });
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex'
        }}
      >
        <Avatar
          src={user?.profilePhotoUrl}
          sx={{
            height: 40,
            width: 40
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1
          }}
        >
          <Typography variant="body1">{user?.name}</Typography>
          <Typography color="textSecondary" variant="body2">
            Harvest Group
          </Typography>
        </Box>
      </Box>
      <Box>
        <MenuItem>
          <ListItemText>
            <Typography variant="caption" color="grey">
              Version {process.env.REACT_APP_VERSION}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
