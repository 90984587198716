import { Backdrop, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { KeywordHarvestingSettings } from '../../types/keyword-harvesting';
import { CreateKeywordHarvestingSettingsForm } from './create-keyword-harvesting-settings-form';
import { UpdateSettingsAndPreviewKeywords } from './update-settings-and-preview-keywords';

const KeywordHarvesting: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;

  const { getKeywordHarvestingSettingsForCampaign } = useAdsApi();
  const navigate = useNavigate();

  const [settings, setSettings] = useState<KeywordHarvestingSettings>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchKeywordSettings = async () => {
      if (!campaignId) {
        navigate('/campaigns');
        return;
      }

      setIsLoading(true);

      const response = await getKeywordHarvestingSettingsForCampaign(campaignId);

      if (response.success && response.body?.keywordHarvestingSettingsId > 0) {
        setSettings(response.body);
      }

      setIsLoading(false);
    };

    fetchKeywordSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!!settings) {
    return <UpdateSettingsAndPreviewKeywords settings={settings} />;
  } else {
    return <CreateKeywordHarvestingSettingsForm />;
  }
};

export default KeywordHarvesting;
