import { ArrowRight } from '@mui/icons-material';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import { createCampaignBuilderRequest } from '../../types/campaign-builder-request';
import { isSbResponseEligibleForReview } from '../../../shared/types/walmart-sams-club/review';
import { CAMPAIGN_FORM_VALIDATION_SCHEMA, INITIAL_FORM_VALUES } from './create-campaign-form/campaign-form-config';
import { CampaignSettingsStep } from './create-campaign-form/campaign-settings-step';
import { ItemSelectionStep } from './create-campaign-form/item-selection-step';
import { SuccessfulCampaignCreation } from './create-campaign-form/successful-campaign-creation';
import { TargetingSettingsStep } from './create-campaign-form/targeting-settings-step';
import { ErrorReview } from '../../../shared/campaign-builder-error-review';

const enum ContentSteps {
  Create = 0,
  Error = 1,
  Complete = 2
}

export const SamsClubCreateCampaign: FC = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getProfiles, samsClubBuildCampaign } = useAdsApi();

  const [profiles, setProfiles] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldShowReview, setShouldShowReview] = useState(false);
  const [currentContentToShow, setCurrentContentToShow] = useState(ContentSteps.Create);
  const [campaignBuilderResponse, setCampaignBuilderResponse] = useState<ResponseObject>({
    body: null,
    success: false,
    response: undefined,
    errorMessage: undefined
  });

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  useEffect(() => {
    const initialize = async () => {
      const response = await getProfiles();

      if (response.success) {
        setProfiles(response.body.records);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: CAMPAIGN_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values, helpers): Promise<void> => {
      setIsSubmitting(true);

      const requestBody = createCampaignBuilderRequest(values);
      const response = await samsClubBuildCampaign(profileId, requestBody);

      setCampaignBuilderResponse(response);

      setIsSubmitting(false);
    }
  });

  useEffect(() => {
    if (!campaignBuilderResponse?.response) {
      return;
    }

    if (!campaignBuilderResponse?.success) {
      enqueueSnackbar(campaignBuilderResponse?.errorMessage, { variant: 'error' });
      navigate(`/campaigns?profileId=${profileId}`);
    }

    if (campaignBuilderResponse?.body?.hasErrors) {
      setCurrentContentToShow(ContentSteps.Error);
      return;
    }

    if (isSbResponseEligibleForReview(campaignBuilderResponse)) {
      setShouldShowReview(true);
    }

    setCurrentContentToShow(ContentSteps.Complete);
  }, [campaignBuilderResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSkipReview() {
    setShouldShowReview(false);
  }

  function handleSuccessfulReviewSubmission() {
    enqueueSnackbar('Review successfully submitted.', { variant: 'success' });
    setShouldShowReview(false);
    setCurrentContentToShow(ContentSteps.Complete);
  }

  const createCampaignFormSteps = [
    {
      label: 'Campaign Settings',
      content: <CampaignSettingsStep formik={formik} profiles={profiles} />
    },
    {
      label: 'Item Selection',
      content: <ItemSelectionStep formik={formik} stepNumber={2} profileId={profileId} />
    },
    {
      label: 'Targeting Settings',
      content: <TargetingSettingsStep formik={formik} stepNumber={3} />
    }
  ];

  const createCampaignContentSteps = [
    {
      key: ContentSteps.Create,
      content: (
        <>
          {createCampaignFormSteps.map((step, index) => (
            <Box key={step.label}>{step.content}</Box>
          ))}
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ my: 5 }}>
              <Button
                endIcon={<ArrowRight fontSize="small" />}
                type="submit"
                variant="contained"
                size="large"
                disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
              >
                Create Campaign
              </Button>
            </Grid>
          </Grid>
        </>
      )
    },
    {
      key: ContentSteps.Error,
      content: (
        <ErrorReview
          campaignBuilderResponse={campaignBuilderResponse}
          genericErrorText="The following errors occurred while creating your campaign. Parts of your campaign may have succeeded. Please review your campaign for completeness."
          handleNavigateToCampaigns={() => navigate(`/sams-club/campaigns?profileId=${profileId}`)}
        />
      )
    },
    {
      key: ContentSteps.Complete,
      content: (
        <>
          <SuccessfulCampaignCreation
            items={formik.values.items}
            keywords={formik.values.keywords}
            negativeKeywords={formik.values.negativeKeywords}
            campaignName={formik.values.name}
            campaignBuilderResponse={campaignBuilderResponse}
            handleSkipReview={handleSkipReview}
            handleSuccessfulReviewSubmission={handleSuccessfulReviewSubmission}
            shouldShowReview={shouldShowReview}
          />
        </>
      )
    }
  ];

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}
      >
        <Container maxWidth="xl">
          <form onSubmit={formik.handleSubmit} {...props}>
            <div>
              <Container maxWidth="md">
                <Typography variant="h6" sx={{ mb: 4, color: '#999' }}>
                  Create a new campaign
                </Typography>
                {createCampaignContentSteps.find((content) => content.key === currentContentToShow)?.content}
              </Container>
            </div>
          </form>
        </Container>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
