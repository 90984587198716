import type { FC } from 'react';
import { Box, CardMedia } from '@mui/material';
import teal from '../../shared/images/vector-branding/vector-square-teal.svg';
import { keyframes } from '@emotion/react';

const fadeInOut = keyframes`
0% {
  opacity:1;
}
50% {
  opacity:0.5;
}
100% {
  opacity:1;
}
`;

export const SplashScreen: FC = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'neutral.900',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 2000
    }}
  >
    <CardMedia
      image={teal}
      sx={{
        height: '150px',
        width: '150px',
        margin: 'auto',
        display: 'block',
        animation: `${fadeInOut} 1500ms ease-in infinite`
      }}
    />
  </Box>
);
