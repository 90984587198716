export const formatAsCurrency = (value: number, maxFractionDigits: number = 2, minimumFractionDigits: number = 2): string => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maxFractionDigits
  });

  return currencyFormatter.format(value);
};
