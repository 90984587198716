interface Attribute {
  id: string;
  label: string;
  dataType: string;
}

export const Entities: Record<string, Attribute[]> = {
  Target: [
    { id: 'cost', label: 'Cost', dataType: 'number' },
    { id: 'clicks', label: 'Clicks', dataType: 'number' },
    { id: 'returnOnAdSpend', label: 'Return on Ad Spend', dataType: 'number' },
    { id: 'attributedRevenue', label: 'Attributed Revenue', dataType: 'number' },
    { id: 'orders', label: 'Orders', dataType: 'number' },
    { id: 'conversionRate', label: 'Conversion Rate', dataType: 'number' },
    { id: 'costPerClick', label: 'Cost Per Click', dataType: 'number' },
    { id: 'state', label: 'State', dataType: 'boolean' },
    { id: 'searchableTargetText', label: 'Target Text', dataType: 'string' },
    { id: 'keywordMatchType', label: 'Keyword Match Type', dataType: 'string' },
    { id: 'bid', label: 'Bid', dataType: 'number' },
    { id: 'targetingType', label: 'Targeting Type', dataType: 'string' },
    { id: 'newToBrandOrders', label: 'New to Brand Orders', dataType: 'number' },
    { id: 'newToBrandRevenue', label: 'New to Brand Revenue', dataType: 'number' },
    { id: 'newToBrandUnits', label: 'New to Brand Units', dataType: 'number' }
  ],
  Campaign: [
    { id: 'cost', label: 'Cost', dataType: 'number' },
    { id: 'clicks', label: 'Clicks', dataType: 'number' },
    { id: 'returnOnAdSpend', label: 'Return on Ad Spend', dataType: 'number' },
    { id: 'attributedRevenue', label: 'Attributed Revenue', dataType: 'number' },
    { id: 'orders', label: 'Orders', dataType: 'number' },
    { id: 'conversionRate', label: 'Conversion Rate', dataType: 'number' },
    { id: 'costPerClick', label: 'Cost Per Click', dataType: 'number' },
    { id: 'state', label: 'State', dataType: 'boolean' },
    { id: 'name', label: 'Name', dataType: 'string' },
    { id: 'campaignType', label: 'Campaign Type', dataType: 'string' },
    { id: 'budget', label: 'Budget', dataType: 'number' },
    { id: 'budgetType', label: 'Budget Type', dataType: 'string' },
    { id: 'dailyBudget', label: 'Daily Budget', dataType: 'number' },
    { id: 'startDate', label: 'Start Date', dataType: 'date' },
    { id: 'endDate', label: 'End Date', dataType: 'date' },
    { id: 'targetingType', label: 'Targeting Type', dataType: 'string' },
    { id: 'impressions', label: 'Impressions', dataType: 'number' },
    { id: 'clickThroughRate', label: 'Click Through Rate', dataType: 'number' }
  ],
  AdGroup: [
    { id: 'cost', label: 'Cost', dataType: 'number' },
    { id: 'clicks', label: 'Clicks', dataType: 'number' },
    { id: 'returnOnAdSpend', label: 'Return on Ad Spend', dataType: 'number' },
    { id: 'attributedRevenue', label: 'Attributed Revenue', dataType: 'number' },
    { id: 'orders', label: 'Orders', dataType: 'number' },
    { id: 'conversionRate', label: 'Conversion Rate', dataType: 'number' },
    { id: 'costPerClick', label: 'Cost Per Click', dataType: 'number' },
    { id: 'state', label: 'State', dataType: 'boolean' },
    { id: 'name', label: 'Name', dataType: 'string' }
  ],
  ProductAd: [
    { id: 'cost', label: 'Cost', dataType: 'number' },
    { id: 'clicks', label: 'Clicks', dataType: 'number' },
    { id: 'returnOnAdSpend', label: 'Return on Ad Spend', dataType: 'number' },
    { id: 'attributedRevenue', label: 'Attributed Revenue', dataType: 'number' },
    { id: 'orders', label: 'Orders', dataType: 'number' },
    { id: 'conversionRate', label: 'Conversion Rate', dataType: 'number' },
    { id: 'costPerClick', label: 'Cost Per Click', dataType: 'number' },
    { id: 'state', label: 'State', dataType: 'boolean' }
  ]
};
