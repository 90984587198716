import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useAdsApi } from '../../../shared/hooks/use-citrusad-api';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CITRUSAD_WALLET_COLUMNS } from '../../components/grid-components/column-configurations/wallet-columns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';

const Wallet: FC = () => {
  const { getWallets } = useAdsApi(Platform.CITRUS_AD.value);
  const { enqueueSnackbar } = useSnackbar();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [wallets, setWallets] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? searchParams.get('profileId') : null;

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.CITRUSAD_WALLET,
    breadCrumbParams: { profileId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    return oldRow;
  }, []);

  useEffect(() => {
    setBreadcrumbParams({ profileId });
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchWallets();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWallets = async () => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    setIsLoading(true);

    const response = await getWallets(pageable);

    if (response.success) {
      setWallets(response.body.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  return (
    <Page title="Wallet">
      <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h4">Wallet</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Link
              to={`/${Platform.CITRUS_AD.value}/wallet/create${profileId ? `?profileId=${profileId}` : ''}`}
              component={RouterLink}
              style={{ textDecoration: 'none' }}
            ></Link>
          </Box>
          {!initialSettings || settingsLoading ? (
            <DataGridPremium
              rows={[]}
              columns={CITRUSAD_WALLET_COLUMNS}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
              key={uuidv4()}
              disableRowGrouping
            />
          ) : (
            <PerformanceGrid
              processRowUpdate={processRowUpdate}
              loading={isLoading}
              rows={wallets}
              columns={CITRUSAD_WALLET_COLUMNS}
              initialState={initialSettings?.config}
              saveGridConfig={saveGridConfig}
              dateConfig={{
                dateSettings: dateSettings,
                dateSettingsLoading: dateSettingsLoading,
                saveDateConfig: saveDateConfig
              }}
              getRowId={(row: any) => row.walletId}
              handleFilterModelChange={handleFilterModelChange}
              handleSortModelChange={handleSortModelChange}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              rowCount={rowCount}
            />
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default Wallet;
