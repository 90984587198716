import AddIcon from '@mui/icons-material/Add';
import ArrowRight from '@mui/icons-material/ArrowRight';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSearchParams } from 'react-router-dom';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { initialSettings, KeywordHarvestingSettings } from '../../types/keyword-harvesting';
import { MetricMinimumFormField } from './metric-minimum-form-field';

export const CreateKeywordHarvestingSettingsForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getProfiles, getCampaign, getKeywordHarvestingSettingsForCampaign, createKeywordHarvestingSettings } = useAdsApi();

  const [campaign, setCampaign] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { pageable } = usePageable();

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  let campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;

  const [settings, setSettings] = useState<KeywordHarvestingSettings>({
    ...initialSettings,
    campaignId: Number(campaignId)
  });

  useEffect(() => {
    const fetchCampaign = async () => {
      if (!profileId || !campaignId) {
        return;
      }

      setIsLoading(true);

      const response = await getCampaign(profileId, campaignId);

      if (response.success) {
        setCampaign(response.body);
      }

      setIsLoading(false);
    };

    fetchCampaign();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable) {
      return;
    }

    const fetchCampaign = async () => {
      if (!profileId || !campaignId) {
        return;
      }

      setIsLoading(true);

      const response = await getCampaign(profileId, campaignId);

      if (response.success) {
        setCampaign(response.body);
      }

      setIsLoading(false);
    };

    fetchCampaign();
  }, [pageable]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: KeywordHarvestingSettings, helpers: any): Promise<void> => {
    setIsSubmitting(true);
    try {
      const response = await createKeywordHarvestingSettings({ ...values, campaignId: campaignId || 0 });
      if (response.success) {
        window.location.reload(); //reload to refire logic in keyword-harvesting.tsx
        return;
      }
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error, { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const cardMargin = 2;

  return (
    <Container>
      <Formik initialValues={initialSettings} onSubmit={handleSubmit}>
        {({ values, isSubmitting, isValid, dirty, setFieldValue }) => (
          <Form>
            <Box sx={{ mt: 10, width: '70%', margin: 'auto' }}>
              <Box sx={{ px: 2, mb: 2 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h5">Keyword Harvesting Settings</Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="This feature isn't available yet. In the meantime, you can apply keywords manually in the next step.">
                      <FormControlLabel
                        control={<Switch disabled />}
                        label="Apply via Automation"
                        onChange={() => {
                          setFieldValue('isAutomationEnabled', !settings.isAutomationEnabled);
                          setSettings((prevSettings) => ({
                            ...prevSettings,
                            isAutomationEnabled: !prevSettings.isAutomationEnabled
                          }));
                        }}
                        checked={settings.isAutomationEnabled}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
              <Card sx={{ mb: cardMargin }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {campaign?.name}
                  </Typography>
                  <Typography variant="caption" sx={{ mb: 2 }} color="text.secondary">
                    How do you want to apply keywords to your campaign?
                  </Typography>
                  <Grid container direction="row" justifyContent={'space-between'} alignItems="center" sx={{ mt: 2 }} spacing={2}>
                    <Grid item xs={6} sx={{ mb: 2 }}>
                      <NumericFormat
                        label="Default Bid"
                        customInput={TextField}
                        id="outlined-adornment-weight"
                        thousandSeparator=","
                        value={values.defaultBid}
                        size="small"
                        decimalScale={2}
                        allowNegative={false}
                        sx={{ mt: 1 }}
                        onValueChange={(values, sourceInfo) => {
                          setFieldValue('defaultBid', values.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box>$</Box>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mb: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={settings.applyAsEnabled}
                            onChange={() => {
                              setFieldValue('applyAsEnabled', !settings.applyAsEnabled);
                              setSettings((prevSettings) => ({ ...prevSettings, applyAsEnabled: !prevSettings.applyAsEnabled }));
                            }}
                          />
                        }
                        label="Apply keywords as Enabled"
                        sx={{ float: 'right' }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ mb: cardMargin }}>
                <CardContent>
                  <Typography variant="h6" sx={{ my: 2 }}>
                    Metrics Rules
                  </Typography>
                  <FieldArray
                    name="metricMinimums"
                    render={(arrayHelpers: ArrayHelpers) => (
                      <Box>
                        {values.metricMinimums.map((metric, index) => (
                          <MetricMinimumFormField
                            metric={metric}
                            index={index}
                            setFieldValue={setFieldValue}
                            arrayHelpers={arrayHelpers}
                            key={index}
                          />
                        ))}
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 6
                          }}
                        >
                          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => arrayHelpers.push('')}>
                            Add metric rule
                          </Button>
                        </Box>
                      </Box>
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: cardMargin }}>
                <CardContent>
                  <Box sx={{ my: 2 }}>
                    <Typography variant="h6">Excluded Terms</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Enter a list of terms separated by line breaks. Each line will be treated as a separate item.
                      <br />
                      These terms will be excluded from your keyword list.
                    </Typography>
                  </Box>

                  <TextField
                    sx={{ width: '100%' }}
                    id="campaignItemIds"
                    label="Does not include"
                    onChange={(event) => {
                      setFieldValue('keywordTextDoesNotContain', event.target.value.split('\n'));
                      setSettings((prevSettings) => ({
                        ...prevSettings,
                        keywordTextDoesNotContain: event.target.value.toLocaleLowerCase().split('\n')
                      }));
                    }}
                    value={settings.keywordTextDoesNotContain?.map((item) => item).join('\n')}
                    placeholder="Keyword text does not include"
                    multiline
                    rows={10}
                    inputProps={{
                      style: {
                        fontSize: '12px'
                      }
                    }}
                  />
                </CardContent>
              </Card>
              <Grid container justifyContent="flex-end">
                <Grid item sx={{ my: 5 }}>
                  <Button endIcon={<ArrowRight fontSize="small" />} type="submit" variant="contained" size="large">
                    Save Settings
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
