import { Box, Typography, IconButton } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { useState } from 'react';
import { convertDateToMonthDayYear } from '../../../../shared/utilities/date-utilities';

interface InlineInputProps {
  value: any;
  onSave: (newValue: any) => void;
  label?: string;
  labelComponentProps?: any;
  EditModeComponent: React.ComponentType<any>;
  EditModeComponentProps?: any;
  disableEditMode?: boolean;
  emptyFieldPlaceHolder?: string;
}

const InlineInput: React.FC<InlineInputProps> = ({
  value,
  onSave,
  label = '',
  labelComponentProps = {},
  EditModeComponent,
  EditModeComponentProps = {},
  disableEditMode = false,
  emptyFieldPlaceHolder = ''
}) => {
  const [editMode, setEditMode] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [isHovered, setIsHovered] = useState(false);

  const handleSave = () => {
    onSave(tempValue);
    setEditMode(false);
  };

  const handleCancel = () => {
    setTempValue(value);
    setEditMode(false);
    setIsHovered(false);
  };

  const onChange = (param: any) => {
    if (param && param.target) {
      setTempValue(param.target.value);
      return;
    }

    if (param instanceof Date) {
      setTempValue(convertDateToMonthDayYear(param));
      return;
    }

    setTempValue(param);
  };

  const renderValue = (rawValue: any) => {
    if (value instanceof Date) {
      return convertDateToMonthDayYear(rawValue);
    }

    return rawValue;
  };

  return (
    <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      {editMode ? (
        <Box display="flex" alignItems="center">
          <EditModeComponent value={tempValue} onChange={onChange} label={label} autoFocus {...EditModeComponentProps} />
          <IconButton onClick={handleSave}>
            <CheckIcon color="success" />
          </IconButton>
          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          {value === '' ? (
            <i>{emptyFieldPlaceHolder}</i>
          ) : (
            <Typography {...labelComponentProps}>
              {label !== '' ? (
                <>
                  <strong>{label}:</strong>
                  <br />
                </>
              ) : (
                ''
              )}
              {renderValue(value)}
            </Typography>
          )}

          <IconButton
            onClick={() => setEditMode(true)}
            sx={{ opacity: isHovered && !editMode ? 1 : 0 }}
            disabled={disableEditMode}
          >
            <EditIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default InlineInput;
