import {
  Avatar,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import { useState, useEffect } from 'react';
import { ResponseObject } from '../../../../../shared/utilities/fetch-utilities';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Platform } from '../../../../../shared/types/platforms';

interface SubmitReviewProps {
  campaignBuilderResponse: ResponseObject;
  campaignName: string;
  keywordRequests: any[];
  onReturnClick: () => void;
}

export const ErrorReview = (props: SubmitReviewProps) => {
  const { campaignBuilderResponse, onReturnClick } = props;
  const [errors, setErrors] = useState<any[]>([]);

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let profileId = searchParams.get('profileId') || null;
  useEffect(() => {
    if (campaignBuilderResponse?.body) {
      const err = campaignBuilderResponse?.body;
      setErrors(err?.details[0]?.fieldViolations);
    }
  }, [campaignBuilderResponse?.body, setErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Button variant={'outlined'} startIcon={<KeyboardBackspaceIcon />} sx={{ mb: 2 }} onClick={() => onReturnClick()}>
        Return to Create Campaign
      </Button>
      <Typography variant={'h5'} sx={{ mb: 2 }}>
        Uh oh! Something went wrong.
      </Typography>
      <Typography variant={'body1'} sx={{ mb: 2 }}>
        The following errors occurred while creating your campaign.
      </Typography>
      <TableContainer component={Paper} sx={{ border: '1px solid lightgray', maxWidth: '800px', borderRadius: '4px', mt: 2 }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: 'nowrap' }}></TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Error Field</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Error Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((error: any, index: number) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Avatar
                      sx={{
                        backgroundColor: 'error.main',
                        color: 'success.contrastText',
                        height: 30,
                        width: 30
                      }}
                    >
                      <PriorityHighIcon />
                    </Avatar>
                  </TableCell>
                  <TableCell>{error.field}</TableCell>
                  <TableCell>{error.description}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
