import { Tooltip, IconButton } from '@mui/material';
import UpdateBidsDialog from './edit-bids-dialog';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface UpdateBidActionButtonProps {
  updateEntities: (updatedEntities: any) => void;
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
  selectionModelHasRows: boolean;
}

const UpdateBidsActionButton = ({
  updateEntities,
  isDialogOpen,
  setIsDialogOpen,
  selectionModelHasRows
}: UpdateBidActionButtonProps) => {
  return (
    <>
      <Tooltip title={selectionModelHasRows ? 'Increase or Decrease Bid(s) By' : 'Select one or more items to enable actions'}>
        <span>
          <IconButton
            sx={{ borderRadius: '100px' }}
            color="primary"
            size="large"
            onClick={() => setIsDialogOpen(true)}
            disabled={!selectionModelHasRows}
          >
            <AttachMoneyIcon />
          </IconButton>
        </span>
      </Tooltip>
      {isDialogOpen ? (
        <UpdateBidsDialog handleUpdate={updateEntities} open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      ) : null}
    </>
  );
};

export default UpdateBidsActionButton;
