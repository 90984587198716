const SingleItemSingleTargetIcon = ({ fill }: any) => (
  <svg viewBox="0 0 1016 580" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M896 12C896 5.37258 901.373 0 908 0H964C970.627 0 976 5.37258 976 12V68C976 74.6274 970.627 80 964 80H908C901.373 80 896 74.6274 896 68V12Z"
      fill={fill}
    />
    <path
      d="M784 12C784 5.37258 789.373 0 796 0H852C858.627 0 864 5.37258 864 12V68C864 74.6274 858.627 80 852 80H796C789.373 80 784 74.6274 784 68V12Z"
      fill={fill}
    />
    <path
      d="M672 12C672 5.37258 677.373 0 684 0H740C746.627 0 752 5.37258 752 12V68C752 74.6274 746.627 80 740 80H684C677.373 80 672 74.6274 672 68V12Z"
      fill={fill}
    />
    <path
      d="M560 12C560 5.37258 565.373 0 572 0H628C634.627 0 640 5.37258 640 12V68C640 74.6274 634.627 80 628 80H572C565.373 80 560 74.6274 560 68V12Z"
      fill={fill}
    />
    <path
      d="M448 12C448 5.37258 453.373 0 460 0H516C522.627 0 528 5.37258 528 12V68C528 74.6274 522.627 80 516 80H460C453.373 80 448 74.6274 448 68V12Z"
      fill={fill}
    />
    <path
      d="M336 12C336 5.37258 341.373 0 348 0H404C410.627 0 416 5.37258 416 12V68C416 74.6274 410.627 80 404 80H348C341.373 80 336 74.6274 336 68V12Z"
      fill={fill}
    />
    <path
      d="M112 12C112 5.37258 117.373 0 124 0H180C186.627 0 192 5.37258 192 12V68C192 74.6274 186.627 80 180 80H124C117.373 80 112 74.6274 112 68V12Z"
      fill={fill}
    />
    <path
      d="M224 12C224 5.37258 229.373 0 236 0H292C298.627 0 304 5.37258 304 12V68C304 74.6274 298.627 80 292 80H236C229.373 80 224 74.6274 224 68V12Z"
      fill={fill}
    />
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H68C74.6274 0 80 5.37258 80 12V68C80 74.6274 74.6274 80 68 80H12C5.37258 80 0 74.6274 0 68V12Z"
      fill={fill}
    />
    <path
      d="M896 186C896 179.373 901.373 174 908 174H964C970.627 174 976 179.373 976 186V242C976 248.627 970.627 254 964 254H908C901.373 254 896 248.627 896 242V186Z"
      fill={fill}
    />
    <path
      d="M784 186C784 179.373 789.373 174 796 174H852C858.627 174 864 179.373 864 186V242C864 248.627 858.627 254 852 254H796C789.373 254 784 248.627 784 242V186Z"
      fill={fill}
    />
    <path
      d="M672 186C672 179.373 677.373 174 684 174H740C746.627 174 752 179.373 752 186V242C752 248.627 746.627 254 740 254H684C677.373 254 672 248.627 672 242V186Z"
      fill={fill}
    />
    <path
      d="M560 186C560 179.373 565.373 174 572 174H628C634.627 174 640 179.373 640 186V242C640 248.627 634.627 254 628 254H572C565.373 254 560 248.627 560 242V186Z"
      fill={fill}
    />
    <path
      d="M448 186C448 179.373 453.373 174 460 174H516C522.627 174 528 179.373 528 186V242C528 248.627 522.627 254 516 254H460C453.373 254 448 248.627 448 242V186Z"
      fill={fill}
    />
    <path
      d="M336 186C336 179.373 341.373 174 348 174H404C410.627 174 416 179.373 416 186V242C416 248.627 410.627 254 404 254H348C341.373 254 336 248.627 336 242V186Z"
      fill={fill}
    />
    <path
      d="M112 186C112 179.373 117.373 174 124 174H180C186.627 174 192 179.373 192 186V242C192 248.627 186.627 254 180 254H124C117.373 254 112 248.627 112 242V186Z"
      fill={fill}
    />
    <path
      d="M224 186C224 179.373 229.373 174 236 174H292C298.627 174 304 179.373 304 186V242C304 248.627 298.627 254 292 254H236C229.373 254 224 248.627 224 242V186Z"
      fill={fill}
    />
    <path
      d="M0 186C0 179.373 5.37258 174 12 174H68C74.6274 174 80 179.373 80 186V242C80 248.627 74.6274 254 68 254H12C5.37258 254 0 248.627 0 242V186Z"
      fill={fill}
    />
    <path
      d="M896 357C896 350.373 901.373 345 908 345H964C970.627 345 976 350.373 976 357V413C976 419.627 970.627 425 964 425H908C901.373 425 896 419.627 896 413V357Z"
      fill={fill}
    />
    <path
      d="M784 357C784 350.373 789.373 345 796 345H852C858.627 345 864 350.373 864 357V413C864 419.627 858.627 425 852 425H796C789.373 425 784 419.627 784 413V357Z"
      fill={fill}
    />
    <path
      d="M672 357C672 350.373 677.373 345 684 345H740C746.627 345 752 350.373 752 357V413C752 419.627 746.627 425 740 425H684C677.373 425 672 419.627 672 413V357Z"
      fill={fill}
    />
    <path
      d="M560 357C560 350.373 565.373 345 572 345H628C634.627 345 640 350.373 640 357V413C640 419.627 634.627 425 628 425H572C565.373 425 560 419.627 560 413V357Z"
      fill={fill}
    />
    <path
      d="M448 357C448 350.373 453.373 345 460 345H516C522.627 345 528 350.373 528 357V413C528 419.627 522.627 425 516 425H460C453.373 425 448 419.627 448 413V357Z"
      fill={fill}
    />
    <path
      d="M336 357C336 350.373 341.373 345 348 345H404C410.627 345 416 350.373 416 357V413C416 419.627 410.627 425 404 425H348C341.373 425 336 419.627 336 413V357Z"
      fill={fill}
    />
    <path
      d="M112 357C112 350.373 117.373 345 124 345H180C186.627 345 192 350.373 192 357V413C192 419.627 186.627 425 180 425H124C117.373 425 112 419.627 112 413V357Z"
      fill={fill}
    />
    <path
      d="M224 357C224 350.373 229.373 345 236 345H292C298.627 345 304 350.373 304 357V413C304 419.627 298.627 425 292 425H236C229.373 425 224 419.627 224 413V357Z"
      fill={fill}
    />
    <path
      d="M0 357C0 350.373 5.37258 345 12 345H68C74.6274 345 80 350.373 80 357V413C80 419.627 74.6274 425 68 425H12C5.37258 425 0 419.627 0 413V357Z"
      fill={fill}
    />
    <path
      d="M896 512C896 505.373 901.373 500 908 500H964C970.627 500 976 505.373 976 512V568C976 574.627 970.627 580 964 580H908C901.373 580 896 574.627 896 568V512Z"
      fill={fill}
    />
    <path
      d="M784 512C784 505.373 789.373 500 796 500H852C858.627 500 864 505.373 864 512V568C864 574.627 858.627 580 852 580H796C789.373 580 784 574.627 784 568V512Z"
      fill={fill}
    />
    <path
      d="M672 512C672 505.373 677.373 500 684 500H740C746.627 500 752 505.373 752 512V568C752 574.627 746.627 580 740 580H684C677.373 580 672 574.627 672 568V512Z"
      fill={fill}
    />
    <path
      d="M560 512C560 505.373 565.373 500 572 500H628C634.627 500 640 505.373 640 512V568C640 574.627 634.627 580 628 580H572C565.373 580 560 574.627 560 568V512Z"
      fill={fill}
    />
    <path
      d="M448 512C448 505.373 453.373 500 460 500H516C522.627 500 528 505.373 528 512V568C528 574.627 522.627 580 516 580H460C453.373 580 448 574.627 448 568V512Z"
      fill={fill}
    />
    <path
      d="M336 512C336 505.373 341.373 500 348 500H404C410.627 500 416 505.373 416 512V568C416 574.627 410.627 580 404 580H348C341.373 580 336 574.627 336 568V512Z"
      fill={fill}
    />
    <path
      d="M112 512C112 505.373 117.373 500 124 500H180C186.627 500 192 505.373 192 512V568C192 574.627 186.627 580 180 580H124C117.373 580 112 574.627 112 568V512Z"
      fill={fill}
    />
    <path
      d="M224 512C224 505.373 229.373 500 236 500H292C298.627 500 304 505.373 304 512V568C304 574.627 298.627 580 292 580H236C229.373 580 224 574.627 224 568V512Z"
      fill={fill}
    />
    <path
      d="M0 512C0 505.373 5.37258 500 12 500H68C74.6274 500 80 505.373 80 512V568C80 574.627 74.6274 580 68 580H12C5.37258 580 0 574.627 0 568V512Z"
      fill={fill}
    />
    <line x1="939" y1="80" x2="939" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="827" y1="80" x2="827" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="715" y1="80" x2="715" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="603" y1="80" x2="603" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="491" y1="80" x2="491" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="379" y1="80" x2="379" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="155" y1="80" x2="155" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="267" y1="80" x2="267" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="43" y1="80" x2="43" y2="174" stroke={fill} strokeWidth="6" />
    <line x1="939" y1="254" x2="939" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="827" y1="254" x2="827" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="715" y1="254" x2="715" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="603" y1="254" x2="603" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="491" y1="254" x2="491" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="379" y1="254" x2="379" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="155" y1="254" x2="155" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="267" y1="254" x2="267" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="43" y1="254" x2="43" y2="348" stroke={fill} strokeWidth="6" />
    <line x1="937" y1="406" x2="937" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="825" y1="406" x2="825" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="713" y1="406" x2="713" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="601" y1="406" x2="601" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="489" y1="406" x2="489" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="377" y1="406" x2="377" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="153" y1="406" x2="153" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="265" y1="406" x2="265" y2="500" stroke={fill} strokeWidth="6" />
    <line x1="41" y1="406" x2="41" y2="500" stroke={fill} strokeWidth="6" />
  </svg>
);

export default SingleItemSingleTargetIcon;
