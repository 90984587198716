import { Divider } from '@mui/material';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import UpdateTargetBids from './update-target-bids';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { TargetUpdateDto } from '../../../../types/target';
import IconButtonWithTooltip from '../../../../../shared/components/icon-button-with-tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

interface TargetBulkActionsProps {
  setIsLoading: (loading: boolean) => void;
  disableUpdateKeywordBids?: boolean;
}

const TargetsBulkActions = ({ setIsLoading, disableUpdateKeywordBids }: TargetBulkActionsProps) => {
  const apiRef = useGridApiContext();
  const { updateTargets } = useAmazonApi();

  const updateTargetState = async (state: 'paused' | 'enabled') => {
    setIsLoading(true);

    const selectedTargets = Array.from(apiRef.current.getSelectedRows().values());

    const targetRequestObjects: TargetUpdateDto[] = selectedTargets.map((target: any) => {
      return {
        targetId: target.targetId,
        state: state
      };
    });

    const response = await updateTargets(targetRequestObjects);

    if (response.success) {
      const updatedTargets = response.body;
      apiRef.current.updateRows(updatedTargets.map((target: any) => ({ targetId: target.targetId, state: target.state })));
    }

    apiRef.current.setRowSelectionModel([]);

    setIsLoading(false);
  };

  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (
    params: GridRowSelectionModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  return (
    <>
      <IconButtonWithTooltip
        handleClick={() => updateTargetState('enabled')}
        tooltipText={selectionModelHasRows ? 'Enable Targets' : 'Select one or more targets to enable actions'}
        icon={<PlayArrowIcon />}
        isDisabled={!selectionModelHasRows}
      />
      <IconButtonWithTooltip
        handleClick={() => updateTargetState('paused')}
        tooltipText={selectionModelHasRows ? 'Pause Targets' : 'Select one or more targets to enable actions'}
        icon={<PauseIcon />}
        isDisabled={!selectionModelHasRows}
      />
      <Divider orientation="vertical" flexItem />
      {!disableUpdateKeywordBids && (
        <UpdateTargetBids setIsLoading={setIsLoading} selectionModelHasRows={selectionModelHasRows} />
      )}
    </>
  );
};

export default TargetsBulkActions;
