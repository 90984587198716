import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Card, CircularProgress, Divider, Grid, List, ListItem, Typography, Collapse } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  ProfileAutocomplete,
  CampaignNameTextField,
  DateRangeSettings,
  CampaignTypeSettings,
  SBProfileBuilder
} from './campaign-settings-inputs';
import { CampaignBuilderStepLabelButton } from './step-label-button';

interface CampaignTypeStepProps {
  onNext?: () => void;
  onBack?: () => void;
  formik: any;
  profiles: any[];
}

export const CampaignSettingsStep: FC<CampaignTypeStepProps> = (props) => {
  const { onBack, onNext, formik, profiles, ...other } = props;

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const handleProfileChange = (event: any, value: any): void => {
    if (!value?.profileId) {
      searchParams.delete('profileId');
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...searchParams,
        profileId: `${value.profileId}`
      });
    }
  };

  return (
    <>
      {profiles.length > 0 ? (
        <Box sx={{ mb: showStep ? 10 : 1 }} {...other}>
          <CampaignBuilderStepLabelButton
            showStep={showStep}
            handleStepButtonClick={handleStepButtonClick}
            stepNumber={1}
            stepLabel="Campaign Settings"
          />
          <Collapse in={showStep}>
            <Card>
              <List>
                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Profile
                      </Grid>
                      <Grid item xs={9}>
                        <ProfileAutocomplete
                          profiles={profiles}
                          handleProfileChange={handleProfileChange}
                          profileId={profileId}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>
                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Campaign Name
                      </Grid>
                      <Grid item xs={9}>
                        <CampaignNameTextField formik={formik} />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>
                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Date Range
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="caption">*To schedule a campaign indefinitely leave end date empty.</Typography>
                          </Grid>
                          <Grid item>
                            <DateRangeSettings formik={formik} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>
                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Campaign Type
                      </Grid>
                      <Grid item xs={9}>
                        <CampaignTypeSettings formik={formik} />
                      </Grid>
                    </Grid>
                  </ListItem>
                </Box>
                {/* Taking out for now until Sam's supports sponsored brands */}
                {/* <Collapse in={formik.values.campaignType === 'sba'}>
                  <Divider />{' '}
                  <Box>
                    <ListItem sx={{ p: 2 }}>
                      <Grid container>
                        <Grid item xs={3}>
                          SB Profile <Box sx={{ fontWeight: 'bold' }}>(Optional)</Box>
                        </Grid>
                        <Grid item xs={9}>
                          <SBProfileBuilder formik={formik} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Box>
                </Collapse> */}
              </List>
            </Card>
          </Collapse>
        </Box>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

CampaignSettingsStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func
};
