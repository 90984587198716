import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { DataGridPremium, GridAggregationModel } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { avgNoLabel, sumNoLabel } from '../../../../shared/components/grid-components/aggregationFunctions';
import MetricsChart from '../../../../shared/components/metrics-chart';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';
import useDialog from '../../../../shared/hooks/use-dialog';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { AD_GROUP_COLUMNS } from '../../../components/grid-components/column-configurations/ad-group-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { UpdateAdGroupRequest } from '../../../types/ad-group';
import { AmazonCampaignType } from '../../../types/campaign';
import AddAdGroupsDialog from './create-dialogs/create-ad-groups-dialog';

interface OverviewAdGroupProps {
  campaignId: string | null;
  campaignType?: string;
  targetingType?: string;
  refreshCampaignOverview: () => Promise<void>;
}

interface AdGroupRow {
  adGroupId: string;
  name: string;
  state: string;
  defaultBid: number;
  syncState: string;
}

export const OverviewAdGroups = (props: OverviewAdGroupProps) => {
  const { campaignId, campaignType, targetingType, refreshCampaignOverview } = props;

  const [searchParams] = useSearchParams();

  let profileId = searchParams.get('profileId');

  const { getAdGroups, buildAdGroup, updateAdGroup } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [adGroups, setAdGroups] = useState<AdGroupRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

  const { isShowing: addAdGroupsDialogIsShowing, toggle: toggleAddAdGroupsDialog } = useDialog();

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_OVERVIEW_ADGROUPS,
    breadCrumbParams: { campaignId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback(async (newRow: AdGroupRow, oldRow: AdGroupRow) => {
    const updatedAdGroup: UpdateAdGroupRequest = {
      adGroupId: newRow.adGroupId,
      name: newRow.name,
      state: newRow.state,
      defaultBid: newRow.defaultBid < 0 ? 0 : newRow.defaultBid
    };

    const response = await updateAdGroup(updatedAdGroup);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    } else {
      enqueueSnackbar('Ad Group updated successfully', { variant: 'success' });
    }

    return response.body[0];
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ campaignId });
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  // When the page loads, put the scroll at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    const fetchAdGroups = async () => {
      setIsLoading(true);

      const response = await getAdGroups(pageable, true, dateSettings.beginDate, dateSettings.endDate);

      if (response.success) {
        setAdGroups(response.body.dataGrid.records);
        setRowCount(response.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);
        setTimeSeriesData(response.body?.timeSeriesData);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchAdGroups();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCreateAdGroupsButton = () => {
    if (!campaignType || campaignType !== AmazonCampaignType.SPONSORED_PRODUCTS) {
      return;
    }

    if (campaignType === AmazonCampaignType.SPONSORED_PRODUCTS) {
      return (
        <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleAddAdGroupsDialog()} sx={{ mb: '0.5rem' }}>
          Create Ad Group
        </Button>
      );
    }
  };

  return (
    <Box mb={'1rem'}>
      <Box display="flex" alignItems={'center'}>
        <Box
          sx={{
            mr: 'auto'
          }}
        ></Box>
        {renderCreateAdGroupsButton()}
      </Box>
      {!initialSettings || settingsLoading ? (
        <DataGridPremium
          rows={[]}
          columns={AD_GROUP_COLUMNS}
          loading={true}
          processRowUpdate={processRowUpdate}
          initialState={{}}
          key={uuidv4()}
          disableRowGrouping
        />
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: '1%' }}>
            <MetricsChart timeSeriesData={timeSeriesData} isMetricsChartLoading={isLoading} />
          </Box>
          <PerformanceGrid
            processRowUpdate={processRowUpdate}
            loading={isLoading}
            rows={adGroups}
            columns={AD_GROUP_COLUMNS}
            initialState={initialSettings?.config}
            saveGridConfig={saveGridConfig}
            dateConfig={{
              dateSettings: dateSettings,
              dateSettingsLoading: dateSettingsLoading,
              saveDateConfig: saveDateConfig
            }}
            getRowId={(row: any) => row.adGroupId}
            handleFilterModelChange={handleFilterModelChange}
            handleSortModelChange={handleSortModelChange}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
            bottomMargin={25}
            otherDataGridProps={{ autoHeight: true }}
            // TODO: this aggregation is temporary until Metrics Tiles are implemented (see VEC-365)
            customProps={{
              customAggregationFunctions: {
                sumNoLabel: sumNoLabel,
                avgNoLabel: avgNoLabel
              },
              customAggregationModel: {
                cost: 'sumNoLabel',
                attributedRevenue: 'sumNoLabel',
                returnOnAdSpend: 'avgNoLabel',
                impressions: 'sumNoLabel',
                clicks: 'sumNoLabel',
                clickThroughRate: 'avgNoLabel',
                units: 'sumNoLabel',
                orders: 'sumNoLabel',
                newToBrandOrders: 'sumNoLabel',
                newToBrandRevenue: 'sumNoLabel',
                newToBrandUnits: 'sumNoLabel',
                conversionRate: 'avgNoLabel',
                costPerClick: 'avgNoLabel',
                costPerConversion: 'avgNoLabel'
              } as GridAggregationModel
            }}
          />
        </>
      )}

      {addAdGroupsDialogIsShowing && (
        <AddAdGroupsDialog
          campaignId={campaignId}
          isShowing={addAdGroupsDialogIsShowing}
          toggle={toggleAddAdGroupsDialog}
          refreshAdGroups={refreshCampaignOverview}
          campaignType={campaignType || AmazonCampaignType.SPONSORED_PRODUCTS}
          targetingType={targetingType || 'MANUAL'}
        />
      )}
    </Box>
  );
};
