import { Badge, BadgeProps, Box, Chip, Tooltip, styled } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-premium';
import { BiddingStrategies, AmazonSponsoredProductsDynamicBidding } from '../../../../types/campaign';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}));

export const renderDynamicBiddingPreview = (params: GridCellParams) => {
  return <DynamicBiddingActionCell {...params} />;
};

export const DynamicBiddingActionCell = (params: GridCellParams) => {
  const amazonSponsoredProductsDynamicBidding = params.value as AmazonSponsoredProductsDynamicBidding;

  const renderDynamicBiddingValue = () => {
    const numberOfPlacementsOverZero = Object.values(amazonSponsoredProductsDynamicBidding.placementBidding).filter(
      (percentage) => percentage > 0
    );

    return (
      <StyledBadge badgeContent={numberOfPlacementsOverZero.length} color="info">
        <Chip
          label={
            BiddingStrategies[amazonSponsoredProductsDynamicBidding.strategy as keyof typeof BiddingStrategies].abbreviatedLabel
          }
          variant="outlined"
          size="small"
        />
      </StyledBadge>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whitespace: 'nowrap',
          color: 'text.primary',
          width: '100%'
        }}
      >
        {renderDynamicBiddingValue()}
      </Box>
    </Box>
  );
};
