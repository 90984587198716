import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import useDialog from '../../../shared/hooks/use-dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteBudgetGroupDialog } from './delete-budget-group-bulk-action';

interface BudgetGroupBulkActionsProps {
  refreshBudgetsView: () => void;
}

export const BudgetGroupBulkActions = (props: BudgetGroupBulkActionsProps) => {
  const { toggle: toggleDeleteBudgetGroupDialog, isShowing: deleteBudgetGroupDialogIsShowing, dynamicKey } = useDialog();

  const apiRef = useGridApiContext();

  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (
    params: GridRowSelectionModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  return (
    <>
      <Button startIcon={<DeleteIcon />} onClick={() => toggleDeleteBudgetGroupDialog()} disabled={!selectionModelHasRows}>
        Delete Budget Group
      </Button>
      <DeleteBudgetGroupDialog
        toggle={toggleDeleteBudgetGroupDialog}
        isShowing={deleteBudgetGroupDialogIsShowing}
        dynamicKey={dynamicKey}
        refreshBudgetsView={props.refreshBudgetsView}
      />
    </>
  );
};
