export const Platform = {
  AMAZON: { label: 'Amazon', value: 'amazon' },
  WALMART: { label: 'Walmart', value: 'walmart' },
  SAMS_CLUB: { label: "Sam's Club", value: 'sams-club', apiValue: 'SamsClub' },
  CITRUS_AD: { label: 'CitrusAd', value: 'citrusad' }
} as const;

export const PlatformValues = new Set(Object.values(Platform).map((platform) => platform.value));

export type PlatformValue = (typeof Platform)[keyof typeof Platform]['value'];
