import { useEffect, useState } from 'react';
import { IGridConfigSettingItem } from '../utilities/grid-config-settings';
import { useDateConfig } from './use-date-config';
import { useGridConfig } from './use-grid-config';
import { usePageable } from './use-pageable';

export const usePerformanceGridSettings = (props: { settingsKey: IGridConfigSettingItem; breadCrumbParams: any }) => {
  const { settingsKey, breadCrumbParams } = props;

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: settingsKey,
    breadCrumbParams: breadCrumbParams
  });
  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [performanceGridSettingsInitialized, setPerformanceGridSettingsInitialized] = useState(false);

  useEffect(() => {
    if (initialSettings && Object.keys(breadCrumbParams).length !== 0) {
      setBreadcrumbParams(breadCrumbParams);
    }
  }, [initialSettings, breadCrumbParams, setBreadcrumbParams]);

  useEffect(() => {
    if (!initialSettings || !initialSettings.pageable) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      setPerformanceGridSettingsInitialized(false);
    } else {
      setPerformanceGridSettingsInitialized(true);
    }
  }, [pageable, settingsLoading, dateSettingsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    performanceGridSettingsInitialized,

    initialSettings,
    saveGridConfig,

    pageable,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange,

    dateSettings,
    dateSettingsLoading,
    saveDateConfig
  };
};
