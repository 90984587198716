import { Box, Card, CardContent, Typography, Skeleton, LinearProgress } from '@mui/material';
import { formatAsCurrency } from '../../../shared/utilities/numeric-format-utilities';

interface MetricsTilesProps {
  isLoading: boolean;
  stats: any[];
  currentMonthSpend: number;
  monthlyBudget: number;
}

export const MetricsTiles = (props: MetricsTilesProps) => {
  const { isLoading, stats, currentMonthSpend, monthlyBudget } = props;
  const getPacingAsPercentOfBudget = () => {
    const numberOfDaysInCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    const averageSpendByDay = monthlyBudget / numberOfDaysInCurrentMonth;
    const projectedSpendToDate = averageSpendByDay * new Date().getDate();
    const asPercent = (projectedSpendToDate / monthlyBudget) * 100;

    return asPercent;
  };

  const getLinearProgressColor = () => {
    var spent = (currentMonthSpend / monthlyBudget) * 100;
    var pacing = getPacingAsPercentOfBudget();

    if (pacing - spent > 30) {
      return 'error';
    }

    if (pacing - spent > 10) {
      return 'warning';
    }

    return 'success';
  };

  return (
    <Box display="flex" justifyContent={'space-evenly'}>
      <Card sx={{ flexGrow: 1, mr: 2 }}>
        <CardContent>
          <Box display="flex" alignContent="center">
            <Typography variant="h6" sx={{ mb: 1, mr: 1 }}>
              {isLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                `$${(stats.reduce((acc: number, stat: any) => acc + stat?.todayAdSpend, 0) / stats.length).toFixed(2)}`
              )}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              (L7D)
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {isLoading ? <Skeleton variant="text" width={100} /> : 'Average Daily Spend'}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexGrow: 2, border: '1px black' }}>
        <CardContent>
          <Box display="flex" alignContent="center">
            <Typography variant="h6" sx={{ mb: 1, mr: 1 }}>
              {isLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                `${formatAsCurrency(currentMonthSpend, 0, 0)} of ${formatAsCurrency(monthlyBudget, 0, 0)} Spent` // format current month spend to currency
              )}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              (MTD)
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {isLoading ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <LinearProgress
                variant="buffer"
                color={getLinearProgressColor()}
                value={(currentMonthSpend / monthlyBudget) * 100}
                // assign value buffer to monthly budget divided by number of days in current month times current day number divided by monthly budget times 100
                valueBuffer={getPacingAsPercentOfBudget()}
                sx={{
                  '& .MuiLinearProgress-dashed': {
                    backgroundColor: 'lightgrey',
                    backgroundImage: 'none',
                    animation: 'none'
                  }
                }}
              />
            )}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {isLoading ? <Skeleton variant="text" width={100} /> : 'Budget Pacing (lighter line shows pacing target)'}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
