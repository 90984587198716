import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface CreateReviewDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const CreateReviewDialog = ({ open, onClose, onSave }: CreateReviewDialogProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Create Review</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a review</DialogContentText>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={onSave}>
            Submit Review
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
