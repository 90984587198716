import { useState } from 'react';
import { BidOptimizationGroupRow } from './bid-groups';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import { enqueueSnackbar } from 'notistack';

interface DeleteBidGroupDialogProps {
  isOpen: boolean;
  toggle: () => void;
  bidGroup: BidOptimizationGroupRow;
  onDelete: (bidGroupId: string) => Promise<ResponseObject>;
  removeBidGroupRow: (bidGroupId: string) => void;
}

const DeleteBidGroupDialog = ({ isOpen, toggle, bidGroup, onDelete, removeBidGroupRow }: DeleteBidGroupDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = async () => {
    setIsSubmitting(true);
    const response = await onDelete(bidGroup.id);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      enqueueSnackbar('Bid group deleted successfully', { variant: 'success' });
      removeBidGroupRow(bidGroup.id);
    }

    setIsSubmitting(false);
    toggle();
  };

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <DialogTitle>Delete Bid Group</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the bid group <strong>{bidGroup.name}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleDelete} color="error" disabled={isSubmitting}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBidGroupDialog;
