import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import InfoIcon from '@mui/icons-material/Info';
import { CustomTypeOptionRadioButton } from '../../../../../shared/components/custom-type-option-radio-button';
import { CurrencyInput } from '../../../../../shared/components/inputs/currency-input';
import { CampaignMode, TargetingMode } from '../../../../types/campaign-builder-request';
import { ItemSettings } from '../../campaign-builder/item-settings';
import { KeywordSettings } from '../../campaign-builder/keyword-settings';
import { NegativeKeywordSettings } from '../../campaign-builder/negative-keyword-settings';
import { ProductTargetingSettings } from '../../campaign-builder/product-and-category-targeting/product-targeting-settings';
import { createAdGroupBuilderRequest } from '../../../../types/ad-group';

interface AddAdGroupsDialogProps {
  campaignId: string | null;
  isShowing: boolean;
  toggle: () => void;
  refreshAdGroups: () => void;
  campaignType: string;
  targetingType: string;
}

const AddAdGroupsDialog = (props: AddAdGroupsDialogProps) => {
  const { campaignId, isShowing, toggle, refreshAdGroups, campaignType, targetingType } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { getCampaigns, buildAdGroup } = useAmazonApi();
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let profileId = searchParams.get('profileId');
  const [formValues, setFormValues] = useState<any | {}>({});

  const [productTargets, setProductTargets] = useState<any[]>([]);

  const [campaignMode, setCampaignMode] = useState<CampaignMode>(CampaignMode.MANUAL);
  const [targetingMode, setTargetingMode] = useState<TargetingMode>(TargetingMode.KEYWORD);

  const formikRef = useRef<any>(null);

  useEffect(() => {
    fetchCampaigns();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!targetingType) {
      return;
    }

    if (targetingType.toLowerCase() === 'manual') {
      setCampaignMode(CampaignMode.MANUAL);
    }

    if (targetingType.toLowerCase() === 'auto') {
      setCampaignMode(CampaignMode.AUTO);
    }
  }, [targetingType]);

  useEffect(() => {
    if (!targetingMode) {
      return;
    }

    formikRef?.current?.setFieldValue('targetingMode', targetingMode);
  }, [targetingMode]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const fetchCampaigns = async () => {
    setIsSubmitting(true);

    const response = await getCampaigns();

    if (response.success) {
      setCampaigns(response.body.dataGrid.records);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);

    const response = await buildAdGroup(createAdGroupBuilderRequest(values));

    if (response.success) {
      enqueueSnackbar(`Ad Group created successfully!`, { variant: 'success' });
      toggle();
      refreshAdGroups();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  return (
    <Box>
      <Formik
        initialValues={{
          campaignId: !!campaignId ? campaignId : 0,
          name: '',
          bid: 0,
          state: 'ENABLED',
          items: [],
          negativeKeywords: [],
          universalKeywords: []
        }}
        onSubmit={async (formValues) => {
          await handleSubmit(formValues);
        }}
      >
        {(formik) => {
          const { values, touched, errors, isSubmitting, isValid, dirty, setFieldValue, handleBlur } = formik;
          formikRef.current = formik;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setFormValues(values);
          }, [values]);

          return (
            <Form>
              <Dialog open={isShowing} title="Create Ad Group" maxWidth="xl" fullWidth={true} disablePortal>
                <DialogContent>
                  <Container maxWidth="lg">
                    <Grid container spacing={2} direction={'column'}>
                      <Grid item>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <h1>Ad Group Builder</h1>
                          <Tooltip
                            title={
                              <Typography variant="caption">
                                This will create a new ad group in the selected campaign. All items targets will be added to a
                                single ad group.
                              </Typography>
                            }
                          >
                            <InfoIcon sx={{ ml: 2 }} />
                          </Tooltip>
                        </Box>
                        <Card>
                          <CardContent>
                            <Typography sx={{ mb: 2 }}>Ad Group Settings</Typography>
                            <Box>
                              <TextField
                                type="text"
                                name="name"
                                label="Ad Group Name"
                                value={values.name}
                                onChange={(e) => setFieldValue('name', e.target.value)}
                                onBlur={handleBlur}
                                size="small"
                                sx={{ mr: 2 }}
                              />
                              <CurrencyInput
                                type="text"
                                name="bid"
                                label="Bid"
                                value={values.bid}
                                onChange={(e) => setFieldValue('bid', e.target.value)}
                                onBlur={handleBlur}
                                size="small"
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <ItemSettings values={values} setFieldValue={setFieldValue} profileId={profileId} />
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              Targeting Settings
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Chip label={campaignMode === CampaignMode.AUTO ? 'Auto' : 'Manual'} />
                              </Grid>
                            </Grid>
                            <Collapse in={campaignMode === CampaignMode.MANUAL} sx={{ mt: 2 }}>
                              <Typography gutterBottom variant="body1" component="div">
                                Manual Targeting Type
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <CustomTypeOptionRadioButton
                                    typeOptionValue={'keyword'}
                                    typeOptionLabel={'Keyword'}
                                    checked={targetingMode === TargetingMode.KEYWORD}
                                    handleClick={(): void => setTargetingMode(TargetingMode.KEYWORD)}
                                    sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <CustomTypeOptionRadioButton
                                    typeOptionValue={'product'}
                                    typeOptionLabel={'Product Targeting'}
                                    checked={targetingMode === TargetingMode.PRODUCT}
                                    handleClick={(): void => setTargetingMode(TargetingMode.PRODUCT)}
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Grid>
                              </Grid>
                            </Collapse>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    {campaignMode === CampaignMode.MANUAL && targetingMode === TargetingMode.KEYWORD && (
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <KeywordSettings
                            profileId={profileId}
                            itemStepType={'allItems'} // only allow "allItems", exclude the "itemCarousel" option
                            handleItemStepTypeChange={() => {}}
                            values={values}
                            setFieldValue={setFieldValue}
                            disableItemCarousel={true}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {campaignMode === CampaignMode.MANUAL && targetingMode === TargetingMode.PRODUCT && (
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <ProductTargetingSettings
                            profileId={profileId}
                            values={values}
                            setFieldValue={setFieldValue}
                            productTargets={productTargets}
                            setProductTargets={setProductTargets}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {(targetingMode === TargetingMode.KEYWORD || campaignMode === CampaignMode.AUTO) && (
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <NegativeKeywordSettings values={values} setFieldValue={setFieldValue} />
                        </Grid>
                      </Grid>
                    )}
                  </Container>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={() => toggle()}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" disabled={!isValid || isSubmitting || !dirty}>
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default AddAdGroupsDialog;
