import { Box, Container, Stack, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { chunk, uniq } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import TargetsBulkActions from '../../components/grid-components/bulk-actions/targets/targets-bulk-actions';
import { createColumns } from '../../components/grid-components/column-configurations/target-columns';
import { useAmazonApi } from '../../hooks/use-amazon-api';
import { TargetUpdateDto } from '../../types/target';
import { AmazonExpressionTypes } from '../../types/targeting-refinements';
import MetricsChart from '../../../shared/components/metrics-chart';
import { v4 as uuidv4 } from 'uuid';

const Targets: FC = () => {
  const { getTargets, updateTarget, searchCatalogItems } = useAmazonApi();

  const { enqueueSnackbar } = useSnackbar();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [targets, setTargets] = useState<any[]>([]);
  const [productInfo, setProductInfo] = useState<any[] | null>(null);
  const [isLoadingProductInfo, setIsLoadingProductInfo] = useState<boolean>(true);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');
  let campaignId = searchParams.get('campaignId');

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_TARGETS,
    breadCrumbParams: { profileId, campaignId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    let updateTargetsRequest: TargetUpdateDto = { targetId: newRow.targetId };

    if (newRow.bid !== oldRow.bid) {
      updateTargetsRequest = { ...updateTargetsRequest, bid: newRow.bid };
    }

    if (newRow.state !== oldRow.state) {
      updateTargetsRequest = { ...updateTargetsRequest, state: newRow.state };
    }

    if (updateTargetsRequest.bid == null && updateTargetsRequest.state == null) {
      return oldRow;
    }

    const response = await updateTarget(updateTargetsRequest);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ profileId, campaignId });
  }, [profileId, campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProductInfo = async () => {
    setIsLoadingProductInfo(true);

    const asins = targets
      .map((target) => {
        return (
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinSameAs.toLowerCase())?.value ??
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinExpandedFrom.toLowerCase())
            ?.value
        );
      })
      .filter((asin: any) => asin);

    const uniqueAsins = uniq(asins);
    if (uniqueAsins.length < 1) {
      setIsLoadingProductInfo(false);
      return;
    }

    const chunkedAsins = chunk(uniqueAsins, 20);

    const requests = chunkedAsins.map((asins) => {
      return searchCatalogItems(asins);
    });

    const responses = await Promise.all(requests);

    const combinedProductInfo = responses.reduce((acc: any, response: any) => {
      return [...acc, ...response.body];
    }, []);

    setProductInfo(combinedProductInfo);
    setIsLoadingProductInfo(false);
  };

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    const fetchTargets = async () => {
      if (!pageable) {
        return;
      }

      setIsLoading(true);

      const response = await getTargets(pageable, true, dateSettings.beginDate, dateSettings.endDate);

      if (response.success) {
        setTargets(response.body.dataGrid.records);
        setRowCount(response.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);
        setTimeSeriesData(response.body?.timeSeriesData);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchTargets();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (targets?.length < 1) {
      return;
    }

    fetchProductInfo();
  }, [targets]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = React.useMemo(() => {
    return createColumns(productInfo, isLoadingProductInfo);
  }, [productInfo, isLoadingProductInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Targets">
      <Container maxWidth="xl" style={{ height: '93vh' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Typography variant="h5" mr={2}>
            Targets
          </Typography>
          <Box display="flex" alignItems={'center'} my={'1%'}>
            <MetricsChart timeSeriesData={timeSeriesData} isMetricsChartLoading={isLoading} />
          </Box>
          <Box height={'800px'}>
            {!initialSettings || settingsLoading ? (
              <DataGridPremium
                rows={[]}
                columns={columns}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
                disableRowGrouping
              />
            ) : (
              <PerformanceGrid
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={targets}
                columns={columns}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  saveDateConfig: saveDateConfig
                }}
                bottomMargin={30}
                getRowId={(row: any) => row.targetId}
                bulkActions={<TargetsBulkActions setIsLoading={setIsLoading} />}
                handleFilterModelChange={handleFilterModelChange}
                handleSortModelChange={handleSortModelChange}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                rowCount={rowCount}
              />
            )}
          </Box>
        </Stack>
      </Container>
    </Page>
  );
};

export default Targets;
