import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, Tooltip } from '@mui/material';
import { useState } from 'react';
import { ExtendedGridRenderCellParams } from '../../../types/render-cell-types';
import { LinkCell } from '../linkCell';

interface LinkCellWithVerticalEllipsisProps extends ExtendedGridRenderCellParams {
  linkUrl: string;
  text?: string;
  actionMenuItems?: JSX.Element[];
  closeActionMenuOnClick?: boolean;
}

export const LinkCellWithVerticalEllipsis = (props: LinkCellWithVerticalEllipsisProps) => {
  const { actionMenuItems, closeActionMenuOnClick, linkUrl, text, value } = props;
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);

  const linkCellText = text ?? value;

  const handleActionMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuAnchorEl(actionMenuAnchorEl ? null : event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
    setIsHovering(false);
  };

  const open = Boolean(actionMenuAnchorEl);

  let menuItems = actionMenuItems !== undefined ? [...actionMenuItems] : [];

  if (closeActionMenuOnClick) {
    menuItems = menuItems.map((menuItem: any) => {
      return {
        ...menuItem,
        props: {
          ...menuItem.props,
          onClick: () => {
            menuItem.props.onClick();
            handleActionMenuClose();
          }
        }
      };
    });
  }

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        width: '100%',
        height: '100%',
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <LinkCell linkUrl={linkUrl} value={linkCellText} />
      {isHovering && menuItems.length > 0 ? (
        <>
          {' '}
          <Box>
            <Tooltip title="Actions" placement="top">
              <IconButton onClick={handleActionMenuIconClick} size="small" color="primary">
                <MoreVertIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            id="action-menu"
            anchorEl={actionMenuAnchorEl}
            open={open}
            onClose={handleActionMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {menuItems.map((menuItem: any) => menuItem)}
          </Menu>
        </>
      ) : null}
    </Box>
  );
};
