import { Button, Card, CircularProgress, ListItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

interface Brand {
  id: string;
  name: string;
}

interface BrandSearchResults {
  brands: Brand[];
  handleExcludeBrand: (brand: Brand) => void;
  addedNegativeTargetingClauses?: any;
  isLoading?: boolean;
  height?: number;
}

function renderRow(
  { index, style }: ListChildComponentProps,
  searchResults: any,
  addedNegativeTargetingClauses: any,
  handleExcludeBrand: any
) {
  const brand = searchResults[index];

  const isDisabled = addedNegativeTargetingClauses?.some((negativeTargetingClause: any) => {
    return negativeTargetingClause.expression[0].value === brand.id;
  });

  return (
    <>
      <ListItem style={style} key={brand.id} divider>
        <Box display="flex" justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              width: '200px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontSize: '12px'
            }}
          >
            {brand.name}
          </Typography>
          <Button sx={{ fontSize: '11px' }} size="small" onClick={() => handleExcludeBrand(brand)} disabled={isDisabled}>
            Exclude
          </Button>
        </Box>
      </ListItem>
    </>
  );
}

export default function BrandSearchResultsList(props: BrandSearchResults) {
  const { brands, handleExcludeBrand, addedNegativeTargetingClauses, isLoading, height } = props;

  if (isLoading) {
    return (
      <Card
        sx={{ width: '100%', height: height ?? 380, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        variant="outlined"
      >
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card sx={{ width: '100%', height: height ?? 380 }} variant="outlined">
      <FixedSizeList height={380} width="100%" itemSize={40} itemCount={brands.length}>
        {(props) => renderRow(props, brands, addedNegativeTargetingClauses, handleExcludeBrand)}
      </FixedSizeList>
    </Card>
  );
}
