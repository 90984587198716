import { API_AUTH_SCOPE, API_ROOT } from '../../config';
import { useAuth } from '../../shared/hooks/use-auth';
import { Pageable } from '../../shared/types/pageable';
import { Platform } from '../../shared/types/platforms';
import {
  ResponseObject,
  executeDeleteRequest,
  executeGetRequest,
  executePostRequest,
  executePutRequest
} from '../../shared/utilities/fetch-utilities';
import { OptimizeTargetBidsDto, UpdateTargetBidsDto } from '../../shared/types/update-bids';
import { buildPageableParams, serializeParams } from '../../shared/utilities/url-utilities';
import { AdGroupBuilderCreateDto, UpdateAdGroupRequest } from '../types/ad-group';
import { AdUpdateDto, UnifiedAdBuilderRequest } from '../types/ads';
import { UpdateCampaignRequest } from '../types/campaign';
import { CampaignBuilderRequest } from '../types/campaign-builder-request';
import { AsinKeywordRecommendationRequest, AsinRecommendationRequest, KeywordRecommendationRequestBase } from '../types/keyword';
import {
  CampaignNegativeTargetBuilderRequest,
  CampaignNegativeTargetUpdateDto,
  NegativeTargetBuilderRequest,
  NegativeTargetUpdateDto
} from '../types/negative-target';
import { ProductMetadataRequest } from '../types/product-metadata';
import {
  CategoryTargetRecommendationRequest,
  ProductTargetRecommendationRequest,
  TargetCreateDto,
  TargetUpdateDto
} from '../types/target';
import {
  AddCampaignsToBudgetGroupRequestDto,
  CreateBudgetGroupWithCampaignsRequestDto,
  UpdateOrCreateProfileAndBudgetGroupBudgetsRequestDto
} from '../types/budget-group';
import {
  AddCampaignsToBidGroupRequestDto,
  BidOptimizationGroupCreateDto,
  BidOptimizationGroupDto
} from '../types/bid-optimization-group';
import { AmazonProfileCreationPayload } from '../../shared/components/profile-creation-modal/profile-creation-objects';

export const useAmazonApi = () => {
  const { acquireToken } = useAuth();

  const getProfiles = async (pageable: Pageable, beginDate?: string | null, endDate?: string | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/profiles/getAllWithMetrics${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getProfilesWithoutMetrics = async (): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/profiles/GetAll`,
      authToken: authResponse.accessToken
    });
  };

  const getPlacementMetrics = async (
    pageable?: Pageable,
    campaignId?: string | null,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ campaignId, beginDate, endDate, shouldIncludeChartMetrics }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaigns/getPlacementMetrics${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaigns = async (
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate, shouldIncludeChartMetrics }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaigns/getAllWithMetrics${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getProfile = async (profileId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/profiles/getById/${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaign = async (campaignId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaigns/getById/${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const getAdGroup = async (adGroupId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/adGroups/getById/${adGroupId}`,
      authToken: authResponse.accessToken
    });
  };

  const getAd = async (adId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/ads/getById/${adId}`,
      authToken: authResponse.accessToken
    });
  };

  const getTarget = async (targetId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/targets/getById/${targetId}`,
      authToken: authResponse.accessToken
    });
  };

  const getNegativeTarget = async (negativeTargetId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/negativeTargets/getById/${negativeTargetId}`,
      authToken: authResponse.accessToken
    });
  };

  const updateNegativeTarget = async (requestBody: NegativeTargetUpdateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/negativeTargets/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const updateCampaign = async (requestBody: UpdateCampaignRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/campaigns/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const updateCampaigns = async (requestBody: UpdateCampaignRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/campaigns/updateRange`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const buildCampaign = async (profileId: string | null, requestBody: CampaignBuilderRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/campaignBuilder/buildCampaign?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const buildCampaignPreview = async (profileId: string | null, requestBody: CampaignBuilderRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/campaignBuilder/createCampaignPreview?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getCampaignOverview = async (campaignId: string) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/views/campaignOverview?campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const getAdGroups = async (
    pageable: Pageable,
    shouldIncludeChartMetrics: boolean,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate, shouldIncludeChartMetrics }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/adGroups/getAllWithMetrics${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getAds = async (pageable: Pageable, beginDate?: string | null, endDate?: string | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/ads/getAllWithMetrics${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const updateAd = async (requestBody: AdUpdateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/ads/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getProductMetadata = async (
    profileId: string,
    requestData: ProductMetadataRequest,
    pageable?: Pageable
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams(requestData, pageable);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passthrough/getProductMetaData?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: requestData
    });
  };

  const getTargets = async (
    pageable: Pageable,
    shouldIncludeChartMetrics: boolean,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate, shouldIncludeChartMetrics }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/targets/getAllWithMetrics${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const updateTarget = async (requestBody: TargetUpdateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/targets/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const updateTargets = async (requestBody: TargetUpdateDto[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/targets/updateRange`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const updateTargetBids = async (requestBody: UpdateTargetBidsDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/targets/updateBids`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const optimizeTargetBids = async (requestBody: OptimizeTargetBidsDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/unified/targets/optimizeBids`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const optimizeTargetBidsPreview = async (requestBody: OptimizeTargetBidsDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/unified/targets/optimizeBidsPreview`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const createTargets = async (requestBody: TargetCreateDto[], discoveredBy?: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/unified/targets${discoveredBy ? `?discoveredBy=${discoveredBy}` : ''}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getKeywords = async (pageable: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({}, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/keywords${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getAsinKeywordRecommendations = async (
    profileId: string,
    AsinKeywordRecommendationRequest: AsinKeywordRecommendationRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passThrough/getAsinKeywordRecommendations?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: AsinKeywordRecommendationRequest
    });
  };

  const getAdGroupKeywordRecommendations = async (
    profileId: string,
    adGroupId: string,
    KeywordRecommendationRequestBase: KeywordRecommendationRequestBase
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/recommendations/keywords/adGroup?profileId=${profileId}&adGroupId=${adGroupId}&suggestionSort=${KeywordRecommendationRequestBase.sortDimension}`,
      authToken: authResponse.accessToken
    });
  };

  const searchBrands = async (profileId: string, searchTerm: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passThrough/searchBrands?profileId=${profileId}&searchTerm=${searchTerm}`,
      authToken: authResponse.accessToken
    });
  };

  const getNegativeBrandSuggestions = async (profileId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passThrough/getNegativeBrandSuggestions?profileId=${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignNegativeTargets = async (pageable: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({}, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaignNegativeTargets/getAll${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const updateCampaignNegativeTarget = async (requestBody: CampaignNegativeTargetUpdateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/campaignNegativeTargets/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getNegativeTargets = async (pageable: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({}, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/negativeTargets/getAll${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const createNegativeTargets = async (requestBody: NegativeTargetBuilderRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/unified/negativeTargets/createRange`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const createCampaignNegativeTargets = async (requestBody: CampaignNegativeTargetBuilderRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/unified/campaignNegativeTargets/createRange`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const buildAdGroup = async (requestBody: AdGroupBuilderCreateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/campaignBuilder/buildAdGroup`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const updateAdGroup = async (requestBody: UpdateAdGroupRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/adGroups/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const createAds = async (requestBody: UnifiedAdBuilderRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/unified/ads/createRange`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getProductTargetRecommendations = async (
    profileId: string | null,
    isSponsoredProductsCampaignBuilderRequest: boolean | undefined,
    requestBody: ProductTargetRecommendationRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/recommendations/productTargeting?profileId=${profileId}&isSponsoredProductsCampaignBuilderRequest=${isSponsoredProductsCampaignBuilderRequest}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getCategoryTargetRecommendations = async (
    profileId: string | null,
    isSponsoredProductsCampaignBuilderRequest: boolean | undefined,
    requestBody: CategoryTargetRecommendationRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/recommendations/categoryTargeting?profileId=${profileId}&isSponsoredProductsCampaignBuilderRequest=${isSponsoredProductsCampaignBuilderRequest}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getCategoryRefinements = async (profileId: string | null, categoryId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passthrough/getCategoryRefinements?profileId=${profileId}&categoryId=${categoryId}`,
      authToken: authResponse.accessToken
    });
  };

  const getAsinRecommendations = async (
    profileId: number | null,
    requestBody: AsinRecommendationRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/keywords/asinRecommendations?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const searchCatalogItems = async (asins: string[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passthrough/searchCatalogItems`,
      authToken: authResponse.accessToken,
      body: asins
    });
  };

  const searchCatalogItemsKeywords = async (searchQuery: string, pageToken?: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({ searchQuery, pageToken });

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passthrough/searchCatalogItemsKeywords${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getProducts = async (
    pageable: Pageable,
    profileId: string,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Amazon/AmazonProducts/GetProducts/${profileId}${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getAssetUrls = async (profileId: string, assetId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Amazon/Passthrough/GetAssets?profileId=${profileId}&assetId=${assetId}`,
      authToken: authResponse.accessToken
    });
  };

  const saveAutomatedAction = async (requestBody: any): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/AutomatedActions`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const deleteAutomatedAction = async (profileId: string, automatedActionId: any): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeDeleteRequest({
      endpoint: `${API_ROOT}/Unified/AutomatedActions?profileId=${profileId}&automatedActionId=${automatedActionId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCronExpression = async (scheduleDescription: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AutomatedActions/GenerateCronExpression?scheduleDescription=${scheduleDescription}`,
      authToken: authResponse.accessToken
    });
  };

  const getAutomatedActions = async (profileId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AutomatedActions?profileId=${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getDaypartingAction = async (profileId: string, campaignId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AutomatedActions/GetDaypartingAction?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignAuditLogs = async (
    pageable?: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null,
    timezone?: string | 'Central Standard Time'
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate, timezone }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AuditLog/Campaigns${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const updateAutomatedAction = async (requestBody: any): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/Unified/AutomatedActions`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getAdGroupAuditLogs = async (
    pageable?: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AuditLog/AdGroups${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getAdAuditLogs = async (
    pageable?: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AuditLog/Ads${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getTargetsAuditLogs = async (
    pageable?: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AuditLog/Targets${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getNegativeTargetsAuditLogs = async (
    pageable?: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AuditLog/NegativeTargets${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getSearchTermReportByAsin = async (
    adGroupId: string,
    pageable: Pageable,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/AdGroups/GetSearchTermReportByAsinOfAdGroup?adGroupId=${adGroupId}${
        params ? `&${params}` : ''
      }`,
      authToken: authResponse.accessToken
    });
  };

  const getOutOfBudgetHours = async (campaignId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Campaigns/GetCampaignsLast7DOutOfBudgetHour?campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const getBudgetsViewData = async (profileId: string, year: Number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Budget/GetBudgetsViewData?profileId=${profileId}&year=${year}`,
      authToken: authResponse.accessToken
    });
  };

  const updateOrCreateProfileAndBudgetGroupBudgets = async (
    requestBody: UpdateOrCreateProfileAndBudgetGroupBudgetsRequestDto
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/Unified/Budget/UpdateOrCreateProfileAndBudgetGroupBudgets`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getProfileBudgetMetrics = async (profileId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Budget/GetProfileBudgetMetrics?profileId=${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignBudgetGroupMetrics = async (profileId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Budget/GetCampaignBudgetGroupMetrics?profileId=${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignBudgetGroups = async (profileId: string, year: Number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Budget/GetCampaignBudgetGroups?profileId=${profileId}&year=${year}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignBudgetGroupOverview = async (campaignBudgetGroupId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Budget/GetCampaignBudgetGroupOverview?campaignBudgetGroupId=${campaignBudgetGroupId}`,
      authToken: authResponse.accessToken
    });
  };

  const createBudgetGroup = async (profileId: string, budgetGroupName: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/Budget/CreateBudgetGroup?profileId=${profileId}&name=${budgetGroupName}`,
      authToken: authResponse.accessToken
    });
  };

  const createBudgetGroupWithCampaigns = async (
    profileId: string,
    requestBody: CreateBudgetGroupWithCampaignsRequestDto
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/Budget/CreateBudgetGroupWithCampaigns?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getBudgetGroups = async (profileId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Budget/GetBudgetGroups?profileId=${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const addCampaignsToBudgetGroup = async (requestBody: AddCampaignsToBudgetGroupRequestDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/Budget/AddCampaignsToBudgetGroup`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getCampaignHourlyMetrics = async (campaignId: string, beginDate: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Campaigns/GetCampaignHourlyMetrics?campaignId=${campaignId}&beginDate=${beginDate}`,
      authToken: authResponse.accessToken
    });
  };

  const removeCampaignsFromBudgetGroup = async (campaignIds: string[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/Budget/RemoveCampaignsFromBudgetGroup`,
      authToken: authResponse.accessToken,
      body: campaignIds
    });
  };

  const deleteBudgetGroups = async (campaignBudgetGroupIds: string[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeDeleteRequest({
      endpoint: `${API_ROOT}/Unified/Budget/DeleteBudgetGroups`,
      authToken: authResponse.accessToken,
      body: campaignBudgetGroupIds
    });
  };

  const getWastedSearchQuerySpend = async (profileId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Insights/GetWastedSearchQuerySpend?profileId=${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getBidOptimizationGroups = async (profileId: string, pageable?: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ profileId }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetAll${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getBidOptimizationGroup = async (bidGroupId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetById/${bidGroupId}`,
      authToken: authResponse.accessToken
    });
  };

  const getBidOptimizationGroupCampaigns = async (bidGroupId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetBidGroupCampaigns/${bidGroupId}`,
      authToken: authResponse.accessToken
    });
  };

  const createBidOptimizationGroup = async (requestBody: BidOptimizationGroupCreateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/Create`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const updateBidOptimizationGroup = async (requestBody: BidOptimizationGroupDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/Update/${requestBody.id}`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const deleteBidOptimizationGroup = async (bidGroupId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeDeleteRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/Delete/${bidGroupId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignsWithoutBidGroup = async (
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetCampaignsWithoutBidGroup/${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const addCampaignsToBidGroup = async (requestBody: AddCampaignsToBidGroupRequestDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/AddCampaigns`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const removeCampaignsFromBidGroup = async (campaignIds: string[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/RemoveCampaigns`,
      authToken: authResponse.accessToken,
      body: campaignIds
    });
  };

  const createProfile = async (requestBody: AmazonProfileCreationPayload): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/Unified/Profiles/Create`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getBidOptimizationTargetHistory = async (
    pageable: Pageable,
    beginDate?: string,
    endDate?: string
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetTargetHistory${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getBidOptimizationPlacementHistory = async (
    pageable: Pageable,
    beginDate?: string,
    endDate?: string
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetPlacementHistory${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  return {
    getProfiles,
    createProfile,
    getProfilesWithoutMetrics,
    getPlacementMetrics,
    getCampaigns,
    getProfile,
    getCampaign,
    getAdGroup,
    getAd,
    getTarget,
    getNegativeTarget,
    updateCampaign,
    updateCampaigns,
    optimizeTargetBidsPreview,
    buildCampaign,
    getCampaignOverview,
    buildCampaignPreview,
    getAdGroups,
    getAds,
    updateAd,
    getTargets,
    updateTarget,
    updateTargets,
    updateTargetBids,
    optimizeTargetBids,
    createTargets,
    getKeywords,
    getAsinKeywordRecommendations,
    getAdGroupKeywordRecommendations,
    getCampaignNegativeTargets,
    updateCampaignNegativeTarget,
    getNegativeTargets,
    updateNegativeTarget,
    getProductMetadata,
    createNegativeTargets,
    createCampaignNegativeTargets,
    buildAdGroup,
    updateAdGroup,
    createAds,
    getProductTargetRecommendations,
    getCategoryTargetRecommendations,
    getCategoryRefinements,
    searchBrands,
    getNegativeBrandSuggestions,
    getAsinRecommendations,
    searchCatalogItems,
    searchCatalogItemsKeywords,
    getProducts,
    getAssetUrls,
    saveAutomatedAction,
    getCronExpression,
    getAutomatedActions,
    updateAutomatedAction,
    deleteAutomatedAction,
    getDaypartingAction,
    getCampaignAuditLogs,
    getAdGroupAuditLogs,
    getAdAuditLogs,
    getTargetsAuditLogs,
    getNegativeTargetsAuditLogs,
    getSearchTermReportByAsin,
    getOutOfBudgetHours,
    getBudgetsViewData,
    getCampaignHourlyMetrics,
    updateOrCreateProfileAndBudgetGroupBudgets,
    getProfileBudgetMetrics,
    getCampaignBudgetGroupMetrics,
    getCampaignBudgetGroups,
    getCampaignBudgetGroupOverview,
    createBudgetGroup,
    createBudgetGroupWithCampaigns,
    getBudgetGroups,
    addCampaignsToBudgetGroup,
    removeCampaignsFromBudgetGroup,
    deleteBudgetGroups,
    getWastedSearchQuerySpend,
    getBidOptimizationGroups,
    getBidOptimizationGroup,
    getBidOptimizationGroupCampaigns,
    createBidOptimizationGroup,
    updateBidOptimizationGroup,
    deleteBidOptimizationGroup,
    getCampaignsWithoutBidGroup,
    addCampaignsToBidGroup,
    removeCampaignsFromBidGroup,
    getBidOptimizationTargetHistory,
    getBidOptimizationPlacementHistory
  };
};

export default useAmazonApi;
