import { Card, CardActionArea, CardContent, Typography, Box, useTheme } from '@mui/material';
import { UserSelectedCampaignType } from '../../../types/campaign-builder-request';

export interface RadioCardProps {
  title: string;
  text: string;
  Icon: React.ComponentType<{ fill: string }>;
  value: UserSelectedCampaignType;
  selectedValue: UserSelectedCampaignType;
  onValueChange: (value: UserSelectedCampaignType) => void;
}

const RadioCard: React.FC<RadioCardProps> = ({ title, text, Icon, value, selectedValue, onValueChange }) => {
  const isSelected = value === selectedValue;

  const handleOnClick = () => {
    onValueChange(value);
  };

  const theme = useTheme();

  const cardStyle = isSelected
    ? { border: `1px solid ${theme.palette.primary.main}` }
    : { border: `1px solid ${theme.palette.neutral?.[400]}` };

  const svgFill = isSelected ? theme.palette.primary.main : theme.palette.text.secondary;

  return (
    <Card style={cardStyle} onClick={handleOnClick}>
      <CardActionArea>
        <CardContent>
          <Box>
            <Icon fill={svgFill} />
            <Typography gutterBottom variant="h6" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {text}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}></Box>
    </Card>
  );
};

export default RadioCard;
