import { Box, Button, List, ListItem, ListItemText, Paper, Skeleton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useDialog from '../../../../../shared/hooks/use-dialog';
import { useAmazonApi } from '../../../../hooks/use-amazon-api';
import { CategoryRefinementDialog } from './catgeory-refinement-dialog';
import { allRefinementsMatch, buildAddedTargetListItemFromRecommendation, getAsinCountText } from './targeting-utils';
import { AmazonCampaignBuilderItemDto } from '../../../../types/campaign-builder-request';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { CategoryRecommendationDto } from '../../../../types/target';

interface CategoryListItemProps {
  category: any;
  handleAddTargets: (targets: any) => void;
  targets: any[];
  existingTargets: any[];
  profileId: string | null;
  bid: number;
}

const CategoryListItem = (props: CategoryListItemProps) => {
  const { category, handleAddTargets, targets, existingTargets, profileId, bid } = props;
  const { getCategoryRefinements } = useAmazonApi();
  const [suggestedRefinements, setSuggestedRefinements] = useState<{ ageRanges: any[]; brands: any[]; genres: any[] }>();
  const { toggle, isShowing } = useDialog();

  useEffect(() => {
    if (!category || !category.id) return;

    const fetchRefinements = async () => {
      const result = await getCategoryRefinements(profileId, category.id);
      setSuggestedRefinements(result.body || []);
    };

    fetchRefinements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  function handleAddTarget() {
    const newTargetClause = buildAddedTargetListItemFromRecommendation(category, 'category', bid);

    // Checking against temporary targets (to be added)
    const isDuplicateInTargets = targets.some(
      (target) => target.key === newTargetClause.key && allRefinementsMatch(target.refinements, newTargetClause.refinements)
    );

    // Checking against existing targets (already in the campaign)
    const isDuplicateInExistingTargets = existingTargets.some((existingTarget) => {
      const targetExistsOnCampaign = allRefinementsMatch(existingTarget.expression, newTargetClause.targetingClause.expression);

      if (targetExistsOnCampaign) {
        enqueueSnackbar(`Target already exists on campaign: ${newTargetClause.categoryPath}`, {
          variant: 'warning'
        });
      }

      return targetExistsOnCampaign;
    });

    if (isDuplicateInTargets || isDuplicateInExistingTargets) {
      return; // Don't add as it's a duplicate
    }

    handleAddTargets([newTargetClause]);
  }

  return (
    <ListItem>
      <ListItemText
        primary={
          <Tooltip title={category.categoryPath}>
            <Typography variant="body2" noWrap>
              {category.categoryPath}
            </Typography>
          </Tooltip>
        }
        secondary={
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              <strong>Category: </strong>
              {category.name}
            </Typography>
            <br />
            <Typography component="span" variant="body2" color="textSecondary">
              {getAsinCountText(category.asinCounts)}
            </Typography>
          </>
        }
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', ml: 2 }}>
        <Button size="small" onClick={handleAddTarget}>
          Add
        </Button>
        {!!category.id && ( // if we don't have an ID, we can't refine (that means it's an SB Category Target Recommendation)
          <Button size="small" onClick={toggle}>
            Refine
          </Button>
        )}
      </Box>
      {isShowing && (
        <CategoryRefinementDialog
          isOpen={isShowing}
          toggle={toggle}
          category={category}
          suggestedRefinements={suggestedRefinements || null}
          handleAddTargets={handleAddTargets}
          targets={targets}
          bid={bid}
        />
      )}
    </ListItem>
  );
};

interface CategoryRecommendationsProps {
  profileId: string | null;
  campaignId: string | null;
  asins: any[];
  handleAddTargets: (targets: any) => void;
  targets: any[];
  existingTargets?: any[];
  categoryRecommendations: CategoryRecommendationDto[];
  setCategoryRecommendations: (categoryRecommendations: CategoryRecommendationDto[]) => void;
  bid: number;
  isSponsoredProductsCampaignBuilderRequest?: boolean;
}

export default function CategoryRecommendations(props: CategoryRecommendationsProps) {
  const {
    profileId,
    campaignId,
    asins,
    handleAddTargets,
    targets,
    existingTargets,
    categoryRecommendations,
    setCategoryRecommendations,
    bid,
    isSponsoredProductsCampaignBuilderRequest
  } = props;
  const { getCategoryTargetRecommendations } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!profileId) return;
    if (!asins.length) return;

    const fetchCategories = async () => {
      setIsLoading(true);

      const response = await getCategoryTargetRecommendations(profileId, isSponsoredProductsCampaignBuilderRequest || false, {
        asins: asins,
        campaignId: campaignId || null
      });

      if (response.success) {
        setCategoryRecommendations(response.body.categories || []);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper variant="outlined" sx={{ height: '425px', overflow: 'scroll' }}>
      <Box sx={{ px: 2 }}>
        {isLoading ? (
          <Skeleton variant="text" width="30%" />
        ) : (
          <Typography variant="caption">{categoryRecommendations?.length} categories</Typography>
        )}
      </Box>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </Box>
      ) : (
        <List>
          {categoryRecommendations.length > 0 ? (
            categoryRecommendations.map((category, index) => (
              <CategoryListItem
                key={index}
                category={category}
                handleAddTargets={handleAddTargets}
                targets={targets}
                existingTargets={existingTargets || []}
                profileId={profileId}
                bid={bid}
              />
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No category recommendations" />
            </ListItem>
          )}
        </List>
      )}
    </Paper>
  );
}
