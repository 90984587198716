import { ExpressionItem } from './targeting-refinements';

export const AMAZON_TARGET_MINIMUM_BID: number = 0.02;

interface Expression {
  type: 'ASIN_SAME_AS' | 'ASIN_EXPANDED_FROM';
  value: string;
}

export interface ProductTargetsBuilderRequest {
  campaignId: number;
  adGroupId: number;
  expression: Expression[];
  expressionType: 'MANUAL';
  state: 'ENABLED';
  bid: number;
}

export enum AmazonTargetMatchType {
  Broad = 'broad',
  Phrase = 'phrase',
  Exact = 'exact'
}

export interface ProductTargetRecommendationRequest {
  campaignId: string | null;
  adAsins: string[];
}

export interface RecommendationDto {
  recommendedAsin: string;
  themes: string[];
}

export interface ProductTargetRecommendationResponseDto {
  recommendations: RecommendationDto[];
}

export interface CategoryTargetRecommendationRequest {
  campaignId: string | null;
  asins: string[];
}

export interface IntegerRangeDto {
  min: number;
  max: number;
}

export interface CategoryRecommendationDto {
  name?: string;
  parentCategoryId?: string;
  categoryPath?: string;
  asinCounts?: IntegerRangeDto;
  translatedName?: string;
  isTargetable?: boolean;
  path?: string;
  translatedPath?: string;
  estimatedReach?: string;
}

export interface CategoryRecommendationResponseDto {
  categories?: CategoryRecommendationDto[];
}

export interface SponsoredProductCategoryRecommendation {
  id: string;
  parentCategoryId: string;
  categoryPath: string;
  name: string;
  asinCounts: any;
}

export const initialProductTarget: ProductTargetsBuilderRequest = {
  campaignId: 0,
  adGroupId: 0,
  expression: [
    {
      type: 'ASIN_SAME_AS',
      value: ''
    }
  ],
  expressionType: 'MANUAL',
  state: 'ENABLED',
  bid: 0
};

export interface TargetUpdateDto {
  targetId: string;
  bid?: number;
  state?: 'enabled' | 'paused';
}

export interface TargetCreateDto {
  adGroupId: string;
  state?: 'enabled' | 'paused';
  bid: number;
  matchType: any;
  keywordText?: string;
  campaignType: any;
  retailer: string;
  expressionType: any;
  expression?: ExpressionItem[];
  resolvedExpression?: ExpressionItem[];
  targetType?: TargetType;
  discoveredBy: string;
}

export enum ProductTargetMatchType {
  Exact = 'Exact',
  Expanded = 'Expanded'
}

export enum TargetType {
  Keyword = 'keyword',
  ProductExact = 'productExact',
  ProductExpanded = 'productExpanded',
  Category = 'category',
  Auto = 'auto',
  Contextual = 'contextual',
  Audience = 'audience'
}

export const initialProductTargets = [initialProductTarget];
