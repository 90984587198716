import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import { getUpdateCampaignRequest } from '../../../shared/types/walmart-sams-club/campaign';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';

const Campaigns: FC = () => {
  const { getCampaigns, updateCampaign, getCampaignTotals } = useAdsApi(Platform.SAMS_CLUB.value);
  const { enqueueSnackbar } = useSnackbar();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [campaignTotals, setCampaignTotals] = useState({});
  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.SAMS_CLUB_CAMPAIGN,
    breadCrumbParams: { profileId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);

    if (!updateCampaignRequest) {
      return oldRow;
    }

    setIsLoading(true);

    const response = await updateCampaign(updateCampaignRequest);

    setIsLoading(false);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ profileId });
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable) {
      return;
    }

    const fetchCampaigns = async () => {
      setIsLoading(true);

      const response = await getCampaigns(pageable, dateSettings.beginDate, dateSettings.endDate);
      const totalsResponse = await getCampaignTotals(pageable, dateSettings.beginDate, dateSettings.endDate);

      if (response.success) {
        setCampaigns(response.body.records);
        setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      if (totalsResponse.success) {
        setCampaignTotals(totalsResponse.body);
      } else {
        enqueueSnackbar(totalsResponse.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchCampaigns();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Page title="Campaigns">
        <Container maxWidth="xl" style={{ height: '93vh' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="h4">Campaigns</Typography>
              <Box
                sx={{
                  mr: 'auto'
                }}
              ></Box>
              <Link
                to={`/${Platform.SAMS_CLUB.value}/campaigns/create${profileId ? `?profileId=${profileId}` : ''}`}
                component={RouterLink}
                style={{ textDecoration: 'none' }}
              >
                <Button startIcon={<AddIcon fontSize="small" />} variant="contained">
                  Create Campaign
                </Button>
              </Link>
            </Box>
            {!initialSettings || settingsLoading ? (
              <DataGridPremium
                disableRowGrouping
                rows={[]}
                columns={CAMPAIGN_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
              />
            ) : (
              <PerformanceGrid
                loading={isLoading}
                processRowUpdate={processRowUpdate}
                rows={campaigns}
                columns={CAMPAIGN_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  saveDateConfig: saveDateConfig
                }}
                getRowId={(row: any) => row.campaignId}
                getRowClassName={(params) => `row-status--${params.row.status}`}
                handleFilterModelChange={handleFilterModelChange}
                handleSortModelChange={handleSortModelChange}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                rowCount={rowCount}
                aggregatedTotals={campaignTotals}
              />
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default Campaigns;
