import { AppRegistrationRounded } from '@mui/icons-material';
import { Box, Input, Slider, styled, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import { getPercent } from '../../../shared/types/budget';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      height: '100%'
    }
  })
);

interface RenderBudgetSliderProps {
  params: GridRenderEditCellParams;
  clientBudgetForCurrentMonth: number;
  isEditing: boolean;
}

function RenderBudgetSlider(props: RenderBudgetSliderProps) {
  const { params, clientBudgetForCurrentMonth, isEditing } = props;

  const apiRef = useGridApiContext();

  const step = 0.01;
  const max = 100;

  const initialPercent = Number(
    getPercent(params.row.projectedMonthBudget || params.row.currentMonthBudget, clientBudgetForCurrentMonth)
  );

  const minimumAllowedPercent = parseFloat(((params.row.currentMonthSpend / clientBudgetForCurrentMonth) * 100).toFixed(2));

  const [error, setError] = useState(false);

  const [percent, setPercent] = useState(initialPercent);

  useEffect(() => {
    if (!isEditing) {
      apiRef.current.stopCellEditMode({ id: params.id, field: params.field });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, apiRef, params.id, params.field]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value === '' ? 0 : parseFloat(event.target.value);
    const floatValue = parseFloat(rawValue.toFixed(2));

    setPercent(floatValue);

    if (floatValue < minimumAllowedPercent || floatValue > max) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    apiRef.current.setEditCellValue({
      id: params.id,
      field: params.field,
      value: floatValue
    });
  };

  const handleBlur = () => {
    const finalValue = percent < minimumAllowedPercent || percent > max ? initialPercent : percent;
    setPercent(finalValue);
    apiRef.current.setEditCellValue({
      id: params.id,
      field: params.field,
      value: finalValue
    });
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        userSelect: 'none'
      }}
    >
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="caption" color="text.secondary" sx={{ userSelect: 'none' }}>
          {`${params.value.toFixed(2)}%`}
        </Typography>
      </Box>
      <Slider
        value={percent}
        step={step}
        min={minimumAllowedPercent}
        max={max}
        aria-labelledby="client-budget-slider"
        valueLabelFormat={(value) => `${value}%`}
        onMouseDown={(event: any) => {
          const cellMode = apiRef.current.getCellMode(params.id, params.field);
          if (cellMode === 'view') {
            apiRef.current.startCellEditMode({ id: params.id, field: params.field });
          }
        }}
        onMouseUp={(event: any) => {
          const cellMode = apiRef.current.getCellMode(params.id, params.field);

          if (cellMode === 'edit') {
            apiRef.current.stopCellEditMode({ id: params.id, field: params.field });
          }
        }}
        onChange={(event: any, newValue: number | number[]) => {
          setPercent(Number(newValue));
          apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: Number(event.target?.value) });
        }}
        sx={{ height: '10px', width: '75%', p: 1 }}
      />
      <StyledTooltip open={error} title={`Value must be between ${minimumAllowedPercent} and ${max}`}>
        <Input
          value={percent}
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={error}
          inputProps={{
            step: step,
            // explicitly set min to 1 (instead of minimumAllowedPercent)
            // to avoid some unexpected behavior with javascript's handling of floating point numbers
            min: 1,
            max: max,
            type: 'number',
            'aria-labelledby': 'input-slider',
            pr: 1
          }}
          sx={{
            mx: 1,
            fontSize: '0.8rem'
          }}
        />
      </StyledTooltip>
    </Box>
  );
}

export function renderBudgetSlider(params: GridRenderEditCellParams, clientBudgetForCurrentMonth: number, isEditing: boolean) {
  return <RenderBudgetSlider params={params} clientBudgetForCurrentMonth={clientBudgetForCurrentMonth} isEditing={isEditing} />;
}
