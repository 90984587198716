import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { renderProductCell } from '../renderer/renderProductCell';

export const PRODUCT_DIGITAL_SHELF_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product Name',
    width: 300,
    minWidth: 300,
    sortable: true,
    filterable: true,
    renderCell: renderProductCell
  },
  {
    field: 'glanceViews',
    headerName: 'Glance Views',
    type: 'number',
    width: 130,
    minWidth: 80
  },
  {
    field: 'isBuyBoxWon',
    headerName: 'Buy Box Won',
    width: 130,
    type: 'boolean',
    renderCell: (params) =>
      params.value ? <Typography color="success.main">Yes</Typography> : <Typography color="error.main">No</Typography>
  },
  {
    field: 'buyBoxPercentage (coming soon)',
    headerName: 'Buy Box %',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `${params.value}%`
  },
  {
    field: 'averageSellingPrice (coming soon)',
    headerName: 'Average Selling Price',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `$${params.value}`
  },
  {
    field: 'bsr (coming soon)',
    headerName: 'BSR',
    type: 'number',
    width: 130,
    minWidth: 80
  },
  {
    field: 'contentHealthScore (coming soon)',
    headerName: 'Content Health Score',
    type: 'number',
    width: 130,
    minWidth: 80
  },
  {
    field: 'hasAPlusContent',
    headerName: 'Has A+ Content',
    type: 'boolean',
    width: 130,
    minWidth: 80
  }
];
