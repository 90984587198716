import { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import type { Settings } from '../../contexts/settings-context';
import { useSettings } from '../../hooks/use-settings';
import { X as XIcon } from '../../icons/x';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import LightModeIcon from '@mui/icons-material/LightMode';
import PropTypes from 'prop-types';

interface SettingsDrawerProps {
  onClose?: () => void;
  open?: boolean;
}

const themes = [
  {
    label: 'Light',
    value: 'light',
    icon: LightModeIcon
  },
  {
    label: 'Dark',
    value: 'dark',
    icon: DarkModeIcon
  }
];

const getValues = (settings: Settings) => ({
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  theme: settings.theme
});

export const SettingsDrawer: FC<SettingsDrawerProps> = (props) => {
  const { open, onClose, ...other } = props;
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState<Settings>(getValues(settings));

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleChange = (field: string, value: unknown): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    onClose?.();
  };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      ModalProps={{ sx: { zIndex: 2000 } }}
      PaperProps={{ sx: { width: 320, borderRadius: '5px' } }}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          color: 'text.primary',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          py: 2
        }}
      >
        <Typography color="inherit" variant="h6">
          Theme settings
        </Typography>
        <IconButton color="inherit" onClick={onClose}>
          <XIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider />
      <Box
        sx={{
          py: 4,
          px: 3
        }}
      >
        <Typography
          color="textSecondary"
          sx={{
            display: 'block',
            mb: 1
          }}
          variant="overline"
        >
          Color Scheme
        </Typography>
        <ToggleButtonGroup value={values.theme}>
          {themes.map((theme) => {
            const { label, icon: Icon, value } = theme;

            return (
              <ToggleButton key={value} value={value} onClick={() => handleChange('theme', value)}>
                <Icon />
                {label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <Button color="primary" fullWidth onClick={handleSave} sx={{ mt: 3 }} variant="contained">
          Save Settings
        </Button>
      </Box>
    </Drawer>
  );
};

SettingsDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
