import { DEFAULT_GRID_AUTOSIZE_OPTIONS, GridAutosizeOptions } from '@mui/x-data-grid-premium';

/* Reference: https://mui.com/x/react-data-grid/column-dimensions/#autosizing */

export const AUTOSIZE_OPTIONS: GridAutosizeOptions = {
  includeHeaders: true,
  includeOutliers: true,
  outliersFactor: DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor,
  expand: DEFAULT_GRID_AUTOSIZE_OPTIONS.expand
};
