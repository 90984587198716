import { Card, CardActionArea, CardContent, Typography, Box, useTheme } from '@mui/material';

export enum UserSelectedCampaignType {
  SINGLE_ITEM_SINGLE_TARGET = 'Single Item Single Target',
  SINGLE_ITEM_MULTIPLE_TARGETS = 'Single Item Multiple Targets',
  MULTIPLE_ITEMS_MULTIPLE_TARGETS = 'Multiple Items Multiple Targets'
}

export interface RadioCardProps {
  title: string;
  text: string;
  Icon: React.ComponentType<{ fill: string }>;
  value: UserSelectedCampaignType;
  selectedValue: UserSelectedCampaignType;
  onValueChange: (value: UserSelectedCampaignType) => void;
}

const RadioCard: React.FC<RadioCardProps> = ({ title, text, Icon, value, selectedValue, onValueChange }) => {
  const isSelected = value === selectedValue;

  const handleOnClick = () => {
    onValueChange(value);
  };

  const theme = useTheme();

  const cardStyle = isSelected
    ? { border: `1px solid ${theme.palette.primary.main}`, height: '100%' }
    : { border: `1px solid ${theme.palette.neutral?.[400]}`, height: '100%' };

  const svgFill = isSelected ? theme.palette.primary.main : theme.palette.text.secondary;

  return (
    <Card style={cardStyle} onClick={handleOnClick}>
      <CardActionArea>
        <CardContent>
          <Box>
            <Icon fill={svgFill} />
            <Typography gutterBottom variant="h6" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {text}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default RadioCard;
