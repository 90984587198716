import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import UpdateBidsActionButton from '../../../../shared/components/performance-grid/edit-bids-action-button';
import useAdsApi from '../../../../shared/hooks/use-walmart-sams-club-api';
import { UpdateAdItemBidsDto, UpdateBidsConfig } from '../../../../shared/types/update-bids';

interface UpdateAdBidsProps {
  setIsLoading: (isLoading: boolean) => void;
  selectionModelHasRows: boolean;
}

const UpdateAdBids = ({ setIsLoading, selectionModelHasRows }: UpdateAdBidsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const apiRef = useGridApiContext();

  const { updateAdItemBids } = useAdsApi();

  const handleUpdateAdItemBids = async (updateBidsConfig: UpdateBidsConfig) => {
    setIsDialogOpen(false);
    setIsLoading(true);

    const selectedAdItems = Array.from(apiRef.current.getSelectedRows().values());

    const requestBody: UpdateAdItemBidsDto = {
      adItemIds: selectedAdItems.map((adItem: any) => adItem.adItemId),
      value: updateBidsConfig.value,
      bidUpdateOperator: updateBidsConfig.bidUpdateOperator,
      bidUpdateType: updateBidsConfig.bidUpdateType,
      ...(updateBidsConfig.shouldApplyMaxBid && { maxBid: updateBidsConfig.maxBid }),
      ...(updateBidsConfig.shouldApplyMinBid && { minBid: updateBidsConfig.minBid })
    };

    const response = await updateAdItemBids(requestBody);

    if (response.success) {
      const updatedAdItems = response.body;
      apiRef.current.updateRows(updatedAdItems.map((adItem: any) => ({ adItemId: adItem.adItemId, bid: adItem.bid })));
    }

    apiRef.current.setRowSelectionModel([]);
    setIsLoading(false);
  };

  return (
    <>
      <UpdateBidsActionButton
        updateEntities={handleUpdateAdItemBids}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        selectionModelHasRows={selectionModelHasRows}
      />
    </>
  );
};

export default UpdateAdBids;
