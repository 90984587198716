import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { Review } from '../../../../../types/walmart-sams-club/review';
import { IndividualItemStatusChip } from '../review-status-chip';

interface AdGroupMediaDetailsProps {
  adGroupMedia: any;
  review: Review;
  onCreate: any;
  onCancel: any;
}

export const AdGroupMediaDetails = (props: AdGroupMediaDetailsProps) => {
  const { adGroupMedia, review, onCreate, onCancel } = props;

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6">Media Review Details</Typography>
          <Box display={'flex'} justifyContent="space-between" alignItems={'flex-start'}>
            <Box display="flex">
              <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                Review Status:
              </Typography>
              <IndividualItemStatusChip
                status={adGroupMedia?.reviewStatus}
                isUnderReview={review?.reviewStatus?.toLocaleLowerCase() === 'pending'}
              />
            </Box>
            <Box>
              {!review || ['cancelled', 'complete'].includes(review?.reviewStatus?.toLowerCase()) ? (
                <>
                  <Button variant="contained" color="primary" onClick={onCreate}>
                    Create Review
                  </Button>
                </>
              ) : (
                <>
                  {review?.reviewComments?.map((comment, index) => (
                    <Typography variant="h6" sx={{ color: 'text.secondary' }} key={index.toString()}>
                      {comment.comments}
                    </Typography>
                  ))}
                  <Button variant="outlined" color="error" onClick={onCancel}>
                    Cancel Review
                  </Button>
                </>
              )}
            </Box>
          </Box>
          {adGroupMedia.reviewReason ? (
            <>
              <Typography variant="body1">Review Comments:</Typography>
              <Typography variant="body2">{adGroupMedia.reviewReason}</Typography>
            </>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
};
