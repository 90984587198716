export const renderEntityId = (value: any) => {
  let stringValueSansCommas = value?.toString().replace(/\,/g, '');

  let parsedInteger = parseInt(stringValueSansCommas, 10);

  if (isNaN(parsedInteger)) {
    return value;
  }

  return parsedInteger;
};
