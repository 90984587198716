import CheckIcon from '@mui/icons-material/Check';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAdsApi } from '../../../../shared/hooks/use-walmart-sams-club-api';
import { CampaignBuilderItem } from '../../../types/campaign-builder-request';
import { SbReviewRequest as SamsClubSbaReviewRequest } from '../../../../shared/types/walmart-sams-club/review';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';
import { Platform } from '../../../../shared/types/platforms';

interface SuccessfulCampaignCreationProps {
  campaignBuilderResponse: ResponseObject;
  items: CampaignBuilderItem[];
  campaignName: string;
  keywords: any[];
  negativeKeywords: any[];
  handleSkipReview: () => void;
  handleSuccessfulReviewSubmission: () => void;
  shouldShowReview: boolean;
}

export const SuccessfulCampaignCreation = (props: SuccessfulCampaignCreationProps) => {
  const {
    items,
    campaignName,
    keywords,
    negativeKeywords,
    shouldShowReview,
    campaignBuilderResponse,
    handleSkipReview,
    handleSuccessfulReviewSubmission
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { walmartCreateSbaReview } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  const submitSbaReview = async () => {
    setIsSubmitting(true);

    const request: SamsClubSbaReviewRequest = {
      campaignId: campaignBuilderResponse?.body?.campaign?.campaignId,
      adGroupId: campaignBuilderResponse?.body?.adGroups[0]?.adGroupId
    };

    const response = await walmartCreateSbaReview(profileId, request);

    if (response.success) {
      handleSuccessfulReviewSubmission();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      navigate(`/campaigns?profileId=${profileId}`);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar
          sx={{
            backgroundColor: 'success.main',
            color: 'success.contrastText',
            height: 40,
            width: 40
          }}
        >
          <CheckIcon />
        </Avatar>
        <Typography variant="h6" sx={{ ml: 2 }}>
          Your campaign was successfully created!
        </Typography>
      </Box>
      <Card variant="outlined">
        <CardContent>
          <Grid container direction="column" justifyContent="space-between" spacing={5}>
            <Grid item>
              {' '}
              <Box sx={{ width: '100%' }}>
                <Typography variant="h6">{campaignName}</Typography>
                <Typography color="textSecondary" variant="caption">
                  {keywords.length} keywords,{' '}
                  <Typography color="inherit" noWrap variant="caption">
                    {negativeKeywords.length} negative keywords,{' '}
                    <Typography color="inherit" noWrap variant="caption">
                      {items.length} items
                    </Typography>
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Collapse in={shouldShowReview}>
                <Divider />
                <Box sx={{ my: 4 }}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6">SB Review</Typography>
                      <Typography variant="caption" component={'p'} sx={{ mt: 1 }}>
                        Your SB campaign is ready for review.
                      </Typography>
                      <Typography variant="caption" component={'p'} sx={{ mt: 1 }}>
                        Would you like to submit a review now? (Optional)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{ mr: 1 }}
                        onClick={() =>
                          navigate(
                            `/review/?profileId=${profileId}&campaignId=${campaignBuilderResponse?.body?.campaign?.campaignId}`
                          )
                        }
                      >
                        Go to Review Page
                      </Button>
                      <Button sx={{ mr: 1 }} onClick={() => handleSkipReview()}>
                        Skip for now
                      </Button>
                      <Button variant={'outlined'} onClick={() => submitSbaReview()}>
                        Submit Review
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </Collapse>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6">Budget Settings</Typography>
                  <Typography variant="caption" component={'p'} sx={{ mt: 1 }}>
                    Your campaign is ready for a budget.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ mr: 1 }}
                    onClick={() => navigate(`/${Platform.SAMS_CLUB.value}/campaigns?profileId=${profileId}`)}
                  >
                    Go to Campaigns
                  </Button>
                  <Button
                    variant={'contained'}
                    onClick={() => navigate(`/${Platform.SAMS_CLUB.value}/budgets?profileId=${profileId}`)}
                  >
                    Go to Budget Manager
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
