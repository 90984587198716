import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, CardContent, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  BiddablePlacements,
  BiddingStrategies,
  BiddingStrategy,
  Campaign,
  AmazonSponsoredProductsDynamicBidding,
  TransformDynamicBidding,
  defaultDynamicBidding,
  UpdateCampaignRequest
} from '../types/campaign';
import { PlacementBidding } from './grid-components/renderer/dynamic-bidding/placement-bidding';

export interface CampaignBiddingStrategyProps {
  campaign: Campaign;
  setCampaign: (campaign: any) => void;
  handleUpdateCampaign: (updatedCampaign: UpdateCampaignRequest) => Promise<void>;
  bidStrategy: BiddingStrategy | null;
  setBidStrategy: (bidStrategy: BiddingStrategy) => void;
  hideHeader?: boolean;
}

const CampaignBiddingStrategy = (props: CampaignBiddingStrategyProps) => {
  const { campaign, setCampaign, handleUpdateCampaign } = props;

  const [selectedDynamicBiddingStrategy, setSelectedDynamicBiddingStrategy] = useState(
    campaign?.amazonSponsoredProductsDynamicBidding?.strategy || ''
  );
  const [amazonSponsoredProductsDynamicBidding, setAmazonSponsoredProductsDynamicBidding] =
    useState<AmazonSponsoredProductsDynamicBidding>(
      TransformDynamicBidding.toFrontend(campaign?.amazonSponsoredProductsDynamicBidding) || defaultDynamicBidding
    );

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDynamicBiddingStrategy(event.target.value as BiddingStrategy);

    const updatedDynamicBidding = {
      ...amazonSponsoredProductsDynamicBidding,
      strategy: event.target.value as BiddingStrategy
    };

    updateDynamicBidding(updatedDynamicBidding);
  };

  const handlePercentageChange = (percentage: any, placement: any) => {
    const updatedDynamicBidding = {
      ...amazonSponsoredProductsDynamicBidding,
      placementBidding: { ...amazonSponsoredProductsDynamicBidding.placementBidding, [placement]: percentage }
    };

    updateDynamicBidding(updatedDynamicBidding);
  };

  const updateDynamicBidding = (updatedDynamicBidding: AmazonSponsoredProductsDynamicBidding) => {
    setAmazonSponsoredProductsDynamicBidding(updatedDynamicBidding);

    const transformedDynamicBidding = TransformDynamicBidding.toBackend(updatedDynamicBidding);

    const updatedCampaignRequest = {
      campaignId: campaign.campaignId,
      amazonSponsoredProductsDynamicBidding: transformedDynamicBidding
    };

    setCampaign({ ...campaign, amazonSponsoredProductsDynamicBidding: transformedDynamicBidding });
    handleUpdateCampaign(updatedCampaignRequest);
  };

  return (
    <Box>
      {!props.hideHeader && <Typography variant="h6">Dynamic Bidding</Typography>}
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderColor: 'divider',
          borderStyle: 'solid',
          borderWidth: '1px'
        }}
      >
        <CardContent sx={{ paddingBottom: '10px' }}>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Bidding Strategy
              </Typography>
              <RadioGroup
                aria-label="bidding-strategy"
                name="bidding-strategy"
                value={selectedDynamicBiddingStrategy}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value={BiddingStrategies.DownOnly.value}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Typography variant="body2">{BiddingStrategies.DownOnly.label}</Typography>
                      <Tooltip
                        title={
                          <Typography variant="caption">
                            We'll lower your bids in real time when your ad may be less likely to convert to a sale.
                          </Typography>
                        }
                      >
                        <InfoIcon sx={{ ml: 2 }} />
                      </Tooltip>
                    </Box>
                  }
                />

                <FormControlLabel
                  value={BiddingStrategies.UpAndDown.value}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Typography variant="body2">{BiddingStrategies.UpAndDown.label}</Typography>
                      <Tooltip
                        title={
                          <Typography variant="caption">
                            We'll raise your bids (by a maximum of 100%) in real time when your ad may be more likely to convert
                            to a sale, and lower your bids when less likely to convert to a sale.
                          </Typography>
                        }
                      >
                        <InfoIcon sx={{ ml: 2 }} />
                      </Tooltip>
                    </Box>
                  }
                />

                <FormControlLabel
                  value={BiddingStrategies.Fixed.value}
                  control={<Radio />}
                  label={
                    <Box display={'flex '}>
                      <Typography variant="body2">{BiddingStrategies.Fixed.label}</Typography>
                      <Tooltip
                        title={
                          <Typography variant="caption">
                            We'll use your exact bid and any manual adjustments you set, and won't change your bids based on the
                            likelihood of a sale.
                          </Typography>
                        }
                      >
                        <InfoIcon sx={{ ml: 2 }} />
                      </Tooltip>
                    </Box>
                  }
                />
              </RadioGroup>
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Placement Adjustments
              </Typography>
              {Object.keys(BiddablePlacements).map((key) => (
                <Grid item xs={12} key={key}>
                  <PlacementBidding
                    placement={BiddablePlacements[key as keyof typeof BiddablePlacements].value}
                    percentage={
                      amazonSponsoredProductsDynamicBidding.placementBidding[
                        BiddablePlacements[key as keyof typeof BiddablePlacements].value
                      ]
                    }
                    setPercentage={(percentage: number | undefined) => {
                      handlePercentageChange(percentage, BiddablePlacements[key as keyof typeof BiddablePlacements].value);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CampaignBiddingStrategy;
