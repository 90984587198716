export const samsClubCampaignTypes = [
  {
    label: 'Sponsored Products',
    value: 'sponsoredProducts'
  },
  {
    label: 'Search Brand Amplifier',
    value: 'sba'
  },
  {
    label: 'Sponsored Video',
    value: 'video'
  }
];
