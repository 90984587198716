import { NegativeKeywordMatchType } from './campaign-builder-request';

interface NegativeExpression {
  type: 'ASIN_BRAND_SAME_AS';
  value: string;
}

export interface NegativeProductTargetsBuilderRequest {
  campaignId: number;
  adGroupId: number;
  state: 'ENABLED';
  expression: NegativeExpression[];
}

export interface NegativeTargetUpdateDto {
  negativeTargetId: string;
  state?: 'enabled' | 'paused';
}

export interface CampaignNegativeTargetUpdateDto {
  campaignNegativeTargetId: string;
  state: 'enabled' | 'paused';
}

export const initialNegativeProductTarget: NegativeProductTargetsBuilderRequest = {
  campaignId: 0,
  adGroupId: 0,
  expression: [
    {
      type: 'ASIN_BRAND_SAME_AS',
      value: ''
    }
  ],
  state: 'ENABLED'
};

export const initialNegativeProductTargets = [initialNegativeProductTarget];

export type CampaignNegativeTargetBuilderRequest = {
  campaignId: number;
  keywordText: string;
  matchType: NegativeKeywordMatchType;
  negativeTargetType: 'Keyword';
  retailer: 'Amazon';
  state: 'Enabled';
  expression?: any;
  resolvedExpression?: any;
};

export type NegativeTargetBuilderRequest = {
  adGroupId: number;
  keywordText?: string;
  matchType?: NegativeKeywordMatchType;
  negativeTargetType: 'Keyword' | 'TargetingClause';
  retailer: 'Amazon';
  state: 'Enabled';
  expression?: any;
  resolvedExpression?: any;
};

export type AdGroupNegativeKeywordUIState = {
  id: string;
  name: string;
  negativeKeywords: NegativeKeyword[];
};

export type NegativeKeyword = {
  matchType: NegativeKeywordMatchType;
  keywordText: string;
};

export function transformAdGroupsToDto(adGroupsFromApi: any) {
  return adGroupsFromApi.map((adGroup: any) => ({
    id: adGroup.adGroupId,
    name: adGroup.name,
    negativeKeywords: []
  }));
}

export function createCampaignNegativeTargetBuilderRequest(
  negativeTargets: any,
  campaignId: any
): CampaignNegativeTargetBuilderRequest[] {
  return negativeTargets.map((negativeTarget: any) => ({
    state: 'Enabled',
    campaignId: campaignId,
    keywordText: negativeTarget?.keywordText,
    matchType: negativeTarget?.matchType,
    negativeTargetType: 'Keyword',
    retailer: 'Amazon',
    expression: negativeTarget?.expression,
    resolvedExpression: negativeTarget?.resolvedExpression
  }));
}

export function createNegativeTargetBuilderRequest(negativeTargets: any, adGroupId: any): NegativeTargetBuilderRequest[] {
  return negativeTargets.map((negativeTarget: any) => ({
    state: 'Enabled',
    adGroupId: adGroupId,
    keywordText: negativeTarget?.keywordText,
    matchType: negativeTarget?.matchType,
    negativeTargetType: 'Keyword',
    retailer: 'Amazon',
    expression: negativeTarget?.expression,
    resolvedExpression: negativeTarget?.resolvedExpression
  }));
}

export interface CampaignNegativeTarget {
  campaignNegativeTargetId: string;
  campaignId: string;
  platformCampaignId: string | null;
  platformNegativeTargetId: string;
  state: 'Enabled' | 'Paused';
  keywordText: string;
  matchType: 'Phrase' | 'Exact' | 'Broad';
  negativeTargetType: string | null;
  expression: string | null;
  resolvedExpression: string | null;
}
