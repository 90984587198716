import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import CampaignDailyBudgetOverrideGrid from './budget-override-grid';
import { useEffect, useState } from 'react';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { useSnackbar } from 'notistack';
import { MetricsTiles } from '../day-parting/metrics-tiles';

interface DailyBudgetOverrideDialogProps {
  profileId: number;
  campaignId: number;
  campaignName: string;
  currentDailyBudget: number;
  isOpen: boolean;
  toggle: () => void;
}

export default function DailyBudgetOverrideDialog(props: DailyBudgetOverrideDialogProps) {
  const { campaignId, campaignName, currentDailyBudget, isOpen, toggle } = props;
  const { getDaypartingViewStats } = useAdsApi();
  const [monthlyBudget, setMonthlyBudget] = useState(0);
  const [currentMonthSpend, setCurrentMonthSpend] = useState(0);
  const [realtimeStats, setRealtimeStats] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const initialize = async () => {
    setIsLoading(true);
    const response = await getDaypartingViewStats(campaignId);

    if (response.success) {
      const statsWithParsedDate = sanitizeRealtimeStats(response?.body);
      setRealtimeStats(statsWithParsedDate);
      setMonthlyBudget(response?.body?.currentMonthBudget ?? 0);
      setCurrentMonthSpend(response.body?.currentMonthSpend ?? 0);
    } else {
      enqueueSnackbar('Error loading budget information', { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function sanitizeRealtimeStats(viewDataDto: any) {
    return viewDataDto.last7DaysRealtimeStats.map((stat: any) => {
      return {
        ...stat,
        dailyOutOfBudgetDateTime: !!stat.dailyOutOfBudgetDateTime ? new Date(stat.dailyOutOfBudgetDateTime) : null,
        asOf: new Date(stat.asOf)
      };
    });
  }

  return (
    <>
      <Dialog onClose={toggle} maxWidth={'lg'} open={isOpen}>
        <DialogTitle>
          <Box display="flex" alignItems={'center'}>
            <Typography variant={'h6'}>Daily Budget Overrides ({campaignName})</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mr: 2 }}>
          <DialogContentText sx={{ mb: 2 }}>
            <Typography variant={'body1'} fontWeight="bold" color="text.primary">
              Overview:
            </Typography>
            <ul>
              <li>
                <Typography variant={'body1'} color="text.primary" sx={{ maxWidth: '900px' }}>
                  Budget overrides are inclusive of start and end dates.
                </Typography>
              </li>
              <li>
                <Typography variant={'body1'} color="text.primary" sx={{ maxWidth: '900px' }}>
                  Budget overrides still respect monthly budget caps.
                </Typography>
              </li>
              <li>
                <Typography variant={'body1'} color="text.primary" sx={{ maxWidth: '400px' }}>
                  The higher of the budget override value or evenly paced remaining monthly budget will be used.
                </Typography>
              </li>
            </ul>
            <Typography variant={'body1'} fontWeight="bold" color="text.primary" sx={{ maxWidth: '900px', mt: 1, mb: 1 }}>
              Current Daily Budget: ${currentDailyBudget}
            </Typography>
            <MetricsTiles
              isLoading={isLoading}
              currentMonthSpend={currentMonthSpend}
              monthlyBudget={monthlyBudget}
              stats={realtimeStats}
            />
          </DialogContentText>
          <CampaignDailyBudgetOverrideGrid campaignId={campaignId} currentDailyBudget={currentDailyBudget} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={toggle}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
