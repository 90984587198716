import { GridColDef } from '@mui/x-data-grid-premium';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { renderLastMetricsDate } from '../../../../shared/rendering/renderLastMetricsDate';

export enum MetricColumnField {
  Cost = 'cost',
  AttributedRevenue = 'attributedRevenue',
  ReturnOnAdSpend = 'returnOnAdSpend',
  Impressions = 'impressions',
  Clicks = 'clicks',
  ClickThroughRate = 'clickThroughRate',
  Units = 'units',
  Orders = 'orders',
  NewToBrandOrders = 'newToBrandOrders',
  NewToBrandRevenue = 'newToBrandRevenue',
  NewToBrandUnits = 'newToBrandUnits',
  ConversionRate = 'conversionRate',
  CostPerClick = 'costPerClick',
  CostPerConversion = 'costPerConversion',
  AdvertisingCostOfSale = 'advertisingCostOfSale',
  PercentNewToBrandUnits = 'percentNewToBrandUnits',
  PercentNewToBrandOrders = 'percentNewToBrandOrders',
  PercentNewToBrandRevenue = 'percentNewToBrandRevenue',
  LastMetricsDate = 'lastMetricsDate',
  RevenuePerClick = 'revenuePerClick'
}

type MetricColumnDef = {
  field: MetricColumnField;
} & GridColDef;

export const METRIC_COLUMNS: MetricColumnDef[] = [
  {
    field: MetricColumnField.LastMetricsDate,
    headerName: 'Data Through',
    description:
      "The latest date with ads data. Yesterday's data is retrieved daily at 8am CST. Note: There may be a 1-2 hours delay before data reaches Vector",
    width: 100,
    minWidth: 120,
    headerAlign: 'left',
    align: 'right',
    type: 'string',
    valueFormatter: ({ value }) => (value ? new Date(value) : value),
    renderCell: (params) => renderLastMetricsDate(params, 2)
  },
  {
    field: MetricColumnField.Cost,
    headerName: 'Spend',
    width: 100,
    minWidth: 100,
    headerAlign: 'left',
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: MetricColumnField.AttributedRevenue,
    headerName: 'Attr. Rev.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: MetricColumnField.ReturnOnAdSpend,
    headerName: 'RoAS',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: MetricColumnField.Impressions,
    headerName: 'Imp.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: MetricColumnField.Clicks,
    headerName: 'Clicks',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: MetricColumnField.ClickThroughRate,
    headerName: 'CTR',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? `${(value * 100).toFixed(2)}%` : value)
  },
  {
    field: MetricColumnField.Units,
    headerName: 'Units',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: MetricColumnField.Orders,
    headerName: 'Orders',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: MetricColumnField.NewToBrandOrders,
    headerName: 'NTB Orders',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: MetricColumnField.NewToBrandRevenue,
    headerName: 'NTB Revenue',
    width: 110,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: MetricColumnField.NewToBrandUnits,
    headerName: 'NTB Units',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: MetricColumnField.PercentNewToBrandUnits,
    headerName: '% NTB Units',
    width: 110,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: MetricColumnField.PercentNewToBrandOrders,
    headerName: '% NTB Orders',
    width: 110,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: MetricColumnField.PercentNewToBrandRevenue,
    headerName: '% NTB Revenue',
    width: 110,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: MetricColumnField.ConversionRate,
    headerName: 'Conv. Rate',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? `${(value * 100).toFixed(2)}%` : value)
  },
  {
    field: MetricColumnField.CostPerClick,
    headerName: 'CPC',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: MetricColumnField.CostPerConversion,
    headerName: 'Cost/Conv.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: MetricColumnField.AdvertisingCostOfSale,
    headerName: 'ACoS',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? `${(value * 100).toFixed()}%` : value)
  },
  {
    field: MetricColumnField.RevenuePerClick,
    headerName: 'RPC',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  }
];
