import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import {
  UserAddedRefinements,
  UserAddedRefinementsKeys,
  defaultUserAddedRefinements
} from '../../../../types/targeting-refinements';
import { BooleanInput, PriceRefinementInput, RatingSettings, RefinementList } from './category-refinement-inputs';
import { allRefinementsMatch, buildAddedTargetListItemFromRecommendation, buildRefinedCategory } from './targeting-utils';

interface RefinementDialogProps {
  isOpen: boolean;
  toggle: () => void;
  category: { id: string; name: string; categoryPath: string } | null;
  suggestedRefinements: { ageRanges: any[]; brands: any[]; genres: any[] } | null;
  handleAddTargets: (targets: any) => void;
  targets: any[];
  bid: number;
}

export const CategoryRefinementDialog = (props: RefinementDialogProps) => {
  const { isOpen, toggle, category, suggestedRefinements, handleAddTargets, targets, bid } = props;
  const [userAddedRefinements, setUserAddedRefinements] = useState<UserAddedRefinements>(defaultUserAddedRefinements);

  const handleAddRefinedCategoryTarget = () => {
    toggle();

    const refinedCategoryExpressions = buildRefinedCategory(userAddedRefinements);

    const newTargetClause = buildAddedTargetListItemFromRecommendation(
      { ...category, refinements: refinedCategoryExpressions },
      'category',
      bid
    );

    if (
      targets.some(
        (target) => target.key === newTargetClause.key && allRefinementsMatch(target.refinements, newTargetClause.refinements)
      )
    ) {
      return;
    }

    handleAddTargets([newTargetClause]);
  };

  const handleExpressionChange = (type: keyof UserAddedRefinements, newValue: any) => {
    setUserAddedRefinements((prevState) => {
      return {
        ...prevState,
        [type]: newValue
      };
    });
  };

  return (
    <Dialog open={isOpen} onClose={toggle} fullWidth maxWidth="sm">
      <DialogTitle>Refinements - {category?.name}</DialogTitle>
      <DialogContent>
        <RefinementList
          title="Age Ranges"
          refinements={suggestedRefinements?.ageRanges}
          userAddedRefinements={userAddedRefinements.ageRanges}
          onRefinementChange={handleExpressionChange}
          refinementType="ageRanges"
        />
        <RefinementList
          title="Brands"
          refinements={suggestedRefinements?.brands}
          userAddedRefinements={userAddedRefinements.brands}
          onRefinementChange={handleExpressionChange}
          refinementType="brands"
        />
        <RefinementList
          title="Genres"
          refinements={suggestedRefinements?.genres}
          userAddedRefinements={userAddedRefinements.genres}
          onRefinementChange={handleExpressionChange}
          refinementType="genres"
        />
        <Box sx={{ my: 1 }}>
          <PriceRefinementInput
            label="Minimum Price"
            type={UserAddedRefinementsKeys.priceGreaterThan}
            onExpressionChange={handleExpressionChange}
          />
        </Box>
        <Box sx={{ my: 1 }}>
          <PriceRefinementInput
            label="Maximum Price"
            type={UserAddedRefinementsKeys.priceLessThan}
            onExpressionChange={handleExpressionChange}
          />
        </Box>
        <Divider />
        <Box sx={{ my: 1 }}>
          <BooleanInput
            label="Is Prime Shipping Eligible"
            type={UserAddedRefinementsKeys.isPrimeShippingEligible}
            onExpressionChange={handleExpressionChange}
          />
        </Box>
        <Divider />
        <Box sx={{ my: 1 }}>
          <RatingSettings label="Review Rating Settings" onExpressionChange={handleExpressionChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddRefinedCategoryTarget} variant="outlined">
          Add Refined Category Target
        </Button>
      </DialogActions>
    </Dialog>
  );
};
