import { ArrowRightAltRounded } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-premium';

export const RenderDeltaCell = (params: GridCellParams) => {
  const theme = useTheme();

  const delta = params.row.deltaPercentage;
  const isBidIncreased = delta > 0;
  const rotation = isBidIncreased ? '-90deg' : '90deg';

  return (
    <Typography
      variant="body2"
      sx={{
        color: delta > 0 ? theme.palette.success.main : delta === 0 ? theme.palette.text.secondary : theme.palette.error.light,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {delta}% {delta !== 0 && <ArrowRightAltRounded sx={{ width: '1.3rem', transform: `rotate(${rotation})` }} />}
    </Typography>
  );
};
