import { Card, CardContent, Typography } from '@mui/material';
import NegativeKeywords from './add-negative-keywords';

interface NegativeKeywordSettingsProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const NegativeKeywordSettings = (props: NegativeKeywordSettingsProps) => {
  const { values, setFieldValue } = props;

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Negative Keywords
        </Typography>
        <NegativeKeywords values={values} setFieldValue={setFieldValue} />
      </CardContent>
    </Card>
  );
};
