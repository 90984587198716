import { Chip, Link } from '@mui/material';

interface KeywordTargetCellProps {
  keywordText: string;
}

const KeywordTargetCell = ({ keywordText }: KeywordTargetCellProps) => {
  if (!keywordText) {
    return null;
  }

  return (
    <>
      <Chip
        size="small"
        sx={{ mr: 1 }}
        component="div"
        label={
          <Link
            href={`https://www.amazon.com/s?k=${encodeURIComponent(keywordText)}`}
            target="_blank"
            sx={{ color: 'text.primary' }}
            title="Click to view search results for this keyword on Amazon.com in a new tab"
          >
            {keywordText}
          </Link>
        }
      />
    </>
  );
};

export default KeywordTargetCell;
