import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Skeleton,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import useAdsApi from '../hooks/use-walmart-sams-club-api';
import { useSnackbar } from 'notistack';
import { MediaVideoPlayerModal } from './sponsored-video-media/media/media-video-player';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

export const VideoProfileDialog = (props: any) => {
  const { toggle, isShowing, dynamicKey, profileId, campaignId, campaignName, refreshParent } = props;

  const { getAdGroupMedia, getMedia, createAdGroupMedia, updateAdGroupMedia } = useAdsApi();
  const [adGroupMedia, setAdGroupMedia] = useState<any>(undefined);
  const [profileMedia, setProfileMedia] = useState<any>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [existingMedia, setExistingMedia] = useState<any>(null);
  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);
  const [shouldShowVideoDialog, setShouldShowVideoDialog] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const initialize = async () => {
    const mediaResponse = await getMedia(profileId);

    if (mediaResponse?.success) {
      setProfileMedia(mediaResponse.body.filter((media: any) => media.status.toLowerCase() === 'available'));
    }

    const adGroupMediaResponse = await getAdGroupMedia(campaignId);

    if (adGroupMediaResponse?.success) {
      setAdGroupMedia(adGroupMediaResponse.body[0]);
      const existingMedia = mediaResponse.body.find((media: any) => media.mediaId === adGroupMediaResponse.body[0]?.mediaId);
      setSelectedMedia(existingMedia);
      setExistingMedia(existingMedia);
    }

    setIsInitialized(true);
  };

  const handleSave = async () => {
    setBackDropOpen(true);

    if (existingMedia && existingMedia.mediaId !== selectedMedia.mediaId) {
      handleUpdateAdGroupMedia();
    } else {
      handleCreateAdGroupMedia();
    }
  };

  const handleCreateAdGroupMedia = async () => {
    const createAdGroupMediaDto = {
      campaignId: campaignId,
      mediaId: selectedMedia.mediaId,
      profileId: profileId
    };

    const response = await createAdGroupMedia(createAdGroupMediaDto);

    if (response?.success) {
      enqueueSnackbar('Media saved successfully', { variant: 'success' });
      toggle();
    } else {
      enqueueSnackbar('Error saving media', { variant: 'error' });
      toggle();
    }

    setBackDropOpen(false);
  };

  const handleUpdateAdGroupMedia = async () => {
    const updateAdGroupMediaDto = {
      campaignId: campaignId,
      mediaId: selectedMedia.mediaId,
      adGroupMediaId: adGroupMedia.adGroupMediaId
    };

    const response = await updateAdGroupMedia(updateAdGroupMediaDto);

    if (response?.success) {
      enqueueSnackbar('Media updated successfully', { variant: 'success' });
      toggle();
    } else {
      enqueueSnackbar('Error updating media', { variant: 'error' });
      toggle();
    }

    setBackDropOpen(false);
  };

  useEffect(() => {
    initialize();
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="sm" sx={{ transition: 'height 1s' }}>
      <DialogTitle>Sponsored Video Media {campaignName}</DialogTitle>
      <DialogContent key={dynamicKey}>
        {isInitialized ? (
          <Box display={'flex'} flexDirection="column" alignItems={'center'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                minWidth: '50%'
              }}
            >
              {!adGroupMedia ? (
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                  No media found for this campaign.
                </Typography>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%',
                      minWidth: '50%'
                    }}
                  >
                    <Typography variant="body1" gutterBottom>
                      Existing Media:
                    </Typography>
                    <StyledPaper>
                      <Typography sx={{ whiteSpace: 'nowrap' }} variant="h6">
                        {existingMedia.name}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        ID: {adGroupMedia.mediaId}
                      </Typography>
                      <img
                        style={{ width: '135px', height: 'auto', cursor: 'pointer' }}
                        src={existingMedia.thumbnail}
                        onClick={() => setShouldShowVideoDialog(true)}
                      />
                      <MediaVideoPlayerModal
                        onClose={() => setShouldShowVideoDialog(false)}
                        open={shouldShowVideoDialog}
                        videoUrl={existingMedia.videoUrl}
                      />
                    </StyledPaper>
                  </Box>
                </>
              )}
            </Box>
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              Select a new media to add or update the existing media.
            </Typography>
            <Autocomplete
              value={selectedMedia || null}
              onChange={(event: any, newValue: any) => {
                setSelectedMedia(newValue);
              }}
              options={profileMedia}
              isOptionEqualToValue={(option: any, value: any) => option.mediaId === value.mediaId}
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, whiteSpace: 'nowrap' }} {...props}>
                  <img loading="lazy" width="20" src={option.thumbnail} />
                  {option.name}
                </Box>
              )}
              sx={{ width: '80%', mt: 2 }}
              renderInput={(params) => <TextField {...params} label="Media" />}
            />
          </Box>
        ) : (
          <Box display={'flex'} flexDirection="column" alignItems={'center'}>
            <Skeleton variant="rectangular" height="200px" width="400px" />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" disabled={!selectedMedia}>
          Save
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
