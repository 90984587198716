import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import Box from '@mui/material/Box';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { decrementDateRange, incrementDateRange } from '../../../utilities/date-utilities';
import { IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface DateRangePickerProps {
  initialDateRange?: DateRange<Date> | undefined;
  initialIncrementInDays?: number | string | undefined;
  onChange?: (dateRange: DateRange<Date>, incrementInDays: number) => void;
  maxDate?: Date;
  disableLeftDivider?: boolean;
}

const timeRanges = [
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 14 days', value: '14' },
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 6 months', value: '180' },
  { label: 'Year To Date', value: 'ytd' },
  { label: 'Month To Date', value: 'mtd' },
  { label: 'Custom Range', value: 'custom' }
];

const defaultDateRange: DateRange<Date> = [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()];

export default function GridDateRangePicker(props: DateRangePickerProps) {
  const { initialDateRange, initialIncrementInDays, onChange } = props;
  const maxDate = props.maxDate || new Date(); // default to today

  const [dateRange, setDateRange] = useState<DateRange<Date>>(initialDateRange || defaultDateRange);
  const [incrementInDays, setIncrementInDays] = useState<any>(initialIncrementInDays || 7);
  const [isOpen, setIsOpen] = useState(false);

  const doesRangeMatchPreset = (selectedRange: { start: any; end: any }) => {
    const { start, end } = selectedRange;

    for (let range of timeRanges) {
      let startDate, endDate;

      startDate = new Date();
      startDate.setDate(startDate.getDate() - Number(range.value) - 1);

      endDate = new Date();

      if (range.value === 'ytd') {
        const currentYear = new Date().getFullYear();
        startDate = new Date(currentYear, 0, 1);
        endDate = new Date();
      } else if (range.value === 'mtd') {
        const currentYear = endDate.getFullYear();
        const currentMonth = endDate.getMonth();
        startDate = new Date(currentYear, currentMonth, 1);
      } else {
        endDate.setDate(endDate.getDate() - 1);
      }

      // silently catch edge case when end date isn't selected yet
      try {
        if (startDate.toDateString() === start.toDateString() && endDate.toDateString() === end.toDateString()) {
          return range.value;
        }
      } catch (e) {
        console.error('error checking date range', e, startDate, endDate, start, end);
      }
    }
    return 'custom';
  };

  const handleIncrementChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;

    setIncrementInDays(newValue);

    let startDate = new Date();
    startDate.setDate(startDate.getDate() - Number(newValue) - 1);

    const endDate = new Date();

    if (newValue === 'ytd') {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      startDate = new Date(currentYear, 0, 1);
      endDate.setDate(currentDate.getDate() - 1);
    } else if (newValue === 'mtd') {
      const currentYear = endDate.getFullYear();
      const currentMonth = endDate.getMonth();
      startDate = new Date(currentYear, currentMonth, 1);
    } else if (newValue === 'custom') {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
    } else {
      endDate.setDate(endDate.getDate() - 1);
    }

    setDateRange([startDate, endDate]);
  };

  const handleDateRangeChange = (newRange: DateRange<Date>) => {
    const rangeValue = doesRangeMatchPreset({ start: newRange[0], end: newRange[1] });
    setIncrementInDays(rangeValue);
    setDateRange(newRange);
  };

  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  const previousIncrementOutOfRange = () => {
    if (isNaN(Number(incrementInDays))) {
      return true;
    }

    const previousDateRange = decrementDateRange(dateRange, incrementInDays);

    return previousDateRange[0] ? previousDateRange[0] < new Date('2000-01-01') : true;
  };

  const nextIncrementOutOfRange = () => {
    if (isNaN(Number(incrementInDays))) {
      return true;
    }

    const nextDateRange = incrementDateRange(dateRange, incrementInDays);

    return nextDateRange[1] ? nextDateRange[1] > new Date() : true;
  };

  useEffect(() => {
    onChange?.(dateRange, incrementInDays);

    setDisablePrevious(previousIncrementOutOfRange());
    setDisableNext(nextIncrementOutOfRange());
  }, [dateRange, incrementInDays]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (incrementInDays !== 'custom') {
      handleIncrementChange({ target: { value: incrementInDays } } as SelectChangeEvent);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DateRangePicker
      value={dateRange}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onChange={handleDateRangeChange}
      maxDate={maxDate}
      renderInput={(startProps, endProps) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              padding: '3px'
            }}
          >
            <IconButton
              size="large"
              sx={{
                borderRadius: '0px'
              }}
              onClick={() => setDateRange(decrementDateRange(dateRange, incrementInDays))}
              role="decrease-date-range"
              disabled={disablePrevious}
            >
              <ArrowLeftIcon />
            </IconButton>

            <Select
              value={incrementInDays.toString()}
              label={'Date Range'}
              role={'increment-select'}
              sx={{
                minWidth: '140px',
                fontSize: '14px'
              }}
              notched={false}
              onChange={handleIncrementChange}
              size="small"
            >
              {timeRanges.map((timeRange) => (
                <MenuItem key={timeRange.value} value={timeRange.value} role={'increment-option'}>
                  {timeRange.value === incrementInDays.toString() ? timeRange.label.replace('Last ', '') : timeRange.label}
                </MenuItem>
              ))}
            </Select>
            <Typography
              onClick={() => setIsOpen(true)}
              variant="body1"
              sx={{
                ':hover': { cursor: 'pointer' },
                borderRadius: '0px',
                padding: '10px',
                width: '100%',
                justifyContent: 'flex-end',
                display: 'flex'
              }}
            >
              {`${startProps.inputProps?.value} - ${endProps.inputProps?.value}`}
            </Typography>
            <IconButton
              size="large"
              sx={{ borderRadius: '0px' }}
              onClick={() => setDateRange(incrementDateRange(dateRange, incrementInDays))}
              disabled={disableNext}
              role="increase-date-range"
            >
              <ArrowRightIcon />
            </IconButton>
          </Box>
        );
      }}
    />
  );
}
