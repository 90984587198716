import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Theme,
  useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Scrollbar } from '../../components/scrollbar';
import { Platform } from '../../types/platforms';
import { PLATFORMS, Section, getWalmartSidebarSections, platformSidebarSections } from '../../types/sidebar';
import logoWithText from '../../images/vector-branding/vector-full-logo-blue-icon-white-text.svg';
import logoIconOnly from '../../images/vector-branding/vector-logo-icon-only-light-blue.svg';
import { DashboardSidebarSection } from './dashboard-sidebar-section';

interface DashboardSidebarProps {
  onClose?: () => void;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  open?: boolean;
}

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onClose, open, setIsSidebarOpen } = props;
  let location = useLocation();
  let navigate = useNavigate();

  const currentPlatform = location.pathname.split('/')[1];

  const [sections, setSections] = useState<Section[]>(getWalmartSidebarSections());
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });

  useEffect(() => {
    if (!Object.keys(platformSidebarSections).includes(currentPlatform)) {
      navigate('/' + PLATFORMS[0].path, { replace: true });
    }
    setSections(platformSidebarSections[currentPlatform]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handlePopoverOpen = useCallback(() => {
    setOpenPopover(true);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setOpenPopover(false);
  }, []);

  const handlePlatformChange = useCallback((newPlatform: string) => {
    setOpenPopover(false);
    navigate('/' + newPlatform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ borderBottom: '1px solid #2D3748', height: '33px', position: 'relative', mt: 2, pb: 2 }}>
            {open ? (
              <Box sx={{}}>
                <img src={logoWithText} height={20} style={{ margin: 'auto', display: 'block' }} />
              </Box>
            ) : (
              <img src={logoIconOnly} height={20} style={{ margin: 'auto', display: 'block' }} />
            )}
          </Box>
          <Box>
            <List dense>
              <ListItemButton onClick={handlePopoverOpen}>
                <ListItemAvatar>
                  <Avatar
                    alt={PLATFORMS.find((p) => p.path === currentPlatform)?.name}
                    src={PLATFORMS.find((p) => p.path === currentPlatform)?.logo}
                    sx={{ p: 1, backgroundColor: PLATFORMS.find((p) => p.path === currentPlatform)?.logoBackgroundColor }}
                  />
                </ListItemAvatar>
                <ListItemText>{PLATFORMS.find((p) => p.path === currentPlatform)?.name}</ListItemText>
                <ListItemIcon ref={anchorRef} sx={{ float: 'right' }}>
                  <ExpandMoreIcon />
                </ListItemIcon>
              </ListItemButton>
            </List>
          </Box>
          <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom'
            }}
            disableScrollLock
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }}
            keepMounted
            onClose={handlePopoverClose}
            open={openPopover}
            PaperProps={{ sx: { width: 180 } }}
          >
            <MenuItem onClick={() => handlePlatformChange?.(Platform.WALMART.value)}>{Platform.WALMART.label}</MenuItem>
            <MenuItem onClick={() => handlePlatformChange?.(Platform.AMAZON.value)}>{Platform.AMAZON.label}</MenuItem>
            <MenuItem onClick={() => handlePlatformChange?.(Platform.SAMS_CLUB.value)}>{Platform.SAMS_CLUB.label}</MenuItem>
            <MenuItem onClick={() => handlePlatformChange?.(Platform.CITRUS_AD.value)}>{Platform.CITRUS_AD.label}</MenuItem>
          </Popover>
          <Divider />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            {!!sections
              ? sections.map((section) => (
                  <DashboardSidebarSection
                    key={section.title}
                    path={location.pathname}
                    open={open}
                    sx={{
                      '& + &': {
                        // mt: 2
                      }
                    }}
                    {...section}
                  />
                ))
              : null}
          </Box>
          <Divider
            sx={{
              borderColor: '#2D3748' // dark divider
            }}
          />
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              sx={{
                border: 'solid 1px #2D3748'
              }}
              onClick={() => setIsSidebarOpen(!open)}
            >
              {open ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            width: open ? '220px' : '70px',
            transition: (theme) =>
              theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
              })
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 220
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
