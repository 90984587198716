import { Backdrop, Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import cronstrue from 'cronstrue';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Filter } from '../../../shared/types/pageable';
import useAmazonApi from '../../hooks/use-amazon-api';
import { ActionParametersStep } from './automated-actions-creation-form/action-parameters-step';
import { FilterCriteriaStep } from './automated-actions-creation-form/filter-criteria-step';

interface CampaignTypeStepProps {
  profileId: string;
  setTabIndex: (tabIndex: number) => void;
}

export const AutomatedActionsCreation: FC<CampaignTypeStepProps> = (props) => {
  const { profileId, setTabIndex } = props;
  const { saveAutomatedAction, getCronExpression } = useAmazonApi();
  const [filterCriteria, setFilterCriteria] = useState<Filter[]>([{ column: '', comparator: '', value: '' }]);
  const [entityType, setEntityType] = useState<string>('');
  const [executionScheduleCron, setExecutionScheduleCron] = useState<string>('');
  const [actionType, setActionType] = useState<string>('');
  const [actionParameters, setActionParameters] = useState<any>({});
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [executionScheduleDescription, setExecutionScheduleDescription] = useState<string>('');
  const [lookBackWindow, setLookBackWindow] = useState<number>();
  const [requiredDaysOfData, setRequiredDaysOfData] = useState<number>();
  const [actionName, setActionName] = useState<string>('');

  const constructActionMessage = () => {
    return (
      <Typography sx={{ mr: 0 }}>
        Performing a <strong>{actionType}</strong> action on <strong>{entityType}</strong>. Update type:{' '}
        {actionType === 'StateUpdate' ? (
          <strong>{JSON.stringify(actionParameters)}. </strong>
        ) : (
          <>
            <strong>{actionParameters.updateType}</strong> with value <strong>{actionParameters.value}</strong>.
          </>
        )}
        Range:{' '}
        <strong>
          {actionParameters.minValue}-{actionParameters.maxValue}
        </strong>
        . Scheduled <strong>{cronstrue.toString(executionScheduleCron)}</strong>. Conditions:{' '}
        <strong>
          {filterCriteria.map((criteria) => `${criteria.column} ${criteria.comparator} ${criteria.value}`).join(', ')}
        </strong>
        . Look back window: <strong>{lookBackWindow}</strong>, requiring <strong>{requiredDaysOfData}</strong> days of data.
      </Typography>
    );
  };

  const handlePreviewClick = async () => {
    const response = await getCronExpression(executionScheduleDescription);
    setExecutionScheduleCron(response.body);
    setIsPreview(true);
  };

  const handleCreateActionClick = async () => {
    const automatedAction = {
      filterCriteria: JSON.stringify(filterCriteria),
      entityType,
      actionType,
      actionParameters: JSON.stringify(actionParameters),
      executionScheduleCron,
      lookBackWindow,
      requiredDaysOfData,
      profileId,
      Name: actionName
    };

    const response = await saveAutomatedAction(automatedAction);

    if (response.success) {
      enqueueSnackbar('Action created successfully', { variant: 'success' });
      setTabIndex(0);
    } else {
      enqueueSnackbar('Error creating action', { variant: 'error' });
    }
  };

  const formSteps = [
    {
      label: 'Filter Criteria',
      content: (
        <FilterCriteriaStep
          setEntityType={setEntityType}
          entityType={entityType}
          filterCriteria={filterCriteria}
          setFilterCriteria={setFilterCriteria}
        />
      )
    },
    {
      label: 'Action Parameters',
      content: (
        <ActionParametersStep
          actionType={actionType}
          actionName={actionName}
          setActionType={setActionType}
          setActionParameters={setActionParameters}
          actionParameters={actionParameters}
          executionScheduleDescription={executionScheduleDescription}
          setExecutionScheduleDescription={setExecutionScheduleDescription}
          setLookBackWindow={setLookBackWindow}
          setRequiredDaysOfData={setRequiredDaysOfData}
          setActionName={setActionName}
        />
      )
    }
  ];

  const createAutomatedActionSteps = [
    {
      content: (
        <>
          {formSteps.map((step, index) => (
            <Box key={step.label}>{step.content}</Box>
          ))}
          {isPreview && constructActionMessage()}
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ my: 5 }}>
              <Button onClick={handlePreviewClick} sx={{ mr: 2 }} variant="outlined" size="large">
                Preview Action
              </Button>
              <Button onClick={handleCreateActionClick} variant="contained" size="large" disabled={!isPreview}>
                Create Action
              </Button>
            </Grid>
          </Grid>
        </>
      )
    }
  ];

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ mb: 4, color: '#999' }}>
          Automated Actions
        </Typography>
        {createAutomatedActionSteps[0].content}
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AutomatedActionsCreation;
