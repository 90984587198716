import React from 'react';
import { Tooltip, Box, Typography, Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

const RenderProductImage: React.FC<{ params: GridRenderCellParams }> = ({ params }) => {
  return (
    <Tooltip
      placement="bottom-start"
      title={
        <Box display="flex" sx={{ opacity: 1 }}>
          <LinkIcon color="primary" />
          <Link href={params.row.productImage} target="_blank" rel="noopener">
            <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 300, textDecoration: 'underline' }}>
              {params.row.productImage}
            </Typography>
          </Link>
        </Box>
      }
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'background.default'
          }
        }
      }}
    >
      <Link href={params.row.productImage} target="_blank" rel="noopener">
        <img
          src={params.value}
          style={{
            height: 40,
            width: 40
          }}
        />
      </Link>
    </Tooltip>
  );
};

export default RenderProductImage;
