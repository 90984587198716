import { Button, Tooltip } from '@mui/material';
import { GridEventListener, GridRowSelectionModel, useGridApiContext } from '@mui/x-data-grid-premium';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAmazonApi from '../../hooks/use-amazon-api';
import { AddCampaignsToBudgetGroupRequestDto } from '../../types/budget-group';
import { Campaign } from '../../types/campaign';
import { BidOptimizationGroupDto } from '../../types/bid-optimization-group';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';

interface CampaignsGridModalActionsProps {
  handleAddCampaigns?: () => void;
  setSelectedCampaignData?: (data: Partial<Campaign>[]) => void;
  isBudgetGroup?: boolean;
  isBidGroup?: boolean;
}

export const CampaignsGridModalActions = ({
  handleAddCampaigns,
  setSelectedCampaignData,
  isBudgetGroup = false,
  isBidGroup = false
}: CampaignsGridModalActionsProps) => {
  const apiRef = useGridApiContext();
  const { addCampaignsToBudgetGroup, updateBidOptimizationGroup, getBidOptimizationGroup } = useAmazonApi();
  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  const [searchParams] = useSearchParams();
  const campaignBudgetGroupId = searchParams.get('campaignBudgetGroupId');
  const bidGroupId = searchParams.get('bidGroupId');

  const handleAddCampaignsToGroup = useCallback(async () => {
    if (setSelectedCampaignData) {
      const selectedCampaignData = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => {
        return { campaignId: campaign.campaignId, name: campaign.name } as Partial<Campaign>;
      });

      setSelectedCampaignData(selectedCampaignData);
    }

    if (isBidGroup && bidGroupId) {
      const selectedCampaignIds: string[] = Array.from(apiRef.current.getSelectedRows().values()).map(
        (campaign) => campaign.campaignId
      );

      const bidGroupResponse: ResponseObject = await getBidOptimizationGroup(bidGroupId);

      if (!bidGroupResponse.success) {
        enqueueSnackbar(bidGroupResponse.errorMessage, { variant: 'error' });
        return;
      }

      const requestBody: BidOptimizationGroupDto = {
        ...bidGroupResponse.body,
        campaignIds: [...bidGroupResponse.body.campaignIds, ...selectedCampaignIds]
      };

      const response = await updateBidOptimizationGroup(requestBody);

      if (!response.success) {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('Campaigns added to bid group', { variant: 'success' });
      }
    }

    if (isBudgetGroup) {
      const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);

      if (!campaignBudgetGroupId) {
        return;
      }

      const requestBody: AddCampaignsToBudgetGroupRequestDto = {
        campaignIds: selectedCampaignIds,
        campaignBudgetGroupId: campaignBudgetGroupId
      };

      const response = await addCampaignsToBudgetGroup(requestBody);

      if (!response.success) {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('Campaigns added to budget group', { variant: 'success' });
      }
    }

    if (handleAddCampaigns) {
      handleAddCampaigns();
    }
  }, [campaignBudgetGroupId, handleAddCampaigns, isBudgetGroup, setSelectedCampaignData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (params: GridRowSelectionModel) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  return (
    <>
      <Tooltip
        title={
          selectionModelHasRows ? '' : `Select campaigns to add to ${isBudgetGroup ? 'budget ' : isBidGroup ? 'bid ' : null}group`
        }
      >
        <span>
          <Button onClick={handleAddCampaignsToGroup} disabled={!selectionModelHasRows}>
            Add Campaigns to {isBudgetGroup ? 'Budget ' : isBidGroup ? 'Bid ' : null}Group
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
