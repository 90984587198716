import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAdsApi from '../../../../../shared/hooks/use-citrusad-api';
import { Product, productDesign } from '../../../../types/campaign';
import { CitrusAdRetailer } from '../../../../types/retailer';
import { CampaignItemsTable } from '../../create-campaign-form/campaign-items-table';

interface ProductAdsDialogProps {
  isShowing: boolean;
  campaignId: string | null;
  ads: any[];
  toggle: () => void;
  refreshAds: () => void;
}

export const ProductAdsDialog = (props: ProductAdsDialogProps) => {
  const { isShowing, campaignId, ads, toggle, refreshAds } = props;

  const [userInputItemIds, setUserInputItemIds] = useState('');

  const { getAllProducts, getProductById, updateCampaign, getChildClientIdByTeamId } = useAdsApi();
  const [products, setProducts] = useState<Product[]>([]);
  const [itemSearchIsLoading, setItemSearchIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Product[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') || '';

  const fetchProducts = async () => {
    const childIdResponse = await getChildClientIdByTeamId(profileId);

    if (!childIdResponse.body.childClientId) {
      enqueueSnackbar('Unable to fetch items for this profile. Profile might not yet be associated with a child client id', {
        variant: 'error'
      });
    }

    const response = await getAllProducts(childIdResponse.body.childClientId);

    if (response.success) {
      const products = response.body.records.map((product: any) => {
        return {
          itemId: product.tcin,
          itemName: product.title,
          itemImageUrl: product.mainImageUrl
        };
      });
      setProducts(products);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addItems = async () => {
    setItemSearchIsLoading(true);

    const asins = userInputItemIds.replace(/,\s+/g, ',').split(/[\n,\s+]/);
    const newAsins = asins.filter((asin) => !formik.values.items.some((item: any) => item.asin === asin) && asin);

    getItemsByIds(newAsins)
      .then((results: any) => {
        const test = [...selectedItem, ...results];

        const uniqueItems = test.filter((value, index, self) => self.findIndex((item) => item.itemId === value.itemId) === index);

        const newItems = uniqueItems.filter(
          (newItem: any) => !formik.values.items.some((item: any) => item.itemId === newItem.itemId) && newItem.itemId
        );

        formik.setFieldValue('items', [...formik.values.items, ...newItems]);
        setSelectedItem([]);
        setUserInputItemIds('');
        setItemSearchIsLoading(false);
      })
      .catch(() => {
        setItemSearchIsLoading(false);
      });
  };

  const handleSubmit = async (values: any[] = []) => {
    setIsSubmitting(true);
    const items = findUniqueObjects(ads.concat(formik.values.items));
    const data = items.map((item: any) => {
      return {
        productCode: item.itemId || item.productCode,
        catalogId: CitrusAdRetailer.TARGET.catalogId
      };
    });

    const payload: any = {
      productCampaign: {
        advertisedProducts: {
          productsByKey: data
        }
      },
      mask: 'advertisedProducts.productsByKey'
    };
    const response = await updateCampaign(campaignId || '', payload);

    if (response.success) {
      enqueueSnackbar(`Product added successfully!`, { variant: 'success' });
      refreshAds();
      toggle();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  function findUniqueObjects(array: any[]) {
    const uniqueObjects = [];
    const seen = new Set();

    for (const object of array) {
      if (!seen.has(object.itemId || object.productCode)) {
        seen.add(object.itemId || object.productCode);
        uniqueObjects.push(object);
      }
    }

    return uniqueObjects;
  }

  const getItemsByIds = (ids: any[]) => {
    return new Promise((res, rej) => {
      if (!ids || ids.length === 0) {
        return res([]);
      }

      Promise.all(ids.map((itemId) => getProductById(itemId)))
        .then((results: any) => {
          const items = [];
          for (let index = 0; index < results.length; index++) {
            const element = results[index].body?.records[0];
            if (element) items.push(element);
          }
          return res(
            items.map((item: any) => {
              return {
                itemId: item.tcin,
                itemName: item.title,
                itemImageUrl: item.mainImageUrl
              };
            })
          );
        })
        .catch((e) => rej(e));
    });
  };

  const removeItem = (item: any) => {
    const filteredItems = formik.values.items.filter((campaignItem: any) => campaignItem.itemId !== item.itemId);
    formik.setFieldValue('items', filteredItems);
  };

  const formik = useFormik({
    initialValues: { items: [] },
    onSubmit: async (values, helpers): Promise<void> => {
      setIsSubmitting(true);
      await handleSubmit(values.items);

      setIsSubmitting(false);
    }
  });

  const handleAutocompleteChange = (event: any, newValue: any) => {
    setSelectedItem(newValue);
  };

  return (
    <Box>
      <Container maxWidth="md">
        <Dialog open={isShowing} maxWidth="md" fullWidth={true} disablePortal sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <DialogContent>
            <Typography variant="h6" sx={{ mb: 4 }}>
              Add Product
            </Typography>
            <Box display={'flex'}>
              <Box sx={{ width: '30%', pr: 2, display: 'flex', flexDirection: 'column' }}>
                <Autocomplete
                  sx={{ mb: 1 }}
                  multiple
                  options={products}
                  getOptionLabel={(option) => `${option.itemId} - ${option.itemName}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px' }}>
                          <span>
                            <b>{option.itemId}</b>
                          </span>
                          <p>{option.itemName}</p>
                        </div>
                        <img style={productDesign.img} src={option.itemImageUrl} alt={option.itemName || ''} />
                      </Box>
                    </li>
                  )}
                  disableCloseOnSelect
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Select item(s)" />}
                  onChange={handleAutocompleteChange}
                  value={selectedItem}
                />
                <TextField
                  id="campaignItemIds"
                  label="Additional items"
                  onChange={(event) => {
                    setUserInputItemIds(event.target.value);
                  }}
                  placeholder="Enter TCINs separated by new lines"
                  multiline
                  value={userInputItemIds}
                  rows={12}
                  fullWidth
                  inputProps={{
                    style: {
                      fontSize: '12px'
                    }
                  }}
                  sx={{ m: 1, mb: 2 }}
                />

                <div style={{ marginTop: 'auto' }}>
                  <Button
                    sx={{ mt: 2 }}
                    variant="outlined"
                    onClick={addItems}
                    disabled={!(selectedItem.length || userInputItemIds)}
                  >
                    Add Items
                  </Button>
                </div>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ width: '70%', pl: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" sx={{ mb: 2 }} onClick={() => formik.setFieldValue('items', [])}>
                    Remove All Items
                  </Button>
                </Box>

                {!itemSearchIsLoading ? (
                  <CampaignItemsTable formik={formik} removeItem={removeItem} />
                ) : (
                  <Box display={'flex'} alignItems="center" justifyContent={'center'} sx={{ height: '100%' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => toggle()}>
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              disabled={isSubmitting || !formik.isValid || !formik.dirty}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
