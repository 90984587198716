import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { DOLLAR_AMOUNT_METRIC_TYPES, V1_METRIC_TYPES } from '../../types/keyword-harvesting';

export const MetricMinimumFormField = (props: any) => {
  const { metric, index, setFieldValue, arrayHelpers } = props;

  return (
    <Box sx={{ my: 3 }}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={7}>
          {' '}
          <Autocomplete
            id={`metricMinimums.${index}.metricType`}
            includeInputInList
            value={V1_METRIC_TYPES.find((type) => type.value === metric.metricType)}
            options={V1_METRIC_TYPES}
            style={{ width: '100%' }}
            onChange={(e, value) => {
              setFieldValue(`metricMinimums.${index}.metricType`, value !== null ? value.value : metric.metricType);
            }}
            renderInput={(params) => <TextField {...params} size="small" label="Metric Type" />}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6" sx={{ ml: 2, margin: 'auto', display: 'flex', justifyContent: 'center' }}>
            {'>'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            sx={{ ml: 2 }}
            name={`metricMinimums.${index}.value`}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ visibility: DOLLAR_AMOUNT_METRIC_TYPES.includes(metric.metricType) ? 'visible' : 'hidden' }}
                >
                  $
                </InputAdornment>
              )
            }}
            onChange={(event) => {
              setFieldValue(`metricMinimums.${index}.value`, event.target.value !== null ? event.target.value : metric.value);
            }}
            value={metric.value}
          />
        </Grid>
        <Grid item xs={1} sx={{ justifyContent: 'center' }}>
          <IconButton onClick={() => arrayHelpers.remove(index)} sx={{ display: 'flex', margin: 'auto' }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
