import React from 'react';
import { Grid, Typography } from '@mui/material';
import { listOfDays } from '../../../shared/types/day-parting';

const DaysLabel: React.FC = () => (
  <Grid item display="flex" flexDirection={'column'} justifyContent="space-evenly">
    {listOfDays.map((day, index) => (
      <Typography key={index} variant="h6" component="div" width="50px">
        {day}
      </Typography>
    ))}
  </Grid>
);

export default DaysLabel;
