import * as React from 'react';
import clsx from 'clsx';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { AmazonCampaignType } from '../../../types/campaign';

// This component is forked from mui "https://github.com/mui/mui-x/blob/next/packages/x-data-grid-generator/src/renderer/renderProgress.tsx"
//TODO: add the time it stopped at (preferably on hover) when we get the data from AMS data

interface ProgressBarProps {
  value: number;
}

const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 26,
  borderRadius: 5
}));

const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
});

const Bar = styled('div')(({ theme }) => ({
  height: '100%',
  '&.low': {
    background: `linear-gradient(to left, ${theme.palette.error.dark}CC, ${theme.palette.error.dark}1A)`
  },
  '&.medium': {
    background: `linear-gradient(to left, ${theme.palette.warning.dark}CC, ${theme.palette.warning.dark}1A)`
  },
  '&.high': {
    background: `linear-gradient(to left, ${theme.palette.success.dark}CC, ${theme.palette.success.dark}1A)`
  },
  '&.completed': {
    background: `${theme.palette.success.dark}CC`
  }
}));

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
  const { value } = props;

  return (
    <Root>
      <Value>{`${value.toLocaleString()} %`}</Value>
      <Bar
        className={clsx({
          low: value < 30,
          medium: value >= 30 && value <= 70,
          high: value > 70,
          completed: value === 100
        })}
        style={{ maxWidth: `${value}%` }}
      />
    </Root>
  );
});

export function renderCampaignPercentInBudget(params: GridRenderCellParams<any, number, any>) {
  if (params.value == null) {
    return '';
  }

  // If the aggregated value does not have the same unit as the other cell
  // Then we fall back to the default rendering based on `valueGetter` instead of rendering a progress bar.
  if (params.aggregation && !params.aggregation.hasCellUnit) {
    return null;
  }

  if (params.row.campaignType !== AmazonCampaignType.SPONSORED_PRODUCTS) {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="caption" sx={{ display: 'flex', justifyContent: 'center' }}>
          N/A
        </Typography>
      </Box>
    );
  } else {
    return <ProgressBar value={params.value} />;
  }
}
