import { Typography, Link, Box } from '@mui/material';
import ProductTargetLoadingSkeleton from './product-target-loading-skeleton';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

interface ProductTargetCellProps {
  isLoadingItemDetails: boolean;
  itemDetails: any;
  title: string;
  asin: string;
}
const ProductTargetCell = (props: ProductTargetCellProps) => {
  const { title, asin, isLoadingItemDetails } = props;

  if (isLoadingItemDetails) {
    return <ProductTargetLoadingSkeleton />;
  }

  const itemDetail = props.itemDetails?.find((item: any) => item.asin === asin);

  const itemName = itemDetail?.itemName;
  const imageUrl = itemDetail?.imageUrl;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 1
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {imageUrl ? (
          <img src={imageUrl ?? ''} alt={itemName} width="50" height="50" />
        ) : (
          <ImageNotSupportedIcon color="disabled" />
        )}

        <Box sx={{ marginLeft: 1 }}>
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                fontSize: '10px',
                flexWrap: 'wrap',
                overflowWrap: 'break-word',
                display: 'flex'
              }}
              title={itemName}
            >
              <strong>{title}:&nbsp;</strong>
              <Link
                href={`https://www.amazon.com/dp/${asin}`}
                target="_blank"
                rel="noopener"
                sx={{
                  fontWeight: 'normal',
                  overflow: 'hidden',
                  maxWidth: '250px',
                  textOverflow: 'ellipsis',
                  color: 'text.secondary'
                }}
              >
                {itemName ?? 'Item Info Not Available'}&nbsp;
              </Link>
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ fontSize: '10px', color: 'text.primary' }}>
            ASIN:&nbsp;{asin}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductTargetCell;
