import { GridColDef } from '@mui/x-data-grid-premium';
import RenderProductImage from '../renderer/renderProductImage';
import { METRIC_COLUMNS } from './metric-columns';

export const PRODUCT_AD_COLUMNS: GridColDef[] = [
  {
    field: 'campaignId',
    headerName: 'Campaign Id',
    width: 150,
    type: 'string'
  },
  {
    field: 'catalogId',
    headerName: 'Catalog Id',
    width: 150,
    type: 'string'
  },
  {
    field: 'productCode',
    headerName: 'Product Code',
    width: 150,
    type: 'string'
  },
  {
    field: 'productImageUrl',
    headerName: 'Product Image',
    width: 150,
    editable: false,
    align: 'left',
    renderCell: (params) => <RenderProductImage params={params} />
  },
  {
    field: 'productName',
    headerName: 'Product Name',
    width: 150,
    editable: false,
    align: 'left'
  },

  ...METRIC_COLUMNS
];
