import { FC, useEffect, useState } from 'react';
import { useLocation, Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Link, Stack, Breadcrumbs as MUIBreadcrumbs, Typography, Skeleton } from '@mui/material';
import useCitrusAdApi from '../../shared/hooks/use-citrusad-api';
import { Platform } from '../../shared/types/platforms';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSnackbar } from 'notistack';
import { useCurrentRetailerPlatform } from '../../shared/hooks/use-current-retailer-platform';

const CitrusBreadcrumbs: FC = () => {
  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const retailerPlatform = useCurrentRetailerPlatform();

  const [profileId, setProfileId] = useState<string>(searchParams.get('profileId') || '');
  const [campaignId, setCampaignId] = useState<string>(searchParams.get('campaignId') || '');
  const [profileName, setProfileName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getTeamById } = useCitrusAdApi(Platform?.CITRUS_AD?.value);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setProfileId(searchParams.get('profileId') || '');
    setCampaignId(searchParams.get('campaignId') || '');

    if (!searchParams.get('campaignId') && !searchParams.get('profileId') && !searchParams.get('adGroupId')) {
      setIsLoading(false);
    }
  }, [location, searchParams]);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);

      await Promise.all([fetchProfile()]);

      setIsLoading(false);
    };

    initialize();
  }, [profileId, campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProfile = async () => {
    if (!profileId) {
      return;
    }

    const response = await getTeamById(profileId);

    if (response.success) {
      setProfileName(response.body?.records[0]?.name);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const crumbModel = [
    {
      key: `/${retailerPlatform}/profiles`,
      name: 'All Profiles',
      href: `/${retailerPlatform}/profiles`
    },
    {
      key: `/${retailerPlatform}/campaign`,
      name: profileName,
      href: `/${retailerPlatform}/campaign?profileId=${profileId}`
    }
  ];

  const crumbs = getCrumbs();

  function getCrumbs() {
    const currentLocation = location.pathname;
    const crumbs: React.ReactElement[] = [];

    if (!profileId) {
      return null;
    }

    crumbModel.forEach((crumbModel) => {
      if (!!crumbModel.name) {
        if (crumbModel.key === currentLocation) {
          crumbs.push(<Typography key={crumbModel.key}>{crumbModel.name}</Typography>);
        } else {
          crumbs.push(
            <Link to={crumbModel.href} component={RouterLink} key={crumbModel.key}>
              {crumbModel.name}
            </Link>
          );
        }
      }
    });

    return crumbs;
  }

  return (
    <Stack>
      {isLoading ? (
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Skeleton sx={{ minWidth: 350 }} />
        </Stack>
      ) : (
        <MUIBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {crumbs}
        </MUIBreadcrumbs>
      )}
    </Stack>
  );
};

export default CitrusBreadcrumbs;
