import { GridColDef, GridEditInputCell, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { METRIC_COLUMNS } from './metric-columns';
import { getOffPaceFontColor } from '../../../../shared/components/grid-components/pacing-font-color';
import { Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { citrusAdRoutes } from '../../../../shared/routing/routes-objects';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';

export const CITRUSAD_PROFILE_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 170,
    minWidth: 130,
    editable: false,
    align: 'left',
    renderCell: (params) => {
      return (
        <Link
          to={`${citrusAdRoutes.find((route) => route.key === 'citrusad-campaigns')?.path}?profileId=${params.id}`}
          sx={{ textDecoration: 'none', color: 'text.primary' }}
          component={RouterLink}
        >
          {params.value}
        </Link>
      );
    }
  },
  {
    field: 'teamId',
    headerName: 'Team ID',
    width: 100,
    minWidth: 50,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'totalCampaigns',
    headerName: 'Total Campaigns',
    width: 100,
    minWidth: 50,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'totalActiveCampaigns',
    headerName: 'Active Campaigns',
    width: 100,
    minWidth: 50,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'totalSpend',
    headerName: 'Total Spend',
    width: 200,
    minWidth: 120,
    editable: false,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'offTargetPercent',
    headerName: '% Off Pace',
    width: 120,
    minWidth: 60,
    editable: false,
    renderCell: ({ value }) => {
      return (
        <Box sx={{ py: 2, width: '100%' }}>
          <Typography variant="body2" sx={{ color: getOffPaceFontColor(Number(value)), float: 'right' }}>
            {value}%
          </Typography>
        </Box>
      );
    }
  },

  {
    field: 'roasGoal',
    headerName: 'RoAS Goal',
    width: 120,
    minWidth: 80,
    editable: false,
    valueFormatter: renderAsCurrencyFormatter,
    cellClassName: () => {
      return 'numeric-edit-cell';
    },
    renderEditCell: (props: GridRenderEditCellParams) => {
      return <GridEditInputCell {...props} sx={{ border: 1, borderColor: 'success.main', borderRadius: '4px' }} />; //TODO: this is not editable for some reason VEC-598
    }
  },
  ...METRIC_COLUMNS
];
