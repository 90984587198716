import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Page from '../../../../page';
import useAdsApi from '../../../../../hooks/use-walmart-sams-club-api';
import { usePageable } from '../../../../../hooks/use-pageable';
import { Campaign } from '../../../../../types/walmart-sams-club/campaign';
import {
  orderByReviewIdDesc,
  Review as TReview,
  SbReviewRequest as WalmartSbaReviewRequest
} from '../../../../../types/walmart-sams-club/review';
import { CreateReviewDialog } from './create-review-dialog';
import { useSnackbar } from 'notistack';
import { CancelReviewDialog } from './cancel-review-dialog';
import { ItemStatusTable, KeywordStatusTable } from '../review-status-tables';
import { AdGroupMediaDetails } from './ad-group-media-details';
import { StyledConnector, StyledStepIcon } from '../review-stepper';
import { Filter, Pageable, buildPageableFilters } from '../../../../../types/pageable';

export const SponsoredVideoReview = () => {
  const {
    getSbaReviews: walmartGetSbaReviews,
    getCampaign,
    getAds,
    getKeywords,
    getAdGroups,
    getAdGroupMedia,
    walmartCreateSbaReview,
    cancelSbaReviews: walmartCancelSbaReviews
  } = useAdsApi();

  const [campaign, setCampaign] = useState<Campaign>();
  const [adGroupMedia, setAdGroupMedia] = useState(null);
  const [ads, setAds] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
  const [isCancelReviewDialogOpen, setIsCancelReviewDialogOpen] = useState(false);
  const [review, setReview] = useState<TReview>({
    adGroupId: 0,
    campaignId: 0,
    reviewComments: [{ commentType: '', comments: '' }],
    reviewId: 0,
    reviewStatus: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageable, setPageable } = usePageable();
  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  const campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;
  const [adGroupId, setAdGroupId] = useState(searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  if (!profileId || !campaignId) {
    navigate('/campaigns');
  }

  const fetchCampaign = async () => {
    const campaignResponse = await getCampaign(profileId, campaignId);

    if (campaignResponse.success) {
      setCampaign(campaignResponse?.body);
    }
  };

  const fetchAdGroupMedia = async () => {
    if (!campaignId) {
      return;
    }
    const adGroupMediaResponse = await getAdGroupMedia(campaignId);

    if (adGroupMediaResponse.success) {
      setAdGroupMedia(adGroupMediaResponse?.body[0]);
    }
  };

  const fetchKeywords = async () => {
    if (!pageable) {
      return;
    }

    const keywordResponse = await getKeywords(pageable, null, null);

    if (keywordResponse.success) {
      setKeywords(keywordResponse?.body?.records);
    }
  };

  const fetchReview = async () => {
    const reviewResponse = await walmartGetSbaReviews(profileId, campaignId, adGroupId);

    if (!reviewResponse.success) {
      console.error('Failed to fetch review');
      return;
    }

    const reviewArray = reviewResponse?.body;
    reviewArray.sort(orderByReviewIdDesc);

    let review = reviewArray[0];
    setReview(review);
  };

  const fetchItems = async () => {
    if (!pageable) {
      return;
    }

    const adResponse = await getAds(pageable, null, null);

    if (adResponse.success) {
      setAds(adResponse?.body?.records);
    }
  };

  const fetchAdGroupId = async () => {
    const adGroupsResponse = await getAdGroups(profileId, campaignId, null, null);

    if (adGroupsResponse.success) {
      setAdGroupId(adGroupsResponse?.body[0].adGroupId);
      setSearchParams(
        {
          profileId: profileId?.toString() || '',
          campaignId: campaignId?.toString() || '',
          adGroupId: adGroupsResponse?.body[0].adGroupId
        },
        { replace: true }
      );
    }
  };

  const initialize = async () => {
    if (!pageable) {
      return;
    }

    if (!isLoading) {
      setIsLoading(true);
    }

    await Promise.all([fetchReview(), fetchCampaign(), fetchItems(), fetchKeywords(), fetchAdGroupMedia()]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!profileId || !campaignId) {
      return;
    }

    setIsLoading(true);

    if (!adGroupId) {
      fetchAdGroupId();
      return;
    }

    const pageableFilters: Filter[] = buildPageableFilters({ ...{ profileId, campaignId, adGroupId } });

    const pageable = {
      filters: pageableFilters,
      sorts: [],
      offset: 0,
      limit: 250
    } as Pageable;

    setPageable(pageable);
  }, [profileId, campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initialize();
  }, [pageable]); // eslint-disable-line react-hooks/exhaustive-deps

  const createReview = async () => {
    setIsLoading(true);
    const reviewRequest: WalmartSbaReviewRequest = {
      adGroupId: adGroupId?.toString() || '',
      campaignId: campaignId?.toString() || ''
    };

    const response = await walmartCreateSbaReview(profileId, reviewRequest);

    if (response.success) {
      initialize();
      enqueueSnackbar('Review created successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Failed to create review', { variant: 'error' });
    }

    setIsLoading(false);
    setIsReviewDialogOpen(false);
  };

  const cancelReview = async () => {
    setIsLoading(true);

    const cancelRequest = {
      reviewId: review?.reviewId.toString(),
      campaignId: campaignId?.toString() || '',
      adGroupId: adGroupId?.toString() || ''
    };

    const response = await walmartCancelSbaReviews(profileId, cancelRequest);
    if (response.success) {
      fetchReview();
      enqueueSnackbar('Review cancelled successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Failed to cancel review', { variant: 'error' });
    }

    setIsCancelReviewDialogOpen(false);
    setIsLoading(false);
  };

  const reviewSteps = [
    {
      key: 0,
      states: ['cancelled', undefined, null],
      label: 'Unsubmitted'
    },
    {
      key: 1,
      states: ['pending', 'inprogress'],
      label: 'Pending'
    },
    {
      key: 2,
      states: ['complete'],
      label: 'Complete'
    }
  ];

  const activeStep = reviewSteps.findIndex((step) => step.states.includes(review?.reviewStatus?.toLowerCase()));

  return (
    <Page title={`Review Status - ${campaign?.name}`}>
      <Box>
        <Container maxWidth="xl">
          <Container maxWidth="md" sx={{ mt: 2 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Grid item>
                {' '}
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {isLoading ? <Skeleton width={500} /> : campaign?.name}
                </Typography>
              </Grid>
            </Grid>

            <Stepper activeStep={activeStep} connector={<StyledConnector />} sx={{ mb: 2 }}>
              {reviewSteps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={step.label} {...stepProps}>
                    <StepLabel StepIconComponent={StyledStepIcon}>
                      {index === activeStep ? (
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {step.label}
                        </Typography>
                      ) : (
                        <Typography variant="body2">{step.label}</Typography>
                      )}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box mt={2}>
              {adGroupMedia && (
                <AdGroupMediaDetails
                  adGroupMedia={adGroupMedia}
                  review={review}
                  onCreate={() => setIsReviewDialogOpen(true)}
                  onCancel={() => setIsCancelReviewDialogOpen(true)}
                />
              )}
            </Box>
            <Box mt={2}>{ads && <ItemStatusTable items={ads} review={review} />}</Box>
            <Box mt={2}>{keywords && <KeywordStatusTable keywords={keywords} review={review} />}</Box>
          </Container>
        </Container>
      </Box>
      <CreateReviewDialog open={isReviewDialogOpen} onClose={() => setIsReviewDialogOpen(false)} onSave={createReview} />
      <CancelReviewDialog
        open={isCancelReviewDialogOpen}
        onClose={() => setIsCancelReviewDialogOpen(false)}
        onSubmit={cancelReview}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};
