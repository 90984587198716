import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { useSearchParams } from 'react-router-dom';

import ProductSearchResultsList from './product-search-results-list';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Tabs, Tab, TextField, InputAdornment, Box, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface ProductSearchContainerProps {
  negativeTargetingClauses: any;
  setNegativeTargetingClauses: (negativeTargetingClauses: any) => void;
  isCampaignNegativeTarget: boolean;
}

export const ProductSearchContainer = (props: ProductSearchContainerProps) => {
  const { negativeTargetingClauses, setNegativeTargetingClauses, isCampaignNegativeTarget } = props;
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [productSearchResults, setProductSearchResults] = useState<any[]>([]);
  const { searchCatalogItemsKeywords } = useAmazonApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [pageTokens, setPageTokens] = useState<string[]>([]);
  const [numberOfResults, setNumberOfResults] = useState(0);

  const [productOrBrandTabIndex, setProductOrBrandTabIndex] = useState(0);
  let profileId = searchParams.get('profileId');
  let campaignId = searchParams.get('campaignId');
  let adGroupId = searchParams.get('adGroupId');

  const fetchProductSearchResults = async (searchTerm: string, pageToken?: string) => {
    if (!profileId) {
      return;
    }

    setIsLoading(true);

    if (!searchTerm) {
      setProductSearchResults([]);
      setIsLoading(false);
      return;
    }

    const response = await searchCatalogItemsKeywords(searchTerm, pageToken);

    if (response?.success && response?.body) {
      setProductSearchResults(response.body?.items);
      setNumberOfResults(response.body?.numberOfResults);
      if (!pageTokens.some((token) => token === response.body?.nextToken)) {
        setPageTokens((prev) => [...prev, response.body?.nextToken]);
      }
    } else {
      enqueueSnackbar('Failed to fetch search results', { variant: 'error' });
    }

    setIsLoading(false);
  };

  const handleExcludeProduct = (product: any) => {
    if (isCampaignNegativeTarget) {
      const campaignNegativeTargetingClause = {
        negativeTargetType: 'TargetingClause',
        retailer: 'Amazon',
        campaignId: campaignId,
        state: 'Enabled',
        expression: [
          {
            type: 'AsinSameAs',
            value: product.asin
          }
        ],
        resolvedExpression: [
          {
            type: 'AsinSameAs',
            value: product.itemName
          }
        ]
      };

      setNegativeTargetingClauses([...negativeTargetingClauses, campaignNegativeTargetingClause]);
    } else {
      const brandNegativeTargetingClause = {
        negativeTargetType: 'TargetingClause',
        retailer: 'Amazon',
        adGroupId: adGroupId,
        state: 'Enabled',
        expression: [
          {
            type: 'AsinSameAs',
            value: product.asin
          }
        ],
        resolvedExpression: [
          {
            type: 'AsinSameAs',
            value: product.itemName
          }
        ]
      };

      setNegativeTargetingClauses([...negativeTargetingClauses, brandNegativeTargetingClause]);
    }
  };

  const handlePageBack = () => {
    // Check if it's possible to go back
    if (pageTokens.length > 2) {
      const previousPageToken = pageTokens[pageTokens.length - 3]; // Get the token for the previous page
      setPage(page - 1); // Update the page state
      fetchProductSearchResults(productSearchTerm, previousPageToken);
      let tokenArrayCopy = [...pageTokens];
      tokenArrayCopy.pop(); // Remove the last token which points to the next page
      tokenArrayCopy.pop(); // Remove the second to last token which points to the current page and will be readded in the previous page fetch call

      setPageTokens(tokenArrayCopy); // Update the token array
    } else {
      setPage(1);
      setPageTokens([]);
      fetchProductSearchResults(productSearchTerm);
    }
  };

  const handlePageForward = () => {
    setPage(page + 1);
    fetchProductSearchResults(productSearchTerm, pageTokens[pageTokens.length - 1]);
  };

  return (
    <>
      <Tabs sx={{ mb: 2 }} value={0}>
        <Tab sx={{ fontSize: '12px' }} label="Search Products" />
      </Tabs>

      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        value={productSearchTerm}
        onChange={(event) => setProductSearchTerm(event.target.value)}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            setPage(1);
            setPageTokens([]);
            setNumberOfResults(0);
            fetchProductSearchResults(productSearchTerm);
          }
        }}
        placeholder="Search by product name"
        size="small"
        fullWidth
      />
      <ProductSearchResultsList
        products={productSearchResults}
        addedNegativeTargetingClauses={negativeTargetingClauses}
        handleExcludeProduct={handleExcludeProduct}
        isLoading={isLoading}
        height={298}
      />
      <Box border={1} borderColor={'divider'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        <Typography>
          {page * pageSize - pageSize + 1}-{page * pageSize} of {numberOfResults}
        </Typography>
        <IconButton disabled={page === 1} onClick={handlePageBack}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton onClick={handlePageForward} disabled={page * pageSize >= numberOfResults}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="caption" color="text.secondary" component={'div'}>
          <strong>Negative targets added: </strong>
          {negativeTargetingClauses?.length}
        </Typography>
      </Box>
    </>
  );
};
