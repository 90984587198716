import {
  Avatar,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ResponseObject } from './utilities/fetch-utilities';

interface SubmitReviewProps {
  campaignBuilderResponse: ResponseObject;
  handleNavigateToCampaigns: () => void;
  genericErrorText: string;
}

export const ErrorReview = (props: SubmitReviewProps) => {
  const { campaignBuilderResponse, handleNavigateToCampaigns, genericErrorText } = props;

  const { errors } = campaignBuilderResponse?.body;

  return (
    <div>
      <Button variant={'outlined'} startIcon={<KeyboardBackspaceIcon />} sx={{ mb: 2 }} onClick={handleNavigateToCampaigns}>
        Return to Campaigns
      </Button>
      <Typography variant={'h5'} sx={{ mb: 2 }}>
        Uh oh! Something went wrong.
      </Typography>
      <Typography variant={'body1'} sx={{ mb: 2 }}>
        {genericErrorText}
      </Typography>
      <TableContainer component={Paper} sx={{ border: '1px solid lightgray', maxWidth: '800px', borderRadius: '4px', mt: 2 }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ whiteSpace: 'nowrap' }}></TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>Error Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((error: any, index: number) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Avatar
                      sx={{
                        backgroundColor: 'error.main',
                        color: 'success.contrastText',
                        height: 30,
                        width: 30
                      }}
                    >
                      <PriorityHighIcon />
                    </Avatar>
                  </TableCell>
                  <TableCell>{error}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
