import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Link as MaterialLink } from '@mui/material';

interface StyledLinkProps {
  to: string;
  children: ReactNode;
  target?: string;
  disabled?: boolean;
}

const StyledLink: FC<StyledLinkProps> = ({ children, to, target, disabled }) => {
  if (disabled === true) {
    return <MaterialLink sx={{ pointerEvents: 'none', opacity: 0.7 }}>{children}</MaterialLink>;
  }

  return (
    <Link to={to} style={{ textDecoration: 'none', width: '100%' }} target={target}>
      {children}
    </Link>
  );
};

export default StyledLink;
