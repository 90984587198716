import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BlockIcon from '@mui/icons-material/Block';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

export type StateOption = {
  label: string;
  value: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
};

export const DEFAULT_STATE_OPTIONS: StateOption[] = [
  {
    label: 'Enabled',
    value: 'enabled',
    icon: PlayArrowIcon
  },
  {
    label: 'Paused',
    value: 'paused',
    icon: PauseIcon
  },
  {
    label: 'Archived',
    value: 'archived',
    icon: BlockIcon
  }
];

export enum STATE_OPTIONS {
  ENABLED = 'enabled',
  PAUSED = 'paused',
  ARCHIVED = 'archived'
}
