import { IconButton, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Delete } from '@mui/icons-material';
import { FC } from 'react';
interface CampaignItemsTableProps {
  formik: any;
  removeItem: (item: any) => void;
}

export const CampaignItemsTable: FC<CampaignItemsTableProps> = (props) => {
  const { formik, removeItem } = props;

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: '400px', minHeight: '400px', border: '1px solid divider', borderRadius: '0px' }}
    >
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Item Image</TableCell>
            <TableCell>Item Id</TableCell>
            <TableCell>Item Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formik.values.items.map((item: any) => (
            <TableRow key={item.itemId}>
              <TableCell>
                <img src={item.itemImageUrl} alt={item.itemName} width={50} />
              </TableCell>
              <TableCell sx={{ fontSize: '11px' }}>{item.itemId}</TableCell>
              <TableCell>
                <Tooltip title={item.itemName}>
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: '11px'
                    }}
                  >
                    {item.itemName.substring(0, 20) + '...' + item.itemName.substring(item.itemName.length - 20)}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <IconButton onClick={() => removeItem(item)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
