import React, { ReactNode } from 'react';
import { Chip, Typography } from '@mui/material';
import { AmazonExpressionTypes } from '../../../../../types/targeting-refinements';

interface AudienceTargetProps {
  expression: any;
}

function renderType(type: string) {
  if (type.toLowerCase() === AmazonExpressionTypes.exactProduct.toLowerCase()) {
    return (
      <React.Fragment>
        <strong>Exact Product</strong>
      </React.Fragment>
    );
  } else if (type.toLowerCase() === AmazonExpressionTypes.asinCategorySameAs.toLowerCase()) {
    return (
      <React.Fragment>
        <strong>Category: </strong>
      </React.Fragment>
    );
  } else if (type.toLowerCase() === AmazonExpressionTypes.similarProduct.toLowerCase()) {
    return (
      <React.Fragment>
        <strong>Similar Product</strong>
      </React.Fragment>
    );
  } else if (type.toLowerCase() === AmazonExpressionTypes.relatedProduct.toLowerCase()) {
    return (
      <React.Fragment>
        <strong>Related Product</strong>
      </React.Fragment>
    );
  } else if (type.toLowerCase() === AmazonExpressionTypes.lookback.toLowerCase()) {
    return (
      <React.Fragment>
        <strong>Lookback: </strong>
      </React.Fragment>
    );
  } else if (type.toLowerCase() === AmazonExpressionTypes.audienceSameAs.toLowerCase()) {
    return null;
  }
}

function cleanAndParseJson(value: string) {
  const cleanedString = value.replace(/\n/g, '').trim();
  try {
    return JSON.parse(cleanedString);
  } catch (e) {
    console.error('Error parsing JSON:', e);
    return value; // Returning the original string in case of parsing failure
  }
}

function renderData(type: string, value: any) {
  return (
    <React.Fragment>
      <Chip label={type} />
      {renderChildData(value)}
    </React.Fragment>
  );
}

function renderChildData(nestedExpression: any): ReactNode {
  if (Array.isArray(nestedExpression)) {
    return nestedExpression.map((expression: any, index: number) => {
      return (
        <React.Fragment key={`exp-${index}`}>
          <strong style={{ marginLeft: 2, marginRight: 1 }}>{renderType(expression.type)} </strong>
          {renderChildData(expression.value)}
        </React.Fragment>
      );
    });
  } else {
    return <span style={{ marginLeft: 2, marginRight: 2 }}>{nestedExpression} </span>;
  }
}

const AudienceTargetCell: React.FC<AudienceTargetProps> = ({ expression }) => {
  if (!expression) {
    return <Typography>Not available</Typography>;
  }

  const renderExpression = (expression: { type: string; value: string }) => {
    const parsedValue = expression.value ? cleanAndParseJson(expression.value) : 'Value not available';

    switch (expression.type.toLowerCase()) {
      case 'views':
      case 'audience':
      case 'purchases':
      case 'asinsameas':
      case 'similarproduct':
        return renderData(expression.type, parsedValue);
      default:
        return <span>{JSON.stringify(expression)}</span>; // Raw object rendering for edge cases
    }
  };

  return (
    <Typography color="text.primary" component={'div'} fontSize={11}>
      {expression.map((exp: any, index: number) => (
        <React.Fragment key={`exp-${index}`}>{renderExpression(exp)}</React.Fragment>
      ))}
    </Typography>
  );
};

export default AudienceTargetCell;
