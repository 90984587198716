import React, { useState, useEffect, Dispatch, SetStateAction, ChangeEvent } from 'react';
import {
  Box,
  Skeleton,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Tooltip,
  Slider,
  useTheme,
  Typography
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import HourLabels from './hour-labels';
import DaysLabel from './days-label';
import DayScheduleRow from './day-schedule-row';
import { DaypartingLegend } from './day-parting-legend';
import { DaypartingMode, DaypartingSchedule, DaypartingScheduleState, DAYS, Point } from './day-parting-types';

interface HourlySchedulesContainerProps {
  isLoading: boolean;
  daySchedules: DaypartingSchedule;
  setDaySchedules: Dispatch<SetStateAction<DaypartingSchedule>>;
  stats: any;
  shouldShowOutOfBudgetHour?: boolean;
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
}

const HourlySchedulesContainer: React.FC<HourlySchedulesContainerProps> = ({
  isLoading,
  daySchedules,
  setDaySchedules,
  stats,
  shouldShowOutOfBudgetHour,
  isDragging,
  setIsDragging
}) => {
  const theme = useTheme();
  const [firstClicked, setFirstClicked] = useState<Point>({ day: DAYS.Monday, hour: 1 });
  const [firstClickedValue, setFirstClickedValue] = useState<DaypartingScheduleState>(DaypartingScheduleState.Disabled);
  const [lastClicked, setLastClicked] = useState<Point>({ day: DAYS.Monday, hour: 1 });
  const [daySchedulesBeforeDrag, setDaySchedulesBeforeDrag] = useState<DaypartingSchedule>(daySchedules);
  const [currentMode, setCurrentMode] = useState<DaypartingMode>(DaypartingMode.EnableDisable);
  const [bidDecreases, setBidDecreases] = useState<{ [key: number]: { [key: number]: number } }>({});
  const [bidValue, setBidValue] = useState<number>(0);

  const handleUpdateSchedule = () => {
    const newDaySchedules = cloneDeep(daySchedulesBeforeDrag || daySchedules);
    const newBidDecreases = cloneDeep(bidDecreases);

    const { day: firstDay, hour: firstHour } = firstClicked;
    const { day: lastClickedDay, hour: lastClickedHour } = lastClicked;

    const minY = Math.min(firstDay, lastClickedDay);
    const maxY = Math.max(firstDay, lastClickedDay);
    const minX = Math.min(firstHour, lastClickedHour);
    const maxX = Math.max(firstHour, lastClickedHour);

    for (let i = minY; i <= maxY; i++) {
      for (let j = minX; j <= maxX; j++) {
        if (currentMode === DaypartingMode.EnableDisable) {
          newDaySchedules[i][j].State = firstClickedValue;
          newDaySchedules[i][j].BidAdjustmentPercentage = 0;
        } else if (currentMode === DaypartingMode.BidDecrease) {
          if (!newBidDecreases[i]) {
            newBidDecreases[i] = {};
          }

          newDaySchedules[i][j].State = DaypartingScheduleState.BidDecrease;
          newDaySchedules[i][j].BidAdjustmentPercentage = bidValue;
          newBidDecreases[i][j] = bidValue;

          if (bidValue === 0) {
            newDaySchedules[i][j].State = DaypartingScheduleState.Disabled;
            delete newBidDecreases[i][j];
          }

          if (bidValue > 0 && bidValue < 100) {
            newDaySchedules[i][j].State = DaypartingScheduleState.BidDecrease;
            newDaySchedules[i][j].BidAdjustmentPercentage = bidValue;
          }
        }
      }
    }

    setDaySchedules(newDaySchedules);
    setBidDecreases(newBidDecreases);
  };

  function checkCapOutHour(day: number, hour: number): boolean {
    return stats.some(
      (stat: any) => stat?.dailyOutOfBudgetDateTime?.getHours() === hour && stat?.dailyOutOfBudgetDateTime?.getDay() === day
    );
  }

  const handleBidValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);

    if (value < 1 || value > 99) {
      enqueueSnackbar('Please enter a bid decrease value between 1 and 99', { variant: 'warning' });
      return;
    }

    if (!isNaN(value) && value >= 1 && value < 100) {
      setBidValue(value);
    }
  };

  useEffect(() => {
    if (isDragging) {
      handleUpdateSchedule();
    }
  }, [lastClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Skeleton variant="rectangular" width={600} height={200} />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="mode"
              name="row-radio-buttons-group"
              value={currentMode}
              onChange={(e) => setCurrentMode(e.target.value as DaypartingMode)}
            >
              <FormControlLabel value={DaypartingMode.EnableDisable} control={<Radio />} label="Enable/Disable" />
              <FormControlLabel
                value={DaypartingMode.BidDecrease}
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color:
                          bidValue > 75
                            ? theme.palette.warning.dark
                            : bidValue < 25
                              ? theme.palette.warning.light
                              : theme.palette.warning.main
                      }
                    }}
                  />
                }
                label={currentMode !== DaypartingMode.BidDecrease ? 'Decrease Bid' : 'Decrease Bid By'}
              />
              {currentMode === DaypartingMode.BidDecrease && (
                <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '13.5rem' }}>
                  <Tooltip arrow placement="top" title="Minimum bid will be applied if decreased bid is below the minimum.">
                    <TextField
                      type="number"
                      value={bidValue}
                      onChange={handleBidValueChange}
                      defaultValue={1}
                      sx={{
                        '& .MuiInput-underline:after': {
                          borderBottomColor:
                            bidValue > 75
                              ? theme.palette.warning.dark
                              : bidValue < 25
                                ? theme.palette.warning.light
                                : theme.palette.warning.main
                        }
                      }}
                      InputProps={{
                        endAdornment: <Box>%</Box>,
                        startAdornment: (
                          <Box>
                            <Slider
                              sx={{
                                width: '9rem',
                                mr: '1rem',
                                color:
                                  bidValue > 75
                                    ? theme.palette.warning.dark
                                    : bidValue < 25
                                      ? theme.palette.warning.light
                                      : theme.palette.warning.main
                              }}
                              size="small"
                              value={bidValue}
                              onChange={(e, value) => {
                                if (typeof value === 'number' && value >= 1 && value < 100) {
                                  setBidValue(value);
                                }
                              }}
                              defaultValue={1}
                              min={1}
                              max={99}
                              valueLabelDisplay="auto"
                              aria-labelledby="bid-decrease-slider"
                            />
                          </Box>
                        )
                      }}
                      variant="standard"
                      size="small"
                    />
                  </Tooltip>
                </Box>
              )}
            </RadioGroup>
          </FormControl>
          <Box>
            <HourLabels />
            <Grid container>
              <DaysLabel />
              <Tooltip
                title={`${
                  bidValue === 0 && currentMode.toLowerCase() === DaypartingMode.BidDecrease.toLowerCase()
                    ? 'Please enter a bid decrease value'
                    : ''
                }`}
                followCursor
                arrow
                placement="top"
              >
                <Grid
                  item
                  sx={{
                    cursor:
                      bidValue === 0 && currentMode.toLowerCase() === DaypartingMode.BidDecrease.toLowerCase()
                        ? 'not-allowed'
                        : 'default'
                  }}
                >
                  {Object.values(daySchedules).map((scheduleOfDay, dayNumber) => (
                    <DayScheduleRow
                      key={dayNumber}
                      dayNumber={dayNumber}
                      scheduleOfDay={scheduleOfDay}
                      bidValue={bidValue}
                      daySchedules={daySchedules}
                      isDragging={isDragging}
                      setIsDragging={setIsDragging}
                      handleUpdateSchedule={handleUpdateSchedule}
                      shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour || false}
                      isCapOutHour={checkCapOutHour}
                      setFirstClicked={setFirstClicked}
                      setFirstClickedValue={setFirstClickedValue}
                      setDaySchedulesBeforeDrag={setDaySchedulesBeforeDrag}
                      setLastClicked={setLastClicked}
                      currentMode={currentMode}
                    />
                  ))}
                </Grid>
              </Tooltip>
            </Grid>
          </Box>
          <DaypartingLegend />
        </Box>
      )}
    </>
  );
};

export default HourlySchedulesContainer;
