import { FC } from 'react';
import { Alert, Link } from '@mui/material';
import { downloadFileFromBlob } from '../../../../shared/utilities/file-export-utilities';

export const AddItemsAlert: FC<{
  invalidItemIds: string[];
  successfullyAddedItems: any[];
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const { invalidItemIds, successfullyAddedItems, isOpen, onClose } = props;

  const getSeverity = (): 'error' | 'warning' | 'success' => {
    if (invalidItemIds.length < 1) {
      return 'success';
    }

    if (successfullyAddedItems.length > 0) {
      return 'warning';
    }

    return 'error';
  };

  const getInvalidItemIdsCsv = () => {
    return 'Invalid Item Ids\n' + invalidItemIds.join('\n');
  };

  const getMessage = (): any => {
    if (invalidItemIds.length < 1) {
      return `Successfully added ${successfullyAddedItems.length} item(s)!`;
    }

    if (successfullyAddedItems.length > 0) {
      return (
        <>
          <div>
            {`${successfullyAddedItems.length}/${
              successfullyAddedItems.length + invalidItemIds.length
            } items added successfully. `}
            <Link href={downloadFileFromBlob(getInvalidItemIdsCsv())} download={'errors.csv'}>
              Download error report.
            </Link>
          </div>
        </>
      );
    }

    return `${invalidItemIds.length}/${
      invalidItemIds.length + successfullyAddedItems.length
    } item ids were not found. Please try again`;
  };

  const shouldDisplayAlert = () => {
    return (invalidItemIds.length > 0 || successfullyAddedItems.length > 0) && isOpen;
  };

  return (
    <>
      {shouldDisplayAlert() ? (
        <Alert sx={{ mb: 2 }} severity={getSeverity()} onClose={onClose}>
          {getMessage()}
        </Alert>
      ) : null}
    </>
  );
};

export default AddItemsAlert;
