import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { GridEditInputCell, GridPreProcessEditCellProps, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { ResponseObject } from '../../../../../shared/utilities/fetch-utilities';
import { formatAsCurrency } from '../../../../../shared/utilities/numeric-format-utilities';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      height: '100%'
    }
  })
);

function BudgetItemEditInputCell(props: GridRenderEditCellParams) {
  const { error } = props;

  let typeSafeProps: GridRenderEditCellParams = { ...props, error: typeof props.error === 'string' && props.error !== '' };

  return (
    <StyledTooltip open={!!error} title={error}>
      <span>
        <GridEditInputCell
          {...typeSafeProps}
          sx={{ border: 1, borderColor: !!error ? 'error.main' : 'success.main', borderRadius: '4px' }}
        />
      </span>
    </StyledTooltip>
  );
}

export function renderEditBudgetItem(params: GridRenderEditCellParams) {
  return <BudgetItemEditInputCell {...params} />;
}

const countDecimals = (value: any) => {
  if (Math.floor(value.valueOf()) === value.valueOf()) {
    return 0;
  }

  var valueString = value.toString();

  if (valueString.includes('.') && valueString.includes('-')) {
    return valueString.split('-')[1] || 0;
  }

  if (valueString.includes('.')) {
    return valueString.split('.')[1].length || 0;
  }

  return valueString.split('-')[1] || 0;
};

const validateBudgetItem = async (
  params: GridPreProcessEditCellProps,
  monthIndex: number,
  isValidMonthlyBudget: (campaignId: number, month: number, year: number, monthlyBudget: number) => Promise<ResponseObject>
) => {
  if (!params?.props?.value === undefined || params.props.value === null) {
    return 'Budget value cannot be empty.';
  }

  if (Number(params.props.value) < 0) {
    return 'Budget value cannot be negative.';
  }

  if (countDecimals(Number(params.props.value)) > 2) {
    return 'Please round to the nearest cent.';
  }

  const currentMonthIndex = new Date().getMonth(); //0-based!

  if (params.row.budgetItemName?.toLowerCase() !== 'total' && params.row.budgetItemName?.toLowerCase() !== 'client budget') {
    if (monthIndex !== currentMonthIndex) {
      //both are 0-based
      return;
    }

    const apiSafeMonthIndex = monthIndex + 1; //Jan = 1, Feb = 2, etc.
    const year = new Date().getFullYear();
    const currentMonthBudget = params.props.value;
    const campaignId = params.row.campaignId;

    const response = await isValidMonthlyBudget(campaignId, apiSafeMonthIndex, year, currentMonthBudget);

    if (response.success) {
      const isValid = response.body?.value?.isValid === true;

      const monthlySpend = response.body?.value?.monthlySpend;

      if (!isValid) {
        return `Budget cannot be lower than the current month spend of ${formatAsCurrency(monthlySpend)}`;
      }
    }
  }

  return '';
};

export const preProcessBudgetItemEditCellProps = async (
  params: GridPreProcessEditCellProps,
  monthIndex: number,
  isValidMonthlyBudget: (campaignId: number, month: number, year: number, monthlyBudget: number) => Promise<ResponseObject>
) => {
  const errorMessage = await validateBudgetItem(params, monthIndex, isValidMonthlyBudget);
  return { ...params.props, error: errorMessage };
};

export function formatValue(value: any) {
  if (!value || isNaN(value)) {
    return formatAsCurrency(0);
  }

  return formatAsCurrency(value);
}
