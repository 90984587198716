import { Card, Link, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AmazonTargetMatchType } from '../../../../amazon/types/target';
import { caseInsensitiveEquals } from '../../../../shared/utilities/string-utilities';

type Keyword = any;

interface SuggestedKeywordsProps {
  keywords: Keyword[];
  preExistingKeywords?: Keyword[];
  selectedMatchTypes: string[];
  handleAddKeyword: (keyword: Keyword) => void;
  bidType: 'suggested' | 'custom';
}

function renderBidLink(
  keyword: any,
  matchType: string,
  handleAddKeyword: any,
  bidType: 'suggested' | 'custom',
  isDisabled?: boolean
) {
  let bid = 0;

  if (matchType === AmazonTargetMatchType.Exact) {
    bid = keyword?.suggestedBidExact;
  } else if (matchType === AmazonTargetMatchType.Phrase) {
    bid = keyword?.suggestedBidPhrase;
  } else if (matchType === AmazonTargetMatchType.Broad) {
    bid = keyword?.suggestedBidBroad;
  }

  const keywordToAdd = {
    keywordText: keyword?.query,
    matchType: matchType,
    bid: bid
  };

  const shouldDisable = () => {
    if (isDisabled) {
      return true;
    }

    if (bidType === 'suggested') {
      return !bid;
    } else {
      return false;
    }
  };

  return (
    <Box display="flex" alignContent={'center'}>
      <Typography sx={{ fontSize: '12px', minWidth: '85px', mr: 2 }}>
        {matchType}: {bid ? `$${bid}` : '-'}
      </Typography>
      <Tooltip title={shouldDisable() ? 'Keyword and match type already added.' : ''}>
        <span>
          <Link
            sx={{
              fontSize: '12px',
              cursor: 'pointer',
              color: shouldDisable() ? 'text.disabled' : 'primary.main'
            }}
            underline={shouldDisable() ? 'none' : 'hover'}
            onClick={() => handleAddKeyword(keywordToAdd)}
            component={'button'}
            type="button"
            disabled={shouldDisable()}
          >
            Add
          </Link>
        </span>
      </Tooltip>
    </Box>
  );
}

function renderRow(
  { index, style }: ListChildComponentProps,
  keywords: Keyword[],
  preExistingKeywords: Keyword[] | undefined,
  selectedMatchTypes: string[],
  handleAddKeyword: any,
  bidType: 'suggested' | 'custom'
) {
  const keyword = keywords[index];

  if (keyword.query === null) {
    return;
  }

  return (
    <>
      <ListItem style={style} key={keyword.query} divider>
        <Stack>
          <Tooltip title={keyword.query}>
            <Typography
              sx={{
                width: '200px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                fontWeight: 'bold'
              }}
            >
              {keyword.query}
            </Typography>
          </Tooltip>
          <Box display={'flex'}>
            {keyword?.score && (
              <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>Relevancy Score:{keyword?.score}</Typography>
            )}
          </Box>
        </Stack>
        <Stack sx={{ width: '80px' }}>
          {selectedMatchTypes.includes(AmazonTargetMatchType.Exact) && (
            <>
              {renderBidLink(
                keyword,
                AmazonTargetMatchType.Exact,
                handleAddKeyword,
                bidType,
                preExistingKeywords?.some(
                  (k) =>
                    caseInsensitiveEquals(k.keywordText, keyword.query) &&
                    caseInsensitiveEquals(k.matchType, AmazonTargetMatchType.Exact)
                )
              )}
            </>
          )}
          {selectedMatchTypes.includes(AmazonTargetMatchType.Broad) && (
            <>
              {renderBidLink(
                keyword,
                AmazonTargetMatchType.Broad,
                handleAddKeyword,
                bidType,
                preExistingKeywords?.some(
                  (k) =>
                    caseInsensitiveEquals(k.keywordText, keyword.query) &&
                    caseInsensitiveEquals(k.matchType, AmazonTargetMatchType.Broad)
                )
              )}
            </>
          )}
          {selectedMatchTypes.includes(AmazonTargetMatchType.Phrase) && (
            <>
              {renderBidLink(
                keyword,
                AmazonTargetMatchType.Phrase,
                handleAddKeyword,
                bidType,
                preExistingKeywords?.some(
                  (k) =>
                    caseInsensitiveEquals(k.keywordText, keyword.query) &&
                    caseInsensitiveEquals(k.matchType, AmazonTargetMatchType.Phrase)
                )
              )}
            </>
          )}
        </Stack>
      </ListItem>
    </>
  );
}

export default function SuggestedKeywords(props: SuggestedKeywordsProps) {
  const { keywords, selectedMatchTypes, handleAddKeyword, bidType, preExistingKeywords } = props;

  return (
    <Card sx={{ width: '100%', height: 380 }} variant="outlined">
      <FixedSizeList height={380} width="100%" itemSize={70} itemCount={keywords.length}>
        {(props) => renderRow(props, keywords, preExistingKeywords, selectedMatchTypes, handleAddKeyword, bidType)}
      </FixedSizeList>
    </Card>
  );
}
