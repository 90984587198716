import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import { InitializedPerformanceGrid } from '../../../shared/components/performance-grid/initialized-performance-grid';
import { amazonRoutes } from '../../../shared/routing/routes-objects';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { useAmazonApi } from '../../hooks/use-amazon-api';
import { getUpdateCampaignRequest } from '../../types/campaign';
import CampaignBulkActions from './bulk-actions/campaign-bulk-actions';

const Campaigns: FC = () => {
  const { getCampaigns, updateCampaign } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);

    if (changedFields.length == 0) {
      return oldRow;
    }

    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);
    const response = await updateCampaign({ campaignId: newRow.campaignId, ...updateCampaignRequest });

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Campaigns">
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h5" mr={2}>
              Campaigns
            </Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Link
              to={`${amazonRoutes.find((route) => route.key === 'amazon-create-campaigns')?.path}${
                profileId ? `?profileId=${profileId}` : ''
              }`}
              component={RouterLink}
              style={{ textDecoration: 'none' }}
            >
              <Button startIcon={<AddIcon fontSize="small" />} variant="contained" sx={{ my: '5%' }}>
                Create Campaign
              </Button>
            </Link>
          </Box>
          <Box height={'800px'}>
            <InitializedPerformanceGrid
              breadCrumbParams={{ profileId }}
              settingsKey={GridConfigSettings.AMAZON_CAMPAIGN}
              columns={CAMPAIGN_COLUMNS}
              processRowUpdate={processRowUpdate}
              fetchEntity={getCampaigns}
              shouldIncludeChartMetrics={true}
              rowId={'campaignId'}
              bottomMargin={30}
              getRowClassName={(params: any) => `row-status--${params.row.status}`}
              bulkActions={(refreshEntities: () => void, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => (
                <CampaignBulkActions setIsLoading={setIsLoading} refreshCampaigns={refreshEntities} />
              )}
              allowQuickFilterSearch={true}
              disableGridDateRangePicker={false}
            />
          </Box>
        </Stack>
      </Container>
    </Page>
  );
};

export default Campaigns;
