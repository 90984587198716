import { Box, Button, Container, Stack, Typography, Link, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import useAdsApi from '../../../../shared/hooks/use-citrusad-api';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import useDialog from '../../../../shared/hooks/use-dialog';
import { Platform } from '../../../../shared/types/platforms';
import { PRODUCT_AD_COLUMNS } from '../../../components/grid-components/column-configurations/product-ad-columns';
import { ProductAdsDialog } from './create-dialog/product-ads-dialog';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';

interface OverviewAdsProps {
  campaignId: string | null;
  shouldShowAddAdsButton?: boolean;
}

export const OverviewAds = (props: OverviewAdsProps) => {
  const { campaignId, shouldShowAddAdsButton } = props;

  const { getProductById, getCampaignProductMetrics } = useAdsApi(Platform?.CITRUS_AD?.value);
  const { enqueueSnackbar } = useSnackbar();

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.CITRUSAD_AD,
    breadCrumbParams: {}
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();
  const { pageable, setPageable, handleFilterModelChange, handleSortModelChange, handlePageChange, handlePageSizeChange } =
    usePageable();
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const [ads, setAds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  const { isShowing, toggle } = useDialog();

  let [searchParams] = useSearchParams();

  useEffect(() => {
    if (!initialSettings) {
      return;
    }
    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const processRowUpdate = useCallback((newRow: any, oldRow: any) => {
    return newRow;
  }, []);

  const fetchProducts = async () => {
    if (!campaignId || !pageable || settingsLoading || dateSettingsLoading) {
      return;
    }
    setIsInitialLoading(true);

    const response = await getCampaignProductMetrics(campaignId, pageable, dateSettings.beginDate, dateSettings.endDate);

    if (response.success) {
      setAds(response.body.records.filter((product: any) => product.productCode != null));
      setIsLoading(false);
      setRowCount(response.body.totalRecords);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
    setIsInitialLoading(false);
  };

  useEffect(() => {
    fetchProducts();
  }, [campaignId, pageable, dateSettings.beginDate, dateSettings.endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Box display="flex" alignItems={'center'}>
        <Typography variant="h5">Products</Typography>
        <Box
          sx={{
            mr: 'auto'
          }}
        ></Box>
        {true && (
          <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggle()}>
            Add Products
          </Button>
        )}
      </Box>
      {!initialSettings || settingsLoading ? (
        <Box display={'flex'} alignItems={'center'} width={'100%'} height={'100%'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <PerformanceGrid
          processRowUpdate={processRowUpdate}
          loading={isLoading}
          rows={ads}
          columns={PRODUCT_AD_COLUMNS}
          initialState={initialSettings?.config}
          saveGridConfig={saveGridConfig}
          getRowId={(row: any) => row.productCode}
          handleFilterModelChange={handleFilterModelChange}
          handleSortModelChange={handleSortModelChange}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={rowCount}
          dateConfig={{
            dateSettings: dateSettings,
            dateSettingsLoading: dateSettingsLoading,
            saveDateConfig: saveDateConfig
          }}
        />
      )}
      {isShowing && (
        <ProductAdsDialog isShowing={isShowing} campaignId={campaignId} ads={ads} toggle={toggle} refreshAds={fetchProducts} />
      )}
    </Box>
  );
};
