import { alpha, Box, Divider, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridColumnResizeParams,
  GridFilterModel,
  GridRowClassNameParams,
  GridRowIdGetter,
  GridRowModesModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  MuiEvent
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { listOfMonths } from '../../sams-club/types/budget';
import { sumNoLabel } from './grid-components/aggregationFunctions';

const StyledBudgetGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .total-row': {
    borderTop: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral?.[800] : theme.palette.neutral?.[100],
    fontWeight: 500
  },
  '& .total-row .over-budget': {
    backgroundColor: 'red'
  },
  '& .current-month-cell:not(.MuiDataGrid-cell--editing)': {
    backgroundColor:
      theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.05) : alpha(theme.palette.common.black, 0.05)
  },
  '& .total-cell': {
    borderLeft: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral?.[800] : theme.palette.neutral?.[100],
    fontWeight: 500
  },
  '& .MuiDataGrid-row': {
    '& .user-editing-cell.numeric-edit-cell:not(.MuiDataGrid-cell--editing, .total-cell)': {
      paddingLeft: '4px',
      paddingRight: '4px',
      '& .MuiDataGrid-cellContent': {
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.neutral?.[200] : theme.palette.neutral?.[600],
        borderRadius: '4px',
        width: '100%',
        minHeight: '60%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '4px 5px 4px 2px'
      }
    }
  }
}));

const nonEditableRows = ['Rollover', 'Actual Spend'];

enum CampaignBudgetGridFilterValue {
  All = 'all',
  Active = 'active'
}

const campaignBudgetGridFilterChoices: { label: string; value: string }[] = [
  { label: 'All', value: CampaignBudgetGridFilterValue.All },
  { label: 'Active Only', value: CampaignBudgetGridFilterValue.Active }
];

const predefiniedCampaignBudgetGridFilterModels: {
  [filterValue in CampaignBudgetGridFilterValue]: GridFilterModel;
} = {
  [CampaignBudgetGridFilterValue.All]: { items: [] },
  [CampaignBudgetGridFilterValue.Active]: { items: [{ field: 'isActiveInVector', operator: 'is', value: 'true' }] }
};

export interface PerformanceGridProps {
  apiRef: MutableRefObject<GridApiPremium>;
  calendarYear: number;
  processRowUpdate?: (newRow: any, oldRow: any) => any;
  rows: any[];
  columns: GridColDef[];
  initialState: GridInitialStatePremium | undefined;
  getRowId?: GridRowIdGetter<any> | undefined;
  loading?: boolean;
  className?: string;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  getCellClassName?: (params: GridCellParams) => string;
  saveGridConfig?: (update: any) => void;
  bottomMargin?: number;
  canEdit: boolean;
  onColumnResize?: (params: GridColumnResizeParams, event: MuiEvent, details: GridCallbackDetails) => void;
  isCampaignBudgetGrid?: boolean;
  onScroll?: (scrollLeft: number) => void;
}

export default function BudgetGrid({
  apiRef,
  calendarYear,
  rows,
  columns,
  initialState,
  processRowUpdate,
  getRowId,
  loading,
  className,
  getRowClassName,
  bottomMargin = 0,
  getCellClassName,
  canEdit,
  onColumnResize: onColumnResizeFromProps,
  isCampaignBudgetGrid,
  onScroll
}: PerformanceGridProps) {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [campaignToggleFilterValue, setCampaignToggleFilterValue] = useState<CampaignBudgetGridFilterValue>(
    CampaignBudgetGridFilterValue.All
  );

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const editableMonths = listOfMonths.filter((_, index) => {
    return calendarYear > currentYear || (calendarYear === currentYear && index >= currentMonth);
  });

  //adapted from example given by mui-x team in this github issue: https://github.com/mui/mui-x/issues/2186
  const handleCellClick = useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: params.isEditable ? GridCellModes.Edit : GridCellModes.View }
        }
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleColumnResize = useCallback((params: GridColumnResizeParams, event: MuiEvent, details: GridCallbackDetails) => {
    onColumnResizeFromProps?.(params, event, details);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggleButtonChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: CampaignBudgetGridFilterValue) => {
      if (value === null) {
        return;
      }

      setCampaignToggleFilterValue(value);
      apiRef.current.setFilterModel(predefiniedCampaignBudgetGridFilterModels[value]);
    },
    [apiRef, setCampaignToggleFilterValue]
  );

  useEffect(() => {
    if (!campaignToggleFilterValue) {
      return;
    }

    apiRef.current.setFilterModel(predefiniedCampaignBudgetGridFilterModels[campaignToggleFilterValue]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!apiRef || !apiRef.current || !onScroll) return;

    const handleScroll = (params: any) => {
      onScroll(params.left);
    };

    return apiRef.current.subscribeEvent('scrollPositionChange', handleScroll);
  }, [apiRef, onScroll]);

  return (
    <Box sx={{ height: `calc(100% - ${bottomMargin}px)` }} className={className}>
      <StyledBudgetGrid
        disableColumnMenu
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onColumnResize={handleColumnResize}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        disableRowSelectionOnClick
        onCellClick={handleCellClick}
        loading={loading}
        processRowUpdate={processRowUpdate}
        getRowId={getRowId}
        disableColumnReorder
        initialState={initialState}
        getRowClassName={getRowClassName}
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              {isCampaignBudgetGrid && (
                <>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={campaignToggleFilterValue}
                    exclusive
                    onChange={handleToggleButtonChange}
                    sx={{ pl: 1, pb: 0.5, pt: 0.5 }}
                  >
                    {campaignBudgetGridFilterChoices.map(({ label, value }) => {
                      return (
                        <ToggleButton key={value} value={value}>
                          {label}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                  <Divider orientation="vertical" flexItem />
                  <GridToolbarQuickFilter sx={{ pl: 1, pr: 1 }} />
                  <Divider orientation="vertical" flexItem />
                </>
              )}
              <GridToolbarExport />
            </GridToolbarContainer>
          )
        }}
        aggregationFunctions={{
          sumNoLabel
        }}
        getCellClassName={(params) => {
          let classes = [];

          if (getCellClassName) {
            classes.push(getCellClassName(params));
          }

          if (
            canEdit &&
            editableMonths.includes(params.field) &&
            !nonEditableRows.some((row) => row.toLowerCase() === params.row?.budgetItemName?.toLowerCase())
          ) {
            classes.push('user-editing-cell');
          }

          if (params.field === listOfMonths[currentMonth]) {
            classes.push('current-month-cell');
          }

          return classes.join(' ');
        }}
        rowCount={rows.length}
        isCellEditable={(params) => {
          return (
            canEdit &&
            editableMonths.includes(params.field) &&
            !nonEditableRows.some((row) => row.toLowerCase() === params.row?.budgetItemName?.toLowerCase())
          );
        }}
      />
    </Box>
  );
}
