import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import AddedNegativeKeywordsTable from './added-negative-keywords-table';

interface AddNegativeKeywordsProps {
  negativeKeywords: any;
  setNegativeKeywords: (negativeKeywords: any) => void;
}

export default function AddNegativeKeywords(props: AddNegativeKeywordsProps) {
  const { negativeKeywords, setNegativeKeywords } = props;
  const [matchTypes, setMatchTypes] = useState(['exact']);
  const [userEnteredKeywords, setUserEnteredKeywords] = useState('');

  const addNegativeKeywords = () => {
    const parsedKeywords = userEnteredKeywords
      .split('\n')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)
      .map((keyword) => keyword.toLowerCase());

    const objectifiedKeywords = matchTypes.flatMap((matchType) =>
      parsedKeywords.map((keyword: string) => ({
        keywordText: keyword,
        matchType: matchType,
        id: `${keyword}-${matchType}`
      }))
    );

    const newNegativeKeywords = objectifiedKeywords.filter(
      (keyword: any) =>
        !negativeKeywords.some(
          (objKeyword: any) => objKeyword.keywordText === keyword.keywordText && objKeyword.matchType === keyword.matchType
        )
    );

    setUserEnteredKeywords('');
    setNegativeKeywords([...negativeKeywords, ...newNegativeKeywords]);
  };

  return (
    <Grid container direction="column" mt={2}>
      <Stack mb={2}>
        <Box display={'flex'}>
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button variant="outlined" onClick={() => setNegativeKeywords([])}>
            Remove All Keywords
          </Button>
        </Box>
      </Stack>
      <Grid item>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Grid item>
            <TextField
              id="campaignItemIds"
              label="Negative Keywords"
              onChange={(event) => {
                setUserEnteredKeywords(event.target.value);
              }}
              placeholder="Enter one keyword per line"
              inputProps={{
                style: { fontSize: '14px' }
              }}
              multiline
              value={userEnteredKeywords}
              sx={{ mt: 1, width: '320px' }}
              rows={15}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5, mr: 1, ml: 1 }}
                variant="outlined"
                size="small"
                aria-label="move all right"
                onClick={addNegativeKeywords}
              >
                <Typography variant="h6" fontWeight={'bold'}>
                  ≫
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <AddedNegativeKeywordsTable negativeKeywords={negativeKeywords} setNegativeKeywords={setNegativeKeywords} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
