import * as React from 'react';
import { Container, Typography } from '@mui/material';
import Page from '../../../shared/components/page';
import { BudgetsManager } from './budgets-manager';

const AmazonBudgets: React.FC = () => {
  return (
    <Page>
      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mb: 4 }}>
          Budgets
        </Typography>
        <BudgetsManager />
      </Container>
    </Page>
  );
};

export default AmazonBudgets;
