import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './navbar/dashboard-navbar';
import { DashboardSidebar } from './sidebar/dashboard-sidebar';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64
}));

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const lgBreakpointIsActive = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(lgBreakpointIsActive);
  // When the page loads, put the scroll at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsSidebarOpen(lgBreakpointIsActive);
  }, [lgBreakpointIsActive]);

  return (
    <>
      <DashboardLayoutRoot
        sx={{
          paddingLeft: isSidebarOpen ? '230px' : '60px',
          transition: 'padding-left 0.2s ease-in-out'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Outlet />
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onOpenSidebar={(): void => setIsSidebarOpen(true)} isSidebarOpen={isSidebarOpen} />
      <DashboardSidebar onClose={(): void => setIsSidebarOpen(false)} setIsSidebarOpen={setIsSidebarOpen} open={isSidebarOpen} />
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};
