import { ArrowDropDownSharp, InfoOutlined } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  TooltipProps,
  Typography,
  colors,
  styled,
  tooltipClasses
} from '@mui/material';
import { green, purple } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import type { ApexOptions } from 'apexcharts';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Chart } from '../../../shared/components/chart';
import { useUserSeries } from '../../../shared/hooks/use-chart-config';
import { abbreviateMetricDisplayName, formatMetricValue } from '../../../shared/utilities/chart-utilities';
import { metricsChartDateFormat } from '../../../shared/utilities/date-utilities';
import { format } from 'date-fns';

const containerStyle = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  borderRadius: '0.5rem',
  border: '1px solid',
  borderColor: 'divider',
  padding: '0.5rem'
};

const headerContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const buttonStyle = {
  width: '0.2rem',
  height: '50%'
};

const chipContainerStyle = {
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  gap: '1rem',
  mr: '1rem',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& > *': {
    m: 0.5
  }
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#36454F'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#36454F'
  }
}));

const useChartOptions = (
  timeSeries: any,
  series: any[],
  colors: any[],
  bidGroupAcosTarget?: number,
  targetChangesCountByDate?: any,
  placementChangesCountByDate?: any
): ApexOptions => {
  const { palette } = useTheme();
  const annotations = bidGroupAcosTarget
    ? {
        annotations: {
          yaxis: [
            {
              y: bidGroupAcosTarget / 100,
              borderColor: palette.error.main,
              strokeDashArray: 8,
              borderWidth: 2,
              yAxisIndex: 0,
              label: {
                borderColor: palette.error.main,
                style: {
                  color: 'white',
                  background: palette.error.main
                },
                text: 'Current ACoS Target'
              }
            }
          ]
        }
      }
    : {};

  return {
    ...annotations,
    chart: {
      animations: {
        enabled: false
      },
      background: palette.background.paper,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      formatter: (value) => {
        return abbreviateMetricDisplayName(
          timeSeries.metrics.find((metric: { name: string }) => metric.name === value).displayName
        );
      },
      labels: {
        colors: palette.text.secondary
      },
      onItemClick: {
        toggleDataSeries: true
      }
    },
    colors: colors,
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
      type: 'gradient',
      gradient: {
        shade: palette.mode === 'dark' ? 'dark' : 'light',
        type: 'vertical',
        shadeIntensity: 0.6,
        opacityFrom: 0.3,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    grid: {
      borderColor: palette.divider,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      radius: 4,
      shape: 'circle',
      size: 2,
      strokeWidth: 0,
      hover: {
        sizeOffset: 2
      }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 3
    },
    theme: {
      mode: palette.mode
    },
    xaxis: {
      categories: timeSeries.categories,
      type: 'datetime',
      labels: {
        style: {
          colors: palette.text.secondary
        },
        format: metricsChartDateFormat(timeSeries.categories)
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: palette.divider,
          width: 2
        }
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: palette.divider,
        height: 6,
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: series,
    noData: {
      text: 'No Data Available'
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const date = new Date(w.globals.seriesX[seriesIndex][dataPointIndex]);
        const formattedDate = format(new Date(date.getFullYear(), date.getMonth(), date.getUTCDate()), 'MMM dd, yyyy');

        return (
          `<div style="padding: 10px; background-color: ${palette.background.paper}; box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.6); display: flex; flex-direction: column; align-items: center;">
          <div>
            <span>${formattedDate}</span>
            <div style="height: 0px; margin: 1rem 0;"></div>
            <div>` +
          series
            .map(
              (data: number[], index: any) =>
                `<div key={index} style="display: flex; align-items: center; margin: 5px">
          <div style="width: 10px; height: 10px; background-color: ${colors[index]}; margin-right: 5px; border-radius: 50%;"></div>
          <Typography variant="body2" component="span">` +
                `${abbreviateMetricDisplayName(
                  timeSeries.metrics.find((metric: { name: any }) => metric.name === w.globals.seriesNames[index]).displayName
                )}: ${formatMetricValue(
                  data[dataPointIndex],
                  true,
                  timeSeries.metrics.find((metric: { name: string }) => metric.name === w.globals.seriesNames[index]).dataType
                )}` +
                `</Typography>
              </div>`
            )
            .join('') +
          `</div>
            <div style="height: 0; margin: 1rem 0 0.5rem 0;"></div>
            <span style="color: ${
              targetChangesCountByDate[date.toISOString().split('T')[0]] ? palette.text.primary : palette.text.secondary
            }; font-size: 0.8rem; margin-left: 10px;">${
              targetChangesCountByDate[date.toISOString().split('T')[0]]
                ? `${targetChangesCountByDate[date.toISOString().split('T')[0]]} Target Changes`
                : '0 Target Changes'
            }</span>
            <div style="height: 0;"></div>
            <span style="color: ${
              placementChangesCountByDate[date.toISOString().split('T')[0]] ? palette.text.primary : palette.text.secondary
            }; font-size: 0.8rem; margin-left: 10px;">${
              placementChangesCountByDate[date.toISOString().split('T')[0]]
                ? `${placementChangesCountByDate[date.toISOString().split('T')[0]]} Placement Changes`
                : '0 Placement Changes'
            }</span>
          </div>
        </div>`
        );
      },
      marker: {
        show: true
      },
      theme: palette.mode
    }
  };
};

interface TimeSeriesChartProps {
  timeSeriesData: any;
  bidGroupAcosTarget?: number;
  isMetricsChartLoading?: boolean;
  targetChangesCountByDate?: any;
  placementChangesCountByDate?: any;
}

const BidGroupsMetricsChart = ({
  timeSeriesData,
  bidGroupAcosTarget,
  isMetricsChartLoading = false,
  targetChangesCountByDate,
  placementChangesCountByDate
}: TimeSeriesChartProps) => {
  const { palette } = useTheme();
  const isFirstRender = useRef(true);

  const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
  const [seriesColors, setSeriesColors] = useState<{ [key: string]: string }>({});
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { userSeriesData, saveUserSeriesData } = useUserSeries();
  const [boxClickedValue, setBoxClickedValue] = useState<string>('');
  const [anchorEls, setAnchorEls] = useState<null[] | HTMLElement[]>([null, null, null, null, null]);
  const [series, setSeries] = useState<any[]>([]);
  const [isMenuItemHovered, setIsMenuItemHovered] = useState<string | null>(null);
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([]);

  useEffect(() => {
    const currentlyDisplayedSeries = selectedSeries.filter((seriesName) => !hiddenSeries.includes(seriesName));
    const orderedSeries = currentlyDisplayedSeries.map((seriesName, index) => {
      const metric = timeSeriesData?.metrics?.find((metric: { name: string }) => metric.name === seriesName);
      const opposite = index % 2 === 0;
      const buffer = 1.1;

      return {
        seriesName: metric.name,
        title: { text: abbreviateMetricDisplayName(metric.displayName) },
        opposite,
        labels: {
          formatter: (value: number) => {
            return formatMetricValue(value, true, metric.dataType);
          }
        },
        color: seriesColors[metric.name],
        data: metric.data,
        min: 0,
        max:
          metric.name === 'AdvertisingCostOfSale'
            ? Math.max(Math.max(...metric.data), (bidGroupAcosTarget ?? 0) / 100) * buffer
            : undefined
      };
    });

    setSeries(orderedSeries);
  }, [selectedSeries, hiddenSeries, timeSeriesData.metrics, seriesColors, bidGroupAcosTarget]);

  useEffect(() => {
    const colors = [palette.info.dark, palette.warning.dark, purple[600], green[600], palette.error.light];
    const initialColors: { [key: string]: string } = {};

    if (userSeriesData && userSeriesData.selectedSeries && userSeriesData.selectedSeries.length > 0) {
      const initialSelectedSeries = userSeriesData.selectedSeries;

      if (!initialSelectedSeries.includes('AdvertisingCostOfSale')) {
        initialSelectedSeries.unshift('AdvertisingCostOfSale');
        initialSelectedSeries.pop();
      }

      let newSelectedSeries: string[] = [];

      if (userSeriesData.selectedSeries.length < 5) {
        newSelectedSeries = timeSeriesData.metrics
          .filter((metric: any) => !initialSelectedSeries.includes(metric.name))
          .map((metric: any) => metric.name)
          .slice(0, 5 - initialSelectedSeries.length);
      }

      setSelectedSeries([...initialSelectedSeries, ...newSelectedSeries]);

      [...initialSelectedSeries, ...newSelectedSeries].forEach((seriesName: string, index: any) => {
        initialColors[seriesName] = colors[index];
      });

      setSeriesColors(initialColors);
    } else if (timeSeriesData.metrics && timeSeriesData.metrics.length >= 5) {
      const initialSelectedSeries = timeSeriesData.metrics.slice(0, 5).map((metric: any) => metric.name);

      if (!initialSelectedSeries.includes('AdvertisingCostOfSale')) {
        initialSelectedSeries.unshift('AdvertisingCostOfSale');
        initialSelectedSeries.pop();
      }

      setSelectedSeries(initialSelectedSeries);

      initialSelectedSeries.forEach((seriesName: string, index: any) => {
        initialColors[seriesName] = colors[index];
      });

      setSeriesColors(initialColors);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSeriesData]);

  useEffect(() => {
    if (isFirstRender.current && selectedSeries.length === 5) {
      setHiddenSeries(selectedSeries.slice(2, 5));
      isFirstRender.current = false;
    }
  }, [selectedSeries]);

  const visibleSeriesColors = selectedSeries
    .filter((series) => !hiddenSeries.includes(series))
    .map((series) => seriesColors[series]);

  const chartOptions = useChartOptions(
    timeSeriesData,
    series,
    visibleSeriesColors,
    bidGroupAcosTarget,
    targetChangesCountByDate,
    placementChangesCountByDate
  );

  const handleSeriesChange = (oldSeriesName: string, newSeriesName: string) => {
    const newSelectedSeries = selectedSeries.map((seriesName) => (seriesName === boxClickedValue ? newSeriesName : seriesName));

    if (hiddenSeries.includes(oldSeriesName)) {
      setHiddenSeries(
        hiddenSeries
          .filter((seriesName) => seriesName !== oldSeriesName)
          .concat([newSeriesName])
          .sort()
      );
    }

    setSelectedSeries(newSelectedSeries);
    saveUserSeriesData(newSelectedSeries);

    const newSeriesColors = { ...seriesColors };
    newSeriesColors[newSeriesName] = newSeriesColors[oldSeriesName];
    delete newSeriesColors[oldSeriesName];

    setSeriesColors(newSeriesColors);

    setAnchorEls((prevAnchorEls: any) =>
      prevAnchorEls.map((_: any, i: any) => (i === selectedSeries.indexOf(boxClickedValue) ? null : _))
    );
  };

  const handleSeriesToggle = (seriesToToggleVisibility: string) => {
    if (hiddenSeries.includes(seriesToToggleVisibility)) {
      setHiddenSeries(hiddenSeries.filter((seriesName) => seriesName !== seriesToToggleVisibility));
    } else {
      setHiddenSeries([...hiddenSeries, seriesToToggleVisibility]);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleBoxClick = (event: React.MouseEvent<HTMLElement>, clickedBoxValue: string) => {
    const anchorEl = event.currentTarget;
    const index = selectedSeries.indexOf(clickedBoxValue);
    setAnchorEls((prevAnchorEls: any) => prevAnchorEls.map((_: any, i: any) => (i === index ? anchorEl : _)));
    setBoxClickedValue(clickedBoxValue);
  };

  return !timeSeriesData.metrics ? (
    <Skeleton height={400} width={'100%'} />
  ) : (
    <Box sx={containerStyle}>
      <Box sx={headerContainerStyle}>
        <Box sx={chipContainerStyle}>
          {Array.isArray(selectedSeries) && selectedSeries.length > 0 ? (
            selectedSeries.map((seriesName, index) => {
              return (
                <Box
                  sx={{
                    borderTop: hiddenSeries.includes(seriesName) ? 'none' : '3px solid',
                    borderColor: hiddenSeries.includes(seriesName) ? palette.grey[500] : seriesColors[seriesName],
                    borderRadius: '2px',
                    paddingX: '0.5rem',
                    paddingTop: hiddenSeries.includes(seriesName) ? '3px' : '',
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.6)',
                    width: '20%',
                    cursor: isCollapsed || index == 0 ? 'auto' : 'pointer'
                  }}
                  key={`${seriesName} - ${index}`}
                  onClick={() => index > 0 && handleSeriesToggle(seriesName)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <Box
                      onClick={
                        isCollapsed
                          ? () => {}
                          : (event) => {
                              event.stopPropagation();
                              if (!isCollapsed && index > 0) {
                                handleBoxClick(event, seriesName);
                              }
                            }
                      }
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        ':hover': {
                          backgroundColor:
                            index > 0 ? (palette.mode === 'dark' ? colors.grey[800] : colors.grey[100]) : 'transparent'
                        },
                        borderRadius: '2px',
                        padding: '0.1rem'
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          alignItems: 'center',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {abbreviateMetricDisplayName(
                          timeSeriesData?.metrics?.find((metric: { name: string }) => metric.name === seriesName)?.displayName
                        ) || null}
                      </Typography>
                      {!isCollapsed && index > 0 ? <ArrowDropDownSharp /> : <></>}
                    </Box>
                    <BootstrapTooltip
                      title={timeSeriesData?.metrics?.find((metric: { name: string }) => metric.name === seriesName)?.description}
                      arrow
                      placement="top"
                    >
                      <InfoOutlined sx={{ scale: '0.8', ml: 'auto', color: colors.grey[500] }} />
                    </BootstrapTooltip>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {formatMetricValue(
                        timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName)?.tileValue,
                        false,
                        timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName)?.dataType
                      )}
                    </Typography>
                    <Typography variant="body2" sx={{ color: colors.grey[400], ml: '0.5rem' }}>
                      {(() => {
                        const metric = timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName);

                        if (metric && metric.tileCalculationType != null) {
                          return metric.tileCalculationType.toString();
                        }

                        return 'N/A';
                      })()}
                    </Typography>
                  </Box>
                  <Menu
                    anchorEl={anchorEls[index] || null}
                    key={seriesName}
                    open={Boolean(anchorEls[index])}
                    onClose={() =>
                      setAnchorEls((prevAnchorEls: any) => prevAnchorEls.map((_: any, i: any) => (i === index ? null : _)))
                    }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 400
                      }
                    }}
                  >
                    {timeSeriesData.metrics.map((metric: any) => (
                      <MenuItem
                        key={`${metric.name} - ${seriesName} - ${index}`}
                        value={metric.name}
                        onClick={(event) => {
                          event.stopPropagation();
                          index > 0 && handleSeriesChange(seriesName, metric.name);
                        }}
                        disabled={selectedSeries.includes(metric.name)}
                        onMouseEnter={() => setIsMenuItemHovered(metric.name)}
                        onMouseLeave={() => setIsMenuItemHovered(null)}
                      >
                        <ListItemText primary={abbreviateMetricDisplayName(metric.displayName)} />
                        <BootstrapTooltip
                          title={
                            timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName)?.description
                          }
                          arrow
                          placement="right"
                        >
                          <InfoOutlined
                            sx={{
                              scale: '0.6',
                              ml: '0.6rem',
                              visibility: isMenuItemHovered === metric.name ? 'visible' : 'hidden',
                              color: colors.grey[500]
                            }}
                          />
                        </BootstrapTooltip>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              );
            })
          ) : (
            <Typography variant="body1">No metrics selected</Typography>
          )}
        </Box>
        <Button onClick={toggleCollapse} variant="outlined" sx={buttonStyle}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Button>
      </Box>
      <Collapse in={!isCollapsed}>
        <span
          style={{
            backgroundColor: isMetricsChartLoading ? palette.divider : 'transparent',
            opacity: isMetricsChartLoading ? 0.5 : 1
          }}
        >
          <Chart
            height={300}
            width="100%"
            options={chartOptions}
            series={series.map((metric: { seriesName: any; data: any }) => {
              return { name: metric.seriesName, data: metric.data };
            })}
            type="area"
            sx={{
              alignSelf: 'center'
            }}
            isLoading={isMetricsChartLoading}
          />
        </span>
      </Collapse>
    </Box>
  );
};

export default memo(BidGroupsMetricsChart);
