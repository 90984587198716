import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Divider, TextField, Tooltip, Collapse } from '@mui/material';
import AddItemsAlert from '../../../../shared/pages/campaigns/create-campaign-form/add-items-alert';
import { useSnackbar } from 'notistack';
import { useAdsApi } from '../../../../shared/hooks/use-walmart-sams-club-api';
import { CampaignItemsTable } from './campaign-items-table';
import { CampaignBuilderStepLabelButton } from './step-label-button';

interface CampaignTypeStepProps {
  onBack?: () => void;
  onNext?: () => void;
  formik: any;
  stepNumber: number;
  profileId: number | null;
}

export const ItemSelectionStep: FC<CampaignTypeStepProps> = (props) => {
  const { onBack, onNext, formik, stepNumber, profileId, ...other } = props;

  const [invalidItemIds, setInvalidItemIds] = useState<string[]>([]);
  const [userInputItemIds, setUserInputItemIds] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [itemSearchIsLoading, setItemSearchIsLoading] = useState(false);

  const { samsClubItemSearch } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  //regex creds go to https://stackoverflow.com/questions/56546491/split-string-by-any-3-options-newline-comma-white-space-in-javascript
  const addItems = async () => {
    setItemSearchIsLoading(true);

    const itemIds = userInputItemIds.replace(/,\s+/g, ',').split(/[\n,\s+]/);
    const newItemsIds = itemIds.filter((itemId) => !formik.values.items.some((item: any) => item.itemId === itemId) && itemId);

    // If user has not input any new item ids don't call api
    if (newItemsIds.length < 1) {
      setItemSearchIsLoading(false);
      return;
    }

    const response = await samsClubItemSearch(profileId, newItemsIds);

    if (response.success) {
      const foundItems = response.body;

      setInvalidItemIds(newItemsIds.filter((itemId: string) => !foundItems.some((item: any) => item.itemId === itemId)));

      formik.setFieldValue('items', [...formik.values.items, ...foundItems]);

      setIsAlertOpen(true);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
    setItemSearchIsLoading(false);
  };

  const removeItem = (item: any) => {
    setIsAlertOpen(false);
    const filteredItems = formik.values.items.filter((campaignItem: any) => campaignItem.itemId !== item.itemId);
    formik.setFieldValue('items', filteredItems);
  };

  const containsAlphabeticalCharacters = (value: string) => {
    return /[a-zA-Z]/.test(value);
  };

  return (
    <Box {...other} sx={{ mb: showStep ? 10 : 1 }}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Select Items"
      />
      <Collapse in={showStep}>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Box display={'flex'}>
              <Box sx={{ width: '30%', pr: 2 }}>
                <TextField
                  id="campaignItemIds"
                  label="Items"
                  onChange={(event) => {
                    setUserInputItemIds(event.target.value);
                  }}
                  placeholder="Enter item IDs separated by new lines"
                  multiline
                  value={userInputItemIds}
                  rows={15}
                  fullWidth
                  inputProps={{
                    style: {
                      fontSize: '12px'
                    }
                  }}
                />
                {!profileId ? (
                  <Tooltip title="Please select a Profile before adding Items">
                    <span>
                      <Button disabled>Add Items</Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button variant="outlined" onClick={addItems} disabled={!userInputItemIds}>
                    Add Items
                  </Button>
                )}
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ width: '70%', pl: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" sx={{ mb: 2 }} onClick={() => formik.setFieldValue('items', [])}>
                    Remove All Items
                  </Button>
                </Box>
                <AddItemsAlert
                  invalidItemIds={invalidItemIds}
                  successfullyAddedItems={formik.values.items}
                  isOpen={isAlertOpen}
                  onClose={() => setIsAlertOpen(false)}
                />
                {!itemSearchIsLoading ? (
                  <CampaignItemsTable formik={formik} removeItem={removeItem} />
                ) : (
                  <Box display={'flex'} alignItems="center" justifyContent={'center'} sx={{ height: '100%' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ mt: 3 }}></Box>
      </Collapse>
    </Box>
  );
};

ItemSelectionStep.propTypes = {
  onBack: PropTypes.func
};
