import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, Collapse, Grid, Skeleton } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useAdsApi } from '../../hooks/use-walmart-sams-club-api';
import { SBProfileBuilder } from '../../../walmart/pages/campaigns/create-campaign-form/campaign-settings-inputs';
import {
  ManageSBProfilesProps,
  SBProfileBuilderRequest,
  SBProfileUpdateRequest,
  UpdateSBProfile
} from '../../types/walmart-sams-club/sb-profile';
import {
  INITIAL_CREATE_FORM_VALUES,
  SB_PROFILE_FORM_VALIDATION_SCHEMA
} from '../../../walmart/components/grid-components/sb-profile-form-config';
import { ViewSBProfile } from './view-sb-profile';
import { useCurrentRetailerPlatform } from '../../hooks/use-current-retailer-platform';
import { Platform } from '../../types/platforms';

export const ManageSBProfiles = (props: ManageSBProfilesProps) => {
  const {
    firstProfile,
    secondProfile,
    profileId,
    campaignId,
    adGroupId,
    refreshParent,
    setCurrentDialogState,
    setIsSubmitting,
    setErrorMessage,
    review
  } = props;

  const [firstProfileState, setFirstProfileState] = useState('view');
  const [secondProfileState, setSecondProfileState] = useState(!!secondProfile ? 'view' : 'nonexistent');
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const currentRetailerPlatform = useCurrentRetailerPlatform();
  const { buildSbaProfile, updateSbaProfile } = useAdsApi(currentRetailerPlatform);

  const [profileToUpdate, setProfileToUpdate] = useState<UpdateSBProfile>({
    searchAmpName: '',
    headlineText: '',
    clickUrl: '',
    logoUrl: '',
    reviewStatus: '',
    reviewReason: '',
    sbaProfileId: 0,
    adGroupId: 0,
    status: 'disabled',
    code: '',
    details: ''
  });

  useEffect(() => {
    if (!secondProfile) {
      return;
    }

    setSecondProfileState('view');
  }, [secondProfile]);

  const walmartValidation = (response: any) => {
    var isSuccessful = false;
    var walmartErrorMessage = '';

    if (response?.body?.sbaProfile?.code !== 'Success') {
      walmartErrorMessage += response?.body?.sbaProfile?.details || '';
    }

    if (response?.body?.sbaProfileImage?.code !== 'Success') {
      if (walmartErrorMessage !== '') {
        walmartErrorMessage += '\n';
      }
      walmartErrorMessage += response?.body?.sbaProfileImage?.details || '';
    }

    if (walmartErrorMessage !== '') {
      setErrorMessage(walmartErrorMessage);
      setCurrentDialogState('walmartError');
      return;
    }

    isSuccessful = true;

    return isSuccessful;
  };

  const createProfileFormik = useFormik({
    initialValues: INITIAL_CREATE_FORM_VALUES,
    validationSchema: SB_PROFILE_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values, helpers): Promise<void> => {
      setSecondProfileState('loading');

      try {
        const requestObject: SBProfileBuilderRequest = {
          searchAmpName: values.searchAmpName,
          headlineText: values.headlineText,
          clickUrl: values.clickUrl,
          logoDataUri: values.logoDataUri
        };

        const response = await buildSbaProfile(profileId, campaignId, requestObject);

        if (response.success) {
          const isSuccessful = walmartValidation(response);

          if (!isSuccessful) {
            setIsSubmitting(false);
            return;
          }

          if (refreshParent) {
            refreshParent();
          }

          enqueueSnackbar('SB Profile successfully created!', { variant: 'success' });
        } else {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
        }
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar('Error creating SB Profile', { variant: 'error' });

        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }

      setSecondProfileState('view');
    }
  });

  const updateProfileFormik = useFormik({
    initialValues: {
      campaignId: campaignId,
      adGroupId: adGroupId,
      sbaProfileId: profileToUpdate?.sbaProfileId,
      searchAmpName: profileToUpdate.searchAmpName,
      headlineText: profileToUpdate.headlineText,
      clickUrl: profileToUpdate.clickUrl,
      logoDataUri: profileToUpdate.logoDataUri,
      logoUrl: profileToUpdate.logoUrl,
      submit: null
    },
    validationSchema: SB_PROFILE_FORM_VALIDATION_SCHEMA,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      setIsSubmitting(true);

      try {
        const requestObject: SBProfileUpdateRequest = {
          campaignId: campaignId,
          adGroupId: adGroupId,
          sbaProfileId: profileToUpdate?.sbaProfileId,
          searchAmpName: values.searchAmpName,
          headlineText: values.headlineText,
          clickUrl: values.clickUrl,
          logoDataUri: values.logoDataUri
        };

        const response = await updateSbaProfile(profileId, requestObject);

        if (response.success) {
          const isSuccessful = walmartValidation(response);

          if (!isSuccessful) {
            setIsSubmitting(false);
            return;
          }

          if (refreshParent) {
            await refreshParent();
          }

          enqueueSnackbar('SB Profile successfully updated!', { variant: 'success' });
        } else {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
        }
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar('Error updating SB Profile', { variant: 'error' });

        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }

      setIsSubmitting(false);
      setFirstProfileState('view');
      setSecondProfileState('view');
    }
  });

  const handleEnableProfile = async (profileToEnable: any) => {
    setIsStatusLoading(true);
    setIsSubmitting(true);

    const requestObject: SBProfileUpdateRequest = {
      campaignId: campaignId,
      adGroupId: adGroupId,
      sbaProfileId: profileToEnable?.sbaProfileId,
      clickUrl: profileToEnable?.clickUrl,
      headlineText: profileToEnable?.headlineText,
      logoDataUri: profileToEnable?.logoDataUri,
      searchAmpName: profileToEnable?.searchAmpName,
      status: 'Enabled'
    };

    const response = await updateSbaProfile(profileId, requestObject);

    if (response.success) {
      const isSuccessful = walmartValidation(response);

      if (!isSuccessful) {
        setIsSubmitting(false);
        setIsStatusLoading(false);
        return;
      }

      if (refreshParent) {
        await refreshParent();
      }

      enqueueSnackbar('SB Profile successfully enabled!', { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsStatusLoading(false);
    setIsSubmitting(false);
  };

  function handleFirstProfileEditClick() {
    if (!firstProfile) {
      return;
    }

    setProfileToUpdate(firstProfile);
    setFirstProfileState('update');
  }

  function handleSecondProfileEditClick() {
    if (!secondProfile) {
      return;
    }

    setProfileToUpdate(secondProfile);
    setSecondProfileState('update');
  }

  const firstProfileStates = [
    {
      state: 'view',
      content: (
        <Card sx={{ margin: 'auto', mt: 4 }} elevation={10}>
          <ViewSBProfile
            {...firstProfile}
            handleEditClick={handleFirstProfileEditClick}
            handleEnableClick={() => handleEnableProfile(firstProfile)}
            isStatusLoading={isStatusLoading}
            allowEdit={true}
            isUnderReview={review?.reviewStatus?.toLowerCase() === 'pending'}
          />
        </Card>
      )
    },
    {
      state: 'update',
      content: (
        <form onSubmit={updateProfileFormik.handleSubmit} id="update-first-profile-form">
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 6 }}>
            <Grid item xs={9}>
              <SBProfileBuilder formik={updateProfileFormik} />
            </Grid>
            <Grid item>
              <Button onClick={() => setFirstProfileState('view')}>Cancel</Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!updateProfileFormik.isValid || updateProfileFormik.isSubmitting || !updateProfileFormik.dirty}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    },
    {
      state: 'loading',
      content: <Skeleton width="100" />
    }
  ];

  const secondProfileStates = [
    {
      state: 'nonexistent',
      content: (
        <Box sx={{ mt: 4, justifyContent: 'center' }}>
          <Grid container justifyContent={'center'}>
            <Grid item>
              <Button startIcon={<AddIcon />} sx={{ margin: 'auto' }} onClick={() => setSecondProfileState('create')}>
                Add A Second Profile
              </Button>
            </Grid>
          </Grid>
        </Box>
      )
    },
    {
      state: 'view',
      content: (
        <Card sx={{ margin: 'auto', mt: 4 }} elevation={10}>
          <ViewSBProfile
            {...secondProfile}
            handleEditClick={handleSecondProfileEditClick}
            handleEnableClick={() => handleEnableProfile(secondProfile)}
            isStatusLoading={isStatusLoading}
            allowEdit={true}
            isUnderReview={review?.reviewStatus?.toLowerCase() === 'pending'}
          />
        </Card>
      )
    },
    {
      state: 'create',
      content: (
        <form onSubmit={createProfileFormik.handleSubmit} id="create-second-profile-form">
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 6 }}>
            <Grid item xs={9}>
              <SBProfileBuilder formik={createProfileFormik} />
            </Grid>
            <Grid item>
              <Button onClick={() => setSecondProfileState('nonexistent')}>Cancel</Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!createProfileFormik.isValid || createProfileFormik.isSubmitting || !createProfileFormik.dirty}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    },
    {
      state: 'update',
      content: (
        <form onSubmit={updateProfileFormik.handleSubmit} id="update-second-profile-form">
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 6 }}>
            <Grid item xs={9}>
              <SBProfileBuilder formik={updateProfileFormik} />
            </Grid>
            <Grid item>
              <Button onClick={() => setSecondProfileState('view')}>Cancel</Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!updateProfileFormik.isValid || updateProfileFormik.isSubmitting || !updateProfileFormik.dirty}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    },
    {
      state: 'loading',
      content: <Skeleton width="100" height={200} />
    }
  ];

  return (
    <Box sx={{ p: 5, animation: 'append-animate .3s linear;' }}>
      <TransitionGroup>
        <Collapse key={firstProfileState}>
          {firstProfileStates.find((item) => item.state === firstProfileState)?.content}
        </Collapse>
      </TransitionGroup>
      {currentRetailerPlatform !== Platform.SAMS_CLUB.value && (
        <TransitionGroup>
          <Collapse key={secondProfileState}>
            {secondProfileStates.find((item) => item.state === secondProfileState)?.content}
          </Collapse>
        </TransitionGroup>
      )}
    </Box>
  );
};
