import { useState } from 'react';

const useDialog = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [dynamicKey, setDynamicKey] = useState(0);

  function toggle() {
    setIsShowing(!isShowing);
    if (!isShowing) {
      setDynamicKey(dynamicKey + 1);
    }
  }

  return {
    isShowing,
    toggle,
    dynamicKey
  };
};

export default useDialog;
