import { Box, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import {
  METRIC_COLUMNS,
  MetricColumnField
} from '../../../../walmart/components/grid-components/column-configurations/metric-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { AmazonCampaignType } from '../../../types/campaign';
import { AMAZON_TARGET_MINIMUM_BID, AmazonTargetMatchType } from '../../../types/target';
import OverviewAdGroupSearchTermHarvestingBulkAction from './create-dialogs/add-harvested-keywords';
import { buildTargetObject } from './create-dialogs/create-targets-dialog';

const HARVESTING_COLUMNS = [
  {
    field: 'searchTerm',
    headerName: 'Search Term',
    width: 200,
    type: 'string'
  },
  ...METRIC_COLUMNS.filter((column) =>
    [
      MetricColumnField.Cost,
      MetricColumnField.AttributedRevenue,
      MetricColumnField.Impressions,
      MetricColumnField.Clicks,
      MetricColumnField.Units,
      MetricColumnField.Orders,
      MetricColumnField.NewToBrandOrders,
      MetricColumnField.NewToBrandRevenue,
      MetricColumnField.NewToBrandUnits,
      MetricColumnField.ConversionRate,
      MetricColumnField.ClickThroughRate,
      MetricColumnField.CostPerClick,
      MetricColumnField.CostPerConversion,
      MetricColumnField.ReturnOnAdSpend
    ].includes(column.field)
  )
];

export interface OverviewAdGroupSearchTermHarvestingRow {
  platformKeywordId: string;
  searchTerm: string;
  matchType: string;
  targeting: string;
}

const OverviewAdGroupSearchTermHarvesting = (props: { adGroupId: string | null }) => {
  const { adGroupId } = props;
  const { getSearchTermReportByAsin, createTargets } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [searchTermHarvestingRows, setSearchTermHarvestingRows] = useState<OverviewAdGroupSearchTermHarvestingRow[]>([]);
  const [bid, setBid] = useState(0);

  const { pageable, setPageable, handleFilterModelChange, handleSortModelChange, handlePageChange, handlePageSizeChange } =
    usePageable();
  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_ADGROUP_OVERVIEW_SEARCH_TERM_HARVESTING,
    breadCrumbParams: {}
  });

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    // Execute PUT to add target to ad group here
    return newRow;
  }, []);

  const fetchSearchTermHarvesting = async () => {
    if (!adGroupId || !pageable) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await getSearchTermReportByAsin(adGroupId, pageable, dateSettings.beginDate, dateSettings.endDate);

      if (response.success) {
        setSearchTermHarvestingRows(response?.body?.dataGrid?.records || []);
        setRowCount(response.body?.dataGrid?.totalFilteredRecords ?? 0);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    } catch (error: any) {
      enqueueSnackbar('Error fetching search term harvesting...', { variant: 'error' });
    }

    setIsLoading(false);
  };

  const handleConfirmAndApplyKeywords = async (rows: OverviewAdGroupSearchTermHarvestingRow[]) => {
    if (!adGroupId) {
      return;
    }

    if (bid < AMAZON_TARGET_MINIMUM_BID) {
      enqueueSnackbar(`Bid must be $${AMAZON_TARGET_MINIMUM_BID} or greater`, { variant: 'error' });
      return;
    }

    const keywords = rows.map((row) => {
      return {
        keywordText: row.searchTerm,
        matchType: AmazonTargetMatchType.Exact,
        bid: bid
      };
    });

    const targets = keywords.map((keyword) => {
      return buildTargetObject(keyword, adGroupId, AmazonCampaignType.SPONSORED_PRODUCTS);
    });

    const response = await createTargets(targets, 'Harvesting');

    if (response.success) {
      enqueueSnackbar(`${targets.length} Targets created successfully`, { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    await fetchSearchTermHarvesting();
  };

  useEffect(() => {
    if (!adGroupId || !pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    fetchSearchTermHarvesting();
  }, [pageable, settingsLoading, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box my={'0.5rem'} sx={{ height: '76vh' }}>
      <Box display="flex" alignItems={'center'} sx={{ mb: '0.5rem', mt: '1rem' }}>
        <Typography variant="h6">Harvesting</Typography>
      </Box>
      {!initialSettings || settingsLoading ? (
        <DataGridPremium
          rows={[]}
          columns={HARVESTING_COLUMNS}
          disableRowGrouping
          loading={true}
          processRowUpdate={processRowUpdate}
          initialState={{}}
          key={uuidv4()}
        />
      ) : (
        <PerformanceGrid
          processRowUpdate={processRowUpdate}
          loading={isLoading}
          rows={searchTermHarvestingRows}
          columns={HARVESTING_COLUMNS}
          initialState={initialSettings?.config}
          saveGridConfig={saveGridConfig}
          dateConfig={{
            dateSettings: dateSettings,
            dateSettingsLoading: dateSettingsLoading,
            saveDateConfig: saveDateConfig
          }}
          getRowId={(row: OverviewAdGroupSearchTermHarvestingRow) => row.searchTerm}
          handleFilterModelChange={handleFilterModelChange}
          handleSortModelChange={handleSortModelChange}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={rowCount}
          bottomMargin={25}
          bulkActions={
            <OverviewAdGroupSearchTermHarvestingBulkAction
              handleConfirmAndApply={handleConfirmAndApplyKeywords}
              setBid={setBid}
            />
          }
        />
      )}
    </Box>
  );
};

export default OverviewAdGroupSearchTermHarvesting;
