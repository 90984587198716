import * as Yup from 'yup';
import { samsClubCampaignTypes } from '../../../types/campaign';
import { keywordMatchTypes, campaignBuilderTargetingTypes } from '../../../../shared/types/walmart-sams-club/campaign';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const CAMPAIGN_FORM_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().max(255).required(),
  campaignType: Yup.string()
    .oneOf(samsClubCampaignTypes.map((option) => option.value))
    .required('Campaign Type is required'),
  targetingType: Yup.string()
    .oneOf(campaignBuilderTargetingTypes.map((type) => type.value))
    .required('Targeting Type is required'),
  startDate: Yup.date().nullable().min(today, 'Start date cannot have passed').required('Start Date is required'),
  endDate: Yup.date()
    .nullable()
    .min(today, 'End date cannot be before start date')
    .when('startDate', (startDate, schema) => {
      return startDate ? schema.min(startDate, 'End date must come after start date.') : schema;
    }),
  items: Yup.array()
    .min(1, 'At least one item is required')
    .of(Yup.object({ itemId: Yup.string().required(), itemName: Yup.string().required() }))
    .required(),
  keywords: Yup.array().of(
    Yup.object({
      keywordText: Yup.string().required(),
      matchType: Yup.string()
        .oneOf(keywordMatchTypes.map((type) => type.value))
        .required(),
      bid: Yup.number().required()
    })
  ),
  negativeKeywords: Yup.array().of(
    Yup.object({
      id: Yup.string().required(),
      keywordText: Yup.string().required(),
      matchType: Yup.string()
        .oneOf(keywordMatchTypes.map((type) => type.value))
        .required()
    })
  ),
  searchAmpName: Yup.string().max(35),
  headlineText: Yup.string().max(45)
});

export const INITIAL_FORM_VALUES = {
  targetingType: 'manual',
  campaignType: 'sponsoredProducts',
  budgetType: 'daily',
  startDate: null,
  endDate: null,
  items: [],
  keywords: [],
  negativeKeywords: [],
  name: '',
  dailyBudget: 50,
  totalBudget: 100,
  submit: null,
  searchAmpName: '',
  headlineText: '',
  clickUrl: '',
  logoDataUri: ''
};
