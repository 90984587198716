import { Box } from '@mui/material';
import { GridAggregationModel } from '@mui/x-data-grid-premium';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sumNoLabel } from '../../../../shared/components/grid-components/aggregationFunctions';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import PlacementPerformanceChart from '../../../../shared/components/placement-performance-chart';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { caseInsensitiveEquals } from '../../../../shared/utilities/string-utilities';
import { PLACEMENT_PERFORMANCE_COLUMNS } from '../../../components/grid-components/column-configurations/placement-performance-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import {
  BackendDynamicBidding,
  BackendPlacementBidding,
  BiddablePlacements,
  Campaign,
  NonbiddablePlacements,
  StrategyType
} from '../../../types/campaign';
import { UnifiedCampaignBuilderRequest } from '../../../types/campaign-builder-request';
import { PlacementMetrics } from '../../../types/campaign-overview';

interface PlacementPerformanceRow extends PlacementMetrics {
  strategy: StrategyType | null;
  percentage: number | null;
  rowNumber: number;
}

const PLACEMENT_ORDER: { [placement: string]: number } = {
  [BiddablePlacements.TopOfSearch.value]: 0,
  [BiddablePlacements.RestOfSearch.value]: 1,
  [BiddablePlacements.ProductPages.value]: 2,
  [NonbiddablePlacements.OffSite.value]: 3
};

const buildPlacementPerformanceRows = (
  placementMetrics: PlacementMetrics[],
  campaignDynamicBidding?: BackendDynamicBidding
): PlacementPerformanceRow[] => {
  // Combine placement metrics with dynamic bidding from campaign
  const rows: PlacementPerformanceRow[] = placementMetrics.map((placementMetric, index) => {
    let placementBidding: BackendPlacementBidding | undefined = undefined;

    if (campaignDynamicBidding && campaignDynamicBidding.placementBidding && campaignDynamicBidding.placementBidding.length > 0) {
      placementBidding = campaignDynamicBidding.placementBidding.find(
        (bidding) => bidding.placement === placementMetric.placement
      );
    }

    return {
      ...placementMetric,
      strategy: campaignDynamicBidding?.strategy ?? null,
      percentage: placementBidding?.percentage ?? (placementMetric.placement in BiddablePlacements ? 0 : null),
      rowNumber: index + 1
    };
  });

  return rows;
};

interface PlacementPerformanceProps {
  campaign: Campaign;
  setCampaign: (campaign: Campaign) => void;
  refresh?: boolean;
}

export const PlacementPerformance = (props: PlacementPerformanceProps) => {
  const { campaign, setCampaign, refresh } = props;

  const { getPlacementMetrics, updateCampaign } = useAmazonApi();
  const [searchParams] = useSearchParams();

  const campaignId = searchParams.get('campaignId');

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();
  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_OVERVIEW_PLACEMENT_PERFORMANCE,
    breadCrumbParams: { campaignId }
  });
  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const [isLoading, setIsLoading] = useState(true);
  const [placementMetrics, setPlacementMetrics] = useState<PlacementMetrics[]>([]);
  const [placementPerformanceRows, setPlacementPerformanceRows] = useState<PlacementPerformanceRow[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any>([]);

  const processRowUpdate = useCallback(async (newRow: PlacementPerformanceRow, oldRow: PlacementPerformanceRow) => {
    if (newRow['percentage'] === oldRow['percentage']) {
      return newRow;
    }

    if (!caseInsensitiveEquals(campaign.campaignType ?? '', 'sponsoredProduct')) {
      enqueueSnackbar('Dynamic bidding is only available for Sponsored Products campaigns', { variant: 'error' });
      return oldRow;
    }

    const newDynamicBidding: BackendDynamicBidding = { ...campaign.amazonSponsoredProductsDynamicBidding };
    const placementBidding = newDynamicBidding.placementBidding.find((bidding) => bidding.placement === newRow.placement);

    if (placementBidding === undefined) {
      newDynamicBidding.placementBidding.push({
        placement: newRow.placement,
        percentage: newRow.percentage ?? 0
      });
    } else {
      placementBidding.percentage = newRow.percentage ?? 0;
    }

    const updateCampaignRequest: UnifiedCampaignBuilderRequest = {
      campaignId: campaignId ?? '',
      amazonSponsoredProductsDynamicBidding: newDynamicBidding
    };

    const response = await updateCampaign(updateCampaignRequest);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setCampaign({ ...campaign, amazonSponsoredProductsDynamicBidding: newDynamicBidding });
    enqueueSnackbar('Bid adjustment updated successfully', { variant: 'success' });

    return newRow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPlacementMetrics = async () => {
    // Only fetch if campaignId is present in filters
    if (!pageable?.filters?.some((filter) => filter.column === 'campaignId')) {
      return;
    }

    setIsLoading(true);

    const response = await getPlacementMetrics(pageable, campaignId, dateSettings.beginDate, dateSettings.endDate, true);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      setIsLoading(false);
      return;
    }

    setPlacementMetrics(response.body.dataGrid);

    const rows = buildPlacementPerformanceRows(response.body.dataGrid, campaign.amazonSponsoredProductsDynamicBidding);
    setTimeSeriesData(response.body.timeSeriesData);

    const sortedRows: PlacementPerformanceRow[] = rows
      .filter((row) => row.placement in PLACEMENT_ORDER)
      .sort((a, b) => {
        return PLACEMENT_ORDER[a.placement] - PLACEMENT_ORDER[b.placement];
      });

    setPlacementPerformanceRows(sortedRows);
    setRowCount(sortedRows.length);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!campaignId) {
      return;
    }

    setBreadcrumbParams({ campaignId });
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    fetchPlacementMetrics();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh === null || refresh === undefined) {
      return;
    }

    fetchPlacementMetrics();
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignContent={'center'} sx={{ my: '0.5rem' }}></Box>
      <PerformanceGrid
        processRowUpdate={processRowUpdate}
        loading={isLoading || settingsLoading || dateSettingsLoading}
        rows={placementPerformanceRows}
        columns={PLACEMENT_PERFORMANCE_COLUMNS}
        initialState={initialSettings?.config}
        saveGridConfig={saveGridConfig}
        dateConfig={{
          dateSettings: dateSettings,
          dateSettingsLoading: dateSettingsLoading,
          saveDateConfig: saveDateConfig
        }}
        getRowId={(row: PlacementPerformanceRow) => `${row.rowNumber}`}
        handleFilterModelChange={handleFilterModelChange}
        handleSortModelChange={handleSortModelChange}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        rowCount={rowCount}
        otherDataGridProps={{
          disableRowSelectionOnClick: true,
          autoHeight: true
        }}
        customProps={{
          customAggregationFunctions: {
            sumNoLabel: sumNoLabel
          },
          customAggregationModel: {
            impressions: 'sumNoLabel',
            clicks: 'sumNoLabel',
            cost: 'sumNoLabel',
            orders: 'sumNoLabel',
            attributedRevenue: 'sumNoLabel',
            units: 'sumNoLabel'
          } as GridAggregationModel
        }}
        aggregatedTotals={{
          impressions: placementMetrics.reduce(
            (totalImpressions, currentPlacement) => totalImpressions + currentPlacement.impressions,
            0
          ),
          clicks: placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0),
          cost: placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0),
          attributedRevenue: placementMetrics.reduce(
            (totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue,
            0
          ),
          clickThroughRate:
            placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) /
              placementMetrics.reduce(
                (totalImpressions, currentPlacement) => totalImpressions + currentPlacement.impressions,
                0
              ) || null,
          orders: placementMetrics.reduce((totalOrders, currentPlacement) => totalOrders + currentPlacement.orders, 0),
          conversionRate:
            placementMetrics.reduce((totalOrders, currentPlacement) => totalOrders + currentPlacement.orders, 0) /
              placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) || null,
          returnOnAdSpend:
            placementMetrics.reduce((totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue, 0) /
              placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) || null,
          costPerClick:
            placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) /
              placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) || null,
          costPerConversion:
            placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) /
              placementMetrics.reduce((totalOrders, currentPlacement) => totalOrders + currentPlacement.orders, 0) || null,
          advertisingCostOfSale:
            placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) /
              placementMetrics.reduce((totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue, 0) ||
            null,
          revenuePerClick:
            placementMetrics.reduce((totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue, 0) /
              placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) || null
        }}
      />
      <PlacementPerformanceChart timeSeriesData={timeSeriesData} />
    </Box>
  );
};
