import { executeGetRequest, ResponseObject } from './fetch-utilities';
import { Group } from '@microsoft/microsoft-graph-types';

const GRAPH_BASE_URL = 'https://graph.microsoft.com/v1.0';

export const getNextPage = async (endpoint: string, authToken: string): Promise<ResponseObject> => {
  return executeGetRequest({
    endpoint,
    authToken
  });
};

export const getUserPhoto = async (authToken: string): Promise<ResponseObject> => {
  return await executeGetRequest({
    endpoint: `${GRAPH_BASE_URL}/me/photo/$value`,
    authToken: authToken
  });
};

export const getUserSecurityGroups = async (authToken: string): Promise<Group[]> => {
  let securityGroups: Group[] = [];

  let response = await executeGetRequest({
    endpoint: `${GRAPH_BASE_URL}/me/memberOf`,
    authToken: authToken
  });

  if (response.success) {
    securityGroups.push(...response.body.value);
  }

  while (response.body['@odata.nextLink']) {
    response = await getNextPage(response.body['@odata.nextLink'], authToken);

    if (response.success) {
      securityGroups.push(...response.body.value);
    }
  }

  return securityGroups;
};
