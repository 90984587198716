import { GridColDef, GridEditInputCell, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { renderProfileNameWithActions } from '../renderer/profile/render-name-with-actions';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { METRIC_COLUMNS } from './metric-columns';
import { Platform } from '../../../../shared/types/platforms';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { getOffPaceFontColor } from '../../../../shared/components/grid-components/pacing-font-color';
import { Box, Typography } from '@mui/material';

export const PROFILE_COLUMNS: GridColDef[] = [
  {
    field: 'profileId',
    headerName: 'Profile ID',
    width: 100,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 170,
    minWidth: 130,
    editable: false,
    align: 'left',
    renderCell: renderProfileNameWithActions(Platform.WALMART.value)
  },
  {
    field: 'platformName',
    headerName: 'Platform Name',
    width: 160,
    minWidth: 120,
    editable: false,
    filterable: false
  },
  {
    field: 'platformId',
    headerName: 'Platform ID',
    width: 160,
    minWidth: 90,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'roasGoal',
    headerName: 'RoAS Goal',
    width: 120,
    minWidth: 80,
    editable: true,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter,
    cellClassName: () => {
      return 'numeric-edit-cell';
    },
    renderEditCell: (props: GridRenderEditCellParams) => {
      return <GridEditInputCell {...props} sx={{ border: 1, borderColor: 'success.main', borderRadius: '4px' }} />;
    }
  },
  {
    field: 'currentMonthlyBudget',
    headerName: 'Current Monthly Budget',
    width: 200,
    minWidth: 100,
    editable: false,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'currentMonthlySpend',
    headerName: 'Current Monthly Spend',
    width: 200,
    minWidth: 100,
    editable: false,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'currentMonthlySpendTarget',
    headerName: 'Current Monthly Spend Target',
    width: 220,
    minWidth: 100,
    editable: false,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'offTargetPercent',
    headerName: '% Off Pace',
    width: 120,
    minWidth: 80,
    editable: false,
    type: 'number',
    renderCell: ({ value }) => {
      return (
        <Box sx={{ p: 2, width: '100%' }}>
          <Typography variant="body2" sx={{ color: getOffPaceFontColor(Number(value)), float: 'right' }}>
            {value}%
          </Typography>
        </Box>
      );
    }
  },
  ...METRIC_COLUMNS
];
