import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const AD_GROUP_STATUS_OPTIONS = [
  {
    label: 'Enabled',
    value: 'enabled',
    icon: PlayArrowIcon
  },
  {
    label: 'Disabled',
    value: 'disabled',
    icon: PauseIcon
  }
];
