import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { METRIC_COLUMNS } from './metric-columns';
import { renderProductCell } from '../renderer/renderProductCell';

const amazonMetricColumns = METRIC_COLUMNS.filter(
  (column) => column.field !== 'units' && !column.field.toLowerCase().includes('newtobrand')
);

export const PRODUCT_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product Name',
    width: 300,
    minWidth: 300,
    sortable: true,
    filterable: true,
    renderCell: renderProductCell
  },
  ...amazonMetricColumns
];
