import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  Avatar,
  Backdrop,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import Box from '@mui/material/Box';
import { amber, green, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ViewSBProfile } from '../../../../walmart-sams-club-sb-profile/view-sb-profile';
import { useAdsApi } from '../../../../../hooks/use-walmart-sams-club-api';
import {
  ReviewComments,
  ReviewStepProps,
  SbReviewRequest as WalmartSbaReviewRequest
} from '../../../../../types/walmart-sams-club/review';
import ReviewDetails from '../review-details';
import { ReviewStatusChip } from '../review-status-chip';
import { ItemStatusTable, KeywordStatusTable } from '../review-status-tables';

export default function CompletedReview(props: ReviewStepProps) {
  const { review, campaign, firstProfile, secondProfile, ads, keywords, profileId, campaignId, adGroupId } = props;
  const { walmartCreateSbaReview } = useAdsApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const approvedItemCount = ads?.filter((item: any) => item.reviewStatus === 'Approved')?.length;
  const approvedKeywordCount = keywords?.filter((item: any) => item.status === 'Approved')?.length;

  const newAds = ads?.slice(0)?.filter((item: any) => item.reviewStatus === 'Pending');
  const newKeywords = keywords?.slice(0)?.filter((item: any) => item.status === 'Pending');

  const newItemsCount = newAds?.length;
  const newKeywordsCount = newKeywords.length;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatedItemsDetailsIn, setUpdatedItemsDetailsIn] = useState(false);
  const [detailsIn, setDetailsIn] = useState(false);

  const newElementsExist =
    firstProfile?.reviewStatus === 'Pending' ||
    secondProfile?.reviewStatus === 'Pending' ||
    newItemsCount > 0 ||
    newKeywordsCount > 0;
  const isEligibleForReivew = campaign?.status === 'Live' || campaign?.status === 'Paused' || campaign?.status === 'Proposal';

  const CompletedReviewStatus = (props: any) => {
    const { approved } = props;

    return <Avatar sx={{ backgroundColor: approved ? green[500] : red[500] }}>{approved ? <CheckIcon /> : <ErrorIcon />}</Avatar>;
  };

  const submitSbaReview = async () => {
    setIsSubmitting(true);

    const request: WalmartSbaReviewRequest = {
      campaignId: campaignId?.toString() || '',
      adGroupId: adGroupId?.toString() || ''
    };

    const response = await walmartCreateSbaReview(profileId, request);

    if (response.success) {
      if (response?.body[0]?.code === 'Success') {
        enqueueSnackbar('Review submitted.', { variant: 'success' });
        navigate(`/walmart/campaigns?profileId=${profileId}`);
        return;
      }

      const walmartErrorMessage = response?.body[0].details || 'Unable to submit review';
      enqueueSnackbar(walmartErrorMessage, { variant: 'error', persist: true });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      navigate(`/walmart/campaigns?profileId=${profileId}`);
    }

    setIsSubmitting(false);
  };

  const PendingSBProfileListItem = () => {
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ReportProblemIcon sx={{ color: amber[500] }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="SB Profile - Pending*"
          secondary={`*The Profile has been updated since the most recent review (Review ${review?.reviewId}).`}
        />
      </ListItem>
    );
  };

  const SecondProfileListItem = () => {
    if (!secondProfile) {
      return <></>;
    }

    if (secondProfile.reviewStatus === 'Pending') {
      return <PendingSBProfileListItem />;
    }

    return (
      <ListItem>
        <ListItemAvatar>
          <CompletedReviewStatus approved={secondProfile?.reviewStatus === 'Approved'} />
        </ListItemAvatar>
        <ListItemText primary="SB Profile" secondary={secondProfile?.reviewStatus} />
      </ListItem>
    );
  };

  return (
    <>
      {newElementsExist && isEligibleForReivew ? (
        <Card>
          <CardActionArea onClick={() => setUpdatedItemsDetailsIn(!updatedItemsDetailsIn)}>
            <CardHeader
              title={
                <Grid container direction="row" justifyContent={'space-between'}>
                  <Grid item>
                    <Box sx={{ display: 'inline-flex' }}>
                      {' '}
                      <FiberNewIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">Unreviewed Items Detected</Typography>
                    </Box>
                  </Grid>
                  <Grid item>{updatedItemsDetailsIn ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Grid>
                </Grid>
              }
              disableTypography={true}
            />
          </CardActionArea>

          <Collapse in={updatedItemsDetailsIn}>
            <CardContent>
              <Typography variant="body2">{`Items have been added to this ${campaign?.status?.toLowerCase()} Campaign since the last review.`}</Typography>
              <Typography variant="body2">
                To include the pending items within this Campaign, please submit a new review.
              </Typography>
              <br />
              {firstProfile?.reviewStatus === 'Pending' ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">SB PROFILE</Typography>
                  <ViewSBProfile {...firstProfile} />{' '}
                </Box>
              ) : null}
              {secondProfile?.reviewStatus === 'Pending' ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">SB PROFILE</Typography>
                  <ViewSBProfile {...secondProfile} />{' '}
                </Box>
              ) : null}
              {newItemsCount > 0 ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">ADS</Typography>
                  <ItemStatusTable items={newAds} />
                </Box>
              ) : null}
              <br />
              {newKeywordsCount > 0 ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">KEYWORDS</Typography> <KeywordStatusTable keywords={newKeywords} />
                </Box>
              ) : null}
              <br />
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <Button variant="contained" onClick={submitSbaReview}>
                    Submit a New Review
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      ) : null}
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={
            <Box sx={{ display: 'inline-flex' }}>
              <Typography variant="h6">REVIEW</Typography> <ReviewStatusChip status={review?.reviewStatus} sx={{ ml: 2 }} />
            </Box>
          }
          disableTypography={true}
        />
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h6">Review {review?.reviewId}</Typography>
            </Grid>
          </Grid>
          <List sx={{ mt: 2, bgcolor: 'background.paper' }}>
            {firstProfile?.reviewStatus === 'Pending' ? (
              <PendingSBProfileListItem />
            ) : (
              <ListItem>
                <ListItemAvatar>
                  <CompletedReviewStatus approved={firstProfile?.reviewStatus === 'Approved'} />
                </ListItemAvatar>
                <ListItemText primary="SB Profile" secondary={firstProfile?.reviewStatus} />
              </ListItem>
            )}
            <SecondProfileListItem />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <CompletedReviewStatus approved={approvedItemCount >= 2} />
              </ListItemAvatar>
              <ListItemText
                primary="Ad Items"
                secondary={
                  <>
                    {`${approvedItemCount} Item${approvedItemCount == 1 ? '' : 's'} Approved`}
                    {newAds?.length > 0 ? (
                      <Typography variant="body2">{`${newAds.length} Item${
                        newAds.length == 1 ? '' : 's'
                      } Pending  (Added since last review)`}</Typography>
                    ) : (
                      ''
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <CompletedReviewStatus approved={approvedKeywordCount >= 1} />
              </ListItemAvatar>
              <ListItemText
                primary="Keywords"
                secondary={
                  <>
                    {`${approvedKeywordCount} Keyword${approvedKeywordCount == 1 ? '' : 's'} Approved`}
                    {newKeywords?.length > 0 ? (
                      <Typography variant="body2">{`${newKeywords.length} Keyword${
                        newKeywords.length == 1 ? '' : 's'
                      } Pending  (Added since last review)`}</Typography>
                    ) : (
                      ''
                    )}
                  </>
                }
              />
            </ListItem>
          </List>
        </CardContent>
        {review?.reviewComments?.length > 0 ? (
          <>
            <CardActions>
              <Button onClick={() => setDetailsIn(!detailsIn)}>
                Expand to see {review?.reviewComments?.length} comment{review?.reviewComments?.length === 1 ? '' : 's'}
                {detailsIn ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </CardActions>
            <Collapse in={detailsIn}>
              <CardContent>
                <List>
                  {review?.reviewComments?.map((comment: ReviewComments) => (
                    <ListItem key={comment.comments}>
                      <ListItemText primary={comment.commentType} secondary={comment.comments} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Collapse>
          </>
        ) : null}
      </Card>
      <br />
      <ReviewDetails {...props} />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
