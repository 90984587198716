import InfoIcon from '@mui/icons-material/Info';
import { Box, LinearProgress, List, ListItem, ListItemText, ListSubheader, Switch, Tooltip } from '@mui/material';
import { Placements } from '../../types/placement';

interface CampaignPlacementProps {
  loading: boolean;
  placements: Placements;
  handlePlacementChange: (event: { target: { checked: boolean; name: string } }) => void;
  disableSwitches: boolean;
}

export const CampaignPlacement = (props: CampaignPlacementProps) => {
  const { loading, placements, handlePlacementChange, disableSwitches } = props;
  const tooltipTitle = 'Placements cannot be changed on Auto campaigns';

  return (
    <Box>
      <List>
        <ListSubheader>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            CAMPAIGN PLACEMENTS
            <Tooltip title="To opt in to a placement, switch the toggle to the right">
              <InfoIcon sx={{ ml: 2 }} />
            </Tooltip>
          </Box>
        </ListSubheader>
        <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
        <Tooltip placement="top-end" title={disableSwitches ? tooltipTitle : ''}>
          <Box>
            <ListItem>
              <ListItemText primary="Buy Box" />
              <Switch
                disabled={disableSwitches}
                checked={placements.buyBoxPlacement}
                onChange={handlePlacementChange}
                name="buyBoxPlacement"
                color="primary"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Item Carousel" />
              <Switch
                disabled={disableSwitches}
                checked={placements.itemCarouselPlacement}
                onChange={handlePlacementChange}
                name="itemCarouselPlacement"
                color="primary"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Search Carousel" />
              <Switch
                disabled={disableSwitches}
                checked={placements.searchCarouselPlacement}
                onChange={handlePlacementChange}
                name="searchCarouselPlacement"
                color="primary"
              />
            </ListItem>
          </Box>
        </Tooltip>
      </List>
    </Box>
  );
};
