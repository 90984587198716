import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DraftIcon from '@mui/icons-material/PlayArrow';
import ArchiveIcon from '@mui/icons-material/PlayArrow';

export const keywordMatchTypes = [
  {
    label: 'Exact',
    value: 'exact'
  }
];

export interface ProductCampaign {
  name: string;
  namespaceId: string;
  approval?: any;
  campaignState: string;
  templateId?: string;
  managerTeamId?: string;
  teamId: string;
  startTime: string;
  endTime?: string;
  walletId: string;
  placementId: string;
  catalogIds: [string];
  advertisedProducts: any;
  targeting: any;
  strategy?: any;
  fixedCosts?: any;
  customFields?: any;
  customQuestions?: any;
  sellerId?: any;
}

export interface UpdateCampaignRequest {
  campaignId: number;
  name?: string;
  isActiveInVector?: boolean;
  startDate?: string | undefined;
  endDate?: string | undefined;
  totalBudget?: number;
  dailyBudget?: number;
  budgetType?: string;
}

export interface Product {
  itemId: number;
  itemName: string | null;
  itemImageUrl: string;
}

export const productDesign = {
  img: {
    height: '60px',
    width: '60px',
    borderRadius: '4px',
    marginLeft: 'auto'
  },
  title: {
    display: 'flex',
    flexDirection: 'column'
  }
};

const MatchTypeMap: any = {
  APPROVAL_STATE_APPROVED: 'Approved',
  APPROVAL_STATE_REJECTED: 'Rejected',
  APPROVAL_STATE_PENDING: 'Pending',
  APPROVAL_STATE_UNSPECIFIED: 'Unspecified',
  MATCH_TYPE_EXACT_MATCH: 'Exact'
};

export const getMatchType = (val: string) => {
  return MatchTypeMap[val] || '';
};

export const CAMPAIGN_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'CAMPAIGN_STATE_ACTIVE',
    icon: PlayArrowIcon
  },
  {
    label: 'Draft',
    value: 'CAMPAIGN_STATE_DRAFT',
    icon: DraftIcon
  },
  {
    label: 'Paused',
    value: 'CAMPAIGN_STATE_PAUSED',
    icon: PauseIcon
  },
  {
    label: 'Archived',
    value: 'CAMPAIGN_STATE_ARCHIVED',
    icon: ArchiveIcon
  }
];

export const PLACEMENT_TYPES = {
  test: [
    {
      value: '04e721ac-9694-4ab0-9b4b-8773372691ca2',
      label: 'Search',
      abbreviatedLabel: 'Search'
    },
    {
      value: '04e721ac-9694-4ab0-9b4b-8773372691ca',
      label: 'Category',
      abbreviatedLabel: 'Category'
    },
    {
      value: '04e721ac-9694-4ab0-9b4b-8773372691ca1',
      label: 'PDP',
      abbreviatedLabel: 'PDP'
    }
  ],
  production: [
    {
      value: '4099eab0-9c9d-417d-a26a-7d06507d0f3a',
      label: 'Search',
      abbreviatedLabel: 'Search'
    },
    {
      value: '3d317587-da24-4b14-891d-74b1726bb15f',
      label: 'Category',
      abbreviatedLabel: 'Category'
    },
    {
      value: '29943df4-71e8-431d-bbc5-e4bb9012dbfc',
      label: 'PDP',
      abbreviatedLabel: 'PDP'
    }
  ]
};

export const KEYWORD_MATCH_TYPES = new Map<string, any>([
  [
    'Exact',
    {
      label: 'Exact',
      value: 'MATCH_TYPE_EXACT_MATCH'
    }
  ]
]);

export const NAMESPACE_IDS = {
  test: 'partners-sandbox1',
  production: 'target'
};

export const CATALOG_IDS = {
  test: '1cc456eb-72ad-4126-8364-3eae7353b045',
  production: '4ecd30e0-9343-4055-abe1-a8b4751f204d'
};

export interface CampaignMetrics {
  adRevenue: number;
  adSpend: number;
  clickSpend: number;
  clicks: number;
  conversions: number;
  impressions: number;
  impressionSpend: number;
  salesRevenue: number;
  unitsSale: number;
  cost: number;
  AttributedRevenue: number;
  returnOnAdSpend: number;
  clickThroughRate: number;
  conversionRate: number;
  costPerClick: number;
  costPerConversion: number;
}

export interface ProductMetrics extends CampaignMetrics {
  productCode: string;
}
export interface KeywordMetrics extends CampaignMetrics {
  searchTerm: string;
}

export interface CampaignProduct {
  catalogId: string;
  productCode: string;
  sellerId: string;
}

export interface CampaignKeyword {
  phrase: string;
  matchType: string;
  collectionName: string | null;
  suggested: boolean;
  treatAsCustom: boolean;
  maxBid: string;
}
