import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import useAdsApi from '../../../../hooks/use-walmart-sams-club-api';
import { UpdateBidsConfig, UpdateKeywordBidsDto } from '../../../../types/update-bids';
import UpdateBidsActionButton from '../../../performance-grid/edit-bids-action-button';

interface UpdateKeywordBidsProps {
  setIsLoading: (isLoading: boolean) => void;
  selectionModelHasRows: boolean;
}

const UpdateKeywordBids = ({ setIsLoading, selectionModelHasRows }: UpdateKeywordBidsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const apiRef = useGridApiContext();

  const { updateKeywordBids } = useAdsApi();

  const handleUpdateKeywordBids = async (updateBidsConfig: UpdateBidsConfig) => {
    setIsDialogOpen(false);
    setIsLoading(true);

    const selectedKeywords = Array.from(apiRef.current.getSelectedRows().values());

    const requestBody: UpdateKeywordBidsDto = {
      keywordIds: selectedKeywords.map((keyword: any) => keyword.keywordId),
      value: updateBidsConfig.value,
      bidUpdateOperator: updateBidsConfig.bidUpdateOperator,
      bidUpdateType: updateBidsConfig.bidUpdateType,
      ...(updateBidsConfig.shouldApplyMaxBid && { maxBid: updateBidsConfig.maxBid }),
      ...(updateBidsConfig.shouldApplyMinBid && { minBid: updateBidsConfig.minBid })
    };

    const response = await updateKeywordBids(requestBody);

    if (response.success) {
      const updatedKeywords = response.body;
      apiRef.current.updateRows(updatedKeywords.map((keyword: any) => ({ keywordId: keyword.keywordId, bid: keyword.bid })));
    }

    apiRef.current.setRowSelectionModel([]);
    setIsLoading(false);
  };

  return (
    <UpdateBidsActionButton
      updateEntities={handleUpdateKeywordBids}
      isDialogOpen={isDialogOpen}
      setIsDialogOpen={setIsDialogOpen}
      selectionModelHasRows={selectionModelHasRows}
    />
  );
};

export default UpdateKeywordBids;
