import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { DaypartingSchedule, AmazonDaypartingStates } from '../../../shared/types/day-parting';
import { DaypartingLegend } from './amazon-day-parting-legend';
import { HourlySchedulesContainer } from './amazon-hourly-schedules-container';
import {
  createEmptySchedule,
  getAutomatedActionFromDaypartingSchedule,
  getDaypartingSchedule
} from '../../daypartingScheduleConversionHelpers';
import useAmazonApi from '../../hooks/use-amazon-api';
import HourlyMetricsChart from '../../../shared/components/day-parting/hourly-metrics-chart/hourly-metrics-chart';
import { OutOfBudgetButton } from '../../../shared/components/day-parting/out-of-budget-button';

interface DaypartingProps {
  profileId: string;
  campaignId: string;
  campaignName: string;
  isOpen: boolean;
  toggle: () => void;
}

interface OutOfBudgetHours {
  day: number;
  hour: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            borderRadius: '0.5rem',
            border: '1px solid',
            borderColor: 'divider',
            padding: '1rem',
            m: '3'
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function AmazonDayparting(props: DaypartingProps) {
  const { profileId, campaignId, campaignName, isOpen, toggle } = props;
  const { saveAutomatedAction, updateAutomatedAction, getDaypartingAction, getOutOfBudgetHours, deleteAutomatedAction } =
    useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const { getCampaignHourlyMetrics } = useAmazonApi();
  const [timeSeriesData, setTimeSeriesData] = useState<any>();

  const [isLoading, setIsLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [automatedActionId, setAutomatedActionId] = useState<string>('');
  const [daySchedules, setDaySchedules] = useState<DaypartingSchedule>({});
  const [shouldShowOutOfBudgetHour, setShouldShowOutOfBudgetHour] = useState(true);
  const [campaignHasDayparting, setCampaignHasDayparting] = useState<boolean>(false);
  const [outOfBudgetHours, setOutOfBudgetHours] = useState<OutOfBudgetHours[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [daysBack, setDaysBack] = useState(7);

  const initialize = async () => {
    setIsLoading(true);

    await initializeHourlySchedule();

    setIsLoading(false);
  };

  const initializeHourlySchedule = async () => {
    const actionResponse = await getDaypartingAction(profileId, campaignId);

    if (actionResponse?.success) {
      if (actionResponse.body === null || actionResponse.body === undefined || actionResponse.body.length === 0) {
        const schedule = createEmptySchedule();
        setDaySchedules(schedule);
        setCampaignHasDayparting(false);
      } else {
        setAutomatedActionId(actionResponse.body.id);
        const actionParameters = JSON.parse(actionResponse.body.actionParameters);
        const schedule = getDaypartingSchedule(actionParameters);

        setDaySchedules(schedule);
        setCampaignHasDayparting(true);
      }
    } else {
      enqueueSnackbar('An error occurred loading the dayparting schedule', { variant: 'error' });
    }

    const outOfBudgetResponse = await getOutOfBudgetHours(campaignId);

    if (outOfBudgetResponse?.success) {
      setOutOfBudgetHours(outOfBudgetResponse.body);
    } else {
      enqueueSnackbar('An error occurred loading the out of budget hours', { variant: 'error' });
    }
  };

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignHourlyMetrics = async (campaignId: string, beginDate: string) => {
    setIsChartLoading(true);

    const response = await getCampaignHourlyMetrics(campaignId, beginDate);
    if (response?.success) {
      await setTimeSeriesData(response.body);
    } else {
      enqueueSnackbar('An error occurred loading the hourly metrics', { variant: 'error' });
    }

    setIsChartLoading(false);
  };

  useEffect(() => {
    const beginDate = new Date();
    beginDate.setDate(beginDate.getDate() - daysBack);
    fetchCampaignHourlyMetrics(campaignId, beginDate.toISOString().split('T')[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, daysBack]);

  const deleteSchedule = async (automatedAction: any) => {
    setIsLoading(true);

    const response = await deleteAutomatedAction(profileId, automatedActionId);

    if (response.success) {
      enqueueSnackbar('Dayparting schedule deleted', { variant: 'success' });
      setCampaignHasDayparting(false);
    } else {
      enqueueSnackbar('An error occurred deleting the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const updateSchedule = async (automatedAction: any) => {
    setIsLoading(true);

    const response = await updateAutomatedAction(automatedAction);

    if (response.success) {
      enqueueSnackbar('Dayparting schedule updated', { variant: 'success' });
    } else {
      enqueueSnackbar('An error occurred updating the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const createSchedule = async (automatedAction: any) => {
    setIsLoading(true);

    const response = await saveAutomatedAction(automatedAction);

    if (response.success) {
      enqueueSnackbar('Dayparting schedule created', { variant: 'success' });
    } else {
      enqueueSnackbar('An error occurred creating the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const saveSchedule = async () => {
    let automatedAction;

    if (allHoursEnabled()) {
      automatedAction = getAutomatedActionFromDaypartingSchedule(daySchedules, campaignId, profileId);
      deleteSchedule(automatedAction);
      return;
    }

    if (campaignHasDayparting) {
      automatedAction = getAutomatedActionFromDaypartingSchedule(daySchedules, campaignId, profileId, automatedActionId);
      await updateSchedule(automatedAction);
      return;
    }

    automatedAction = getAutomatedActionFromDaypartingSchedule(daySchedules, campaignId, profileId);
    await createSchedule(automatedAction);
  };

  const allHoursEnabled = () => {
    return Object.values(daySchedules).every((daySchedule) => {
      return Object.values(daySchedule).every((hour) => {
        return hour === AmazonDaypartingStates.Enabled;
      });
    });
  };

  const handleDaysBackChange = (event: SelectChangeEvent) => {
    setDaysBack(Number(event.target.value));
  };

  return (
    <>
      <Dialog onClose={toggle} maxWidth={'lg'} open={isOpen} onMouseUp={() => setIsDragging(false)}>
        <DialogTitle>
          <Box display="flex" alignItems={'center'} justifyContent="center">
            <Typography variant={'h6'}>Dayparting Schedule ({campaignName})</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mr: 2 }}>
          <DialogContentText sx={{ mb: 2 }}>
            <Box display="flex" justifyContent={'space-between'}>
              <Typography variant={'body2'}>All hours will be interpreted as CST.</Typography>
            </Box>
          </DialogContentText>
          <Box display="flex" justifyContent="space-between" mb={'2rem'}>
            <Tabs value={tabIndex} onChange={(event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue)}>
              <Tab label="Dayparting Schedule" />
              <Tab label="Hourly Metrics" />
            </Tabs>
            {tabIndex === 0 && (
              <OutOfBudgetButton
                shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour}
                setShouldShowOutOfBudgetHour={setShouldShowOutOfBudgetHour}
              />
            )}
            {tabIndex === 1 && (
              <FormControl sx={{ minWidth: '100px' }} disabled={isChartLoading ? true : false}>
                <InputLabel>Days Back</InputLabel>
                <Select value={daysBack.toString()} label="Days Back" onChange={handleDaysBackChange}>
                  <MenuItem value={1}>Yesterday</MenuItem>
                  <MenuItem value={7}>L7D</MenuItem>
                  <MenuItem value={30}>L30D</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
          <CustomTabPanel value={tabIndex} index={0}>
            <HourlySchedulesContainer
              daySchedules={daySchedules}
              setDaySchedules={setDaySchedules}
              shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour}
              isDragging={isDragging}
              isLoading={isLoading}
              setIsDragging={setIsDragging}
              stats={outOfBudgetHours}
            />
            <DaypartingLegend />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            <HourlyMetricsChart timeSeriesData={timeSeriesData} isLoading={isChartLoading} />
          </CustomTabPanel>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={toggle}>
            Cancel
          </Button>
          <Button variant={'contained'} onClick={saveSchedule}>
            Save Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
