import { IconButton, Tooltip } from '@mui/material';

interface IconButtonWithTooltipProps {
  isDisabled?: boolean;
  tooltipText: string;
  handleClick: () => void;
  icon: React.ReactNode;
}

const IconButtonWithTooltip = ({ isDisabled, handleClick, icon, tooltipText }: IconButtonWithTooltipProps) => {
  return (
    <>
      <Tooltip title={tooltipText}>
        <span>
          <IconButton
            sx={{ borderRadius: '100px' }}
            color="primary"
            size="large"
            onClick={handleClick}
            disabled={isDisabled ?? false}
          >
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default IconButtonWithTooltip;
