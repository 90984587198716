import { GridColDef } from '@mui/x-data-grid-premium';
import { amazonRoutes } from '../../../../shared/routing/routes-objects';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { METRIC_COLUMNS } from './metric-columns';

export const PROFILE_COLUMNS: GridColDef[] = [
  {
    field: 'profileId',
    headerName: 'Profile ID',
    width: 100,
    type: 'string'
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 160,
    editable: false,
    align: 'left',
    renderCell: (params) => {
      return (
        <Link
          to={`${amazonRoutes.find((route) => route.key === 'amazon-campaigns')?.path}?profileId=${params.id}`}
          sx={{ textDecoration: 'none', color: 'text.primary' }}
          component={RouterLink}
        >
          {params.value}
        </Link>
      );
    }
  },
  ...METRIC_COLUMNS
];
