import { Box, Card, useTheme } from '@mui/material';
import { FC } from 'react';

interface DashboardCardProps {
  loading?: boolean;
  children: React.ReactNode;
}

export const DashboardCard: FC<DashboardCardProps> = ({ loading, children }) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: loading ? palette.divider : 'transparent',
        opacity: loading ? 0.5 : 1
      }}
    >
      <Card>{children}</Card>
    </Box>
  );
};
