import { FC } from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

export interface MediaErrorDetailModalProps {
  error: string | undefined;
  onClose: () => void;
  open: boolean;
}

export const MediaErrorDetailModal: FC<MediaErrorDetailModalProps> = (props) => {
  const { error, onClose, open } = props;

  const serializedErrors = JSON.parse(error || '');

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={'md'}>
      <DialogTitle>Media Errors</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Error Type</TableCell>
                <TableCell align="left">Error Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serializedErrors.map((error: any, index: number) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {error.type}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align="left">
                    {error.message}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
