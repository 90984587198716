import { Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import * as React from 'react';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  }
}));

interface TargetingTypeProps {
  targetingType: boolean;
}

export const TargetingType = React.memo((props: TargetingTypeProps) => {
  const { targetingType } = props;
  return (
    <Tooltip title="" arrow>
      <StyledChip size={'small'} label={targetingType} variant="outlined" />
    </Tooltip>
  );
});

export function renderTargetingType(params: GridRenderCellParams) {
  if (params.value == null) {
    return '';
  }

  return <TargetingType targetingType={params.value} />;
}
