import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorReview } from '../../../../shared/campaign-builder-error-review';
import MultiItemMultiTargetIcon from '../../../../shared/icons/campaign-type-icons/multi-item-mutli-target';
import SingleItemMultiTargetIcon from '../../../../shared/icons/campaign-type-icons/single-item-multi-target';
import SingleItemSingleTargetIcon from '../../../../shared/icons/campaign-type-icons/single-item-single-target';
import { amazonRoutes } from '../../../../shared/routing/routes-objects';
import { Pageable } from '../../../../shared/types/pageable';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';
import { CAMPAIGN_COLUMNS } from '../../../components/grid-components/column-configurations/campaign-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { ContentSteps } from '../../../types/campaign';
import {
  CampaignMode,
  TargetingMode,
  UserSelectedCampaignType,
  campaignTypeMapping,
  createCampaignBuilderRequest,
  singleItemCampaignStructureTypes
} from '../../../types/campaign-builder-request';
import { CampaignBuilderFormValues, INITIAL_FORM_VALUES, VALIDATION_SCHEMA } from './campaign-form-config';
import CampaignPreview from './campaign-preview';
import { CampaignSettings } from './campaign-settings';
import { CampaignBuilderCampaignStrategySection } from './campaign-strategy';
import { ItemSettings } from './item-settings';
import { KeywordSettings } from './keyword-settings';
import { NegativeKeywordSettings } from './negative-keyword-settings';
import { ProductTargetingSettings } from './product-and-category-targeting/product-targeting-settings';
import RadioCard from './radio-card';
import { TargetingSettings } from './targeting-settings';

const SponsoredProductsCampaignBuilder: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getProfiles, buildCampaign } = useAmazonApi();

  const [userSelectedCampaignType, setUserSelectedCampaignType] = useState<UserSelectedCampaignType>(
    UserSelectedCampaignType.SINGLE_ITEM_SINGLE_TARGET
  );
  const [currentContentToShow, setCurrentContentToShow] = useState(ContentSteps.Create);
  const [profiles, setProfiles] = useState<any[]>([]);
  const [itemStepType, setItemStepType] = useState('allItems');
  const [campaignMode, setCampaignMode] = useState<CampaignMode>(CampaignMode.MANUAL);
  const [targetingMode, setTargetingMode] = useState<TargetingMode>(TargetingMode.KEYWORD);
  const [searchParams, setSearchParams] = useSearchParams();
  const [successfulCampaigns, setSuccessfulCampaigns] = useState<any[]>([]);
  const [productTargets, setProductTargets] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<CampaignBuilderFormValues | {}>({});
  const formikRef = useRef<any>(null);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const parentRef = useRef<HTMLDivElement | null>(null);
  const [parentWidth, setParentWidth] = useState(0);

  const profileId = searchParams.get('profileId') ?? null;

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [campaignBuilderResponse, setCampaignBuilderResponse] = useState<ResponseObject>({
    body: null,
    success: false,
    response: undefined,
    errorMessage: undefined
  });

  useEffect(() => {
    fetchProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!campaignBuilderResponse?.response) {
      return;
    }

    if (campaignBuilderResponse?.body?.errors.length > 0) {
      setCurrentContentToShow(ContentSteps.Error);
      return;
    }

    setSuccessfulCampaigns(campaignBuilderResponse?.body?.successes);

    setCurrentContentToShow(ContentSteps.Complete);
  }, [campaignBuilderResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  const parseErrors = (): string => {
    let errors = [];

    errors.push(
      ...Object.keys(formikRef.current?.errors).map((key: string) => {
        const error: CampaignBuilderFormValues[keyof CampaignBuilderFormValues] =
          formikRef.current?.errors[key as keyof CampaignBuilderFormValues];

        // Check if error is an array and contains objects
        if (Array.isArray(error) && error.some((e) => typeof e === 'object')) {
          return error
            .map((obj) => {
              if (typeof obj === 'object' && obj !== null && obj !== undefined) {
                const nestedKey = Object.keys(obj)[0];
                return `${nestedKey} - ${obj[nestedKey]}`;
              }
            })
            .join('\n');
        } else {
          // Handle the case where the error is just a string
          return error;
        }
      })
    );

    if (isMissingItemNickname(formikRef.current?.values)) {
      errors.push('Please add a nickname to each item');
    }

    return errors.join('\n\n');
  };

  useEffect(() => {
    if (!userSelectedCampaignType || !campaignMode || !targetingMode) {
      return;
    }

    const structureType =
      campaignTypeMapping[userSelectedCampaignType as UserSelectedCampaignType][campaignMode as CampaignMode][
        targetingMode as TargetingMode
      ];

    formikRef?.current?.setFieldValue('campaignStructureType', structureType);
  }, [userSelectedCampaignType, campaignMode, targetingMode]); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    /**
     * This block calculates the explicit width for the child component when it is set to 'position: fixed'.
     *
     * Why do we need this?
     * 1. A fixed position element is taken out of the document's normal flow. Therefore, it doesn't automatically inherit or respect its parent's width.
     * 2. Setting a fixed position element without an explicit width can cause layout issues or unexpected rendering.
     * 3. While `width: inherit` might seem like a solution, it doesn't work in this context because the fixed child doesn't know about its parent's width due to being out of the normal document flow.
     *
     * How does this solution work?
     * - We use a React ref (`parentRef`) to reference the parent container.
     * - We then measure the parent container's offsetWidth to get its rendered width on the screen.
     * - We account for any margins and paddings to ensure the child's width is correctly computed.
     * - We update the child's width based on the measurements whenever the window is resized or the component mounts.
     *
     * This ensures that our UI remains responsive and that our fixed position child behaves as expected across different screen sizes.
     */

    function handleResize() {
      if (parentRef.current) {
        const style = window.getComputedStyle(parentRef.current);

        const leftPadding = parseFloat(style.paddingLeft);
        const rightPadding = parseFloat(style.paddingRight);
        const leftMargin = parseFloat(style.marginLeft);
        const rightMargin = parseFloat(style.marginRight);

        const totalWidth = parentRef.current.offsetWidth - leftPadding - rightPadding + leftMargin + rightMargin;

        setParentWidth(totalWidth);
      }
    }

    handleResize(); // Call once initially
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchProfiles = async () => {
    const pageable = {
      filters: [],
      sorts: [],
      offset: 0,
      limit: 250
    } as Pageable;

    setIsLoading(true);

    const response = await getProfiles(pageable);

    if (response.success) {
      setProfiles(response.body.records);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const handleProfileChange = (event: any, value: any): void => {
    if (!value?.profileId) {
      searchParams.delete('profileId');
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...searchParams,
        profileId: `${value.profileId}`
      });
    }
  };

  const handleItemStepTypeChange = (event: React.MouseEvent<HTMLElement>, itemStepType: string): void => {
    setItemStepType(itemStepType);
  };

  const handleSubmit = async (values: any, helpers: any): Promise<void> => {
    setIsSubmitting(true);

    const updatedValues = { ...values };

    const requestBody = createCampaignBuilderRequest(updatedValues);

    const response = await buildCampaign(profileId, requestBody);

    setCampaignBuilderResponse(response);

    setIsSubmitting(false);
  };

  const userSelectedManualCampaignTypeCardData = [
    {
      Icon: SingleItemSingleTargetIcon,
      title: 'Single Item Single Target',
      text: 'Every (item + keyword) pair gets its own campaign & ad group. If you add 3 items and 3 keywords, you get 9 campaigns',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.SINGLE_ITEM_SINGLE_TARGET
    },
    {
      Icon: SingleItemMultiTargetIcon,
      title: 'Single Item Multiple Targets',
      text: 'Every item gets its own campaign, single ad group, multiple targets. If you add 3 items and 3 keywords, you get 3 campaigns',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.SINGLE_ITEM_MULTIPLE_TARGETS
    },
    {
      Icon: MultiItemMultiTargetIcon,
      title: 'Multiple Items Multiple Targets',
      text: 'Every item is in one campaign, multiple ad groups, with multiple keywords. If you add 3 items and 3 keywords, you get 1 campaign',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.MULTIPLE_ITEMS_MULTIPLE_TARGETS
    }
  ];

  const userSelectedAutoCampaignTypeCardData = [
    {
      Icon: SingleItemMultiTargetIcon,
      title: 'Single Item Auto',
      text: 'Every item gets its own campaign & ad group. If you add 3 items, you get 3 campaigns',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.SINGLE_ITEM_MULTIPLE_TARGETS
    },
    {
      Icon: MultiItemMultiTargetIcon,
      title: 'Multiple Items Auto',
      text: 'Every item is in one campaign, multiple ad groups, with multiple keywords. If you add 3 items, you get 1 campaign',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.MULTIPLE_ITEMS_MULTIPLE_TARGETS
    }
  ];

  const isMissingItemNickname = (values: CampaignBuilderFormValues): boolean => {
    return (
      singleItemCampaignStructureTypes.includes(values.campaignStructureType) &&
      values.items.filter((item) => !item.itemNickname).length > 0
    );
  };

  const createCampaignContentSteps = [
    {
      key: ContentSteps.Create,
      content: (
        <Container maxWidth={isScreenSmall ? 'md' : 'xl'}>
          <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column', width: '100%' }}>
            <Typography variant="h4" gutterBottom>
              Campaign Builder
            </Typography>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Autocomplete
                disablePortal
                options={profiles}
                getOptionLabel={(profile) => profile.name}
                onChange={handleProfileChange}
                value={profiles.find((profile: { profileId: any }) => profile.profileId === profileId) || null}
                sx={{ width: 300, mb: 2 }}
                renderInput={(params) => <TextField {...params} size="small" label="Profile" />}
              />
            </Box>
          </Box>
          <Grid container direction={isScreenSmall ? 'column' : 'row'} spacing={2}>
            <Grid item xs={12} xl={7}>
              <Formik
                initialValues={{ ...INITIAL_FORM_VALUES, profileId: Number(profileId) }}
                validationSchema={VALIDATION_SCHEMA}
                onSubmit={handleSubmit}
              >
                {(formik) => {
                  const { values, touched, errors, isSubmitting, isValid, dirty, setFieldValue, handleBlur } = formik;
                  formikRef.current = formik;

                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    setFormValues(values);
                  }, [values]);

                  return (
                    <Form>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <CampaignBuilderCampaignStrategySection campaignBuilderValues={values} setFieldValue={setFieldValue} />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <ItemSettings values={values} setFieldValue={setFieldValue} profileId={profileId} />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <TargetingSettings
                            campaignMode={campaignMode}
                            setCampaignMode={setCampaignMode}
                            targetingMode={targetingMode}
                            setTargetingMode={setTargetingMode}
                          />
                        </Grid>
                      </Grid>
                      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                        Pick a Campaign Structure
                      </Typography>
                      {campaignMode === CampaignMode.MANUAL ? (
                        <>
                          <Grid container direction={'row'} justifyContent="space-between" spacing={2}>
                            {userSelectedManualCampaignTypeCardData.map((data, index) => (
                              <Grid item xs={4} key={index + '-manual-grid'}>
                                <RadioCard key={index} {...data} />
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      ) : campaignMode === CampaignMode.AUTO ? (
                        <>
                          <Grid container direction={'row'} justifyContent="flex-start" spacing={2}>
                            {userSelectedAutoCampaignTypeCardData.map((data, index) => (
                              <Grid item xs={4} key={index + '-auto-grid'}>
                                <RadioCard key={index} {...data} />
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      ) : null}
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <CampaignSettings
                            values={values}
                            touched={touched}
                            errors={errors}
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            dirty={dirty}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            userSelectedCampaignType={userSelectedCampaignType}
                          />
                        </Grid>
                      </Grid>
                      {campaignMode === CampaignMode.MANUAL && targetingMode === TargetingMode.KEYWORD && (
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item xs={12}>
                            <KeywordSettings
                              profileId={profileId}
                              itemStepType={itemStepType}
                              handleItemStepTypeChange={handleItemStepTypeChange}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {campaignMode === CampaignMode.MANUAL && targetingMode === TargetingMode.PRODUCT && (
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item xs={12}>
                            <ProductTargetingSettings
                              profileId={profileId}
                              values={values}
                              setFieldValue={setFieldValue}
                              productTargets={productTargets}
                              setProductTargets={setProductTargets}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {(targetingMode === TargetingMode.KEYWORD || campaignMode === CampaignMode.AUTO) && (
                        <Grid container sx={{ mt: 2 }}>
                          <Grid item xs={12}>
                            <NegativeKeywordSettings values={values} setFieldValue={setFieldValue} />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container sx={{ my: 6 }} justifyContent={'flex-end'}>
                        <Grid item>
                          <Tooltip title={parseErrors()}>
                            <span>
                              <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting || !dirty || !isValid || isMissingItemNickname(values)}
                                sx={{ mr: 2 }}
                              >
                                Create Campaign
                                {userSelectedCampaignType !== UserSelectedCampaignType.MULTIPLE_ITEMS_MULTIPLE_TARGETS ? 's' : ''}
                              </Button>
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
            <Grid item xs={12} xl={5} ref={parentRef}>
              <CampaignPreview
                values={formValues}
                campaignMode={campaignMode}
                isScreenSmall={isScreenSmall}
                parentWidth={parentWidth}
              />
            </Grid>
          </Grid>
        </Container>
      )
    },
    {
      key: ContentSteps.Error,
      content: (
        <Typography>
          <ErrorReview
            campaignBuilderResponse={campaignBuilderResponse}
            genericErrorText="Campaign creation failed due to the following errors. Please review and try again."
            handleNavigateToCampaigns={() => navigate(`/amazon/campaigns?profileId=${profileId}`)}
          />
        </Typography>
      )
    },
    {
      key: ContentSteps.Complete,
      content: (
        <>
          <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
            <Stack spacing={1} style={{ height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Campaigns Successfully Created</Typography>
                <CheckCircleIcon fontSize="large" color="success" sx={{ ml: 2 }} />
              </Box>
              <Box sx={{ height: '100%' }}>
                <DataGridPremium
                  columns={CAMPAIGN_COLUMNS}
                  rows={successfulCampaigns?.length > 0 ? successfulCampaigns : []}
                  getRowId={(row) => row.campaignId}
                  autoHeight
                  disableRowGrouping
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 2 }}>
                <Link
                  to={`${amazonRoutes.find((route) => route.key === 'amazon-campaigns')?.path}${
                    profileId ? `?profileId=${profileId}` : ''
                  }`}
                  component={RouterLink}
                  style={{ textDecoration: 'none' }}
                >
                  <Button variant="contained">Go to All Campaigns</Button>
                </Link>
              </Box>
            </Stack>
          </Container>
        </>
      )
    }
  ];

  return (
    <>
      <Box component="main" marginY={5}>
        {createCampaignContentSteps.find((content) => content.key === currentContentToShow)?.content}
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SponsoredProductsCampaignBuilder;
