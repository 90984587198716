import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { UpdateBidsConfig, UpdateTargetBidsDto } from '../../../../../shared/types/update-bids';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import UpdateBidsActionButton from '../../../../../shared/components/performance-grid/edit-bids-action-button';

interface UpdateTargetBidsProps {
  setIsLoading: (isLoading: boolean) => void;
  selectionModelHasRows: boolean;
}

const UpdateTargetBids = ({ setIsLoading, selectionModelHasRows }: UpdateTargetBidsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const apiRef = useGridApiContext();

  const { updateTargetBids } = useAmazonApi();

  const handleUpdateTargetBids = async (updateBidsConfig: UpdateBidsConfig) => {
    setIsDialogOpen(false);
    setIsLoading(true);

    const selectedTargets = Array.from(apiRef.current.getSelectedRows().values());

    const requestBody: UpdateTargetBidsDto = {
      ids: selectedTargets.map((target: any) => target.targetId),
      value: updateBidsConfig.value,
      bidUpdateOperator: updateBidsConfig.bidUpdateOperator,
      bidUpdateType: updateBidsConfig.bidUpdateType,
      ...(updateBidsConfig.shouldApplyMaxBid && { maxBid: updateBidsConfig.maxBid }),
      ...(updateBidsConfig.shouldApplyMinBid && { minBid: updateBidsConfig.minBid })
    };

    const response = await updateTargetBids(requestBody);

    if (response.success) {
      const updatedTargets = response.body;
      apiRef.current.updateRows(updatedTargets.map((target: any) => ({ targetId: target.targetId, bid: target.bid })));
    }

    apiRef.current.setRowSelectionModel([]);
    setIsLoading(false);
  };

  return (
    <UpdateBidsActionButton
      updateEntities={handleUpdateTargetBids}
      isDialogOpen={isDialogOpen}
      setIsDialogOpen={setIsDialogOpen}
      selectionModelHasRows={selectionModelHasRows}
    />
  );
};

export default UpdateTargetBids;
