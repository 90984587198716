import {
  GridColumnMenuContainer,
  GridColumnMenuFilterItem,
  GridColumnMenuProps,
  GridColumnMenuSortItem
} from '@mui/x-data-grid-premium';

export function CustomColumnMenuComponent(props: GridColumnMenuProps) {
  const { hideMenu, colDef, ...other } = props;

  return (
    <GridColumnMenuContainer hideMenu={hideMenu} colDef={colDef} {...other}>
      <GridColumnMenuSortItem onClick={hideMenu} colDef={colDef!} />
      <GridColumnMenuFilterItem onClick={hideMenu} colDef={colDef!} />
    </GridColumnMenuContainer>
  );
}
