import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DataGridPremium, useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import useAmazonApi from '../../../hooks/use-amazon-api';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useSearchParams } from 'react-router-dom';
import { OptimizeTargetBidsDto } from '../../../../shared/types/update-bids';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';

const BidOptimizerBulkActionButton = (props: any) => {
  const apiRef = useGridApiContext();
  const { optimizeTargetBids, optimizeTargetBidsPreview } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [optimizedPreviewTargets, setOptimizedPreviewTargets] = useState<any[]>([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [acosTarget, setAcosTarget] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowPreview, setShouldShowPreview] = useState(false);
  const [lookbackWindow, setLookbackWindow] = useState(7);
  const [requiredDaysOfData, setRequiredDaysOfData] = useState(7);

  const isInvalidAcosTarget = useMemo(() => !acosTarget || acosTarget < 0 || acosTarget > 100, [acosTarget]);

  const numberOfSelectedRows = apiRef.current.getSelectedRows()?.size || 0;
  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');

  const onClose = () => {
    setAcosTarget(0);
    setOptimizedPreviewTargets([]);
    setLookbackWindow(7);
    setRequiredDaysOfData(7);
    setShouldShowPreview(false);
    setIsDialogOpen(false);
  };

  const handlePreviewButtonClick = async () => {
    if (!profileId || isInvalidAcosTarget) {
      return;
    }

    setIsLoading(true);
    setShouldShowPreview(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);
    const requestBody: OptimizeTargetBidsDto = {
      campaignIds: selectedCampaignIds,
      acosTarget: acosTarget / 100,
      profileId: profileId,
      lookbackWindow: lookbackWindow,
      requiredDaysOfData: requiredDaysOfData
    };

    const response = await optimizeTargetBidsPreview(requestBody);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      setOptimizedPreviewTargets(response.body);
    }

    setIsLoading(false);
  };

  const handleSaveButtonClick = async () => {
    if (!profileId || isInvalidAcosTarget) {
      return;
    }

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);
    const requestBody: OptimizeTargetBidsDto = {
      campaignIds: selectedCampaignIds,
      acosTarget: acosTarget / 100,
      profileId: profileId,
      lookbackWindow: lookbackWindow,
      requiredDaysOfData: requiredDaysOfData
    };

    const response = await optimizeTargetBids(requestBody);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      enqueueSnackbar('Bid optimization successful', { variant: 'success' });
    }

    setOptimizedPreviewTargets([]);
    setAcosTarget(0);
    setIsDialogOpen(false);
  };

  const handleBackButtonClick = () => {
    setOptimizedPreviewTargets([]);
    setShouldShowPreview(false);
  };

  const renderDialogContent = () => {
    if (isLoading) {
      return renderLoadingView();
    }

    if (shouldShowPreview) {
      if (optimizedPreviewTargets.length === 0) {
        return renderNoOptimizedTargetsPreview();
      } else {
        return renderOptimizedtargetsPreviewGrid();
      }
    }

    return renderOptimizationSettingsInputForm();
  };

  const renderLoadingView = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </Box>
    );
  };

  const renderNoOptimizedTargetsPreview = () => {
    return (
      <Box>
        <Typography variant={'body2'} component={'div'} sx={{ mb: 2 }}>
          No targets found to optimize. Please try again with different settings or wait for more data to be collected.
        </Typography>
      </Box>
    );
  };

  const renderOptimizedtargetsPreviewGrid = () => {
    return (
      <>
        <Typography variant={'body2'} component={'div'} sx={{ mb: 2 }}>
          <strong>Preview</strong>
        </Typography>
        <Box height={'400px'}>
          <DataGridPremium
            rows={optimizedPreviewTargets}
            getRowId={(row) => row.targetId}
            slots={{
              noRowsOverlay: () => (
                <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                  <Typography variant={'body2'} component={'div'} sx={{ mb: 2 }}>
                    No targets found to optimize
                  </Typography>
                </Box>
              )
            }}
            columns={[
              { field: 'target', headerName: 'Target', width: 150 },
              { field: 'oldBid', headerName: 'Old Bid', width: 150 },
              { field: 'newBid', headerName: 'New Bid', width: 150 },
              { field: 'optimizationReason', headerName: 'Optimization Reason', width: 200 },
              {
                field: 'currentAcos',
                headerName: 'Current ACoS',
                width: 150,
                valueFormatter: (params) => `${Math.round(params.value * 100)}%`
              },
              { field: 'daysOfData', headerName: 'Days of Data', width: 150 }
            ]}
          />
        </Box>
      </>
    );
  };

  const renderOptimizationSettingsInputForm = () => {
    return (
      <>
        <Typography variant={'caption'} component={'div'} sx={{ mb: 2 }} color={'text.secondary'}>
          {numberOfSelectedRows} row{numberOfSelectedRows === 1 ? '' : 's'} selected
        </Typography>
        <Typography variant={'body2'} component={'div'}>
          <strong>Optimization Logic</strong>
        </Typography>
        <Typography variant={'body2'} component={'div'} sx={{ mb: 2 }}>
          Bid Optimizer will automatically adjust your bids to achieve your target ACoS. If the ACoS of a given target is 20%
          greater than your target ACoS over the last 7 days then the bid will be adjusted according to a revenue per click model.
          If the ACoS is 20% less than your target ACoS then the bid will be increased by 10%.
        </Typography>
        <Typography variant={'body2'} component={'div'} sx={{ mb: 2 }}>
          <strong>Example:</strong> If your target ACoS is 30% and your ACoS over the last 7 days is 36% then the bid will be set
          to revenue per click times your target ACoS, but will only be decreased at most by %50 of your current bid.
        </Typography>
        <Typography variant={'body2'} component={'div'} sx={{ mb: 2 }}>
          <strong>Example:</strong> If your target ACoS is 30% and your ACoS over the last 7 days is 24% then the bid will be
          increased by 10%.
        </Typography>
        <TextField
          type="number"
          sx={{ minWidth: '200px', mr: 2 }}
          InputProps={{ inputProps: { min: 0, max: 100, step: 1 } }}
          label="Target ACoS Percentage"
          value={acosTarget}
          error={acosTarget < 0 || acosTarget > 100}
          helperText={acosTarget < 0 || acosTarget > 100 ? 'Target ACoS must be between 0 and 100' : ''}
          onChange={(e) => setAcosTarget(parseInt(e.target.value))}
        />
        {/* Lookback Window is currently unused in the backend. We may add it back soon though. */}
        {/* <TextField
          type="number"
          sx={{ minWidth: '200px', mr: 2 }}
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          label={
            <Box display={'flex'} alignItems={'center'}>
              Lookback Window (Days)
              <Tooltip title="The number of days of data to include when calculating a target's performance.">
                <InfoIcon fontSize="small" color="primary" sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
          }
          value={lookbackWindow}
          error={lookbackWindow < 0}
          helperText={lookbackWindow < 0 ? 'Lookback Window must be a positive integer' : ''}
          onChange={(e) => setLookbackWindow(parseInt(e.target.value))}
        /> */}
        <TextField
          type="number"
          sx={{ minWidth: '200px' }}
          InputProps={{ inputProps: { min: 0, step: 1 } }}
          label={
            <Box display={'flex'} alignItems={'center'}>
              Required Days of Data
              <Tooltip title="The number of days a target must have data for in order to be optimized.">
                <InfoIcon fontSize="small" color="primary" sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
          }
          value={requiredDaysOfData}
          error={requiredDaysOfData < 0}
          helperText={requiredDaysOfData < 0 ? 'Required Days of Data must be a positive integer' : ''}
          onChange={(e) => setRequiredDaysOfData(parseInt(e.target.value))}
        />
      </>
    );
  };

  return (
    <>
      <Tooltip title="Optimize Bids">
        <span>
          <IconButton
            sx={{ borderRadius: '100px' }}
            color="primary"
            size="large"
            onClick={() => setIsDialogOpen(true)}
            disabled={!props.selectionModelHasRows}
          >
            <AutoFixHighIcon />
          </IconButton>
        </span>
      </Tooltip>
      {isDialogOpen ? (
        <Dialog open={isDialogOpen} onClose={onClose} fullWidth maxWidth="md">
          <DialogTitle>Bid Optimizer</DialogTitle>
          <DialogContent>{renderDialogContent()}</DialogContent>
          <DialogActions sx={{ mt: 4 }}>
            <Button onClick={onClose}>Cancel</Button>
            {shouldShowPreview ? (
              <>
                <Button variant="contained" onClick={handleBackButtonClick}>
                  <ArrowBackIcon />
                  Back
                </Button>
                <Button variant="contained" onClick={handleSaveButtonClick} disabled={optimizedPreviewTargets.length === 0}>
                  Optimize Bids
                </Button>
              </>
            ) : (
              <Tooltip arrow placement="top" title={isInvalidAcosTarget ? 'Please add a target ACoS percentage to preview' : ''}>
                <Box>
                  <Button disabled={isInvalidAcosTarget} variant="contained" onClick={handlePreviewButtonClick}>
                    Preview
                  </Button>
                </Box>
              </Tooltip>
            )}
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default BidOptimizerBulkActionButton;
