import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const DaypartingLegend: FC = () => {
  return (
    <Box display="flex" sx={{ mt: 2 }} flexDirection={'row'}>
      <Box display="flex" alignItems="center">
        <Box sx={{ mr: 1, width: 20, height: 20, bgcolor: 'primary.main' }} />
        <Typography variant="body2" sx={{ mr: 2, width: '50px' }}>
          Enabled
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ mr: 1, width: 20, height: 20, bgcolor: 'gray' }} />
        <Typography variant="body2" sx={{ mr: 2 }}>
          Paused by Dayparting
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ width: 7, height: 20, bgcolor: 'warning.light' }} />
        <Box sx={{ width: 7, height: 20, bgcolor: 'warning.main' }} />
        <Box sx={{ mr: 1, width: 7, height: 20, bgcolor: 'warning.dark' }} />

        <Typography variant="body2" sx={{ mr: 2 }}>
          Decreased Bid
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ mr: 1, width: 20, height: 20, border: '2px solid', borderColor: 'error.light' }} />
        <Typography variant="body2" sx={{ width: '200px' }}>
          Out Of Budget (OOB) Hour
        </Typography>
      </Box>
    </Box>
  );
};
