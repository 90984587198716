import { GridColDef } from '@mui/x-data-grid-premium';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { MetricColumnField } from '../../../../walmart/components/grid-components/column-configurations/metric-columns';
import { renderLastMetricsDate } from '../../../../shared/rendering/renderLastMetricsDate';

export const METRIC_COLUMNS: GridColDef[] = [
  {
    field: MetricColumnField.LastMetricsDate,
    headerName: 'Data Through',
    description:
      'The latest date with ads data. Data as of three days ago is retrieved daily at 8am and 12pm CST. Note: There may be a 1-2 hours delay before data reaches Vector.',
    width: 100,
    minWidth: 100,
    headerAlign: 'left',
    align: 'right',
    type: 'string',
    valueFormatter: ({ value }) => (value ? new Date(value) : value),
    renderCell: (params) => renderLastMetricsDate(params, 4)
  },
  {
    field: 'cost',
    headerName: 'Spend',
    width: 100,
    minWidth: 100,
    headerAlign: 'left',
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'attributedRevenue',
    headerName: 'Attr. Rev.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'returnOnAdSpend',
    headerName: 'RoAS',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'impressions',
    headerName: 'Imp.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: 'clickThroughRate',
    headerName: 'CTR',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? `${(value * 100).toFixed(2)}%` : value)
  },
  {
    field: 'units',
    headerName: 'Units',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: 'orders',
    headerName: 'Orders',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: 'newToBrandOrders',
    headerName: 'NTB Orders',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: 'newToBrandRevenue',
    headerName: 'NTB Revenue',
    width: 110,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'newToBrandUnits',
    headerName: 'NTB Units',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? value.toLocaleString('en-US') : value)
  },
  {
    field: MetricColumnField.PercentNewToBrandUnits,
    headerName: '% NTB Units',
    width: 110,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: MetricColumnField.PercentNewToBrandOrders,
    headerName: '% NTB Orders',
    width: 110,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: MetricColumnField.PercentNewToBrandRevenue,
    headerName: '% NTB Revenue',
    width: 110,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: 'conversionRate',
    headerName: 'Conv. Rate',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? `${(value * 100).toFixed(2)}%` : value)
  },
  {
    field: 'costPerClick',
    headerName: 'CPC',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'costPerConversion',
    headerName: 'Cost/Conv.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  }
];
