import ProductTargetCell from './product-target-cell';
import { AmazonExpressionTypes } from '../../../../../types/targeting-refinements';
import CategoryTargetCell from './category-target-cell';
import KeywordTargetCell from './keyword-target-cell';
import { TargetType } from '../../../../../types/target';
import { caseInsensitiveEquals } from '../../../../../../shared/utilities/string-utilities';
import AutoTargetCell from './auto-target-cell';
import { resolve } from 'path';
import { Typography } from '@mui/material';
import React from 'react';
import ContextualTargetCell from './contextual-target-cell';
import AudienceTargetCell from './audience-target-cell';
import { getDateRangePickerToolbarUtilityClass } from '@mui/x-date-pickers-pro';

interface Expression {
  type: string;
  value: string;
}

interface TargetTextProps {
  params: any;
  itemDetails: any;
  isLoadingItemDetails: boolean;
}

const getAsinFromExpression = (targetType: any, resolvedExpression: any) => {
  if (caseInsensitiveEquals(targetType, TargetType.Category)) {
    return resolvedExpression.find((e: Expression) => caseInsensitiveEquals(e.type, AmazonExpressionTypes.asinSameAs))?.value;
  } else if (caseInsensitiveEquals(targetType, TargetType.ProductExact)) {
    return resolvedExpression.find((e: Expression) => caseInsensitiveEquals(e.type, AmazonExpressionTypes.asinSameAs))?.value;
  } else if (caseInsensitiveEquals(targetType, TargetType.ProductExpanded)) {
    return resolvedExpression.find(
      (e: Expression) => e.type.toLowerCase() === AmazonExpressionTypes.asinExpandedFrom.toLowerCase()
    )?.value;
  }
};

export const TargetCell = (props: TargetTextProps) => {
  const { itemDetails, isLoadingItemDetails } = props;
  const { keywordText, resolvedExpression, targetType } = props.params.row;

  if (!keywordText && !resolvedExpression) {
    return null;
  }

  if (caseInsensitiveEquals(targetType, TargetType.Keyword)) {
    return <KeywordTargetCell keywordText={keywordText} />;
  }

  if (caseInsensitiveEquals(targetType, TargetType.Category)) {
    return <CategoryTargetCell expression={resolvedExpression} />;
  }

  if (caseInsensitiveEquals(targetType, TargetType.ProductExact)) {
    return (
      <ProductTargetCell
        title="Product"
        asin={getAsinFromExpression(targetType, resolvedExpression)}
        itemDetails={itemDetails}
        isLoadingItemDetails={isLoadingItemDetails}
      />
    );
  }

  if (caseInsensitiveEquals(targetType, TargetType.ProductExpanded)) {
    return (
      <ProductTargetCell
        title="Products Similar To"
        itemDetails={itemDetails}
        isLoadingItemDetails={isLoadingItemDetails}
        asin={getAsinFromExpression(targetType, resolvedExpression)}
      />
    );
  }

  if (caseInsensitiveEquals(targetType, TargetType.Auto)) {
    return <AutoTargetCell expression={resolvedExpression} />;
  }

  if (caseInsensitiveEquals(targetType, TargetType.Contextual)) {
    return <ContextualTargetCell expression={resolvedExpression} />;
  }

  if (caseInsensitiveEquals(targetType, TargetType.Audience)) {
    return <AudienceTargetCell expression={resolvedExpression} />;
  }
};
