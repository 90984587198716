import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import AddNegativeKeywords from '../../../components/add-negative-keywords';

interface AddNegativeKeywordDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (keywords: any) => void;
}

export const AddNegativeKeywordsDialog: FC<AddNegativeKeywordDialogProps> = (props) => {
  const { isOpen, handleClose, handleSave } = props;
  const [negativeKeywords, setNegativeKeywords] = useState<any>([]);

  return (
    <Dialog open={isOpen} maxWidth={'lg'} onClose={handleClose}>
      <DialogTitle>Add Negative Keywords</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ maxWidth: '800px' }}>
          Enter negative keywords. Once finished click confirm and apply to add the negative keywords to your selected items.
        </DialogContentText>
        <AddNegativeKeywords negativeKeywords={negativeKeywords} setNegativeKeywords={setNegativeKeywords} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleSave(negativeKeywords)} disabled={negativeKeywords.length < 1}>
          Confirm and apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
