import { Card, Link, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AmazonTargetMatchType } from '../types/target';

type Keyword = any;

interface SuggestedKeywordsProps {
  keywords: Keyword[];
  selectedMatchTypes: string[];
  handleAddKeyword: (keyword: Keyword) => void;
  bidType: 'suggested' | 'custom';
}

function renderBidLink(keyword: any, matchType: string, handleAddKeyword: any, bidType: 'suggested' | 'custom') {
  const bidInfoByMatchType = keyword?.bidRecommendations?.find(
    (bidInfo: any) => bidInfo?.matchType?.toLowerCase() === matchType.toLowerCase()
  );
  const bid = bidInfoByMatchType?.recommended;

  const keywordToAdd = {
    keywordText: keyword?.keywordText,
    matchType: matchType,
    bid: bid
  };

  const shouldDisable = () => {
    if (bidType === 'suggested') {
      return !bid;
    } else {
      return false;
    }
  };

  return (
    <Box display="flex" alignContent={'center'}>
      <Typography sx={{ fontSize: '12px', minWidth: '80px', mr: 2 }}>
        {matchType}: {bid ? `$${bid}` : '-'}
      </Typography>
      <Link
        sx={{
          fontSize: '12px',
          cursor: 'pointer',
          color: shouldDisable() ? 'text.disabled' : 'primary.main'
        }}
        underline={shouldDisable() ? 'none' : 'hover'}
        onClick={() => handleAddKeyword(keywordToAdd)}
        component={'button'}
        type="button"
        disabled={shouldDisable()}
      >
        Add
      </Link>
    </Box>
  );
}

function renderRow(
  { index, style }: ListChildComponentProps,
  keywords: Keyword[],
  selectedMatchTypes: string[],
  handleAddKeyword: any,
  bidType: 'suggested' | 'custom'
) {
  const keyword = keywords[index];

  return (
    <>
      <ListItem style={style} key={keyword.keywordText} divider>
        <Stack>
          <Tooltip title={keyword.keywordText}>
            <Typography
              sx={{
                width: '200px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                fontWeight: 'bold'
              }}
            >
              {keyword.keywordText}
            </Typography>
          </Tooltip>
          <Box display={'flex'}>
            <Typography sx={{ fontSize: '12px', mr: 1, color: 'text.secondary' }}>
              IS:{keyword?.searchTermImpressionShare?.toFixed(2) ?? 0}
            </Typography>
            {keyword?.searchTermImpressionRank && (
              <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>IR:{keyword?.searchTermImpressionRank}</Typography>
            )}
          </Box>
        </Stack>
        <Stack sx={{ width: '80px' }}>
          {selectedMatchTypes.includes(AmazonTargetMatchType.Exact) && (
            <>{renderBidLink(keyword, AmazonTargetMatchType.Exact, handleAddKeyword, bidType)}</>
          )}
          {selectedMatchTypes.includes(AmazonTargetMatchType.Broad) && (
            <>{renderBidLink(keyword, AmazonTargetMatchType.Broad, handleAddKeyword, bidType)}</>
          )}
          {selectedMatchTypes.includes(AmazonTargetMatchType.Phrase) && (
            <>{renderBidLink(keyword, AmazonTargetMatchType.Phrase, handleAddKeyword, bidType)}</>
          )}
        </Stack>
      </ListItem>
    </>
  );
}

export default function SuggestedKeywords(props: SuggestedKeywordsProps) {
  const { keywords, selectedMatchTypes, handleAddKeyword, bidType } = props;

  return (
    <Card sx={{ width: '100%', height: 380 }} variant="outlined">
      <FixedSizeList height={380} width="100%" itemSize={70} itemCount={keywords.length}>
        {(props) => renderRow(props, keywords, selectedMatchTypes, handleAddKeyword, bidType)}
      </FixedSizeList>
    </Card>
  );
}
