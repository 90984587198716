import { Box, Button, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Tooltip, Typography } from '@mui/material';
import { Fragment } from 'react';
import { MatchType } from '../../../../types/campaign-builder-request';
import { getAsinCountText } from './targeting-utils';

type AddedTargetListItemProps = {
  target: any;
  removeTarget: () => void;
  targetType: string;
};

export const AddedTargetListItem = (props: AddedTargetListItemProps) => {
  const { target, removeTarget, targetType } = props;

  if (targetType === 'product') {
    return (
      <ListItem key={`${target.recommendedAsin}${target.matchType}`}>
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography variant="body2" component={'div'} sx={{ mr: '0.5em' }}>
                <strong>{target.recommendedAsin}</strong>{' '}
              </Typography>
              <Typography variant="caption">{target.matchType}</Typography>
            </Box>
          }
          secondary={
            <Box>
              {target.themes.map((theme: any) => (
                <Fragment key={theme}>
                  <Typography component="span" variant="caption" color="textPrimary">
                    {theme}
                  </Typography>
                  <br />
                </Fragment>
              ))}
              <Box>
                <Typography variant="body2">Bid: ${target?.bid}</Typography>
              </Box>
            </Box>
          }
        />
        <ListItemSecondaryAction>
          <Button onClick={removeTarget}>Remove</Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  return (
    <ListItem key={`$${target.name}`}>
      <ListItemText
        primary={
          <Tooltip title={target.categoryPath}>
            <Typography variant="body2" noWrap>
              {target.categoryPath}
            </Typography>
          </Tooltip>
        }
        secondary={
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              <strong>Category: </strong>
              {target.name}
            </Typography>
            <br />
            {!!target.refinements && target.refinements?.length > 0 ? (
              <>
                <Typography component="span" variant="body2" color="textPrimary">
                  <Tooltip
                    title={
                      <Fragment>
                        {target.refinements.map((refinement: any, index: number) => (
                          <Fragment key={index}>
                            <Typography component="span" variant="caption" color="primary.text">
                              {`${refinement.type}: ${refinement.value}`}
                            </Typography>
                            <br />
                          </Fragment>
                        ))}
                      </Fragment>
                    }
                  >
                    <strong>{target.refinements.length} refinements</strong>
                  </Tooltip>
                </Typography>
              </>
            ) : (
              <Typography component="span" variant="body2" color="textSecondary">
                {target.asinCountText}
              </Typography>
            )}
            <Box>
              <Typography variant="body2">Bid: ${target?.bid}</Typography>
            </Box>
          </>
        }
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', ml: 2 }}>
        <Button onClick={removeTarget}>Remove</Button>
      </Box>
    </ListItem>
  );
};
