import { forwardRef } from 'react';
import { Autocomplete, Box, Card, Radio, TextField, Typography, SxProps, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

interface ProfileAutoCompleteProps {
  profiles: any[];
  handleProfileChange: (event: any, value: any) => void;
  profileId: number | null;
}

export const ProfileAutocomplete = (props: ProfileAutoCompleteProps) => {
  const { profiles, handleProfileChange, profileId } = props;

  return (
    <Autocomplete
      disablePortal
      options={profiles}
      getOptionLabel={(profile) => profile.name}
      onChange={handleProfileChange}
      value={profiles.find((profile: { profileId: any }) => profile.profileId === profileId) || null}
      sx={{ width: 300, mb: 2 }}
      renderInput={(params) => <TextField {...params} size="small" label="Profile" />}
    />
  );
};

interface FormikInputProps {
  formik: any;
}

export const CampaignNameTextField = (props: any) => {
  const { formik } = props;

  return (
    <TextField
      error={Boolean(formik.touched.name && formik.errors.name)}
      fullWidth
      helperText={formik.touched.name && formik.errors.name}
      label="Campaign Name"
      name="name"
      size="small"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.name}
    />
  );
};

const StartDatePicker = (props: FormikInputProps) => {
  const { formik } = props;
  const startDateError = Boolean(formik.touched.startDate && formik.errors.startDate);

  return (
    <DatePicker
      label="Start Date"
      value={formik.values.startDate}
      componentsProps={{
        actionBar: { actions: ['clear'] }
      }}
      onChange={(date) => {
        formik.setFieldValue('startDate', date);
      }}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              onBlur={formik.handleBlur}
              error={startDateError}
              name="startDate"
              size="small"
              sx={{ mr: 2 }}
              helperText={(formik.touched.startDate && formik.errors.startDate) || ' '}
            />
          </>
        );
      }}
    />
  );
};

const EndDatePicker = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <DatePicker
      label="End Date"
      value={formik.values.endDate}
      componentsProps={{
        actionBar: { actions: ['clear'] }
      }}
      onChange={(date) => {
        formik.setFieldValue('endDate', date);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onBlur={formik.handleBlur}
          helperText={(formik.touched.endDate && formik.errors.endDate) || ' '}
          name="endDate"
          size="small"
          error={Boolean(formik.touched.endDate && formik.errors.endDate)}
        />
      )}
    />
  );
};

export const DateRangeSettings = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StartDatePicker formik={formik} />
      </LocalizationProvider>
      <Box sx={{ ml: 0 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <EndDatePicker formik={formik} />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

interface CustomTypeOptionRadioButtonProps {
  checked?: boolean;
  handleClick: () => void;
  typeOptionValue: string;
  typeOptionLabel: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const CustomTypeOptionRadioButton = forwardRef(function CustomTypeOptionRadioButton(
  props: CustomTypeOptionRadioButtonProps,
  ref
) {
  const { checked, handleClick, typeOptionValue, typeOptionLabel, disabled, sx, ...other } = props;
  return (
    <Box {...other} ref={ref}>
      <Card
        sx={[
          {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            px: 1,
            pr: 2,
            ...(checked && {
              borderColor: 'primary.main',
              borderWidth: 2,
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
              m: '-1px'
            }),
            ...(disabled && {
              borderColor: 'grey',
              borderWidth: 2,
              backgroundColor: 'grey',
              m: '-1px',
              cursor: 'not-allowed'
            })
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        onClick={disabled ? undefined : handleClick}
        variant="outlined"
      >
        <Radio checked={checked} color="primary" disabled={disabled} />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle1">{typeOptionLabel}</Typography>
        </Box>
      </Card>
    </Box>
  );
});
