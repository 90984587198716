import { CircularProgress, Switch } from '@mui/material';
import { GridRowParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import useAdsApi from '../../../../../shared/hooks/use-walmart-sams-club-api';
import { buildUpdateAdRequest } from '../../../../types/ad';
import { useSnackbar } from 'notistack';

export function ToggleAction(params: GridRowParams) {
  const { id, row } = params;

  const { updateAd } = useAdsApi();
  const apiRef = useGridApiContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (event: any) => {
    setIsLoading(true);

    const newStatus = event.target.checked ? 'Enabled' : 'Disabled';

    const updateAdRequest = buildUpdateAdRequest({
      ...row,
      status: newStatus
    });

    const response = await updateAd(params.row.profileId, updateAdRequest);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return;
    }

    apiRef.current.updateRows([{ adItemId: id, status: newStatus }]);

    setIsLoading(false);
  };

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Switch
      key={`${id}-active-check`}
      checked={params.row.status.toLowerCase() === 'enabled'}
      color="primary"
      size="small"
      onChange={handleChange}
    />
  );
}

export function renderAdActions(params: GridRowParams) {
  return [<ToggleAction {...params} />];
}
