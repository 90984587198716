import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const HourLabels: React.FC = () => (
  <Box display={'flex'} ml={'43px'}>
    {Array.from(Array(24).keys()).map((hour) => (
      <Box key={hour}>
        <Box height="32px" width="32px">
          <Typography sx={{ fontSize: '12px' }}>{String(hour).padStart(2, '0')}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
      </Box>
    ))}
  </Box>
);

export default HourLabels;
