import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { NegativeKeywordMatchType } from '../../../types/campaign-builder-request';
import AddedNegativeKeywordsTable from './added-negative-keywords';
import { formatOption } from '../../../types/negative-keyword';

interface NegativeKeywordsProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export default function AddNegativeKeywords(props: NegativeKeywordsProps) {
  const { values, setFieldValue } = props;

  const [userEnteredKeywords, setUserEnteredKeywords] = useState('');
  const [matchTypes, setMatchTypes] = useState<NegativeKeywordMatchType[]>([NegativeKeywordMatchType.Exact]);

  const handleAddNegativeKeywords = async () => {
    const parsedKeywords = userEnteredKeywords
      .split('\n')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)
      .map((keyword) => keyword.toLowerCase());

    const objectifiedKeywords = matchTypes.flatMap((matchType) =>
      parsedKeywords.map((keyword: string) => ({
        keywordText: keyword,
        matchType: matchType,
        id: `${keyword}-${matchType}`
      }))
    );

    const newKeywords = objectifiedKeywords.filter(
      (keyword: any) =>
        !values.negativeKeywords.some(
          (objKeyword: any) => objKeyword.keywordText === keyword.keywordText && objKeyword.matchType === keyword.matchType
        )
    );

    setUserEnteredKeywords('');
    setFieldValue('negativeKeywords', [...values.negativeKeywords, ...newKeywords]);
  };

  const handleMatchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (matchTypes.includes(event.target.value as NegativeKeywordMatchType)) {
      setMatchTypes(matchTypes.filter((matchType) => matchType !== event.target.value));
    } else {
      setMatchTypes([...matchTypes, event.target.value as NegativeKeywordMatchType]);
    }
  };

  return (
    <Grid container direction="column" mt={2}>
      <Stack mb={2}>
        <Box display={'flex'}>
          {Object.values(NegativeKeywordMatchType).map((option) => (
            <FormControlLabel
              key={option}
              control={<Checkbox checked={matchTypes.includes(option)} onChange={handleMatchTypeChange} value={option} />}
              label={formatOption(option)}
            />
          ))}
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button variant="outlined" onClick={() => setFieldValue('negativeKeywords', [])}>
            Remove All Negative Keywords
          </Button>
        </Box>
      </Stack>
      <Grid item>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Grid item xs={4}>
            <TextField
              id="campaignItemIds"
              label="Negative Keywords"
              onChange={(event) => {
                setUserEnteredKeywords(event.target.value);
              }}
              placeholder="Enter one keyword per line"
              inputProps={{
                style: { fontSize: '14px' }
              }}
              multiline
              value={userEnteredKeywords}
              sx={{ mt: 1, width: '100%' }}
              rows={15}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5, mx: 0.5 }}
                variant="outlined"
                size="small"
                aria-label="move all right"
                onClick={handleAddNegativeKeywords}
              >
                <Typography variant="h6" fontWeight={'bold'}>
                  ≫
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6} md={7}>
            <AddedNegativeKeywordsTable
              keywords={values.negativeKeywords}
              setKeywords={(negativeKeywords: any) => setFieldValue('negativeKeywords', negativeKeywords)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
