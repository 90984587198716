import { Edit as EditIcon } from '@mui/icons-material';
import { Box, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { CurrencyInput } from '../../../../shared/components/inputs/currency-input';
import InlineInput from '../../../../shared/pages/campaigns/campaign-overview/inline-input';
import { formatAsCurrency } from '../../../../shared/utilities/numeric-format-utilities';
import { CampaignType } from '../../../components/grid-components/renderer/renderCampaignType';
import { StateChip } from '../../../components/grid-components/renderer/renderState';
import { Campaign as AmazonCampaign, BiddingStrategies } from '../../../types/campaign';
import { DEFAULT_STATE_OPTIONS } from '../../../types/state-options';
import CampaignOverviewDateRangePicker from '../../../../shared/components/grid-components/date-picker/campaign-overview-date-picker';
import { useSnackbar } from 'notistack';

interface CampaignDetailsProps {
  campaign: AmazonCampaign;
  updateCampaign: (updatedCampaign: any) => Promise<void>;
  setCampaign: (campaign: any) => void;
  openBidStrategyDialog: (open: boolean) => void;
}

export const CampaignDetails = (props: CampaignDetailsProps) => {
  const { campaign, updateCampaign, openBidStrategyDialog } = props;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isBidStrategyHovered, setIsBidStrategyHovered] = useState(false);

  const handleSaveInlineInput = (paramToUpdate: string) => (newValue: any) => {
    const updatedCampaign = { campaignId: campaign.campaignId, [paramToUpdate]: newValue };
    updateCampaign(updatedCampaign);
  };

  const handleClickStateChip = () => {
    const currentIndex = DEFAULT_STATE_OPTIONS.findIndex((option) => option.value === campaign.state);
    const nextIndex = (currentIndex + 1) % DEFAULT_STATE_OPTIONS.length;
    const newState = DEFAULT_STATE_OPTIONS[nextIndex];

    if (newState && 'value' in newState) {
      handleSaveInlineInput('state')(newState);
    } else {
      enqueueSnackbar(`Invalid newState ${newState}`, { variant: 'error' });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: '0.5rem',
        padding: '1rem'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          {campaign?.campaignType && (
            <Box>
              <CampaignType campaignType={campaign?.campaignType} chipSize={'small'} />
            </Box>
          )}
          <StateChip state={campaign?.state} stateOptions={DEFAULT_STATE_OPTIONS} size="small" onClick={handleClickStateChip} />{' '}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: '1rem' }}>
          <InlineInput
            value={campaign.name}
            onSave={handleSaveInlineInput('name')}
            labelComponentProps={{
              variant: 'h5'
            }}
            EditModeComponent={TextField}
            EditModeComponentProps={{
              size: 'small'
            }}
          />
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Box sx={{ borderRadius: '1rem', border: '1px solid', borderColor: theme.palette.divider, padding: '10px' }}>
              <InlineInput
                value={formatAsCurrency(Number(campaign.dailyBudget))}
                onSave={handleSaveInlineInput('dailyBudget')}
                label="Daily Budget"
                EditModeComponent={CurrencyInput}
                EditModeComponentProps={{
                  size: 'small'
                }}
              />
            </Box>
            {campaign.amazonSponsoredProductsDynamicBidding && (
              <Box
                display="flex"
                alignItems="center"
                onMouseEnter={() => setIsBidStrategyHovered(true)}
                onMouseLeave={() => setIsBidStrategyHovered(false)}
                sx={{ borderRadius: '1rem', border: '1px solid', borderColor: theme.palette.divider, padding: '10px' }}
              >
                <Typography>
                  <strong>Bid Strategy:</strong> <br />
                  {BiddingStrategies[campaign.amazonSponsoredProductsDynamicBidding.strategy].label}
                </Typography>
                <IconButton sx={{ opacity: isBidStrategyHovered ? 1 : 0 }} onClick={() => openBidStrategyDialog(true)}>
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignContent="right">
          <CampaignOverviewDateRangePicker campaign={campaign} handleSaveInlineInput={handleSaveInlineInput} />
        </Box>
      </Box>
    </Box>
  );
};
