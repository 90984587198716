import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Tab,
  Tabs
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import React from 'react';
import {
  blankDaypartingSchedule,
  DaypartingSchedule,
  DaypartingScheduleState,
  formatApiDataForDaypartingUI,
  formatDaypartingDataForApi
} from './day-parting-types';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import { MetricsTiles } from './metrics-tiles';
import { OutOfBudgetButton } from '../../../shared/components/day-parting/out-of-budget-button';
import { DaypartingUpdatedRecentlyAlert } from './dayparting-updated-recently-alert';
import HourlySchedulesContainer from './hourly-schedules-container';
import { HourlyStatsChart } from './hourly-stats-chart';

interface DaypartingProps {
  profileId: number;
  campaignId: number;
  campaignName: string;
  isOpen: boolean;
  toggle: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabIndex: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabIndex, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabIndex === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function Dayparting(props: DaypartingProps) {
  const { profileId, campaignId, campaignName, isOpen, toggle } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const { getHourlySchedule, updateHourlySchedule, createHourlySchedule, deleteHourlySchedule, getDaypartingViewStats } =
    useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [daySchedules, setDaySchedules] = useState<DaypartingSchedule>({ ...blankDaypartingSchedule });
  const [stats, setStats] = useState<any>([]);
  const [monthlyBudget, setMonthlyBudget] = useState<number>(0);
  const [currentMonthSpend, setCurrentMonthSpend] = useState<number>(0);
  const [shouldShowOutOfBudgetHour, setShouldShowOutOfBudgetHour] = useState<boolean>(true);

  const [campaignHasDayparting, setCampaignHasDayparting] = useState<boolean>(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<Date | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const initialize = async () => {
    setIsLoading(true);

    await initializeViewStats();
    await initializeHourlySchedule();

    setIsLoading(false);
  };

  const initializeViewStats = async () => {
    const daypartingViewDataResponse = await getDaypartingViewStats(campaignId);

    if (daypartingViewDataResponse.success) {
      const viewDataDto = daypartingViewDataResponse.body;

      const statsWithParsedDate = sanitizeRealtimeStats(viewDataDto);
      setStats(statsWithParsedDate);
      setMonthlyBudget(viewDataDto?.currentMonthBudget ?? 0);
      setCurrentMonthSpend(viewDataDto?.currentMonthSpend ?? 0);
    } else {
      enqueueSnackbar('An error occurred loading the campaign stats', { variant: 'error' });
    }
  };

  const initializeHourlySchedule = async () => {
    const response = await getHourlySchedule(profileId, campaignId);

    if (response?.success) {
      if (Object.keys(response.body.schedule).length > 0) {
        setDaySchedules(formatApiDataForDaypartingUI(response.body.schedule));
        setCampaignHasDayparting(true);

        if (response?.body?.lastUpdated) {
          updateLastUpdated(response);
        }
      }
    } else {
      enqueueSnackbar('An error occurred loading the dayparting schedule', { variant: 'error' });
    }
  };

  function updateLastUpdated(response: ResponseObject) {
    let date = new Date(response.body.lastUpdated);

    if (date.getFullYear() < 2020) {
      setLastUpdatedDate(null);
    } else {
      setLastUpdatedDate(date);
    }
  }

  function sanitizeRealtimeStats(viewDataDto: any) {
    return viewDataDto.last7DaysRealtimeStats.map((stat: any) => {
      return {
        ...stat,
        dailyOutOfBudgetDateTime: !!stat.dailyOutOfBudgetDateTime ? new Date(stat.dailyOutOfBudgetDateTime) : null,
        asOf: new Date(stat.asOf)
      };
    });
  }

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteSchedule = async () => {
    setIsLoading(true);

    const response = await deleteHourlySchedule(profileId, campaignId);

    if (response.success) {
      enqueueSnackbar('Dayparting schedule deleted', { variant: 'success' });
      setCampaignHasDayparting(false);
    } else {
      enqueueSnackbar('An error occurred deleting the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const updateSchedule = async () => {
    setIsLoading(true);

    const response = await updateHourlySchedule(profileId, campaignId, formatDaypartingDataForApi(daySchedules));

    if (response.success) {
      enqueueSnackbar('Dayparting schedule updated', { variant: 'success' });
    } else {
      enqueueSnackbar('An error occurred updating the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const createSchedule = async () => {
    setIsLoading(true);

    const response = await createHourlySchedule(profileId, campaignId, formatDaypartingDataForApi(daySchedules));

    if (response.success) {
      enqueueSnackbar('Dayparting schedule created', { variant: 'success' });
    } else {
      enqueueSnackbar('An error occurred creating the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const saveSchedule = async () => {
    if (allHoursEnabled()) {
      deleteSchedule();
      return;
    }

    if (campaignHasDayparting) {
      await updateSchedule();
      return;
    }

    await createSchedule();
  };

  const allHoursEnabled = () => {
    return Object.values(daySchedules).every((day) => day.State === DaypartingScheduleState.Enabled);
  };

  return (
    <>
      <Dialog onClose={toggle} maxWidth={'xl'} open={isOpen} onMouseUp={() => setIsDragging(false)}>
        <DialogTitle>
          <Box display="flex" alignItems={'center'}>
            <Typography variant={'h6'}>Dayparting Schedule ({campaignName})</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mr: 2 }}>
          <DialogContentText sx={{ mb: 2 }}>
            <Box display="flex" justifyContent={'space-between'}>
              <Typography variant={'body2'}>All hours will be interpreted as CST.</Typography>
              {lastUpdatedDate && tabIndex === 0 && (
                <Typography variant={'body2'}>
                  Schedule Last Updated:{' '}
                  {lastUpdatedDate?.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
                </Typography>
              )}
            </Box>
          </DialogContentText>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Tabs value={tabIndex} onChange={(event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue)}>
                <Tab label="Dayparting Schedule" />
                <Tab label="Hourly Metrics" />
              </Tabs>
            </Grid>
            {tabIndex === 0 && (
              <OutOfBudgetButton
                shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour}
                setShouldShowOutOfBudgetHour={setShouldShowOutOfBudgetHour}
              />
            )}
          </Grid>
          {lastUpdatedDate && <DaypartingUpdatedRecentlyAlert lastUpdated={lastUpdatedDate} />}
          <MetricsTiles isLoading={isLoading} currentMonthSpend={currentMonthSpend} monthlyBudget={monthlyBudget} stats={stats} />
          <TabPanel tabIndex={tabIndex} index={0}>
            <HourlySchedulesContainer
              daySchedules={daySchedules}
              setDaySchedules={setDaySchedules}
              isDragging={isDragging}
              shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour}
              isLoading={isLoading}
              setIsDragging={setIsDragging}
              stats={stats}
            />
          </TabPanel>
          <TabPanel tabIndex={tabIndex} index={1}>
            <HourlyStatsChart campaignId={campaignId} />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={toggle}>
            Cancel
          </Button>
          <Button variant={'contained'} onClick={saveSchedule}>
            Save Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
