import { Autocomplete, Box, Card, CardContent, TextField, Typography } from '@mui/material';
import { CampaignStrategyOptions, CampaignStrategyType } from '../../../types/campaign';

interface CampaignStrategyProps {
  campaignBuilderValues: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const CampaignBuilderCampaignStrategySection = (props: CampaignStrategyProps) => {
  const { campaignBuilderValues, setFieldValue } = props;

  const options: CampaignStrategyType[] = [
    CampaignStrategyOptions.Branded.value,
    CampaignStrategyOptions.Nonbranded.value,
    CampaignStrategyOptions.Discovery.value,
    CampaignStrategyOptions.Conquest.value
  ];

  const handleCampaignStrategyChange = (event: any, newValue: CampaignStrategyType) => {
    setFieldValue('campaignStrategy', newValue);
  };

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Select a Campaign Strategy
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Autocomplete
            disablePortal
            disableClearable
            autoHighlight
            options={options}
            getOptionLabel={(option: CampaignStrategyType) => CampaignStrategyOptions[option].label}
            value={campaignBuilderValues.campaignStrategy}
            sx={{ width: 300, mb: 2 }}
            renderInput={(params) => <TextField {...params} size="small" label="Campaign Strategy" />}
            onChange={handleCampaignStrategyChange}
          />
        </Box>
        <Box sx={{ mt: 2, mb: 2 }}>
          {campaignBuilderValues.campaignStrategy === CampaignStrategyOptions.Branded.value && (
            <Typography variant="body2" component="div">
              Used exclusively for bidding on branded keywords or branded ASINs. For Amazon, there are cases in which brand
              defense is vital for the business. For others, it can be poor spend.
              <Typography component="ul">
                <Typography variant="body2" component="li">
                  SP, SB, SBV, SD are eligible for Branded campaigns
                </Typography>
                <Typography variant="body2" component="li">
                  Exact, Phrase Only (no broad, no 'similar to advertised product')
                </Typography>
                <Typography variant="body2" component="li">
                  Discuss budget allocation with Manager - 5% of budget (unless proven out more is needed)
                </Typography>
                <Typography variant="body2" component="li">
                  Branded spend should be limited to a strategic set of items (large packs, innovation, low organic ranking items,
                  etc)
                </Typography>
              </Typography>
            </Typography>
          )}
          {campaignBuilderValues.campaignStrategy === CampaignStrategyOptions.Nonbranded.value && (
            <Typography variant="body2" component="div">
              Category keyword campaigns should be the bulk of any account's strategy. These campaigns should come out of
              Discovery campaigns, or 3rd party discovery through keywording tools like Merchant Words, or strong performing
              keywords known to the business. Nonbranded campaigns target Category keywords - nonbranded keywords that customers
              are searching on Amazon to find products like your brand is selling.
              <Typography component="ul">
                <Typography variant="body2" component="li">
                  Exact match, category keywords only
                </Typography>
                <Typography variant="body2" component="li">
                  Strict use of negative keywords to prevent branded
                </Typography>
                <Typography variant="body2" component="li">
                  80%+ of budget
                </Typography>
              </Typography>
            </Typography>
          )}
          {campaignBuilderValues.campaignStrategy === CampaignStrategyOptions.Discovery.value && (
            <Typography variant="body2" component="div">
              A campaign strategy for discovering new keywords and ASINs to target. Discovery should be used with Sponsored
              Products (SP) only. Eventually, Discovery campaigns will work themselves out of a job. The purpose of Discovery
              campaigns is to find all the relevant (or not relevant) keywords for your product and brand, and ultimately move
              them to core category or competitor campaigns, or exclude from campaigns.
              <Typography component="ul">
                <Typography variant="body2" component="li">
                  Auto, Phrase and Broad ONLY
                </Typography>
                <Typography variant="body2" component="li">
                  Bids must be lower than their manual counterpart
                </Typography>
                <Typography variant="body2" component="li">
                  Broad bids should be lower than phrase, etc.
                </Typography>
                <Typography variant="body2" component="li">
                  Must negatively target Brand keywords
                </Typography>
                <Typography variant="body2" component="li">
                  Auto campaigns exclusively classified as Discovery.
                </Typography>
              </Typography>
            </Typography>
          )}
          {campaignBuilderValues.campaignStrategy === CampaignStrategyOptions.Conquest.value && (
            <Typography variant="body2" component="div">
              Conquesting campaigns may not be necessary for your brand, but if so, should be isolated to their own strategy type
              to control against budget and ROAS goals. Conquesting keywords and ASINs should come from collaboration with retail
              teams. These keywords and ASINs may surface in Discovery campaigns, and if so should be moved to Conquesting
              campaigns to isolate performance.
              <Typography component="ul">
                <Typography variant="body2" component="li">
                  TOS conquesting, should be run through SP
                </Typography>
                <Typography variant="body2" component="li">
                  PDP conquesting should be run through SD
                </Typography>
              </Typography>
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
