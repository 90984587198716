import { Box, Tooltip } from '@mui/material';
import { FC, useEffect, useState, useCallback } from 'react';
import { ENVIRONMENT_IS_PRODUCTION } from '../../../config';
import useSharedApi from '../../hooks/use-shared-api';
import { Platform } from '../../types/platforms';

interface ApiHealthCheckDisplayProps {
  retailerPlatform: string;
}

type RetailerNameMap = {
  [PlatformValue: string]: string;
};

const retailerPlatformKeyMap: RetailerNameMap = {
  [Platform.WALMART.value]: Platform.WALMART.label,
  // [Platform.AMAZON.value]: Platform.AMAZON.label, // not implemented yet
  [Platform.SAMS_CLUB.value]: 'Sams'
};

export const ApiHealthCheckDisplay: FC<ApiHealthCheckDisplayProps> = ({ retailerPlatform }) => {
  const { getHealthCheckData } = useSharedApi();
  const [healthCheckData, setHealthCheckData] = useState<any>(<></>);
  const currentRetailerPlatformKey = retailerPlatformKeyMap[retailerPlatform];
  const prodOrNoRetailerPlatformKey = ENVIRONMENT_IS_PRODUCTION || !currentRetailerPlatformKey;

  const fetchHealthCheckData = useCallback(async () => {
    if (prodOrNoRetailerPlatformKey) {
      return;
    }

    const response = await getHealthCheckData();

    if (response.success || !!(response.body && response.body.entries)) {
      const platformHealthData = response.body.entries[currentRetailerPlatformKey];
      const tooltipTitle = (
        <div>
          <p>{platformHealthData.description}</p>
          <p>Status: {platformHealthData.status}</p>
          <p>baseUrl: {platformHealthData.data.baseUrl}</p>
        </div>
      );
      setHealthCheckData(
        <Tooltip title={tooltipTitle} placement="bottom">
          <Box
            sx={{
              backgroundColor: platformHealthData.status === 'Healthy' ? 'green' : 'red',
              color: 'white',
              fontWeight: 'bold',
              padding: '5px',
              borderRadius: '5px',
              marginLeft: '10px'
            }}
          >
            {currentRetailerPlatformKey} {platformHealthData.data.environment}
          </Box>
        </Tooltip>
      );
    } else {
      console.error('Error fetching health check data: ', response.errorMessage);
    }
  }, [currentRetailerPlatformKey, prodOrNoRetailerPlatformKey, getHealthCheckData]);

  useEffect(() => {
    if (!prodOrNoRetailerPlatformKey) {
      fetchHealthCheckData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (prodOrNoRetailerPlatformKey) {
    return <></>;
  }

  return healthCheckData;
};
