import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import CampaignsGridForDialog from './campaigns-grid-for-dialog';
import useAmazonApi from '../../hooks/use-amazon-api';

interface AddCampaignsToBudgetGroupWithGridDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  refreshCampaigns?: () => void;
}

export const AddCampaignsToBudgetGroupWithGridDialog = (props: AddCampaignsToBudgetGroupWithGridDialogProps) => {
  const { toggle, isShowing, dynamicKey, refreshCampaigns } = props;
  const { getCampaigns } = useAmazonApi();

  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);

  const handleAddCampaigns = async () => {
    setBackDropOpen(true);

    refreshCampaigns?.();

    setBackDropOpen(false);
    toggle();
  };

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="lg">
      <DialogTitle>Create Budget Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>
        <CampaignsGridForDialog getCampaigns={getCampaigns} handleAddCampaigns={handleAddCampaigns} isBudgetGroup />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
