import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import ErrorIcon from '@mui/icons-material/Error';
import { off } from 'process';
import { Box, Tooltip } from '@mui/material';

export const renderLastMetricsDate = (params: GridRenderCellParams, daysBackSla: number) => {
  if (!params.value) {
    return null;
  }

  const warningDate = new Date();
  warningDate.setDate(warningDate.getDate() - daysBackSla);
  const dateValue = new Date(params.value);
  dateValue.setHours(dateValue.getHours() + 8);
  const tooltipTitle = `Data is older than ${daysBackSla} day(s)`;
  if (new Date(params.value) < warningDate) {
    return (
      <>
        <Tooltip title={tooltipTitle}>
          <Box display={'flex'} alignContent={'center'}>
            <ErrorIcon color="warning" sx={{ mr: '0.7rem' }} /> {dateValue.toLocaleDateString()}
          </Box>
        </Tooltip>
      </>
    );
  }

  return <>{dateValue.toLocaleDateString()}</>;
};
