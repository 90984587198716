import { Tooltip, IconButton } from '@mui/material';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import useAdsApi from '../../../../shared/hooks/use-walmart-sams-club-api';
import PauseIcon from '@mui/icons-material/Pause';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

interface PauseCampaignActionButtonProps {
  setIsLoading: (isLoading: boolean) => void;
  refreshCampaigns: () => void;
  selectionModelHasRows: boolean;
}

const DisableCampaignsActionButton = ({
  setIsLoading,
  refreshCampaigns,
  selectionModelHasRows
}: PauseCampaignActionButtonProps) => {
  const apiRef = useGridApiContext();
  const { bulkSetCampaignIsActiveInVector } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const disableCampaigns = async () => {
    setIsLoading(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values())
      .filter((campaign) => campaign.status.toLowerCase() !== 'proposal')
      .map((campaign) => campaign.campaignId);

    const response = await bulkSetCampaignIsActiveInVector(selectedCampaignIds, false);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    await refreshCampaigns();
    apiRef.current.setRowSelectionModel([]);
    setIsLoading(false);
  };

  return (
    <>
      <Tooltip title={selectionModelHasRows ? 'Pause Campaigns' : 'Select at least one item to enable actions'}>
        <span>
          <IconButton
            sx={{ borderRadius: '100px' }}
            color="primary"
            size="large"
            onClick={disableCampaigns}
            disabled={!selectionModelHasRows}
          >
            <PauseIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DisableCampaignsActionButton;
