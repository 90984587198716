import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import {
  GridCellEditStopParams,
  GridEditCellValueParams,
  GridEditInputCell,
  GridRenderEditCellParams,
  GridRowEditStopParams
} from '@mui/x-data-grid-premium';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      height: '100%'
    }
  })
);

export function BudgetItemEditInputCell(props: GridRenderEditCellParams) {
  const { error } = props;

  let typeSafeProps: GridRenderEditCellParams = { ...props, error: typeof props.error === 'string' && props.error !== '' };

  return (
    <StyledTooltip open={!!error} title={error}>
      <span>
        <GridEditInputCell
          {...typeSafeProps}
          sx={{ border: 1, borderColor: !!error ? 'error.main' : 'success.main', borderRadius: '4px' }}
          onBlur={() => {
            props.api.setEditCellValue({
              id: typeSafeProps.id,
              field: typeSafeProps.field,
              value: typeSafeProps.value
            } as GridEditCellValueParams);
            props.api.stopCellEditMode({
              id: typeSafeProps.id,
              field: typeSafeProps.field
            } as GridCellEditStopParams);
          }}
        />
      </span>
    </StyledTooltip>
  );
}
