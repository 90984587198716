import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { formatAsCurrency } from '../../../shared/utilities/numeric-format-utilities';

interface BidConfirmationDialogProps {
  promiseArguments: any;
  handleYes: () => void;
  handleNo: () => void;
}

export function BidConfirmationDialog({ promiseArguments, handleYes, handleNo }: BidConfirmationDialogProps) {
  if (!promiseArguments) {
    return null;
  }

  const { newRow, oldRow } = promiseArguments;

  return (
    <Dialog maxWidth="xs" open={!!promiseArguments}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>{`Pressing 'Yes' will change  the bid from ${formatAsCurrency(oldRow.bid)} to ${formatAsCurrency(
        newRow.bid
      )}.`}</DialogContent>
      <DialogActions>
        <Button onClick={handleNo}>No</Button>
        <Button onClick={handleYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}
