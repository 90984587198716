import { GridRowId } from '@mui/x-data-grid-premium';
import { hasValue } from '../utilities/general-utilities';

export interface Profile {
  profileId: number | string;
  name: string;
}

export interface UpdateProfileRequest {
  profileId: GridRowId;
  name?: string;
  roasGoal?: number;
}

export const getUpdateProfileRequest = (newRow: any, oldRow: any): UpdateProfileRequest | null => {
  const profile = getUpdatedProfileFields(oldRow, newRow);

  // If there is only one key it is the profileId and nothing has changed
  if (Object.keys(profile).length === 1) {
    return null;
  }

  // Only include fields that exist on the mutated object
  return {
    profileId: profile.profileId,
    ...(hasValue(profile?.name) && { name: profile.name }),
    ...(hasValue(profile?.roasGoal) && { roasGoal: profile.roasGoal })
  };
};

const getUpdatedProfileFields = (oldRow: any, newRow: any): UpdateProfileRequest => {
  // Only include the fields on the object that have changed
  return {
    profileId: oldRow.profileId,
    ...(oldRow.name !== newRow.name && { name: newRow.name }),
    ...(oldRow.roasGoal !== newRow.roasGoal && { roasGoal: newRow.roasGoal })
  };
};
