import { API_AUTH_SCOPE, API_ROOT } from '../../config';
import { ResponseObject, executeGetRequest, executePostRequest } from '../utilities/fetch-utilities';
import { useAuth } from './use-auth';

const useSharedApi = () => {
  const { user, acquireToken } = useAuth();

  const getHealthCheckData = async () => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    return await executeGetRequest({
      endpoint: `${API_ROOT}/utilities/health`,
      authToken: authResponse.accessToken
    });
  };

  const getUserSetting = async (key: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return executeGetRequest({
      endpoint: `${API_ROOT}/api/UserSettings?email=${user.email}&key=${key}`,
      authToken: authResponse.accessToken
    });
  };

  const updateUserSetting = async (key: string, setting: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const postData = {
      email: user.email,
      key: key,
      value: setting
    };

    return await executePostRequest({
      endpoint: `${API_ROOT}/api/UserSettings`,
      body: postData,
      authToken: authResponse.accessToken
    });
  };

  const getAdvertizingClientsFromForeman = async (retailer: string) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/ForemanPassthrough/GetAdvertisingClients?retailer=${retailer}`,
      authToken: authResponse.accessToken
    });
  };

  return {
    getHealthCheckData,
    getUserSetting,
    updateUserSetting,
    getAdvertizingClientsFromForeman
  };
};

export default useSharedApi;
