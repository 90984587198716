import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { IGridConfigSettingItem } from '../../../shared/utilities/grid-config-settings';
import { v4 as uuidv4 } from 'uuid';

type AuditLogGridProps = {
  entityType: string;
  selectedAuditLogTypeConfig: {
    columns: GridColDef[];
    fetchFunction: any;
    gridConfigSetting: IGridConfigSettingItem;
    pinnedColumns: any;
  };
};

export default function AuditLogGrid(props: AuditLogGridProps) {
  const { entityType, selectedAuditLogTypeConfig } = props;

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  var [auditLogData, setAuditLogData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: {
      ...selectedAuditLogTypeConfig.gridConfigSetting
    },
    breadCrumbParams: {}
  });

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await selectedAuditLogTypeConfig.fetchFunction(pageable, dateSettings.beginDate, dateSettings.endDate);

        if (response.success) {
          setAuditLogData(response.body.records);
          setRowCount(response.body.totalFilteredRecords);
        } else {
          enqueueSnackbar('Error fetching audit logs...', { variant: 'error' });
        }
      } catch (error: any) {
        enqueueSnackbar('Error: ' + error?.message, { variant: 'error' });
      }
      setIsLoading(false);
    };

    if (selectedAuditLogTypeConfig && pageable && !settingsLoading && !dateSettingsLoading) {
      fetchData();
    }
  }, [selectedAuditLogTypeConfig, pageable, dateSettings, settingsLoading, dateSettingsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ height: '800px' }}>
      {!initialSettings || settingsLoading ? (
        <DataGridPremium
          rows={[]}
          columns={selectedAuditLogTypeConfig.columns}
          loading={true}
          disableRowGrouping
          initialState={{}}
          key={uuidv4()}
        />
      ) : (
        <PerformanceGrid
          key={entityType}
          loading={isLoading}
          rows={auditLogData}
          columns={selectedAuditLogTypeConfig.columns}
          initialState={{
            ...initialSettings?.config,
            pinnedColumns: selectedAuditLogTypeConfig.pinnedColumns
          }}
          saveGridConfig={saveGridConfig}
          dateConfig={{
            dateSettings: dateSettings,
            dateSettingsLoading: dateSettingsLoading,
            saveDateConfig: saveDateConfig
          }}
          rowCount={rowCount}
          handleFilterModelChange={handleFilterModelChange}
          handleSortModelChange={handleSortModelChange}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          processRowUpdate={function (newRow: any, oldRow: any) {
            throw new Error('Function not implemented.');
          }}
        />
      )}
    </Box>
  );
}
