import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { DataGridPremium as DefaultUninitializedDataGridPremium } from '@mui/x-data-grid-premium';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PerformanceGrid, { OtherDataGridProps } from '../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { GridConfigSettings, IGridConfigSettingItem } from '../../../shared/utilities/grid-config-settings';
import useAmazonApi from '../../hooks/use-amazon-api';
import { Campaign } from '../../types/campaign';
import { BID_GROUP_TARGET_HISTORY_COLUMNS } from './bid-group-target-history-columns';
import { BID_GROUP_PLACEMENT_HISTORY_COLUMNS } from './bid-group-placement-history-columns';
import BidGroupsMetricsChart from './bid-group-metrics-chart';
import { format } from 'date-fns';

interface BidGroupTargetHistoryRow {
  compositeKey: string;
  id: string;
  campaignBidOptimizationGroupId: string;
  campaignName?: string;
  campaignState?: string;
  createdAt: string;
  acosTarget: number;
  updatedTargetsCount: number;
  targetId: string;
  target: string;
  entityType: string;
  oldBid: number;
  newBid: number;
  reason: string;
}

interface BidGroupPlacementHistoryRow {
  compositeKey: string;
  id: string;
  campaignBidOptimizationGroupId: string;
  campaignName?: string;
  campaignState?: string;
  createdAt: string;
  acosTarget: number;
  updatedTargetsCount: number;
  targetId: string;
  target: string;
  entityType: string;
  oldBid: number;
  newBid: number;
  reason: string;
}
interface InitializedPerformanceGridProps {
  bidGroupAcosTarget?: number;
  breadCrumbParams: any;
  settingsKey: IGridConfigSettingItem;
  columns: any;
  processRowUpdate: any;
  fetchEntity: any;
  rowId: string;
  getRowClassName: any;
  disableGridDateRangePicker: boolean;
  bulkActions: any;
  bottomMargin: number;
  allowQuickFilterSearch: boolean;
  shouldIncludeChartMetrics: boolean;
  otherDataGridProps?: OtherDataGridProps;
  customProps?: any;
}

export const RawInitializedBidGroupPerformanceGrid = (params: InitializedPerformanceGridProps) => {
  const {
    bidGroupAcosTarget,
    breadCrumbParams,
    settingsKey,
    columns,
    processRowUpdate,
    fetchEntity,
    rowId,
    getRowClassName,
    disableGridDateRangePicker,
    bulkActions,
    bottomMargin,
    allowQuickFilterSearch,
    shouldIncludeChartMetrics,
    otherDataGridProps,
    customProps
  } = params;

  const { getBidOptimizationTargetHistory, getBidOptimizationPlacementHistory } = useAmazonApi();

  const {
    initialSettings: campaignInitialSettings,
    settingsLoading: isCampaignSettingsLoading,
    saveGridConfig: saveCampaignGridConfig
  } = useGridConfig({
    setting: settingsKey,
    breadCrumbParams: breadCrumbParams
  });
  const {
    initialSettings: targetHistoryInitialSettings,
    settingsLoading: isTargetHistorySettingsLoading,
    saveGridConfig: saveTargetHistoryGridConfig
  } = useGridConfig({
    setting: GridConfigSettings.BID_GROUP_TARGET_HISTORY_VIEW,
    breadCrumbParams: breadCrumbParams
  });
  const {
    initialSettings: placementHistoryInitialSettings,
    settingsLoading: isPlacementHistorySettingsLoading,
    saveGridConfig: savePlacementHistoryGridConfig
  } = useGridConfig({
    setting: GridConfigSettings.BID_GROUP_PLACEMENT_HISTORY_VIEW,
    breadCrumbParams: breadCrumbParams
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const {
    pageable: campaignPageable,
    setPageable: setCampaignPageable,
    setBreadcrumbParams: setCampaignBreadcrumbParams,
    handleFilterModelChange: handleCampaignFilterModelChange,
    handleSortModelChange: handleCampaignSortModelChange,
    handlePageChange: handleCampaignPageChange,
    handlePageSizeChange: handleCampaignPageSizeChange
  } = usePageable();

  const {
    pageable: targetHistoryPageable,
    setPageable: setTargetHistoryPageable,
    setBreadcrumbParams: setTargetHistoryBreadcrumbParams,
    handleFilterModelChange: handleTargetHistoryFilterModelChange,
    handleSortModelChange: handleTargetHistorySortModelChange,
    handlePageChange: handleTargetHistoryPageChange,
    handlePageSizeChange: handleTargetHistoryPageSizeChange
  } = usePageable();

  const {
    pageable: placementHistoryPageable,
    setPageable: setPlacementHistoryPageable,
    setBreadcrumbParams: setPlacementHistoryBreadcrumbParams,
    handleFilterModelChange: handlePlacementHistoryFilterModelChange,
    handleSortModelChange: handlePlacementHistorySortModelChange,
    handlePageChange: handlePlacementHistoryPageChange,
    handlePageSizeChange: handlePlacementHistoryPageSizeChange
  } = usePageable();

  const theme = useTheme();
  const [campaignRows, setCampaignRows] = useState<Campaign[]>([]);
  const [campaignRowCount, setCampaignRowCount] = useState(0);
  const [campaignTimeSeriesData, setCampaignTimeSeriesData] = useState<any[]>([]);
  const [isLoadingCampaignRows, setIsLoadingCampaignRows] = useState(true);

  const [targetHistoryRows, setTargetHistoryRows] = useState<any[]>([]);
  const [targetHistoryRowCount, setTargetHistoryRowCount] = useState(0);
  const [isLoadingTargetHistoryRows, setIsLoadingTargetHistoryRows] = useState(true);
  const [tagertChangesCountByDate, setTargetChangesCountByDate] = useState<{ [date: string]: number }>({});

  const [placementHistoryRows, setPlacementHistoryRows] = useState<any[]>([]);
  const [placementHistoryRowCount, setPlacementHistoryRowCount] = useState(0);
  const [isLoadingPlacementHistoryRows, setIsLoadingPlacementHistoryRows] = useState(true);
  const [placementChangesCountByDate, setPlacementChangesCountByDate] = useState<{ [date: string]: number }>({});

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!campaignInitialSettings) {
      return;
    }

    setCampaignBreadcrumbParams(breadCrumbParams);
  }, [campaignInitialSettings, breadCrumbParams, setCampaignBreadcrumbParams]);

  useEffect(() => {
    if (!campaignInitialSettings) {
      return;
    }

    setCampaignPageable(campaignInitialSettings.pageable);
  }, [campaignInitialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!campaignPageable || isCampaignSettingsLoading || dateSettingsLoading) {
      return;
    }

    fetchCampaigns();
  }, [campaignPageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!targetHistoryInitialSettings) {
      return;
    }

    setTargetHistoryBreadcrumbParams(breadCrumbParams);
  }, [targetHistoryInitialSettings, breadCrumbParams, setTargetHistoryBreadcrumbParams]);

  useEffect(() => {
    if (!targetHistoryInitialSettings) {
      return;
    }

    setTargetHistoryPageable(targetHistoryInitialSettings.pageable);
  }, [targetHistoryInitialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!targetHistoryPageable || isTargetHistorySettingsLoading || dateSettingsLoading) {
      return;
    }

    fetchTargetHistory();
  }, [targetHistoryPageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!placementHistoryInitialSettings) {
      return;
    }
    console.log('placementHistoryInitialSettings', placementHistoryInitialSettings);
    console.log('breadCrumbParams', breadCrumbParams);
    setPlacementHistoryBreadcrumbParams(breadCrumbParams);
  }, [placementHistoryInitialSettings, breadCrumbParams, setPlacementHistoryBreadcrumbParams]);

  useEffect(() => {
    if (!placementHistoryInitialSettings) {
      return;
    }
    setPlacementHistoryBreadcrumbParams(breadCrumbParams);

    setPlacementHistoryPageable(placementHistoryInitialSettings.pageable);
  }, [placementHistoryInitialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!placementHistoryPageable || isPlacementHistorySettingsLoading || dateSettingsLoading) {
      return;
    }

    fetchPlacementHistory();
  }, [placementHistoryPageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const fetchTargetHistory = useCallback(() => {
    if (!targetHistoryPageable || isTargetHistorySettingsLoading || dateSettingsLoading) {
      return;
    }

    setIsLoadingTargetHistoryRows(true);

    getBidOptimizationTargetHistory(targetHistoryPageable, dateSettings.beginDate, dateSettings.endDate)
      .then((response: any) => {
        if (!response || !response.success || !response.body) {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
          return;
        }

        setTargetHistoryRows(response.body.records || []);
        setTargetHistoryRowCount(response.body.totalFilteredRecords || DEFAULT_ROW_COUNT);

        const changesCount = getChangesCountByDate(response.body.records);
        setTargetChangesCountByDate(changesCount);
      })
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => {
        setIsLoadingTargetHistoryRows(false);
      });
  }, [
    targetHistoryPageable,
    isTargetHistorySettingsLoading,
    dateSettingsLoading,
    getBidOptimizationTargetHistory,
    dateSettings.beginDate,
    dateSettings.endDate
  ]);

  const fetchPlacementHistory = useCallback(() => {
    if (!placementHistoryPageable || isPlacementHistorySettingsLoading || dateSettingsLoading) {
      return;
    }

    setIsLoadingPlacementHistoryRows(true);

    getBidOptimizationPlacementHistory(placementHistoryPageable, dateSettings.beginDate, dateSettings.endDate)
      .then((response: any) => {
        if (!response || !response.success || !response.body) {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
          return;
        }

        setPlacementHistoryRows(response.body.records || []);
        setPlacementHistoryRowCount(response.body.totalFilteredRecords || DEFAULT_ROW_COUNT);

        const changesCount = getChangesCountByDate(response.body.records);
        setPlacementChangesCountByDate(changesCount);
      })
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => {
        setIsLoadingPlacementHistoryRows(false);
      });
  }, [
    placementHistoryPageable,
    isPlacementHistorySettingsLoading,
    dateSettingsLoading,
    getBidOptimizationPlacementHistory,
    dateSettings.beginDate,
    dateSettings.endDate
  ]);

  const getChangesCountByDate = (data: any[]): { [date: string]: number } => {
    const changesCount: { [date: string]: number } = {};

    if (!Array.isArray(data)) {
      return changesCount;
    }

    data.forEach((item) => {
      const date = format(new Date(item.createdAt), 'yyyy-MM-dd');
      changesCount[date] = (changesCount[date] || 0) + 1;
    });

    return changesCount;
  };

  const fetchCampaigns = useCallback(() => {
    if (!campaignPageable || isCampaignSettingsLoading || dateSettingsLoading) {
      return;
    }

    setIsLoadingCampaignRows(true);

    fetchEntity(campaignPageable, dateSettings.beginDate, dateSettings.endDate, shouldIncludeChartMetrics)
      .then((response: any) => {
        if (!response || !response.success || !response.body) {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
          return;
        }

        if (!response.body.dataGrid) {
          setCampaignRows(response.body.records || []);
          setCampaignRowCount(response.body.totalFilteredRecords || DEFAULT_ROW_COUNT);
        } else {
          setCampaignRows(response.body.dataGrid?.records || []);
          setCampaignRowCount(response.body.dataGrid?.totalFilteredRecords || DEFAULT_ROW_COUNT);
        }

        if (response.body.timeSeriesData) {
          setCampaignTimeSeriesData(response.body.timeSeriesData);
        }
      })
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => {
        setIsLoadingCampaignRows(false);
      });
  }, [
    campaignPageable,
    isCampaignSettingsLoading,
    dateSettingsLoading,
    fetchEntity,
    dateSettings.beginDate,
    dateSettings.endDate,
    shouldIncludeChartMetrics
  ]); // Ensure dependencies are correctly listed

  const refreshEntities = useCallback(() => {
    fetchCampaigns();
    fetchTargetHistory();
  }, [fetchCampaigns, fetchTargetHistory]);

  const BulkActions = useMemo(() => bulkActions(refreshEntities, setIsLoadingCampaignRows), [bulkActions, refreshEntities]);

  if (!campaignInitialSettings || isCampaignSettingsLoading) {
    return (
      <DefaultUninitializedDataGridPremium
        rows={[]}
        columns={columns}
        loading={true}
        processRowUpdate={processRowUpdate}
        initialState={{}}
        key={'default-grid-key'}
        disableRowGrouping
      />
    );
  }

  return (
    <Box>
      {!!campaignTimeSeriesData && shouldIncludeChartMetrics && (
        <Box display="flex" alignItems={'center'} my={'1rem'}>
          <BidGroupsMetricsChart
            timeSeriesData={campaignTimeSeriesData}
            isMetricsChartLoading={isLoadingCampaignRows}
            bidGroupAcosTarget={bidGroupAcosTarget}
            targetChangesCountByDate={tagertChangesCountByDate}
            placementChangesCountByDate={placementChangesCountByDate}
          />
        </Box>
      )}
      <Box
        pl={2}
        sx={{ backgroundColor: theme.palette.background.paper, pt: '0.5rem', borderRadius: '0.5rem', mb: '0.5rem', mt: '1.5rem' }}
      >
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Campaigns" sx={{ pt: 0 }} />
          <Tab label="Target History" sx={{ pt: 0 }} />
          <Tab label="Placement History" sx={{ pt: 0 }} />
        </Tabs>
      </Box>
      <Box>
        {(() => {
          switch (tabIndex) {
            case 0:
              return (
                // Campaigns Performance Grid
                <PerformanceGrid
                  key={'campaign-grid-key'}
                  processRowUpdate={processRowUpdate}
                  loading={isLoadingCampaignRows}
                  rows={campaignRows}
                  columns={columns}
                  initialState={campaignInitialSettings?.config}
                  saveGridConfig={saveCampaignGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    saveDateConfig: saveDateConfig
                  }}
                  getRowId={(row: any) => row[rowId]}
                  getRowClassName={getRowClassName}
                  bulkActions={BulkActions}
                  bottomMargin={bottomMargin}
                  disableGridDateRangePicker={disableGridDateRangePicker}
                  allowQuickFilterSearch={allowQuickFilterSearch}
                  rowCount={campaignRowCount}
                  handleFilterModelChange={(params) => {
                    console.log(params);
                    handleCampaignFilterModelChange(params);
                  }}
                  handleSortModelChange={(params) => {
                    console.log(params);
                    handleCampaignSortModelChange(params);
                  }}
                  handlePageChange={handleCampaignPageChange}
                  handlePageSizeChange={handleCampaignPageSizeChange}
                  maxHeight={'100vh'}
                  {...customProps}
                  {...otherDataGridProps}
                />
              );
            case 1:
              return (
                // Target History Grid
                <PerformanceGrid
                  key={'target-history-grid-key'}
                  processRowUpdate={(oldRow: BidGroupTargetHistoryRow, newRow: BidGroupTargetHistoryRow) => {}}
                  loading={isLoadingTargetHistoryRows}
                  rows={targetHistoryRows}
                  columns={BID_GROUP_TARGET_HISTORY_COLUMNS}
                  initialState={targetHistoryInitialSettings?.config}
                  saveGridConfig={saveTargetHistoryGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    saveDateConfig: saveDateConfig
                  }}
                  getRowId={(row: BidGroupTargetHistoryRow) => row.compositeKey}
                  getRowClassName={getRowClassName}
                  bulkActions={null}
                  bottomMargin={bottomMargin}
                  disableGridDateRangePicker={disableGridDateRangePicker}
                  allowQuickFilterSearch={false}
                  rowCount={targetHistoryRowCount}
                  handleFilterModelChange={(params) => {
                    console.log(params);
                    handleTargetHistoryFilterModelChange(params);
                  }}
                  handleSortModelChange={(params) => {
                    console.log(params);
                    handleTargetHistorySortModelChange(params);
                  }}
                  handlePageChange={handleTargetHistoryPageChange}
                  handlePageSizeChange={handleTargetHistoryPageSizeChange}
                  maxHeight={'100vh'}
                  minHeight={'50vh'}
                  {...customProps}
                  {...otherDataGridProps}
                />
              );
            case 2:
              return (
                // Placement History Grid
                <PerformanceGrid
                  key={'placement-history-grid-key'}
                  processRowUpdate={(oldRow: BidGroupPlacementHistoryRow, newRow: BidGroupPlacementHistoryRow) => {}}
                  loading={isLoadingPlacementHistoryRows}
                  rows={placementHistoryRows}
                  columns={BID_GROUP_PLACEMENT_HISTORY_COLUMNS}
                  initialState={placementHistoryInitialSettings?.config}
                  saveGridConfig={savePlacementHistoryGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    saveDateConfig: saveDateConfig
                  }}
                  getRowId={(row: BidGroupPlacementHistoryRow) => row.compositeKey}
                  getRowClassName={getRowClassName}
                  bulkActions={null}
                  bottomMargin={bottomMargin}
                  disableGridDateRangePicker={disableGridDateRangePicker}
                  allowQuickFilterSearch={false}
                  rowCount={placementHistoryRowCount}
                  handleFilterModelChange={handlePlacementHistoryFilterModelChange}
                  handleSortModelChange={handlePlacementHistorySortModelChange}
                  handlePageChange={handlePlacementHistoryPageChange}
                  handlePageSizeChange={handlePlacementHistoryPageSizeChange}
                  maxHeight={'100vh'}
                  minHeight={'50vh'}
                  {...customProps}
                  {...otherDataGridProps}
                />
              );
            default:
              return null;
          }
        })()}
      </Box>
    </Box>
  );
};

export const InitializedBidGroupPerformanceGrid = React.memo(RawInitializedBidGroupPerformanceGrid);
