import { API_AUTH_SCOPE, API_ROOT } from '../../config';
import { PlatformValue } from '../types/platforms';
import { ResponseObject, executeGetRequest, executePatchRequest, executePostRequest } from '../utilities/fetch-utilities';
import { useAuth } from './use-auth';
import { useCurrentRetailerPlatform } from './use-current-retailer-platform';
import type { CampaignBuilderRequest } from '../../citrusad/types/campaign-builder-request';
import { buildPageableParams } from '../utilities/url-utilities';
import { Pageable } from '../../shared/types/pageable';

export const useAdsApi = (retailerPlatform?: PlatformValue) => {
  const { acquireToken, user } = useAuth();
  const currentRetailerPlatform = useCurrentRetailerPlatform(retailerPlatform);

  if (!currentRetailerPlatform) {
    return {} as {
      [functionName: string]: () => Promise<ResponseObject>;
    };
  }

  const getTeams = async (pageable?: Pageable, beginDate?: string | null, endDate?: string | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Team?${params}`,
      authToken: authResponse.accessToken
    });
  };

  const getChildClientIdByTeamId = async (teamId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign/GetChildClientIdByProfileId?teamId=${teamId}`,
      authToken: authResponse.accessToken
    });
  };

  const getTeamById = async (id: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const query = {
      column: 'teamId',
      value: id,
      comparator: 'Equals'
    };

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Team?Filters=${encodeURI(JSON.stringify(query))}`,
      authToken: authResponse.accessToken
    });
  };

  const getWallets = async (pageable: Pageable, beginDate?: string | null, endDate?: string | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    pageable.filters?.forEach((filter) => {
      if (filter.column === 'profileId') {
        filter.column = 'teamId';
      }
    });

    const filter = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Wallet?${filter}`,
      authToken: authResponse.accessToken
    });
  };

  const buildCampaign = async (campaignBuilderRequest: CampaignBuilderRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign`,
      authToken: authResponse.accessToken,
      body: campaignBuilderRequest
    });
  };

  const getCampaigns = async (pageable: Pageable, beginDate?: string | null, endDate?: string | null) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    let url = `${API_ROOT}/${currentRetailerPlatform}/Campaign?${params}`;

    return await executeGetRequest({
      endpoint: url,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignById = async (profileId: string, campaignId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const teamQuery = {
      column: 'teamId',
      value: profileId,
      comparator: 'Equals'
    };

    const campaignQuery = {
      column: 'Id',
      value: campaignId,
      comparator: 'Equals'
    };

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Team?Filters=${encodeURI(JSON.stringify(teamQuery))}?Filters=${encodeURI(
        JSON.stringify(campaignQuery)
      )}`,
      authToken: authResponse.accessToken
    });
  };

  const getWalletsByTeamId = async (id: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const query = {
      column: 'teamId',
      value: id,
      comparator: 'Equals'
    };

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/wallet?Filters=${encodeURI(JSON.stringify(query))}`,
      authToken: authResponse.accessToken
    });
  };

  const updateCampaign = async (id: string, requestBody: any): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePatchRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign/${id}`,
      body: requestBody,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignOverview = async (campaignId: string, teamId: string, namespaceId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign/GetById?campaignId=${campaignId}&teamId=${teamId}&namespaceId=${namespaceId}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignProductMetrics = async (
    campaignId: string,
    pageable: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    const params = buildPageableParams({ beginDate, endDate, campaignId }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign/GetCampaignProductMetric?${params}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignSearchTermMetrics = async (
    campaignId: string,
    pageable: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    const params = buildPageableParams({ beginDate, endDate, campaignId }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign/GetCampaignSearchTermMetric?${params}`,
      authToken: authResponse.accessToken
    });
  };

  const getAds = async (id: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Team`,
      authToken: authResponse.accessToken
    });
  };

  const getAllProducts = async (childClientId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const query = {
      column: 'childClientId',
      value: `${childClientId}`,
      comparator: 'Equals'
    };

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign/GetAllProduct?Filters=${encodeURI(JSON.stringify(query))}`,
      authToken: authResponse.accessToken
    });
  };

  const getProductById = async (value: number | string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);
    const payload = { column: 'Tcin', value, comparator: 'Equals' };
    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Campaign/GetAllProduct?Filters=${encodeURI(JSON.stringify(payload))}`,
      authToken: authResponse.accessToken
    });
  };

  return {
    getTeams,
    getTeamById,
    getWallets,
    buildCampaign,
    getCampaigns,
    getCampaignById,
    getWalletsByTeamId,
    updateCampaign,
    getCampaignOverview,
    getAds,
    getAllProducts,
    getProductById,
    getChildClientIdByTeamId,
    getCampaignProductMetrics,
    getCampaignSearchTermMetrics
  };
};

export default useAdsApi;
