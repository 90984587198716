import { CheckCircle, Delete, DisabledByDefault } from '@mui/icons-material';
import { IconButton, TextField, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useState } from 'react';
import { CampaignBuilderFormValues } from './campaign-form-config';
import { singleItemCampaignStructureTypes } from '../../../types/campaign-builder-request';
import { CampaignBuilderItem } from '../../../types/campaign-builder-item';

interface CampaignItemsTableProps {
  values: CampaignBuilderFormValues;
  removeItem: (item: CampaignBuilderItem) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

interface CampaignItemsTableRowProps extends CampaignItemsTableProps {
  item: CampaignBuilderItem;
}

export const CampaignItemsTable: FC<CampaignItemsTableProps> = (props) => {
  const { values, removeItem, setFieldValue } = props;

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: '400px', minHeight: '400px', border: '1px solid divider', borderRadius: '0px' }}
    >
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Item Image</TableCell>
            <TableCell>ASIN</TableCell>
            <TableCell>Item Name</TableCell>
            {singleItemCampaignStructureTypes.includes(values.campaignStructureType) && (
              <TableCell align="center">
                <Tooltip placement="top" title="Required: Nickname is included in the campaign name">
                  <span>Nickname*</span>
                </Tooltip>
              </TableCell>
            )}
            <TableCell>Eligibility</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.items.map((item: CampaignBuilderItem) => (
            <CampaignItemsTableRow
              key={item.asin}
              item={item}
              values={values}
              removeItem={removeItem}
              setFieldValue={setFieldValue}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CampaignItemsTableRow = (props: CampaignItemsTableRowProps) => {
  const { item, values, removeItem, setFieldValue } = props;
  const [editedItemNickname, setEditedItemNickname] = useState<string>('');
  const itemNicknameCharacterLimit = 50;

  return (
    <TableRow key={`${item.asin}-row`}>
      <TableCell>
        <img src={item.imageUrl} alt={item.title} width={50} />
      </TableCell>
      <TableCell sx={{ fontSize: '11px' }}>{item.asin}</TableCell>
      <TableCell>
        <Tooltip title={item.title}>
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontSize: '11px'
            }}
          >
            {item.title.substring(0, 10) + '...' + item.title.substring(item.title.length - 10)}
          </Typography>
        </Tooltip>
      </TableCell>
      {singleItemCampaignStructureTypes.includes(values.campaignStructureType) && (
        <TableCell>
          <TextField
            size="small"
            error={!editedItemNickname}
            helperText={!editedItemNickname ? 'required' : `${editedItemNickname.length}/${itemNicknameCharacterLimit}`}
            fullWidth
            inputProps={{
              style: {
                fontSize: '11px'
              },
              maxLength: itemNicknameCharacterLimit
            }}
            InputProps={{
              endAdornment: editedItemNickname ? <CheckCircle color="success" /> : <DisabledByDefault color="error" />
            }}
            variant="outlined"
            value={editedItemNickname}
            onChange={(e) => {
              setEditedItemNickname(e.target.value);
            }}
            onBlur={async (e) => {
              const updatedItems = [...values.items];
              updatedItems.find((i) => i.asin === item.asin).itemNickname = editedItemNickname;
              await setFieldValue('items', updatedItems);
            }}
          />
        </TableCell>
      )}
      <TableCell sx={{ fontSize: '11px' }}>{item.eligibilityStatus}</TableCell>
      <TableCell>
        <IconButton onClick={() => removeItem(item)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
