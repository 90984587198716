import { Typography } from '@mui/material';
import { GridColDef, GridValueSetterParams } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { renderBudgetSlider } from '../../../../walmart/pages/budget/render-budget-slider-edit';
import { renderCurrentAssignedBudget } from '../../../../walmart/pages/budget/render-budget-slider-view';
import { MIN_DAILY_BUDGET, calculateDailyBudget, daysElapsed, getPacingPercent, getPercent } from '../../../types/budget';
import { renderPacingPercent } from '../renderer/budget/render-pacing-percent';
import { renderAsCurrencyFormatter } from '../renderer/renderAsCurrencyFormat';

export const useBudgetReallocationColumns = (clientBudgetForCurrentMonth: number, isEditing: boolean, month: string) => {
  const createdColumns: GridColDef[] = useMemo(
    () => [
      { field: 'campaignId', headerName: 'Campaign ID', width: 80, editable: false },
      { field: 'campaignName', headerName: 'Campaign Name', width: 225, editable: false },
      {
        field: 'currentMonthSpend',
        headerName: `${month} Spend`,
        flex: 1,
        editable: false,
        type: 'number',
        valueFormatter: renderAsCurrencyFormatter
      },
      {
        field: 'averageDailySpend',
        headerName: 'Average Daily Spend',
        flex: 1,
        type: 'number',
        aggregable: false,
        valueGetter: (params) => {
          if (params.row.currentMonthSpend === 0) {
            return 0;
          }

          return Number(params.row.currentMonthSpend / daysElapsed() - 1);
        },
        valueFormatter: renderAsCurrencyFormatter
      },
      {
        field: 'currentMonthBudget',
        headerName: `${month} Budget`,
        flex: 1,
        editable: false,
        type: 'number',
        valueFormatter: renderAsCurrencyFormatter
      },
      {
        field: 'dailyBudget',
        headerName: 'Daily Budget',
        width: 120,
        editable: false,
        valueFormatter: renderAsCurrencyFormatter
      },
      {
        field: 'pacing',
        headerName: '% Off Pace',
        flex: 1,
        aggregable: false,
        renderCell: (params) => renderPacingPercent(params.row.actualPacingPercentage, params.row.targetPacingPercentage),
        valueGetter: (params) => Number(getPacingPercent(params.row.actualPacingPercentage, params.row.targetPacingPercentage))
      },
      {
        field: 'projectedMonthBudget',
        headerName: `New ${month} Budget`,
        headerClassName: 'overBudget',
        flex: 1,
        type: 'number',
        editable: false,
        aggregable: true,
        cellClassName: 'projected-month-budget-cell',
        valueGetter: (params) => {
          if (params.row.projectedMonthBudget !== undefined) {
            return params.row.projectedMonthBudget;
          }

          return params.row.currentMonthBudget;
        },
        valueFormatter: renderAsCurrencyFormatter
      },
      {
        field: 'projectedDailyBudget',
        headerName: 'New Daily Budget',
        headerClassName: 'overBudget',
        flex: 1,
        type: 'number',
        editable: false,
        aggregable: true,
        renderCell: (params) => {
          if (params.row.projectedDailyBudget !== undefined && params.value === 50) {
            return (
              <Typography variant="body2" color="warning.main">
                {params.row.projectedDailyBudget}
              </Typography>
            );
          }
          if (params.row.projectedDailyBudget !== undefined) {
            return <Typography variant="body2">{params.row.projectedDailyBudget}</Typography>;
          }

          return <Typography variant="body2">-</Typography>;
        },
        valueGetter: (params) => {
          let monthlyBudget =
            params.row.projectedMonthBudget !== undefined ? params.row.projectedMonthBudget : params.row.currentMonthBudget;

          let calculatedBudget = calculateDailyBudget(monthlyBudget, params.row.currentMonthSpend);
          let newDailyBudget = calculatedBudget < MIN_DAILY_BUDGET ? MIN_DAILY_BUDGET : calculatedBudget;

          return Number(newDailyBudget.toFixed(2));
        }
      },
      {
        field: 'projectedPacing',
        headerName: 'New % Off',
        headerClassName: 'overBudget',
        flex: 1,
        type: 'number',
        editable: false,
        aggregable: true,
        renderCell: (params) => {
          const targetPacing = params.row.targetPacingPercentage;

          let projectedPacing = 0;

          if (params.row.projectedMonthBudget !== undefined) {
            projectedPacing = getPacingPercent(params.row.currentMonthSpend, params.row.projectedMonthBudget);
          }

          return renderPacingPercent(projectedPacing, targetPacing);
        },
        valueGetter: (params) => {
          const targetPacing = params.row.targetPacingPercentage;

          let projectedPacing = 0;

          if (params.row.projectedMonthBudget !== undefined) {
            projectedPacing = getPacingPercent(params.row.currentMonthSpend, params.row.projectedMonthBudget);
            return getPacingPercent(projectedPacing, targetPacing);
          }

          return getPacingPercent(params.row.currentMonthSpend, params.row.currentMonthBudget);
        }
      },

      { field: 'targetPacingPercentage', headerName: 'Target Pacing Percentage', width: 80, editable: false },
      { field: 'actualPacingPercentage', headerName: 'Actual Pacing Percentage', width: 80, editable: false },
      {
        field: 'currentAssignedBudget',
        headerName: 'Current Assigned Budget %',
        width: 300,
        type: 'number',
        editable: isEditing,
        renderCell: (params) => renderCurrentAssignedBudget(params, isEditing),
        renderEditCell: (params) => renderBudgetSlider(params, clientBudgetForCurrentMonth, isEditing),
        valueGetter: (params) => {
          if (params.row.currentAssignedBudget !== undefined) {
            return params.row.currentAssignedBudget;
          }

          if (params.row.projectedMonthBudget !== undefined) {
            return getPercent(params.row.projectedMonthBudget, clientBudgetForCurrentMonth);
          }

          return getPercent(params.row.currentMonthBudget, clientBudgetForCurrentMonth);
        },
        valueSetter: (params: GridValueSetterParams) => {
          const newValue = Number(params.value);

          const monthlyBudget = (newValue / 100) * clientBudgetForCurrentMonth;
          const calculatedBudget = calculateDailyBudget(monthlyBudget, params.row.currentMonthSpend);
          const dailyBudget = calculatedBudget < MIN_DAILY_BUDGET ? MIN_DAILY_BUDGET : calculatedBudget;

          params.row.projectedMonthBudget = Number(monthlyBudget.toFixed(2));
          params.row.projectedDailyBudget = Number(dailyBudget.toFixed(2));
          params.row.projectedPacing = getPercent(params.row.currentMonthSpend, params.row.projectedMonthBudget).toFixed(2);

          const newRow = {
            ...params.row,
            projectedMonthBudget: Number(monthlyBudget.toFixed(2)),
            projectedDailyBudget: Number(dailyBudget.toFixed(2)),
            currentAssignedBudget: newValue,
            clientBudgetForCurrentMonth: clientBudgetForCurrentMonth
          };

          return newRow;
        },
        valueFormatter: renderAsCurrencyFormatter
      }
    ],
    [clientBudgetForCurrentMonth, isEditing, month] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return createdColumns;
};
