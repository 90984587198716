import { GridColDef } from '@mui/x-data-grid-premium';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { WALLET_STATUS_OPTIONS } from '../../../types/wallet';
import { getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { renderCampaignStatus } from '../../grid-components/renderer/renderStatus';

export const CITRUSAD_WALLET_COLUMNS: GridColDef[] = [
  {
    field: 'walletId',
    headerName: 'Wallet ID',
    width: 160,
    minWidth: 100,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'walletName',
    headerName: 'Name',
    width: 160,
    minWidth: 100,
    editable: false,
    pinnable: true,
    align: 'left'
  },
  {
    field: 'teamName',
    headerName: 'Team Name',
    width: 160,
    minWidth: 100,
    editable: false,
    pinnable: true,
    align: 'left'
  },
  {
    field: 'teamId',
    headerName: 'Team ID',
    width: 160,
    minWidth: 100,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    minWidth: 80,
    editable: false,
    type: 'singleSelect',
    valueOptions: WALLET_STATUS_OPTIONS.map((option) => option.value),
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    renderCell: renderCampaignStatus(WALLET_STATUS_OPTIONS)
  },
  {
    field: 'creditLimit',
    headerName: 'Credit Limit',
    width: 180,
    minWidth: 100,
    editable: false,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'dailyBudget',
    headerName: 'Current Monthly Budget',
    width: 180,
    minWidth: 100,
    editable: false,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'currentBalance',
    headerName: 'Current Balance',
    width: 180,
    minWidth: 100,
    editable: false,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'availableBalance',
    headerName: 'Available Balance',
    width: 180,
    minWidth: 100,
    editable: false,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'activeCampaignCount',
    headerName: 'Active Campaign Count',
    width: 100,
    minWidth: 80,
    editable: false,
    type: 'number'
  },
  {
    field: 'activeCampaignMaxTotalSpend',
    headerName: 'Active Campaign Max Total Spend',
    width: 180,
    minWidth: 100,
    headerAlign: 'left',
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  }
];
