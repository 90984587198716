import { Box, Button } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  getGridSingleSelectOperators,
  getGridStringOperators
} from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { renderSyncStatus } from '../../../../shared/components/grid-components/renderer/renderSyncStatus';
import { caseInsensitiveEquals } from '../../../../shared/utilities/string-utilities';
import { METRIC_COLUMNS } from '../../../../walmart/components/grid-components/column-configurations/metric-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import OverviewCreativeDrawer from '../../../pages/campaigns/campaign-overview/overview-creative-drawer';
import { AmazonCampaignType, AmazonCampaignTypeValue } from '../../../types/campaign';
import { DEFAULT_STATE_OPTIONS } from '../../../types/state-options';
import { renderActiveAction } from '../renderer/renderActiveToggle';
import { renderState } from '../renderer/renderState';

function RenderActions(params: GridRowParams) {
  const { updateAd } = useAmazonApi();

  const handleUpdateEntity = (entityId: string, state: 'enabled' | 'paused') => {
    return updateAd({ adId: entityId, state: state });
  };

  return renderActiveAction({ entityIdKey: 'adId', updateEntityFunction: handleUpdateEntity })(params);
}

export const AD_COLUMNS: GridColDef[] = [
  {
    field: 'actions',
    headerName: 'Active',
    filterable: false,
    type: 'actions',
    width: 80,
    getActions: RenderActions
  },
  {
    field: 'adGroupId',
    headerName: 'Ad Group ID',
    width: 160,
    type: 'string',
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
  },
  {
    field: 'adId',
    headerName: 'Ad ID',
    width: 160,
    type: 'string',
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
  },
  {
    field: 'state',
    headerName: 'State',
    type: 'singleSelect',
    valueOptions: ['Paused', 'Enabled'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    renderCell: renderState({ stateOptions: DEFAULT_STATE_OPTIONS }),
    width: 140,
    minWidth: 120
  },
  {
    field: 'syncState',
    headerName: 'Sync Status',
    width: 140,
    minWidth: 120,
    editable: false,
    type: 'singleSelect',
    align: 'left',
    valueOptions: ['Synced', 'Pending', 'Error'],
    renderCell: renderSyncStatus
  },
  ...METRIC_COLUMNS
];

export const createAdColumns = (campaignType: AmazonCampaignTypeValue): GridColDef[] => {
  if (caseInsensitiveEquals(campaignType, AmazonCampaignType.SPONSORED_BRANDS)) {
    return [
      {
        field: 'amazonSponsoredBrandsCreative',
        headerName: 'Creative',
        width: 200,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return <CreativeButton {...params} />;
        }
      },
      {
        field: 'amazonSponsoredBrandsName',
        headerName: 'SB Brand Name',
        width: 150,
        editable: false,
        align: 'left',
        type: 'string'
      },
      ...AD_COLUMNS
    ];
  }

  return [
    {
      field: 'asin',
      headerName: 'Asin',
      width: 150,
      editable: false,
      align: 'left',
      type: 'string'
    },
    ...AD_COLUMNS
  ];
};

const CreativeButton = (params: GridRenderCellParams) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const { amazonSponsoredBrandsCreative, amazonSponsoredBrandsLandingPage } = params.row;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button onClick={() => toggle()}>View Creative</Button>
      <OverviewCreativeDrawer
        creative={amazonSponsoredBrandsCreative}
        landingPage={amazonSponsoredBrandsLandingPage}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </Box>
  );
};
