import { getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { renderCampaignStatus } from '../../../../shared/components/grid-components/renderer/campaign/renderStatus';
import { renderKeywordActions } from '../../../../walmart/components/grid-components/renderer/keyword/renderActiveToggle';
import { renderKeywordText } from '../../../../walmart/components/grid-components/renderer/keyword/renderKeywordText';
import { Platform } from '../../../../shared/types/platforms';
import { campaignStatusOptions } from '../../../../shared/types/walmart-sams-club/campaign';

export const NEGATIVE_KEYWORD_COLUMNS: ExtendedGridColDef[] = [
  {
    field: 'actions',
    headerName: 'Active',
    type: 'actions',
    width: 80,
    getActions: renderKeywordActions
  },
  {
    field: 'keywordText',
    headerName: 'Negative Keyword Text',
    width: 200,
    minWidth: 120,
    editable: false,
    renderCell: renderKeywordText(Platform.SAMS_CLUB.value)
  },
  {
    field: 'state',
    headerName: 'Active Status',
    type: 'singleSelect',
    valueOptions: ['Enabled', 'Paused'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 140,
    minWidth: 100,
    renderCell: renderCampaignStatus(campaignStatusOptions)
  },
  {
    field: 'keywordId',
    headerName: 'Keyword ID',
    width: 100,
    minWidth: 75,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'adGroupName',
    headerName: 'Ad Group Name',
    width: 300,
    minWidth: 125,
    editable: false
  },
  {
    field: 'adGroupId',
    headerName: 'Ad Group ID',
    width: 100,
    minWidth: 60,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'campaignName',
    headerName: 'Campaign Name',
    width: 300,
    minWidth: 150,
    editable: false
  },
  {
    field: 'campaignId',
    headerName: 'Campaign ID',
    width: 100,
    minWidth: 60,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'profileName',
    headerName: 'Profile Name',
    width: 300,
    minWidth: 120,
    editable: false
  },
  {
    field: 'profileId',
    headerName: 'Profile ID',
    width: 100,
    minWidth: 30,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  }
];
