import { Switch, Tooltip } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useAmazonApi from '../../hooks/use-amazon-api';
import { BidOptimizationGroupDto, BidOptimizationGroupStatus } from '../../types/bid-optimization-group';

export const StatusToggle = (params: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const { updateBidOptimizationGroup } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (event: any) => {
    setIsLoading(true);

    const requestString = event.target.checked ? BidOptimizationGroupStatus.Enabled : BidOptimizationGroupStatus.Disabled;

    const response = await updateBidOptimizationGroup({
      ...apiRef.current.getRow(params.row.id),
      status: requestString
    } as BidOptimizationGroupDto);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return;
    }

    const newRow = response?.body || { ...params.row };

    apiRef.current.updateRows([newRow]);
    enqueueSnackbar(`Bid group successfully ${requestString.toLowerCase()}`, { variant: 'success' });
    setIsLoading(false);
  };

  return (
    <Tooltip title={`Currently ${params.row.status.toLowerCase()}`} enterDelay={1000} enterNextDelay={1000}>
      <Switch
        key={`${params.row.id}-status-check`}
        checked={params.row.status === 'Enabled'}
        color="primary"
        size="small"
        onChange={handleChange}
        disabled={params.row.status === 'Archived'}
      />
    </Tooltip>
  );
};

export const renderStatus = (params: GridRenderCellParams) => {
  return [<StatusToggle key={params.row.id} {...params} />];
};
