import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Grid,
  InputAdornment,
  LinearProgress,
  ListItem,
  ListSubheader,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { PlatformMultiplierRequestObject } from '../../types/bid-multipliers';

interface PlatformMultipliers {
  App: number;
  Desktop: number;
  Mobile: number;
}

interface PlatformMultiplierProps {
  loading: boolean;
  platformMultipliers: PlatformMultipliers;
  setPlatformMultipliers: React.Dispatch<React.SetStateAction<PlatformMultipliers>>;
}

export const PlatformMultipliersContainer = (props: PlatformMultiplierProps) => {
  const { loading, platformMultipliers, setPlatformMultipliers } = props;

  return (
    <>
      <ListSubheader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          PLATFORM MODIFIERS
          <Tooltip title="To disable a modifier, set its value to 0">
            <InfoIcon sx={{ ml: 2 }} />
          </Tooltip>
        </Box>
      </ListSubheader>
      <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
      <ListItem>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>App</Typography>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '150px' }}
              size="small"
              value={platformMultipliers.App}
              onChange={(event: any) =>
                setPlatformMultipliers((prevState) => ({
                  ...prevState,
                  App: event.target.value
                }))
              }
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Box>%</Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        {' '}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>Desktop</Typography>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '150px' }}
              size="small"
              value={platformMultipliers.Desktop}
              onChange={(event: any) =>
                setPlatformMultipliers((prevState) => ({
                  ...prevState,
                  Desktop: event.target.value
                }))
              }
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Box>%</Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        {' '}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>Mobile Web</Typography>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '150px' }}
              size="small"
              value={platformMultipliers.Mobile}
              onChange={(event: any) =>
                setPlatformMultipliers((prevState) => ({
                  ...prevState,
                  Mobile: event.target.value
                }))
              }
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Box>%</Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};
