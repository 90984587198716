import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Platform } from '../platforms';

export interface CreateKeywordRequest {
  campaignId: number;
  adGroupId: number;
  state: string;
  matchType: string;
  keywordText: string;
  bid: number;
}

export interface CreateNegativeKeywordRequest {
  adGroupId: number;
  keywordText: string;
}

export interface UpdateKeywordRequest {
  keywordId: number;
  adGroupId: number;
  state: string;
  bid: number;
}

export interface UpdateNegativeKeywordRequest {
  keywordId: number;
  state: string;
}

export const buildUpdateKeywordRequest = (keyword: any): UpdateKeywordRequest => {
  return {
    keywordId: keyword.keywordId,
    adGroupId: keyword.adGroupId,
    state: keyword.state,
    bid: keyword.bid
  };
};

export const buildUpdateNegativeKeywordRequest = (keyword: any): UpdateNegativeKeywordRequest => {
  return {
    keywordId: keyword.keywordId,
    state: keyword.state
  };
};

export const KEYWORD_STATUS_OPTIONS = [
  {
    label: 'Enabled',
    value: 'enabled',
    icon: PlayArrowIcon
  },
  {
    label: 'Paused',
    value: 'paused',
    icon: PauseIcon
  }
];

export interface Keyword {
  adGroupId: Number;
  attributedRevenue: null;
  bid: Number;
  campaignId: Number;
  clickThroughRate: null;
  clicks: null;
  conversionRate: null;
  cost: null;
  costPerClick: null;
  costPerConversion: null;
  impressions: null;
  keywordCategory: null;
  keywordId: Number;
  keywordText: string;
  matchType: string;
  profileId: Number;
  returnOnAdSpend: null;
  state: string;
  status: string;
  units: null;
}

export const minWalmartSamsClubKeywordBid = {
  [Platform.WALMART.value]: 1.0,
  [Platform.SAMS_CLUB.value]: 1.25
} as const;

export const minWalmartSamsClubAdItems = {
  [Platform.WALMART.value]: 2,
  [Platform.SAMS_CLUB.value]: 1
} as const;
