import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/Delete';

interface AddedNegativeKeywordsTableProps {
  keywords: any;
  setKeywords: (keywords: any) => void;
}

const AddedNegativeKeywordsTable = ({ keywords, setKeywords }: AddedNegativeKeywordsTableProps) => {
  const [rows, setRows] = useState<any[]>([...keywords]);

  useEffect(() => {
    if (!keywords) {
      return;
    }

    setRows(keywords);
  }, [keywords]);

  const deleteKeyword = useCallback(
    (id: GridRowId) => () => {
      const updatedKeywordList = keywords.filter((keyword: any) => {
        return keyword.id !== id;
      });
      setKeywords(updatedKeywordList);
    },
    [setKeywords, keywords]
  );

  const columns = useMemo<Array<GridColDef>>(
    () => [
      {
        field: 'keywordText',
        headerName: 'Keyword Text',
        flex: 1,
        editable: false,
        disableReorder: true,
        sortable: false,
        resizable: false,
        filterable: false
      },
      {
        field: 'matchType',
        headerName: 'Match Type',
        flex: 0.75,
        editable: false,
        disableReorder: true,
        sortable: false,
        resizable: false,
        filterable: false
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 80,
        getActions: (params: { id: GridRowId }) => [
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteKeyword(params.id)} />
        ]
      }
    ],
    [deleteKeyword]
  );

  return (
    <div style={{ height: 420 }}>
      <DataGridPremium
        rows={rows}
        columns={columns}
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableDensitySelector
        disableRowSelectionOnClick
        disableRowGrouping
        getRowId={(row) => `${row.keywordText}-${row.matchType}`}
      />
    </div>
  );
};

export default AddedNegativeKeywordsTable;
