import { Configuration } from '@azure/msal-browser';
let frontendHostName;
let apiHost;
let environment;
let clientId = 'f8320d3a-6f88-4382-969c-9dff4d124eaf';
let apiAuthScope = 'api://691a92c4-c250-4cda-b560-52ddc003bf1f/access';

const developmentFrontendHost = 'http://localhost:3000';

const localBackendHost = 'http://localhost:3001';
const testBackendHost = 'https://vector-api-test.harvestgroup.com';
const prodBackendHost = 'https://vector-api.harvestgroup.com';

const testFrontendHostnames = ['https://hg-advertising-cdn-test.azureedge.net', 'https://vector-test.harvestgroup.com'];
const prodFrontendHostnames = ['https://hg-advertising-cdn-prod.azureedge.net', 'https://vector.harvestgroup.com'];
const currentHostname = window?.location.origin;

const apiEnv = (process.env.REACT_APP_API_ENV || '').toLowerCase();

export const isTestFrontendDomain = () => {
  return testFrontendHostnames.includes(currentHostname);
};

export const isProductionFrontendDomain = () => {
  return prodFrontendHostnames.includes(currentHostname);
};

if (isTestFrontendDomain()) {
  frontendHostName = currentHostname;
  apiHost = testBackendHost;
  environment = 'Test';
} else if (isProductionFrontendDomain()) {
  frontendHostName = currentHostname;
  apiHost = prodBackendHost;
  environment = 'Production';
  clientId = 'f370e82a-6a92-4715-b63d-ff34603d9dfa';
  apiAuthScope = 'api://ce438bfa-ad76-445d-ab9d-5d5fd909ca0f/access';
} else {
  frontendHostName = developmentFrontendHost;
  apiHost = localBackendHost;
  environment = 'Local';
}

if (apiEnv === 'test') {
  apiHost = testBackendHost;
}

export const API_ROOT = apiHost;
export const API_AUTH_SCOPE = apiAuthScope;
export const MICROSOFT_GRAPH_SCOPE = 'User.Read';
export const HARVEST_EMPLOYEES_SECURITY_GROUP_NAME = 'Harvest Employees';
export const MUI_LICENSE_KEY =
  '31a6ddca5053981a5984359dc7374821Tz04NjU2NixFPTE3NDIxMzU2NDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: 'https://login.microsoftonline.com/52934c9b-912c-4480-b2bc-72fee70477bb',
    redirectUri: frontendHostName,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

export const ENVIRONMENT_IS_PRODUCTION: boolean = environment === 'Production';
