import { Backdrop, Box, CircularProgress, Container, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../../shared/components/page';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { Campaign as AmazonCampaign, getUpdateCampaignRequest } from '../../../types/campaign';
import { CampaignDetails } from './campaign-details';
import CampaignOverviewBiddingDialog from './campaign-overview-bidding';
import { OverviewAdGroups } from './overview-ad-groups';
import { OverviewAds } from './overview-ads';
import { OverviewCampaignNegativeTargets } from './overview-campaign-negative-targets';
import { PlacementPerformance } from './placement-performance';
import CustomTabPanel from '../../../../shared/components/CustomTabPanel';

export const CampaignOverview: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateCampaign, getCampaign } = useAmazonApi();
  const [searchParams] = useSearchParams();

  const profileId = searchParams.has('profileId') ? searchParams.get('profileId') : null;
  const campaignId = searchParams.has('campaignId') ? searchParams.get('campaignId') : null;

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isBidStrategyDialogOpen, setIsBidStrategyDialogOpen] = useState(false);
  const [placementPerformanceRefresh, setPlacementPerformanceRefresh] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<AmazonCampaign>({} as AmazonCampaign);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  // When the page loads, put the scroll at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchCampaignOverview = async () => {
    if (!campaignId) {
      return;
    }

    setIsInitialLoading(true);

    const response = await getCampaign(campaignId);

    if (response?.success) {
      setCampaign(response?.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsInitialLoading(false);
  };

  useEffect(() => {
    fetchCampaignOverview();
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateCampaign = async (updatedCampaign: any) => {
    setIsUpdateLoading(true);

    const requestBody = getUpdateCampaignRequest(updatedCampaign);

    const response = await updateCampaign({ campaignId: updatedCampaign.campaignId, ...requestBody });

    if (response.success) {
      setCampaign(response?.body[0]);
      enqueueSnackbar('Campaign updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsUpdateLoading(false);
  };

  function renderCampaignDetails() {
    if (isInitialLoading) {
      return <Skeleton width={'100%'} height={50} />;
    }

    if (!campaign) {
      return;
    }

    return (
      <CampaignDetails
        campaign={campaign}
        updateCampaign={handleUpdateCampaign}
        setCampaign={setCampaign}
        openBidStrategyDialog={setIsBidStrategyDialogOpen}
      />
    );
  }

  function renderPlacementPerformance() {
    if (isInitialLoading) {
      return <Skeleton width={1500} height={300} />;
    }

    if (!campaign || !campaign.amazonSponsoredProductsDynamicBidding) {
      return (
        <Typography variant="h6" mt={2}>
          No Placement Performance data available
        </Typography>
      );
    }

    return <PlacementPerformance campaign={campaign} setCampaign={setCampaign} refresh={placementPerformanceRefresh} />;
  }

  function renderAdGroups() {
    if (isInitialLoading) {
      return <Skeleton width={1500} height={300} />;
    }

    if (!campaign) {
      return null;
    }

    return (
      <OverviewAdGroups
        campaignId={campaignId}
        campaignType={campaign?.campaignType}
        targetingType={campaign?.targetingType}
        refreshCampaignOverview={fetchCampaignOverview}
      />
    );
  }

  function renderAds() {
    if (isInitialLoading) {
      return <Skeleton width={'100%'} height={50} />;
    }

    if (!campaign) {
      return null;
    }

    return <OverviewAds campaignType={campaign.campaignType} />;
  }

  function renderOverviewNegativeTargets() {
    if (isInitialLoading) {
      return <Skeleton width={1500} height={300} />;
    }

    return <OverviewCampaignNegativeTargets campaignId={campaignId} campaignType={campaign.campaignType} />;
  }

  return (
    <Page title={!!campaign?.name ? `Campaign: ${campaign?.name}` : 'Campaign Overview'}>
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Stack spacing={2} mt={2}>
          <Typography variant="h5" mr={2}>
            Campaign: {campaign?.name}
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTabIndex} onChange={handleChange} sx={{ margin: '0px' }}>
              <Tab label="Ad Groups" />
              <Tab label="Placements" />
              <Tab label="Negative Targets" />
              <Tab label="Ads" />
              <Tab label="Settings" />
            </Tabs>
          </Box>
          <CustomTabPanel value={currentTabIndex} index={0}>
            {renderAdGroups()}
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={1}>
            <Box mb={5}>{renderPlacementPerformance()}</Box>
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={2}>
            {renderOverviewNegativeTargets()}
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={3}>
            {renderAds()}
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={4}>
            {renderCampaignDetails()}
          </CustomTabPanel>
        </Stack>
      </Container>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isUpdateLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CampaignOverviewBiddingDialog
        isDialogOpen={isBidStrategyDialogOpen}
        setIsDialogOpen={setIsBidStrategyDialogOpen}
        campaign={campaign}
        setCampaign={setCampaign}
        updateCampaign={updateCampaign}
        refreshPlacementPerformance={() => setPlacementPerformanceRefresh(!placementPerformanceRefresh)}
      />
    </Page>
  );
};

export default CampaignOverview;
