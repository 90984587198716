import { isProductionFrontendDomain } from '../../config';
import { CATALOG_IDS, KEYWORD_MATCH_TYPES, NAMESPACE_IDS } from '../types/campaign';
import { ResponseObject } from '../../shared/utilities/fetch-utilities';
import { ProductCampaign } from './campaign';

export interface AdvertisedProducts {
  productsByKey: [ProductsByKey];
  productsByAttributes: ProductsByAttributes;
}

export interface Targeting {
  searchTerms: [SearchTerms];
  crossSell: CrossSell;
  upsell: Upsell;
  excludeFilters: [Filters];
  includeFilters: [Filters];
}

export interface Strategy {
  auction: Auction;
  fixedTenancy: FixedTenancy;
}

export interface ProductsByKey {
  catalogId: string;
  itemId: string;
  sellerId: string;
}

export interface ProductsByAttributes {
  tags: [string];
  priceRange: PriceRange;
}

export interface SearchTerms {
  phrase: string;
  matchType: SearchTermMatchType;
  collectionName: string;
  maxBid: string;
  treatAsCustom: boolean;
}

export interface CatalogCosts {
  catalogId: string;
  catalogCostPercentage: number;
}

export interface FixedCosts {
  dataCost: string;
  creativeCost: string;
  otherCost: string;
}

export interface CrossSell {
  targetProductsByKey: [TargetProductsByKey];
  targetProductsByAttributes: TargetProductsByAttributes;
}

export interface Upsell {
  targetProductsByKey: [TargetProductsByKey];
  targetProductsByAttributes: TargetProductsByAttributes;
}

export interface TargetProductsByKey {
  catalogId: string;
  productCode: string;
  sellerId: string;
}

export interface TargetProductsByAttributes {
  subclassTags: [string];
  vendorTags: [string];
  priceRange: PriceRange;
}

export interface PriceRange {
  min: string;
  max: string;
}

export interface Filters {
  catalogId: string;
  filter: string;
}

export interface Auction {
  maxBid: string;
  spendLimit: SpendLimit;
}

export interface SpendLimit {
  daily: string;
  total: string;
}

export interface FixedTenancy {
  cost: string;
  positions: [number];
}

export interface CustomFields {
  customFieldId: string;
  content: string;
}

export interface CustomQuestions {
  customQuestionId: string;
  answers: [string];
}

export enum ApprovalState {
  unspecified = 'APPROVAL_STATE_UNSPECIFIED'
}

export enum SearchTermMatchType {
  unspecified = 'MATCH_TYPE_UNSPECIFIED',
  exact = 'MATCH_TYPE_EXACT_MATCH'
}

export interface CampaignBuilderRequest {
  productCampaign: ProductCampaign;
  campaignStructure: string;
}

export const createCampaignBuilderRequest = (values: any): CampaignBuilderRequest => {
  const envType = isProductionFrontendDomain() ? 'production' : 'test';

  return {
    productCampaign: {
      name: values.name,
      namespaceId: NAMESPACE_IDS[envType],
      approval: {
        state: 'APPROVAL_STATE_APPROVED'
      },
      campaignState: values.campaignState,
      teamId: values.teamId,
      startTime: values.startDate,
      endTime: values.endDate,
      walletId: values.walletId,
      placementId: values.placementId,
      catalogIds: [CATALOG_IDS[envType]],
      advertisedProducts: {
        productsByKey: values.items.map((product: ProductsByKey) => ({
          productCode: product.itemId,
          catalogId: CATALOG_IDS[envType]
        }))
      },
      targeting: {
        searchTerms: values.keywords?.map((term: any) => ({
          phrase: term.keywordText,
          matchType: KEYWORD_MATCH_TYPES.get('Exact').value,
          maxBid: `${term.bid}`,
          suggested: false,
          treatAsCustom: false
        }))
      },
      strategy: {
        auction: {
          maxBid: `${values.maxBid}`,
          spendLimit: {
            [values.spendLimitType]: `${values.spendLimit}`
          }
        }
      },
      fixedCosts: {},
      customQuestions: [],
      customFields: []
    },
    campaignStructure: values.campaignStructure
  };
};

export const isCampaignCreationSuccessful = (response: ResponseObject) => {
  const { productCampaign } = response.body;
  return response.success && productCampaign?.campaignId != undefined;
};
