interface RequestObject {
  endpoint: string;
  headers?: Headers;
  body?: object;
  authToken?: string;
}

export interface ResponseObject {
  success: boolean;
  body?: any;
  response?: Response;
  errorMessage?: string;
}

const blobContentTypes = ['image/jpeg', 'image/png'];

const getResponse = async (response: Response) => {
  let responseBody = null;

  if (response.body) {
    if (response.headers.get('content-type')?.includes('application/json')) {
      responseBody = await response.json();
    } else if (blobContentTypes.includes(response.headers.get('content-type') || '')) {
      responseBody = await response.blob();
    } else {
      responseBody = await response.text();
    }
  }

  return {
    success: response.ok,
    body: responseBody || response.body,
    response: response,
    errorMessage: !response.ok ? responseBody || response.statusText : response.statusText
  };
};

export const executeGetRequest = async ({
  endpoint,
  authToken,
  headers = new Headers()
}: RequestObject): Promise<ResponseObject> => {
  const bearer = `Bearer ${authToken}`;

  if (!headers.get('Authorization')) {
    headers.append('Authorization', bearer);
  }

  const options = {
    method: 'GET',
    headers
  };

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      console.error(response.statusText);
    }

    return getResponse(response);
  } catch (error: any) {
    console.error(error);

    return {
      success: false,
      errorMessage: error.message
    };
  }
};

export const executePostRequest = async ({
  endpoint,
  body,
  authToken,
  headers = new Headers()
}: RequestObject): Promise<ResponseObject> => {
  headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  });

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      console.error(response.statusText);
    }

    return getResponse(response);
  } catch (error: any) {
    console.error(error);

    return {
      success: false,
      errorMessage: error.message
    };
  }
};

export const executePostWithFormData = async ({
  endpoint,
  body,
  authToken,
  headers = new Headers()
}: RequestObject): Promise<ResponseObject> => {
  headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${authToken}`
  });

  const options = {
    method: 'POST',
    headers,
    body
  };

  try {
    const response = await fetch(endpoint, options as any);

    if (!response.ok) {
      console.error(response.statusText);
    }

    return getResponse(response);
  } catch (error: any) {
    console.error(error);

    return {
      success: false,
      errorMessage: error.message
    };
  }
};

export const executePutRequest = async ({
  endpoint,
  body,
  authToken,
  headers = new Headers()
}: RequestObject): Promise<ResponseObject> => {
  headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  });

  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      console.error(response.statusText);
    }

    return getResponse(response);
  } catch (error: any) {
    console.error(error);

    return {
      success: false,
      errorMessage: error.message
    };
  }
};

export const executePatchRequest = async ({
  endpoint,
  body,
  authToken,
  headers = new Headers()
}: RequestObject): Promise<ResponseObject> => {
  headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  });

  const options = {
    method: 'PATCH',
    headers,
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      console.error(response.statusText);
    }

    return getResponse(response);
  } catch (error: any) {
    console.error(error);

    return {
      success: false,
      errorMessage: error.message
    };
  }
};

export const executeDeleteRequest = async ({
  endpoint,
  body,
  authToken,
  headers = new Headers()
}: RequestObject): Promise<ResponseObject> => {
  headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  });

  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(body)
  };

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      console.error(response.statusText);
    }

    return getResponse(response);
  } catch (error: any) {
    console.error(error);

    return {
      success: false,
      errorMessage: error.message
    };
  }
};

export const getQueryStringFromObject = (data: any) => {
  const searchParams = new URLSearchParams();

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const element = data[key];
      searchParams.append(key, data[key]);
    }
  }

  return searchParams.toString() || '';
};
