import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { chunk, uniq } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CustomTabPanel from '../../../../shared/components/CustomTabPanel';
import MetricsChart from '../../../../shared/components/metrics-chart';
import Page from '../../../../shared/components/page';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';
import useDialog from '../../../../shared/hooks/use-dialog';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT, Filter, Pageable } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import TargetsBulkActions from '../../../components/grid-components/bulk-actions/targets/targets-bulk-actions';
import { createColumns } from '../../../components/grid-components/column-configurations/target-columns';
import { useAmazonApi } from '../../../hooks/use-amazon-api';
import { AdGroup } from '../../../types/ad-group';
import { AmazonCampaignType, Campaign, TargetingTypeValue } from '../../../types/campaign';
import { TargetUpdateDto } from '../../../types/target';
import { AmazonExpressionTypes } from '../../../types/targeting-refinements';
import CreateTargetsDialog from './create-dialogs/create-targets-dialog';
import { OverviewAdGroupNegativeTargets } from './overview-ad-group-negative-targets';
import { OverviewAds } from './overview-ads';
import OverviewAdGroupSearchTermHarvesting from './overview-ad-group-harvesting';

const AdGroupOverview: FC = () => {
  const { getTargets, updateTarget, searchCatalogItems, getAdGroup, getCampaign } = useAmazonApi();

  const { enqueueSnackbar } = useSnackbar();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();
  const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
  const [adGroup, setAdGroup] = useState<AdGroup>({} as AdGroup);
  const [targets, setTargets] = useState<any[]>([]);
  const [productInfo, setProductInfo] = useState<any[] | null>(null);
  const [isLoadingProductInfo, setIsLoadingProductInfo] = useState<boolean>(true);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const { isShowing, toggle } = useDialog();

  const [searchParams, setSearchParams] = useSearchParams();
  let profileId: string | null = searchParams.get('profileId');
  let campaignId: string | null = searchParams.get('campaignId');
  let adGroupId: string | null = searchParams.get('adGroupId');

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_TARGETS,
    breadCrumbParams: { profileId, campaignId, adGroupId }
  });

  // When the page loads, put the scroll at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const [adGroupHasTargets, setAdGroupHasTargets] = useState(false);
  const [campaignTargetTypeIsKeyword, setCampaignTargetTypeIsKeyword] = useState(false);

  enum Action {
    None,
    CreateTargets,
    CreateKeywords
  }

  const [action, setAction] = useState<Action>(Action.None);

  useEffect(() => {
    if (action !== Action.None) {
      toggle();
    }
  }, [action]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateTargetsClick = () => {
    setCampaignTargetTypeIsKeyword(false);
    setAction(Action.CreateTargets);
  };

  const handleCreateKeywordsClick = () => {
    setCampaignTargetTypeIsKeyword(true);
    setAction(Action.CreateKeywords);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    let updateTargetsRequest: TargetUpdateDto = { targetId: newRow.targetId };

    if (newRow.bid !== oldRow.bid) {
      updateTargetsRequest = { ...updateTargetsRequest, bid: newRow.bid };
    }

    if (newRow.state !== oldRow.state) {
      updateTargetsRequest = { ...updateTargetsRequest, state: newRow.state };
    }

    if (updateTargetsRequest.bid == null && updateTargetsRequest.state == null) {
      return oldRow;
    }

    const response = await updateTarget(updateTargetsRequest);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ profileId, campaignId, adGroupId });
  }, [profileId, campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable) {
      return;
    }

    const updatedPageable: Pageable = {
      ...pageable,
      filters: [
        ...(pageable.filters || []).filter((filter) => filter.column !== 'adGroupId'),
        {
          column: 'adGroupId',
          comparator: 'Equals',
          value: adGroupId
        } as unknown as Filter
      ]
    };

    setPageable(updatedPageable);
  }, [adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProductInfo = async () => {
    setIsLoadingProductInfo(true);

    const asins = targets
      .map((target) => {
        return (
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinSameAs.toLowerCase())?.value ??
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinExpandedFrom.toLowerCase())
            ?.value
        );
      })
      .filter((asin: any) => asin);

    const uniqueAsins = uniq(asins);
    if (uniqueAsins.length < 1) {
      setIsLoadingProductInfo(false);
      return;
    }

    const chunkedAsins = chunk(uniqueAsins, 20);

    const requests = chunkedAsins.map((asins) => {
      return searchCatalogItems(asins);
    });

    const responses = await Promise.all(requests);

    const combinedProductInfo = responses.reduce((acc: any, response: any) => {
      return [...acc, ...response.body];
    }, []);

    setProductInfo(combinedProductInfo);
    setIsLoadingProductInfo(false);
  };

  const fetchTargets = async () => {
    if (!pageable) {
      return;
    }

    setIsLoading(true);

    const response = await getTargets(pageable, true, dateSettings.beginDate, dateSettings.endDate);

    if (response.success) {
      setTargets(response.body.dataGrid.records);
      setRowCount(response.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);
      setTimeSeriesData(response.body?.timeSeriesData);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (targets && targets.length > 0) {
      setAdGroupHasTargets(true);
      setCampaignTargetTypeIsKeyword(targets[0]?.targetType === 'Keyword');
    } else {
      setAdGroupHasTargets(false);
    }
  }, [targets]);

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    fetchTargets();
  }, [pageable, dateSettings, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchAdGroup = async () => {
      if (!adGroupId) {
        return;
      }

      const response = await getAdGroup(adGroupId);

      if (response.success) {
        setAdGroup(response.body);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    fetchAdGroup();
  }, [adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchCampaign = async () => {
      if (!campaignId) {
        return;
      }

      const response = await getCampaign(campaignId);

      if (response.success) {
        setCampaign(response.body);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    fetchCampaign();
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (targets?.length < 1) {
      return;
    }

    fetchProductInfo();
  }, [targets]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = React.useMemo(() => {
    return createColumns(productInfo, isLoadingProductInfo);
  }, [productInfo, isLoadingProductInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  const renderCreateTargetsButton = () => {
    if (isLoading || !campaign) {
      return <Skeleton width={210} height={40} />;
    }

    if (campaign?.targetingType?.toLowerCase() === 'auto') {
      return <Box sx={{ height: '40px', visibility: 'hidden' }}></Box>;
    }

    if (adGroupHasTargets) {
      return (
        <Button variant="contained" startIcon={<AddIcon fontSize="small" />} onClick={() => toggle()}>
          Create Targets
        </Button>
      );
    }

    return (
      <Stack direction="row" spacing={2}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateTargetsClick} sx={{ ml: 2 }}>
          Create Targets
        </Button>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateKeywordsClick}>
          Create Keywords
        </Button>
      </Stack>
    );
  };

  const handleToggleCreateTargetsDialog = () => {
    toggle();
    setAction(Action.None);
  };

  const renderAdGroupTargets = () => {
    return (
      <>
        <Box display="flex" alignItems={'center'} my={'1%'}>
          <MetricsChart timeSeriesData={timeSeriesData} isMetricsChartLoading={isLoading} />
        </Box>
        <Box height="600px">
          {!initialSettings || settingsLoading ? (
            <DataGridPremium
              rows={[]}
              columns={columns}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
              key={uuidv4()}
              disableRowGrouping
            />
          ) : (
            <>
              <Box display="flex" alignItems={'center'} sx={{ mb: 2 }}>
                <Box
                  sx={{
                    mr: 'auto'
                  }}
                ></Box>
                {renderCreateTargetsButton()}
              </Box>
              <PerformanceGrid
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={targets}
                columns={columns}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  saveDateConfig: saveDateConfig
                }}
                bottomMargin={30}
                getRowId={(row: any) => row.targetId}
                bulkActions={<TargetsBulkActions setIsLoading={setIsLoading} />}
                handleFilterModelChange={handleFilterModelChange}
                handleSortModelChange={handleSortModelChange}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                rowCount={rowCount}
              />
              {isShowing && (
                <CreateTargetsDialog
                  profileId={profileId}
                  campaignId={campaignId}
                  adGroupId={adGroupId}
                  campaignType={campaign.campaignType}
                  campaignTargetTypeIsKeyword={campaignTargetTypeIsKeyword}
                  isShowing={isShowing}
                  toggle={handleToggleCreateTargetsDialog}
                  refreshTargets={fetchTargets}
                  existingTargets={targets}
                />
              )}
            </>
          )}
        </Box>
      </>
    );
  };

  const renderAdGroupNegativeTargets = () => {
    return <OverviewAdGroupNegativeTargets adGroupId={adGroupId} />;
  };

  const renderAds = () => {
    if (!campaign.campaignType) {
      return <Skeleton variant="rectangular" height={600} />;
    }

    return <OverviewAds campaignType={campaign.campaignType} />;
  };

  const shouldRenderHarvesting = () => {
    return (
      campaign.campaignType === AmazonCampaignType.SPONSORED_PRODUCTS &&
      campaign.targetingType &&
      campaign.targetingType.toUpperCase() !== TargetingTypeValue.AUTO
    );
  };

  const renderHarvesting = () => {
    if (!shouldRenderHarvesting()) {
      return;
    }

    return <OverviewAdGroupSearchTermHarvesting adGroupId={adGroupId} />;
  };

  return (
    <Page title="Targets">
      <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'} justifyContent={'center'}>
            <Typography variant="h6" mr={2}>
              Ad Group: {adGroup?.name}
            </Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTabIndex} onChange={handleChange}>
              <Tab label="Targets" />
              <Tab label="Negative Targets" />
              <Tab label="Ads" />
              {shouldRenderHarvesting() && <Tab label="Harvesting" />}
            </Tabs>
          </Box>
          <CustomTabPanel value={currentTabIndex} index={0}>
            {renderAdGroupTargets()}
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={1}>
            {renderAdGroupNegativeTargets()}
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={2}>
            {renderAds()}
          </CustomTabPanel>
          {shouldRenderHarvesting() && (
            <CustomTabPanel value={currentTabIndex} index={3}>
              {renderHarvesting()}
            </CustomTabPanel>
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default AdGroupOverview;
