import { Skeleton, Box } from '@mui/material';

const ProductTargetLoadingSkeleton = () => {
  return (
    <>
      <Skeleton variant="rectangular" width={40} height={40} sx={{ mr: 1, borderRadius: 1 }} />
      <Box display="flex" flexWrap={'wrap'} maxWidth={200}>
        <Skeleton variant="text" width={250} height={20} />
        <Skeleton variant="text" width={100} height={20} />
      </Box>
    </>
  );
};

export default ProductTargetLoadingSkeleton;
