import { Skeleton, Box, useTheme, ListItemText, Menu, MenuItem, Typography, CircularProgress } from '@mui/material';
import { purple, green } from '@mui/material/colors';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { Chart } from '../../chart';
import '../../../utilities/chart-utilities';
import { abbreviateMetricDisplayName, formatMetricValue } from '../../../utilities/chart-utilities';
import { ArrowDropDownSharp } from '@mui/icons-material';
import { useChartOptions } from './hourly-metrics-chart.utils';
import styles from './hourly-metrics-chart.styles';

interface HourlyMetricsChartProps {
  timeSeriesData: any;
  isLoading: boolean;
}

const HourlyMetricsChart: FC<HourlyMetricsChartProps> = (props) => {
  const { timeSeriesData, isLoading } = props;
  const isFirstRender = useRef(true);

  const [series, setSeries] = useState<any[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([]);
  const { palette } = useTheme();
  const [anchorEls, setAnchorEls] = useState<null[] | HTMLElement[]>([null, null, null, null, null]);
  const [seriesColors, setSeriesColors] = useState<{ [key: string]: string }>({});
  const [boxClickedValue, setBoxClickedValue] = useState<string>('');

  useEffect(() => {
    const currentlyDisplayedSeries = selectedSeries.filter((seriesName) => !hiddenSeries.includes(seriesName));
    const orderedSeries = currentlyDisplayedSeries.map((seriesName, index) => {
      const metric = timeSeriesData?.find((metric: { name: string }) => metric.name === seriesName);
      const opposite = index % 2 === 0;

      return {
        seriesName: metric.name,
        title: { text: metric.displayName },
        opposite,
        labels: {
          formatter: (value: number) => {
            return formatMetricValue(value, true, metric.dataType);
          }
        },
        color: seriesColors[metric.name],
        data: metric.data
      };
    });

    setSeries(orderedSeries);
  }, [selectedSeries, hiddenSeries, timeSeriesData, seriesColors]);

  useEffect(() => {
    const colors = [palette.info.dark, palette.warning.dark, purple[600], green[600], palette.error.light];
    const initialColors: { [key: string]: string } = {};

    if (selectedSeries && selectedSeries.length > 0) {
      const initialSelectedSeries = selectedSeries;
      let newSelectedSeries: string[] = [];
      if (selectedSeries.length < 5) {
        newSelectedSeries = timeSeriesData
          .filter((metric: any) => !initialSelectedSeries.includes(metric.name))
          .map((metric: any) => metric.name)
          .slice(0, 5 - initialSelectedSeries.length);
      }
      setSelectedSeries([...initialSelectedSeries, ...newSelectedSeries]);

      [...initialSelectedSeries, ...newSelectedSeries].forEach((seriesName: string, index: any) => {
        initialColors[seriesName] = colors[index];
      });
      setSeriesColors(initialColors);
    } else if (timeSeriesData && timeSeriesData.length >= 5) {
      const initialSelectedSeries = timeSeriesData.slice(0, 5).map((metric: any) => metric.name);
      setSelectedSeries(initialSelectedSeries);

      initialSelectedSeries.forEach((seriesName: string, index: any) => {
        initialColors[seriesName] = colors[index];
      });
      setSeriesColors(initialColors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSeriesData]);

  useEffect(() => {
    if (isFirstRender.current && selectedSeries.length === 5) {
      setHiddenSeries(selectedSeries.slice(2, 5));
      isFirstRender.current = false;
    }
  }, [selectedSeries]);

  const visibleSeriesColors = selectedSeries
    .filter((series) => !hiddenSeries.includes(series))
    .map((series) => seriesColors[series]);

  const chartOptions = useChartOptions(timeSeriesData, series, visibleSeriesColors);

  const handleSeriesChange = (oldSeriesName: string, newSeriesName: string) => {
    const newSelectedSeries = selectedSeries.map((seriesName) => (seriesName === boxClickedValue ? newSeriesName : seriesName));

    if (hiddenSeries.includes(oldSeriesName)) {
      setHiddenSeries(
        hiddenSeries
          .filter((seriesName) => seriesName !== oldSeriesName)
          .concat([newSeriesName])
          .sort()
      );
    }

    setSelectedSeries(newSelectedSeries);

    const newSeriesColors = { ...seriesColors };
    newSeriesColors[newSeriesName] = newSeriesColors[oldSeriesName];
    delete newSeriesColors[oldSeriesName];

    setSeriesColors(newSeriesColors);

    setAnchorEls((prevAnchorEls: any) =>
      prevAnchorEls.map((_: any, i: any) => (i === selectedSeries.indexOf(boxClickedValue) ? null : _))
    );
  };

  const handleBoxClick = (event: React.MouseEvent<HTMLElement>, clickedBoxValue: string) => {
    const anchorEl = event.currentTarget;
    const index = selectedSeries.indexOf(clickedBoxValue);
    setAnchorEls((prevAnchorEls: any) => prevAnchorEls.map((_: any, i: any) => (i === index ? anchorEl : _)));
    setBoxClickedValue(clickedBoxValue);
  };

  const handleSeriesToggle = (seriesToToggleVisibility: string) => {
    if (hiddenSeries.includes(seriesToToggleVisibility)) {
      setHiddenSeries(hiddenSeries.filter((seriesName) => seriesName !== seriesToToggleVisibility));
    } else {
      setHiddenSeries([...hiddenSeries, seriesToToggleVisibility]);
    }
  };

  return !timeSeriesData ? (
    <Skeleton height={400} width={800} />
  ) : (
    <Box sx={{ opacity: isLoading ? 0.5 : 1, ...styles.chartContainer }}>
      <Box sx={styles.tilesBoxWrapper}>
        {Array.isArray(timeSeriesData) && timeSeriesData.length > 0 ? (
          selectedSeries.map((seriesName, index) => {
            return (
              <Box
                sx={{
                  ...styles.metricBox,
                  ...(hiddenSeries.includes(seriesName)
                    ? styles.metricBoxHidden
                    : { ...styles.metricBoxSelected, borderColor: seriesColors[seriesName] }),
                  pointerEvents: isLoading ? 'none' : 'auto'
                }}
                key={`${seriesName} - ${index}`}
                onClick={() => handleSeriesToggle(seriesName)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                      handleBoxClick(event, seriesName);
                    }}
                    sx={styles.metricDropdown}
                  >
                    <Typography variant="body1" sx={styles.metricBoxTitle}>
                      {abbreviateMetricDisplayName(
                        timeSeriesData?.find((metric: { name: string }) => metric.name === seriesName)?.displayName
                      ) || null}
                    </Typography>{' '}
                    <ArrowDropDownSharp />
                  </Box>
                </Box>
                <Menu
                  anchorEl={anchorEls[index] || null}
                  key={seriesName}
                  open={Boolean(anchorEls[index])}
                  onClose={() =>
                    setAnchorEls((prevAnchorEls: any) => prevAnchorEls.map((_: any, i: any) => (i === index ? null : _)))
                  }
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  PaperProps={{
                    style: {
                      maxHeight: 400
                    }
                  }}
                >
                  {timeSeriesData.map((metric: any) => (
                    <MenuItem
                      key={`${metric.name} - ${seriesName} - ${index}`}
                      value={metric.name}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSeriesChange(seriesName, metric.name);
                      }}
                      disabled={selectedSeries.includes(metric.name)}
                    >
                      <ListItemText primary={metric.displayName} />
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            );
          })
        ) : (
          <Typography variant="body1">No metrics selected</Typography>
        )}
      </Box>
      {isLoading && <CircularProgress color="primary" sx={styles.loadingSpinner} />}
      <Chart
        height={350}
        options={chartOptions}
        series={series?.map((metric: { seriesName: any; data: any }) => {
          return { name: metric.seriesName, data: metric.data };
        })}
        type="area"
        sx={{ alignSelf: 'center', pointerEvents: isLoading ? 'none' : 'auto' }}
      />
    </Box>
  );
};

export default memo(HourlyMetricsChart);
