import PropTypes from 'prop-types';
import { FC, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  TextField,
  Tooltip,
  Collapse,
  Autocomplete
} from '@mui/material';
import AddItemsAlert from '../../../../shared/pages/campaigns/create-campaign-form/add-items-alert';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CampaignItemsTable } from './campaign-items-table';
import { CampaignBuilderStepLabelButton } from './step-label-button';
import { Platform } from '../../../../shared/types/platforms';
import useAdsApi from '../../../../shared/hooks/use-citrusad-api';
import { Product, productDesign } from '../../../types/campaign';

interface CampaignTypeStepProps {
  onBack?: () => void;
  onNext?: () => void;
  formik: any;
  stepNumber: number;
  profileId: string | null;
}

export const ItemSelectionStep: FC<CampaignTypeStepProps> = (props) => {
  const { onBack, onNext, formik, stepNumber, profileId, ...other } = props;
  const { getAllProducts, getProductById, getChildClientIdByTeamId } = useAdsApi(Platform.CITRUS_AD.value);

  const [invalidItemIds, setInvalidItemIds] = useState<string[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [userInputItemIds, setUserInputItemIds] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [itemSearchIsLoading, setItemSearchIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Product[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  //regex creds go to https://stackoverflow.com/questions/56546491/split-string-by-any-3-options-newline-comma-white-space-in-javascript
  const addItems = async () => {
    setItemSearchIsLoading(true);

    const asins = userInputItemIds.replace(/,\s+/g, ',').split(/[\n,\s+]/);
    const newAsins = asins.filter((asin) => !formik.values.items.some((item: any) => item.asin === asin) && asin);

    getItemsByIds(newAsins)
      .then((results: any) => {
        const test = [...selectedItem, ...results];
        const uniqueItems = test.filter((value, index, self) => self.findIndex((item) => item.itemId === value.itemId) === index);
        const newItems = uniqueItems.filter(
          (uniqueItem: any) => !formik.values.items.some((item: any) => item.itemId === uniqueItem.itemId) && uniqueItem.itemId
        );

        formik.setFieldValue('items', [...formik.values.items, ...newItems]);
        setSelectedItem([]);
        setUserInputItemIds('');
        setItemSearchIsLoading(false);
      })
      .catch(() => {
        setItemSearchIsLoading(false);
      });
  };

  const getItemsByIds = (ids: any[]) => {
    return new Promise((res, rej) => {
      if (!ids || ids.length === 0) {
        return res([]);
      }

      Promise.all(ids.map((itemId) => getProductById(itemId)))
        .then((results: any) => {
          const items = [];
          for (let index = 0; index < results.length; index++) {
            const element = results[index].body?.records[0];
            if (element) items.push(element);
          }

          return res(
            items.map((item: any) => {
              return {
                itemId: item.tcin,
                itemName: item.title,
                itemImageUrl: item.mainImageUrl
              };
            })
          );
        })
        .catch((e) => rej(e));
    });
  };

  const handleAutocompleteChange = (event: any, newValue: any) => {
    setSelectedItem(newValue);
  };

  const fetchProducts = async () => {
    if (!profileId) return;
    setItemSearchIsLoading(true);
    const childIdResponse = await getChildClientIdByTeamId(profileId || '');

    if (!childIdResponse.body.childClientId) {
      enqueueSnackbar('Unable to fetch items for this profile. Profile might not yet be associated with a child client id', {
        variant: 'error'
      });
    } else {
      const response = await getAllProducts(childIdResponse.body.childClientId);
      if (response.success) {
        const products = response.body.records.map((product: any) => {
          return {
            itemId: product.tcin,
            itemName: product.title,
            itemImageUrl: product.mainImageUrl
          };
        });
        setProducts(products);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    }

    setItemSearchIsLoading(false);
  };

  const removeItem = (item: any) => {
    setIsAlertOpen(false);
    const filteredItems = formik.values.items.filter((campaignItem: any) => campaignItem.itemId !== item.itemId);
    formik.setFieldValue('items', filteredItems);
  };

  useEffect(() => {
    fetchProducts();
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box {...other} sx={{ mb: showStep ? 10 : 1 }}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Select Items"
      />
      <Collapse in={showStep}>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Box display={'flex'}>
              <Box sx={{ width: '30%', pr: 2, display: 'flex', flexDirection: 'column' }}>
                <Autocomplete
                  sx={{ mb: 1 }}
                  multiple
                  options={products}
                  getOptionLabel={(option) => `${option.itemId} - ${option.itemName}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12px' }}>
                          <span>
                            <b>{option.itemId}</b>
                          </span>
                          <p>{option.itemName}</p>
                        </div>
                        <img style={productDesign.img} src={option.itemImageUrl} alt={option.itemName || ''} />
                      </Box>
                    </li>
                  )}
                  disableCloseOnSelect
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Select item(s)" />}
                  onChange={handleAutocompleteChange}
                  value={selectedItem}
                />
                <TextField
                  id="campaignItemIds"
                  label="Additional items"
                  onChange={(event) => {
                    setUserInputItemIds(event.target.value);
                  }}
                  placeholder="Enter TCINs separated by new lines"
                  multiline
                  value={userInputItemIds}
                  rows={12}
                  fullWidth
                  inputProps={{
                    style: {
                      fontSize: '12px'
                    }
                  }}
                  sx={{ m: 1, mb: 2 }}
                />

                <div style={{ marginTop: 'auto' }}>
                  {!profileId ? (
                    <Tooltip title="Please select a Profile before adding Items">
                      <span>
                        <Button disabled>Add Items</Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <Button
                      sx={{ mt: 2 }}
                      variant="outlined"
                      onClick={addItems}
                      disabled={!(selectedItem.length || userInputItemIds)}
                    >
                      Add Items
                    </Button>
                  )}
                </div>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ width: '70%', pl: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" sx={{ mb: 2 }} onClick={() => formik.setFieldValue('items', [])}>
                    Remove All Items
                  </Button>
                </Box>
                <AddItemsAlert
                  invalidItemIds={invalidItemIds}
                  successfullyAddedItems={formik.values.items}
                  isOpen={isAlertOpen}
                  onClose={() => setIsAlertOpen(false)}
                />
                {!itemSearchIsLoading ? (
                  <CampaignItemsTable formik={formik} removeItem={removeItem} />
                ) : (
                  <Box display={'flex'} alignItems="center" justifyContent={'center'} sx={{ height: '100%' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ mt: 3 }}></Box>
      </Collapse>
    </Box>
  );
};

ItemSelectionStep.propTypes = {
  onBack: PropTypes.func
};
