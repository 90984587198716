export interface BidOptimizationGroupDto {
  id: string;
  profileId: string;
  name: string;
  status: BidOptimizationGroupStatus;
  acosTarget: number;
  campaignIds?: string[];
}

export interface BidOptimizationGroupCreateDto {
  profileId: string;
  name: string;
  status: BidOptimizationGroupStatus;
  acosTarget: number;
  campaignIds?: string[];
}

export enum BidOptimizationGroupStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled'
}

export interface AddCampaignsToBidGroupRequestDto {
  campaignBidOptimizationGroupId: string;
  campaignIds: string[];
}
