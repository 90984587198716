import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import useAdsApi from '../../../../shared/hooks/use-citrusad-api';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';
import useDialog from '../../../../shared/hooks/use-dialog';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { Platform } from '../../../../shared/types/platforms';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { KEYWORD_COLUMNS } from '../../../components/grid-components/column-configurations/keywords-columns';
import { ProductKeywordsDialog } from './create-dialog/product-keywords-dialog';

interface OverviewKeywordsProps {
  campaignId: string | null;
  shouldShowAddAdsButton?: boolean;
}

export const OverviewKeywords = (props: OverviewKeywordsProps) => {
  const { campaignId, shouldShowAddAdsButton } = props;
  const { getCampaignSearchTermMetrics, updateCampaign } = useAdsApi(Platform?.CITRUS_AD?.value);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.CITRUSAD_KEYWORD,
    breadCrumbParams: {}
  });
  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();
  const { pageable, setPageable, handleFilterModelChange, handleSortModelChange, handlePageChange, handlePageSizeChange } =
    usePageable();

  const [keywords, setKeywords] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const { isShowing, toggle } = useDialog();

  const processRowUpdate = useCallback(
    (newRow: any, oldRow: any) => {
      const payload: any = { productCampaign: {} };

      if (newRow['maxBid'] !== oldRow['maxBid']) {
        payload['productCampaign']['targeting'] = {
          searchTerms: keywords.map((keyword: any) => {
            let obj = { phrase: keyword['searchTerm'], matchType: keyword['matchType'], maxBid: keyword['maxBid'] };
            if (keyword.searchTerm == newRow.searchTerm) obj['maxBid'] = newRow['maxBid'];
            return obj;
          })
        };
        payload['mask'] = 'targeting.searchTerms';
      }

      updateCampaign(campaignId || '', payload);
      return newRow;
    },
    [keywords] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchKeywords = async () => {
    if (!campaignId || !pageable || settingsLoading || dateSettingsLoading) {
      return;
    }
    setIsLoading(true);

    const response = await getCampaignSearchTermMetrics(campaignId, pageable, dateSettings.beginDate, dateSettings.endDate);

    if (response.success) {
      setKeywords(response.body.records.filter((e: any) => e.searchTerm != null));
      setRowCount(response.body.totalRecords);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchKeywords();
  }, [campaignId, pageable, dateSettings.beginDate, dateSettings.endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box>
        <Box display="flex" alignItems={'center'}>
          <Typography variant="h5">Keywords </Typography>
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          {true && (
            <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggle()}>
              Add Keywords
            </Button>
          )}
        </Box>

        {!initialSettings || settingsLoading ? (
          <Box display={'flex'} alignItems={'center'} width={'100%'} height={'100%'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <PerformanceGrid
            processRowUpdate={processRowUpdate}
            loading={isLoading}
            rows={keywords}
            columns={KEYWORD_COLUMNS}
            initialState={initialSettings?.config}
            saveGridConfig={saveGridConfig}
            dateConfig={{
              dateSettings: dateSettings,
              dateSettingsLoading: dateSettingsLoading,
              saveDateConfig: saveDateConfig
            }}
            getRowId={(row: any) => row.searchTerm || row.phrase}
            handleFilterModelChange={handleFilterModelChange}
            handleSortModelChange={handleSortModelChange}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
          />
        )}
        {isShowing && (
          <ProductKeywordsDialog
            isShowing={isShowing}
            keywords={keywords}
            campaignId={campaignId}
            toggle={toggle}
            refreshKeywords={fetchKeywords}
          />
        )}
      </Box>
    </>
  );
};
