import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, CardContent, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ItemCarousel from './item-carousel';
import AmazonAddKeywords from '../../../components/amazon-add-keywords';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { AsinKeywordRecommendationRequest } from '../../../types/keyword';

interface KeywordSettingsProps {
  itemStepType: string;
  profileId: string | null;
  handleItemStepTypeChange: (event: React.MouseEvent<HTMLElement>, itemStepType: string) => void;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  disableItemCarousel?: boolean;
}

export const KeywordSettings = (props: KeywordSettingsProps) => {
  const { itemStepType, handleItemStepTypeChange, values, setFieldValue, profileId, disableItemCarousel } = props;
  const { getAsinKeywordRecommendations } = useAmazonApi();
  const [suggestedKeywords, setSuggestedKeywords] = useState<any[]>([]);
  const [suggestionSort, setSuggestionSort] = useState<'CONVERSIONS' | 'CLICKS'>('CONVERSIONS');
  const [isLoading, setIsLoading] = useState(false);

  const fetchSuggestedKeywords = async () => {
    setIsLoading(true);
    const asins = values.items.map((item: any) => item.asin);

    if (!profileId || asins.length < 1) {
      setIsLoading(false);
      return;
    }

    const userSetDynamicBidding = values?.amazonSponsoredProductsDynamicBidding?.strategy;

    const dynamicBiddingMapping: Record<string, string> = {
      DownOnly: 'LEGACY_FOR_SALES',
      UpAndDown: 'AUTO_FOR_SALES',
      Fixed: 'MANUAL'
    };

    const request: AsinKeywordRecommendationRequest = {
      maxRecommendations: 200,
      asins: asins,
      sortDimension: suggestionSort,
      dynamicBidding: userSetDynamicBidding ? dynamicBiddingMapping[userSetDynamicBidding] : 'AUTO_FOR_SALES'
    };

    const response = await getAsinKeywordRecommendations(profileId, request);

    if (response?.body?.keywordTargetList) {
      setSuggestedKeywords(response.body.keywordTargetList);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSuggestedKeywords();
  }, [profileId, values.items, suggestionSort]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Keywords
        </Typography>
        <Box>
          <ToggleButtonGroup exclusive value={itemStepType} onChange={handleItemStepTypeChange}>
            <ToggleButton value="allItems" aria-label="All Items">
              <Typography variant="body2" sx={{ mr: 2 }}>
                Add Keywords for All Items
              </Typography>
              <Tooltip title="Any keyword added here will be applied to all items.">
                <InfoIcon color="info" />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="itemCarousel" aria-label="Item Carousel" disabled={disableItemCarousel === true}>
              <Typography variant="body2" sx={{ mr: 2 }}>
                Add Keywords by Item (Item Carousel)
              </Typography>
              <Tooltip title="Any keyword added here will be applied the current item only.">
                <InfoIcon color="info" />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ display: itemStepType === 'allItems' ? '' : 'none' }}>
          <AmazonAddKeywords
            suggestedKeywords={suggestedKeywords}
            keywords={values.universalKeywords}
            setKeywords={(keywords: string[]): void => {
              setFieldValue('universalKeywords', keywords);
            }}
            suggestionSort={suggestionSort}
            setSuggestionSort={(sort: 'CONVERSIONS' | 'CLICKS'): void => {
              setSuggestionSort(sort);
            }}
            isLoading={isLoading}
          />
        </Box>
        <Box sx={{ display: itemStepType === 'itemCarousel' ? '' : 'none' }}>
          <ItemCarousel items={values?.items} setFieldValue={setFieldValue} />
        </Box>
      </CardContent>
    </Card>
  );
};
