import EditIcon from '@mui/icons-material/Edit';
import { Badge, BadgeProps, Box, Chip, IconButton, Tooltip, styled } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import useDialog from '../../../../../shared/hooks/use-dialog';
import {
  BiddingStrategies,
  AmazonSponsoredProductsDynamicBidding,
  BiddablePlacements,
  StrategyType,
  defaultDynamicBidding,
  defaultPlacementBidding
} from '../../../../types/campaign';
import { DynamicBiddingDialog } from './dynamic-bidding-dialog';
import BlockIcon from '@mui/icons-material/Block';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}));

export const renderDynamicBidding = (params: GridCellParams) => {
  return <DynamicBiddingActionCell {...params} />;
};

export const DynamicBiddingActionCell = (params: GridCellParams) => {
  const { toggle: toggleDynamicBiddingDialog, isShowing: dynamicBiddingDialogIsShowing } = useDialog();

  const [isHovering, setIsHovering] = useState(false);
  const [amazonSponsoredProductsDynamicBidding, setAmazonSponsoredProductsDynamicBidding] =
    useState<AmazonSponsoredProductsDynamicBidding>(defaultDynamicBidding);

  useEffect(() => {
    if (!params.value) {
      return;
    }

    const dynamicBiddingFromParams = params.value as {
      strategy: string;
      placementBidding: { placement: string; percentage: number }[];
    };

    const updatedPlacementBidding = { ...defaultPlacementBidding };

    const placementBidding = dynamicBiddingFromParams?.placementBidding as { placement: string; percentage: number }[];
    placementBidding?.map((placement) => {
      if (placement.placement === BiddablePlacements.TopOfSearch.value) {
        updatedPlacementBidding.TopOfSearch = placement.percentage;
      } else if (placement.placement === BiddablePlacements.ProductPages.value) {
        updatedPlacementBidding.ProductPages = placement.percentage;
      }
    });

    setAmazonSponsoredProductsDynamicBidding({
      strategy: (dynamicBiddingFromParams?.strategy as StrategyType) || BiddingStrategies.DownOnly.value,
      placementBidding: updatedPlacementBidding
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value]);

  const renderPlacementBiddingTooltip = () => {
    if (
      amazonSponsoredProductsDynamicBidding.placementBidding.TopOfSearch === 0 &&
      amazonSponsoredProductsDynamicBidding.placementBidding.ProductPages === 0
    ) {
      return '';
    }

    if (
      amazonSponsoredProductsDynamicBidding.placementBidding.TopOfSearch !== 0 &&
      amazonSponsoredProductsDynamicBidding.placementBidding.ProductPages !== 0
    ) {
      return `${BiddablePlacements.TopOfSearch.label}: ${amazonSponsoredProductsDynamicBidding.placementBidding.TopOfSearch}% | ${BiddablePlacements.ProductPages.label} ${amazonSponsoredProductsDynamicBidding.placementBidding.ProductPages}%`;
    }

    if (amazonSponsoredProductsDynamicBidding.placementBidding.TopOfSearch !== 0) {
      return `${BiddablePlacements.TopOfSearch.label}: ${amazonSponsoredProductsDynamicBidding.placementBidding.TopOfSearch}%`;
    }

    if (amazonSponsoredProductsDynamicBidding.placementBidding.ProductPages !== 0) {
      return `${BiddablePlacements.ProductPages.label}: ${amazonSponsoredProductsDynamicBidding.placementBidding.ProductPages}%`;
    }
  };

  const renderDynamicBiddingValue = () => {
    const numberOfPlacementsOverZero = Object.values(amazonSponsoredProductsDynamicBidding.placementBidding).filter(
      (percentage) => percentage > 0
    );

    // If this isn't a sponsored product campaign, don't render anything
    if (!params.value) {
      return (
        <Tooltip title={'Dynamic Bidding only Available for SP Campaigns'} placement="top">
          <BlockIcon color={'disabled'} fontSize="small" />
        </Tooltip>
      );
    }

    return (
      <Tooltip title={renderPlacementBiddingTooltip()} placement="top">
        <StyledBadge badgeContent={numberOfPlacementsOverZero.length} color="primary">
          <Chip
            label={
              BiddingStrategies[amazonSponsoredProductsDynamicBidding.strategy as keyof typeof BiddingStrategies]
                ?.abbreviatedLabel
            }
            variant="outlined"
            size="small"
          />
        </StyledBadge>
      </Tooltip>
    );
  };

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        width: '100%',
        height: '100%',
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whitespace: 'nowrap',
          color: 'text.primary',
          width: '100%'
        }}
      >
        {renderDynamicBiddingValue()}
      </Box>

      {isHovering &&
        !!params.value && ( // If the campaign is not a sponsored product campaign, don't render the edit icon
          <Box>
            <IconButton onClick={() => toggleDynamicBiddingDialog()} color="primary">
              <EditIcon />
            </IconButton>
          </Box>
        )}
      {dynamicBiddingDialogIsShowing && (
        <DynamicBiddingDialog
          isOpen={dynamicBiddingDialogIsShowing}
          toggle={toggleDynamicBiddingDialog}
          campaignId={params.row.campaignId}
          campaignName={params.row.name}
          amazonSponsoredProductsDynamicBidding={amazonSponsoredProductsDynamicBidding}
          setAmazonSponsoredProductsDynamicBidding={setAmazonSponsoredProductsDynamicBidding}
        />
      )}
    </Box>
  );
};
