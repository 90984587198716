import type { ApexOptions } from 'apexcharts';
import { Box, Skeleton, useTheme } from '@mui/material';
import { memo, useState } from 'react';
import { useEffect } from 'react';

import { Chart } from './chart';
import { useBarChartOptions } from '../hooks/use-chart-options';
import { useAreaChartOptions } from '../hooks/use-chart-options';

const PLACEMENT_ORDER: { [placement: string]: number } = {
  TopOfSearch: 0,
  RestOfSearch: 1,
  ProductPages: 2,
  OffSite: 3
};

const formatPlacementName = (placement: string): string => {
  const placementMap: Record<string, string> = {
    TopOfSearch: 'Top Of Search',
    RestOfSearch: 'Rest Of Search',
    ProductPages: 'Product Pages',
    OffSite: 'Off Site'
  };

  return placementMap[placement] || placement;
};

const renderSkeleton = (height: number, theme: any) => (
  <Skeleton
    variant="rectangular"
    height={height}
    sx={{
      bgcolor: 'background.paper',
      borderRadius: '0.5rem',
      border: `1px solid ${theme.palette.divider}`
    }}
  />
);

const renderChart = (options: ApexOptions, series: any[], type: any, height: any, width: string, isArea: boolean, theme: any) => (
  <Chart
    options={options}
    series={isArea ? series : [{ data: series }]}
    type={type}
    height={height}
    width={width}
    sx={{
      bgcolor: 'background.paper',
      p: '0.5rem',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.5rem'
    }}
  />
);

interface TimeSeriesChartProps {
  timeSeriesData: any;
}

const PlacementPerformanceChart = ({ timeSeriesData }: TimeSeriesChartProps) => {
  const [areaSeries, setAreaSeries] = useState<any[]>([]);
  const [roasSeries, setRoasSeries] = useState<any[]>([]);
  const [convRateSeries, setConvRateSeries] = useState<any[]>([]);
  const [percentOfSpendSeries, setPercentOfSpendSeries] = useState<any[]>([]);
  const theme = useTheme();

  useEffect(() => {
    if (!timeSeriesData.series || timeSeriesData.series.length === 0) {
      return;
    }

    const sortedSeries = timeSeriesData.series.sort((a: any, b: any) => {
      return PLACEMENT_ORDER[a.name] - PLACEMENT_ORDER[b.name];
    });

    setAreaSeries(
      sortedSeries.map((metric: { name: string; data: any }, index: any) => ({
        name: formatPlacementName(metric.name),
        opposite: index % 2 === 0,
        data: metric.data ? metric.data.map((value: any) => (value * 100).toFixed(2)) : []
      }))
    );

    setConvRateSeries(
      sortedSeries?.map((metric: any) => ({
        x: metric.name,
        y: metric.conversionRate ? metric.conversionRate * 100 : 0
      }))
    );

    setRoasSeries(
      sortedSeries?.map((metric: any) => ({
        x: metric.name,
        y: metric.roasByPlacement ? metric.roasByPlacement : 0
      }))
    );

    setPercentOfSpendSeries(
      sortedSeries?.map((metric: any) => ({
        x: metric.name,
        y: metric.percentOfSpendByPlacement ? metric.percentOfSpendByPlacement * 100 : 0
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSeriesData]);

  const timeSeriesChartOptions = useAreaChartOptions(timeSeriesData, areaSeries);
  const convRateChartOptions = useBarChartOptions('', '%', 'Conversion Rate by Placement');
  const percentOfSpendChartOptions = useBarChartOptions('', '%', '% of Spend by Placement');
  const roasChartOptions = useBarChartOptions('$', '', 'ROAS by Placement');

  return !timeSeriesData.series ? (
    <Skeleton height={150} width={'100%'} />
  ) : (
    <Box sx={{ width: '100%', bgcolor: 'transparent', my: '1%', alignItems: 'center' }}>
      <Box display="flex" justifyContent="space-between" alignContent={'center'} gap={'1rem'}>
        <Box display="flex" flexDirection="column" width={'50%'} justifyContent="space-between" gap={'0.5rem'}>
          {convRateSeries.length === 0
            ? renderSkeleton(150, theme)
            : renderChart(convRateChartOptions, convRateSeries, 'bar', 200, '100%', false, theme)}
          {roasSeries.length === 0
            ? renderSkeleton(150, theme)
            : renderChart(roasChartOptions, roasSeries, 'bar', 200, '100%', false, theme)}
          {percentOfSpendSeries.length === 0
            ? renderSkeleton(150, theme)
            : renderChart(percentOfSpendChartOptions, percentOfSpendSeries, 'bar', 200, '100%', false, theme)}
        </Box>
        <Box display="flex" flexDirection="column" width={'100%'}>
          {areaSeries.length === 0
            ? renderSkeleton(350, theme)
            : renderChart(timeSeriesChartOptions, areaSeries, 'area', '100%', '100%', true, theme)}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PlacementPerformanceChart);
