import { DaypartingSchedule, AmazonDaypartingStates } from '../shared/types/day-parting';
import { ActionParameters, ScheduleItem } from './types/dayparting-parameters';

const DAYS_IN_WEEK = 7;
const HOURS_IN_DAY_STARTING_AT_ZERO = 23;
const DAYS_OF_WEEK = [0, 1, 2, 3, 4, 5, 6];

interface AutomatedActionObject {
  id?: string;
  profileId: string;
  filterCriteria: any;
  entityType: string;
  actionType: string;
  actionParameters: any;
  executionScheduleCron: string;
  name: string;
}

export const getAutomatedActionFromDaypartingSchedule = (
  schedule: DaypartingSchedule,
  campaignId: string,
  profileId: string,
  automatedActionId?: string
) => {
  const actionParameters = convertScheduleToActionParameters(schedule);
  const date = new Date();

  const automatedActionObject: AutomatedActionObject = {
    id: automatedActionId,
    profileId: profileId,
    filterCriteria: JSON.stringify([
      {
        column: 'CampaignId',
        comparator: 'Equals',
        value: campaignId
      }
    ]),
    entityType: 'Campaign',
    actionType: 'Dayparting',
    actionParameters: JSON.stringify(actionParameters),
    executionScheduleCron: '*/20 * * * *',
    name: `DaypartingAction${campaignId}${date.getTime()}`
  };

  return automatedActionObject;
};

function convertScheduleToActionParameters(schedule: DaypartingSchedule): ActionParameters {
  const actionParameters: ActionParameters = initializeActionParameters();

  DAYS_OF_WEEK.forEach((day) => {
    const daySchedule = schedule[day];

    for (let hour = 0; hour <= HOURS_IN_DAY_STARTING_AT_ZERO; hour++) {
      if (daySchedule[hour] === AmazonDaypartingStates.Enabled) {
        continue;
      } else {
        let startOfRange = { hour, minute: 0 };
        let endOfRange = { hour: hour, minute: 59 };
        while (
          endOfRange.hour < HOURS_IN_DAY_STARTING_AT_ZERO &&
          daySchedule[endOfRange.hour + 1] === AmazonDaypartingStates.Disabled
        ) {
          endOfRange.hour++;
        }

        let dayOfWeek = '';
        switch (day) {
          case 0:
            dayOfWeek = 'Sunday';
            break;
          case 1:
            dayOfWeek = 'Monday';
            break;
          case 2:
            dayOfWeek = 'Tuesday';
            break;
          case 3:
            dayOfWeek = 'Wednesday';
            break;
          case 4:
            dayOfWeek = 'Thursday';
            break;
          case 5:
            dayOfWeek = 'Friday';
            break;
          case 6:
            dayOfWeek = 'Saturday';
            break;
        }

        const scheduleItem: ScheduleItem = {
          Begin: `${startOfRange.hour.toString().padStart(2, '0')}:${startOfRange.minute.toString().padStart(2, '0')}:00`,
          End: `${endOfRange.hour.toString().padStart(2, '0')}:${endOfRange.minute.toString().padStart(2, '0')}:59`
        };

        actionParameters.Schedule[dayOfWeek as keyof typeof actionParameters.Schedule].push(scheduleItem);

        hour = endOfRange.hour;
      }
    }
  });

  return actionParameters;
}

function initializeActionParameters(): ActionParameters {
  return {
    Timezone: 'Central Standard Time', //TODO: Make this dynamically set based on the user's timezone choice.
    Schedule: {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: []
    }
  };
}

export const getDaypartingSchedule = (actionParameters: any) => {
  if (!actionParameters) {
    return {
      0: {},
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {}
    };
  }

  const schedule = convertActionParametersToSchedule(actionParameters);
  return schedule;
};

function convertActionParametersToSchedule(actionParameters: any) {
  const schedule = createEmptySchedule();
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const daysOfWeekNumber: { [key: string]: number } = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };

  daysOfWeek.forEach((day) => {
    const daySchedule = actionParameters.Schedule[day];
    daySchedule.forEach((scheduleItem: ScheduleItem) => {
      const startHour = parseInt(scheduleItem.Begin.split(':')[0]);
      const endHour = parseInt(scheduleItem.End.split(':')[0]);

      for (let hour = startHour; hour <= endHour; hour++) {
        schedule[daysOfWeekNumber[day]][hour] = AmazonDaypartingStates.Disabled;
      }
    });
  });

  return schedule;
}

export const createEmptySchedule = () => {
  const schedule: DaypartingSchedule = {};
  for (let day = 0; day < DAYS_IN_WEEK; day++) {
    schedule[day] = {};
    for (let hour = 0; hour <= HOURS_IN_DAY_STARTING_AT_ZERO; hour++) {
      schedule[day][hour] = AmazonDaypartingStates.Enabled;
    }
  }
  return schedule;
};
