/* eslint-disable react-hooks/exhaustive-deps */
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { Group } from '@microsoft/microsoft-graph-types';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { MICROSOFT_GRAPH_SCOPE, msalConfig } from '../../config';
import type { User } from '../../walmart/types/user';
import { getUserPhoto, getUserSecurityGroups } from '../utilities/microsoft-graph-utilities';

interface AuthResult {
  isAuthenticated: boolean;
  user: User | null;
}

interface State extends AuthResult {
  isInitialized: boolean;
}

export interface AuthContextValue extends State {
  platform: 'MSAL';
  login: () => Promise<void>;
  logout: () => Promise<void>;
  acquireToken: (scopes: string[]) => Promise<AuthenticationResult | null>;
}

interface AuthProviderProps {
  children: ReactNode;
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: ActionType.LOGIN;
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: ActionType.LOGOUT;
};

type Action = InitializeAction | LoginAction | LogoutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'MSAL',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  acquireToken: () => Promise.resolve(null)
});

const msalInstance = new PublicClientApplication(msalConfig);

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const authenticationResult = await getAuthenticationResult();

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            ...authenticationResult
          }
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const getAuthenticationResult = async (): Promise<AuthResult> => {
    try {
      const msGraphAuthResult = await acquireToken([MICROSOFT_GRAPH_SCOPE]);

      if (!msGraphAuthResult?.accessToken) {
        return {
          isAuthenticated: false,
          user: null
        };
      }

      const user: User = await getUserInfo(msGraphAuthResult);

      return {
        isAuthenticated: true,
        user
      };
    } catch (error: any) {
      if (error instanceof InteractionRequiredAuthError) {
        login();
      }

      return {
        isAuthenticated: false,
        user: null
      };
    }
  };

  const getUserInfo = async (authenticationResult: AuthenticationResult) => {
    const [userPhotoResult, securityGroupResult] = await Promise.allSettled([
      getUserPhoto(authenticationResult.accessToken),
      getUserSecurityGroups(authenticationResult.accessToken)
    ]);

    let profilePhotoUrl = '';

    if (userPhotoResult?.status === 'fulfilled' && userPhotoResult?.value?.success) {
      profilePhotoUrl = URL.createObjectURL(userPhotoResult.value.body as Blob);
    }

    const securityGroups: Group[] = securityGroupResult?.status === 'fulfilled' ? securityGroupResult.value : [];

    return {
      name: authenticationResult?.account?.name || '',
      email: authenticationResult?.account?.username || '',
      graphToken: authenticationResult.accessToken,
      profilePhotoUrl,
      securityGroups
    };
  };

  const acquireToken = async (scopes: string[]): Promise<AuthenticationResult | null> => {
    const account = msalInstance.getAllAccounts()[0];

    if (!account) {
      return null;
    }

    const silentRequest: SilentRequest = {
      scopes: scopes,
      account: account
    };

    try {
      return msalInstance.acquireTokenSilent(silentRequest);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        login();
      }

      console.error(error);
      return null;
    }
  };

  const login = async (): Promise<void> => {
    await msalInstance.initialize();
    await msalInstance.handleRedirectPromise();

    const authenticationResult = await getAuthenticationResult();

    const redirectRequest: RedirectRequest = {
      scopes: [MICROSOFT_GRAPH_SCOPE],
      onRedirectNavigate: () => navigate(window.location.pathname)
    };

    if (!authenticationResult?.isAuthenticated || !authenticationResult?.user) {
      msalInstance.loginRedirect(redirectRequest);
      return;
    }

    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user: authenticationResult.user
      }
    });
  };

  const logout = async (): Promise<void> => {
    msalInstance.logoutRedirect();

    dispatch({ type: ActionType.LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'MSAL',
        login,
        logout,
        acquireToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
