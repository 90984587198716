import { GridColDef, getGridStringOperators } from '@mui/x-data-grid-premium';
import { renderState } from '../../components/grid-components/renderer/renderState';
import { DEFAULT_STATE_OPTIONS } from '../../types/state-options';
import { RenderDeltaCell } from './render-bid-group-bid-delta';

export const BID_GROUP_TARGET_HISTORY_COLUMNS: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 200,
    type: 'dateTime',
    valueFormatter: (params) => new Date(params.value as string).toLocaleString()
  },
  {
    field: 'campaignName',
    headerName: 'Campaign Name',
    width: 200
  },
  {
    field: 'campaignState',
    headerName: 'Campaign State',
    type: 'singleSelect',
    valueOptions: [...DEFAULT_STATE_OPTIONS.map((option) => option.label)],
    renderCell: renderState({ stateOptions: DEFAULT_STATE_OPTIONS }),
    width: 140,
    minWidth: 120
  },
  {
    field: 'target',
    headerName: 'Target',
    width: 150,
    minWidth: 120
  },
  {
    field: 'acosTargetPercentage',
    headerName: 'Target ACoS',
    width: 120,
    minWidth: 100,
    valueGetter: (params) => (params.row.acosTargetPercentage ? `${params.row.acosTargetPercentage}%` : 'Unavailable')
  },
  {
    field: 'oldBid',
    headerName: 'Old Bid',
    width: 100,
    minWidth: 80,
    renderCell: (params) => `$${params.row.oldBid.toFixed(2)}`
  },
  {
    field: 'newBid',
    headerName: 'New Bid',
    width: 100,
    minWidth: 80,
    renderCell: (params) => `$${params.row.newBid.toFixed(2)}`
  },
  {
    field: 'deltaPercentage',
    headerName: '𝚫%',
    width: 100,
    minWidth: 80,
    align: 'right',
    renderCell: (params) => <RenderDeltaCell {...params} />,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
  },
  {
    field: 'reason',
    headerName: 'Reason for Change',
    width: 200
  },
  {
    field: 'targetId',
    headerName: 'Target ID',
    width: 200,
    minWidth: 120
  },
  {
    field: 'id',
    headerName: 'Log ID',
    width: 200
  },
  {
    field: 'campaignBidOptimizationGroupId',
    headerName: 'Bid Group ID',
    width: 200
  }
];
