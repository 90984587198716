import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ArchiveIcon from '@mui/icons-material/Archive';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { State } from './campaign-builder-request';
import { hasValue } from '../../shared/utilities/general-utilities';
import { convertDateToYearMonthDay } from '../../shared/utilities/date-utilities';

export const CAMPAIGN_STATE_OPTIONS = {
  ENABLED: { value: 'enabled', label: 'Enabled', icon: PlayArrowIcon },
  PAUSED: { value: 'paused', label: 'Paused', icon: PauseIcon },
  ARCHIVED: { value: 'archived', label: 'Archived', icon: ArchiveIcon },
  ENABLING: { value: 'ENABLED', label: 'Enabling', icon: HourglassEmptyIcon },
  USER_DELETED: { value: 'USER_DELETED', label: 'User deleted', icon: DeleteIcon },
  OTHER: { value: 'OTHER', label: 'Other', icon: HelpOutlineIcon }
} as const;

export enum CampaignState {
  Enabled = 'Enabled',
  Paused = 'Paused',
  Archived = 'Archived'
}

export const BiddingStrategies = {
  DownOnly: {
    value: 'DownOnly',
    label: 'Dynamic bids - down only',
    abbreviatedLabel: 'Down only'
  },
  UpAndDown: {
    value: 'UpAndDown',
    label: 'Dynamic bids - up and down',
    abbreviatedLabel: 'Up and down'
  },
  Fixed: {
    value: 'Fixed',
    label: 'Fixed bids',
    abbreviatedLabel: 'Fixed'
  },
  RuleBased: {
    value: 'RuleBased',
    label: 'Rule Based bids',
    abbreviatedLabel: 'Rule Based'
  }
} as const;

export const BiddablePlacements = {
  TopOfSearch: {
    value: 'TopOfSearch',
    label: 'Top of search (first page)'
  },
  RestOfSearch: {
    value: 'RestOfSearch',
    label: 'Rest of search'
  },
  ProductPages: {
    value: 'ProductPages',
    label: 'Product pages'
  }
} as const;

export const NonbiddablePlacements = {
  OffSite: {
    value: 'OffSite',
    label: 'Offsite'
  }
} as const;

export const PlacementOptions = {
  ...BiddablePlacements,
  ...NonbiddablePlacements
} as const;

export type BiddingStrategy = keyof typeof BiddingStrategies;

export type BiddablePlacementType = (typeof BiddablePlacements)[keyof typeof BiddablePlacements]['value'];
export type StrategyType = (typeof BiddingStrategies)[keyof typeof BiddingStrategies]['value'];

export interface PlacementBidding {
  TopOfSearch: number;
  ProductPages: number;
  RestOfSearch: number;
}

export interface AmazonSponsoredProductsDynamicBidding {
  strategy: StrategyType;
  placementBidding: PlacementBidding;
}

export const defaultPlacementBidding: PlacementBidding = {
  TopOfSearch: 0,
  ProductPages: 0,
  RestOfSearch: 0
};

export const defaultDynamicBidding: AmazonSponsoredProductsDynamicBidding = {
  strategy: BiddingStrategies.DownOnly.value,
  placementBidding: defaultPlacementBidding
};

export interface BackendDynamicBidding {
  strategy: StrategyType;
  placementBidding: BackendPlacementBidding[];
}
export interface BackendPlacementBidding {
  placement: string;
  percentage: number;
}

export const TransformDynamicBidding = {
  toFrontend(backendModel: BackendDynamicBidding): AmazonSponsoredProductsDynamicBidding {
    let placementBidding: PlacementBidding = defaultPlacementBidding;

    if (backendModel === undefined || backendModel?.placementBidding === undefined) {
      return defaultDynamicBidding;
    }

    if (backendModel.placementBidding !== undefined && backendModel.placementBidding?.length > 0) {
      placementBidding = backendModel.placementBidding.reduce((acc, curr) => {
        acc[curr.placement as BiddablePlacementType] = curr.percentage;
        return acc;
      }, {} as PlacementBidding);
    }

    return {
      strategy: backendModel.strategy as StrategyType,
      placementBidding: placementBidding
    };
  },

  toBackend(frontendModel: AmazonSponsoredProductsDynamicBidding): BackendDynamicBidding {
    return {
      strategy: frontendModel.strategy,
      placementBidding: Object.entries(frontendModel.placementBidding).map(([placement, percentage]) => ({
        placement: placement as BiddablePlacementType,
        percentage
      }))
    };
  }
};

export const CAMPAIGN_TYPE_OPTIONS = [
  { value: 'sponsoredProduct', abbreviation: 'SP' },
  { value: 'sponsoredBrand', abbreviation: 'SB' },
  { value: 'sponsoredBrandVideo', abbreviation: 'SBV' },
  { value: 'sponsoredDisplay', abbreviation: 'SD' }
];

export type AmazonCampaignTypeValue = (typeof AmazonCampaignType)[keyof typeof AmazonCampaignType];

export enum AmazonCampaignType {
  SPONSORED_PRODUCTS = 'SponsoredProduct',
  SPONSORED_BRANDS = 'SponsoredBrand',
  SPONSORED_DISPLAY = 'SponsoredDisplay'
}

export enum TargetingTypeValue {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO'
}

export type TargetingType = keyof typeof TargetingTypeValue;

export const TARGETING_TYPE_OPTIONS = {
  [TargetingTypeValue.MANUAL]: { value: TargetingTypeValue.MANUAL, label: 'Manual' },
  [TargetingTypeValue.AUTO]: { value: TargetingTypeValue.AUTO, label: 'Auto' }
} as const;

export const enum ContentSteps {
  Create = 0,
  Error = 1,
  Complete = 2
}

export const CampaignStrategyOptions = {
  Branded: {
    value: 'Branded',
    label: 'Branded'
  },
  Nonbranded: {
    value: 'Nonbranded',
    label: 'Nonbranded'
  },
  Discovery: {
    value: 'Discovery',
    label: 'Discovery'
  },
  Conquest: {
    value: 'Conquest',
    label: 'Conquest'
  }
} as const;

export type CampaignStrategyType = keyof typeof CampaignStrategyOptions;

export interface Campaign {
  profileId: string;
  campaignId: string;
  startDate: string;
  endDate: string;
  name: string;
  state: State;
  status?: string;
  campaignType?: AmazonCampaignTypeValue;
  targetingType: TargetingType;
  dailyBudget?: number;
  budget: {
    budgetType: 'DAILY';
    budget: number;
  };
  amazonSponsoredProductsDynamicBidding: BackendDynamicBidding;
}

export const initialCampaign: Campaign = {
  profileId: '',
  campaignId: '',
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  name: '',
  state: State.ENABLED,
  targetingType: TargetingTypeValue.MANUAL,
  budget: {
    budgetType: 'DAILY',
    budget: 0
  },
  amazonSponsoredProductsDynamicBidding: {
    strategy: BiddingStrategies.DownOnly.value,
    placementBidding: [
      {
        placement: BiddablePlacements.TopOfSearch.value,
        percentage: 0
      }
    ]
  }
};

export interface UpdateCampaignRequest {
  campaignId: string;
  isActiveInVector?: boolean;
  name?: string;
  state?: string;
  startDate?: string | undefined | null;
  endDate?: string | undefined | null;
  dailyBudget?: number;
  amazonSponsoredProductsDynamicBidding?: BackendDynamicBidding;
}

export const getUpdateCampaignRequest = (newRow: any, oldRow?: any): UpdateCampaignRequest | null => {
  const campaign = newRow && oldRow ? getUpdatedCampaignFields(oldRow, newRow) : newRow;

  // If there is only one key it is the campaignId and nothing has changed
  if (Object.keys(campaign).length === 1) {
    return null;
  }

  if (campaign?.startDate) {
    const startDate = new Date(campaign.startDate);

    campaign.startDate = convertDateToYearMonthDay(startDate);
  }

  if (campaign?.endDate) {
    const endDate = new Date(campaign.endDate);

    campaign.endDate = convertDateToYearMonthDay(endDate);
  }

  // Only include fields that exist on the mutated object
  return {
    campaignId: campaign.campaignId,
    ...(hasValue(campaign?.name) && { name: campaign.name }),
    ...(hasValue(campaign?.startDate) && { startDate: campaign.startDate }),
    ...(hasValue(campaign?.endDate) && { endDate: campaign.endDate }),
    ...(hasValue(campaign?.dailyBudget) && { dailyBudget: campaign.dailyBudget })
  };
};

const getUpdatedCampaignFields = (oldRow: any, newRow: any): UpdateCampaignRequest => {
  // Only include the fields on the object that have changed
  return {
    campaignId: oldRow.campaignId,
    ...(oldRow.name !== newRow.name && { name: newRow.name }),
    ...(oldRow.startDate !== newRow.startDate && { startDate: newRow.startDate }),
    ...(oldRow.endDate !== newRow.endDate && { endDate: newRow.endDate }),
    ...(oldRow.dailyBudget !== newRow.dailyBudget && { dailyBudget: newRow.dailyBudget })
  };
};

export interface SponsoredBrandsCreative {
  asins: string[];
  customImageCrop: any;
  brandLogoCrop: any;
  brandName: string;
  customImageAssetId: string;
  brandLogoAssetId: string;
  headline: string;
}

export interface SponsoredBrandsLandingPage {
  url: string;
  pageType: string;
  asins: string[];
}

export interface SponsoredBrandsSecondaryProperties {
  creative: SponsoredBrandsCreative;
  landingPage: SponsoredBrandsLandingPage;
}
