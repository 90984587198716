import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack, Typography } from '@mui/material';

interface AddedNegativeTargetingClausesTableProps {
  negativeTargetingClauses: any;
  setNegativeTargetingClauses: (keywords: any) => void;
}

const AddedNegativeTargetingClauseTable = ({
  negativeTargetingClauses,
  setNegativeTargetingClauses
}: AddedNegativeTargetingClausesTableProps) => {
  const [rows, setRows] = useState<any[]>([...negativeTargetingClauses]);

  useEffect(() => {
    if (!negativeTargetingClauses) {
      return;
    }

    setRows(negativeTargetingClauses);
  }, [negativeTargetingClauses]);

  const deleteNegativeTargetingClause = useCallback(
    (id: GridRowId) => () => {
      const updatedNegativeTargetingList = negativeTargetingClauses.filter((targetingClause: any) => {
        return `${targetingClause.expression[0].value}-${targetingClause.resolvedExpression[0].value}` !== id;
      });
      setNegativeTargetingClauses(updatedNegativeTargetingList);
    },
    [setNegativeTargetingClauses, negativeTargetingClauses]
  );

  const columns = useMemo<Array<GridColDef>>(
    () => [
      {
        field: 'targetDescriptor',
        headerName: 'Brands & Products',
        flex: 1,
        editable: false,
        disableReorder: true,
        sortable: false,
        resizable: false,
        filterable: false,
        renderCell: (params) => {
          if (params.row?.expression.some((expression: any) => expression.type === 'AsinBrandSameAs')) {
            return (
              <Typography fontSize={12}>
                <strong>Brand</strong>: {params.row?.resolvedExpression[0].value}
              </Typography>
            );
          }

          if (params.row?.expression.some((expression: any) => expression.type === 'AsinSameAs')) {
            return (
              <Stack>
                <Typography fontSize={12}>
                  <strong>Product</strong>: {params.row?.resolvedExpression[0].value}
                </Typography>
                <Typography fontSize={12} color={'text.secondary'}>
                  ASIN: {params.row?.expression[0].value}
                </Typography>
              </Stack>
            );
          }
        }
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 80,
        getActions: (params: { id: GridRowId }) => [
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteNegativeTargetingClause(params.id)} />
        ]
      }
    ],
    [deleteNegativeTargetingClause]
  );

  return (
    <div style={{ height: 490 }}>
      <DataGridPremium
        rows={rows}
        getRowHeight={() => 'auto'}
        columns={columns}
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableDensitySelector
        disableRowSelectionOnClick
        disableRowGrouping
        getRowId={(row) => `${row.expression[0].value}-${row.resolvedExpression[0].value}`}
      />
    </div>
  );
};

export default AddedNegativeTargetingClauseTable;
