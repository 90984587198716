import * as Yup from 'yup';
import { BackendDynamicBidding, CampaignStrategyOptions } from '../../../types/campaign';
import { CampaignStructureType, SponsoredProductsCreateOrUpdateBudgetType, State } from '../../../types/campaign-builder-request';

export interface CampaignBuilderFormValues {
  name: string;
  campaignStrategy: string;
  startDate: Date | null;
  endDate: Date | null;
  dailyBudget: number;
  amazonSponsoredProductsDynamicBidding: BackendDynamicBidding;
  budgetType: SponsoredProductsCreateOrUpdateBudgetType;
  state: State;
  items: any[];
  campaignStructureType: CampaignStructureType;
  isBrandedCampaign: boolean;
  negativeKeywords: string[];
  universalKeywords: string[];
}

export const INITIAL_FORM_VALUES: CampaignBuilderFormValues = {
  name: '',
  campaignStrategy: CampaignStrategyOptions.Branded.value,
  startDate: new Date(),
  endDate: null,
  dailyBudget: 50,
  amazonSponsoredProductsDynamicBidding: {
    strategy: 'DownOnly',
    placementBidding: [
      { placement: 'TopOfSearch', percentage: 0 },
      { placement: 'ProductPages', percentage: 0 }
    ]
  },
  budgetType: SponsoredProductsCreateOrUpdateBudgetType.DAILY,
  state: State.ENABLED,
  items: [],
  campaignStructureType: CampaignStructureType.SINGLE_ITEM_SINGLE_KEYWORD_CAMPAIGN,
  isBrandedCampaign: false,
  negativeKeywords: [],
  universalKeywords: []
};

const today = new Date();
today.setHours(0, 0, 0, 0);

export const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().when('campaignStructureType', {
    is: (value: string[]) => value[0].startsWith('MULTI'),
    then: (schema) => schema.required('Name is required').min(1, 'Name is required'),
    otherwise: (schema) => schema
  }),
  startDate: Yup.date().nullable().min(today, 'Start date cannot have passed').required('Start Date is required'),
  endDate: Yup.date()
    .nullable()
    .min(today, 'End date cannot be before start date')
    .when('startDate', (startDate, schema) => {
      return startDate ? schema.min(startDate, 'End date must come after start date.') : schema;
    }),
  campaignStructureType: Yup.string().nonNullable().required('Campaign Structure Type is required'),
  items: Yup.array().min(1, 'At least one item is required').required('At least one item is required')
});
