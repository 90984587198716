import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { NegativeKeywordMatchType } from '../../../../types/campaign-builder-request';
import AddedNegativeKeywordsTable from '../../campaign-builder/added-negative-keywords';
import { formatOption } from '../../../../types/negative-keyword';

interface AddNegativeKeywordsProps {
  negativeKeywords: any;
  setNegativeKeywords: (negativeKeywords: any) => void;
}

export default function AmazonAddNegativeKeywords(props: AddNegativeKeywordsProps) {
  const { negativeKeywords, setNegativeKeywords } = props;
  const [userEnteredNegativeKeywords, setUserEnteredNegativeKeywords] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [matchTypes, setMatchTypes] = useState<NegativeKeywordMatchType[]>([NegativeKeywordMatchType.Exact]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleMatchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (matchTypes.includes(event.target.value as NegativeKeywordMatchType)) {
      setMatchTypes(matchTypes.filter((matchType) => matchType !== event.target.value));
    } else {
      setMatchTypes([...matchTypes, event.target.value as NegativeKeywordMatchType]);
    }
  };

  const addNegativeKeywords = () => {
    const parsedNegativeKeywords = userEnteredNegativeKeywords
      .split('\n')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)
      .map((keyword) => keyword.toLowerCase());

    const objectifiedKeywords = matchTypes.flatMap((matchType) =>
      parsedNegativeKeywords.map((keyword: string) => ({
        keywordText: keyword,
        matchType: matchType,
        id: `${keyword}-${matchType}`
      }))
    );

    const newNegativeKeywords = objectifiedKeywords.filter(
      (negativeKeyword: any) =>
        !negativeKeywords.some(
          (objKeyword: any) =>
            objKeyword.keywordText === negativeKeyword.keywordText && objKeyword.matchType === negativeKeyword.matchType
        )
    );

    setUserEnteredNegativeKeywords('');
    setNegativeKeywords([...negativeKeywords, ...newNegativeKeywords]);
  };

  return (
    <Grid container direction="column" mt={2}>
      <Grid item>
        <Box display={'flex'}>
          {Object.values(NegativeKeywordMatchType).map((option) => (
            <FormControlLabel
              key={option}
              control={<Checkbox checked={matchTypes.includes(option)} onChange={handleMatchTypeChange} value={option} />}
              label={formatOption(option)}
            />
          ))}
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button variant="outlined" onClick={() => setNegativeKeywords([])}>
            Remove All Keywords
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Grid container justifyContent={'space-between'} alignItems="center" spacing={1}>
          <Grid item xs={4}>
            <TextField
              label="Negative Keywords"
              onChange={(event) => {
                setUserEnteredNegativeKeywords(event.target.value);
              }}
              placeholder="Enter one keyword per line"
              multiline
              value={userEnteredNegativeKeywords}
              sx={{ mt: 1, width: '100%' }}
              rows={15}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <Grid container direction="column" alignItems="center">
              <Button sx={{ my: 0.5 }} variant="outlined" size="small" aria-label="move all right" onClick={addNegativeKeywords}>
                <Typography variant="h6" fontWeight={'bold'}>
                  ≫
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <AddedNegativeKeywordsTable
              key={'add-negative-keywords'}
              keywords={negativeKeywords || ''}
              setKeywords={setNegativeKeywords}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
