import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const LinkCellStatus = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
} as const;

interface LinkCellParams {
  linkUrl: string;
  value: string;
  status?: (typeof LinkCellStatus)[keyof typeof LinkCellStatus];
}

export const LinkCell = (props: LinkCellParams) => {
  const status = props.status || LinkCellStatus.ENABLED;

  return (
    <Link
      to={props.linkUrl}
      sx={{
        textDecoration: 'none',
        color: status === LinkCellStatus.ENABLED ? 'text.primary' : 'text.disabled'
      }}
      component={RouterLink}
    >
      {props.value}
    </Link>
  );
};
