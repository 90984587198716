import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Grid,
  InputAdornment,
  LinearProgress,
  ListItem,
  ListSubheader,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { PlacementMultipliers } from '../../types/bid-multipliers';

interface PlacementMultiplierProps {
  loading: boolean;
  targetingType: string;
  placementMultipliers: PlacementMultipliers;
  buyBoxEnabled: boolean;
  setPlacementMultipliers: React.Dispatch<React.SetStateAction<PlacementMultipliers>>;
}

export const PlacementMultipliersContainer = (props: PlacementMultiplierProps) => {
  const { loading, targetingType, placementMultipliers, buyBoxEnabled, setPlacementMultipliers } = props;

  return (
    <>
      <ListSubheader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          PLACEMENT MODIFIERS
          <Tooltip title="To disable a modifier, set its value to 0">
            <InfoIcon sx={{ ml: 2 }} />
          </Tooltip>
        </Box>
      </ListSubheader>
      <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
      <ListItem>
        {' '}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>Buy Box</Typography>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '150px' }}
              size="small"
              value={placementMultipliers.BuyBox}
              onChange={(event: any) =>
                setPlacementMultipliers((prevState) => ({
                  ...prevState,
                  BuyBox: event.target.value
                }))
              }
              disabled={loading || !buyBoxEnabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Box>%</Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>Search in Grid</Typography>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: '150px' }}
              size="small"
              value={placementMultipliers.SearchIngrid}
              onChange={(event: any) =>
                setPlacementMultipliers((prevState) => ({
                  ...prevState,
                  SearchIngrid: event.target.value
                }))
              }
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Box>%</Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </ListItem>
      {targetingType === 'Auto' ? (
        <>
          <ListItem>
            {' '}
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography>Home Page</Typography>
              </Grid>
              <Grid item>
                <TextField
                  sx={{ width: '150px' }}
                  size="small"
                  value={placementMultipliers.HomePage}
                  onChange={(event: any) =>
                    setPlacementMultipliers((prevState) => ({
                      ...prevState,
                      HomePage: event.target.value
                    }))
                  }
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box>%</Box>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            {' '}
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography>Stock Up</Typography>
              </Grid>
              <Grid item>
                <TextField
                  sx={{ width: '150px' }}
                  size="small"
                  value={placementMultipliers.StockUp}
                  onChange={(event: any) =>
                    setPlacementMultipliers((prevState) => ({
                      ...prevState,
                      StockUp: event.target.value
                    }))
                  }
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box>%</Box>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </ListItem>
        </>
      ) : (
        ''
      )}
    </>
  );
};
