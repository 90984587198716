import { useRef, useState } from 'react';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { AccountPopover } from './account-popover';
import { useAuth } from '../../hooks/use-auth';

export const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const { user } = useAuth();

  const handleOpenPopover = (): void => {
    setIsPopoverOpen(true);
  };

  const handleClosePopover = (): void => {
    setIsPopoverOpen(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40
          }}
          src={user?.profilePhotoUrl}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
      </Box>
      <AccountPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={isPopoverOpen} />
    </>
  );
};
