import { Box, Button, Container, Stack, Typography } from '@mui/material';
import Page from '../../../shared/components/page';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { Platform } from '../../../shared/types/platforms';
import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { DataGridPremium, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';

export const SEARCH_REPORT_COLUMNS: GridColDef[] = [
  {
    field: 'report_date',
    headerName: 'Report Date',
    width: 100,
    type: 'string'
  },
  {
    field: 'search_keyword',
    headerName: 'Search Keyword',
    width: 200
  },
  {
    field: 'keyword_rank',
    headerName: 'Keyword Rank',
    width: 200
  },
  {
    field: 'item_id_1',
    headerName: 'Item 1 Id',
    width: 200
  },
  {
    field: 'item_name_1',
    headerName: 'Item 1 Name',
    width: 200
  },
  {
    field: 'pct_click_share_item_1',
    headerName: 'Item 1 Click Share',
    width: 200
  },
  {
    field: 'item_id_2',
    headerName: 'Item 2 Id',
    width: 200
  },
  {
    field: 'item_name_2',
    headerName: 'Item 2 Name',
    width: 200
  },
  {
    field: 'pct_click_share_item_2',
    headerName: 'Item 2 Click Share ',
    width: 200
  },
  {
    field: 'item_id_3',
    headerName: 'Item 3 Id',
    width: 200
  },
  {
    field: 'item_name_3',
    headerName: 'Item 3 Name',
    width: 200
  },
  {
    field: 'pct_click_share_item_3',
    headerName: 'Item 3 Click Share',
    width: 200
  }
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

export const SearchInsights = () => {
  const { getSearchTrends, getSearchTrendsUrl } = useAdsApi(Platform.WALMART.value);

  const [searchTermData, setSearchTermData] = useState<any[]>([]);
  const [searchTermReportUrl, setSearchTermReportUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldShowReportInGrid, setShouldShowReportInGrid] = useState<boolean>(false);

  useEffect(() => {
    if (!shouldShowReportInGrid) {
      return;
    }

    fetchSearchTrends();
  }, [shouldShowReportInGrid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchSearchTrendsUrl();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSearchTrends = async () => {
    setIsLoading(true);
    try {
      const response = await getSearchTrends();

      var csvString = response.body;

      const { data, meta } = Papa.parse(csvString, {
        header: true, // Treat the first row as header
        dynamicTyping: true // Convert numeric values to numbers
      });

      setSearchTermData(data.filter((row: any) => !!row?.keyword_rank)); // extra rows were included from csv file that didn't contain data.
    } catch (error) {
      console.error('Error fetching or parsing Excel data:', error);
    }
    setIsLoading(false);
  };

  const fetchSearchTrendsUrl = async () => {
    setIsLoading(true);
    try {
      const response = await getSearchTrendsUrl();

      setSearchTermReportUrl(response.body);
    } catch (error) {
      console.error('Error fetching search trends url:', error);
    }
    setIsLoading(false);
  };

  if (isLoading && !shouldShowReportInGrid) {
    return (
      <Page title="Search Insights">
        <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Search Insights</Typography>
            <Box style={{ height: 'calc(100% - 100px)' }}>
              <Typography variant="body1">Loading...</Typography>
            </Box>
          </Stack>
        </Container>
      </Page>
    );
  }

  if (!shouldShowReportInGrid) {
    return (
      <Page title="Search Insights">
        <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Search Insights</Typography>
            <Box style={{ height: 'calc(100% - 100px)' }}>
              <Typography variant="body1">Would you like to view the report in the grid or download the report?</Typography>
              <Button onClick={() => setShouldShowReportInGrid(true)}>View Report in Grid (May take a minute to load)</Button>
              <Button onClick={() => window.open(searchTermReportUrl)}>Download Report</Button>
            </Box>
          </Stack>
        </Container>
      </Page>
    );
  }

  return (
    <Page title="Search Insights">
      <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Typography variant="h4">Search Insights</Typography>
          <Box style={{ height: 'calc(100% - 100px)' }}>
            <DataGridPremium
              rows={searchTermData}
              disableRowGrouping
              columns={SEARCH_REPORT_COLUMNS}
              getRowId={(row) => row.keyword_rank}
              loading={isLoading}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Stack>
      </Container>
    </Page>
  );
};
