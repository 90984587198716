import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Field, FieldArray, Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Filter, Pageable } from '../../../../../shared/types/pageable';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { AdGroupViewDto } from '../../../../types/ad-group';
import { UnifiedAdBuilderRequest } from '../../../../types/ads';
import { AmazonCampaignType } from '../../../../types/campaign';

interface CreateAdsDialogProps {
  isShowing: boolean;
  toggle: () => void;
  refreshAds: () => void;
}

export const CreateAdsDialog = (props: CreateAdsDialogProps) => {
  const { isShowing, toggle, refreshAds } = props;

  const [adGroups, setAdGroups] = useState<AdGroupViewDto[]>([]);

  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');
  let campaignId = searchParams.get('campaignId');
  let adGroupId = searchParams.get('adGroupId');

  const [currentAdGroupId, setCurrentAdGroupId] = useState<string | null>(adGroupId);
  const { createAds, getAdGroups } = useAmazonApi();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: any[]) => {
    setIsSubmitting(true);

    const response = await createAds(values);

    if (response.success) {
      enqueueSnackbar(`Product Ads created successfully!`, { variant: 'success' });
      refreshAds();
      toggle();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const fetchAdGroups = async () => {
    const filters: Filter[] = [
      {
        column: 'campaignId',
        comparator: 'Equals',
        value: campaignId?.toString() ?? ''
      }
    ];
    const pageable: Pageable = {
      filters: filters,
      limit: 1000,
      offset: 0
    };

    const response = await getAdGroups(pageable, false);

    if (response.success) {
      setAdGroups(response.body?.dataGrid?.records || []);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchAdGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Formik
        initialValues={{
          ads: [
            {
              adGroupId: !!currentAdGroupId ? currentAdGroupId : '',
              customText: '',
              asin: '',
              state: 'ENABLED',
              sku: '',
              campaignType: AmazonCampaignType.SPONSORED_PRODUCTS,
              retailer: 'Amazon'
            }
          ] as UnifiedAdBuilderRequest[]
        }}
        onSubmit={async (formValues) => {
          await handleSubmit(formValues.ads);
        }}
      >
        {({ values, setFieldValue, dirty, isValid, isSubmitting }) => (
          <Form>
            <Container maxWidth="md">
              <Dialog open={isShowing} maxWidth="xl" disablePortal sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <DialogContent>
                  <Typography variant="h6" sx={{ mb: 4 }}>
                    Create Ads
                  </Typography>
                  {!adGroupId && adGroups.length > 0 ? (
                    <Autocomplete
                      options={adGroups}
                      getOptionLabel={(adGroup) => adGroup.name}
                      onChange={(event, value) => {
                        setCurrentAdGroupId(value?.adGroupId || null);
                        values.ads.forEach((_: any, index: any) => {
                          setFieldValue(`ads.${index}.adGroupId`, value?.adGroupId || 0);
                        });
                      }}
                      sx={{ width: 300, mb: 2 }}
                      renderInput={(params) => <TextField {...params} size="small" label="Ad Group" />}
                      value={adGroups.find((adGroup: { adGroupId: any }) => adGroup.adGroupId === currentAdGroupId) || null}
                    />
                  ) : null}
                  <FieldArray name="ads">
                    {({ remove, push }: any) => (
                      <Box>
                        {values.ads.length > 0 &&
                          values.ads.map((ad: UnifiedAdBuilderRequest, index: number) => (
                            <Box
                              className="row"
                              key={index}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="row"
                            >
                              <Field name={`ads.${index}.customText`} as={TextField} label="Custom Text" size="small" />
                              <Field name={`ads.${index}.asin`} as={TextField} label="ASIN" size="small" />
                              <Field name={`ads.${index}.sku`} as={TextField} label="SKU" size="small" />
                              <IconButton aria-label="delete" onClick={() => remove(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                          <Button
                            type="button"
                            onClick={() =>
                              push({
                                adGroupId: !!currentAdGroupId ? currentAdGroupId : 0,
                                customText: '',
                                asin: '',
                                state: 'ENABLED',
                                sku: '',
                                campaignType: AmazonCampaignType.SPONSORED_PRODUCTS,
                                retailer: 'Amazon'
                              })
                            }
                            startIcon={<AddIcon />}
                          >
                            Add Product Ad
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </FieldArray>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={() => toggle()}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting || !isValid || !dirty}>
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Container>
          </Form>
        )}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
