import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Container, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPremium, GridRowModel } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { BidConfirmationDialog } from '../../components/grid-components/bid-confirmation-dialog';
import { AD_COLUMNS } from '../../components/grid-components/column-configurations/ad-columns';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { useSbReview } from '../../hooks/use-sb-review';
import { buildUpdateAdRequest } from '../../types/ad';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import AdBulkActions from './bulk-actions/ad-bulk-actions';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { v4 as uuidv4 } from 'uuid';
import MetricsChart from '../../../shared/components/metrics-chart';

const Ads: FC = () => {
  const { getAds, updateAd } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [ads, setAds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [promiseArguments, setPromiseArguments] = useState<any>(null);

  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  const campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;
  const adGroupId = searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null;

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();
  const { allowAddItems, isLoading: reviewIsLoading } = useSbReview(profileId, campaignId, adGroupId);

  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.WALMART_AD,
    breadCrumbParams: { profileId, campaignId, adGroupId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (newRow.bid === oldRow.bid && newRow.state === oldRow.state) {
      return oldRow;
    }

    //Setting the promiseArguments will open the confirmation dialog if the bid is greater than suggested max bid
    if (Number(newRow.bid) > 10) {
      return new Promise<GridRowModel>((resolve, reject) => {
        setPromiseArguments({ resolve, reject, newRow, oldRow });
      });
    }

    setIsLoading(true);

    const response = await updateAd(newRow.profileId, buildUpdateAdRequest(newRow));

    setIsLoading(false);

    if (response.success && response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      return newRow;
    }

    enqueueSnackbar(response.body[0].details, { variant: 'error' });

    return oldRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ profileId, campaignId, adGroupId });
  }, [profileId, campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    setIsLoading(true);

    const response = await updateAd(newRow.profileId, buildUpdateAdRequest(newRow));

    setIsLoading(false);

    if (response.success && response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      resolve(newRow);
    } else {
      enqueueSnackbar(response.body[0].details, { variant: 'error' });
      reject(oldRow);
    }

    setPromiseArguments(null);
  };

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    const fetchAds = async () => {
      setIsLoading(true);

      const response = await getAds(pageable, dateSettings.beginDate, dateSettings.endDate, true);

      if (response.success) {
        setAds(response.body.dataGrid.records);
        setRowCount(response.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);

        if (response.body.timeSeriesData) {
          setTimeSeriesData(response.body.timeSeriesData);
        }
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchAds();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const CreateAdsButton = () => {
    if (reviewIsLoading) {
      return (
        <Skeleton>
          <Button startIcon={<AddIcon fontSize="small" />} variant="contained" disabled={true}>
            Create Ads
          </Button>
        </Skeleton>
      );
    }

    if (!allowAddItems) {
      return (
        <Tooltip title="Unable to add Ad Items to an SB Campaign with Pending/InProgress Review">
          <Box>
            <Button startIcon={<AddIcon fontSize="small" />} variant="contained" disabled={true}>
              Create Ads
            </Button>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Link
        to={`/walmart/ads/create${profileId ? `?profileId=${profileId}` : ''}${
          campaignId && profileId ? `&campaignId=${campaignId}` : ''
        }`}
        component={RouterLink}
        style={{ textDecoration: 'none' }}
      >
        <Button startIcon={<AddIcon fontSize="small" />} variant="contained">
          Create Ads
        </Button>
      </Link>
    );
  };

  return (
    <>
      <Page title="Ads">
        <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="h4">Ads</Typography>
              <Box
                sx={{
                  mr: 'auto'
                }}
              ></Box>
              <CreateAdsButton />
            </Box>
            {!initialSettings || settingsLoading ? (
              <DataGridPremium
                disableRowGrouping
                rows={[]}
                columns={AD_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
              />
            ) : (
              <>
                {!!timeSeriesData && (
                  <Box display="flex" alignItems={'center'} my={'1%'}>
                    <MetricsChart timeSeriesData={timeSeriesData} isMetricsChartLoading={isLoading} />
                  </Box>
                )}
                <PerformanceGrid
                  processRowUpdate={processRowUpdate}
                  loading={isLoading}
                  rows={ads}
                  columns={AD_COLUMNS}
                  initialState={initialSettings?.config}
                  saveGridConfig={saveGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    saveDateConfig: saveDateConfig
                  }}
                  getRowId={(row: any) => row.adItemId}
                  getRowClassName={(params) => `row-status--${params.row.status}`}
                  bulkActions={<AdBulkActions setIsLoading={setIsLoading} />}
                  handleFilterModelChange={handleFilterModelChange}
                  handleSortModelChange={handleSortModelChange}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                  rowCount={rowCount}
                />
              </>
            )}
          </Stack>
        </Container>
      </Page>
      <BidConfirmationDialog handleNo={handleNo} handleYes={handleYes} promiseArguments={promiseArguments} />
    </>
  );
};

export default Ads;
