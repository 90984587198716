import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { File as DropZoneFile, FileDropzone } from '../../file-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { bytesToSize } from '../../../utilities/bytes-to-size';
import DescriptionIcon from '@mui/icons-material/Description';
import useAdsApi from '../../../hooks/use-walmart-sams-club-api';
import { useSnackbar } from 'notistack';

interface FileUploaderProps {
  profileId: number;
  onClose?: () => void;
  open?: boolean;
}

export const MediaFileUploader: FC<FileUploaderProps> = (props) => {
  const { onClose, profileId, open = false } = props;
  const [files, setFiles] = useState<DropZoneFile[]>([]);
  const [mediaName, setMediaName] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { createMedia } = useAdsApi();

  useEffect(() => {
    setFiles([]);
  }, [open]);

  const handleUpload = useCallback(async () => {
    setIsUploading(true);
    const formData = new FormData();

    const videoFile = files.find((file) => file.type.includes('video'));
    const captionFile = files.find((file) => file.name.split('.').pop() === 'vtt');

    if (!videoFile || !mediaName) {
      enqueueSnackbar('An error occurred: No video file found', { variant: 'error' });
      return;
    }

    if (videoFile.size > 500000000) {
      alert('File size must be less than 476.8 MB.');
      return;
    }

    const castedVideoFile = new File([videoFile], videoFile.name, { type: videoFile.type });

    formData.append('VideoFile', castedVideoFile, 'video file');
    formData.append('MediaName', mediaName);
    formData.append('ProfileId', profileId.toString());

    if (captionFile) {
      const castedCaptionFile = new File([captionFile], captionFile.name, { type: captionFile.type });
      formData.append('ClosedCaptionFile', castedCaptionFile, 'cc file');
    }

    let wakeLock = null;
    // Request the wake lock so the screen doesn't turn off while uploading
    try {
      wakeLock = await navigator.wakeLock.request('screen');
    } catch (err) {
      console.error('Failed to acquire wake lock:', err);
    }

    // Timer to simulate upload progress
    const estimatedTime = calculateEstimatedTime(videoFile.size);
    const startTime = Date.now();

    const timer = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000;
      let progress = (elapsedTime / estimatedTime) * 90;

      if (progress >= 90) {
        progress = 90;
        clearInterval(timer);
      }

      setUploadProgress(progress);
    }, 100);

    try {
      const response = await createMedia(formData);
      if (response.success) {
        enqueueSnackbar('Media uploaded successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to upload media', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to upload media', { variant: 'error' });
    } finally {
      if (wakeLock !== null) {
        wakeLock.release();
      }

      setUploadProgress(100);
      setIsUploading(false);
      setUploadComplete(true);
      onClose?.();
    }
  }, [files, mediaName, profileId, enqueueSnackbar, onClose]); // eslint-disable-line react-hooks/exhaustive-deps

  function calculateEstimatedTime(size: any) {
    const averageSpeed = 1211876; // somewhat arbitrary, derived during testing on local
    return size / averageSpeed;
  }

  const handleDrop = useCallback((newFiles: DropZoneFile[]): void => {
    setFiles((prevFiles) => {
      return [...prevFiles, ...newFiles];
    });
  }, []);

  const handleRemove = useCallback((file: DropZoneFile): void => {
    setFiles((prevFiles) => {
      return prevFiles.filter((_file) => _file.path !== file.path);
    });
  }, []);

  const handleRemoveAll = useCallback((): void => {
    setFiles([]);
  }, []);

  const hasAnyFiles = files.length > 0;

  const filesIncludeVideoFile = () => {
    return files.some((file) => file.type.includes('video'));
  };

  const filesIncludeVttFile = () => {
    return files.some((file) => file.name.split('.').pop() === 'vtt');
  };

  const getAccept = () => {
    var newAccept = {};

    if (!filesIncludeVideoFile()) {
      newAccept = { 'video/mp4': [], 'video/vnd.sealedmedia.softseal.mov': [] };
    }

    if (!filesIncludeVttFile()) {
      newAccept = { ...newAccept, 'text/vtt': ['.vtt'] };
    }

    return newAccept;
  };

  var getDisabled = () => {
    return filesIncludeVideoFile() && filesIncludeVttFile();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
        sx={{ opacity: isUploading ? 0 : 1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            py: 2
          }}
        >
          <Typography variant="h6">Upload Files</Typography>
          <IconButton color="inherit" onClick={onClose}>
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
        <DialogContent>
          <DialogContentText
            sx={{
              color: 'text.secondary',
              mb: 2
            }}
          >
            Upload at most one mp4 file and optionally one vtt file.
          </DialogContentText>
          <FileDropzone accept={getAccept()} files={files} maxFiles={2} disabled={getDisabled()} onDrop={handleDrop} />
          <Box sx={{ mt: 2 }}>
            <List>
              {hasAnyFiles &&
                files.map((file, index) => {
                  return (
                    <Box key={`${file.path}-${index}}`} sx={{ mt: 2 }}>
                      <ListItem
                        sx={{
                          border: 1,
                          borderColor: 'divider',
                          borderRadius: 1,
                          '& + &': {
                            mt: 1
                          }
                        }}
                      >
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={file.name}
                          primaryTypographyProps={{ variant: 'subtitle2' }}
                          secondary={bytesToSize(file.size)}
                        />
                        <Tooltip title="Remove">
                          <IconButton edge="end" onClick={() => handleRemove?.(file)}>
                            <SvgIcon>
                              <CloseIcon />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    </Box>
                  );
                })}
              <TextField
                fullWidth
                sx={{ mt: 2 }}
                label="Media Name"
                name="mediaName"
                onChange={(event) => setMediaName(event.target.value)}
                value={mediaName}
                variant="outlined"
              />
            </List>
            <Stack alignItems="center" direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
              <Button color="inherit" onClick={handleRemoveAll} size="small" type="button">
                Remove All
              </Button>
              <Button
                onClick={handleUpload}
                size="small"
                type="button"
                variant="contained"
                disabled={isUploading || uploadComplete || !mediaName || !files.some((file) => file.type.includes('video'))}
              >
                Upload
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) =>
            theme.zIndex.drawer + 2 /* Make sure this is +1 the dialog's zIndex so users can't click away while uploading */
        }}
        open={isUploading}
      >
        <Card>
          <CardContent>
            <Stack alignItems="center" direction="column" justifyContent="flex-end">
              <Stack alignItems="center" direction="column" justifyContent="flex-end" spacing={2}>
                <Typography variant="h6">Uploading...</Typography>
                <Typography variant="body2">Please wait while we upload your media.</Typography>
                <Typography variant="body2">This may take a while - please don't close this tab or refresh the page.</Typography>
              </Stack>
              <Box sx={{ my: 2 }} />
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={uploadProgress} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
                    uploadProgress
                  )}%`}</Typography>
                </Box>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Backdrop>
    </>
  );
};

MediaFileUploader.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
