import * as React from 'react';
import { Tabs, Tab, Container, Typography, Box } from '@mui/material';
import { BudgetManager } from './budget-manager';
import Pacing from './pacing';
import Page from '../../../shared/components/page';

const Budgets: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Page>
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4">Budgets</Typography>
          <Tabs value={tabIndex} onChange={handleChange}>
            <Tab label="Budget Manager" />
          </Tabs>
        </Box>

        {tabIndex === 0 ? <BudgetManager /> : <Pacing />}
      </Container>
    </Page>
  );
};

export default Budgets;
