import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button, IconButton, MenuItem, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { STATE_OPTIONS } from '../../../types/state-options';

interface CampaignActionButtonProps {
  setIsLoading: (isLoading: boolean) => void;
  refreshCampaigns: () => void;
  selectionModelHasRows: boolean;
  handleClose: () => void;
}

const EnableCampaignsActionButton = ({ setIsLoading, handleClose, selectionModelHasRows }: CampaignActionButtonProps) => {
  const apiRef = useGridApiContext();
  const { updateCampaigns } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const activateCampaigns = async () => {
    setIsLoading(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);

    const updateDtos = selectedCampaignIds.map((campaignId) => ({
      campaignId,
      state: STATE_OPTIONS.ENABLED
    }));

    const response = await updateCampaigns(updateDtos);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    if (response.success) {
      //set the state of the campaign to enabled through apiRef
      apiRef.current.updateRows(
        selectedCampaignIds.map((campaignId) => ({
          campaignId: campaignId,
          state: 'Enabled'
        }))
      );
    }

    apiRef.current.setRowSelectionModel([]);
    setIsLoading(false);
    handleClose();
  };

  return (
    <MenuItem sx={{ fontSize: 14 }} onClick={activateCampaigns} disabled={!selectionModelHasRows}>
      <PlayArrowIcon />
      Enable
    </MenuItem>
  );
};

export default EnableCampaignsActionButton;
