import { Container, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridRowModel } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import KeywordBulkActions from '../../../shared/components/grid-components/bulk-actions/keywords/keyword-bulk-actions';
import { NEGATIVE_KEYWORD_COLUMNS } from '../../components/grid-components/column-configurations/negative-keyword-columns';
import { buildUpdateNegativeKeywordRequest } from '../../../shared/types/walmart-sams-club/keyword';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { v4 as uuidv4 } from 'uuid';

const NegativeKeywords: FC = () => {
  const { getNegativeKeywords, updateNegativeKeywords } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [searchParams] = useSearchParams();
  const [negativeKeywords, setNegativeKeywords] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  const campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;
  const adGroupId = searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null;

  const [rowCount, setRowCount] = useState(0);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.SAMS_CLUB_NEGATIVE_KEYWORD,
    breadCrumbParams: { profileId, campaignId, adGroupId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    setIsLoading(true);

    const response = await updateNegativeKeywords([buildUpdateNegativeKeywordRequest(newRow)]);

    setIsLoading(false);

    if (response.success && response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      return newRow;
    }

    enqueueSnackbar(response.body[0].details, { variant: 'error' });

    return oldRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ profileId, campaignId, adGroupId });
  }, [profileId, campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable) {
      return;
    }

    const fetchNegativeKeywords = async () => {
      setIsLoading(true);

      const response = await getNegativeKeywords(pageable);

      if (response.success) {
        setNegativeKeywords(response.body?.records);
        setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchNegativeKeywords();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Page title="Negative Keywords">
        <Container maxWidth="xl" style={{ height: '93vh' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Negative Keywords</Typography>
            {!initialSettings || settingsLoading ? (
              <DataGridPremium
                rows={[]}
                columns={NEGATIVE_KEYWORD_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
                disableRowGrouping
              />
            ) : (
              <PerformanceGrid
                disableGridDateRangePicker
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={negativeKeywords}
                columns={NEGATIVE_KEYWORD_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  saveDateConfig: saveDateConfig
                }}
                getRowId={(row: any) => row.keywordId}
                getRowClassName={(params) => `row-status--${params.row.state}`}
                bulkActions={<KeywordBulkActions setIsLoading={setIsLoading} disableUpdateKeywordBids />}
                handleFilterModelChange={handleFilterModelChange}
                handleSortModelChange={handleSortModelChange}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                rowCount={rowCount}
              />
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default NegativeKeywords;
