import { Box, Chip, Grid, Typography } from '@mui/material';
import {
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  gridFilterModelSelector
} from '@mui/x-data-grid-premium';

const defaultDateConfig = {
  dateSettings: {},
  dateSettingsLoading: false,
  saveDateConfig: () => {}
};

export const PerformanceGridToolbar = (props: GridToolbarProps) => {
  const {
    apiRef,
    setFilterButtonEl,
    bulkActions,
    disableGridDateRangePicker,
    dateConfig = defaultDateConfig,
    allowQuickFilterSearch = false
  } = props;

  const filterModel = gridFilterModelSelector(apiRef);

  const renderFilterChips = () => {
    const filterItems = [...filterModel?.items];

    const customAttributionChips = [
      { key: 'brand', label: 'Brand' },
      { key: 'subbrand', label: 'Sub Brand' },
      { key: 'category', label: 'Category' },
      { key: 'subcategory', label: 'Sub Category' }
    ].map((customAttributionItem) => {
      const filteredItem = filterItems.find((i) => i.field === customAttributionItem.key);

      if (!filteredItem || filteredItem.value === undefined || filteredItem.value === null || filteredItem.value === '') {
        return (
          <Chip // inactive chip
            label={customAttributionItem.label}
            onClick={() => apiRef.current.showFilterPanel(customAttributionItem.key)}
            sx={{ margin: '10px' }}
          />
        );
      }

      return (
        <Chip // active chip
          label={`${customAttributionItem.label} ${filteredItem.operator} ${filteredItem.value}`}
          onDelete={() => apiRef.current.deleteFilterItem(filteredItem)}
          onClick={() => apiRef.current.showFilterPanel(customAttributionItem.key)}
          sx={{ margin: '10px' }}
          color="primary"
        />
      );
    });

    const additionalFilterChips = filterItems
      .filter((item) => !['brand', 'subbrand', 'category', 'subcategory'].includes(item.field))
      .map((item, index) => {
        if (item.value === undefined || item.value === null || item.value === '') {
          return null;
        }

        return (
          <Chip //only render if filter is active
            key={item.field + index}
            sx={{ margin: '10px' }}
            label={`${item.field} ${item.operator} ${item.value}`}
            onDelete={() => apiRef.current.deleteFilterItem(item)}
            onClick={() => apiRef.current.showFilterPanel()}
            color="primary"
          />
        );
      })
      .filter(Boolean);

    return [...customAttributionChips, ...additionalFilterChips];
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        style={{ borderBottom: '1px solid #ccc', paddingBottom: '4px', paddingTop: '4px', transition: 'height .25s ease' }}
        ref={setFilterButtonEl}
      >
        {bulkActions && (
          <>
            <Typography variant="h6" sx={{ ml: 2, mr: 2 }}>
              Actions:
            </Typography>
            {bulkActions}
          </>
        )}
        {allowQuickFilterSearch && (
          <Box sx={{ ml: 1.5 }}>
            <GridToolbarQuickFilter debounceMs={200} />
          </Box>
        )}

        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarFilterButton />
        {renderFilterChips()}
      </Grid>
    </>
  );
};
