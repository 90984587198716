import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import useAmazonApi from '../../hooks/use-amazon-api';
import { AddCampaignsToBidGroupRequestDto, BidOptimizationGroupDto } from '../../types/bid-optimization-group';

interface AddCampaignsToBidGroupDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  refreshCampaigns: () => void;
}

export const AddCampaignsToBidGroupDialog = (props: AddCampaignsToBidGroupDialogProps) => {
  const { toggle, isShowing, dynamicKey, refreshCampaigns } = props;
  const { getBidOptimizationGroups, addCampaignsToBidGroup } = useAmazonApi();
  const [bidGroups, setBidGroups] = useState<BidOptimizationGroupDto[]>([]);
  const [selectedBidGroup, setSelectedBidGroup] = useState<BidOptimizationGroupDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const apiRef = useGridApiContext();

  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');

  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);

  const fetchBidGroups = async () => {
    if (!profileId) {
      return;
    }

    setIsLoading(true);

    const response = await getBidOptimizationGroups(profileId);

    if (response.success) {
      setBidGroups(response.body.records);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchBidGroups();
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveButtonClick = async () => {
    setBackDropOpen(true);

    if (!selectedBidGroup) {
      enqueueSnackbar('Bid group is missing', { variant: 'error' });
      return;
    }

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);

    if (!profileId) {
      enqueueSnackbar('Profile ID is missing', { variant: 'error' });
      return;
    }

    const requestBody: AddCampaignsToBidGroupRequestDto = {
      campaignIds: selectedCampaignIds,
      campaignBidOptimizationGroupId: selectedBidGroup.id
    };

    const response = await addCampaignsToBidGroup(requestBody);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      if (location.pathname.includes('bid-optimization-groups')) {
        refreshCampaigns();
      } else {
        apiRef.current.updateRows(
          selectedCampaignIds.map((campaignId) => ({
            campaignId: campaignId,
            campaignBidOptimizationGroupId: selectedBidGroup.id,
            campaignBidOptimizationGroupName: selectedBidGroup.name
          }))
        );
      }

      enqueueSnackbar('Campaigns added to bid group', { variant: 'success' });
    }

    apiRef.current.setRowSelectionModel([]);

    setBackDropOpen(false);
    toggle();
  };

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="sm" sx={{ transition: 'height 1s' }}>
      <DialogTitle>Add Campaigns to Bid Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>
        <Typography variant="body1" gutterBottom>
          Select a bid group to add the selected campaigns to.
        </Typography>
        <Box display={'flex'} justifyContent={'flex-start'} mb={2} alignItems={'center'} mt={4}>
          {isLoading ? (
            <Skeleton variant="rectangular" width={300} height={40} />
          ) : (
            <Autocomplete
              options={bidGroups}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => setSelectedBidGroup(value as BidOptimizationGroupDto)}
              renderInput={(params) => <TextField {...params} label="Bid Group" />}
              style={{ width: 300 }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSaveButtonClick} variant="contained" color="primary" disabled={!selectedBidGroup}>
          Add Campaigns
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
