import { Divider, Box, Stack, Typography, Button, Container } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MediaListContainer } from './media-list-container';

import { MediaListTable } from './media-list-table';
import AddIcon from '@mui/icons-material/Add';
import useAdsApi from '../../../hooks/use-walmart-sams-club-api';
import Page from '../../page';
import { MediaListSearch } from './media-list-search';
import { MediaFileUploader } from './media-file-uploader';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface Filters {
  status?: string;
}

interface Search {
  filters: Filters;
}

const useSearch = () => {
  const [search, setSearch] = useState<Search>({
    filters: {
      status: 'LIVED'
    }
  });

  return {
    search,
    updateSearch: setSearch
  };
};

export const VideoMedia = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const { search, updateSearch } = useSearch();
  const [medias, setMedias] = useState<any[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [shouldShowErrorState, setShouldShowErrorState] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [openFileUploader, setOpenFileUploader] = useState<boolean>(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  if (!profileId) {
    enqueueSnackbar('An error occurred - please select a profile to use this route', { variant: 'error' });
    navigate('/profiles');
  }

  const [drawer, setDrawer] = useState<{
    isOpen: boolean;
    data?: string;
  }>({
    isOpen: false,
    data: undefined
  });

  const { getMedia } = useAdsApi();

  const handleFiltersChange = useCallback(
    (filters: Filters): void => {
      updateSearch((prevState) => ({
        ...prevState,
        filters
      }));
    },
    [updateSearch]
  );

  const handleUploaderOpen = useCallback((): void => {
    setOpenFileUploader(true);
  }, []);

  const fetchMedia = async () => {
    const responseObject = await getMedia(profileId || 0);

    if (!responseObject.success) {
      enqueueSnackbar('An error occurred while fetching media', { variant: 'error' });
      setErrorMessage(`${responseObject.response?.status} - ${responseObject.errorMessage || responseObject.body?.message}`);
      setShouldShowErrorState(true);
      return;
    }

    setMedias(responseObject.body);
    setIsInitialized(true);
  };

  const handleUploaderClose = useCallback((): void => {
    fetchMedia();
    setOpenFileUploader(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchMedia();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFilteredMedia = useCallback(() => {
    const { filters } = search;
    const { status } = filters;

    if (status) {
      return medias.filter((media) => {
        //Walmart doesn't have processing as a status, but it is showing up in API calls
        if (search.filters.status === 'PENDING') {
          return media.status === search.filters.status || media.status === 'PROCESSING';
        }

        return media.status === search.filters.status;
      });
    }

    return medias;
  }, [medias, search]);

  return (
    <>
      <Page title="Campaigns">
        <>
          {isInitialized ? (
            <Box
              component="main"
              ref={rootRef}
              sx={{
                display: 'flex',
                flex: '1 1 auto',
                overflow: 'hidden',
                position: 'relative'
              }}
            >
              <Box
                ref={rootRef}
                sx={{
                  bottom: 0,
                  display: 'flex',
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0
                }}
              >
                <MediaListContainer open={drawer.isOpen}>
                  <Container>
                    <Box sx={{ py: 3 }}>
                      <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={4}>
                        <div>
                          <Typography variant="h4">Client Video Assets</Typography>
                        </div>
                        <div>
                          <Button startIcon={<AddIcon />} variant="contained" onClick={handleUploaderOpen}>
                            Create New
                          </Button>
                        </div>
                      </Stack>
                    </Box>
                    <MediaListSearch onFiltersChange={handleFiltersChange} />
                    <MediaListTable medias={getFilteredMedia()} search={search.filters.status} />
                  </Container>
                </MediaListContainer>
              </Box>
            </Box>
          ) : (
            <Container maxWidth="xl">
              <Box sx={{ height: '100%', minHeight: '100vh', py: 3 }}>
                <Typography variant="h4">
                  {shouldShowErrorState ? 'An error occurred while fetching media' : 'Loading...'}
                </Typography>
                {shouldShowErrorState ? (
                  <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1">Error: {errorMessage}</Typography>
                  </>
                ) : null}
              </Box>
            </Container>
          )}
        </>
      </Page>
      {!!profileId && openFileUploader ? (
        <MediaFileUploader onClose={handleUploaderClose} profileId={profileId} open={openFileUploader} />
      ) : null}
    </>
  );
};
