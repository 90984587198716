import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

export const WALLET_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'Active',
    icon: PlayArrowIcon
  },
  {
    label: 'Archived',
    value: 'Archived',
    icon: PauseIcon
  }
];
