import { NumericFormat, InputAttributes, removeNumericFormat, ChangeMeta } from 'react-number-format';
import { forwardRef } from 'react';
import { Box, InputAdornment, TextField, TextFieldProps } from '@mui/material';

const defaultChangeMeta: ChangeMeta = {
  from: { start: 0, end: 0 },
  to: { start: 0, end: 0 },
  lastValue: ''
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: number | undefined } }) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  name: string;
}

export const NumberFormatCustom = forwardRef<typeof NumericFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, onBlur: defaultOnBlur, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
          defaultOnBlur(event);

          const newValue = Number(removeNumericFormat(event.target.value, defaultChangeMeta, ''));

          onChange({ target: { name: props.name, value: newValue } });
        }}
        thousandSeparator
        decimalScale={2}
        valueIsNumericString
      />
    );
  }
);

export const CurrencyInput = ({ ...rest }: TextFieldProps) => {
  return (
    <TextField
      {...rest}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        startAdornment: (
          <InputAdornment position="start">
            <Box>$</Box>
          </InputAdornment>
        )
      }}
    />
  );
};
