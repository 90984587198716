import { FC, useEffect, useState } from 'react';
import { useLocation, Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Link, Stack, Breadcrumbs as MUIBreadcrumbs, Typography, Skeleton } from '@mui/material';
import { useAdsApi } from '../../hooks/use-walmart-sams-club-api';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSnackbar } from 'notistack';
import { useCurrentRetailerPlatform } from '../../hooks/use-current-retailer-platform';

const WalmartAndSamsClubBreadCrumbs: FC = () => {
  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const retailerPlatform = useCurrentRetailerPlatform();

  const [profileId, setProfileId] = useState<string>(searchParams.get('profileId') || '');
  const [campaignId, setCampaignId] = useState<string>(searchParams.get('campaignId') || '');
  const [adGroupId, setAdGroupId] = useState<string>(searchParams.get('adGroupId') || '');
  const [profileName, setProfileName] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [adGroupName, setAdGroupName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [adName, setAdName] = useState<string>(searchParams.get('adName') || '');

  const { getProfile, getCampaign, getAdGroup } = useAdsApi(retailerPlatform);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setProfileId(searchParams.get('profileId') || '');
    setCampaignId(searchParams.get('campaignId') || '');
    setAdGroupId(searchParams.get('adGroupId') || '');

    if (!searchParams.get('profileId')) {
      setProfileName('');
    }
    if (!searchParams.get('campaignId')) {
      setCampaignName('');
    }
    if (!searchParams.get('adGroupId')) {
      setAdGroupName('');
    }
    if (!searchParams.get('adName')) {
      setAdName('');
    }

    if (!searchParams.get('campaignId') && !searchParams.get('profileId') && !searchParams.get('adGroupId')) {
      setIsLoading(false);
    }
  }, [location, searchParams]);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);

      await Promise.all([fetchProfile(), fetchCampaign(), fetchAdGroup()]);

      setIsLoading(false);
    };

    initialize();
  }, [profileId, campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProfile = async () => {
    if (!profileId) {
      return;
    }

    const response = await getProfile(parseInt(profileId, 0));

    if (response.success) {
      setProfileName(response.body?.name);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const fetchCampaign = async () => {
    if (!campaignId) {
      return;
    }

    const response = await getCampaign(parseInt(profileId, 0), parseInt(campaignId, 0));

    if (response.success) {
      setCampaignName(response.body?.name);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const fetchAdGroup = async () => {
    if (!adGroupId) {
      return;
    }

    const response = await getAdGroup(parseInt(profileId, 0), parseInt(campaignId, 0), parseInt(adGroupId, 0));

    if (response.success) {
      setAdGroupName(response.body?.name);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const crumbModel = [
    {
      key: `/${retailerPlatform}/profiles`,
      name: 'All Profiles',
      href: `/${retailerPlatform}/profiles`
    },
    {
      key: `/${retailerPlatform}/campaigns`,
      name: profileName,
      href: `/${retailerPlatform}/campaigns?profileId=${profileId}`
    },
    {
      key: `/${retailerPlatform}/ads`,
      name: campaignName,
      href: `/${retailerPlatform}/ads?campaignId=${campaignId}&profileId=${profileId}&isSba=${
        adName === 'Sba Ads' ? true : false
      }`
    },
    {
      key: `/${retailerPlatform}/keywords`,
      name: adName,
      href: `/${retailerPlatform}/keywords?campaignId=${campaignId}&profileId=${profileId}&adGroupId=${adGroupId}&adName=${adName}`
    }
  ];

  const crumbs = getCrumbs();

  function getCrumbs() {
    const currentLocation = location.pathname;
    const crumbs: React.ReactElement[] = [];

    if (!profileId) {
      return null;
    }

    crumbModel.forEach((crumbModel) => {
      if (!!crumbModel.name) {
        if (crumbModel.key === currentLocation) {
          crumbs.push(<Typography key={crumbModel.key}>{crumbModel.name}</Typography>);
        } else {
          crumbs.push(
            <Link to={crumbModel.href} component={RouterLink} key={crumbModel.key}>
              {crumbModel.name}
            </Link>
          );
        }
      }
    });

    return crumbs;
  }

  return (
    <Stack>
      {isLoading ? (
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Skeleton sx={{ minWidth: 350 }} />
        </Stack>
      ) : (
        <MUIBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {crumbs}
        </MUIBreadcrumbs>
      )}
    </Stack>
  );
};

export default WalmartAndSamsClubBreadCrumbs;
