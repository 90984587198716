import {
  Pageable,
  Filter,
  buildPageableFilters,
  Sort,
  convertOperatorToComparator,
  SortDirections,
  DEFAULT_ROW_COUNT,
  processSortsFromGrid,
  convertGridFilterModelToCustomFilters
} from '../types/pageable';
import { FC, useCallback, useEffect, useState } from 'react';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { isEqual } from 'lodash';

const defaultPageable: Pageable = {
  filters: [],
  sorts: [],
  limit: DEFAULT_ROW_COUNT,
  offset: 0
};

export const usePageable = (params?: any) => {
  const [breadcrumbParams, setBreadcrumbParams] = useState<{}>();
  const [filtersFromGrid, setFiltersFromGrid] = useState<Filter[]>();
  const [filters, setFilters] = useState<Filter[]>();
  const [sorts, setSorts] = useState<Sort[]>();
  const [limit, setLimit] = useState(DEFAULT_ROW_COUNT);
  const [offset, setOffset] = useState(0);
  const [pageable, setPageable] = useState<Pageable>();

  useEffect(() => {
    if (!filtersFromGrid) {
      return;
    }

    let updatedFilters: Filter[] = [];

    if (breadcrumbParams) {
      updatedFilters = buildPageableFilters({ ...breadcrumbParams });
    }

    if (filtersFromGrid && filtersFromGrid.length > 0) {
      filtersFromGrid.forEach((filter) => {
        const existingIndex = updatedFilters.findIndex((f) => f.column === filter.column);
        if (existingIndex >= 0) {
          updatedFilters[existingIndex] = filter;
        } else {
          updatedFilters.push(filter);
        }
      });
    }

    setFilters(updatedFilters);
  }, [filtersFromGrid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!filters) {
      return;
    }

    const filtersAreEqual = isEqual(filters, pageable?.filters);
    const sortsAreEqual = isEqual(sorts, pageable?.sorts);
    const limitIsEqual = limit === pageable?.limit;
    const offsetIsEqual = offset === pageable?.offset;

    if (filtersAreEqual && sortsAreEqual && limitIsEqual && offsetIsEqual) {
      return;
    }

    setPageable({
      filters: filters,
      sorts: sorts,
      limit: limit,
      offset: offset
    });
  }, [filters, sorts, limit, offset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterModelChange = (filterModel: GridFilterModel, searchableColumns?: string[]) => {
    const newFilters = convertGridFilterModelToCustomFilters(filterModel, searchableColumns);

    // problem: this wipes out the pageableParams from the filters, cuz it just updates
    setFiltersFromGrid(newFilters);
  };

  const handleSortModelChange = (sortModel?: GridSortModel) => {
    if (!sortModel) {
      return;
    }

    const newSorts = processSortsFromGrid(sortModel);

    setSorts(newSorts);
  };

  const handlePageChange = (newPage: number) => {
    setOffset(newPage * limit);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setLimit(newPageSize);
  };

  return {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    setSorts,
    handlePageChange,
    handlePageSizeChange
  };
};
