import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Platform } from '../../../../shared/types/platforms';

interface AdGroupNameLinkProps {
  automatedActionName: string;
  automatedActionId: string;
}

const AutomatedActionNameLink = (props: AdGroupNameLinkProps) => {
  const { automatedActionName, automatedActionId } = props;

  const [searchParams] = useSearchParams();

  let profileId = searchParams.get('profileId');

  return (
    <Link
      to={`/${Platform.AMAZON.value}/automated-actions?profileId=${profileId}&automatedActionId=${automatedActionId}`}
      sx={{
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whitespace: 'nowrap',
        color: 'text.primary'
      }}
      component={RouterLink}
    >
      {automatedActionName}
    </Link>
  );
};

export const renderAutomatedActionName = (params: GridRenderCellParams) => {
  return <AutomatedActionNameLink automatedActionName={(params?.value as string) || ''} automatedActionId={params.row.id} />;
};
