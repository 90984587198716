import LineWeightIcon from '@mui/icons-material/LineWeight';
import { Box, Button, Typography } from '@mui/material';
import { GridPagination, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid-premium';

interface PerformanceGridFooterProps {
  selectionModel: any[];
  onColumnAutoResize: () => void;
}

export function PerformanceGridFooter({ selectionModel, onColumnAutoResize }: PerformanceGridFooterProps) {
  return (
    <Box display="flex" justifyContent={'flex-end'}>
      {selectionModel.length > 0 ? (
        <Box display="flex" alignItems="center" ml={2}>
          <Typography variant="body2" color="text.secondary">
            {selectionModel.length} {selectionModel.length === 1 ? 'row' : 'rows'} selected
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          mr: 'auto'
        }}
      ></Box>
      <Button
        startIcon={<LineWeightIcon sx={{ transform: 'rotate(90deg)' }} />}
        size="small"
        variant="text"
        color="primary"
        onClick={() => onColumnAutoResize()}
      >
        Autosize Columns
      </Button>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridPagination />
    </Box>
  );
}
