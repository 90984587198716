import { useCallback, useMemo } from 'react';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/Delete';
import { renderAsCurrencyFormatter } from './grid-components/renderer/renderAsCurrencyFormat';

interface AddedKeywordsTableProps {
  keywords: any;
  setKeywords: (keywords: any) => void;
  isBidEditable?: boolean;
}

const AddedKeywordsTable = ({ keywords, setKeywords, isBidEditable }: AddedKeywordsTableProps) => {
  const deleteKeyword = useCallback(
    (id: GridRowId) => () => {
      const updatedKeywordList = keywords.filter((keyword: any) => {
        return keyword.id !== id;
      });
      setKeywords(updatedKeywordList);
    },
    [setKeywords, keywords]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      if (newRow.bid === oldRow.bid && newRow.state === oldRow.state) {
        return oldRow;
      }

      const id = keywords.findIndex((o: any) => o.id === newRow?.id);
      const newData = keywords;
      newData[id].bid = newRow.bid;
      setKeywords(newData);

      return newRow;
    },
    [setKeywords, keywords]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo<Array<GridColDef>>(
    () => [
      {
        field: 'keywordText',
        headerName: 'Keyword Text',
        width: 200,
        editable: false,
        disableReorder: true,
        sortable: false,
        resizable: false,
        filterable: false
      },
      {
        field: 'matchType',
        headerName: 'Match Type',
        width: 100,
        editable: false,
        disableReorder: true,
        sortable: false,
        resizable: false,
        filterable: false
      },
      {
        field: 'bid',
        headerName: 'Bid',
        width: 100,
        editable: isBidEditable,
        disableReorder: true,
        sortable: false,
        resizable: false,
        filterable: false,
        valueFormatter: renderAsCurrencyFormatter
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 80,
        getActions: (params: { id: GridRowId }) => [
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteKeyword(params.id)} />
        ]
      }
    ],
    // eslint-disable-next-line
    [deleteKeyword]
  );

  return (
    <div style={{ height: 375 }}>
      <DataGridPremium
        processRowUpdate={processRowUpdate}
        rows={keywords}
        columns={columns}
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableDensitySelector
        disableRowSelectionOnClick
        getRowId={(row) => `${row.keywordText}-${row.matchType}`}
        disableRowGrouping
      />
    </div>
  );
};

export default AddedKeywordsTable;
