import AddIcon from '@mui/icons-material/Add';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import useAmazonApi from '../../../hooks/use-amazon-api';
import {
  AmazonCampaignType,
  AmazonSponsoredProductsDynamicBidding,
  BackendDynamicBidding,
  BiddingStrategies,
  TransformDynamicBidding,
  defaultDynamicBidding
} from '../../../types/campaign';
import { UnifiedCampaignBuilderRequest } from '../../../types/campaign-builder-request';
import WarningIcon from '@mui/icons-material/Warning';

const MAX_MULTIPLIER = 900;

enum Operation {
  None = 'none',
  Increase = 'increase',
  Decrease = 'decrease',
  SetToValue = 'setToValue'
}

enum Placement {
  TopOfSearch = 'TopOfSearch',
  RestOfSearch = 'RestOfSearch',
  ProductPages = 'ProductPages'
}

const PLACEMENT_LABELS: Record<Placement, string> = {
  [Placement.TopOfSearch]: 'Top of Search',
  [Placement.RestOfSearch]: 'Rest of Search',
  [Placement.ProductPages]: 'Product Page'
};

interface PlacementState {
  operation: Operation;
  adjustmentValue: number;
}

const AdjustmentValueInput: React.FC<{
  value: number;
  onChange: (value: number) => void;
}> = ({ value, onChange }) => {
  const [error, setError] = useState(false);

  const handleChange = (values: { floatValue: any }) => {
    const { floatValue } = values;

    if (floatValue > MAX_MULTIPLIER) {
      setError(true);
    } else {
      setError(false);
    }

    onChange(floatValue);
  };

  return (
    <NumericFormat
      label="Adjustment Value"
      customInput={TextField}
      id="outlined-adornment-weight"
      thousandSeparator=","
      value={value}
      size="small"
      decimalScale={2}
      allowNegative={false}
      error={error}
      helperText={error ? `Multiplier cannot exceed ${MAX_MULTIPLIER}%` : ''}
      onValueChange={handleChange}
      onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        onChange(Number(event.target.value));
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box>%</Box>
          </InputAdornment>
        )
      }}
    />
  );
};

const PlacementBulkActionsButton = (props: any) => {
  const { selectionModelHasRows, handleMenuClose } = props;
  const apiRef = useGridApiContext();
  const { updateCampaigns } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const numberOfSelectedRows = apiRef.current.getSelectedRows()?.size || 0;

  const [placementStates, setPlacementStates] = useState<Record<Placement, PlacementState>>({
    [Placement.TopOfSearch]: { operation: Operation.None, adjustmentValue: 0 },
    [Placement.RestOfSearch]: { operation: Operation.None, adjustmentValue: 0 },
    [Placement.ProductPages]: { operation: Operation.None, adjustmentValue: 0 }
  });

  const handleUpdateMultiplier = (currentValue: number, operation: Operation, userInputValue: number) => {
    let newValue: number = 0;

    switch (operation) {
      case Operation.Increase:
        newValue = currentValue + currentValue * (userInputValue / 100);
        break;
      case Operation.Decrease:
        newValue = currentValue - currentValue * (userInputValue / 100);
        break;
      case Operation.SetToValue:
        newValue = userInputValue;
        break;
      default:
        break;
    }

    if (newValue > MAX_MULTIPLIER) {
      newValue = MAX_MULTIPLIER;
    }

    return Math.round(newValue);
  };

  const onClose = () => {
    setIsDialogOpen(false);
    setPlacementStates({
      [Placement.TopOfSearch]: { operation: Operation.None, adjustmentValue: 0 },
      [Placement.RestOfSearch]: { operation: Operation.None, adjustmentValue: 0 },
      [Placement.ProductPages]: { operation: Operation.None, adjustmentValue: 0 }
    });
    handleMenuClose();
  };

  const handleSaveButtonClick = async () => {
    const selectedCampaigns = apiRef.current.getSelectedRows();
    const updatedCampaigns: UnifiedCampaignBuilderRequest[] = [];

    selectedCampaigns.forEach((campaign: any) => {
      const currentDynamicBidding: BackendDynamicBidding =
        campaign.amazonSponsoredProductsDynamicBidding || TransformDynamicBidding.toBackend(defaultDynamicBidding);

      const updatedPlacementBidding = Object.entries(placementStates).reduce(
        (acc, [placement, state]) => {
          const currentMultiplier =
            currentDynamicBidding.placementBidding.find((p: any) => p.placement === placement)?.percentage || 0;

          if (state.operation !== Operation.None) {
            acc[placement as Placement] = handleUpdateMultiplier(currentMultiplier, state.operation, state.adjustmentValue);
          } else {
            acc[placement as Placement] = currentMultiplier;
          }

          return acc;
        },
        {} as Record<Placement, number>
      );

      const updatedDynamicBidding: AmazonSponsoredProductsDynamicBidding = {
        strategy: campaign.amazonSponsoredProductsDynamicBidding?.strategy || BiddingStrategies.DownOnly.value,
        placementBidding: updatedPlacementBidding
      };

      updatedCampaigns.push({
        ...campaign,
        amazonSponsoredProductsDynamicBidding: TransformDynamicBidding.toBackend(updatedDynamicBidding)
      });
    });

    const response = await updateCampaigns(updatedCampaigns);

    if (response.success) {
      apiRef.current.updateRows(response.body);
      enqueueSnackbar(`Successfully updated ${numberOfSelectedRows} campaign${numberOfSelectedRows === 1 ? '' : 's'}`, {
        variant: 'success'
      });
    } else {
      enqueueSnackbar(`Failed to update ${numberOfSelectedRows} campaign${numberOfSelectedRows === 1 ? '' : 's'}`, {
        variant: 'error'
      });
    }

    apiRef.current.setRowSelectionModel([]);
    onClose();
  };

  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const campaignsThatAreNotSPCount = Array.from(apiRef.current.getSelectedRows().values()).filter(
    (campaign: any) => campaign.campaignType !== AmazonCampaignType.SPONSORED_PRODUCTS
  ).length;

  return (
    <>
      <MenuItem sx={{ fontSize: 14 }} onClick={handleClick} disabled={!selectionModelHasRows}>
        <ShowChartIcon />
        Update Placements
      </MenuItem>
      {isDialogOpen && (
        <Dialog open={isDialogOpen} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle>Placement Bulk Actions</DialogTitle>
          <DialogContent>
            <Typography variant="caption" component="div" sx={{ mb: 2 }} color="text.secondary">
              {numberOfSelectedRows} row{numberOfSelectedRows === 1 ? '' : 's'} selected
            </Typography>
            {campaignsThatAreNotSPCount > 0 && (
              <Typography variant="caption" component="div" sx={{ mb: 2 }} color="text.secondary">
                <Box sx={{ display: 'flex' }}>
                  <WarningIcon fontSize="small" color="warning" sx={{ mr: 0.5 }} />
                  {campaignsThatAreNotSPCount} row{campaignsThatAreNotSPCount === 1 ? ' is' : 's are'} not Sponsored Product (SP)
                  campaigns and will be ignored.
                </Box>
              </Typography>
            )}

            {Object.values(Placement).map((placement) => (
              <Box key={placement}>
                <Typography>{PLACEMENT_LABELS[placement]}</Typography>
                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                  <Grid item xs={8}>
                    <Select
                      fullWidth
                      size="small"
                      value={placementStates[placement].operation}
                      onChange={(e) =>
                        setPlacementStates((prev) => ({
                          ...prev,
                          [placement]: { ...prev[placement], operation: e.target.value as Operation }
                        }))
                      }
                    >
                      <MenuItem value={Operation.None}>None</MenuItem>
                      <MenuItem value={Operation.Increase}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <AddIcon color="success" sx={{ mr: 0.75 }} />
                          <Typography>Increase by</Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={Operation.Decrease}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <RemoveIcon color="error" sx={{ mr: 0.75 }} />
                          <Typography>Decrease by</Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={Operation.SetToValue}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <DragHandleIcon color="primary" sx={{ mr: 0.75 }} />
                          <Typography>Set to value</Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <AdjustmentValueInput
                      value={placementStates[placement].adjustmentValue}
                      onChange={(value) =>
                        setPlacementStates((prev) => ({
                          ...prev,
                          [placement]: { ...prev[placement], adjustmentValue: value }
                        }))
                      }
                    />
                  </Grid>
                </Grid>
                <br />
              </Box>
            ))}
          </DialogContent>
          <DialogActions sx={{ mt: 4 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleSaveButtonClick}
              disabled={Object.values(placementStates).every((state) => state.operation === Operation.None)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PlacementBulkActionsButton;
