import { getGridSingleSelectOperators, getGridStringOperators, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { renderState } from '../renderer/renderState';
import { TargetCell } from '../renderer/targets/expressions/TargetCell';
import { preProcessBidEditCellProps, renderEditBid } from '../renderer/renderEditBid';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { renderActiveAction } from '../renderer/renderActiveToggle';
import { DEFAULT_STATE_OPTIONS } from '../../../types/state-options';
import { renderSyncStatus } from '../../../../shared/components/grid-components/renderer/renderSyncStatus';
import { METRIC_COLUMNS } from './metric-columns';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';

const targetTypes = [
  {
    label: 'Keyword',
    value: 'keyword'
  },
  {
    label: 'Product - Exact',
    value: 'productExact'
  },
  {
    label: 'Product - Expanded',
    value: 'productExpanded'
  },
  {
    label: 'Category',
    value: 'category'
  }
];

function RenderActions(params: GridRowParams) {
  const { updateTarget } = useAmazonApi();

  const handleUpdateEntity = (entityId: string, state: 'enabled' | 'paused') => {
    return updateTarget({ targetId: entityId, state: state });
  };

  return renderActiveAction({ entityIdKey: 'targetId', updateEntityFunction: handleUpdateEntity })(params);
}

export const createColumns = (productInfo: any, isLoadingProductInfo: boolean) => {
  return [
    {
      field: 'actions',
      headerName: 'Active',
      filterable: false,
      type: 'actions',
      width: 80,
      getActions: RenderActions
    },
    {
      field: 'searchableTargetText',
      headerName: 'Target',
      width: 300,
      minWidth: 150,
      editable: false,
      align: 'left',
      renderCell: (params) => {
        return <TargetCell params={params} itemDetails={productInfo} isLoadingItemDetails={isLoadingProductInfo} />;
      }
    },
    {
      field: 'targetType',
      headerName: 'Target Type',
      width: 160,
      minWidth: 80,
      editable: false,
      type: 'singleSelect',
      valueOptions: ['ProductExact', 'Keyword', 'ProductExpanded', 'Category', 'Contextual', 'Audience'],
      valueFormatter: ({ value }) =>
        value ? targetTypes.find((targetType) => targetType.value.toLowerCase() === value.toLowerCase())?.label : value,
      align: 'left'
    },
    {
      field: 'bid',
      headerName: 'Bid',
      width: 100,
      minWidth: 60,
      type: 'number',
      headerAlign: 'left',
      editable: true,
      valueFormatter: renderAsCurrencyFormatter,
      cellClassName: () => {
        return 'numeric-edit-cell';
      },
      renderEditCell: renderEditBid,
      preProcessEditCellProps: preProcessBidEditCellProps
    },
    {
      field: 'state',
      headerName: 'State',
      type: 'singleSelect',
      valueOptions: ['Enabled', 'Paused', 'Archived'],
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
      renderCell: renderState({ stateOptions: DEFAULT_STATE_OPTIONS }),
      width: 140,
      minWidth: 110
    },
    {
      field: 'syncState',
      headerName: 'Sync Status',
      width: 140,
      minWidth: 110,
      editable: false,
      type: 'singleSelect',
      align: 'left',
      valueOptions: ['Synced', 'Pending', 'Error'],
      renderCell: renderSyncStatus
    },
    {
      field: 'campaignName',
      headerName: 'Campaign Name',
      width: 300,
      minWidth: 200,
      type: 'string'
    },
    {
      field: 'matchType',
      headerName: 'Keyword Match Type',
      width: 160,
      minWidth: 120,
      type: 'singleSelect',
      valueOptions: ['Exact', 'Phrase', 'Broad'],
      editable: false,
      align: 'left',
      valueFormatter: ({ value }) => value ?? 'N/A'
    },
    {
      field: 'targetId',
      headerName: 'Target ID',
      width: 100,
      minWidth: 80,
      type: 'string',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
    },
    {
      field: 'adGroupId',
      headerName: 'Ad Group ID',
      width: 100,
      minWidth: 80,
      type: 'string',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
    },
    {
      field: 'campaignId',
      headerName: 'Campaign ID',
      width: 100,
      minWidth: 80,
      type: 'string',
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
    },
    {
      field: 'adGroupName',
      headerName: 'Ad Group Name',
      width: 100,
      minWidth: 80,
      type: 'string'
    },
    {
      field: 'discoveredBy',
      headerName: 'Discovered By',
      width: 100,
      minWidth: 80,
      type: 'string'
    },
    ...METRIC_COLUMNS
  ] as GridColDef[];
};
