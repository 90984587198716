import { GridColDef } from '@mui/x-data-grid-premium';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';

export const METRIC_COLUMNS: GridColDef[] = [
  {
    field: 'attributedRevenue',
    headerName: 'Attr. Rev.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'returnOnAdSpend',
    headerName: 'RoAS',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'impressions',
    headerName: 'Imp.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left'
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left'
  },
  {
    field: 'clickThroughRate',
    headerName: 'CTR',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? `${(value * 100).toFixed(2)}%` : value)
  },
  {
    field: 'conversionRate',
    headerName: 'Conv. Rate',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: ({ value }) => (value ? `${(value * 100).toFixed(2)}%` : value)
  },
  {
    field: 'costPerClick',
    headerName: 'CPC',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'costPerConversion',
    headerName: 'Cost/Conv.',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'adSpend',
    headerName: 'Ad Spend',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'conversions',
    headerName: 'Conversions',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left'
  },
  {
    field: 'unitsSale',
    headerName: 'Units',
    width: 100,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left'
  }
];
