import { FC, useState } from 'react';
import { Box, Button, Typography, Avatar, Tooltip, Grid } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface StepButtonLabelProps {
  showStep: boolean;
  handleStepButtonClick: () => void;
  stepNumber: number;
  stepLabel: string;
}

export const CampaignBuilderStepLabelButton: FC<StepButtonLabelProps> = (props) => {
  const { showStep, handleStepButtonClick, stepNumber, stepLabel } = props;

  return (
    <Tooltip title={'Click to show/hide'} placement="left">
      <Button sx={{ display: 'flex', alignItems: 'center', width: '100%', px: 0 }} onClick={handleStepButtonClick}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                  backgroundColor: 'secondary.main',
                  color: 'secondary.contrastText'
                }}
                variant="rounded"
              >
                {stepNumber}
              </Avatar>
              <Typography variant="h6" sx={{ ml: 2 }}>
                {stepLabel}
              </Typography>
            </Box>
          </Grid>
          <Grid item>{showStep ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</Grid>
        </Grid>
      </Button>
    </Tooltip>
  );
};
