import { GridColDef, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { dateColumnType } from '../../../../shared/components/grid-components/renderer/campaign/date-cell-components';
import {
  preProcessDailyBudgetCellProps,
  renderEditMonetaryCell
} from '../../../../shared/components/grid-components/renderer/campaign/renderMonetaryEditCell';
import { renderCampaignType } from '../renderer/renderCampaignType';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { renderDynamicBiddingPreview } from '../renderer/dynamic-bidding/render-dynamic-bidding-preview';
import { AmazonCampaignType } from '../../../types/campaign';

export const CAMPAIGN_PREVIEW_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    editable: false,
    align: 'left'
  },
  {
    field: 'campaignType',
    headerName: 'Type',
    width: 60,
    editable: false,
    type: 'singleSelect',
    valueOptions: [AmazonCampaignType.SPONSORED_PRODUCTS],
    align: 'left',
    renderCell: renderCampaignType
  },
  {
    field: 'targetingType',
    headerName: 'Targeting Type',
    width: 70,
    editable: false,
    align: 'left'
  },
  {
    field: 'dailyBudget',
    headerName: 'Daily Budget',
    width: 80,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => preProcessDailyBudgetCellProps(params, 1),
    renderEditCell: renderEditMonetaryCell,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'amazonSponsoredProductsDynamicBidding',
    headerName: 'Dynamic Bidding',
    width: 100,
    editable: false,
    align: 'left',
    renderCell: renderDynamicBiddingPreview
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    ...dateColumnType,
    width: 100,
    type: 'date',
    filterable: false,
    editable: true
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    ...dateColumnType,
    width: 100,
    type: 'date',
    filterable: false,
    editable: true
  }
];
