import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import useAdsApi from '../../../../../shared/hooks/use-walmart-sams-club-api';
import {
  BiddingStrategyHelperTextDynamic,
  WalmartBiddingStrategy,
  WalmartBiddingStrategyStrategy
} from '../../../../../shared/types/walmart-sams-club/campaign';
import { WalmartBiddingStrategyLabelMap } from './render-bidding-strategy';

interface BiddingStrategyDialogProps {
  isOpen: boolean;
  toggle: () => void;
  campaign: any;
  biddingStrategy: WalmartBiddingStrategy;
  setWalmartBiddingStrategy: (biddingStrategy: WalmartBiddingStrategy) => void;
}

const BiddingStrategyDialog = (props: BiddingStrategyDialogProps) => {
  const { isOpen, toggle, campaign, biddingStrategy, setWalmartBiddingStrategy } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { updateCampaign } = useAdsApi();
  const apiRef = useGridApiContext();

  const [biddingStrategyRadioChoice, setBiddingStrategyRadioChoice] = useState<WalmartBiddingStrategy>({
    strategy: biddingStrategy.strategy.toUpperCase() as WalmartBiddingStrategyStrategy
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBiddingStrategyRadioChoice({
      strategy: event.target.value as WalmartBiddingStrategyStrategy
    } as WalmartBiddingStrategy);
  };

  const handleSaveButtonClick = useCallback(async () => {
    const updatedRow = { campaignId: campaign.campaignId, biddingStrategy: biddingStrategyRadioChoice };

    setIsLoading(true);
    setIsSaveButtonDisabled(true);

    const response = await updateCampaign(updatedRow);

    if (response.success) {
      enqueueSnackbar('Campaign updated successfully', { variant: 'success' });

      if (!!apiRef) {
        apiRef.current?.updateRows([{ campaignId: campaign.campaignId, biddingStrategy: biddingStrategyRadioChoice }]);
      }

      setWalmartBiddingStrategy(biddingStrategyRadioChoice);
    } else {
      enqueueSnackbar('Error updating campaign', { variant: 'error' });
    }

    setIsLoading(false);
    setIsSaveButtonDisabled(false);

    toggle();
  }, [campaign, biddingStrategyRadioChoice]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth>
      <DialogTitle> Bidding Strategy - {campaign.name}</DialogTitle>
      <DialogContent>
        <Box>
          <Alert severity="warning" sx={{ mb: 1 }}>
            If running intraday bidding, we recommend using a Fixed bidding strategy to avoid unintended swings in bid values.
          </Alert>
          <Typography variant={'subtitle1'} color="text.secondary">
            Strategy
          </Typography>
          <FormControl>
            <RadioGroup name="radio-buttons-group" value={biddingStrategyRadioChoice.strategy} onChange={handleOptionChange}>
              <FormControlLabel
                control={<Radio />}
                value={WalmartBiddingStrategyStrategy.Fixed}
                label={WalmartBiddingStrategyLabelMap[WalmartBiddingStrategyStrategy.Fixed]}
              />
              <FormControlLabel
                control={<Radio />}
                value={WalmartBiddingStrategyStrategy.Dynamic}
                label={WalmartBiddingStrategyLabelMap[WalmartBiddingStrategyStrategy.Dynamic]}
              />
              <FormHelperText sx={{ pl: 2 }} variant="filled" id="bidding-strategy-radio-helper-text">
                {BiddingStrategyHelperTextDynamic}
              </FormHelperText>
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>Cancel</Button>
        <Button onClick={handleSaveButtonClick} variant="contained" disabled={isSaveButtonDisabled}>
          Save
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default BiddingStrategyDialog;
