import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const DaypartingLegend: FC = () => {
  return (
    <Box display="flex" sx={{ mt: 2 }} flexDirection={'row'} justifyContent="center">
      <Box display="flex" alignItems="center">
        <Box sx={{ mr: 1, width: 20, height: 20, bgcolor: 'primary.main' }} />
        <Typography variant="body2" sx={{ mr: 2, width: '50px' }}>
          Enabled
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ mr: 1, width: 20, height: 20, bgcolor: 'gray' }} />
        <Typography variant="body2" sx={{ mr: 2 }}>
          Paused by Dayparting
        </Typography>
      </Box>
    </Box>
  );
};
