import { Alert, Box, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import { FormikValues } from 'formik';
import {
  BiddingStrategyHelperTextDynamic,
  WalmartBiddingStrategy,
  WalmartBiddingStrategyStrategy
} from '../../../../shared/types/walmart-sams-club/campaign';
import { WalmartBiddingStrategyLabelMap } from '../../../components/grid-components/renderer/campaign/render-bidding-strategy';

const BiddingStrategySetting = (props: { formik: FormikValues }) => {
  const { formik } = props;

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('biddingStrategy', {
      strategy: event.target.value as WalmartBiddingStrategyStrategy
    } as WalmartBiddingStrategy);
  };

  return (
    <Box>
      <Alert severity="warning" sx={{ mb: 1 }}>
        If running intraday bidding, we recommend using a Fixed bidding strategy to avoid unintended swings in bid values.
      </Alert>
      <FormControl>
        <RadioGroup
          name="bidding-strategy-radio-group"
          value={formik.values.biddingStrategy.strategy}
          onChange={handleOptionChange}
        >
          <FormControlLabel
            control={<Radio />}
            value={WalmartBiddingStrategyStrategy.Fixed}
            label={WalmartBiddingStrategyLabelMap[WalmartBiddingStrategyStrategy.Fixed]}
          />
          <FormControlLabel
            control={<Radio />}
            value={WalmartBiddingStrategyStrategy.Dynamic}
            label={WalmartBiddingStrategyLabelMap[WalmartBiddingStrategyStrategy.Dynamic]}
          />
          <FormHelperText sx={{ pl: 2 }} variant="filled" id="bidding-strategy-radio-helper-text">
            {BiddingStrategyHelperTextDynamic}
          </FormHelperText>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default BiddingStrategySetting;
