import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import { useDateConfig } from '../../../../shared/hooks/use-date-config';
import useDialog from '../../../../shared/hooks/use-dialog';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { AD_COLUMNS, createAdColumns } from '../../../components/grid-components/column-configurations/ad-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { CreateAdsDialog } from './create-dialogs/create-ads-dialog';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AmazonCampaignTypeValue } from '../../../types/campaign';

interface OverviewAdsProps {
  campaignType: AmazonCampaignTypeValue | undefined;
}

export const OverviewAds = (props: OverviewAdsProps) => {
  const { campaignType } = props;

  const { getAds } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [ads, setAds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  let [searchParams] = useSearchParams();
  let campaignId = searchParams.get('campaignId');
  let adGroupId = searchParams.get('adGroupId');

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: adGroupId ? GridConfigSettings.AMAZON_AD_GROUP_OVERVIEW_ADS : GridConfigSettings.AMAZON_CAMPAIGN_OVERVIEW_ADS,
    breadCrumbParams: { campaignId, adGroupId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const { isShowing, toggle: toggleAddAdsDialog } = useDialog();

  const processRowUpdate = useCallback((newRow: any, oldRow: any) => {
    //Execute Put Request here
    return newRow;
  }, []);

  useEffect(() => {
    setBreadcrumbParams({ campaignId, adGroupId });
  }, [campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    fetchAds();
  }, [pageable, settingsLoading, dateSettingsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAds = async () => {
    if (!pageable) {
      return;
    }

    setIsLoading(true);

    const response = await getAds(pageable, dateSettings.beginDate, dateSettings.endDate);

    if (response.success) {
      setAds(response.body.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const adColumns = useMemo(() => {
    if (!campaignType) {
      return [];
    }

    return createAdColumns(campaignType);
  }, [campaignType]);

  return (
    <Box>
      <Box display="flex" alignItems={'center'}>
        <Typography variant="h6">Ads</Typography>
        <Box
          sx={{
            mr: 'auto'
          }}
        ></Box>
        <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleAddAdsDialog()} sx={{ mb: '0.5rem' }}>
          Create Ads
        </Button>
      </Box>
      {!initialSettings || settingsLoading ? (
        <DataGridPremium
          rows={[]}
          columns={AD_COLUMNS}
          disableRowGrouping
          loading={true}
          processRowUpdate={processRowUpdate}
          initialState={{}}
          key={uuidv4()}
        />
      ) : (
        <PerformanceGrid
          processRowUpdate={processRowUpdate}
          loading={isLoading}
          rows={ads}
          columns={adColumns}
          initialState={initialSettings.config}
          saveGridConfig={saveGridConfig}
          dateConfig={{
            dateSettings: dateSettings,
            dateSettingsLoading: dateSettingsLoading,
            saveDateConfig: saveDateConfig
          }}
          getRowId={(row: any) => row.adId}
          handleFilterModelChange={handleFilterModelChange}
          handleSortModelChange={handleSortModelChange}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={rowCount}
          otherDataGridProps={{ autoHeight: true }}
        />
      )}
      {isShowing && <CreateAdsDialog isShowing={isShowing} toggle={toggleAddAdsDialog} refreshAds={fetchAds} />}
    </Box>
  );
};
