import { Box, Typography } from '@mui/material';
import { getDisplayPercent } from '../../../../types/budget';
import { getOffPaceFontColor } from '../../pacing-font-color';

function RenderPacingPercent(props: { actualPacingValue: number; targetPacingValue: number }) {
  const { actualPacingValue, targetPacingValue } = props;
  if (actualPacingValue === 0 || targetPacingValue === 0) return <Box sx={{ p: 2, width: '100%' }}>-</Box>;

  const displayPercent = getDisplayPercent(actualPacingValue, targetPacingValue);

  if (isNaN(displayPercent)) return <Box sx={{ p: 2, width: '100%' }}></Box>;

  return (
    <Box sx={{ p: 2, width: '100%' }}>
      <Typography variant="body2" sx={{ color: getOffPaceFontColor(Number(displayPercent)), float: 'right' }}>
        {displayPercent}%
      </Typography>
    </Box>
  );
}

export function renderPacingPercent(actualPacingValue: number, targetPacingValue: number) {
  return <RenderPacingPercent actualPacingValue={actualPacingValue} targetPacingValue={targetPacingValue} />;
}
