import { useEffect, useState } from 'react';
import useAdsApi from '../../shared/hooks/use-walmart-sams-club-api';
import { Review as TReview, orderByReviewIdDesc } from '../../shared/types/walmart-sams-club/review';
import { Campaign } from '../../shared/types/walmart-sams-club/campaign';

export const useSbReview = (profileId: number | null, campaignId: number | null, adGroupId?: number | null) => {
  const { getSbaReviews, getAdGroups, getCampaign } = useAdsApi();
  const [localAdGroupId, setLocalAdGroupId] = useState(adGroupId || 0);
  const [review, setReview] = useState<TReview>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [allowAddItems, setAllowAddItems] = useState(true);
  const [allowUpdateProfile, setAllowUpdateProfile] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const initialize = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }

    if (!profileId || !campaignId) {
      setIsLoading(false);
      return;
    }

    await Promise.all([fetchReview(), fetchCampaign()]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!localAdGroupId) {
      fetchAdGroupId();
      return;
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, campaignId, localAdGroupId]);

  useEffect(() => {
    if (!review) {
      return;
    }

    if (review?.reviewStatus === 'Pending' || review?.reviewStatus === 'InProgress') {
      setAllowAddItems(false);
    }
  }, [review]);

  useEffect(() => {
    if (campaign?.status === 'Live') {
      setAllowUpdateProfile(false);
    }
  }, [campaign]);

  const fetchReview = async () => {
    const sbReviewResponse = await getSbaReviews(profileId, campaignId, localAdGroupId);
    const reviewArray = sbReviewResponse?.body ?? [];
    if (reviewArray.length === 0) {
      return;
    }
    reviewArray.sort(orderByReviewIdDesc);
    setReview(reviewArray[0]);
  };

  const fetchCampaign = async () => {
    const campaignResponse = await getCampaign(profileId, campaignId);

    if (campaignResponse.success) {
      setCampaign(campaignResponse?.body);
    }
  };

  const fetchAdGroupId = async () => {
    const adGroupsResponse = await getAdGroups(profileId, campaignId, null, null);

    if (!adGroupsResponse?.success) {
      return;
    }

    setLocalAdGroupId(Number(adGroupsResponse?.body[0]?.adGroupId || 0));
  };

  return { allowAddItems, allowUpdateProfile, isLoading };
};
