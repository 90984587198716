import { Box, Container, Link, Paper, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import { InitializedPerformanceGrid } from '../../../shared/components/performance-grid/initialized-performance-grid';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { useAmazonApi } from '../../hooks/use-amazon-api';
import { getUpdateCampaignRequest } from '../../types/campaign';
import { formatAsCurrency } from '../../../shared/utilities/numeric-format-utilities';
import CampaignBulkActions from '../campaigns/bulk-actions/campaign-bulk-actions';
import { AddCampaignsToBudgetGroupActions } from './add-campaigns-to-budget-group-actions';

const BudgetGroupOverview: FC = () => {
  const { getCampaigns, updateCampaign, getCampaignBudgetGroupOverview } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const [campaignBudgetOverview, setCampaignBudgetOverview] = useState<any>({});
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');
  let campaignBudgetGroupId = searchParams.get('campaignBudgetGroupId');

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);

    if (changedFields.length == 0) {
      return oldRow;
    }

    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);
    const response = await updateCampaign({ campaignId: newRow.campaignId, ...updateCampaignRequest });

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBudgetGroupOverview = async () => {
    if (!campaignBudgetGroupId) {
      return;
    }

    const response = await getCampaignBudgetGroupOverview(campaignBudgetGroupId);

    if (response.success) {
      setCampaignBudgetOverview(response.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchBudgetGroupOverview();
  }, [campaignBudgetGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!profileId || !campaignBudgetGroupId) {
      navigate('/amazon/campaigns');
    }
  }, [profileId, campaignBudgetGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Page title="Campaigns">
        <Container maxWidth="xl" sx={{ height: '100%' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Paper elevation={0} sx={{ p: 2, width: '100%' }}>
                <Box display="flex" flexDirection={'column'}>
                  <Typography variant="h5" mr={2}>
                    Budget Group: {campaignBudgetOverview?.name || 'Loading...'}
                  </Typography>
                  <Box
                    sx={{
                      mr: 'auto'
                    }}
                  ></Box>
                  <Typography variant="h6" mr={2}>
                    Current Month Budget: {formatAsCurrency(campaignBudgetOverview?.currentMonthBudget)}
                  </Typography>
                  <Link href={`/amazon/budgets?profileId=${profileId}`} component={Link} sx={{ maxWidth: '220px' }}>
                    Click here to manage budgets
                  </Link>
                </Box>
              </Paper>
            </Box>
            <Box height={'800px'}>
              <InitializedPerformanceGrid
                breadCrumbParams={{ campaignBudgetGroupId }}
                settingsKey={GridConfigSettings.AMAZON_CAMPAIGN}
                columns={CAMPAIGN_COLUMNS}
                processRowUpdate={processRowUpdate}
                fetchEntity={getCampaigns}
                shouldIncludeChartMetrics={true}
                rowId={'campaignId'}
                bottomMargin={30}
                getRowClassName={(params: any) => `row-status--${params.row.status}`}
                bulkActions={(refreshEntities: () => void, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => (
                  <>
                    <AddCampaignsToBudgetGroupActions refreshEntities={refreshEntities} />
                    <CampaignBulkActions refreshCampaigns={refreshEntities} setIsLoading={setIsLoading} />
                  </>
                )}
                allowQuickFilterSearch={false}
                disableGridDateRangePicker={false}
              />
            </Box>
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default BudgetGroupOverview;
