import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import AmazonAddKeywords from '../../../../components/amazon-add-keywords';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { AmazonCampaignType } from '../../../../types/campaign';
import { KeywordRecommendationRequestBase } from '../../../../types/keyword';
import { AddProductAndCategoryTargets } from './add-product-and-category-targets';

interface AddTargetsDialogProps {
  profileId: string | null;
  campaignId: string | null;
  adGroupId?: string | null;
  isShowing: boolean;
  toggle: () => void;
  campaignTargetTypeIsKeyword?: boolean;
  campaignType?: AmazonCampaignType;
  existingTargets: any[];
  refreshTargets: () => void;
}

type SponsoredProductsTargetingExpressionPredicateType = 'AsinSameAs' | 'AsinExpandedFrom' | 'AsinCategorySameAs';

interface SponsoredProductsTargetingExpressionPredicate {
  type: SponsoredProductsTargetingExpressionPredicateType;
  value: any;
}

type TargetType = 'ProductExact' | 'ProductExpanded' | 'Category' | 'Auto';

function getTargetType(expressions: SponsoredProductsTargetingExpressionPredicate[]): TargetType {
  if (expressions.some((exp) => exp.type?.toLowerCase() === 'asinsameas')) {
    return 'ProductExact';
  }
  if (expressions.some((exp) => exp.type?.toLowerCase() === 'asinexpandedfrom')) {
    return 'ProductExpanded';
  }
  if (expressions.some((exp) => exp.type?.toLowerCase() === 'asincategorysameas')) {
    return 'Category';
  }
  return 'Auto';
}

export function buildTargetObject(target: any, adGroupId: string, campaignType?: AmazonCampaignType) {
  return {
    retailer: 'Amazon',
    campaignType: campaignType,
    state: 'Enabled',
    ...(adGroupId && { adGroupId: adGroupId }),
    ...(target.bid !== undefined && { bid: target.bid }),
    ...(target.keywordText && target.matchType && { matchType: target.matchType }),
    ...(target.keywordText && {
      keywordText: target.keywordText,
      targetType: 'Keyword'
    }),
    ...(target.expressionType && { expressionType: target.expressionType }),
    ...(target.targetingClause && {
      expressionType: 'Manual',
      expression: target.targetingClause.expression?.map((expression: any) => ({
        type: _.camelCase(expression?.type),
        value: expression?.value
      })),
      resolvedExpression: target.targetingClause.resolvedExpression?.map((resolvedExpression: any) => ({
        type: _.camelCase(resolvedExpression?.type),
        value: resolvedExpression?.value
      }))
    }),
    ...(target.targetingClause && {
      targetType: getTargetType(target.targetingClause.expression)
    })
  };
}

type ValidationResult = {
  isValid: boolean;
  errorMessage?: string;
};

const AddTargetsDialog = (props: AddTargetsDialogProps) => {
  const {
    profileId,
    campaignId,
    adGroupId,
    isShowing,
    toggle,
    campaignTargetTypeIsKeyword,
    campaignType,
    existingTargets,
    refreshTargets
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { getAdGroupKeywordRecommendations, createTargets } = useAmazonApi();
  const [suggestedKeywords, setSuggestedKeywords] = useState<any[]>([]);
  const [suggestionSort, setSuggestionSort] = useState<'CONVERSIONS' | 'CLICKS'>('CONVERSIONS');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchSuggestedKeywords = async () => {
    setIsLoading(true);

    if (!profileId || !adGroupId) {
      setIsLoading(false);
      return;
    }

    const request: KeywordRecommendationRequestBase = {
      maxRecommendations: 200,
      sortDimension: suggestionSort
    };

    const response = await getAdGroupKeywordRecommendations(profileId, adGroupId, request);

    if (response?.body) {
      setSuggestedKeywords(response.body);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSuggestedKeywords();
  }, [profileId, adGroupId, suggestionSort]); // eslint-disable-line react-hooks/exhaustive-deps

  function validateKeyword(keyword: string, matchType?: string): ValidationResult {
    keyword = keyword.trim(); // Trim leading and trailing spaces

    // Check maximum length
    if (keyword.length > 80) {
      return { isValid: false, errorMessage: 'Keyword exceeds maximum length of 80 characters. Keyword: ' + keyword };
    }

    const parts = keyword.split(/\s+|\-/).filter(Boolean); // Corrected to split by space or hyphen
    const maxParts = matchType === 'negativeExact' ? 10 : matchType === 'negative' ? 4 : 10;

    if (parts.length > maxParts) {
      return { isValid: false, errorMessage: `Keyword can have up to ${maxParts} parts. Keyword: ${keyword}` };
    }

    // Validate characters based on Unicode scope
    if (
      !new RegExp(
        `^[\\-&\\+\\[\\]\\t\\n\\r'"\dA-Za-z \u00AE\u00C1\u00C9\u00CD\u00D1\u00D3\u00DA\u00DC\u00E1\u00E9\u00ED\u00F1\u00F3\u00FA\u00FC\u00C4\u00D6\u0152\u00DF\u00E4\u00F6\u00C0\u00C2\u00C6\u00C7\u00C8\u00CA\u00CB\u00CE\u00CF\u00D4\u00D9\u00DB\u0178\u00E0\u00E2\u00E6\u00E7\u00E8\u00EA\u00EB\u00EE\u00EF\u00F4\u00F9\u00FB\u0177\u0153]*$`
      ).test(keyword)
    ) {
      return { isValid: false, errorMessage: 'Keyword contains invalid characters. Keyword: ' + keyword };
    }

    // Special character positioning checks
    if (keyword.startsWith('-') || keyword.startsWith('+') || keyword.endsWith('-') || keyword.endsWith('+')) {
      return {
        isValid: false,
        errorMessage: 'Hyphen "-" or plus "+" cannot be at the start or end of a keyword. Keyword: ' + keyword
      };
    }

    if (keyword.indexOf('.') === 0 || keyword.lastIndexOf('.') === keyword.length - 1) {
      return { isValid: false, errorMessage: 'Period "." cannot be at the start or end of a keyword. Keyword: ' + keyword };
    }

    if (/\s[\-+]\s/.test(keyword)) {
      return { isValid: false, errorMessage: 'Hyphen "-" or plus "+" cannot have spaces around them. Keyword: ' + keyword };
    }

    // If all checks pass
    return { isValid: true };
  }

  function getTargetsFromFromValues(formValues: any) {
    let targets = [];

    if (formValues.keywords && formValues.keywords.length > 0) {
      targets = formValues.keywords.map((target: any) => buildTargetObject(target, formValues.adGroupId, campaignType));
    }

    if (formValues.targets && formValues.targets.length > 0) {
      targets = formValues.targets.map((target: any) => buildTargetObject(target, formValues.adGroupId, campaignType));
    }

    return targets;
  }

  const handleSubmit = async (formValues: any) => {
    setIsSubmitting(true);

    const targetUpdateRequest = getTargetsFromFromValues(formValues);

    if (targetUpdateRequest.length === 0) {
      enqueueSnackbar('Please add targets to create', { variant: 'info' });
      setIsSubmitting(false);
      return;
    }

    //validate targets
    const validationResults = targetUpdateRequest.map((target: any) => {
      if (target.keywordText) {
        return validateKeyword(target.keywordText, target.matchType);
      } else {
        return { isValid: true };
      }
    });

    if (validationResults.some((result: { isValid: boolean }) => !result.isValid)) {
      const errorMessage = validationResults.find((result: { isValid: boolean }) => !result.isValid)?.errorMessage;
      enqueueSnackbar(errorMessage, { variant: 'error' });
      setIsSubmitting(false);
      return;
    }

    const response = await createTargets(targetUpdateRequest);

    if (response.success) {
      if (response.body?.length > 0) {
        const existingTargetsText = response.body
          .map((target: any) =>
            target.keywordText
              ? target.keywordText
              : 'Type:' + target.expression[0].type + ', Value: ' + target.expression[0].value
          )
          .join(', ');

        enqueueSnackbar(`Some targets already exist: "${existingTargetsText}". they were not created`, {
          variant: 'info',
          hideIconVariant: true
        });
      }

      enqueueSnackbar('Targets created successfully', { variant: 'success' });
      toggle();
      refreshTargets();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  return (
    <Box>
      <Formik
        initialValues={{
          adGroupId: adGroupId || '',
          userEnteredKeywords: '',
          keywords: [],
          targets: [],
          bid: 0
        }}
        onSubmit={async (formValues) => {
          await handleSubmit(formValues);
        }}
      >
        {({ values, setValues, setFieldValue, dirty, isValid, isSubmitting }) => (
          <Form>
            <Dialog open={isShowing} fullWidth maxWidth="lg" disablePortal>
              <DialogContent>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Add Targets
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Add targets to the selected Ad Group.
                </Typography>
                <Divider sx={{ my: 2 }} />
                {campaignTargetTypeIsKeyword ? (
                  <AmazonAddKeywords
                    suggestedKeywords={suggestedKeywords}
                    keywords={values.keywords}
                    setKeywords={(keywords: string[]): void => {
                      setFieldValue('keywords', keywords);
                    }}
                    suggestionSort={suggestionSort}
                    setSuggestionSort={(sort: 'CONVERSIONS' | 'CLICKS'): void => {
                      setSuggestionSort(sort);
                    }}
                    isLoading={isLoading}
                  />
                ) : (
                  <AddProductAndCategoryTargets
                    profileId={profileId}
                    campaignId={campaignId}
                    adGroupId={adGroupId || ''}
                    platformAdGroupId={null}
                    retailer={'amazon'}
                    campaignType={campaignType}
                    existingTargets={existingTargets}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={toggle} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" type="submit" color="primary" disabled={isSubmitting}>
                  Add Targets
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default AddTargetsDialog;
