import { Autocomplete, TextField } from '@mui/material';

interface ProfileAutoCompleteProps {
  profiles: any[];
  handleProfileChange: (event: any, value: any) => void;
  profileId: string | null;
}

export const ProfileAutocomplete = (props: ProfileAutoCompleteProps) => {
  const { profiles, handleProfileChange, profileId } = props;

  return (
    <Autocomplete
      disablePortal
      options={profiles}
      getOptionLabel={(profile) => profile.name}
      onChange={handleProfileChange}
      value={profiles.find((profile: { profileId: any }) => profile.profileId === profileId) || null}
      sx={{ width: 300, mb: 2 }}
      renderInput={(params) => <TextField {...params} size="small" label="Profile" />}
    />
  );
};
