import { getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { Platform } from '../../../../shared/types/platforms';
import {
  preProcessBidEditCellProps,
  renderEditBid
} from '../../../../walmart/components/grid-components/renderer/ad/renderEditBid';
import { renderCampaignStatus } from '../../../../shared/components/grid-components/renderer/campaign/renderStatus';
import { renderKeywordActions } from '../../../../walmart/components/grid-components/renderer/keyword/renderActiveToggle';
import { renderKeywordText } from '../../../../walmart/components/grid-components/renderer/keyword/renderKeywordText';
import { METRIC_COLUMNS } from './metric-columns';
import { campaignStatusOptions } from '../../../../shared/types/walmart-sams-club/campaign';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';

export const KEYWORD_COLUMNS: ExtendedGridColDef[] = [
  {
    field: 'actions',
    headerName: 'Active',
    type: 'actions',
    width: 80,
    getActions: renderKeywordActions
  },
  {
    field: 'keywordText',
    headerName: 'Keyword Text',
    width: 160,
    minWidth: 120,
    editable: false,
    renderCell: renderKeywordText(Platform.SAMS_CLUB.value)
  },
  {
    field: 'matchType',
    headerName: 'Match Type',
    type: 'singleSelect',
    valueOptions: ['Exact', 'Phrase', 'Broad'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 100,
    minWidth: 65,
    editable: false
  },
  {
    field: 'state',
    headerName: 'Active Status',
    type: 'singleSelect',
    valueOptions: ['Enabled', 'Paused'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 140,
    minWidth: 65,
    renderCell: renderCampaignStatus(campaignStatusOptions)
  },
  {
    field: 'bid',
    headerName: 'Bid',
    width: 100,
    minWidth: 65,
    type: 'number',
    headerAlign: 'left',
    editable: true,
    valueFormatter: renderAsCurrencyFormatter,
    preProcessEditCellProps: preProcessBidEditCellProps,
    cellClassName: () => {
      return 'numeric-edit-cell';
    },
    renderEditCell: renderEditBid
  },
  {
    field: 'keywordId',
    headerName: 'Keyword ID',
    width: 100,
    minWidth: 65,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'adGroupId',
    headerName: 'Ad Group ID',
    width: 100,
    minWidth: 65,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'campaignId',
    headerName: 'Campaign ID',
    width: 100,
    minWidth: 65,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'status',
    headerName: 'Review Status',
    type: 'singleSelect',
    valueOptions: ['Approved', 'Pending', 'Rejected'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 100,
    minWidth: 65,
    editable: false
  },
  {
    field: 'discoveredBy',
    headerName: 'Discovered By',
    width: 130,
    minWidth: 65,
    editable: false
  },
  ...METRIC_COLUMNS
];
