import { FC, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InitializedPerformanceGrid } from '../../../shared/components/performance-grid/initialized-performance-grid';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { Campaign } from '../../types/campaign';
import { CampaignsGridModalActions } from './campaigns-grid-modal-actions';

interface CampaignsGridForDialogProps {
  getCampaigns: () => Promise<ResponseObject>;
  handleAddCampaigns?: () => void;
  setSelectedCampaignData?: (data: Partial<Campaign>[]) => void;
  isBidGroup?: boolean;
  isBudgetGroup?: boolean;
  gridHeight?: string | number;
}

const CampaignsGridForDialog: FC<CampaignsGridForDialogProps> = ({
  getCampaigns,
  handleAddCampaigns,
  setSelectedCampaignData,
  isBidGroup = false,
  isBudgetGroup = false,
  gridHeight
}: CampaignsGridForDialogProps) => {
  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId');

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    return { ...oldRow };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <InitializedPerformanceGrid
      breadCrumbParams={{ profileId }}
      settingsKey={GridConfigSettings.AMAZON_CAMPAIGN}
      columns={CAMPAIGN_COLUMNS}
      processRowUpdate={processRowUpdate}
      fetchEntity={getCampaigns}
      shouldIncludeChartMetrics={false}
      bottomMargin={0}
      rowId={'campaignId'}
      getRowClassName={(params: any) => `row-status--${params.row.status}`}
      bulkActions={(refreshEntities: () => void, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
        return (
          <CampaignsGridModalActions
            handleAddCampaigns={handleAddCampaigns}
            setSelectedCampaignData={setSelectedCampaignData}
            isBidGroup={isBidGroup}
            isBudgetGroup={isBudgetGroup}
          />
        );
      }}
      allowQuickFilterSearch={false}
      disableGridDateRangePicker={false}
      otherDataGridProps={{
        keepNonExistentRowsSelected: true
      }}
      gridHeight={gridHeight}
    />
  );
};

export default CampaignsGridForDialog;
