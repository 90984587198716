import { Autocomplete, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { Profile } from '../../types/profile';
import { ResponseObject } from '../../utilities/fetch-utilities';
import { useSearchParams } from 'react-router-dom';

interface ProfileSelectProps {
  profileId: string | number | null;
  getProfiles: () => Promise<ResponseObject>;
  disabled?: boolean;
}

const ProfileSelect: FC<ProfileSelectProps> = (props) => {
  const { profileId, getProfiles, disabled = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProfileSelectValue = useCallback((): Profile | null => {
    return profiles.find((profile) => profile.profileId === profileId) ?? null;
  }, [profileId, profiles]);

  const handleProfileChange = useCallback(
    (event: any, profile: Profile | null) => {
      if (!profile) {
        return;
      }

      if (!profile.profileId) {
        searchParams.delete('profileId');
        setSearchParams(searchParams);
      } else {
        setSearchParams({
          ...searchParams,
          profileId: `${profile.profileId}`
        });
      }
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const fetchProfiles = async () => {
      setIsLoading(true);
      const response = await getProfiles();

      if (response?.success) {
        setProfiles(response?.body.records ?? ([] as Profile[]));
      } else {
        enqueueSnackbar(response?.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Autocomplete
        loading={isLoading}
        disablePortal
        options={profiles}
        getOptionLabel={(profile) => profile.name}
        onChange={handleProfileChange}
        value={getProfileSelectValue()}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} size="small" label="Profile" />}
        disabled={disabled}
        autoHighlight
      />
    </>
  );
};

export default ProfileSelect;
