import { Card, CardContent, Typography } from '@mui/material';
import { AddItems } from './add-items';

interface ItemSettingsProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  profileId: string | null;
}

export const ItemSettings = (props: ItemSettingsProps) => {
  const { values, setFieldValue, profileId } = props;

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Select Items To Promote
        </Typography>
        <AddItems profileId={profileId} values={values} setFieldValue={setFieldValue} />
      </CardContent>
    </Card>
  );
};
