import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { ArrayHelpers, FieldArray } from 'formik';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { KeywordApplyConfirmationDialogProps, KeywordHarvestingSettings, MetricMinimum } from '../../types/keyword-harvesting';
import { MetricMinimumFormField } from './metric-minimum-form-field';

interface KeywordHarvestingSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  handleSubmit: (settings: KeywordHarvestingSettings) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: KeywordHarvestingSettings;
}

export const KeywordHarvestingSettingsDialog = (props: KeywordHarvestingSettingsDialogProps) => {
  const { open, onClose, handleSubmit, setFieldValue, values } = props;

  const handleSave = () => {
    handleSubmit(values);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Keyword Harvesting Settings</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            <NumericFormat
              label="Default Bid"
              customInput={TextField}
              id="outlined-adornment-weight"
              thousandSeparator=","
              value={values.defaultBid}
              size="small"
              decimalScale={2}
              allowNegative={false}
              sx={{ mt: 1 }}
              onValueChange={(values, sourceInfo) => {
                setFieldValue('defaultBid', values.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box>$</Box>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            {' '}
            <FormControlLabel
              control={<Switch />}
              label={<Typography variant="caption">Apply Keywords as Enabled</Typography>}
              onChange={(event: any) => setFieldValue('applyAsEnabled', !values.applyAsEnabled)}
              checked={values.applyAsEnabled}
              sx={{ float: 'right' }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <FieldArray
          name="metricMinimums"
          render={(arrayHelpers: ArrayHelpers) => (
            <Box>
              {values.metricMinimums.map((metric, index) => (
                <MetricMinimumFormField
                  metric={metric}
                  index={index}
                  setFieldValue={setFieldValue}
                  arrayHelpers={arrayHelpers}
                  key={metric.metricType}
                />
              ))}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  my: 2
                }}
              >
                <Button variant="outlined" startIcon={<AddIcon />} onClick={() => arrayHelpers.push('')} size="small">
                  Add metric rule
                </Button>
              </Box>
            </Box>
          )}
        />
        <Divider sx={{ my: 3 }} />
        <TextField
          fullWidth
          margin="normal"
          id="campaignItemIds"
          label="Does not include"
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setFieldValue('keywordTextDoesNotContain', event.target.value.toLocaleLowerCase().split('\n'))
          }
          value={values.keywordTextDoesNotContain.map((item) => item).join('\n')}
          placeholder="Keyword text does not include"
          multiline
          rows={5}
          inputProps={{
            style: {
              fontSize: '12px'
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
