import { Box } from '@mui/material';
import { DataGridPremium as DefaultUninitializedDataGridPremium } from '@mui/x-data-grid-premium';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDateConfig } from '../../hooks/use-date-config';
import { useGridConfig } from '../../hooks/use-grid-config';
import { usePageable } from '../../hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../types/pageable';
import { IGridConfigSettingItem } from '../../utilities/grid-config-settings';
import MetricsChart from '../metrics-chart';
import PerformanceGrid, { OtherDataGridProps } from './performance-grid';

interface InitializedPerformanceGridProps {
  breadCrumbParams: any;
  settingsKey: IGridConfigSettingItem;
  columns: any;
  processRowUpdate: any;
  fetchEntity: any;
  rowId: string;
  getRowClassName: any;
  disableGridDateRangePicker: boolean;
  bulkActions: any;
  bottomMargin: number;
  allowQuickFilterSearch: boolean;
  shouldIncludeChartMetrics: boolean;
  otherDataGridProps?: OtherDataGridProps;
  customProps?: any;
  gridHeight?: string | number;
}

export const RawInitializedPerformanceGrid = (params: InitializedPerformanceGridProps) => {
  const {
    breadCrumbParams,
    settingsKey,
    columns,
    processRowUpdate,
    fetchEntity,
    rowId,
    getRowClassName,
    disableGridDateRangePicker,
    bulkActions,
    bottomMargin,
    allowQuickFilterSearch,
    shouldIncludeChartMetrics,
    otherDataGridProps,
    customProps,
    gridHeight
  } = params;

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: settingsKey,
    breadCrumbParams: breadCrumbParams
  });
  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [isLoadingRows, setIsLoadingRows] = useState(true);

  useEffect(() => {
    if (initialSettings) {
      setBreadcrumbParams(breadCrumbParams);
    }
  }, [initialSettings, breadCrumbParams, setBreadcrumbParams]);

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    fetchData();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  // Optimize: Memoize fetchData to avoid re-creation on each render
  const fetchData = useCallback(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    setIsLoadingRows(true);

    fetchEntity(pageable, dateSettings.beginDate, dateSettings.endDate, shouldIncludeChartMetrics)
      .then((response: any) => {
        if (!response || !response.success || !response.body) {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
          return;
        }

        if (!response.body.dataGrid) {
          setRows(response.body.records || []);
          setRowCount(response.body.totalFilteredRecords || DEFAULT_ROW_COUNT);
        } else {
          setRows(response.body.dataGrid?.records || []);
          setRowCount(response.body.dataGrid?.totalFilteredRecords || DEFAULT_ROW_COUNT);
        }

        if (response.body.timeSeriesData) {
          setTimeSeriesData(response.body.timeSeriesData);
        }
      })
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => {
        setIsLoadingRows(false);
      });
  }, [
    pageable,
    settingsLoading,
    dateSettingsLoading,
    fetchEntity,
    dateSettings.beginDate,
    dateSettings.endDate,
    shouldIncludeChartMetrics
  ]); // Ensure dependencies are correctly listed

  const refreshEntities = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const BulkActions = useMemo(() => bulkActions(refreshEntities, setIsLoadingRows), [bulkActions, refreshEntities]);

  if (!initialSettings || settingsLoading) {
    return (
      <DefaultUninitializedDataGridPremium
        rows={[]}
        columns={columns}
        loading={true}
        processRowUpdate={processRowUpdate}
        initialState={{}}
        key={'default-grid-key'}
        disableRowGrouping
      />
    );
  }

  return (
    <Box>
      {!!timeSeriesData && shouldIncludeChartMetrics && (
        <Box display="flex" alignItems={'center'} my={'1%'}>
          <MetricsChart timeSeriesData={timeSeriesData} isMetricsChartLoading={isLoadingRows} />
        </Box>
      )}
      <Box height={gridHeight ?? '800px'}>
        <PerformanceGrid
          processRowUpdate={processRowUpdate}
          loading={isLoadingRows}
          rows={rows}
          columns={columns}
          initialState={initialSettings?.config}
          saveGridConfig={saveGridConfig}
          dateConfig={{
            dateSettings: dateSettings,
            dateSettingsLoading: dateSettingsLoading,
            saveDateConfig: saveDateConfig
          }}
          getRowId={(row: any) => row[rowId]}
          getRowClassName={getRowClassName}
          bulkActions={BulkActions}
          bottomMargin={bottomMargin}
          disableGridDateRangePicker={disableGridDateRangePicker}
          allowQuickFilterSearch={allowQuickFilterSearch}
          rowCount={rowCount}
          handleFilterModelChange={handleFilterModelChange}
          handleSortModelChange={handleSortModelChange}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          {...customProps}
          {...otherDataGridProps}
        />
      </Box>
    </Box>
  );
};

export const InitializedPerformanceGrid = React.memo(RawInitializedPerformanceGrid);
