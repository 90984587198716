import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Skeleton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { CurrencyInput } from '../../../../../shared/components/inputs/currency-input';
import { Filter, Pageable } from '../../../../../shared/types/pageable';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { MatchType } from '../../../../types/targeting-refinements';
import AddedTargets from '../../campaign-builder/product-and-category-targeting/added-targets';
import CategoryRecommendations from '../../campaign-builder/product-and-category-targeting/category-recommendations';
import ProductTargetRecommendations from '../../campaign-builder/product-and-category-targeting/product-recommendations';
import { AmazonCampaignType } from '../../../../types/campaign';
import { AMAZON_TARGET_MINIMUM_BID } from '../../../../types/target';
import AddAsins from '../../campaign-builder/product-and-category-targeting/add-asins';

const TARGETING_TABS = ['Categories', 'Products', 'Enter ASINs'];

interface AddProductAndCategoryTargetsProps {
  profileId: string | null;
  campaignId: string | null;
  adGroupId: string | null;
  platformAdGroupId: string | null;
  retailer: string;
  campaignType?: AmazonCampaignType;
  existingTargets: any[];
}

export const AddProductAndCategoryTargets = (props: AddProductAndCategoryTargetsProps) => {
  const { profileId, campaignId, adGroupId, existingTargets } = props;

  const { setFieldValue } = useFormikContext<any>();

  const [bid, setBid] = useState(AMAZON_TARGET_MINIMUM_BID);
  const [tabIndex, setTabIndex] = useState(0);
  const [targetsToAddToForm, setTargetsToAddToForm] = useState<any[]>([]);
  const [productMatchType, setProductMatchType] = useState<MatchType>({ exact: true, expanded: false });
  const [productRecommendations, setProductRecommendations] = useState<any[]>([]);
  const [categoryRecommendations, setCategoryRecommendations] = useState<any[]>([]);
  const [asins, setAsins] = useState<any[]>([]);

  const { getAds } = useAmazonApi();

  const handleAddTargets = (newTargets: any[]) => {
    setTargetsToAddToForm((prevTargets) => [...prevTargets, ...newTargets]);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleRemoveTarget = (targetToRemove: any) => {
    setTargetsToAddToForm((prevTargets) => prevTargets.filter((target) => target !== targetToRemove));
  };

  const handleRemoveAllTargets = () => {
    setTargetsToAddToForm([]);
  };

  useEffect(() => {
    setFieldValue('targets', targetsToAddToForm);
  }, [targetsToAddToForm, setFieldValue]);

  useEffect(() => {
    if (!adGroupId && targetsToAddToForm?.length > 0) {
      setTargetsToAddToForm([]);
    }
  }, [adGroupId, targetsToAddToForm]);

  useEffect(() => {
    const fetchAsins = async () => {
      if (!adGroupId || !profileId) {
        return;
      }

      const adsPageable: Pageable = {
        limit: 100,
        offset: 0,
        filters: [
          {
            column: 'adGroupId',
            value: adGroupId,
            comparator: 'Equals'
          }
        ] as Filter[]
      };

      const response = await getAds(adsPageable);

      if (response.success) {
        let asins: any[] = [];

        // ASINS from SP
        asins = response.body.records.map((ad: any) => ad.asin);

        // ASINS from SB
        if (asins.length === 0 || asins[0] === null) {
          asins = response.body.records.flatMap((ad: any) => ad.amazonSponsoredBrandsCreative.asins.map((asin: any) => asin));
        }

        setAsins(asins);
      }
    };

    fetchAsins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adGroupId, tabIndex]);

  const renderRecommendations = (asins: any[]) => {
    if (!adGroupId || !asins || asins.length === 0 || !profileId) {
      return <Skeleton variant="rectangular" height={200} />;
    }

    if (tabIndex === TARGETING_TABS.indexOf('Categories')) {
      return (
        <CategoryRecommendations
          handleAddTargets={handleAddTargets}
          targets={targetsToAddToForm}
          existingTargets={existingTargets}
          categoryRecommendations={categoryRecommendations}
          setCategoryRecommendations={setCategoryRecommendations}
          bid={bid}
          profileId={profileId}
          campaignId={campaignId}
          asins={asins}
        />
      );
    }

    if (tabIndex === TARGETING_TABS.indexOf('Products')) {
      return (
        <ProductTargetRecommendations
          handleAddTargets={handleAddTargets}
          targets={targetsToAddToForm}
          existingTargets={existingTargets}
          matchType={productMatchType}
          setMatchType={setProductMatchType}
          productRecommendations={productRecommendations}
          setProductRecommendations={setProductRecommendations}
          bid={bid}
          profileId={profileId}
          campaignId={campaignId}
          asins={asins}
        />
      );
    }

    if (tabIndex === TARGETING_TABS.indexOf('Enter ASINs')) {
      return (
        <AddAsins
          handleAddTargets={handleAddTargets}
          profileId={profileId}
          targets={targetsToAddToForm}
          existingTargets={existingTargets}
          bid={bid}
        />
      );
    }
  };

  return (
    <Box sx={{ minWidth: '750px' }}>
      <Grid container direction={'row'} spacing={2} alignItems={'stretch'} display={'flex'}>
        <Grid item xs={6}>
          <Box>
            <Box sx={{ mb: 2 }}>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                {TARGETING_TABS.map((tab, index) => (
                  <Tab key={index} label={tab} />
                ))}
              </Tabs>
            </Box>
            <Stack mb={2}>
              <Box sx={{ mt: 2 }} display={'flex'} justifyContent={'space-between'}>
                <CurrencyInput
                  size="small"
                  label="Bid"
                  name="bid"
                  error={bid < 0.0}
                  helperText={bid < 0.0 ? `Bid must be at least $${0.0}` : ' '}
                  sx={{ maxWidth: '180px' }}
                  value={bid}
                  onChange={(event) => setBid(Number(event.target.value))}
                />
              </Box>
              {tabIndex === TARGETING_TABS.indexOf('Categories') ||
                (tabIndex === TARGETING_TABS.indexOf('Products') && (
                  <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{ mb: 2 }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                      Recommended Targets
                    </Typography>
                    <Tooltip title="Recommendations are based on the items in the current ad group">
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                ))}
              <Box sx={{ maxWidth: '400px' }}>{renderRecommendations(asins)}</Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ maxWidth: '400px' }}>
            <AddedTargets
              targets={targetsToAddToForm}
              removeAllTargets={handleRemoveAllTargets}
              removeTarget={handleRemoveTarget}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
