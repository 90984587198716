import { Box, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material';
import { FC, useEffect, useState, useCallback } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import useAmazonApi from '../../hooks/use-amazon-api';
import { useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { DashboardCard } from '../../../shared/components/dashboard-card';
import InsightsIcon from '@mui/icons-material/Insights';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Link as RouterLink } from 'react-router-dom';
import { Platform } from '../../../shared/types/platforms';
import { renderAsCurrencyFormatter } from '../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { cheerfulFiestaPalette } from '@mui/x-charts/colorPalettes';
import { formatAsCurrency } from '../../../shared/utilities/numeric-format-utilities';

interface WastedSpendByCampaign {
  key: string;
  campaignName: string;
  totalWastedSpend: number;
  totalWastedSpendPercentage: number;
}

interface WastedSpendByTarget {
  key: string;
  targetText: string;
  totalWastedSpend: number;
  totalWastedSpendPercentage: number;
  adGroupId: string;
  campaignId: string;
}

interface TopOffendersProps {
  topOffenderCampaigns: WastedSpendByCampaign[];
  topOffenderKeywords: WastedSpendByTarget[];
}

const TopOffenders = ({ topOffenderCampaigns, topOffenderKeywords }: TopOffendersProps) => {
  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');

  return (
    <>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
        Top Offender Campaigns
      </Typography>
      <Box height={'400px'}>
        <DataGridPremium
          rows={topOffenderCampaigns}
          columns={[
            {
              field: 'campaignName',
              headerName: 'Campaign Name',
              flex: 1,
              renderCell: (params) => (
                <Link
                  to={`/${Platform.AMAZON.value}/campaigns/overview?campaignId=${params.row.key}&profileId=${profileId}`}
                  sx={{
                    textDecoration: 'none',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whitespace: 'nowrap',
                    color: 'text.primary'
                  }}
                  component={RouterLink}
                >
                  {params.value}
                </Link>
              )
            },
            { field: 'totalWastedSpend', headerName: 'Wasted Spend', flex: 1, valueFormatter: renderAsCurrencyFormatter },
            { field: 'totalWastedSpendPercentage', headerName: 'Wasted Spend %', flex: 1 }
          ]}
          getRowId={(row) => row.key}
        />
      </Box>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
        Top Offender Targets
      </Typography>
      <Box height={'400px'}>
        <DataGridPremium
          rows={topOffenderKeywords}
          columns={[
            {
              field: 'targetText',
              headerName: 'Target Text',
              flex: 1
            },
            {
              field: 'adGroupName',
              headerName: 'Ad Group Name',
              flex: 1,
              renderCell: (params) => (
                <Link
                  to={`/${Platform.AMAZON.value}/campaigns/overview/adGroup?campaignId=${params.row.campaignId}&profileId=${profileId}&adGroupId=${params.row.adGroupId}`}
                  sx={{
                    textDecoration: 'none',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whitespace: 'nowrap',
                    color: 'text.primary'
                  }}
                  component={RouterLink}
                >
                  {params.value}
                </Link>
              )
            },
            { field: 'totalWastedSpend', headerName: 'Wasted Spend', flex: 1, valueFormatter: renderAsCurrencyFormatter },
            { field: 'totalWastedSpendPercentage', headerName: 'Wasted Spend %', flex: 1 }
          ]}
          getRowId={(row) => row.key}
        />
      </Box>
    </>
  );
};

export const WastedSpendModule: FC = () => {
  const { getWastedSearchQuerySpend } = useAmazonApi();
  const [wastedSpendInsights, setWastedSpendInsights] = useState<any>(null);
  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchWastedSpend = useCallback(async () => {
    if (!profileId) {
      return;
    }

    setIsLoading(true);

    const response = await getWastedSearchQuerySpend(profileId);

    if (response?.success) {
      setWastedSpendInsights(response?.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchWastedSpend();
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardCard loading={isLoading}>
      <CardHeader
        title={
          <Box display={'flex'} alignContent={'center'}>
            <InsightsIcon sx={{ mr: 2, color: 'primary.main' }} />
            Wasted Spend
          </Box>
        }
        sx={{ pb: 0 }}
      />

      <CardContent>
        <Grid container spacing={2} alignContent={'center'} justifyContent={'flex-start'} marginBottom={2}>
          <Grid item alignContent={'center'}>
            <Typography variant="h2">{Math.round(wastedSpendInsights?.totalWastedSpendPercentage ?? 0)}%</Typography>
            <Typography variant="body2" width={'200px'}>
              Of spend <strong>({formatAsCurrency(wastedSpendInsights?.totalWastedSpend ?? 0)})</strong> went to search terms that
              had <strong>zero</strong> conversions in the last 30 days.
            </Typography>
          </Grid>
          <Grid item>
            {wastedSpendInsights?.wastedSpendByMatchType && (
              <PieChart
                colors={cheerfulFiestaPalette}
                series={[
                  {
                    data: wastedSpendInsights?.wastedSpendByMatchType
                  }
                ]}
                width={400}
                slotProps={{
                  legend: {
                    hidden: false
                  }
                }}
                height={200}
              />
            )}
          </Grid>
        </Grid>
        {wastedSpendInsights?.wastedSpendByCampaign && (
          <TopOffenders
            topOffenderCampaigns={wastedSpendInsights?.wastedSpendByCampaign}
            topOffenderKeywords={wastedSpendInsights?.wastedSpendByTarget}
          />
        )}
      </CardContent>
    </DashboardCard>
  );
};
