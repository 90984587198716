import { Backdrop, Box, Button, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { PROFILE_COLUMNS } from '../../components/grid-components/column-configurations/profile-columns';
import { useAmazonApi } from '../../hooks/use-amazon-api';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../shared/hooks/use-dialog';
import zIndex from '@mui/material/styles/zIndex';
import { Platform } from '../../../shared/types/platforms';
import ProfileCreationModal from '../../../shared/components/profile-creation-modal/profile-creation-modal';

const Profiles: FC = () => {
  const { getProfiles } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const { pageable, setPageable, handleFilterModelChange, handleSortModelChange, handlePageChange, handlePageSizeChange } =
    usePageable();

  const [profiles, setProfiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const { toggle: toggleProfileCreationDialog, isShowing: profileCreationDialogIsShowing } = useDialog();
  const [isPageLoading, setIsPageLoading] = useState(false);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_PROFILE,
    breadCrumbParams: {}
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();

  const processRowUpdate = useCallback((newRow: any, oldRow: any) => {
    //Execute Put Request here
    return newRow;
  }, []);

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading) {
      return;
    }

    const fetchProfiles = async () => {
      if (!pageable) {
        return;
      }

      setIsLoading(true);

      const response = await getProfiles(pageable, dateSettings.beginDate, dateSettings.endDate);

      if (response.success) {
        setProfiles(response.body.records);
        setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchProfiles();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Profiles">
      <Container maxWidth="xl" style={{ height: '93vh' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'} my={'5%'}>
            <Typography variant="h4">Profiles</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => toggleProfileCreationDialog()}>
              Add Profile
            </Button>
          </Box>
          {!initialSettings || settingsLoading ? (
            <DataGridPremium
              rows={[]}
              columns={PROFILE_COLUMNS}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
              key={uuidv4()}
              disableRowGrouping
            />
          ) : (
            <PerformanceGrid
              processRowUpdate={processRowUpdate}
              loading={isLoading}
              rows={profiles}
              columns={PROFILE_COLUMNS}
              initialState={initialSettings?.config}
              saveGridConfig={saveGridConfig}
              dateConfig={{
                dateSettings: dateSettings,
                dateSettingsLoading: dateSettingsLoading,
                saveDateConfig: saveDateConfig
              }}
              getRowId={(row: any) => row.profileId}
              handleFilterModelChange={handleFilterModelChange}
              handleSortModelChange={handleSortModelChange}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              rowCount={rowCount}
            />
          )}
        </Stack>
      </Container>
      {profileCreationDialogIsShowing && (
        <ProfileCreationModal
          isOpen={profileCreationDialogIsShowing}
          onClose={() => toggleProfileCreationDialog()}
          retailer={Platform.AMAZON.label}
          setIsPageLoading={setIsPageLoading}
          existingPlatformProfileIds={profiles.map((profile) => profile.platformProfileId)}
        />
      )}
      {isPageLoading && (
        <Backdrop sx={{ color: '#fff', zIndex: zIndex.modal + 1 }} open={isPageLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Page>
  );
};

export default Profiles;
