import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import useAdsApi from '../../../../../shared/hooks/use-citrusad-api';
import AddKeywords from '../../../../../shared/components/add-keywords';

interface ProductKeywordsDialogProps {
  isShowing: boolean;
  campaignId: string | null;
  keywords: any[];
  toggle: () => void;
  refreshKeywords: () => void;
}

export const ProductKeywordsDialog = (props: ProductKeywordsDialogProps) => {
  const { isShowing, campaignId, keywords, toggle, refreshKeywords } = props;

  const { updateCampaign } = useAdsApi();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const data = keywords.concat(formik.values.keywords);
    const uniqueData = findUniqueKeywords(data);
    const newData = uniqueData.map((keyword: any) => {
      const obj: any = {
        phrase: keyword.keywordText || keyword.searchTerm,
        matchType: 'MATCH_TYPE_EXACT_MATCH'
      };
      if (keyword.maxBid || keyword.bid) obj['maxBid'] = `${keyword.maxBid || keyword.bid}`;
      return obj;
    });

    const payload: any = {
      productCampaign: {
        targeting: {
          searchTerms: newData
        }
      },
      mask: 'targeting.searchTerms'
    };

    const response = await updateCampaign(campaignId || '', payload);

    if (response.success) {
      enqueueSnackbar(`Keyword(s) added successfully!`, { variant: 'success' });
      refreshKeywords();
      toggle();
    } else {
      enqueueSnackbar(response.body?.details[0]?.fieldViolations[0]?.description, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  function findUniqueKeywords(keywords: any[]) {
    const uniqueObjects = [];
    const seen = new Set();

    for (const keyword of keywords) {
      if (!seen.has(keyword.keywordText || keyword.phrase || keyword.searchTerm)) {
        seen.add(keyword.keywordText || keyword.phrase || keyword.searchTerm);
        uniqueObjects.push(keyword);
      }
    }

    return uniqueObjects;
  }

  const formik = useFormik({
    initialValues: { keywords: [] },
    onSubmit: async (values, helpers): Promise<void> => {
      setIsSubmitting(true);
      await handleSubmit();

      setIsSubmitting(false);
    }
  });

  return (
    <Box>
      <Container maxWidth="md">
        <Dialog open={isShowing} maxWidth="md" fullWidth={true} disablePortal sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <DialogContent>
            <Typography variant="h6" sx={{ mb: 4 }}>
              Add Keywords
            </Typography>
            <Box>
              <Typography variant="caption" gutterBottom>
                Enter a bid, select match types, and enter keywords. Once finished click create ads to create your new ads and
                keywords.
              </Typography>
              <AddKeywords
                keywords={formik.values.keywords}
                setKeywords={(keywords) => formik.setFieldValue('keywords', keywords)}
                onlyExact={true}
                isBidEditable={true}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => toggle()}>
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              type="submit"
              variant="contained"
              disabled={isSubmitting || !formik.isValid || !formik.dirty}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
