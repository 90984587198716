import * as Yup from 'yup';
import { keywordMatchTypes } from '../../../types/campaign';
import { UserSelectedCampaignType } from './radio-card';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const CAMPAIGN_FORM_VALIDATION_SCHEMA = Yup.object({
  teamId: Yup.string().max(255).required(),
  name: Yup.string().max(255).required(),
  startDate: Yup.date().nullable().min(today, 'Start date cannot have passed').required('Start Date is required'),
  endDate: Yup.date()
    .nullable()
    .min(today, 'End date cannot be before start date')
    .when('startDate', (startDate, schema) => {
      return startDate ? schema.min(startDate, 'End date must come after start date.') : schema;
    }),
  items: Yup.array()
    .min(1, 'At least one item is required')
    .of(Yup.object({ itemId: Yup.string().required(), itemName: Yup.string().required() }))
    .required(),
  keywords: Yup.array().of(
    Yup.object({
      keywordText: Yup.string().required(),
      matchType: Yup.string()
        .oneOf(keywordMatchTypes.map((type) => type.value))
        .required(),
      bid: Yup.number().required()
    })
  ),

  searchAmpName: Yup.string().max(35),
  headlineText: Yup.string().max(45),
  campaignStructure: Yup.string().max(255).required()
});

export const INITIAL_FORM_VALUES = {
  teamId: '',
  walletId: '',
  targetingType: 'manual',
  startDate: new Date(),
  endDate: null,
  items: [],
  keywords: [],
  name: '',
  submit: null,
  dailySpendLimit: '',
  totalSpendLimit: '',
  maxBid: '0.3',
  campaignStructure: UserSelectedCampaignType.SINGLE_ITEM_SINGLE_TARGET
};
