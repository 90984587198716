// Define a type for the abbreviations object with an index signature
type Abbreviations = {
  [key: string]: string;
  'Attributed Revenue': string;
  'Conversion Rate': string;
  'Click Through Rate': string;
  'Cost Per Click': string;
  'Cost Per Conversion': string;
  'Return on Ad Spend': string;
  'New to Brand': string;
  'Advertising Cost of Sale': string;
};

// Helper function to abbreviate certain phrases
const abbreviations: Abbreviations = {
  'Attributed Revenue': 'Attr. Rev.',
  'Conversion Rate': 'Conv. Rate',
  'Click Through Rate': 'CTR',
  'Cost Per Click': 'CPC',
  'Cost Per Conversion': 'Cost/Conv',
  'Return on Ad Spend': 'ROAS',
  'New to Brand': 'NTB',
  'Advertising Cost of Sale': 'ACoS'
};

export function abbreviateMetricDisplayName(text: string): string {
  // Replace each phrase with its abbreviation
  for (let phrase in abbreviations) {
    text = text.replace(new RegExp(phrase, 'g'), abbreviations[phrase]);
  }

  return text;
}

export const formatMetricValue = (value: number, inYAxis: Boolean, dataType: string) => {
  if (value == undefined) {
    return 'N/A';
  }

  let formattedValue: any = value;

  if (inYAxis) {
    if (Math.abs(value) >= 1000000) {
      formattedValue = (value / 1000000).toFixed(0) + 'M';
    } else if (Math.abs(value) >= 1000) {
      formattedValue = (value / 1000).toFixed(0) + 'K';
    } else if (Number.isInteger(value)) {
      formattedValue = value.toFixed(0);
    } else if (dataType === 'Currency') {
      formattedValue = '$' + formattedValue.toFixed(2).toLocaleString();
    } else if (dataType === 'Percentage') {
      formattedValue = (formattedValue * 100).toFixed(2).toLocaleString() + '%';
    } else {
      formattedValue = value.toFixed(2);
    }
  } else {
    if (dataType === 'Currency') {
      formattedValue = '$' + formattedValue.toFixed(2).toLocaleString();
    } else if (dataType === 'Percentage') {
      formattedValue = (formattedValue * 100).toFixed(2).toLocaleString() + '%';
    } else if (Number.isInteger(value)) {
      formattedValue = value.toFixed(0).toLocaleString();
    } else {
      formattedValue = formattedValue.toLocaleString();
    }
  }

  formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  return formattedValue;
};
