import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { chunk, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import useAmazonApi from '../../../hooks/use-amazon-api';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton, Link, Skeleton, Tooltip } from '@mui/material';
import { SponsoredBrandsCreative, SponsoredBrandsLandingPage } from '../../../types/campaign';
import { useSearchParams } from 'react-router-dom';

interface ProductInfo {
  asin: string;
  imageUrl: string;
  itemName: string;
}

interface OverviewCreativeDrawerProps {
  creative: SponsoredBrandsCreative;
  landingPage: SponsoredBrandsLandingPage;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}

const OverviewCreativeDrawer = ({ creative, landingPage, drawerOpen, setDrawerOpen }: OverviewCreativeDrawerProps) => {
  const { searchCatalogItems, getAssetUrls } = useAmazonApi();
  const [searchParams] = useSearchParams();

  const profileId = searchParams.has('profileId') ? searchParams.get('profileId') : null;

  const [productInfo, setProductInfo] = useState<ProductInfo[] | null>(null);
  const [isLoadingProductInfo, setIsLoadingProductInfo] = useState<boolean>(true);

  const [brandLogoUrl, setBrandLogoUrl] = useState<string | null>(null);
  const [customImageUrl, setCustomImageUrl] = useState<string | null>(null);

  useEffect(() => {
    fetchProductInfo();
  }, [creative?.asins]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!creative.brandLogoAssetId) {
      return;
    }

    fetchUrl(creative.brandLogoAssetId.slice(0, creative.brandLogoAssetId.search(':')), setBrandLogoUrl);
  }, [creative.brandLogoAssetId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!creative.customImageAssetId) {
      return;
    }

    fetchUrl(creative.customImageAssetId.slice(0, creative.customImageAssetId.search(':')), setCustomImageUrl);
  }, [creative.customImageAssetId]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchUrl = async (assetId: string, handleUpdateUrl: (value: React.SetStateAction<string | null>) => void) => {
    if (!profileId || !assetId) {
      return;
    }

    const response = await getAssetUrls(profileId, assetId);

    if (response.success) {
      handleUpdateUrl(response.body.assetVersionList?.[0]?.url);
    }
  };

  const fetchProductInfo = async () => {
    if (!creative?.asins || creative?.asins.length < 1) {
      return;
    }
    setIsLoadingProductInfo(true);

    const uniqueAsins = uniq(creative.asins as string[]);
    if (uniqueAsins.length < 1) {
      setIsLoadingProductInfo(false);
      return;
    }

    const chunkedAsins = chunk(uniqueAsins, 20);

    const requests = chunkedAsins.map((asins) => {
      return searchCatalogItems(asins);
    });

    const responses = await Promise.all(requests);

    const combinedProductInfo = responses.reduce((acc: any, response: any) => {
      return [...acc, ...response.body];
    }, []);

    setProductInfo(combinedProductInfo);
    setIsLoadingProductInfo(false);
  };

  return (
    <Drawer open={drawerOpen} anchor="right" onClose={() => setDrawerOpen(false)}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box role="presentation" width={'250px'} sx={{ py: '50px' }}>
          <Box sx={{}}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stack sx={{ padding: '10px' }}>
            <Typography variant="h5" component="div">
              {creative.brandName}
            </Typography>
            {!!brandLogoUrl && <img src={brandLogoUrl} alt="Brand Logo Image" style={{ width: '100%' }} />}
            {!!customImageUrl && <img src={customImageUrl} alt="Custom Creative Image" style={{ width: '100%' }} />}

            <Typography variant="body2">{creative.headline}</Typography>
            <Typography variant="caption" color={'text.secondary'}>
              <strong>Landing Page: </strong>Link to{' '}
              <Link
                href={landingPage.url}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                sx={{ display: 'inline-flex', alignItems: 'center' }}
              >
                {landingPage?.pageType}
                <IconButton color="primary" aria-label="Open in new window" edge="end" size="small">
                  <OpenInNewIcon fontSize="inherit" />
                </IconButton>
              </Link>
            </Typography>

            <Typography variant="h6" component="div" sx={{ mt: 2 }}>
              Products
            </Typography>
            <List sx={{ bgcolor: 'background.paper' }}>
              {isLoadingProductInfo || !productInfo ? (
                <Box sx={{}}>
                  <Skeleton height={'100px'} />
                  <Skeleton height={'100px'} />
                </Box>
              ) : (
                productInfo.length > 0 &&
                productInfo.map((productInfoItem, index) => (
                  <ListItem key={productInfoItem.asin} sx={{ width: '100%' }}>
                    <ListItemAvatar>
                      <Avatar alt={`${productInfoItem.itemName} item image`} src={productInfoItem.imageUrl} variant="square" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Tooltip title={productInfoItem.itemName}>
                          <Box sx={{ width: '100%' }}>
                            <Typography
                              variant="caption"
                              sx={{
                                width: '100%',
                                maxWidth: '100%',
                                display: 'block',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {productInfoItem.itemName}
                            </Typography>
                          </Box>
                        </Tooltip>
                      }
                      secondary={productInfoItem.asin}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </Stack>
        </Box>
        <Button onClick={() => setDrawerOpen(false)}>Done</Button>
      </Box>
    </Drawer>
  );
};

export default OverviewCreativeDrawer;
