import { getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { Platform } from '../../../../shared/types/platforms';
import { renderCampaignActions } from '../../../../walmart/components/grid-components/renderer/campaign/renderActiveToggle';
import { renderCampaignStatus } from '../../../../shared/components/grid-components/renderer/campaign/renderStatus';
import { dateColumnType } from '../../../../shared/components/grid-components/renderer/campaign/date-cell-components';
import { renderBidMultiplier } from '../../../../walmart/components/grid-components/renderer/keyword-harvesting/renderBidMultiplierCell';
import { METRIC_COLUMNS } from './metric-columns';
import { campaignStatusOptions } from '../../../../shared/types/walmart-sams-club/campaign';
import { ACTIVE_STATUSES } from '../../../../shared/types/walmart-sams-club-citrusad/status';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { renderCampaignNameWithActions } from '../../../../shared/components/grid-components/renderer/campaign/renderNameWithActions';

const gridCampaignTypes = [
  {
    label: 'Sponsored Products',
    value: 'sponsoredProducts'
  },
  {
    label: 'Search Brand Amplifier',
    value: 'sba'
  },
  {
    label: 'Video',
    value: 'video'
  }
];

export const CAMPAIGN_COLUMNS: ExtendedGridColDef[] = [
  {
    field: 'isActiveInVector',
    headerName: 'Active',
    filterable: false,
    width: 80,
    resizable: false,
    align: 'center',
    renderCell: renderCampaignActions
  },
  {
    field: 'name',
    headerName: 'Campaign Name',
    width: 200,
    minWidth: 125,
    editable: false,
    renderCell: renderCampaignNameWithActions(Platform.SAMS_CLUB.value)
  },
  {
    field: 'campaignId',
    headerName: 'Campaign ID',
    type: 'number',
    width: 100,
    minWidth: 65,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'campaignType',
    headerName: 'Campaign Type',
    type: 'singleSelect',
    valueOptions: ['SponsoredProducts', 'Sba', 'Video'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 150,
    minWidth: 120,
    valueFormatter: ({ value }) =>
      value ? gridCampaignTypes.find((campaignType) => campaignType.value.toLowerCase() === value.toLowerCase())?.label : value
  },
  {
    field: 'targetingType',
    headerName: 'Targeting Type',
    type: 'singleSelect',
    valueOptions: ['Manual', 'Auto'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 90,
    minWidth: 65
  },
  {
    field: 'status',
    headerName: 'Active Status',
    type: 'singleSelect',
    valueOptions: ACTIVE_STATUSES,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    renderCell: renderCampaignStatus(campaignStatusOptions),
    width: 140,
    minWidth: 80
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    ...dateColumnType,
    width: 140,
    minWidth: 95,
    type: 'string',
    filterable: false,
    editable: true
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    ...dateColumnType,
    width: 140,
    minWidth: 95,
    type: 'string',
    filterable: false,
    editable: true
  },
  {
    field: 'budgetType',
    headerName: 'Budget Type',
    filterable: false,
    width: 100,
    minWidth: 55,
    editable: false
  },
  {
    field: 'dailyBudget',
    headerName: 'Daily Budget',
    width: 100,
    minWidth: 75,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'totalBudget',
    headerName: 'Total Budget',
    width: 100,
    minWidth: 80,
    type: 'number',
    headerAlign: 'left',
    editable: false,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'profileId',
    headerName: 'Profile ID',
    type: 'number',
    width: 100,
    minWidth: 35,
    editable: false,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'bidMultiplier',
    headerName: 'Bid Multiplier',
    width: 100,
    editable: false,
    renderCell: renderBidMultiplier,
    sortable: false,
    filterable: false
  },
  ...METRIC_COLUMNS
];
