import { values } from 'lodash';
import { AmazonCampaignBuilderItemDto, CampaignStructureType, State } from './campaign-builder-request';
import { CampaignBuilderItem } from './campaign-builder-item';

export interface AdGroupBuilderCreateDto {
  campaignId: string;
  name?: string;
  state: State;
  bid: number;
  items: AmazonCampaignBuilderItemDto[];
  targetingMode: string;
  universalKeywords: string[];
  negativeKeywords: string[];
}

export const createAdGroupBuilderRequest = (values: any): AdGroupBuilderCreateDto => {
  return {
    campaignId: values.campaignId,
    name: values.name,
    state: values.state,
    bid: values.bid,
    items: values.items.map((item: CampaignBuilderItem) => {
      return {
        asin: item.asin,
        sku: item.sku,
        state: State.ENABLED,
        keywords: item.keywords.map((keyword: any) => {
          return {
            matchType: keyword.matchType,
            state: State.ENABLED,
            bid: keyword.bid,
            keywordText: keyword.keywordText
          };
        }),
        targetingClauses: item.targetingClauses.map((target: any) => {
          const targetingClause = target.targetingClause;
          return {
            state: State.ENABLED,
            bid: targetingClause.bid,
            expression: targetingClause.expression,
            resolvedExpression: targetingClause.resolvedExpression,
            type: 'manual'
          };
        }),
        itemNickname: item.itemNickname
      };
    }),
    targetingMode: values.targetingMode,
    universalKeywords: values.universalKeywords,
    negativeKeywords: values.negativeKeywords
  };
};

export interface UpdateAdGroupRequest {
  adGroupId: string;
  state: string;
  name?: string;
  defaultBid?: number;
}

export interface AdGroup {
  adGroupId: string;
  campaignId: string;
  platformCampaignId: string;
  platformAdGroupId: string;
  name: string;
  state: string;
  defaultBid: number;
  entityType: string;
  successfulSyncAt: string;
  syncErrorMessage: string | null;
  syncErrorAt: string | null;
  syncState: string;
  createdAt: string;
  updatedAt: string;
}

export interface AdGroupViewDto {
  adGroupId: string;
  name: string;
  defaultBid: number;
  state: string;
  campaignId: string;
  campaignName: string;
  platformCampaignId: string;
  platformAdGroupId: string;
  createdAt: string;
  updatedAt: string;
  successfulSyncAt: string;
  syncError: boolean;
  syncErrorMessage: string | null;
  syncErrorAt: string | null;
  clickThroughRate: number;
  conversionRate: number;
  costPerConversion: number;
  returnOnAdSpend: number;
  costPerClick: number;
  lastMetricsDate: string | null;
  date: string;
  cost: number;
  attributedRevenue: number;
  impressions: number;
  clicks: number;
  units: number;
  orders: number;
  newToBrandOrders: number;
  newToBrandRevenue: number;
  newToBrandUnits: number;
  syncState: string;
}
