import { Delete } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { IndividualItemStatusChip } from './review-status-chip';

export const ItemStatusTable = (props: any) => {
  const { items, review } = props;

  return (
    <TableContainer component={Paper} sx={{ border: '1px solid divider', borderRadius: '0px' }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Item Status</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Item Image</TableCell>
            <TableCell>Item Id</TableCell>
            <TableCell>Item Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: any) => (
            <TableRow key={item.itemId}>
              <TableCell>
                <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'inline-flex' }}>
                  <IndividualItemStatusChip
                    status={item.reviewStatus}
                    sx={{ mr: 1 }}
                    isUnderReview={review?.reviewStatus?.toLowerCase() === 'pending'}
                  />
                  {!!item.reviewReason ? (
                    <Tooltip title={item.reviewReason}>
                      <HelpIcon sx={{ color: grey[500] }} />
                    </Tooltip>
                  ) : null}
                </Box>
              </TableCell>
              <TableCell>
                <img src={item.itemImageUrl} alt={item.name} width={50} />
              </TableCell>
              <TableCell sx={{ fontSize: '11px' }}>{item.itemId}</TableCell>
              <TableCell>
                <Tooltip title={item.name}>
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: '11px'
                    }}
                  >
                    {item.name.substring(0, 20) + '...' + item.name.substring(item.name.length - 20)}
                  </Typography>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const KeywordStatusTable = (props: any) => {
  const { keywords, review } = props;

  return (
    <TableContainer component={Paper} sx={{ minWidth: '400px', borderRadius: '4px' }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Keyword Status</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Keyword Text</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Match Type</TableCell>
            <TableCell>Bid</TableCell>
            <TableCell> Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keywords.map((keyword: any, index: number) => (
            <TableRow
              key={`${keyword.keywordText} - ${keyword.matchType} - ${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <IndividualItemStatusChip
                  status={keyword.status}
                  sx={{ mr: 1 }}
                  isUnderReview={review?.reviewStatus?.toLowerCase() === 'pending'}
                />
              </TableCell>
              <TableCell sx={{ maxWidth: '100px' }}>
                <Tooltip title={keyword.keywordText}>
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {keyword.keywordText}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>{keyword.matchType}</TableCell>
              <TableCell>${keyword.bid}</TableCell>
              <TableCell>
                {keyword.status === 'Rejected' ? (
                  <IconButton onClick={() => {}}>
                    <Delete />
                  </IconButton>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
