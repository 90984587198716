import { Box, LinearProgress, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

function RenderCurrentAssignedBudget(props: any) {
  const { params, isEditing } = props;
  const [hover, setHover] = useState(false);

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{ position: 'relative', height: '100%', width: '100%' }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          p: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          opacity: (theme) => {
            if (isEditing && hover) return 0.5;
            if (hover) return theme.palette.action.hoverOpacity;
            return 1;
          }
        }}
      >
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="caption" color="text.secondary" sx={{ mr: 2 }}>{`${params.value.toFixed(2)}%`}</Typography>
        </Box>
        <LinearProgress value={params.value} variant="determinate" sx={{ height: '10px', width: '100%', p: 1 }} />
      </Box>
      {hover && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: (theme) => theme.palette.action.hoverOpacity,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          {isEditing ? (
            <Typography variant="caption" color="text.primary">
              Double click to edit
            </Typography>
          ) : (
            <Typography variant="caption" color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
              <ArrowOutwardIcon sx={{ mr: 1 }} />
              Enter edit mode to adjust %
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export function renderCurrentAssignedBudget(params: GridRenderCellParams, isEditing: boolean) {
  return <RenderCurrentAssignedBudget params={params} isEditing={isEditing} />;
}
