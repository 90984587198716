import { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { listOfMonths } from '../../../../types/budget';
import { BudgetItemEditInputCell } from './renderEditBudgetItem';
import { renderAsCurrencyFormatter } from '../renderAsCurrencyFormat';
import { renderCampaignBudgetGroupName } from '../../../../../amazon/pages/budget/render-budget-group-name';

const MONTH_COLUMNS: GridColDef[] = [
  ...listOfMonths.map((month) => ({
    field: month,
    headerClassName: `header--validate-monthly-aggregate--${month}`,
    headerName: month.toUpperCase().slice(0, 3),
    editable: true,
    width: 100,
    minWidth: 0,
    type: 'number',
    cellClassName: () => 'numeric-edit-cell',
    valueFormatter: renderAsCurrencyFormatter,
    renderEditCell: (params: GridRenderEditCellParams) => BudgetItemEditInputCell(params)
  }))
];

export const createProfileBudgetColumns = () => {
  const createdBudgetColumns: GridColDef[] = [
    { field: 'budgetItemName', headerName: 'BUDGET ITEMS', width: 150 },
    ...MONTH_COLUMNS.map((monthColumn) => ({
      ...monthColumn
    })),
    {
      field: 'TOTAL',
      headerName: 'TOTAL',
      cellClassName: 'total-cell',
      sortable: false,
      valueGetter: (params) => {
        const total = listOfMonths.reduce((acc, month) => {
          return acc + (params.row[month] || 0);
        }, 0);
        return total;
      },
      valueFormatter: renderAsCurrencyFormatter
    }
  ];

  return createdBudgetColumns;
};

export const createCampaignBudgetGroupColumns = () => {
  const createdBudgetColumns: GridColDef[] = [
    { field: 'campaignBudgetGroupName', headerName: 'Budget Group Name', width: 150, renderCell: renderCampaignBudgetGroupName },
    ...MONTH_COLUMNS.map((monthColumn) => ({
      ...monthColumn
    })),
    {
      field: 'TOTAL',
      headerName: 'TOTAL',
      cellClassName: 'total-cell',
      sortable: false,
      valueGetter: (params) => {
        const total = listOfMonths.reduce((acc, month) => {
          return acc + (params.row[month] || 0);
        }, 0);
        return total;
      },
      valueFormatter: renderAsCurrencyFormatter
    }
  ];

  return createdBudgetColumns;
};
