import { Collapse, Alert, IconButton } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface DaypartingUpdatedRecentlyAlertProps {
  lastUpdated: Date;
}

const sevenDaysInMilliseconds = 604800000;

export const DaypartingUpdatedRecentlyAlert = ({ lastUpdated }: DaypartingUpdatedRecentlyAlertProps) => {
  const lastUpdatedInLastWeek = new Date().getTime() - lastUpdated?.getTime() < sevenDaysInMilliseconds;
  const [open, setOpen] = useState(lastUpdatedInLastWeek);

  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mt: 2 }}
        severity={'warning'}
      >
        Warning! Schedule changed in past week so OOB hours may appear outside of the current window.
      </Alert>
    </Collapse>
  );
};
