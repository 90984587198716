import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { GridCellParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  defaultMonthAmounts,
  getPreciseFloatingPointSum,
  listOfMonths,
  UpdateProfileMonthlyBudgetRequest
} from '../../../shared/types/budget';
import useAmazonApi from '../../hooks/use-amazon-api';
import {
  createCampaignBudgetGroupColumns,
  createProfileBudgetColumns
} from '../../../shared/components/grid-components/renderer/budget/budget-columns';
import AddIcon from '@mui/icons-material/Add';
import { CreateBudgetGroupDialog } from './create-budget-group-dialog';
import useDialog from '../../../shared/hooks/use-dialog';
import { BudgetGroupBulkActions } from './budget-groups-bulk-actions';
import { UpdateOrCreateProfileAndBudgetGroupBudgetsRequestDto } from '../../types/budget-group';
import AmazonBudgetGrid from './amazon-budget-grid';

const DisabledButtonTooltipMessages = {
  noProfile: 'Please select a Profile before editing',
  overbudget: "Campaign budgets's total cannot exceed Client budget"
};

// Validates the data copied from the clipboard and converts it from "$16.666,67 	$16.666,67 	$16.666,67 " or "1666667 	1666667 	1666667" to a 2D array
function validateCopiedData(text: string): Array<Array<string> | string> {
  let separatedData = [];

  const commaIndex = text.indexOf(',');
  const dotIndex = text.indexOf('.');

  if (commaIndex > -1 && dotIndex > -1) {
    if (commaIndex > dotIndex) {
      text = text.replace(/\./g, '');
    } else {
      text = text.replace(/,/g, '');
    }
  }

  //check if the text has $, if so, it's a csv file
  if (text.includes('$')) {
    separatedData = text.split('\n').map(
      (row) =>
        row
          .trim()
          .replace(/,/g, '.')
          .replace(/\$/g, '')
          .split('\t')
          .map((item) => {
            const cleanedItem = item.replace(/[^\d.-]/g, '');
            return !isNaN(parseFloat(cleanedItem)) ? parseFloat(cleanedItem).toString() : item;
          }) //replace all commas with dots and remove all non-numeric characters
    );
  } else {
    // If it doesn't have "$", then it's a newline separated file
    separatedData = text.split('\n').map(
      (row) =>
        row
          .trim()
          .replace(/\s+/g, ', ')
          .split(',')
          .map((item) => {
            const cleanedItem = item.replace(/[,$.]/g, '');
            return !isNaN(parseFloat(cleanedItem)) ? parseFloat(cleanedItem).toString() : item;
          }) //replace all commas with dots and remove all non-numeric characters
    );
  }

  let parsedData: Array<Array<string> | string> = [];

  separatedData.forEach((row) => {
    parsedData.push(row);
  });

  return parsedData;
}

export function BudgetsManager() {
  const clientGridApiRef = useGridApiRef();

  const { getProfilesWithoutMetrics, getBudgetsViewData, updateOrCreateProfileAndBudgetGroupBudgets, createBudgetGroup } =
    useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disabledEditButtonTooltip, setDisabledEditButtonTooltip] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [calendarYear, setCalendarYear] = useState(new Date().getFullYear());
  const [searchParams, setSearchParams] = useSearchParams();
  const { toggle: toggleCreateBudgetGroupDialog, isShowing: createBudgetGroupDialogIsShowing, dynamicKey } = useDialog();

  const profileId = searchParams.get('profileId') ? searchParams.get('profileId') : null;

  const [profiles, setProfiles] = useState<any[]>([]);
  const [monthTotals, setMonthTotals] = useState<{ [key: string]: any }>(defaultMonthAmounts);
  const [campaignBudgetGroups, setCampaignBudgetGroups] = useState<any[]>([]);
  const [clientBudgetRow, setClientBudgetRow] = useState<{ [key: string]: any }>({
    budgetItemName: 'Client Budget',
    id: 0,
    defaultMonthAmounts
  });

  useEffect(() => {
    const fetchProfiles = async () => {
      const response = await getProfilesWithoutMetrics();

      if (response.success) {
        setProfiles(response.body.records);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    fetchProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBudgetsViewData = async () => {
    if (!profileId) {
      return;
    }

    const response = await getBudgetsViewData(profileId, calendarYear);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error', persist: true });
      return;
    }

    if (response.body.profileBudget) {
      processProfileBudgetFromFetchRequest(response.body.profileBudget);
    }

    if (response.body.budgetGroupDtos) {
      setCampaignBudgetGroups(response.body.budgetGroupDtos);
    }
  };

  const handleCreateBudgetGroup = async (budgetGroupName: string) => {
    setIsSaving(true);

    if (!profileId) {
      enqueueSnackbar('Please select a Profile before creating a Budget Group', { variant: 'error' });
      return;
    }

    const response = await createBudgetGroup(profileId, budgetGroupName);

    if (response.success) {
      enqueueSnackbar('Budget Group created successfully', { variant: 'success' });
      toggleCreateBudgetGroupDialog();
      const newBudgetGroup = {
        campaignBudgetGroupName: budgetGroupName,
        campaignBudgetGroupId: response.body[0].id,
        year: calendarYear,
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0
      };
      setCampaignBudgetGroups((prevGroups) => [...prevGroups, newBudgetGroup]);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSaving(false);
  };

  const processProfileBudgetFromFetchRequest = (profileBudget: any) => {
    setClientBudgetRow({
      budgetItemName: 'Client Budget',
      id: profileBudget.profileId,
      year: profileBudget.year,
      january: profileBudget.january || 0,
      february: profileBudget.february || 0,
      march: profileBudget.march || 0,
      april: profileBudget.april || 0,
      may: profileBudget.may || 0,
      june: profileBudget.june || 0,
      july: profileBudget.july || 0,
      august: profileBudget.august || 0,
      september: profileBudget.september || 0,
      october: profileBudget.october || 0,
      november: profileBudget.november || 0,
      december: profileBudget.december || 0
    });
  };

  useEffect(() => {
    if (!profileId || !calendarYear) {
      return;
    }

    setIsLoading(true);

    fetchBudgetsViewData();

    setIsLoading(false);
  }, [profileId, calendarYear]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let shouldDisable = false;

    listOfMonths.map((month) => {
      if (Number(monthTotals[month]) > Number(clientBudgetRow[month])) {
        shouldDisable = true;
      }
    });

    setIsDisabled(shouldDisable);
    if (shouldDisable) {
      setDisabledEditButtonTooltip(DisabledButtonTooltipMessages.overbudget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthTotals, clientBudgetRow]);

  const processClientBudgetRowUpdate = (newRow: any, oldRow: any) => {
    //This is a work around for when the user hits delete without clicking into the cell it returns a string instead of 0
    Object.entries(newRow).map(([key, value]) => {
      if (typeof value != 'number' && key !== 'budgetItemName' && key !== 'id' && key !== 'TOTAL') {
        newRow[key] = 0;
      }
    });

    setClientBudgetRow(newRow);

    const changedFields = [];

    for (const key in oldRow) {
      if (newRow.hasOwnProperty(key) && oldRow.hasOwnProperty(key)) {
        if (newRow[key] !== oldRow[key]) {
          changedFields.push(key);
        }
      }
    }

    if (changedFields.length > 0) {
      changedFields.forEach((field) => highlightCell(newRow.id, field));
    }

    return newRow;
  };

  const processBudgetGroupRowUpdate = (newRow: any, oldRow: any) => {
    const campaignBudgetRowsCopy = [...campaignBudgetGroups];
    const rowIndex = campaignBudgetRowsCopy.findIndex((row) => row.campaignBudgetGroupId === newRow.campaignBudgetGroupId);

    campaignBudgetRowsCopy[rowIndex] = newRow;

    setCampaignBudgetGroups(campaignBudgetRowsCopy);

    return newRow;
  };

  const highlightCell = useCallback(
    (rowId: number, field: string) => {
      const cell = clientGridApiRef.current.getCellElement(rowId, field);

      cell?.classList.add('highlight-cell');
      setTimeout(() => {
        cell?.classList.remove('highlight-cell');
      }, 1500);
    },

    [clientGridApiRef]
  );

  const buildProfileBudgetDto = (): UpdateProfileMonthlyBudgetRequest => {
    return {
      profileId: profileId || 0,
      year: calendarYear,
      january: clientBudgetRow['january'],
      february: clientBudgetRow['february'],
      march: clientBudgetRow['march'],
      april: clientBudgetRow['april'],
      may: clientBudgetRow['may'],
      june: clientBudgetRow['june'],
      july: clientBudgetRow['july'],
      august: clientBudgetRow['august'],
      september: clientBudgetRow['september'],
      october: clientBudgetRow['october'],
      november: clientBudgetRow['november'],
      december: clientBudgetRow['december']
    };
  };

  const handleProfileChange = (event: any, value: any) => {
    if (!value?.profileId) {
      searchParams.delete('profileId');
      setSearchParams(searchParams);
      setIsDisabled(true);
      setDisabledEditButtonTooltip(DisabledButtonTooltipMessages.noProfile);
    } else {
      setSearchParams({
        ...searchParams,
        profileId: `${value.profileId}`
      });
    }
  };

  useEffect(() => {
    if (!campaignBudgetGroups.length || campaignBudgetGroups[0].year !== calendarYear) {
      return;
    }

    const totals = listOfMonths.reduce(
      (acc, month) => {
        acc[month] = campaignBudgetGroups.reduce((total, row) => getPreciseFloatingPointSum([total, row[month]]), 0);
        return acc;
      },
      {} as { [key: string]: number }
    );

    totals.year = calendarYear;

    setMonthTotals(totals);
  }, [campaignBudgetGroups, calendarYear]);

  function getCellClassName(params: GridCellParams) {
    if (params.row.budgetItemName !== 'TOTAL') {
      return '';
    }

    if (monthTotals[params.field] > clientBudgetRow[params.field]) {
      return 'over-budget';
    }

    return '';
  }

  async function handleSaveClick() {
    setIsSaving(true);

    let allMonthsHaveValues = true;
    Object.entries(clientBudgetRow).map(([key, value]) => {
      if (key in listOfMonths && value === null) {
        allMonthsHaveValues = false;
      }
    });

    if (!allMonthsHaveValues) {
      enqueueSnackbar('All months must have a value', { variant: 'error' });
      setIsSaving(false);
      return;
    }

    const requestBody: UpdateOrCreateProfileAndBudgetGroupBudgetsRequestDto = {
      campaignBudgetGroupBudgetsDtos: campaignBudgetGroups,
      profileMonthlyBudgetsDto: buildProfileBudgetDto()
    };

    const response = await updateOrCreateProfileAndBudgetGroupBudgets(requestBody);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      enqueueSnackbar('Budgets saved successfully', { variant: 'success' });
    }

    setIsEditing(false);
    setIsSaving(false);
  }

  async function handleEditClick() {
    setIsEditing(true);
  }

  async function handleCancelClick() {
    setIsLoading(true);
    await fetchBudgetsViewData();
    setIsLoading(false);
    setIsEditing(false);
  }

  const handlePaste = useCallback((text: string) => {
    const parsedData = validateCopiedData(text);

    if (parsedData.flat().some((item: any) => item === undefined || item === null || isNaN(Number(item)))) {
      enqueueSnackbar('Invalid data. Please make sure you copied numbers correctly and try again.', { variant: 'warning' });
      return;
    }

    return parsedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid container direction={'row'} justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <Grid container direction="row" justifyContent={'flex-start'} alignItems="center">
            <Grid item>
              <FormControl>
                <Autocomplete
                  disablePortal
                  options={profiles}
                  getOptionLabel={(profile) => profile.name}
                  onChange={handleProfileChange}
                  value={profiles.find((profile: { profileId: any }) => profile.profileId === profileId) || null}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} size="small" label="Profile" />}
                  disabled={isEditing}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Paper sx={{ py: 0.25 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Button
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => setCalendarYear(calendarYear - 1)}
                  disabled={isEditing}
                ></Button>
              </Grid>
              <Grid item>
                <Typography variant="h5">{calendarYear}</Typography>
              </Grid>
              <Grid item>
                <Button
                  endIcon={<ChevronRightIcon />}
                  onClick={() => setCalendarYear(calendarYear + 1)}
                  disabled={isEditing}
                ></Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title={isDisabled ? disabledEditButtonTooltip : ''} placement="top">
            <span style={{ float: 'right' }}>
              <Button
                variant={isEditing ? 'contained' : 'outlined'}
                onClick={isEditing ? handleSaveClick : handleEditClick}
                disabled={isDisabled}
                sx={{ opacity: !!profileId ? 1 : 0 }}
              >
                {isEditing ? 'Save' : 'Edit'}
              </Button>
            </span>
          </Tooltip>
          {isEditing ? (
            <span style={{ float: 'right' }}>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </span>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Box
        sx={[
          { mb: 4 },
          ...listOfMonths.map((month: string) => {
            if (
              !clientBudgetRow ||
              clientBudgetRow.year !== calendarYear ||
              clientBudgetRow.year !== calendarYear ||
              clientBudgetRow.year !== monthTotals.year
            ) {
              return {};
            }

            if (Number(monthTotals[month]) > Number(clientBudgetRow[month])) {
              return {
                [`& .header--validate-monthly-aggregate--${month}`]: {
                  backgroundColor: (theme: { palette: { error: { main: any } } }) => theme.palette.error.main
                }
              };
            }

            if (Number(monthTotals[month]) === Number(clientBudgetRow[month]) && isEditing) {
              return {
                [`& .header--validate-monthly-aggregate--${month}`]: {
                  backgroundColor: (theme: { palette: { success: { main: any } } }) => theme.palette.success.main
                }
              };
            }

            return {};
          })
        ]}
      >
        <Box sx={{ my: 3 }}>
          <Typography variant="h5" sx={{ mb: 1.5 }}>
            Client Budget
          </Typography>
          <AmazonBudgetGrid
            apiRef={clientGridApiRef}
            calendarYear={calendarYear}
            rows={!!profileId ? [clientBudgetRow] : []}
            columns={createProfileBudgetColumns()}
            getCellClassName={undefined}
            processRowUpdate={processClientBudgetRowUpdate}
            getRowClassName={(params) => (params.row?.budgetItemName === 'TOTAL' ? 'total-row' : '')}
            initialState={undefined}
            canEdit={isEditing}
            loading={isLoading}
            experimentalFeatures={{ clipboardPaste: isEditing ? true : false }}
            checkboxSelection={false}
            unstable_cellSelection={false}
            unstable_splitClipboardPastedText={(text: string) => handlePaste(text) as any[]}
          />
        </Box>
        <Box sx={{ my: 3, mb: 10 }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 1.5 }}>
            <Typography variant="h5">Budget Groups</Typography>
            <Button startIcon={<AddIcon fontSize="small" />} variant="contained" onClick={toggleCreateBudgetGroupDialog}>
              Create Budget Group
            </Button>
          </Box>
          <Box height={'500px'}>
            <AmazonBudgetGrid
              apiRef={clientGridApiRef}
              calendarYear={calendarYear}
              rows={!!profileId ? campaignBudgetGroups : []}
              columns={createCampaignBudgetGroupColumns()}
              getRowClassName={(params) => (params.row?.budgetItemName === 'TOTAL' ? 'total-row' : '')}
              getRowId={(row) => row.campaignBudgetGroupId}
              processRowUpdate={processBudgetGroupRowUpdate}
              getCellClassName={getCellClassName}
              canEdit={isEditing}
              checkboxSelection={true}
              loading={isLoading}
              experimentalFeatures={{ clipboardPaste: isEditing ? true : false }}
              bulkActions={<BudgetGroupBulkActions refreshBudgetsView={fetchBudgetsViewData} />}
              unstable_cellSelection={false}
              unstable_splitClipboardPastedText={(text: string) => handlePaste(text) as any[]}
              initialState={{
                aggregation: {
                  model: {
                    january: 'sum',
                    february: 'sum',
                    march: 'sum',
                    april: 'sum',
                    may: 'sum',
                    june: 'sum',
                    july: 'sum',
                    august: 'sum',
                    september: 'sum',
                    october: 'sum',
                    november: 'sum',
                    december: 'sum',
                    TOTAL: 'sum'
                  }
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      {createBudgetGroupDialogIsShowing && (
        <CreateBudgetGroupDialog
          isShowing={createBudgetGroupDialogIsShowing}
          toggle={toggleCreateBudgetGroupDialog}
          dynamicKey={dynamicKey}
          isLoading={isLoading}
          handleCreateBudgetGroup={handleCreateBudgetGroup}
        />
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSaving}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
