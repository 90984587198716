import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import useAdsApi from '../../../shared/hooks/use-citrusad-api';
import { CITRUSAD_CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { useDateConfig } from '../../../shared/hooks/use-date-config';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const Campaigns: FC = () => {
  const { getCampaigns, updateCampaign } = useAdsApi(Platform.CITRUS_AD.value);
  const { enqueueSnackbar } = useSnackbar();

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [campaignTotals, setCampaignTotals] = useState({});
  let [searchParams] = useSearchParams();
  let teamId = searchParams.get('profileId');
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.CITRUSAD_CAMPAIGN,
    breadCrumbParams: { teamId }
  });

  const { dateSettings, dateSettingsLoading, saveDateConfig } = useDateConfig();
  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const payload: any = { productCampaign: {} };

    if (newRow.startTime !== oldRow.startTime) {
      payload['productCampaign']['startTime'] = newRow.startTime;
      payload['mask'] = 'startTime';
    }

    if (newRow.endTime !== oldRow.endTime) {
      payload['productCampaign']['endTime'] = newRow.endTime;
      payload['mask'] = 'endTime';
    }

    if (newRow.maxBid !== oldRow.maxBid) {
      payload['productCampaign']['strategy'] = {
        auction: {
          maxBid: `${newRow.maxBid}`
        }
      };
      payload['mask'] = 'strategy.auction.maxBid';
    }

    if (newRow['dailySpendLimit'] !== oldRow['dailySpendLimit']) {
      payload['productCampaign']['strategy'] = {
        auction: {
          spendLimit: {
            daily: `${newRow['dailySpendLimit']}`
          }
        }
      };
      payload['mask'] = 'strategy.auction.spendLimit.daily';
    }

    if (newRow['totalSpendLimit'] !== oldRow['totalSpendLimit']) {
      payload['productCampaign']['strategy'] = {
        auction: {
          maxBid: `${newRow.totalSpendLimit}`,
          spendLimit: {
            total: `${newRow['totalSpendLimit']}`
          }
        }
      };
      payload['mask'] = 'strategy.auction.spendLimit.total';
    }

    updateCampaign(newRow.id, payload);

    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ teamId });
  }, [teamId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pageable || settingsLoading || dateSettingsLoading) {
      return;
    }

    const fetchCampaigns = async () => {
      setIsLoading(true);

      const response = await getCampaigns(pageable, dateSettings.beginDate, dateSettings.endDate);

      if (response.success) {
        setCampaigns(response.body?.records);
        setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchCampaigns();
  }, [pageable, dateSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Page title="Campaigns">
        <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="h4">Campaigns</Typography>
              <Box
                sx={{
                  mr: 'auto'
                }}
              ></Box>
              <Link
                to={`/${Platform.CITRUS_AD.value}/campaigns/create${teamId ? `?profileId=${teamId}` : ''}`}
                component={RouterLink}
                style={{ textDecoration: 'none' }}
              >
                <Button startIcon={<AddIcon fontSize="small" />} variant="contained">
                  Create Campaign
                </Button>
              </Link>
            </Box>

            {!initialSettings || settingsLoading || dateSettingsLoading ? (
              <DataGridPremium
                rows={[]}
                disableRowGrouping
                columns={CITRUSAD_CAMPAIGN_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
              />
            ) : (
              <PerformanceGrid
                loading={isLoading}
                processRowUpdate={processRowUpdate}
                rows={campaigns}
                columns={CITRUSAD_CAMPAIGN_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  saveDateConfig: saveDateConfig
                }}
                getRowId={(row: any) => row.id}
                getRowClassName={(params) => `row-status--${params.row.status}`}
                handleFilterModelChange={handleFilterModelChange}
                handleSortModelChange={handleSortModelChange}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                rowCount={rowCount}
                aggregatedTotals={campaignTotals}
              />
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default Campaigns;
