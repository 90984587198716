import { DataGridPremium } from '@mui/x-data-grid-premium';
import { AUTOMATED_ACTION_COLUMNS } from './automated-actions-view/automated-action-columns';
import { FC, useCallback, useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import useAmazonApi from '../../hooks/use-amazon-api';

interface AutomatedActionsViewProps {
  profileId: string;
}

const AutomatedActionsView: FC<AutomatedActionsViewProps> = (props) => {
  const profileId = props.profileId;
  const [rows, setRows] = useState<any[]>([]);
  const { getAutomatedActions, updateAutomatedAction } = useAmazonApi();

  useEffect(() => {
    const fetchAutomatedActions = async () => {
      if (!profileId) {
        return;
      }

      const response = await getAutomatedActions(profileId);
      if (!response.success) {
        enqueueSnackbar(
          'Error fetching automated actions, please try refreshing the page. If the issue persists, reach out for support',
          { variant: 'error' }
        );
        return;
      }
      setRows(response.body);
    };

    fetchAutomatedActions();
  }, [profileId]); //eslint-disable-line react-hooks/exhaustive-deps

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);

    if (changedFields.length == 0) {
      return oldRow;
    }

    const response = await updateAutomatedAction(newRow);
    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    enqueueSnackbar('Update successful', { variant: 'success' });
    return { ...newRow };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    rows && (
      <>
        <DataGridPremium
          rows={rows}
          checkboxSelection
          columns={AUTOMATED_ACTION_COLUMNS}
          loading={false}
          processRowUpdate={processRowUpdate}
          key={Math.random()}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              }
            }
          }}
        />
      </>
    )
  );
};

export default AutomatedActionsView;
