import { ProductTargetMatchType } from '../../../../types/target';
import {
  AmazonExpressionTypes,
  ExpressionItem,
  UserAddedRefinements,
  UserAddedRefinementsKeys
} from '../../../../types/targeting-refinements';

export function buildRefinedCategory(userAddedRefinements: UserAddedRefinements): ExpressionItem[] {
  const arrayRefinementKeys = [
    UserAddedRefinementsKeys.ageRanges,
    UserAddedRefinementsKeys.brands,
    UserAddedRefinementsKeys.genres
  ] as const;

  let refinedExpressions: ExpressionItem[] = [];

  // Loop through arrayRefinements
  for (let key of arrayRefinementKeys) {
    if (userAddedRefinements[key].length === 0) {
      continue;
    }

    const amazonExpressionType = AmazonExpressionTypes[key];
    const keyExpressions: ExpressionItem[] = userAddedRefinements[key].map((refinement: { id: any; name: any }) => ({
      type: amazonExpressionType,
      value: refinement.id,
      resolvedValue: refinement.name
    }));

    refinedExpressions = [...refinedExpressions, ...keyExpressions];
  }

  // Handle reviewRating refinements
  if (userAddedRefinements.reviewRatingBetween.start > 0 && userAddedRefinements.reviewRatingBetween.end < 5) {
    refinedExpressions.push({
      type: AmazonExpressionTypes.asinReviewRatingBetween,
      value: `${userAddedRefinements.reviewRatingBetween.start}-${userAddedRefinements.reviewRatingBetween.end}`
    });
  } else if (userAddedRefinements.reviewRatingBetween.start > 0 && userAddedRefinements.reviewRatingBetween.end === 5) {
    refinedExpressions.push({
      type: AmazonExpressionTypes.asinReviewRatingGreaterThan,
      value: String(userAddedRefinements.reviewRatingBetween.start - 1)
    });
  } else if (userAddedRefinements.reviewRatingBetween.start === 0 && userAddedRefinements.reviewRatingBetween.end < 5) {
    refinedExpressions.push({
      type: AmazonExpressionTypes.asinReviewRatingLessThan,
      value: String(userAddedRefinements.reviewRatingBetween.end + 1)
    });
  }

  // Handle price refinements
  if (userAddedRefinements.priceGreaterThan > 0 && userAddedRefinements.priceLessThan > 0) {
    refinedExpressions.push({
      type: AmazonExpressionTypes.asinPriceBetween,
      value: `${userAddedRefinements.priceGreaterThan}-${userAddedRefinements.priceLessThan}`
    });
  } else if (userAddedRefinements.priceGreaterThan > 0) {
    refinedExpressions.push({
      type: AmazonExpressionTypes.asinPriceGreaterThan,
      value: String(userAddedRefinements.priceGreaterThan)
    });
  } else if (userAddedRefinements.priceLessThan > 0) {
    refinedExpressions.push({
      type: AmazonExpressionTypes.asinPriceLessThan,
      value: String(userAddedRefinements.priceLessThan)
    });
  }

  // Add prime shipping refinement
  if (userAddedRefinements.isPrimeShippingEligible === true) {
    refinedExpressions.push({
      type: AmazonExpressionTypes.asinIsPrimeShippingEligible,
      value: 'true'
    });
  }

  return refinedExpressions;
}

export const allRefinementsMatch = (refinementsA: any[], refinementsB: any[]) => {
  if (!refinementsA && refinementsB?.length > 0) {
    return false;
  }

  if (!refinementsB && refinementsA?.length > 0) {
    return false;
  }

  if (!refinementsA && !refinementsB) {
    return true;
  }

  return (
    refinementsA.every((refinementA) => {
      return refinementsB.some((refinementB) => {
        return (
          refinementA.type.toLowerCase() === refinementB.type.toLowerCase() &&
          refinementA.value.toLowerCase() === refinementB.value.toLowerCase()
        );
      });
    }) &&
    refinementsB.every((refinementB) => {
      return refinementsA.some((refinementA) => {
        return (
          refinementB.type.toLowerCase() === refinementA.type.toLowerCase() &&
          refinementB.value.toLowerCase() === refinementA.value.toLowerCase()
        );
      });
    })
  );
};

export function getAsinCountText(asinCount: { min: number; max: number }) {
  if (!asinCount || (!asinCount.min && !asinCount.max) || (asinCount.min === 0 && asinCount.max === 0)) {
    return ' ';
  } else if (asinCount.min === asinCount.max) {
    return `Products: ${asinCount.min}`;
  } else {
    return `Products: ${asinCount.min}-${asinCount.max}`;
  }
}

export function buildAddedTargetListItemFromCatalogItem(item: any, type: any, bid: number) {
  if (type !== 'product') {
    return;
  }

  const productExpression =
    item.matchType === ProductTargetMatchType.Exact ? AmazonExpressionTypes.asinSameAs : AmazonExpressionTypes.asinExpandedFrom;

  return {
    key: item.asin,
    recommendedAsin: item.asin,
    themes: [],
    matchType: item.matchType,
    bid: bid,
    targetingClause: {
      expression: [{ type: productExpression, value: item.asin }],
      resolvedExpression: [{ type: productExpression, value: item.asin }],
      expressionType: 'MANUAL',
      state: 'ENABLED',
      bid: bid
    }
  };
}

export function buildAddedTargetListItemFromRecommendation(item: any, type: any, bid: number) {
  if (type === 'product') {
    const productExpression =
      item.matchType === ProductTargetMatchType.Exact ? AmazonExpressionTypes.asinSameAs : AmazonExpressionTypes.asinExpandedFrom;

    return {
      key: item.recommendedAsin,
      recommendedAsin: item.recommendedAsin,
      themes: item.themes,
      matchType: item.matchType,
      bid: bid,
      targetingClause: {
        expression: [{ type: productExpression, value: item.recommendedAsin }],
        resolvedExpression: [{ type: productExpression, value: item.recommendedAsin }],
        expressionType: 'MANUAL',
        state: 'ENABLED',
        bid: bid
      }
    };
  }

  return {
    key: item.name,
    id: item.id,
    categoryPath: item.categoryPath,
    name: item.name,
    asinCount: item.asinCount,
    asinCountText: getAsinCountText(item.asinCounts),
    refinements: item.refinements,
    bid: bid,
    targetingClause: {
      expression: [
        { type: AmazonExpressionTypes.asinCategorySameAs, value: item.id },
        ...(item.refinements && item.refinements.length > 0
          ? item.refinements.map((refinement: any) => ({ type: refinement.type, value: refinement.value }))
          : [])
      ],
      resolvedExpression: [
        { type: AmazonExpressionTypes.asinCategorySameAs, value: item.name },
        ...(item.refinements && item.refinements.length > 0
          ? item.refinements.map((refinement: any) => ({
              type: refinement.type,
              value: refinement?.resolvedValue ?? refinement.value
            }))
          : [])
      ],
      expressionType: 'MANUAL',
      state: 'ENABLED',
      bid: bid
    }
  };
}
