import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Slider,
  Typography
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { UserAddedRefinements, UserAddedRefinementsKeys } from '../../../../types/targeting-refinements';
import { CurrencyInput } from '../../../../../shared/components/inputs/currency-input';

interface RefinementListProps {
  title: string;
  refinements?: any[];
  userAddedRefinements: any[];
  onRefinementChange: (type: keyof UserAddedRefinements, newValue: any) => void;
  refinementType: keyof UserAddedRefinements;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const RefinementList = (props: RefinementListProps) => {
  const { title, refinements, userAddedRefinements, onRefinementChange, refinementType } = props;

  if (!refinements || refinements.length === 0) return null;

  return (
    <>
      <FormControl sx={{ mt: 2, mb: 2, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">{title}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          label={title}
          value={userAddedRefinements}
          onChange={(e) => {
            const newRefinements = e.target.value as any[];

            onRefinementChange(refinementType, newRefinements);
          }}
          input={<OutlinedInput label={title} />}
          renderValue={(selected) => (selected as any[]).map((item) => item.name).join(', ')}
          MenuProps={MenuProps}
        >
          {refinements.map((refinement: any) => {
            return (
              <MenuItem key={refinement.id} value={refinement}>
                <Checkbox
                  checked={userAddedRefinements.some((userAddedRefinement) => userAddedRefinement.name === refinement.name)}
                />
                <ListItemText primary={refinement.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

interface PriceRefinementInputProps {
  label: string;
  type: keyof typeof UserAddedRefinementsKeys;
  onExpressionChange: (expressionType: keyof typeof UserAddedRefinementsKeys, newValue: any) => void;
}

export const PriceRefinementInput: React.FC<PriceRefinementInputProps> = ({ label, type, onExpressionChange }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!value) {
      return;
    }

    onExpressionChange(type, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <CurrencyInput label={label} type="number" value={value} onChange={(event) => setValue(event.target.value)} />;
};

interface RangeInputProps {
  label: string;
  type: keyof typeof UserAddedRefinementsKeys;
  onExpressionChange: (expressionType: keyof typeof UserAddedRefinementsKeys, newValue: any) => void;
}

export const RangeInput: React.FC<RangeInputProps> = ({ label, type, onExpressionChange }) => {
  const [value, setValue] = useState({ start: '', end: '' });

  useEffect(() => {
    onExpressionChange(type, `${value.start}-${value.end}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value
    });
  };

  return (
    <>
      <TextField label={`${label} Start`} type="number" name="start" value={value.start} onChange={handleChange} />
      <TextField label={`${label} End`} type="number" name="end" value={value.end} onChange={handleChange} />
    </>
  );
};

interface BooleanInputProps {
  label: string;
  type: keyof typeof UserAddedRefinementsKeys;
  onExpressionChange: (expressionType: keyof typeof UserAddedRefinementsKeys, newValue: any) => void;
}

export const BooleanInput: React.FC<BooleanInputProps> = ({ label, type, onExpressionChange }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    onExpressionChange(type, checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} name={label} color="primary" />}
      label={label}
    />
  );
};

interface RatingSliderProps {
  label: string;
  type: keyof typeof UserAddedRefinementsKeys;
  onExpressionChange: (expressionType: keyof typeof UserAddedRefinementsKeys, newValue: any) => void;
}

export const RatingSlider: React.FC<RatingSliderProps> = ({ label, type, onExpressionChange }) => {
  const [value, setValue] = useState<number[]>([0, 5]);

  const handleChange = (event: Event, newValue: any) => {
    if (newValue[0] === newValue[1] || newValue[0] > newValue[1]) {
      return;
    }

    onExpressionChange(type, { start: newValue[0], end: newValue[1] });
    setValue(newValue as number[]);
  };

  const marks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: 1,
      label: '1'
    },
    {
      value: 2,
      label: '2'
    },
    {
      value: 3,
      label: '3'
    },
    {
      value: 4,
      label: '4'
    },
    {
      value: 5,
      label: '5'
    }
  ];

  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={0}
        max={5}
        sx={{ maxWidth: '400px', mr: 4 }}
        marks={marks}
      />
      <Typography sx={{ minWidth: '100px' }}>
        {value[0] === 0 && value[1] === 5 ? 'All Stars' : `${value[0]} - ${value[1]} Stars`}
      </Typography>
    </Box>
  );
};

interface RatingSettingsProps {
  label: string;
  onExpressionChange: (expressionType: keyof typeof UserAddedRefinementsKeys, newValue: any) => void;
}

export const RatingSettings: React.FC<RatingSettingsProps> = ({ label, onExpressionChange }) => {
  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography variant="h6">{label}</Typography>
      <Divider />

      <Box sx={{ mx: 2 }}>
        <RatingSlider label={label} type={UserAddedRefinementsKeys.reviewRatingBetween} onExpressionChange={onExpressionChange} />
      </Box>
    </Paper>
  );
};
