import { Container, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DashboardBudgetPacing } from '../../../shared/components/dashboard-budget-pacing';
import Page from '../../../shared/components/page';
import ProfileSelect from '../../../shared/components/page-components/profile-select';
import { useCurrentRetailerPlatform } from '../../../shared/hooks/use-current-retailer-platform';
import { Platform } from '../../../shared/types/platforms';
import useAmazonApi from '../../hooks/use-amazon-api';
import { WastedSpendModule } from './wasted-spend-module';

const ProfileDashboard: FC = () => {
  const retailerPlatform = useCurrentRetailerPlatform();
  const title = retailerPlatform === Platform.AMAZON.value ? 'Amazon Dashboard' : 'Dashboard';

  const [searchParams] = useSearchParams();
  const { getProfilesWithoutMetrics } = useAmazonApi();
  const profileId = searchParams.get('profileId');

  return (
    <Page title={title}>
      <Container maxWidth="xl" sx={{ mb: 10 }}>
        <Typography variant="h5" marginBottom={'10px'}>
          {title}
        </Typography>
        <ProfileSelect profileId={profileId} getProfiles={getProfilesWithoutMetrics} />
        <Grid
          display={profileId ? 'flex' : 'none'}
          container
          spacing={{
            xs: 2,
            lg: 2
          }}
          pt={2}
        >
          <Grid item xs={12} lg={6}>
            <Stack
              spacing={{
                xs: 2,
                lg: 2
              }}
            >
              <DashboardBudgetPacing profileId={profileId} />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6}>
            <WastedSpendModule />
          </Grid>
        </Grid>
        <Grid
          display={profileId ? 'flex' : 'none'}
          container
          spacing={{
            xs: 2,
            lg: 2
          }}
          pt={2}
        >
          <Grid item xs={12} lg={6}>
            <Stack
              spacing={{
                xs: 2,
                lg: 2
              }}
            ></Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ProfileDashboard;
