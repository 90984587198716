import { DateRange } from '@mui/x-date-pickers-pro';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import useSharedApi from './use-shared-api';

const eightDaysAgo = new Date(new Date().setDate(new Date().getDate() - 8)).toISOString().split('T')[0];
const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
const DATE_SETTINGS_KEY = 'DateSettings';

export interface DateConfig {
  beginDate: string;
  endDate: string;
  datePickerIncrementInDays: number;
}

const defaultDateConfig: DateConfig = {
  beginDate: eightDaysAgo,
  endDate: yesterday,
  datePickerIncrementInDays: 7
};

export interface DateConfigUIProps {
  dateSettings: DateConfig;
  dateSettingsLoading: boolean;
  saveDateConfig: (dateRange: DateRange<Date>, newDatePickerIncrementInDays: number) => void;
}

export const useDateConfig = () => {
  const [dateSettings, setDateSettings] = useState<DateConfig>(defaultDateConfig);
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  const { getUserSetting, updateUserSetting } = useSharedApi();

  const fetchDateConfig = async () => {
    let loadedDateConfig: DateConfig;

    const response = await getUserSetting(DATE_SETTINGS_KEY);

    if (response.success && response.body && typeof response.body === 'string') {
      const dateConfig = JSON.parse(response.body);
      loadedDateConfig = dateConfig;
    } else {
      loadedDateConfig = defaultDateConfig;
    }

    return loadedDateConfig;
  };

  const saveDateConfig = (dateRange: DateRange<Date>, newDatePickerIncrementInDays: number) => {
    if (dateSettingsLoading) {
      return;
    }

    if (dateRange[0] && dateRange[1]) {
      const newDateSettings = {
        beginDate: format(dateRange[0], 'yyyy/MM/dd'),
        endDate: format(dateRange[1], 'yyyy/MM/dd'),
        datePickerIncrementInDays: newDatePickerIncrementInDays
      };

      updateUserSetting(DATE_SETTINGS_KEY, JSON.stringify(newDateSettings));

      setDateSettings(newDateSettings);
    }
  };

  useEffect(() => {
    fetchDateConfig().then((loadedDateConfig) => {
      setDateSettings(loadedDateConfig);
      setDateSettingsLoading(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    dateSettings,
    dateSettingsLoading,
    saveDateConfig
  };
};
