import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import Box from '@mui/material/Box';
import { Divider, TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DatePicker } from '@mui/x-date-pickers-pro';
import InlineInput from '../../../pages/campaigns/campaign-overview/inline-input';
import { convertDateToMonthDayYear } from '../../../utilities/date-utilities';

interface CampaignOverviewDateRangePickerProps {
  initialDateRange?: DateRange<Date> | undefined;
  initialIncrementInDays?: number | string | undefined;
  onChange?: (dateRange: DateRange<Date>, incrementInDays: number) => void;
  maxDate?: Date;
  campaign: any;
  handleSaveInlineInput: any;
}

const defaultDateRange: DateRange<Date> = [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()];

export default function CampaignOverviewDateRangePicker(props: CampaignOverviewDateRangePickerProps) {
  const { initialDateRange, initialIncrementInDays, onChange, campaign, handleSaveInlineInput } = props;
  const theme = useTheme();
  const [dateRange, setDateRange] = useState<DateRange<Date>>(initialDateRange || defaultDateRange);
  const [incrementInDays, setIncrementInDays] = useState<any>(initialIncrementInDays || 7);

  useEffect(() => {
    onChange?.(dateRange, incrementInDays);
  }, [dateRange, incrementInDays]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        width: '100%',
        borderRadius: '1rem',
        border: '1px solid',
        borderColor: theme.palette.divider
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ gap: 'auto', width: '100%', paddingX: '10px' }}
      >
        <Typography
          variant="body1"
          sx={{ color: theme.palette.text.primary, paddingY: '15px', pl: '10px', alignSelf: 'flex-start' }}
        >
          Date Range:
        </Typography>
        <DateRangeIcon sx={{ color: theme.palette.text.primary, mr: '10px' }} />
      </Box>

      <Divider orientation="horizontal" flexItem variant="fullWidth" />

      <Box sx={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
        <Box
          sx={{
            borderRadius: '0.5rem',
            border: '1px solid',
            borderColor: theme.palette.divider,
            padding: '10px',
            margin: '10px'
          }}
        >
          <InlineInput
            value={convertDateToMonthDayYear(campaign.startDate)}
            onSave={handleSaveInlineInput('startDate')}
            label="Start Date"
            EditModeComponent={DatePicker}
            EditModeComponentProps={{
              componentsProps: { actionBar: { actions: ['clear'] } },
              renderInput: (params: any) => <TextField {...params} name="startDate" size="small" />
            }}
            emptyFieldPlaceHolder="No Start Date"
          />
        </Box>
        <Box
          sx={{
            borderRadius: '0.5rem',
            border: '1px solid',
            borderColor: theme.palette.divider,
            padding: '10px',
            margin: '10px'
          }}
        >
          <InlineInput
            value={convertDateToMonthDayYear(campaign.endDate)}
            onSave={handleSaveInlineInput('endDate')}
            label="End Date"
            EditModeComponent={DatePicker}
            EditModeComponentProps={{
              componentsProps: { actionBar: { actions: ['clear'] } },
              minDate: campaign.startDate,
              renderInput: (params: any) => <TextField {...params} name="endDate" size="small" />
            }}
            emptyFieldPlaceHolder="No End Date"
          />
        </Box>
      </Box>
    </Box>
  );
}
