import { Divider } from '@mui/material';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import EnableCampaignsActionButton from './enable-campaigns-action-button';
import DisableCampaignsActionButton from './pause-campaigns-action-button';

interface CampaignBulkActionsProps {
  setIsLoading: (loading: boolean) => void;
  refreshCampaigns: () => void;
}

const CampaignBulkActions = ({ setIsLoading, refreshCampaigns }: CampaignBulkActionsProps) => {
  const apiRef = useGridApiContext();

  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (
    params: GridRowSelectionModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  return (
    <>
      <EnableCampaignsActionButton
        setIsLoading={setIsLoading}
        refreshCampaigns={refreshCampaigns}
        selectionModelHasRows={selectionModelHasRows}
      />
      <DisableCampaignsActionButton
        setIsLoading={setIsLoading}
        refreshCampaigns={refreshCampaigns}
        selectionModelHasRows={selectionModelHasRows}
      />
      <Divider orientation="vertical" flexItem />
    </>
  );
};

export default CampaignBulkActions;
