import { FC, ReactNode, useEffect } from 'react';

interface PageProps {
  title?: string;
  children?: ReactNode;
}

const Page: FC<PageProps> = ({ children, title }) => {
  useEffect(() => {
    document.title = title || '';
  }, [title]);

  return <>{children}</>;
};

export default Page;
