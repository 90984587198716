import InfoIcon from '@mui/icons-material/Info';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import {
  BiddingStrategies,
  AmazonSponsoredProductsDynamicBidding,
  BiddablePlacements,
  StrategyType,
  TransformDynamicBidding
} from '../../../../types/campaign';
import { PlacementBidding } from './placement-bidding';

interface DynamicBiddingDialogProps {
  isOpen: boolean;
  toggle: () => void;
  campaignId: string;
  campaignName: string;
  amazonSponsoredProductsDynamicBidding: AmazonSponsoredProductsDynamicBidding;
  setAmazonSponsoredProductsDynamicBidding: (
    amazonSponsoredProductsDynamicBidding: AmazonSponsoredProductsDynamicBidding
  ) => void;
}

export const DynamicBiddingDialog = (props: DynamicBiddingDialogProps) => {
  const {
    isOpen,
    toggle,
    campaignId,
    campaignName,
    amazonSponsoredProductsDynamicBidding,
    setAmazonSponsoredProductsDynamicBidding
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { updateCampaign } = useAmazonApi();
  const apiRef = useGridApiContext();

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSaveButtonClick = async () => {
    const backendDynamicBidding = TransformDynamicBidding.toBackend(amazonSponsoredProductsDynamicBidding);

    const updatedRow = { campaignId, amazonSponsoredProductsDynamicbidding: backendDynamicBidding };

    setIsLoading(true);
    setIsSaveButtonDisabled(true);

    const response = await updateCampaign(updatedRow);

    if (response.success) {
      enqueueSnackbar('Campaign updated successfully', { variant: 'success' });
      if (!!apiRef) {
        apiRef.current?.updateRows([{ campaignId, amazonSponsoredProductsDynamicbidding: backendDynamicBidding }]);
      }
    } else {
      enqueueSnackbar('Error updating campaign', { variant: 'error' });
    }

    setIsLoading(false);
    setIsSaveButtonDisabled(false);

    toggle();
  };

  return (
    <Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth>
      <DialogTitle> DYNAMIC BIDDING - {campaignName}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant={'subtitle1'}>Strategy</Typography>
          <Select
            sx={{ width: '100%' }}
            value={amazonSponsoredProductsDynamicBidding.strategy}
            onChange={(e: any) => {
              setAmazonSponsoredProductsDynamicBidding({
                ...amazonSponsoredProductsDynamicBidding,
                strategy: e.target.value as StrategyType
              });
            }}
          >
            {Object.keys(BiddingStrategies).map((key) => (
              <MenuItem key={key} value={BiddingStrategies[key as keyof typeof BiddingStrategies].value}>
                {BiddingStrategies[key as keyof typeof BiddingStrategies].label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Divider sx={{ my: 4 }} />
        <Box sx={{}}>
          <Typography variant={'subtitle1'}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              Placement Bidding{' '}
              <Tooltip title="To disable a modifier, set its value to 0" placement="top">
                <InfoIcon sx={{ ml: 2 }} />
              </Tooltip>
            </Box>
          </Typography>
          <Grid container spacing={2} direction={'column'}>
            {Object.keys(BiddablePlacements).map((key) => (
              <Grid item xs={12} key={key}>
                <PlacementBidding
                  placement={BiddablePlacements[key as keyof typeof BiddablePlacements].value}
                  percentage={
                    amazonSponsoredProductsDynamicBidding.placementBidding[
                      BiddablePlacements[key as keyof typeof BiddablePlacements].value
                    ]
                  }
                  setPercentage={(percentage: number | undefined) => {
                    setIsSaveButtonDisabled(percentage === undefined || percentage < 0);

                    setAmazonSponsoredProductsDynamicBidding({
                      ...amazonSponsoredProductsDynamicBidding,
                      placementBidding: {
                        ...amazonSponsoredProductsDynamicBidding.placementBidding,
                        [BiddablePlacements[key as keyof typeof BiddablePlacements].value]: percentage
                      }
                    });
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>Cancel</Button>
        <Button onClick={handleSaveButtonClick} variant="contained" disabled={isSaveButtonDisabled}>
          Save
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
