import { Typography } from '@mui/material';
import { AmazonExpressionTypes } from '../../../../../types/targeting-refinements';
import React from 'react';

const CategoryTargetCell = ({ expression }: any) => {
  if (!expression) {
    return '';
  }

  const innerText = expression.map((expressionItem: any, index: number) => {
    if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinCategorySameAs.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Category: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinBrandSameAs.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Brand: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinPriceBetween.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Price: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinPriceGreaterThan.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Price:</strong> {'> '}
          {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinPriceLessThan.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Price:</strong> {'< '}
          {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinReviewRatingBetween.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Review Rating: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinReviewRatingGreaterThan.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Review Rating:</strong> {'> '}
          {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinReviewRatingLessThan.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Review Rating:</strong> {'< '}
          {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinIsPrimeShippingEligible.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Prime Shipping Eligible: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    }
  });

  return (
    <>
      <Typography variant="body2" color="text.primary" fontSize={11}>
        {innerText}
      </Typography>
    </>
  );
};

export default CategoryTargetCell;
