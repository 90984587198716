export interface MetricMinimum {
  metricType: string;
  value: number;
}

export interface KeywordHarvestingSettings {
  campaignId: number;
  defaultBid: number;
  isAutomationEnabled: boolean;
  applyAsEnabled: boolean;
  keywordTextDoesNotContain: string[];
  metricMinimums: MetricMinimum[];
}

export const defaultMetrics: MetricMinimum[] = [
  { metricType: 'ReturnOnAdSpend', value: 3 },
  { metricType: 'Units', value: 5 }
];

export const initialSettings = {
  campaignId: 0,
  defaultBid: 0,
  isAutomationEnabled: false,
  applyAsEnabled: true,
  keywordTextDoesNotContain: [],
  metricMinimums: defaultMetrics
};

export interface KeywordApplyConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  profileId: number;
  campaignId: number;
}

export const SETTINGS_TYPES = [
  {
    label: 'Default Bid',
    value: 'defaultBid'
  },
  {
    label: 'Minimum Days of Data',
    value: 'minimumDaysOfData'
  },
  {
    label: 'Apply As Enabled',
    value: 'applyAsEnabled'
  },
  {
    label: 'Automation Enabled',
    value: 'isAutomationEnabled'
  },
  {
    label: 'Keyword Text Does Not Contain',
    value: 'keywordTextDoesNotContain'
  },
  {
    label: 'Metric Minimums',
    value: 'metricMinimums'
  }
];

export const V1_METRIC_TYPES = [
  {
    label: 'Cost',
    value: 'Cost'
  },
  {
    label: 'Attributed Revenue',
    value: 'AttributedRevenue'
  },
  {
    label: 'Impressions',
    value: 'Impressions'
  },
  {
    label: 'Clicks',
    value: 'Clicks'
  },
  {
    label: 'Conversions',
    value: 'Conversions'
  },
  {
    label: 'RoAS',
    value: 'ReturnOnAdSpend'
  },
  {
    label: 'Cost Per Click',
    value: 'CostPerClick'
  },
  {
    label: 'Cost Per Conversion',
    value: 'CostPerConversion'
  },
  {
    label: 'Click Through Rate',
    value: 'ClickThroughRate'
  },
  {
    label: 'Conversion Rate',
    value: 'ConversionRate'
  }
];

export const DOLLAR_AMOUNT_METRIC_TYPES = ['Cost', 'AttributedRevenue', 'CostPerClick', 'CostPerConversion', 'ReturnOnAdSpend'];

export const KEYWORD_HARVESTING_COLUMNS = [
  { field: 'keywordText', headerName: 'Keyword', width: 200 },
  { field: 'itemId', headerName: 'Item ID', width: 130 },
  { field: 'adGroupId', headerName: 'Ad Group ID', width: 150 },
  { field: 'campaignId', headerName: 'Campaign ID', width: 150 },
  { field: 'profileId', headerName: 'Profile ID', width: 130 },
  { field: 'cost', headerName: 'Cost', type: 'number', width: 120 },
  { field: 'attributedRevenue', headerName: 'Revenue', type: 'number', width: 130 },
  { field: 'impressions', headerName: 'Impressions', type: 'number', width: 150 },
  { field: 'clicks', headerName: 'Clicks', type: 'number', width: 120 },
  { field: 'units', headerName: 'Units', type: 'number', width: 150 },
  { field: 'clickThroughRate', headerName: 'CTR', type: 'number', width: 120 },
  { field: 'conversionRate', headerName: 'Conversion Rate', type: 'number', width: 170 },
  { field: 'costPerConversion', headerName: 'CPC', type: 'number', width: 120 },
  { field: 'returnOnAdSpend', headerName: 'ROAS', type: 'number', width: 120 },
  { field: 'costPerClick', headerName: 'Cost per Click', type: 'number', width: 170 }
];
