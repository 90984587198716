import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import cronstrue from 'cronstrue';
import { renderAutomatedActionName } from './render-automated-action-name';
import { renderState } from '../../../components/grid-components/renderer/renderState';
import { DEFAULT_STATE_OPTIONS } from '../../../types/state-options';

export const AUTOMATED_ACTION_COLUMNS: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 100, type: 'string', headerAlign: 'left' },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    type: 'string',
    headerAlign: 'left',
    editable: true,
    renderCell: (params) => {
      return renderAutomatedActionName(params);
    }
  },
  {
    field: 'entityType',
    headerName: 'Entity',
    width: 100,
    type: 'string',
    headerAlign: 'left'
  },
  {
    field: 'executionScheduleCron',
    headerName: 'Schedule',
    width: 230,
    type: 'string',
    headerAlign: 'left',
    renderCell: (params) => {
      return cronstrue.toString(params?.value as string);
    }
  },
  {
    field: 'actionType',
    headerName: 'Action Type',
    width: 120,
    type: 'string',
    headerAlign: 'left'
  },
  {
    field: 'filterCriteria',
    headerName: 'Condition(s)',
    width: 200,
    type: 'string',
    headerAlign: 'left',
    renderCell: (params) => {
      const filterCriteria = JSON.parse(params?.value);
      return filterCriteria.map((criteria: any) => `${criteria.column} ${criteria.comparator} ${criteria.value}`).join(' and ');
    }
  },
  {
    field: 'actionParameters',
    headerName: 'Action Parameters',
    width: 350,
    type: 'string',
    headerAlign: 'left',
    renderCell: renderActionParameters
  },
  {
    field: 'requiredDaysOfData',
    headerName: 'Required Days Of Data',
    width: 170,
    type: 'number',
    headerAlign: 'left',
    editable: true
  },
  {
    field: 'lookBackWindow',
    headerName: 'Look Back Window',
    width: 170,
    type: 'number',
    headerAlign: 'left',
    editable: true
  },
  {
    field: 'lastSuccessfulExecution',
    headerName: 'Last Successful Execution',
    width: 200,
    type: 'string',
    headerAlign: 'left'
  }
];

function renderActionParameters(params: GridRenderCellParams) {
  const actionParameters = JSON.parse(params.value);

  if (actionParameters.state) {
    return renderState({ stateOptions: DEFAULT_STATE_OPTIONS })({ value: actionParameters.state } as GridRenderCellParams);
  }

  return `${actionParameters.updateOperator} by ${actionParameters.value} ${actionParameters.updateType}(s) | range : ${actionParameters.minValue} - ${actionParameters.maxValue}`;
}
