import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Tooltip
} from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import useDialog from '../../../shared/hooks/use-dialog';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import { Keyword } from '../../../shared/types/walmart-sams-club/keyword';
import {
  ReviewStepProps,
  Review as TReview,
  isSbEligibleForReview,
  orderByReviewIdDesc
} from '../../../shared/types/walmart-sams-club/review';
import { StyledConnector, StyledStepIcon } from '../../../shared/components/sponsored-video-media/media/review/review-stepper';
import CompletedReview from '../../../shared/components/sponsored-video-media/media/review/review-steps/completed-review';
import ProposalStep from '../../../shared/components/sponsored-video-media/media/review/review-steps/proposal-review';
import UnsubmittedReviewStep from '../../../shared/components/sponsored-video-media/media/review/review-steps/unsubmitted-review';
import { SamsClubAdItem } from '../../types/ad';
import { SBProfile } from '../../../shared/types/walmart-sams-club/sb-profile';
import { Campaign } from '../../../shared/types/walmart-sams-club/campaign';
import { Filter, Pageable, buildPageableFilters } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';

export default function Review() {
  const { getSbaReviews, getCampaign, getSbaProfiles, getAds, getKeywords, getAdGroups } = useAdsApi();
  const { toggle: toggleDialog, isShowing: dialogIsShowing, dynamicKey } = useDialog();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageable, setPageable } = usePageable();

  const [activeStep, setActiveStep] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  const campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;
  const [adGroupId, setAdGroupId] = useState(searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null);

  const [review, setReview] = useState<TReview>({
    adGroupId: 0,
    campaignId: 0,
    reviewComments: [{ commentType: '', comments: '' }],
    reviewId: 0,
    reviewStatus: ''
  });

  const [campaign, setCampaign] = useState<Campaign>();
  const [firstProfile, setFirstProfile] = useState<SBProfile>();
  const [ads, setAds] = useState<Array<SamsClubAdItem>>([]);
  const [keywords, setKeywords] = useState<Array<Keyword>>([]);

  const [reviewHistory, setReviewHistory] = useState<Array<TReview>>([]);
  const [historyDialogOpen, setHistoryDialogIsOpen] = useState(false);

  const fetchReview = async () => {
    const sbReviewResponse = await getSbaReviews(profileId, campaignId, adGroupId);

    if (!sbReviewResponse.success) {
      console.error('Failed to fetch review');
      return;
    }

    if (sbReviewResponse?.body?.length === 0) {
      setActiveStep(0);
      return;
    }

    const reviewArray = sbReviewResponse?.body;
    reviewArray.sort(orderByReviewIdDesc);

    if (reviewArray.length > 1) {
      setReviewHistory(reviewArray);
    }

    let review = reviewArray[0];
    setReview(review);

    if (review?.reviewStatus === 'Complete') {
      setActiveStep(2);
      return;
    }

    if (review?.reviewStatus === 'Cancelled') {
      setActiveStep(0);
      return;
    }

    setActiveStep(1);
  };

  const fetchCampaign = async () => {
    const campaignResponse = await getCampaign(profileId, campaignId);

    if (campaignResponse.success) {
      setCampaign(campaignResponse?.body);
    }
  };

  const fetchSbaProfile = async () => {
    const sbProfileResponse = await getSbaProfiles(profileId, campaignId, adGroupId);

    if (sbProfileResponse.success) {
      if (!!sbProfileResponse?.body[0]) {
        setFirstProfile(sbProfileResponse?.body[0]);
      }
    }
  };

  const fetchItems = async () => {
    if (!pageable) {
      return;
    }

    const adResponse = await getAds(pageable, null, null);

    if (adResponse.success) {
      setAds(adResponse?.body?.records);
    }
  };

  const fetchKeywords = async () => {
    if (!pageable) {
      return;
    }

    const keywordResponse = await getKeywords(pageable, null, null);

    if (keywordResponse.success) {
      setKeywords(keywordResponse?.body?.records);
    }
  };

  const fetchAdGroupId = async () => {
    const adGroupsResponse = await getAdGroups(profileId, campaignId, null, null);

    if (adGroupsResponse.success) {
      setAdGroupId(adGroupsResponse?.body[0].adGroupId);
      setSearchParams(
        {
          profileId: profileId?.toString() || '',
          campaignId: campaignId?.toString() || '',
          adGroupId: adGroupsResponse?.body[0].adGroupId
        },
        { replace: true }
      );
    }
  };

  const initialize = async () => {
    if (!pageable) {
      return;
    }

    if (!isLoading) {
      setIsLoading(true);
    }

    await Promise.all([fetchReview(), fetchCampaign(), fetchSbaProfile(), fetchItems(), fetchKeywords()]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!profileId || !campaignId) {
      return;
    }

    setIsLoading(true);

    if (!adGroupId) {
      fetchAdGroupId();
      return;
    }

    const pageableFilters: Filter[] = buildPageableFilters({ ...{ profileId, campaignId, adGroupId } });

    const pageable = {
      filters: pageableFilters,
      sorts: [],
      offset: 0,
      limit: 250
    } as Pageable;

    setPageable(pageable);
  }, [profileId, campaignId, adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initialize();
  }, [pageable]); // eslint-disable-line react-hooks/exhaustive-deps

  const isReady = isSbEligibleForReview({
    firstProfile: firstProfile,
    secondProfile: null,
    adItems: ads,
    keywords: keywords,
    retailerPlatform: Platform.SAMS_CLUB.value
  });

  const stepProps: ReviewStepProps = {
    review,
    campaign,
    firstProfile,
    ads,
    keywords,
    isReady,
    profileId,
    campaignId,
    adGroupId,
    toggleDialog,
    dialogIsShowing,
    dynamicKey,
    refreshParent: initialize
  };

  const reviewSteps = [
    {
      key: 0,
      state: 'unsubmitted',
      label: review?.reviewStatus === 'Cancelled' ? 'Cancelled' : activeStep > 0 ? 'Submitted' : 'Unsubmitted',
      content: <UnsubmittedReviewStep {...stepProps} />
    },
    {
      key: 1,
      state: 'pending',
      label: 'Pending',
      content: <ProposalStep {...stepProps} />
    },
    {
      key: 2,
      state: 'complete',
      label: 'Complete',
      content: <CompletedReview {...stepProps} />
    }
  ];

  const LoadingSkeleton = () => {
    return (
      <>
        <Skeleton />
        <Card sx={{ mb: 0, mt: 2, px: 2 }}>
          <CardHeader
            title={
              <Typography variant="h6" sx={{}}>
                <Skeleton height={100} sx={{}} />
              </Typography>
            }
            disableTypography={true}
            sx={{ py: 0 }}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ height: '155px' }}>
              <Skeleton height={50} width={'50%'} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ mt: 2 }}>
          <Skeleton height={60} />
        </Box>
      </>
    );
  };

  return (
    <Page title={`Review Status - ${campaign?.name}`}>
      <Box>
        <Container maxWidth="xl">
          <Container maxWidth="md" sx={{ mt: 2 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Grid item>
                {' '}
                <Typography variant="h4">{isLoading ? <Skeleton width={500} /> : campaign?.name}</Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    {reviewHistory.length > 1 && !isLoading ? (
                      <Tooltip title="Review History" placement="bottom">
                        <IconButton aria-label="history" onClick={() => setHistoryDialogIsOpen(true)}>
                          <HistoryIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h5">{isLoading ? <Skeleton width={100} /> : 'Review Status'}</Typography>
              </Grid>
              <Grid item>
                {activeStep === 1 && !isLoading ? (
                  <Tooltip title="Refresh page" placement="bottom">
                    <IconButton aria-label="history" onClick={() => initialize()}>
                      <RefreshIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>

            <Box sx={{ mt: 5 }}>
              <>
                {!isLoading ? (
                  <>
                    <Stepper activeStep={activeStep} connector={<StyledConnector />} sx={{ mb: 2 }}>
                      {reviewSteps.map((step, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                          <Step key={step.label} {...stepProps}>
                            <StepLabel StepIconComponent={StyledStepIcon}>
                              {index === activeStep ? (
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                  {step.label}
                                </Typography>
                              ) : (
                                <Typography variant="body2">{step.label}</Typography>
                              )}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {reviewSteps.find((step) => step.key === activeStep)?.content}
                  </>
                ) : (
                  <LoadingSkeleton />
                )}
              </>
            </Box>
          </Container>
        </Container>
        <Dialog open={historyDialogOpen} onClose={() => setHistoryDialogIsOpen(false)} maxWidth="lg">
          <DialogTitle>Review History</DialogTitle>
          <DialogContent>
            <List>
              {reviewHistory.map((review, index) => (
                <ListItem disablePadding key={review.reviewId}>
                  <ListItemText
                    primary={index + 1 + ': ' + review.reviewId + (index === 0 ? ' (Most recent)' : '')}
                    secondary={review?.reviewStatus}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </Box>
    </Page>
  );
}
