export const listOfDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export enum DAYS {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export interface Point {
  day: DAYS;
  hour: number;
}

export interface DaypartingSchedule {
  [key: string]: {
    [key: number]: AmazonDaypartingStates;
  };
}

export enum AmazonDaypartingStates {
  Enabled = 'Enabled',
  Disabled = 'Disabled'
}
