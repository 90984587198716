import { getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { Platform } from '../../../../shared/types/platforms';
import { CAMPAIGN_STATUS_OPTIONS } from '../../../types/campaign';
import { METRIC_COLUMNS } from './metric-columns';
import { renderNameWithActions } from '../renderer/renderNameWithActions';
import { format } from 'date-fns';
import { renderCampaignStatus } from '../../grid-components/renderer/renderStatus';
import { getMatchType } from '../../../types/campaign';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';

export const CITRUSAD_CAMPAIGN_COLUMNS: ExtendedGridColDef[] = [
  {
    field: 'id',
    headerName: 'Campaign ID',
    type: 'number',
    width: 120,
    minWidth: 100,
    headerAlign: 'left'
  },
  {
    field: 'name',
    headerName: 'Campaign Name',
    width: 200,
    minWidth: 150,
    editable: false,
    headerAlign: 'left',
    renderCell: renderNameWithActions(Platform.CITRUS_AD.value)
  },
  {
    field: 'namespaceId',
    headerName: 'Namespace ID',
    width: 100,
    minWidth: 65,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'walletId',
    headerName: 'Wallet ID',
    width: 100,
    minWidth: 65,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'teamId',
    headerName: 'Team ID',
    width: 100,
    minWidth: 65,
    editable: false,
    filterable: false,
    align: 'left'
  },
  {
    field: 'approvalState',
    headerName: 'Approval State',
    filterable: false,
    width: 120,
    minWidth: 85,
    align: 'center',
    valueFormatter: ({ value }) => getMatchType(value) || ''
  },
  {
    field: 'approvalRejectReason',
    headerName: 'Reject Reason',
    filterable: false,
    width: 120,
    minWidth: 100,
    align: 'center'
  },
  {
    field: 'campaignState',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: ['CAMPAIGN_STATE_ACTIVE', 'CAMPAIGN_STATE_PAUSE', 'CAMPAIGN_STATE_DRAFT', 'CAMPAIGN_STATE_ARCHIVE'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    renderCell: renderCampaignStatus(CAMPAIGN_STATUS_OPTIONS),
    width: 120,
    minWidth: 100
  },
  {
    field: 'startTime',
    headerName: 'Start Date',
    width: 120,
    minWidth: 100,
    type: 'string',
    filterable: false,
    editable: true,
    valueFormatter: ({ value }) => (value ? format(new Date(value), 'MM/dd/yyyy') : '')
  },
  {
    field: 'endTime',
    headerName: 'End Date',
    width: 120,
    minWidth: 100,
    type: 'string',
    filterable: false,
    editable: true,
    valueFormatter: ({ value }) => (value ? format(new Date(value), 'MM/dd/yyyy') : '')
  },
  {
    field: 'dailySpendLimit',
    headerName: 'Daily Spend Limit',
    width: 140,
    minWidth: 80,
    type: 'number',
    headerAlign: 'left',
    editable: true,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'totalSpendLimit',
    headerName: 'Total Spend Limit',
    width: 140,
    minWidth: 80,
    type: 'number',
    headerAlign: 'left',
    editable: true,
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'maxBid',
    headerName: 'Max Bid',
    width: 100,
    minWidth: 65,
    editable: true,
    headerAlign: 'left',
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  ...METRIC_COLUMNS
];
