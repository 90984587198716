import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { BiddablePlacementType, BiddablePlacements } from '../../../../types/campaign';
import { NumericFormat } from 'react-number-format';

interface PlacementBiddingProps {
  placement: BiddablePlacementType;
  percentage: number;
  setPercentage: (percentage: number) => void;
}

export const PlacementBidding = (props: PlacementBiddingProps) => {
  const { placement, percentage, setPercentage } = props;

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {' '}
          <Typography variant="body2">{BiddablePlacements[placement as keyof typeof BiddablePlacements].label}</Typography>
        </Grid>
        <Grid item>
          {' '}
          <NumericFormat
            label="Default Bid"
            customInput={TextField}
            id="outlined-adornment-weight"
            thousandSeparator=","
            value={percentage}
            size="small"
            decimalScale={2}
            allowNegative={false}
            sx={{ mt: 1 }}
            onBlur={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
              setPercentage(Number(event.target.value));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box>%</Box>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
