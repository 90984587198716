import CheckIcon from '@mui/icons-material/Check';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';
import { Platform } from '../../../../shared/types/platforms';

interface SuccessfulCampaignCreationProps {
  campaignBuilderResponse: ResponseObject;
  campaignName: string;
  keywords: any[];
}

export const SuccessfulCampaignCreation = (campaigns: SuccessfulCampaignCreationProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let profileId = searchParams.get('profileId') || null;

  return (
    <div>
      <Card variant="outlined">
        <CardContent>
          <Grid container direction="column" justifyContent="space-between" spacing={5}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      sx={{
                        backgroundColor: 'success.main',
                        color: 'success.contrastText',
                        height: 40,
                        width: 40
                      }}
                    >
                      <CheckIcon />
                    </Avatar>
                  </Box>
                </Grid>
                <Typography variant="h6" sx={{ ml: 2 }}>
                  {campaigns.campaignBuilderResponse?.body?.length} campaign(s) successfully created!
                </Typography>
                <Grid item>
                  <Button sx={{ mr: 1 }} onClick={() => navigate(`/${Platform.CITRUS_AD.value}/campaign?profileId=${profileId}`)}>
                    Go to Campaigns
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
