import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box, Link, Rating, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';

export const renderProductCell = (params: any) => {
  const product = params.row;

  const truncateMiddle = (text: any, startChars: any, endChars: any, ellipsis = '...') => {
    if (!text) {
      return product.asin;
    }

    if (text.length <= startChars + endChars) {
      return text;
    }

    const start = text.substring(0, startChars);
    const end = text.substring(text.length - endChars, text.length);
    return `${start}${ellipsis}${end}`;
  };

  const truncated = truncateMiddle(product.name, 10, 25);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 1
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {product.imageUrl ? (
          <img src={product.imageUrl ?? ''} alt={product.name} width="50" height="50" />
        ) : (
          <Box sx={{ width: '50px', height: '50px' }}>
            <ImageNotSupportedIcon color="disabled" />
          </Box>
        )}

        <Box sx={{ marginLeft: 1 }}>
          <Box>
            <Tooltip title={product.name}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '10px',
                  flexWrap: 'wrap',
                  overflowWrap: 'break-word',
                  display: 'flex'
                }}
              >
                <strong>
                  <Link
                    href={`https://www.amazon.com/dp/${product.asin}`}
                    target="_blank"
                    rel="noopener"
                    sx={{
                      fontWeight: 'normal',
                      overflow: 'hidden',
                      maxWidth: '250px',
                      textOverflow: 'ellipsis',
                      color: 'text.primary'
                    }}
                  >
                    {truncated ?? 'Item Info Not Available'}&nbsp;
                  </Link>
                </strong>
              </Typography>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'flex-start',
              fontSize: '11px'
            }}
          >
            <Tooltip title={`Rating: ${product.rating}`} placement="left">
              <Box sx={{}}>
                <Rating name="read-only" value={Number(product.rating)} readOnly precision={0.5} sx={{ fontSize: '11px' }} />
              </Box>
            </Tooltip>
            <Box sx={{ display: 'flex', color: 'text.secondary' }}>
              <Typography sx={{ fontSize: '10px' }}>
                {' '}
                | {!!product.price ? `$${product.price}` : 'Price not available'}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="body1" sx={{ fontSize: '10px', color: 'text.primary' }}>
              ASIN:&nbsp;{product.asin}
            </Typography>
            <Typography sx={{ color: 'text.secondary', fontSize: '10px', mx: 0.4 }}>|</Typography>
            <Typography sx={{ fontSize: '10px', color: product.availability === 'In Stock' ? 'success.main' : 'error.main' }}>
              {product.availability}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
