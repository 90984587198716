import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

interface CreateBudgetGroupDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  handleCreateBudgetGroup: (budgetGroupName: string) => Promise<void>;
  isLoading: boolean;
}

export const CreateBudgetGroupDialog = (props: CreateBudgetGroupDialogProps) => {
  const { toggle, isShowing, dynamicKey, handleCreateBudgetGroup, isLoading } = props;
  const [budgetGroupName, setBudgetGroupName] = useState<string>('');

  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);

  const handleSaveButtonClick = async () => {
    setBackDropOpen(true);
    await handleCreateBudgetGroup(budgetGroupName);
    setBackDropOpen(false);
    toggle();
  };

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="sm" sx={{ transition: 'height 1s' }}>
      <DialogTitle>Create Budget Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>
        <Typography variant="body1" gutterBottom>
          Organize your campaigns by brand, category, or product line.
        </Typography>
        <Box display={'flex'} justifyContent={'flex-start'} mb={2} alignItems={'center'} mt={4}>
          <Typography variant="body1" gutterBottom fontWeight={'bold'}>
            Budget Group Name:
          </Typography>
          <TextField
            size="small"
            name="name"
            value={budgetGroupName}
            onChange={(event) => setBudgetGroupName(event.target.value)}
            sx={{ ml: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSaveButtonClick} variant="contained" color="primary" disabled={isLoading}>
          Create
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
