import { Box, Button, Divider, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import BrandSearchResultsList from './brand-search-results-list';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AddedNegativeTargetingClauseTable from '../../campaign-builder/added-negative-targeting-clauses';
import ProductSearchResultsList from './product-search-results-list';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ProductSearchContainer } from './product-search-container';

interface AddNegativeTargetingClausesProps {
  negativeTargetingClauses: any;
  setNegativeTargetingClauses: (negativeTargetingClauses: any) => void;
  isCampaignNegativeTarget: boolean;
}

export default function AmazonAddNegativeTargetingClauses(props: AddNegativeTargetingClausesProps) {
  const { negativeTargetingClauses, setNegativeTargetingClauses, isCampaignNegativeTarget } = props;
  const [brandSearchTerm, setBrandSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [negativeBrandSuggestions, setNegativeBrandSuggestions] = useState<any[]>([]);
  const { searchBrands, getNegativeBrandSuggestions, searchCatalogItemsKeywords } = useAmazonApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedOrSearchBrandTabIndex, setSuggestedOrSearchBrandTabIndex] = useState(0);
  const [productOrBrandTabIndex, setProductOrBrandTabIndex] = useState(0);
  let profileId = searchParams.get('profileId');
  let campaignId = searchParams.get('campaignId');
  let adGroupId = searchParams.get('adGroupId');
  const { enqueueSnackbar } = useSnackbar();

  const handleExcludeBrand = (brand: any) => {
    if (isCampaignNegativeTarget) {
      const campaignNegativeTargetingClause = {
        negativeTargetType: 'TargetingClause',
        retailer: 'Amazon',
        campaignId: campaignId,
        state: 'Enabled',
        expression: [
          {
            type: 'AsinBrandSameAs',
            value: brand.id
          }
        ],
        resolvedExpression: [
          {
            type: 'AsinBrandSameAs',
            value: brand.name
          }
        ]
      };

      setNegativeTargetingClauses([...negativeTargetingClauses, campaignNegativeTargetingClause]);
    } else {
      const brandNegativeTargetingClause = {
        negativeTargetType: 'TargetingClause',
        retailer: 'Amazon',
        adGroupId: adGroupId,
        state: 'Enabled',
        expression: [
          {
            type: 'AsinBrandSameAs',
            value: brand.id
          }
        ],
        resolvedExpression: [
          {
            type: 'AsinBrandSameAs',
            value: brand.name
          }
        ]
      };

      setNegativeTargetingClauses([...negativeTargetingClauses, brandNegativeTargetingClause]);
    }
  };

  const fetchSearchResults = async (searchTerm: string) => {
    if (!profileId) {
      return;
    }

    setIsLoading(true);

    if (!searchTerm) {
      setSearchResults([]);
      setIsLoading(false);
      return;
    }

    const response = await searchBrands(profileId, searchTerm);

    if (response?.success && response?.body) {
      setSearchResults(response.body);
    } else {
      enqueueSnackbar('Failed to fetch search results', { variant: 'error' });
    }

    setIsLoading(false);
  };

  const fetchNegativeBrandSuggestions = async () => {
    if (!profileId) {
      return;
    }

    setIsLoading(true);

    const response = await getNegativeBrandSuggestions(profileId);

    if (response?.success && response?.body) {
      setNegativeBrandSuggestions(response.body);
    } else {
      enqueueSnackbar('Failed to fetch negative brand suggestions', { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchNegativeBrandSuggestions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderBrandsOptions = () => {
    return (
      <>
        <Tabs
          value={suggestedOrSearchBrandTabIndex}
          onChange={(event, newValue) => setSuggestedOrSearchBrandTabIndex(newValue)}
          sx={{ mb: 2 }}
        >
          <Tab sx={{ fontSize: '12px' }} label="Your Brands" />
          <Tab sx={{ fontSize: '12px' }} label="Search Brands" />
        </Tabs>
        {suggestedOrSearchBrandTabIndex === 1 ? (
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            value={brandSearchTerm}
            onChange={(event) => setBrandSearchTerm(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                fetchSearchResults(brandSearchTerm);
              }
            }}
            placeholder="Search by brand name"
            size="small"
            fullWidth
          />
        ) : null}
        <BrandSearchResultsList
          brands={suggestedOrSearchBrandTabIndex === 0 ? negativeBrandSuggestions : searchResults}
          addedNegativeTargetingClauses={negativeTargetingClauses}
          handleExcludeBrand={handleExcludeBrand}
          isLoading={isLoading}
          height={suggestedOrSearchBrandTabIndex === 1 ? 340 : 380}
        />
        <Box>
          <Typography variant="caption" color="text.secondary" component={'div'}>
            <strong>Negative targets added: </strong>
            {negativeTargetingClauses?.length}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Box display={'flex'}>
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button sx={{ mb: 2 }} variant="outlined" onClick={() => setNegativeTargetingClauses([])}>
            Remove All
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6} mr={4}>
            <Tabs
              value={productOrBrandTabIndex}
              onChange={(event, newValue) => setProductOrBrandTabIndex(newValue)}
              sx={{ mb: 2 }}
            >
              <Tab label="Exclude Brands" />
              <Tab label="Exclude Products" />
            </Tabs>
            <Divider />
            {productOrBrandTabIndex === 0 ? renderBrandsOptions() : null}
            {productOrBrandTabIndex === 1 ? (
              <ProductSearchContainer
                negativeTargetingClauses={negativeTargetingClauses}
                setNegativeTargetingClauses={setNegativeTargetingClauses}
                isCampaignNegativeTarget={isCampaignNegativeTarget}
              />
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <AddedNegativeTargetingClauseTable
              key={'add-negative-targeting-clauses-table'}
              negativeTargetingClauses={negativeTargetingClauses || ''}
              setNegativeTargetingClauses={setNegativeTargetingClauses}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
