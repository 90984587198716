import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from './shared/theme';
import { SettingsConsumer, SettingsProvider } from './shared/contexts/settings-context';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './shared/contexts/msal-context';
import Routing from './shared/routing/routing';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_LICENSE_KEY } from './config';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './shared/components/close-snackbar-button';
import './global-styles.css';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeProvider
              theme={createTheme({
                direction: settings.direction,
                responsiveFontSizes: settings.responsiveFontSizes,
                mode: settings.theme
              })}
            >
              <SnackbarProvider maxSnack={3} preventDuplicate action={(snackKey) => <SnackbarCloseButton snackKey={snackKey} />}>
                <BrowserRouter>
                  <AuthProvider>
                    <CssBaseline />
                    <Routing />
                  </AuthProvider>
                </BrowserRouter>
              </SnackbarProvider>
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </LocalizationProvider>
  );
}

export default App;
