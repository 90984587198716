import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import { convertDateToMonthDayYear } from '../utilities/date-utilities';
import { blue, lightGreen } from '@mui/material/colors';

const formatPlacementName = (placement: string): string => {
  const placementMap: Record<string, string> = {
    TopOfSearch: 'Top Of Search',
    ProductPages: 'Product Pages',
    RestOfSearch: 'Rest Of Search',
    OffSite: 'Off Site'
  };

  return placementMap[placement] || placement;
};

const useBarChartOptions = (prefix: string, postfix: string, title: string): ApexOptions => {
  const { palette } = useTheme();

  return {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: [palette.text.secondary]
      },
      formatter: function (
        val: number,
        opt: { w: { globals: { labels: { [x: string]: any } } }; dataPointIndex: string | number }
      ) {
        return formatPlacementName(opt.w.globals.labels[opt.dataPointIndex]) + ':  ' + prefix + val.toFixed(2) + postfix;
      },
      offsetX: 0,
      dropShadow: {
        enabled: false
      }
    },
    colors: [palette.primary.main, palette.secondary.main, palette.warning.main, palette.error.main],
    grid: {
      borderColor: palette.divider,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    stroke: {
      width: 0
    },
    xaxis: {
      type: 'category'
    },
    yaxis: {
      floating: false,
      title: {
        text: 'Placement',
        style: {
          color: palette.text.secondary,
          fontSize: '12px',
          fontWeight: 500
        },
        offsetX: -10
      },
      labels: {
        show: false
      }
    },
    tooltip: {
      theme: palette.mode,
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return '';
          }
        },
        formatter: function (val: number) {
          return prefix + val.toFixed(2) + postfix;
        }
      }
    },
    title: {
      text: title,
      align: 'center',
      floating: true,
      style: {
        color: palette.text.secondary
      }
    }
  };
};

const useAreaChartOptions = (timeSeries: any, series: any[]): ApexOptions => {
  const { palette } = useTheme();

  return {
    chart: {
      background: palette.background.paper,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      formatter(legendName, opts) {
        const { seriesIndex } = opts;
        const series = timeSeries.series[seriesIndex];
        return `${formatPlacementName(series.name)}`;
      },
      labels: {
        colors: palette.text.secondary
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    colors: [palette.primary.main, lightGreen[300], blue[600], palette.warning.main, palette.error.main],
    dataLabels: {
      enabled: timeSeries?.categories?.length <= 30,
      formatter: (value: any) => {
        if (Number.isInteger(value)) {
          return value.toFixed(0) + '%';
        } else {
          return value.toFixed(2) + '%';
        }
      },
      offsetY: -5,
      background: {
        enabled: false
      }
    },
    fill: {
      opacity: 1,
      type: 'gradient',
      gradient: {
        shade: palette.mode === 'dark' ? 'dark' : 'light',
        type: 'vertical',
        shadeIntensity: 0.6,
        opacityFrom: 0.3,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    grid: {
      borderColor: palette.divider,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      radius: 4,
      shape: 'circle',
      size: 4,
      strokeWidth: 0,
      hover: {
        sizeOffset: 2
      }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 3
    },
    theme: {
      mode: palette.mode
    },
    xaxis: {
      categories: timeSeries.categories,
      type: 'datetime',
      labels: {
        style: {
          colors: palette.text.secondary
        },
        formatter: (value) => {
          const date = new Date(value);
          return convertDateToMonthDayYear(date);
        }
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: palette.divider,
          width: 2
        }
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: palette.divider,
        height: 6,
        offsetX: 0,
        offsetY: 0
      }
    },
    noData: {
      text: 'No Data Available'
    },
    tooltip: {
      x: {
        formatter: (value) => {
          const date = new Date(value);
          return date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          });
        }
      },
      y: {
        formatter: (value: any) => {
          return value.toFixed(2) + '%';
        }
      },
      marker: {
        show: true
      },
      theme: palette.mode
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value: any) => {
          return value.toFixed(0) + '%';
        }
      },
      opposite: true,
      title: {
        text: 'Placement of row',
        style: {
          color: palette.text.secondary
        }
      },
      axisTicks: {
        show: true
      },
      min: 0,
      max: 100
    },
    title: {
      text: 'Percent of Spend by Placement Over Time (%)',
      align: 'center',
      floating: false,
      style: {
        color: palette.text.secondary
      }
    }
  };
};

export { useBarChartOptions, useAreaChartOptions };
