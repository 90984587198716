import NorthWestIcon from '@mui/icons-material/NorthWest';
import { Box, Card, Container, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { DataGridPremium, GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import GridDateRangePicker from '../../../shared/components/grid-components/date-picker/grid-date-picker';
import Page from '../../../shared/components/page';
import { ProfileAutocomplete } from '../../../shared/components/page-components/profile-auto-complete';
import { usePerformanceGridSettings } from '../../../shared/hooks/use-performance-grid-settings';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { PRODUCT_COLUMNS } from '../../components/grid-components/column-configurations/product-columns';
import { PRODUCT_DIGITAL_SHELF_COLUMNS } from '../../components/grid-components/column-configurations/product-digital-shelf-columns';
import { PRODUCTS_OVERVIEW_COLUMNS } from '../../components/grid-components/column-configurations/product-overview-columns';
import { useAmazonApi } from '../../hooks/use-amazon-api';
import PerformanceGrid from './products-performance-grid/products-performance-grid';
import { PRODUCT_COLUMNS_ALL } from '../../components/grid-components/column-configurations/product-columns-all';

const Products: FC = () => {
  const { getProfiles, getProducts } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [profiles, setProfiles] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');

  const {
    performanceGridSettingsInitialized,
    initialSettings,
    saveGridConfig,
    pageable,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange,
    dateSettings,
    dateSettingsLoading,
    saveDateConfig
  } = usePerformanceGridSettings({
    settingsKey: GridConfigSettings.AMAZON_PRODUCTS,
    breadCrumbParams: {} //profileId is passed directly as a URL path parameter, not as a query string parameter
  });

  const processRowUpdate = useCallback((newRow: any, oldRow: any) => {
    const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);

    return newRow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      const pageable = {
        filters: [],
        sorts: [],
        limit: 0,
        offset: 0
      };

      const response = await getProfiles(pageable);

      if (response.success) {
        setProfiles(response.body.records);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    fetchProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProducts = async () => {
    if (!pageable || !dateSettings || !profileId) {
      return;
    }

    setIsLoading(true);

    //profileId is passed directly as a URL path parameter, not as a query string parameter
    const response = await getProducts(pageable, profileId || '', dateSettings.beginDate, dateSettings.endDate);

    if (response.success) {
      setProducts(response.body.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!performanceGridSettingsInitialized || !profileId) {
      return;
    }

    fetchProducts();
  }, [profileId, pageable, dateSettings, performanceGridSettingsInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProfileChange = (event: any, value: any) => {
    if (!value?.profileId) {
      searchParams.delete('profileId');
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...searchParams,
        profileId: `${value.profileId}`
      });
    }
  };

  const renderProductsGrid = () => {
    if (!profileId) {
      return (
        <Card
          sx={{
            my: 5,
            height: '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
            <NorthWestIcon /> To view Products, please select a profile.
          </Typography>
        </Card>
      );
    }

    if (!performanceGridSettingsInitialized) {
      return (
        <DataGridPremium
          rows={[]}
          columns={PRODUCT_COLUMNS_ALL}
          loading={true}
          processRowUpdate={processRowUpdate}
          initialState={{}}
          key={uuidv4()}
          disableRowGrouping
        />
      );
    }

    var columnVisibilityModel: GridColumnVisibilityModel = {};

    if (tabIndex === 0) {
      PRODUCTS_OVERVIEW_COLUMNS.forEach((column) => {
        columnVisibilityModel[column.field] = true;
      });
    }

    if (tabIndex === 1) {
      PRODUCT_COLUMNS.forEach((column) => {
        columnVisibilityModel[column.field] = true;
      });
    }

    if (tabIndex === 2) {
      PRODUCT_DIGITAL_SHELF_COLUMNS.forEach((column) => {
        columnVisibilityModel[column.field] = true;
      });
    }

    // Ensures only the columns relevant to the current tab are visible
    PRODUCT_COLUMNS_ALL.forEach((column) => {
      if (!(column.field in columnVisibilityModel)) {
        columnVisibilityModel[column.field] = false;
      }
    });

    return (
      <PerformanceGrid
        processRowUpdate={processRowUpdate}
        loading={isLoading}
        rows={products}
        columns={PRODUCT_COLUMNS_ALL}
        initialState={initialSettings?.config}
        saveGridConfig={saveGridConfig}
        dateConfig={{
          dateSettings: dateSettings,
          dateSettingsLoading: dateSettingsLoading,
          saveDateConfig: saveDateConfig
        }}
        getRowId={(row: any) => row.asin}
        handleFilterModelChange={handleFilterModelChange}
        handleSortModelChange={handleSortModelChange}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        rowCount={rowCount}
        disableGridDateRangePicker={true}
        columnVisibilityModel={columnVisibilityModel}
        otherDataGridProps={{
          rowHeight: 60
        }}
      />
    );
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const [isMounted, setIsMounted] = useState(false); // Track mount state

  useEffect(() => {
    setIsMounted(true); // Component has mounted
    return () => {
      setIsMounted(false); // Component is unmounting
    };
  }, []);

  const updateGridDateRange = (dateRange: DateRange<Date>, newDatePickerIncrementInDays: number) => {
    if (isMounted && dateRange[0] && dateRange[1]) {
      saveDateConfig(dateRange, newDatePickerIncrementInDays);
    }
  };

  return (
    <Page title="Products">
      <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h5">Products</Typography>
            <Box sx={{ mr: 'auto' }}></Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              {profiles.length > 0 && (
                <Box display="flex" alignItems={'center'}>
                  <ProfileAutocomplete profiles={profiles} handleProfileChange={handleProfileChange} profileId={profileId} />
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <Box marginLeft="10px" marginRight="10px" sx={{ display: 'flex', float: 'right' }}>
                <GridDateRangePicker
                  onChange={(newDateRange, newDatePickerIncrementInDays) =>
                    updateGridDateRange(newDateRange, newDatePickerIncrementInDays || 7)
                  }
                  initialDateRange={[new Date(dateSettings.beginDate), new Date(dateSettings.endDate)]}
                  initialIncrementInDays={dateSettings.datePickerIncrementInDays}
                />
              </Box>
            </Grid>
          </Grid>

          <Card>
            <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
              <Tab label="Overview" />
              <Tab label="Advertising" />
              <Tab label="Digital Shelf" />
            </Tabs>
          </Card>

          <Box height={'800px'}>{renderProductsGrid()}</Box>
        </Stack>
      </Container>
    </Page>
  );
};

export default Products;
