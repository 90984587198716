import { useCallback, useMemo } from 'react';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import DeleteIcon from '@mui/icons-material/Delete';

interface AddedNegativeKeywordsTableProps {
  negativeKeywords: any;
  setNegativeKeywords: (negativeKeywords: any) => void;
}

const AddedNegativeKeywordsTable = ({ negativeKeywords, setNegativeKeywords }: AddedNegativeKeywordsTableProps) => {
  const deleteKeyword = useCallback(
    (id: GridRowId) => () => {
      const updatedKeywordList = negativeKeywords.filter((keyword: any) => {
        return keyword.id !== id;
      });
      setNegativeKeywords(updatedKeywordList);
    },
    [setNegativeKeywords, negativeKeywords]
  );

  const columns = useMemo<Array<GridColDef>>(
    () => [
      {
        field: 'keywordText',
        headerName: 'Negative Keyword Text',
        width: 400,
        editable: false,
        disableReorder: true,
        sortable: false,
        resizable: false,
        filterable: false
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 80,
        getActions: (params: { id: GridRowId }) => [
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteKeyword(params.id)} />
        ]
      }
    ],
    [deleteKeyword]
  );

  return (
    <div style={{ height: 375, minWidth: '500px' }}>
      <DataGridPremium
        rows={negativeKeywords}
        columns={columns}
        disableColumnMenu
        disableColumnReorder
        disableColumnResize
        disableDensitySelector
        disableRowSelectionOnClick
        getRowId={(row) => `${row.keywordText}-${row.matchType}`}
        disableRowGrouping
      />
    </div>
  );
};

export default AddedNegativeKeywordsTable;
