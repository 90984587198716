import * as Yup from 'yup';
import { keywordMatchTypes } from '../../../shared/types/walmart-sams-club/campaign';

export const ADS_BUILDER_FORM_VALIDATION_SCHEMA = Yup.object({
  items: Yup.array()
    .min(1, 'At least one item is required')
    .of(Yup.object({ itemId: Yup.string().required(), itemName: Yup.string().required() }))
    .required(),
  keywords: Yup.array().of(
    Yup.object({
      keywordText: Yup.string().required(),
      matchType: Yup.string()
        .oneOf(keywordMatchTypes.map((type) => type.value))
        .required(),
      bid: Yup.number().required()
    })
  )
});

export const INITIAL_FORM_VALUES = {
  items: [],
  keywords: [],
  submit: null
};
