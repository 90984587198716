import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import useAmazonApi from '../../hooks/use-amazon-api';

interface RemoveCampaignsFromBidGroupDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  refreshCampaigns: () => void;
}

const RemoveCampaignsFromBidGroupDialog = (props: RemoveCampaignsFromBidGroupDialogProps) => {
  const { toggle, isShowing, dynamicKey, refreshCampaigns } = props;
  const apiRef = useGridApiContext();
  const { removeCampaignsFromBidGroup } = useAmazonApi();
  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);

  const handleRemoveCampaigns = async () => {
    setBackDropOpen(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values()).map((campaign) => campaign.campaignId);
    const response = await removeCampaignsFromBidGroup(selectedCampaignIds);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    } else {
      // On the bid groups overview we need to refresh because the rows should be removed from the table after removing them from the bid group
      // On the campaigns grid we can just update the rows to update the bid group name since the row should remain in the table
      if (location.pathname.includes('bid-optimization-groups')) {
        refreshCampaigns();
      } else {
        apiRef.current.updateRows(
          selectedCampaignIds.map((campaignId) => ({
            campaignId: campaignId,
            campaignBidOptimizationGroupId: null,
            campaignBidOptimizationGroupName: null
          }))
        );
      }

      enqueueSnackbar('Campaigns successfully removed from bid group.', { variant: 'success' });
    }

    apiRef.current.setRowSelectionModel([]);
    setBackDropOpen(false);
    toggle();
  };

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="sm" sx={{ transition: 'height 1s' }}>
      <DialogTitle>Create Bid Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to remove the selected campaigns from their current bid group?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleRemoveCampaigns} variant="contained" color="primary">
          Yes I'm sure
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default RemoveCampaignsFromBidGroupDialog;
