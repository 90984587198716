import { Link, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Platform } from '../../../shared/types/platforms';

export const renderCampaignBudgetGroupName = (params: GridRenderCellParams) => {
  return <CampaignBudgetGroupNameCell {...params} />;
};

export const CampaignBudgetGroupNameCell = (params: GridRenderCellParams) => {
  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId') ? searchParams.get('profileId') : null;
  const isDashboard = window.location.href.includes('dashboard');

  if (!params.row.campaignBudgetGroupId) {
    return params.value;
  }

  return (
    <Link
      to={`/${Platform.AMAZON.value}/budgetGroups?campaignBudgetGroupId=${params.row.campaignBudgetGroupId}&profileId=${profileId}`}
      sx={{ textDecoration: 'none', color: 'text.primary' }}
      component={RouterLink}
    >
      {isDashboard ? <Typography variant="caption">{params.value}</Typography> : <span>{params.value}</span>}
    </Link>
  );
};
