import * as React from 'react';
import { Tabs, Tab, Container, Typography, Box } from '@mui/material';
import { BudgetManager } from './budget-manager';
import Pacing from './pacing';
import Page from '../../../shared/components/page';
import { useSearchParams } from 'react-router-dom';

const Budgets: React.FC = () => {
  // Default the tab index based off the query param if it exists
  const [searchParams] = useSearchParams();

  const currentTab = searchParams.get('currentTab') || 0;

  const [tabIndex, setTabIndex] = React.useState(Number(currentTab));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Page>
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4">Budgets</Typography>
          <Tabs value={tabIndex} onChange={handleChange}>
            <Tab label="Budget Manager" />
            <Tab label="Pacing" />
          </Tabs>
        </Box>

        {tabIndex === 0 ? <BudgetManager /> : <Pacing />}
      </Container>
    </Page>
  );
};

export default Budgets;
