import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Card, CardContent, Typography, Collapse, Grid, Box } from '@mui/material';
import { CampaignBuilderStepLabelButton } from './step-label-button';
import AddKeywords from '../../../../shared/components/add-keywords';
import RadioCard, { UserSelectedCampaignType } from './radio-card';
import MultiItemMultiTargetIcon from '../../../../shared/icons/campaign-type-icons/multi-item-mutli-target';
import SingleItemMultiTargetIcon from '../../../../shared/icons/campaign-type-icons/single-item-multi-target';
import SingleItemSingleTargetIcon from '../../../../shared/icons/campaign-type-icons/single-item-single-target';

interface TargetingSettingsStepProps {
  formik: any;
  stepNumber: number;
}

export const TargetingSettingsStep: FC<TargetingSettingsStepProps> = (props) => {
  const { formik, stepNumber, ...other } = props;

  const [showStep, setShowStep] = useState<boolean>(true);

  const [userSelectedCampaignType, setUserSelectedCampaignType] = useState<UserSelectedCampaignType>(
    UserSelectedCampaignType.SINGLE_ITEM_SINGLE_TARGET
  );

  useEffect(() => {
    formik.setFieldValue('campaignStructure', userSelectedCampaignType);
  }, [userSelectedCampaignType]); // eslint-disable-line react-hooks/exhaustive-deps

  const userSelectedCampaignTypeCardData = [
    {
      Icon: SingleItemSingleTargetIcon,
      title: 'Single Item Single Target',
      text: 'Every (item + keyword) pair gets its own campaign. If you add 3 items and 3 keywords, you get 9 campaigns. Campaign names will be auto generated in the format - Placement Type | Product ID | Search Term',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.SINGLE_ITEM_SINGLE_TARGET
    },
    {
      Icon: SingleItemMultiTargetIcon,
      title: 'Single Item Multiple Targets',
      text: 'Every item gets its own campaign, multiple targets. If you add 3 items and 3 keywords, you get 3 campaigns. Campaign names will be auto generated in the format - Placement Type | Product ID',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.SINGLE_ITEM_MULTIPLE_TARGETS
    },
    {
      Icon: MultiItemMultiTargetIcon,
      title: 'Multiple Items Multiple Targets',
      text: 'Every item is in one campaign, with multiple keywords. If you add 3 items and 3 keywords, you get 1 campaign. Campaign name will be modified in the format - Placement Type | Name',
      selectedValue: userSelectedCampaignType,
      onValueChange: setUserSelectedCampaignType,
      value: UserSelectedCampaignType.MULTIPLE_ITEMS_MULTIPLE_TARGETS
    }
  ];

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  return (
    <Box {...other} sx={{ mb: !showStep ? 7 : 1 }}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Targeting Settings"
      />
      <Collapse in={showStep}>
        <Collapse in={formik.values.targetingType === 'manual'}>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Pick a Campaign Structure
              </Typography>
              <Grid container direction={'row'} justifyContent="space-between" spacing={2}>
                {userSelectedCampaignTypeCardData.map((data, index) => (
                  <Grid item xs={4} key={index + 'grid'}>
                    <RadioCard key={index} {...data} />
                  </Grid>
                ))}
              </Grid>
              <Typography variant="h6" sx={{ my: 2 }}>
                Add Keywords (Optional)
              </Typography>
              <Typography variant="caption" gutterBottom>
                Enter a bid, select match types, and enter keywords. Once finished click create ads to create your new ads and
                keywords.
              </Typography>
              <AddKeywords
                keywords={formik.values.keywords}
                setKeywords={(keywords) => formik.setFieldValue('keywords', keywords)}
                onBidChange={(bidValue) => formik.setFieldValue('maxBid', bidValue)}
                onlyExact={true}
                isBidEditable={true}
              />
            </CardContent>
          </Card>
        </Collapse>
      </Collapse>
    </Box>
  );
};
