import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import { DaypartingMode, DaypartingSchedule, DaypartingScheduleState, Point } from './day-parting-types';
import { Close } from '@mui/icons-material';

interface HourCellProps {
  dayNumber: number;
  hour: string;
  bidValue: number;
  setFirstClicked: React.Dispatch<React.SetStateAction<Point>>;
  setFirstClickedValue: React.Dispatch<React.SetStateAction<DaypartingScheduleState>>;
  setDaySchedulesBeforeDrag: React.Dispatch<React.SetStateAction<DaypartingSchedule>>;
  daySchedules: DaypartingSchedule;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateSchedule: () => void;
  isCapOutHour: (day: number, hour: number) => boolean;
  setLastClicked: React.Dispatch<React.SetStateAction<Point>>;
  shouldShowOutOfBudgetHour: boolean;
  currentMode: string;
}

const HourCell: React.FC<HourCellProps> = ({
  dayNumber,
  hour,
  bidValue,
  setFirstClicked,
  setFirstClickedValue,
  setDaySchedulesBeforeDrag,
  daySchedules,
  isDragging,
  setIsDragging,
  handleUpdateSchedule,
  shouldShowOutOfBudgetHour,
  isCapOutHour,
  setLastClicked,
  currentMode: currentMode
}) => {
  const theme = useTheme();

  const getSquareColor = (day: number, hour: number): string => {
    const scheduleEntry = daySchedules[day][hour];

    if (scheduleEntry.State === DaypartingScheduleState.Disabled) {
      return theme.palette.text.disabled;
    }

    if (scheduleEntry.State === DaypartingScheduleState.Enabled) {
      return theme.palette.primary.main;
    }

    if (scheduleEntry.State === DaypartingScheduleState.BidDecrease) {
      const bidValue = scheduleEntry.BidAdjustmentPercentage;
      if (bidValue > 75) {
        return theme.palette.warning.dark;
      } else if (bidValue < 25) {
        return theme.palette.warning.light;
      } else {
        return theme.palette.warning.main;
      }
    }

    return theme.palette.text.disabled;
  };

  const getSquareIcon = (day: number, hour: number) => {
    const scheduleEntry = daySchedules[day][hour];

    switch (scheduleEntry.State) {
      case DaypartingScheduleState.Enabled:
        return null;
      case DaypartingScheduleState.BidDecrease:
        return (
          <Typography
            component="div"
            sx={{
              fontSize: '0.6rem',
              userSelect: 'none',
              color: scheduleEntry.BidAdjustmentPercentage < 75 ? 'black' : 'white'
            }}
          >
            {`-${scheduleEntry.BidAdjustmentPercentage}%`}
          </Typography>
        );
      case DaypartingScheduleState.Disabled:
      default:
        return <Close sx={{ opacity: '20%', width: '50%' }} />;
    }
  };

  const isOutOfBudgetHourVisible = useMemo(
    () => isCapOutHour(dayNumber, Number(hour)) && shouldShowOutOfBudgetHour,
    [shouldShowOutOfBudgetHour] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box
      onMouseDown={() => {
        if (bidValue === 0 && currentMode === DaypartingMode.BidDecrease) {
          enqueueSnackbar('Please enter a bid decrease value', { variant: 'warning' });
          return;
        }

        const currentState = daySchedules[dayNumber][Number(hour)].State;
        let nextState = DaypartingScheduleState.Disabled;

        if (currentMode === DaypartingMode.EnableDisable) {
          nextState =
            currentState === DaypartingScheduleState.Enabled ? DaypartingScheduleState.Disabled : DaypartingScheduleState.Enabled;
        } else if (currentMode === DaypartingMode.BidDecrease) {
          nextState = DaypartingScheduleState.BidDecrease;
        }

        setFirstClicked({ day: dayNumber, hour: Number(hour) });
        setFirstClickedValue(nextState);
        setDaySchedulesBeforeDrag(cloneDeep(daySchedules));
        setIsDragging(true);
      }}
      onMouseOver={() => {
        setLastClicked({ day: dayNumber, hour: Number(hour) });
      }}
      onMouseUp={() => {
        setIsDragging(false);
        handleUpdateSchedule();
      }}
      sx={{
        margin: '1px',
        ...(!isDragging && {
          ':hover': {
            border: '1px solid',
            borderRadius: '5px',
            borderColor: theme.palette.mode === 'dark' ? 'black' : 'gray',
            outline: '1px solid',
            outlineColor: theme.palette.mode === 'dark' ? 'black' : 'gray'
          }
        }),
        bgcolor: getSquareColor(dayNumber, Number(hour)),
        border: isOutOfBudgetHourVisible ? '2px solid' : '0px',
        outline: isOutOfBudgetHourVisible ? '1px solid' : '0px',
        outlineColor: isOutOfBudgetHourVisible ? theme.palette.error.main : 'transparent',
        borderColor: isOutOfBudgetHourVisible ? 'error.main' : 'transparent',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {getSquareIcon(dayNumber, Number(hour))}
    </Box>
  );
};

export default HourCell;
