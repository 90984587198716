import { Campaign } from '../../shared/types/walmart-sams-club/campaign';
import { ResponseObject } from '../../shared/utilities/fetch-utilities';

export interface CampaignBuilderSbProfile {
  searchAmpName: string;
  headlineText: string;
  logoDataUri: string;
  clickUrl: string;
}

export interface CampaignBuilderItem {
  itemId: number;
  itemName: string;
}

export interface CampaignBuilderKeyword {
  keywordText: string;
  matchType: string;
  bid: number;
}

export interface CampaignBuilderNegativeKeyword {
  id: string;
  keywordText: string;
  matchType: string;
}

export interface CampaignBuilderRequest {
  campaign: Campaign;
  sbaProfile?: CampaignBuilderSbProfile;
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
  negativeKeywords: string[];
}

export const createCampaignBuilderRequest = (values: any): CampaignBuilderRequest => {
  return {
    campaign: {
      name: values.name,
      campaignType: values.campaignType,
      targetingType: values.targetingType,
      startDate: values.startDate,
      dailyBudget: values.dailyBudget,
      ...(values.endDate ? { endDate: values.endDate } : {})
    },
    ...(values.campaignType === 'sba' &&
      values?.searchAmpName &&
      values?.headlineText &&
      values?.clickUrl &&
      values?.logoDataUri && {
        sbaProfile: {
          searchAmpName: values.searchAmpName,
          headlineText: values.headlineText,
          clickUrl: values.clickUrl,
          logoDataUri: values.logoDataUri
        }
      }),
    items: values.items.map((item: CampaignBuilderItem) => ({
      itemId: item.itemId,
      itemName: item.itemName
    })),
    keywords: values.keywords,
    negativeKeywords: [
      ...values.negativeKeywords.map((negativeKeyword: CampaignBuilderNegativeKeyword) => negativeKeyword.keywordText)
    ]
  };
};

export const isCampaignCreationSuccessful = (response: ResponseObject) => {
  const { adGroups, keywords, negativeKeywords, adItems, campaign, sbaProfileImage, sbaProfile } = response.body;

  return (
    adGroups?.every((adGroup: any) => adGroup?.code?.toLowerCase() === 'success') &&
    keywords?.every((keyword: any) => keyword?.code?.toLowerCase() === 'success') &&
    negativeKeywords?.every((negativeKeyword: any) => negativeKeyword?.code?.toLowerCase() === 'success') &&
    adItems?.every((adItem: any) => adItem?.code?.toLowerCase() === 'success') &&
    campaign?.code?.toLowerCase() === 'success' &&
    (sbaProfileImage?.code ? sbaProfileImage?.code?.toLowerCase() === 'success' : true) &&
    (sbaProfile?.code ? sbaProfile?.code?.toLowerCase() === 'success' : true)
  );
};
