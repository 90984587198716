import * as React from 'react';
import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { CAMPAIGN_TYPE_OPTIONS } from '../../../types/campaign';
import { Tooltip } from '@mui/material';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  }
}));

interface CampaignTypeProps {
  campaignType: string;
  chipSize?: 'small' | 'medium';
}

export const CampaignType = React.memo((props: CampaignTypeProps) => {
  const { campaignType, chipSize } = props;

  const campaignTypeObject = CAMPAIGN_TYPE_OPTIONS.find((option) => option?.value?.toLowerCase() === campaignType.toLowerCase());

  if (!campaignTypeObject) {
    return null;
  }

  return (
    <Tooltip title={campaignType} arrow>
      <StyledChip size={chipSize || 'small'} label={campaignTypeObject.abbreviation} variant="outlined" />
    </Tooltip>
  );
});

export function renderCampaignType(params: GridRenderCellParams) {
  if (params.value == null) {
    return '';
  }

  return <CampaignType campaignType={params.value} />;
}
