import {
  Box,
  Card,
  CardContent,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { FC, useState } from 'react';
import { CampaignBuilderStepLabelButton } from '../../../../sams-club/pages/campaigns/create-campaign-form/step-label-button';
import { ActionParameters } from '../automated-actions';
import { STATE_OPTIONS } from '../../../types/state-options';

interface ActionParametersStepProps {
  setActionType: React.Dispatch<React.SetStateAction<string>>;
  setActionParameters: React.Dispatch<React.SetStateAction<ActionParameters>>;
  actionParameters: ActionParameters;
  executionScheduleDescription: string;
  setExecutionScheduleDescription: React.Dispatch<React.SetStateAction<string>>;
  setLookBackWindow: React.Dispatch<React.SetStateAction<number | undefined>>;
  setRequiredDaysOfData: React.Dispatch<React.SetStateAction<number | undefined>>;
  actionType: string;
  setActionName: React.Dispatch<React.SetStateAction<string>>;
  actionName: string;
}

export const ActionParametersStep: FC<ActionParametersStepProps> = (props) => {
  const {
    actionType,
    actionName,
    setActionName,
    setActionType,
    actionParameters,
    setLookBackWindow,
    setActionParameters,
    setRequiredDaysOfData,
    executionScheduleDescription,
    setExecutionScheduleDescription,
    ...other
  } = props;

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleChange = (event: any) => {
    const scheduleDescription = event.target.value;
    setExecutionScheduleDescription(scheduleDescription);
  };

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const handleActionChange = (event: any) => {
    setActionType(event.target.value);
  };

  const handleUpdateTypeChange = (event: any) => {
    setActionParameters((currentParams) => ({ ...currentParams, updateType: event.target.value }));
  };

  const handleUpdateOperatorChange = (event: any) => {
    setActionParameters((currentParams) => ({ ...currentParams, updateOperator: event.target.value }));
  };

  const handleValueChange = (event: any) => {
    setActionParameters((currentParams) => ({ ...currentParams, value: Number(event.target.value) }));
  };

  const handleMinValueChange = (event: any) => {
    setActionParameters((currentParams) => ({ ...currentParams, minValue: Number(event.target.value) }));
  };

  const handleMaxValueChange = (event: any) => {
    setActionParameters((currentParams) => ({ ...currentParams, maxValue: Number(event.target.value) }));
  };

  const handleLookBackWindowChange = (event: any) => {
    setLookBackWindow(event.target.value);
  };

  const handleRequiredDaysOfDataChange = (event: any) => {
    setRequiredDaysOfData(event.target.value);
  };

  const handleStateChange = (event: any) => {
    setActionParameters((currentParams) => ({ ...currentParams, state: event.target.value }));
  };

  const handleNameChange = (event: any) => {
    setActionName(event.target.value);
  };

  const handleBudgetTypeChange = (event: any) => {
    setActionParameters((currentParams) => ({ ...currentParams, BudgetType: event.target.value }));
  };

  return (
    <Box {...other} sx={{ mt: 2, mb: showStep ? 10 : 1 }}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={2}
        stepLabel="Action Parameters"
      />
      <Collapse in={showStep}>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <TextField
              id="name"
              label="Action Name *"
              placeholder="Enter a name for this action"
              sx={{ width: '63%', mb: 2 }}
              onChange={handleNameChange}
            />
            {actionName && (
              <TextField
                id="Cron"
                label="Please describe the execution schedule *"
                placeholder="every hour, every day at 9AM, every 3 months, etc"
                sx={{ width: '63%' }}
                onChange={handleChange}
              />
            )}
            {executionScheduleDescription && (
              <Box>
                <Typography variant="h6" sx={{ mt: 3 }}>
                  Choose Action:
                </Typography>
                <Box sx={{ mt: 1, mb: 2, display: 'flex', width: '100%' }}>
                  <FormControl sx={{ mr: 2, width: '30%' }}>
                    <InputLabel id="action-label">Action</InputLabel>
                    <Select labelId="action-label" id="action-select" label="Action" onChange={handleActionChange}>
                      <MenuItem value="BidUpdate">Update bid</MenuItem>
                      <MenuItem value="BudgetUpdate">Update budget</MenuItem>
                      <MenuItem value="StateUpdate">Update state</MenuItem>
                    </Select>
                  </FormControl>
                  {actionType === 'BudgetUpdate' && (
                    <FormControl sx={{ mr: 2, width: '30%' }}>
                      <InputLabel id="budget-type-label">Budget Type</InputLabel>
                      <Select labelId="entity-label" id="entity-select" label="Entity" onChange={handleBudgetTypeChange}>
                        <MenuItem value="Daily">Daily</MenuItem>
                        <MenuItem value="Lifetime">LifeTime</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {(actionType === 'BidUpdate' || actionType === 'BudgetUpdate') && (
                    <FormControl sx={{ mr: 2, width: '30%' }}>
                      <InputLabel id="update-type-label">Update Type</InputLabel>
                      <Select
                        labelId="update-type-label"
                        id="update-type-select"
                        label="Update Type"
                        onChange={handleUpdateTypeChange}
                      >
                        <MenuItem value="percent">Percentage</MenuItem>
                        <MenuItem value="dollar">Dollar</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {(actionType === 'BidUpdate' || actionType === 'BudgetUpdate') && (
                    <FormControl sx={{ mr: 2, width: '30%' }}>
                      <InputLabel id="update-operator-label">Update Operator</InputLabel>
                      <Select
                        labelId="update-operator-label"
                        id="update-operator-select"
                        label="Update Operator"
                        onChange={handleUpdateOperatorChange}
                      >
                        <MenuItem value="increase">Increase</MenuItem>
                        <MenuItem value="decrease">Decrease</MenuItem>
                        {actionParameters.updateType === 'dollar' && <MenuItem value="SetToValue">Set</MenuItem>}
                      </Select>
                    </FormControl>
                  )}
                </Box>
                {(actionType === 'BidUpdate' || actionType === 'BudgetUpdate') && (
                  <Box sx={{ mt: 2, display: 'flex' }}>
                    <TextField id="value" label="Value" type="number" sx={{ width: '30%', mr: 2 }} onChange={handleValueChange} />
                    <TextField
                      id="min-value"
                      label="Min Value"
                      type="number"
                      sx={{ width: '30%', mr: 2 }}
                      onChange={handleMinValueChange}
                    />
                    <TextField
                      id="max-value"
                      label="Max Value"
                      type="number"
                      sx={{ width: '30%' }}
                      onChange={handleMaxValueChange}
                    />
                  </Box>
                )}
                {actionType === 'StateUpdate' && (
                  <Box sx={{ mt: 2 }}>
                    <FormControl sx={{ mr: 2, width: '30%' }}>
                      <InputLabel id="state-label">State</InputLabel>
                      <Select labelId="state-label" id="state-select" label="State" onChange={handleStateChange}>
                        <MenuItem value={STATE_OPTIONS.ENABLED}>Enable</MenuItem>
                        <MenuItem value={STATE_OPTIONS.PAUSED}>Pause</MenuItem>
                        <MenuItem value={STATE_OPTIONS.ARCHIVED}>Archive</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <Typography variant="h6" sx={{ mt: 3 }}>
                  Action Settings:
                </Typography>
                <Box sx={{ mt: 2, display: 'flex' }}>
                  <TextField
                    id="look-back-window"
                    label="Look-Back window (in days)"
                    type="number"
                    sx={{ width: '30%', mr: 2 }}
                    onChange={handleLookBackWindowChange}
                  />
                  <TextField
                    id="required-days-of-data"
                    label="Required days of data"
                    type="number"
                    sx={{ width: '30%', mr: 2 }}
                    onChange={handleRequiredDaysOfDataChange}
                  />
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};
