import * as Yup from 'yup';

export const SB_PROFILE_FORM_VALIDATION_SCHEMA = Yup.object({
  searchAmpName: Yup.string().max(35).required(),
  headlineText: Yup.string().max(45).required(),
  clickUrl: Yup.string().required(),
  logoDataUri: Yup.string().required()
});

export const INITIAL_CREATE_FORM_VALUES = {
  campaignId: 0,
  adGroupId: 0,
  searchAmpName: '',
  headlineText: '',
  clickUrl: '',
  logoDataUri: '',
  submit: null
};

export const INITIAL_UPDATE_FORM_VALUES = {
  campaignId: 0,
  adGroupId: 0,
  sbaProfileId: 0,
  searchAmpName: '',
  headlineText: '',
  clickUrl: '',
  logoDataUri: '',
  logoUrl: '',
  submit: null
};
