import { useState } from 'react';
import type { FC } from 'react';
import { Card, CardContent, Tooltip, Typography, Collapse, Grid, Box } from '@mui/material';
import { CustomTypeOptionRadioButton } from './campaign-settings-inputs';
import { CampaignBuilderStepLabelButton } from './step-label-button';
import AddKeywords from '../../../../shared/components/add-keywords';
import AddNegativeKeywords from '../../../components/add-negative-keywords';

interface TargetingSettingsStepProps {
  formik: any;
  stepNumber: number;
}
type stringNumberType = {
  [key: string]: number;
};

const minKeywordBidsByCampaignType: stringNumberType = {
  sba: 1.25,
  sponsoredProducts: 0.3,
  video: 1.5
};

export const TargetingSettingsStep: FC<TargetingSettingsStepProps> = (props) => {
  const { formik, stepNumber, ...other } = props;

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  return (
    <Box {...other}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Targeting Settings"
      />
      <Collapse in={showStep}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={4}>
            {['sba', 'video'].some((type) => type === formik.values.campaignType) ? (
              <Tooltip
                title={'Automatic targeting not available for Sponsored Brand and Sponsored Video campaigns'}
                placement="bottom"
              >
                <CustomTypeOptionRadioButton
                  typeOptionValue={'auto'}
                  typeOptionLabel={'Automatic'}
                  checked={formik.values.targetingType === 'auto'}
                  handleClick={(): void => formik.setFieldValue('targetingType', 'auto')}
                  sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
                  disabled={true}
                />
              </Tooltip>
            ) : (
              <CustomTypeOptionRadioButton
                typeOptionValue={'auto'}
                typeOptionLabel={'Automatic'}
                checked={formik.values.targetingType === 'auto'}
                handleClick={(): void => formik.setFieldValue('targetingType', 'auto')}
                sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <CustomTypeOptionRadioButton
              typeOptionValue={'manual'}
              typeOptionLabel={'Manual'}
              checked={formik.values.targetingType === 'manual'}
              handleClick={(): void => formik.setFieldValue('targetingType', 'manual')}
              sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
            />
          </Grid>
        </Grid>
        <Collapse in={formik.values.targetingType === 'manual'}>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Add Keywords (Optional)
              </Typography>
              <Typography variant="caption" gutterBottom>
                Enter a bid, select match types, and enter keywords. Once finished click create ads to create your new ads and
                keywords.
              </Typography>
              <AddKeywords
                keywords={formik.values.keywords}
                setKeywords={(keywords) => formik.setFieldValue('keywords', keywords)}
                minBid={minKeywordBidsByCampaignType[formik.values.campaignType]}
              />
            </CardContent>
          </Card>
        </Collapse>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Add Negative Keywords (Optional)
            </Typography>
            <Typography variant="caption" gutterBottom>
              Enter negative keywords. Once finished click create ads to create your new ads and keywords.
            </Typography>
            <AddNegativeKeywords
              negativeKeywords={formik.values.negativeKeywords}
              setNegativeKeywords={(negativeKeywords) => formik.setFieldValue('negativeKeywords', negativeKeywords)}
            />
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};
