import { GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { renderActiveAction } from '../renderer/renderActiveToggle';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { Link, Stack, Tooltip, Typography } from '@mui/material';

function RenderActions(params: GridRowParams) {
  const { updateNegativeTarget } = useAmazonApi();

  const handleUpdateEntity = (entityId: string, state: 'enabled' | 'paused') => {
    return updateNegativeTarget({ negativeTargetId: entityId, state: state });
  };

  return renderActiveAction({ entityIdKey: 'negativeTargetId', updateEntityFunction: handleUpdateEntity })(params);
}

export const NEGATIVE_TARGET_COLUMNS: GridColDef[] = [
  {
    field: 'actions',
    headerName: 'Active',
    filterable: false,
    type: 'actions',
    width: 80,
    getActions: RenderActions
  },
  {
    field: 'expression',
    headerName: 'Negative Target',
    width: 150,
    minWidth: 120,
    renderCell: (params) => {
      if (params?.row?.negativeTargetType === 'Keyword') {
        return params?.row?.keywordText;
      }

      if (params?.row?.expression[0]?.type === 'AsinBrandSameAs') {
        return (
          <Typography fontSize={12}>
            <strong>Brand</strong>: {params?.row?.resolvedExpression[0]?.value}
          </Typography>
        );
      }

      if (params?.row?.expression[0]?.type === 'AsinSameAs') {
        return (
          <Stack>
            <Tooltip title={params?.row?.resolvedExpression[0]?.value}>
              <Link
                href={`https://www.amazon.com/dp/${params?.row?.expression[0]?.value}`}
                target="_blank"
                rel="noopener"
                sx={{
                  fontSize: '12px',
                  overflow: 'hidden',
                  maxWidth: '300px',
                  textOverflow: 'ellipsis'
                }}
              >
                {params?.row?.resolvedExpression[0]?.value}
              </Link>
            </Tooltip>
            <Typography
              sx={{
                fontSize: '12px'
              }}
            >
              ASIN: {params?.row?.expression[0]?.value}
            </Typography>
          </Stack>
        );
      }
    }
  },
  { field: 'state', headerName: 'State', width: 150 },
  {
    field: 'matchType',
    headerName: 'Match Type',
    width: 150,
    minWidth: 120,
    renderCell: (params) => {
      if (params?.row?.negativeTargetType === 'Keyword') {
        return params?.row?.matchType;
      }

      return 'N/A';
    }
  }
];
