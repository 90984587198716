import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MinMaxInput from './min-max-input';
import { useEffect, useState } from 'react';
import { UpdateBidsConfig } from '../../types/update-bids';
import { NumericFormat } from 'react-number-format';

interface EditBidsDialogProps {
  open: boolean;
  onClose: () => void;
  handleUpdate: (updateConfig: UpdateBidsConfig) => void;
}

const bidUpdateTypeOptions: { [key: string]: string } = {
  percent: '%',
  dollar: '$'
};

export default function UpdateBidsDialog({ open, onClose, handleUpdate }: EditBidsDialogProps) {
  const [updateConfig, setUpdateConfig] = useState<UpdateBidsConfig>({
    bidUpdateType: 'percent',
    value: 0,
    bidUpdateOperator: 'increase',
    shouldApplyMaxBid: false,
    shouldApplyMinBid: false,
    maxBid: 0.3,
    minBid: 0.3
  });

  const handleBidUpdateTypeChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    if (value !== 'percent' && value !== 'dollar') {
      return;
    }

    setUpdateConfig((prevState) => ({
      ...prevState,
      bidUpdateType: value
    }));
  };

  const handleBidUpdateOperatorChange = (event: SelectChangeEvent<any>) => {
    const { value } = event.target;

    setUpdateConfig((prevState) => ({
      ...prevState,
      bidUpdateOperator: value
    }));
  };

  useEffect(() => {
    if (updateConfig.bidUpdateOperator === 'setToValue') {
      setUpdateConfig((prevState) => ({
        ...prevState,
        bidUpdateType: 'dollar'
      }));
    }
  }, [updateConfig.bidUpdateOperator]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUpdateConfig((prevState) => ({
      ...prevState,
      value: 0
    }));
  }, [updateConfig.bidUpdateType]); // eslint-disable-line react-hooks/exhaustive-deps

  const getValueInputComponent = () => {
    if (updateConfig.bidUpdateType === 'percent') {
      return (
        <NumericFormat
          customInput={TextField}
          id="outlined-adornment-weight"
          value={updateConfig.value}
          size="small"
          sx={{ ml: 1, maxWidth: '100px' }}
          thousandSeparator=","
          allowNegative={false}
          decimalScale={2}
          onValueChange={(values, sourceInfo) => {
            setUpdateConfig((prevState) => ({
              ...prevState,
              value: values.floatValue
            }));
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
        />
      );
    }

    return (
      <NumericFormat
        customInput={TextField}
        id="outlined-adornment-weight"
        thousandSeparator=","
        value={updateConfig.value}
        size="small"
        decimalScale={2}
        allowNegative={false}
        sx={{ ml: 1, maxWidth: '100px' }}
        onValueChange={(values, sourceInfo) => {
          setUpdateConfig((prevState) => ({
            ...prevState,
            value: values.floatValue
          }));
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box>$</Box>
            </InputAdornment>
          )
        }}
      />
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle sx={{ minWidth: '400px' }} id="alert-dialog-title">
          {'Update Bids'}
        </DialogTitle>
        <DialogContent>
          <Typography variant="caption">Percent or dollar values must be positive*</Typography>
          <Box sx={{ minWidth: 400, mt: 2, display: 'flex' }} alignItems="center">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              value={updateConfig.bidUpdateOperator}
              onChange={(event) => handleBidUpdateOperatorChange(event)}
              sx={{
                '& .MuiSelect-select': {
                  minWidth: '100px'
                }
              }}
            >
              <MenuItem value={'increase'}>Increase by</MenuItem>
              <MenuItem value={'decrease'}>Decrease by</MenuItem>
              <MenuItem value={'setToValue'}>Set to value</MenuItem>
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={updateConfig.bidUpdateType}
              onChange={handleBidUpdateTypeChange}
              sx={{ minWidth: '65px', ml: 1 }}
              size="small"
              disabled={updateConfig.bidUpdateOperator === 'setToValue'}
            >
              {Object.entries(bidUpdateTypeOptions).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {getValueInputComponent()}
          </Box>
          <MinMaxInput updateConfig={updateConfig} setUpdateConfig={setUpdateConfig} />
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => handleUpdate(updateConfig)}
            variant={'contained'}
            autoFocus
            disabled={updateConfig.value < 0 || !updateConfig.value}
          >
            Update Bids
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
