import { cloneDeep } from 'lodash';

export const listOfDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export enum DAYS {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export interface Point {
  day: DAYS;
  hour: number;
}

export interface DaypartingSchedule {
  [key: number]: DaypartingScheduleEntry[];
}

export enum DaypartingMode {
  EnableDisable = 'enableDisable',
  BidDecrease = 'bidDecrease'
}

export interface DaypartingScheduleEntry {
  State: DaypartingScheduleState;
  BidAdjustmentPercentage: number;
}

export enum DaypartingScheduleState {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  BidDecrease = 'BidDecrease'
}

export const blankDaypartingSchedule: DaypartingSchedule = {
  [DAYS.Sunday]: generateDaypartingEntries(),
  [DAYS.Monday]: generateDaypartingEntries(),
  [DAYS.Tuesday]: generateDaypartingEntries(),
  [DAYS.Wednesday]: generateDaypartingEntries(),
  [DAYS.Thursday]: generateDaypartingEntries(),
  [DAYS.Friday]: generateDaypartingEntries(),
  [DAYS.Saturday]: generateDaypartingEntries()
};

function generateDaypartingEntries(state: string = DaypartingScheduleState.Enabled): DaypartingScheduleEntry[] {
  const entries: DaypartingScheduleEntry[] = [];
  for (let hour = 0; hour < 24; hour++) {
    entries.push({
      State: state as DaypartingScheduleState,
      BidAdjustmentPercentage: 0.0
    });
  }
  return entries;
}

export const formatApiDataForDaypartingUI = (apiData: any): DaypartingSchedule => {
  const daypartingSchedule: DaypartingSchedule = cloneDeep(blankDaypartingSchedule);

  for (const [day, daySchedule] of Object.entries(apiData)) {
    const dayNumber = DAYS[day as keyof typeof DAYS];

    Object.entries(daySchedule as { [hour: string]: any }).forEach(([hour, entry]: [string, any]) => {
      const hourNumber = Number(hour);

      daypartingSchedule[dayNumber][hourNumber].State = entry.state as DaypartingScheduleState;
      daypartingSchedule[dayNumber][hourNumber].BidAdjustmentPercentage = entry.bidAdjustmentPercentage;
    });
  }

  return daypartingSchedule;
};

export const formatDaypartingDataForApi = (daypartingSchedule: DaypartingSchedule): any => {
  const apiData: any = {
    Schedule: {},
    LastUpdated: new Date().toISOString()
  };

  Object.keys(daypartingSchedule).forEach((day) => {
    const dayName = DAYS[day as any];
    apiData.Schedule[dayName] = {};

    const daySchedule = daypartingSchedule[Number(day)];
    daySchedule.forEach((entry, hour) => {
      apiData.Schedule[dayName][hour] = {
        State: entry.State,
        BidAdjustmentPercentage: entry.BidAdjustmentPercentage
      };
    });
  });

  return apiData;
};
