import { FC, useEffect, useState } from 'react';
import type { ApexOptions } from 'apexcharts';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../../../shared/components/chart';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { cloneDeep } from 'lodash';

const createCategories = (): string[] => {
  const categories: string[] = [];

  // add 24 hours 0 to 23
  for (let i = 0; i < 24; i++) {
    categories.push(i.toString());
  }

  return categories;
};

interface UseChartOptionProps {
  averageOOBHour: number;
}
const useChartOptions = ({ averageOOBHour }: UseChartOptionProps): ApexOptions => {
  const theme = useTheme();
  const categories = createCategories();

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    fill: {
      opacity: 1,
      type: 'solid'
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      horizontalAlign: 'right',
      labels: {
        colors: theme.palette.text.secondary
      },
      position: 'top',
      show: true
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2
      },
      radius: 2,
      shape: 'circle',
      size: 4,
      strokeWidth: 0
    },
    stroke: {
      curve: 'smooth',
      dashArray: [0, 3],
      lineCap: 'butt',
      width: 3
    },
    theme: {
      mode: theme.palette.mode
    },
    labels: categories,
    // create array of numbers from 0 to 23
    xaxis: {
      type: 'numeric',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tickAmount: 23,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        },
        formatter: (value) => {
          // Remove decimal of string number
          const hour = value.toString().split('.')[0];
          return hour.toString();
        }
      }
    },
    // conditionally add annotation if average OOB hour is greater than 0 and not null

    ...(averageOOBHour && {
      annotations: {
        xaxis: [
          {
            x: averageOOBHour.toString(),
            // use theme colors
            borderColor: theme.palette.warning.main,
            strokeDashArray: 0,
            label: {
              borderColor: theme.palette.warning.main,
              style: {
                color: theme.palette.text.primary,
                background: theme.palette.warning.main
              },
              text: 'Average OOB Hour'
            }
          }
        ]
      }
    }),
    yaxis: [
      {
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },
        labels: {
          show: true
        }
      }
    ]
  };
};

interface HourlyStatsChartProps {
  campaignId: number;
}

export const HourlyStatsChart: FC<HourlyStatsChartProps> = (props) => {
  const { campaignId } = props;
  const { getHourlyStatsChartViewData } = useAdsApi();
  const [clicksByHour, setClicksByHour] = useState<any>([]);
  const [impressionsByHour, setImpressionsByHour] = useState<any>([]);
  const [adSpendByHour, setAdSpendByHour] = useState<any>([]);
  const [averageOOBHour, setAverageOOBHour] = useState<any>(0);
  const [daysBack, setDaysBack] = useState(7);
  const [isLoading, setIsLoading] = useState(true);

  const intialize = async () => {
    setIsLoading(true);
    const response = await getHourlyStatsChartViewData(campaignId, daysBack);

    setClicksByHour(response?.body?.clicks ?? []);
    setImpressionsByHour(response?.body?.impressions ?? []);
    setAdSpendByHour(response?.body?.adSpend ?? []);
    setAverageOOBHour(response?.body?.averageOutOfBudgetHour ?? 0);

    setIsLoading(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setDaysBack(Number(event.target.value));
  };

  useEffect(() => {
    intialize();
  }, [daysBack]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography component={'div'} variant="h5">
            Cumulative Metrics By Hour
          </Typography>
          <FormControl sx={{ minWidth: '100px' }}>
            <InputLabel>Days Back</InputLabel>
            <Select value={daysBack.toString()} label="Days Back" onChange={handleChange}>
              <MenuItem value={1}>Yesterday</MenuItem>
              <MenuItem value={7}>L7D</MenuItem>
              <MenuItem value={30}>L30D</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <Skeleton variant="rectangular" width={800} height={320} />
          </Box>
        ) : (
          <MetricsChart
            clicksByHour={clicksByHour}
            impressionsByHour={impressionsByHour}
            adSpendByHour={adSpendByHour}
            averageOOBHour={averageOOBHour}
          />
        )}
      </CardContent>
    </Card>
  );
};

interface MetricsChartProps {
  clicksByHour: any;
  impressionsByHour: any;
  adSpendByHour: any;
  averageOOBHour: number;
}

const MetricsChart = ({ clicksByHour, impressionsByHour, adSpendByHour, averageOOBHour }: MetricsChartProps) => {
  const chartOptions = useChartOptions({ averageOOBHour });

  return (
    <Chart
      height={320}
      width={800}
      options={chartOptions}
      series={[
        {
          data: Object.entries(clicksByHour).map((value) => [Number(value[0]), Number(value[1])]),
          name: 'Clicks'
        },
        {
          data: Object.entries(adSpendByHour).map((value) => [Number(value[0]), Number(value[1])]),
          name: 'Ad Spend'
        }
      ]}
      type="line"
    />
  );
};
