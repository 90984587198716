import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';

import { useSnackbar } from 'notistack';
import useAdsApi from '../../../../shared/hooks/use-walmart-sams-club-api';

interface PauseCampaignActionButtonProps {
  setIsLoading: (isLoading: boolean) => void;
  refreshCampaigns: () => void;
  selectionModelHasRows: boolean;
}

const EnableCampaignsActionButton = ({
  setIsLoading,
  refreshCampaigns,
  selectionModelHasRows
}: PauseCampaignActionButtonProps) => {
  const apiRef = useGridApiContext();
  const { bulkSetCampaignIsActiveInVector } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const activateCampaigns = async () => {
    setIsLoading(true);

    const selectedCampaignIds = Array.from(apiRef.current.getSelectedRows().values())
      .filter((campaign) => campaign.status.toLowerCase() !== 'proposal')
      .map((campaign) => campaign.campaignId);

    const response = await bulkSetCampaignIsActiveInVector(selectedCampaignIds, true);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    await refreshCampaigns();
    apiRef.current.setRowSelectionModel([]);
    setIsLoading(false);
  };

  return (
    <>
      <Tooltip title={selectionModelHasRows ? 'Activate Campaigns' : 'Select at least one item to enable actions'}>
        <span>
          <IconButton
            sx={{ borderRadius: '100px' }}
            color="primary"
            size="large"
            onClick={activateCampaigns}
            disabled={!selectionModelHasRows}
          >
            <PlayArrowIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default EnableCampaignsActionButton;
