import { FC, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Button, Tooltip, styled } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CampaignMode, CampaignStructureType, createCampaignBuilderRequest } from '../../../types/campaign-builder-request';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CAMPAIGN_PREVIEW_COLUMNS } from '../../../components/grid-components/column-configurations/campaign-preview-columns';
import RefreshIcon from '@mui/icons-material/Refresh';

interface CampaignPreviewProps {
  values: any;
  campaignMode: CampaignMode;
  isScreenSmall?: boolean;
  parentWidth: number;
}

const StyledInfoBox = styled(Box)(({ theme }) => ({
  border: ` 2px solid ${theme.palette.divider}`,
  borderRadius: '0.5rem',
  padding: '1rem',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

const CampaignPreview: FC<CampaignPreviewProps> = (props) => {
  const { values, campaignMode, isScreenSmall, parentWidth } = props;
  const [displayedRows, setDisplayedRows] = useState<any[]>([]);
  const { buildCampaignPreview } = useAmazonApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [previewDataLoaded, setPreviewDataLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const profileId = searchParams.get('profileId') ?? null;
  const [campaignInfo, setCampaignInfo] = useState({
    numberOfItems: 0,
    numberOfNestedKeywords: 0,
    numberOfTargets: 0,
    numberOfUniversalKeywords: 0,
    numberOfCampaigns: 0,
    totalBudget: 0
  });

  async function loadPreviewData() {
    const updatedValues = { ...values };
    const requestBody = createCampaignBuilderRequest(updatedValues);
    const response = await buildCampaignPreview(profileId, requestBody);

    if (response.success) {
      setDisplayedRows([]);
      for (const row of response.body.successes) {
        setDisplayedRows((prevDisplayedRows) => [...prevDisplayedRows, row]);
        await new Promise((resolve) => setTimeout(resolve, 150));
      }
      setPreviewDataLoaded(true);
    } else {
      enqueueSnackbar('Error while loading preview data', { variant: 'error' });
    }
  }

  useEffect(() => {
    const newCampaignInfo = {
      numberOfItems: values?.items?.length || 0,
      numberOfNestedKeywords: values?.items?.map((item: any) => item.keywords.length).reduce((a: any, b: any) => a + b, 0) ?? 0,
      numberOfTargets: values?.items?.map((item: any) => item.targetingClauses.length).reduce((a: any, b: any) => a + b, 0) ?? 0,
      numberOfUniversalKeywords: values?.universalKeywords?.length ?? 0
    };

    const numberOfCampaigns = calculateNumberOfCampaigns(
      newCampaignInfo.numberOfItems,
      newCampaignInfo.numberOfUniversalKeywords,
      newCampaignInfo.numberOfTargets,
      newCampaignInfo.numberOfNestedKeywords
    );

    setCampaignInfo({
      ...newCampaignInfo,
      numberOfCampaigns: numberOfCampaigns,
      totalBudget: (values?.dailyBudget ?? 0) * numberOfCampaigns
    });
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  function calculateNumberOfCampaigns(
    numberOfItems: number,
    numberOfUniversalKeywords: number,
    numberOfTargets: number,
    numberOfNestedKeywords: number
  ): number {
    if (campaignMode === CampaignMode.MANUAL) {
      switch (values?.campaignStructureType) {
        case CampaignStructureType.SINGLE_ITEM_SINGLE_KEYWORD_CAMPAIGN:
          return numberOfItems * numberOfUniversalKeywords + numberOfNestedKeywords;
        case CampaignStructureType.SINGLE_ITEM_SINGLE_PRODUCT_TARGET_CAMPAIGN:
          return numberOfItems * numberOfTargets;
        case CampaignStructureType.SINGLE_ITEM_MULTI_KEYWORD_CAMPAIGN:
        case CampaignStructureType.SINGLE_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN:
          return numberOfItems;
        case CampaignStructureType.MULTI_ITEM_MULTI_KEYWORD_CAMPAIGN:
        case CampaignStructureType.MULTI_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN:
          return 1;
        default:
          return 0;
      }
    } else return 1;
  }

  return (
    <Box sx={{ width: '100%', position: 'relative', display: 'block' }}>
      <Card
        sx={{
          mt: 2,
          position: isScreenSmall ? 'flex' : 'fixed',
          height: isScreenSmall ? 'auto' : '650px',
          width: isScreenSmall ? 'auto' : parentWidth,
          overflow: 'scroll'
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: '1rem' }}>
            <Typography variant="h4">Preview</Typography>
            {previewDataLoaded && (
              <Tooltip title="Refresh Preview Data">
                <Button color="primary" onClick={loadPreviewData} sx={{ alignSelf: 'center' }}>
                  <RefreshIcon fontSize="large" />
                </Button>
              </Tooltip>
            )}
          </Box>

          <Box sx={{ pb: '1rem', display: 'flex', gap: 4 }}>
            <StyledInfoBox>
              <Typography variant="body2">Number of Campaigns :</Typography>
              <Typography variant="h4">{campaignInfo.numberOfCampaigns}</Typography>
            </StyledInfoBox>
            <StyledInfoBox>
              <Typography variant="body2">Total Daily Budget:</Typography>
              <Typography variant="h4">${campaignInfo.totalBudget}</Typography>
            </StyledInfoBox>
          </Box>
          {previewDataLoaded && displayedRows.length > 0 ? (
            <DataGridPremium
              disableColumnMenu
              disableColumnReorder
              disableDensitySelector
              disableRowSelectionOnClick
              columns={CAMPAIGN_PREVIEW_COLUMNS}
              disableRowGrouping
              rows={displayedRows}
              getRowId={(row) => row.campaignId}
              autoHeight
            />
          ) : (
            <Button variant="outlined" color="primary" onClick={loadPreviewData} sx={{ my: '2rem', alignSelf: 'center' }}>
              Load Preview
            </Button>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CampaignPreview;
