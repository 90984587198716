import { ApexOptions } from 'apexcharts';
import { abbreviateMetricDisplayName, formatMetricValue } from '../../../utilities/chart-utilities';
import { useTheme } from '@mui/material';

interface HourlyMetricsChartData {
  [x: string]: string;
  displayName: string;
  dataType: string;
  data: any;
}

const useChartOptions = (timeSeries: HourlyMetricsChartData[], series: any[], colors: string[]): ApexOptions => {
  const { palette } = useTheme();

  return {
    chart: {
      animations: {
        enabled: true
      },
      background: palette.background.paper,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    legend: {
      show: false
    },
    colors: colors,
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
      type: 'gradient',
      gradient: {
        shade: palette.mode === 'dark' ? 'dark' : 'light',
        type: 'vertical',
        shadeIntensity: 0.6,
        opacityFrom: 0.3,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    grid: {
      borderColor: palette.divider,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      radius: 4,
      shape: 'circle',
      size: 2,
      strokeWidth: 0,
      hover: {
        sizeOffset: 2
      }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 3
    },
    theme: {
      mode: palette.mode
    },
    xaxis: {
      categories: Array.from({ length: 24 }, (_, i) => i),
      type: 'numeric',
      labels: {
        style: {
          colors: palette.text.secondary
        },
        formatter: (value: any) => {
          return Math.floor(value) + 'h';
        }
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: palette.divider,
          width: 2
        }
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: palette.divider,
        height: 6,
        offsetX: 0,
        offsetY: 0
      },
      tickAmount: 23,
      title: {
        text: 'Hour of Day',
        style: {
          color: palette.text.secondary
        }
      }
    },
    yaxis: series,
    noData: {
      text: 'No Data Available'
    },
    tooltip: {
      x: {
        formatter: (val: any) => {
          return `Hour: ${Math.floor(val)}:00`;
        }
      },
      y: {
        formatter: (val: any, opt) => {
          const lastYAxis = opt.w.globals.lastYAxis[opt.seriesIndex];
          const metric = timeSeries.find(
            (metric: { displayName: string }) => abbreviateMetricDisplayName(metric.displayName) === lastYAxis?.title?.text
          );
          if (metric) {
            return formatMetricValue(val, false, metric.dataType);
          }
          return '';
        },
        title: {
          formatter: (seriesName: string) => {
            const metric = timeSeries.find((metric: HourlyMetricsChartData) => metric.name === seriesName);
            return `${abbreviateMetricDisplayName(metric?.displayName || '')}: `;
          }
        }
      },
      marker: {
        show: true
      },
      theme: palette.mode
    }
  };
};

export { useChartOptions };
