import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Skeleton,
  Typography
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useAmazonApi } from '../../../../hooks/use-amazon-api';
import { allRefinementsMatch, buildAddedTargetListItemFromRecommendation } from './targeting-utils';
import { MatchType } from '../../../../types/targeting-refinements';
import { AmazonCampaignBuilderItemDto } from '../../../../types/campaign-builder-request';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { ProductTargetMatchType, RecommendationDto } from '../../../../types/target';

interface ProductRecommendationItemProps {
  recommendation: any;
  handleAddTargets: (targets: any) => void;
  targets: any[];
  existingTargets: any[];
  matchType: MatchType;
  bid: number;
}

const ProductRecommendationItem = (props: ProductRecommendationItemProps) => {
  const { recommendation, handleAddTargets, targets, existingTargets, matchType, bid } = props;

  function handleAddTarget() {
    let newTargetClauses: any[] = [];

    if (matchType.exact === true) {
      newTargetClauses.push(
        buildAddedTargetListItemFromRecommendation({ ...recommendation, matchType: ProductTargetMatchType.Exact }, 'product', bid)
      );
    }

    if (matchType.expanded === true) {
      newTargetClauses.push(
        buildAddedTargetListItemFromRecommendation(
          { ...recommendation, matchType: ProductTargetMatchType.Expanded },
          'product',
          bid
        )
      );
    }

    // Filter out duplicates based on targets array
    const newDistinctTargets = newTargetClauses.filter(
      (newTarget) =>
        !targets.some(
          (prevTarget: { recommendedAsin: any; matchType: any }) =>
            prevTarget.recommendedAsin === newTarget.recommendedAsin && prevTarget.matchType === newTarget.matchType
        )
    );

    // Further filter out duplicates based on existingTargets array
    const finalDistinctTargets = newDistinctTargets.filter(
      (newTarget) =>
        !existingTargets.some((existingTarget) => {
          const targetExistsOnCampaign = allRefinementsMatch(existingTarget.expression, newTarget.targetingClause.expression);

          if (targetExistsOnCampaign) {
            enqueueSnackbar(`Target already exists on campaign: ${newTarget.recommendedAsin} - ${newTarget.matchType}`, {
              variant: 'warning'
            });
          }

          return targetExistsOnCampaign;
        })
    );

    handleAddTargets(finalDistinctTargets);
  }

  return (
    <ListItem>
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <img
              src={`https://images.amazon.com/images/P/${recommendation.recommendedAsin}.jpg`}
              style={{ height: '50px', width: '50px' }}
            />
            <Typography variant="body2" component={'div'}>
              <strong>{recommendation.recommendedAsin}</strong>{' '}
            </Typography>
          </Box>
        }
        secondary={
          <Box>
            {recommendation.themes.map((theme: any) => (
              <Fragment key={theme}>
                <Typography component="span" variant="caption" color="textPrimary">
                  {theme}
                </Typography>
                <br />
              </Fragment>
            ))}
          </Box>
        }
      />
      <ListItemSecondaryAction>
        <Button onClick={handleAddTarget}>Add</Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

interface ProductTargetRecommendationsProps {
  profileId: string | null;
  campaignId: string | null;
  asins: any[];
  handleAddTargets: (targets: any) => void;
  matchType: MatchType;
  setMatchType: (matchType: MatchType) => void;
  targets: any[];
  existingTargets?: any[];
  productRecommendations: RecommendationDto[];
  setProductRecommendations: (productRecommendations: RecommendationDto[]) => void;
  bid: number;
  isSponsoredProductsCampaignBuilderRequest?: boolean;
}

export default function ProductTargetRecommendations(props: ProductTargetRecommendationsProps) {
  const {
    profileId,
    campaignId,
    asins,
    handleAddTargets,
    targets,
    existingTargets,
    matchType,
    setMatchType,
    productRecommendations,
    setProductRecommendations,
    bid,
    isSponsoredProductsCampaignBuilderRequest
  } = props;

  const { getProductTargetRecommendations } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!profileId || !asins.length) {
      return;
    }

    const fetchProductTargets = async () => {
      setIsLoading(true);

      const response = await getProductTargetRecommendations(profileId, isSponsoredProductsCampaignBuilderRequest || false, {
        adAsins: asins,
        campaignId: campaignId || null
      });

      if (response.success) {
        const recommendations = response.body.recommendations || [];
        setProductRecommendations(recommendations);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    fetchProductTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMatchType({ ...matchType, [event.target.name]: event.target.checked });
  };

  return (
    <Box>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={matchType.exact} onChange={handleCheckboxChange} name="exact" />}
          label="Exact"
        />
        <FormControlLabel
          control={<Checkbox checked={matchType.expanded} onChange={handleCheckboxChange} name="expanded" />}
          label="Expanded"
        />
      </FormGroup>
      <Paper variant="outlined" sx={{ height: '325px', overflow: 'scroll' }}>
        <Box sx={{ px: 2 }}>
          {isLoading ? (
            <Skeleton variant="text" width="30%" />
          ) : (
            <Typography variant="caption">{productRecommendations?.length} products</Typography>
          )}
        </Box>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
        ) : (
          <List>
            {productRecommendations.length > 0 ? (
              productRecommendations.map((productTargetRecommendation: any, index: number) => (
                <ProductRecommendationItem
                  key={index}
                  recommendation={productTargetRecommendation}
                  handleAddTargets={handleAddTargets}
                  targets={targets}
                  existingTargets={existingTargets || []}
                  matchType={matchType}
                  bid={bid}
                />
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No category recommendations" />
              </ListItem>
            )}
          </List>
        )}
      </Paper>
    </Box>
  );
}
