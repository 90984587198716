import { ResponseObject } from '../../shared/utilities/fetch-utilities';
import { Campaign } from '../../shared/types/walmart-sams-club/campaign';

export interface CampaignBuilderSbProfile {
  searchAmpName: string;
  headlineText: string;
  logoDataUri: string;
  clickUrl: string;
}

export interface CampaignBuilderItem {
  itemId: number;
  itemName: string;
}

export interface CampaignBuilderKeyword {
  keywordText: string;
  matchType: string;
  bid: number;
}

export interface CampaignBuilderRequest {
  campaign: Campaign;
  sbaProfile?: CampaignBuilderSbProfile;
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
}

export interface AdBuilderRequest {
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
}

export const createCampaignBuilderRequest = (values: any): CampaignBuilderRequest => {
  return {
    campaign: {
      name: values.name,
      campaignType: values.campaignType,
      targetingType: values.targetingType,
      startDate: values.startDate,
      ...(values.endDate ? { endDate: values.endDate } : {}),
      budgetType: values.budgetType,
      ...(['daily', 'both'].includes(values.budgetType) && { dailyBudget: Number(values.dailyBudget) }),
      ...(['total', 'both'].includes(values.budgetType) && { totalBudget: Number(values.totalBudget) }),
      biddingStrategy: values.biddingStrategy
    },
    ...(values.campaignType === 'sba' &&
      values?.searchAmpName &&
      values?.headlineText &&
      values?.clickUrl &&
      values?.logoDataUri && {
        sbaProfile: {
          searchAmpName: values.searchAmpName,
          headlineText: values.headlineText,
          clickUrl: values.clickUrl,
          logoDataUri: values.logoDataUri
        }
      }),
    items: values.items.map((item: CampaignBuilderItem) => ({
      itemId: item.itemId,
      itemName: item.itemName
    })),
    keywords: values.keywords
  };
};

export const isCampaignCreationSuccessful = (response: ResponseObject) => {
  const { adGroups, keywords, adItems, campaign, sbaProfileImage, sbaProfile } = response.body;

  return (
    adGroups?.every((adGroup: any) => adGroup?.code?.toLowerCase() === 'success') &&
    keywords?.every((keyword: any) => keyword?.code?.toLowerCase() === 'success') &&
    adItems?.every((adItem: any) => adItem?.code?.toLowerCase() === 'success') &&
    campaign?.code?.toLowerCase() === 'success' &&
    (sbaProfileImage?.code ? sbaProfileImage?.code?.toLowerCase() === 'success' : true) &&
    (sbaProfile?.code ? sbaProfile?.code?.toLowerCase() === 'success' : true)
  );
};
