import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { GridEventListener, GridRowSelectionModel, useGridApiContext } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import IconButtonWithTooltip from '../../../../../shared/components/icon-button-with-tooltip';
import useDialog from '../../../../../shared/hooks/use-dialog';
import { OverviewAdGroupSearchTermHarvestingRow } from '../overview-ad-group-harvesting';
import { AMAZON_TARGET_MINIMUM_BID } from '../../../../types/target';

const OverviewAdGroupSearchTermHarvestingBulkAction = (props: {
  handleConfirmAndApply: (rows: OverviewAdGroupSearchTermHarvestingRow[]) => Promise<void>;
  setBid: (bid: number) => void;
}) => {
  const { handleConfirmAndApply, setBid } = props;
  const { toggle: toggleAddSearchTermDialog, isShowing: isAddSearchTermDialogShowing } = useDialog();
  const apiRef = useGridApiContext();
  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (params: GridRowSelectionModel) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  useEffect(() => {
    const initialize = () => {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IconButtonWithTooltip
        tooltipText="Add Search Term(s) to Ad Group"
        handleClick={toggleAddSearchTermDialog}
        isDisabled={!selectionModelHasRows}
        icon={
          <>
            <AddIcon />
            <Typography variant="body1">Add Harvested Keywords</Typography>
          </>
        }
      />
      {isAddSearchTermDialogShowing && (
        <Dialog open={isAddSearchTermDialogShowing} onClose={toggleAddSearchTermDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Add Harvested Keyword(s) to Ad Group</DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              This bid will apply to all new keywords being added. The match type will be "Exact".
            </Typography>
            <Box display="flex" justifyContent="center">
              <TextField
                label="Bid"
                type="number"
                inputProps={{ min: AMAZON_TARGET_MINIMUM_BID, step: '.01' }}
                variant="outlined"
                sx={{ mt: 2, display: 'flex', width: '250px' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                onChange={(event) => {
                  setBid(Number(event.target.value));
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={toggleAddSearchTermDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleConfirmAndApply(
                  Array.from(apiRef.current.getSelectedRows().values()) as OverviewAdGroupSearchTermHarvestingRow[]
                );
                toggleAddSearchTermDialog();
              }}
            >
              Confirm and Apply
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default OverviewAdGroupSearchTermHarvestingBulkAction;
