import { GridColDef } from '@mui/x-data-grid-premium';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { renderProfileNameWithActions } from '../../../../walmart/components/grid-components/renderer/profile/render-name-with-actions';
import { METRIC_COLUMNS } from './metric-columns';
import { Platform } from '../../../../shared/types/platforms';

export const PROFILE_COLUMNS: GridColDef[] = [
  {
    field: 'profileId',
    headerName: 'Profile ID',
    width: 100,
    minWidth: 50,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 160,
    minWidth: 100,
    editable: false,
    align: 'left',
    renderCell: renderProfileNameWithActions(Platform.SAMS_CLUB.value)
  },
  {
    field: 'platformName',
    headerName: 'Platform Name',
    width: 160,
    minWidth: 100,
    editable: false,
    filterable: false
  },
  {
    field: 'platformId',
    headerName: 'Platform ID',
    width: 160,
    minWidth: 70,
    editable: false,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  ...METRIC_COLUMNS
];
