import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import { Box, Rating, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { METRIC_COLUMNS } from './metric-columns';
import { renderProductCell } from '../renderer/renderProductCell';

const amazonMetricColumns = METRIC_COLUMNS.filter(
  (column) => column.field !== 'units' && !column.field.toLowerCase().includes('newtobrand')
);

export const PRODUCT_COLUMNS_ALL: GridColDef[] = [
  {
    field: 'asin',
    headerName: 'ASIN',
    width: 130,
    minWidth: 115,
    sortable: true,
    filterable: true
  },
  {
    field: 'name',
    headerName: 'Product Name',
    width: 300,
    minWidth: 300,
    sortable: true,
    filterable: true,
    renderCell: renderProductCell
  },
  {
    field: 'glanceViews',
    headerName: 'Glance Views',
    type: 'number',
    width: 130,
    minWidth: 80
  },
  {
    field: 'isBuyBoxWon',
    headerName: 'Buy Box Won',
    width: 130,
    type: 'boolean',
    renderCell: (params) =>
      params.value ? <Typography color="success.main">Yes</Typography> : <Typography color="error.main">No</Typography>
  },
  {
    field: 'buyBoxPercentage',
    headerName: 'Buy Box %',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `${params.value}%`
  },
  {
    field: 'lostBuyBoxPercent',
    headerName: 'LBB %',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `${params.value}%`
  },
  {
    field: 'averageSellingPrice',
    headerName: 'Average Selling Price',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params: any) => `$${params.value}`
  },
  {
    field: 'bsr',
    headerName: 'BSR',
    type: 'number',
    width: 130,
    minWidth: 80
  },
  {
    field: 'contentHealthScore',
    headerName: 'Content Health Score',
    type: 'number',
    width: 130,
    minWidth: 80
  },
  {
    field: 'hasAPlusContent',
    headerName: 'Has A+ Content',
    type: 'boolean',
    width: 130,
    minWidth: 80,
    renderCell: (params) => {
      return params.value ? (
        <Typography color="success.main">
          <TextIncreaseIcon />
        </Typography>
      ) : (
        <Typography color="error.main">-</Typography>
      );
    }
  },
  ...amazonMetricColumns,
  {
    field: 'percentOfTotalCost',
    headerName: '% of Total Cost',
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: 'totalAdvertisingCostOfSale',
    headerName: 'TACoS',
    description: 'Total Advertising Cost of Sale',
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: 'rating',
    headerName: 'Rating',
    type: 'number',
    width: 130,
    minWidth: 75,
    renderCell: (params) => {
      return (
        <Tooltip title={`Rating: ${params.value}`}>
          <Box>
            <Rating name="read-only" value={params.value} readOnly precision={0.5} sx={{ display: 'flex', fontSize: '10px' }} />
          </Box>
        </Tooltip>
      );
    }
  },
  {
    field: 'availability',
    headerName: 'Availability',
    width: 150,
    minWidth: 100,
    sortable: true,
    filterable: true,
    renderCell: (params) => {
      return (
        <Typography variant="body2" color={params.value === 'In Stock' ? 'success.main' : 'error.main'}>
          {params.value}
        </Typography>
      );
    }
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    width: 130,
    minWidth: 80,
    valueFormatter: (params) => `$${params.value}`
  },
  {
    field: 'buyBoxWinner',
    headerName: 'Buy Box Winner',
    width: 130,
    valueFormatter: (params) => `$${params.value}`
  },
  {
    field: 'orderedRevenue',
    headerName: 'Ordered Rev.',
    description: 'Ordered Revenue',
    width: 100,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'percentOfTotalOrderedRevenue',
    headerName: '% of Ordered Rev.',
    description: 'Percent of Total Ordered Revenue',
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${value}%`
  },
  {
    field: 'orderedUnits',
    headerName: 'Ordered Units',
    width: 100,
    type: 'number'
  },
  {
    field: 'shippedRevenue',
    headerName: 'Shipped Rev.',
    description: 'Shipped Revenue',
    width: 100,
    type: 'number',
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'shippedUnits',
    headerName: 'Shipped Units',
    width: 100,
    type: 'number'
  },
  {
    field: 'brand',
    headerName: 'Brand',
    width: 130,
    minWidth: 80
  },
  {
    field: 'subbrand',
    headerName: 'Sub Brand',
    width: 130,
    minWidth: 80
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 130,
    minWidth: 80
  },
  {
    field: 'subcategory',
    headerName: 'Sub Category',
    width: 130,
    minWidth: 80
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 150
  },
  {
    field: 'sellableOnHandUnits',
    headerName: 'Sellable Units',
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => value?.toLocaleString() || ''
  },
  {
    field: 'weeksOfSupplyOnHand',
    headerName: 'WOS OH',
    description: 'Weeks of Supply On Hand',
    minWidth: 150,
    type: 'number',
    valueFormatter: ({ value }) => value?.toLocaleString() || ''
  },
  {
    field: 'isPrime',
    headerName: 'Prime',
    width: 100,
    renderCell: (params) =>
      params.value ? <Typography color="primary.main">Prime</Typography> : <Typography>No Prime</Typography>
  }
];
