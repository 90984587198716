import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { List, ListSubheader } from '@mui/material';
import type { ListProps } from '@mui/material';
import { DashboardSidebarItem } from './dashboard-sidebar-item';

interface Item {
  path?: string;
  icon?: ReactNode;
  chip?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  target?: string;
  disabled?: boolean;
}

interface DashboardSidebarSectionProps extends ListProps {
  items: Item[];
  path: string;
  title: string;
  open: boolean | undefined;
}

const renderNavItems = ({
  depth = 0,
  items,
  path,
  open
}: {
  depth?: number;
  items: Item[];
  path: string;
  open?: boolean;
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      (accumulatorArray: JSX.Element[], item) => reduceChildRoutes({ accumulatorArray, depth, item, path, open }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  accumulatorArray,
  depth,
  item,
  path,
  open
}: {
  accumulatorArray: JSX.Element[];
  depth: number;
  item: Item;
  path: string;
  open?: boolean;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const partialMatch = item.path ? path.includes(item.path) : false;
  const exactMatch = path.split('?')[0] === `${item.path}`; // We don't compare query params

  if (item.children) {
    accumulatorArray.push(
      <DashboardSidebarItem
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        expanded={partialMatch}
        sidebarOpen={open}
        path={item.path}
        title={item.title}
        target={item.target}
        disabled={item.disabled}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          path
        })}
      </DashboardSidebarItem>
    );
  } else {
    accumulatorArray.push(
      <DashboardSidebarItem
        active={exactMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        sidebarOpen={open}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        target={item.target}
        disabled={item.disabled}
      />
    );
  }

  return accumulatorArray;
};

export const DashboardSidebarSection: FC<DashboardSidebarSectionProps> = (props) => {
  const { items, path, title, open, ...other } = props;

  return (
    <List
      subheader={
        open ? (
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: 'neutral.500',
              fontSize: '0.75rem',
              fontWeight: 700,
              lineHeight: 2.5,
              ml: 2,
              textTransform: 'uppercase'
            }}
          >
            {title}
          </ListSubheader>
        ) : (
          <div style={{ marginBottom: '30px' }}></div>
        )
      }
      {...other}
    >
      {renderNavItems({
        items,
        path,
        open
      })}
    </List>
  );
};

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
