import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import { MetricsTiles } from '../../../walmart/components/day-parting/metrics-tiles';
import { OutOfBudgetButton } from '../../../shared/components/day-parting/out-of-budget-button';
import { DaypartingUpdatedRecentlyAlert } from '../../../walmart/components/day-parting/dayparting-updated-recently-alert';
import { convertDaysToNumber, DaypartingSchedule, fullDaypartingSchedule } from './sams-club-dayparting-types';
import { HourlySchedulesContainer } from './sams-club-hourly-schedules-container';
import { DaypartingLegend } from './sams-club-dayparting-legend';
import { HourlyStatsChart } from '../../../walmart/components/day-parting/hourly-stats-chart';
import { useSnackbar } from 'notistack';

interface DaypartingProps {
  profileId: number;
  campaignId: number;
  campaignName: string;
  isOpen: boolean;
  toggle: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabIndex: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabIndex, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabIndex === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SamsClubDayparting(props: DaypartingProps) {
  const { profileId, campaignId, campaignName, isOpen, toggle } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const { getHourlySchedule, updateHourlySchedule, createHourlySchedule, deleteHourlySchedule, getDaypartingViewStats } =
    useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [daySchedules, setDaySchedules] = useState<DaypartingSchedule>({ ...fullDaypartingSchedule });
  const [stats, setStats] = useState<any>([]);
  const [monthlyBudget, setMonthlyBudget] = useState<number>(0);
  const [currentMonthSpend, setCurrentMonthSpend] = useState<number>(0);
  const [shouldShowOutOfBudgetHour, setShouldShowOutOfBudgetHour] = useState<boolean>(true);

  const [campaignHasDayparting, setCampaignHasDayparting] = useState<boolean>(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<Date | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const initialize = async () => {
    setIsLoading(true);

    await initializeViewStats();
    await initializeHourlySchedule();

    setIsLoading(false);
  };

  const initializeViewStats = async () => {
    const daypartingViewDataResponse = await getDaypartingViewStats(campaignId);

    if (daypartingViewDataResponse.success) {
      const viewDataDto = daypartingViewDataResponse.body;

      const statsWithParsedDate = sanitizeRealtimeStats(viewDataDto);
      setStats(statsWithParsedDate);
      setMonthlyBudget(viewDataDto?.currentMonthBudget ?? 0);
      setCurrentMonthSpend(viewDataDto?.currentMonthSpend ?? 0);
    } else {
      enqueueSnackbar('An error occurred loading the campaign stats', { variant: 'error' });
    }
  };

  const initializeHourlySchedule = async () => {
    const response = await getHourlySchedule(profileId, campaignId);

    if (response?.success) {
      if (Object.keys(response.body.schedule).length > 0) {
        setDaySchedules(convertDaysToNumber(response.body.schedule));
        setCampaignHasDayparting(true);

        if (response?.body?.lastUpdated) {
          updateLastUpdated(response);
        }
      }
    } else {
      enqueueSnackbar('An error occurred loading the dayparting schedule', { variant: 'error' });
    }
  };

  function updateLastUpdated(response: ResponseObject) {
    let date = new Date(response.body.lastUpdated);

    if (date.getFullYear() < 2020) {
      setLastUpdatedDate(null);
    } else {
      setLastUpdatedDate(date);
    }
  }

  function sanitizeRealtimeStats(viewDataDto: any) {
    return viewDataDto.last7DaysRealtimeStats.map((stat: any) => {
      return {
        ...stat,
        dailyOutOfBudgetDateTime: !!stat.dailyOutOfBudgetDateTime ? new Date(stat.dailyOutOfBudgetDateTime) : null,
        asOf: new Date(stat.asOf)
      };
    });
  }

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteSchedule = async () => {
    setIsLoading(true);

    const response = await deleteHourlySchedule(profileId, campaignId);

    if (response.success) {
      enqueueSnackbar('Dayparting schedule deleted', { variant: 'success' });
      setCampaignHasDayparting(false);
    } else {
      enqueueSnackbar('An error occurred deleting the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const updateSchedule = async () => {
    setIsLoading(true);

    const response = await updateHourlySchedule(profileId, campaignId, daySchedules);

    if (response.success) {
      enqueueSnackbar('Dayparting schedule updated', { variant: 'success' });
    } else {
      enqueueSnackbar('An error occurred updating the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const createSchedule = async () => {
    setIsLoading(true);

    const response = await createHourlySchedule(profileId, campaignId, daySchedules);

    if (response.success) {
      enqueueSnackbar('Dayparting schedule created', { variant: 'success' });
    } else {
      enqueueSnackbar('An error occurred creating the dayparting schedule', { variant: 'error' });
    }

    setIsLoading(false);
    toggle();
  };

  const saveSchedule = async () => {
    if (allHoursDisabled()) {
      deleteSchedule();
      return;
    }

    if (campaignHasDayparting) {
      await updateSchedule();
      return;
    }

    await createSchedule();
  };

  const allHoursDisabled = () => {
    return Object.values(daySchedules).every((daySchedule) => {
      return Object.values(daySchedule).every((hour) => {
        return !hour;
      });
    });
  };

  return (
    <>
      <Dialog onClose={toggle} maxWidth={'lg'} open={isOpen} onMouseUp={() => setIsDragging(false)}>
        <DialogTitle>
          <Box display="flex" alignItems={'center'}>
            <Typography variant={'h6'}>Dayparting Schedule ({campaignName})</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mr: 2 }}>
          <DialogContentText sx={{ mb: 2 }}>
            <Box display="flex" justifyContent={'space-between'}>
              <Typography variant={'body2'}>All hours will be interpreted as CST.</Typography>
              {lastUpdatedDate && (
                <Typography variant={'body2'}>Schedule Last Updated: {lastUpdatedDate?.toLocaleDateString()}</Typography>
              )}
            </Box>
          </DialogContentText>
          <Tabs value={tabIndex} onChange={(event: React.SyntheticEvent, newValue: number) => setTabIndex(newValue)}>
            <Tab label="Dayparting Schedule" />
            <Tab label="Hourly Metrics" />
          </Tabs>
          <TabPanel tabIndex={tabIndex} index={0}>
            <MetricsTiles
              isLoading={isLoading}
              currentMonthSpend={currentMonthSpend}
              monthlyBudget={monthlyBudget}
              stats={stats}
            />
            <OutOfBudgetButton
              shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour}
              setShouldShowOutOfBudgetHour={setShouldShowOutOfBudgetHour}
            />
            {lastUpdatedDate && <DaypartingUpdatedRecentlyAlert lastUpdated={lastUpdatedDate} />}
            <HourlySchedulesContainer
              daySchedules={daySchedules}
              setDaySchedules={setDaySchedules}
              shouldShowOutOfBudgetHour={shouldShowOutOfBudgetHour}
              isDragging={isDragging}
              isLoading={isLoading}
              setIsDragging={setIsDragging}
              stats={stats}
            />
            <DaypartingLegend />
          </TabPanel>
          {tabIndex === 1 && (
            <Box sx={{ minWidth: '900px' }}>
              <TabPanel tabIndex={tabIndex} index={1}>
                <HourlyStatsChart campaignId={campaignId} />
              </TabPanel>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={toggle}>
            Cancel
          </Button>
          <Button variant={'contained'} onClick={saveSchedule}>
            Save Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
