import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../../shared/components/page';
import ProfileSelect from '../../../shared/components/page-components/profile-select';
import PerformanceGrid from '../../../shared/components/performance-grid/performance-grid';
import useDialog from '../../../shared/hooks/use-dialog';
import { useGridConfig } from '../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../shared/hooks/use-pageable';
import { DEFAULT_ROW_COUNT } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import useAmazonApi from '../../hooks/use-amazon-api';
import {
  BidOptimizationGroupCreateDto,
  BidOptimizationGroupDto,
  BidOptimizationGroupStatus
} from '../../types/bid-optimization-group';
import { BID_GROUP_COLUMNS } from './bid-group-columns';
import CreateBidGroupDialog from './create-bid-group-dialog';

export interface BidOptimizationGroupRow {
  id: string;
  profileId: string;
  name: string;
  status: BidOptimizationGroupStatus;
  acosTarget: number;
  campaignIds: string[];
}

const BidOptimizationGroups: FC = () => {
  const { getBidOptimizationGroups, getProfilesWithoutMetrics, updateBidOptimizationGroup, createBidOptimizationGroup } =
    useAmazonApi();
  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId');
  const { pageable, setPageable, handleFilterModelChange, handleSortModelChange, handlePageChange, handlePageSizeChange } =
    usePageable();
  const [bidOptimizationGroups, setBidOptimizationGroups] = useState<BidOptimizationGroupRow[]>([]);
  const { toggle: toggleCreateBidGroupDialog, isShowing: createBidGroupDialogIsShowing, dynamicKey } = useDialog();
  const [rowCount, setRowCount] = useState<number>(DEFAULT_ROW_COUNT);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_CAMPAIGN_BID_OPTIMIZATION_GROUPS,
    breadCrumbParams: {}
  });

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBidOptimizationGroups = useCallback(async () => {
    if (!profileId || !pageable) {
      return;
    }

    setIsLoading(true);

    const response = await getBidOptimizationGroups(profileId, pageable);

    if (response.success) {
      setBidOptimizationGroups(response.body?.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  }, [profileId, pageable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!profileId || !pageable || settingsLoading) {
      return;
    }

    fetchBidOptimizationGroups();
  }, [profileId, pageable]); // eslint-disable-line react-hooks/exhaustive-deps

  const processRowUpdate = useCallback(async (newRow: BidOptimizationGroupRow, oldRow: BidOptimizationGroupRow) => {
    if (!profileId) {
      return oldRow;
    }

    const bidOptimizationGroupDto: BidOptimizationGroupDto = {
      id: newRow.id,
      profileId: profileId,
      name: newRow.name,
      status: newRow.status,
      acosTarget: newRow.acosTarget,
      campaignIds: [...newRow.campaignIds]
    };

    const response = await updateBidOptimizationGroup(bidOptimizationGroupDto);

    if (response.success) {
      enqueueSnackbar('Bid Optimization Group updated successfully', { variant: 'success' });
      return response.body;
    }

    enqueueSnackbar(response.errorMessage, { variant: 'error' });
    return oldRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateBidGroup = async (bidOptimizationGroupCreateDto: BidOptimizationGroupCreateDto) => {
    if (!profileId) {
      return;
    }

    const response = await createBidOptimizationGroup(bidOptimizationGroupCreateDto);

    if (response.success) {
      enqueueSnackbar('Bid Optimization Group created successfully', { variant: 'success' });
      toggleCreateBidGroupDialog();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  return (
    <Page title="Bid Optimization Groups">
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h5" mr={2}>
              Bid Optimization Groups
            </Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Box>
              <Button variant="contained" color="primary" onClick={toggleCreateBidGroupDialog}>
                Create Bid Group
              </Button>
            </Box>
          </Box>
          <ProfileSelect profileId={profileId} getProfiles={getProfilesWithoutMetrics} />
          <Box height={'100%'}>
            {!initialSettings || settingsLoading ? (
              <DataGridPremium
                rows={[]}
                columns={BID_GROUP_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
                disableRowGrouping
              />
            ) : (
              <PerformanceGrid
                loading={isLoading}
                rows={bidOptimizationGroups}
                columns={BID_GROUP_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                processRowUpdate={processRowUpdate}
                bottomMargin={30}
                allowQuickFilterSearch={false}
                getRowId={(row) => row.id}
                getRowClassName={(params) => `row-status--${params.row.status}`}
                disableGridDateRangePicker
                handleFilterModelChange={handleFilterModelChange}
                handleSortModelChange={handleSortModelChange}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                rowCount={rowCount}
              />
            )}
          </Box>
        </Stack>
      </Container>
      {createBidGroupDialogIsShowing && (
        <CreateBidGroupDialog
          isShowing={createBidGroupDialogIsShowing}
          toggle={toggleCreateBidGroupDialog}
          dynamicKey={dynamicKey}
          handleCreateBidGroup={handleCreateBidGroup}
          refreshEntities={fetchBidOptimizationGroups}
        />
      )}
    </Page>
  );
};

export default BidOptimizationGroups;
