import { FC, useState } from 'react';
import { Box, Card, CardContent, TextField, Collapse, MenuItem, Typography } from '@mui/material';
import { CampaignBuilderStepLabelButton } from '../../../../sams-club/pages/campaigns/create-campaign-form/step-label-button';
import CustomDataSelector from './customDataSelector';

interface FilterCriteria {
  column: string;
  comparator: string;
  value: string;
}

interface CampaignTypeStepProps {
  setEntityType: React.Dispatch<React.SetStateAction<string>>;
  setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria[]>>;
  filterCriteria: FilterCriteria[];
  entityType: string;
}

export const FilterCriteriaStep: FC<CampaignTypeStepProps> = (props) => {
  const { setEntityType, setFilterCriteria, filterCriteria, entityType, ...other } = props;
  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const entityTypes = ['Target', 'Campaign', 'AdGroup', 'ProductAd'];

  return (
    <Box>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={1}
        stepLabel="Filter Criteria"
      />
      <Collapse in={showStep}>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Box>
              <TextField
                id="Entities"
                label="Please select the entity *"
                select
                value={entityType}
                sx={{ width: '40%', bottom: 10 }}
                onChange={(event) => {
                  setEntityType(event.target.value);
                }}
              >
                {entityTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              {entityType && (
                <Box>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Add Filter Criteria:
                  </Typography>
                  <CustomDataSelector
                    setFilterCriteria={setFilterCriteria}
                    filterCriteria={filterCriteria}
                    entityType={entityType}
                  />
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};
