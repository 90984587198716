import { GridAggregationFunction } from '@mui/x-data-grid-premium';

/*
 * MUI's default sum and avg aggregation functions include a label in the header, which we don't want.
 * This file contains custom sum and avg aggregation functions that don't include a label in the header.
 *
 * These aggregation functions can't replace our usual way of fetching the totals row from the backend,
 * because they can't account for pagination. However, using these is preferable if the data grid doesn't
 * have pagination.
 */

export const sumNoLabel: GridAggregationFunction = {
  apply: (params) => {
    if (!params.values || params.values.length === 0) {
      return null;
    }

    const sum = params.values.reduce((acc, value) => {
      if (value) {
        return acc + value;
      }
      return acc;
    }, 0);

    return sum;
  },
  label: '',
  columnTypes: ['number']
};

export const avgNoLabel: GridAggregationFunction = {
  apply: (params) => {
    if (!params.values || params.values.length === 0) {
      return null;
    }

    const sum = params.values.reduce((acc, value) => {
      if (value) {
        return acc + value;
      }
      return acc;
    }, 0);

    const avg = sum / params.values.length;
    return avg;
  },
  label: '',
  columnTypes: ['number']
};
