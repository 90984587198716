import { colors } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

const styles = {
  chartContainer: {
    height: '350',
    scrollBehavior: 'smooth'
  },
  tilesBoxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    justifyContent: 'center',
    gap: '0.5rem',
    padding: '0.5rem',
    minWidth: '800px'
  },
  metricBox: {
    borderRadius: '2px',
    paddingX: '0.8rem',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.6)',
    width: '30%',
    cursor: 'pointer'
  },
  metricBoxHidden: {
    borderTop: 'none',
    paddingTop: '3px',
    borderColor: colors.grey[500]
  },
  metricBoxSelected: {
    borderTop: '3px solid',
    borderRadius: '2px'
  },
  metricBoxTitle: {
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    p: 0.5
  },
  metricDropdown: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ':hover': {
      backgroundColor: (palette: { mode: string }) => (palette.mode === 'dark' ? colors.grey[50] : colors.grey[800])
    },
    borderRadius: '0.3rem',
    my: 0.5
  },
  loadingSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: zIndex.tooltip
  }
};

export default styles;
