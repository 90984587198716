import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Skeleton } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';
import CampaignBiddingStrategy from '../../../components/campaign-bidding-strategy';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { Campaign as AmazonCampaign, BiddingStrategy, UpdateCampaignRequest } from '../../../types/campaign';

interface CampaignOverviewBiddingDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
  campaign: AmazonCampaign;
  setCampaign: (campaign: any) => void;
  updateCampaign: (updatedCampaignRequest: any) => Promise<ResponseObject>;
  refreshPlacementPerformance?: () => void;
}

const CampaignOverviewBiddingDialog = (props: CampaignOverviewBiddingDialogProps) => {
  const { isDialogOpen, setIsDialogOpen, campaign, setCampaign, updateCampaign, refreshPlacementPerformance } = props;
  const { getCampaign } = useAmazonApi();
  const [searchParams] = useSearchParams();

  const [campaignStrategy, setCampaignStrategy] = useState<BiddingStrategy | null>(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const campaignId = searchParams.get('campaignId');

  const handleUpdateCampaign = async (updatedCampaignRequest: UpdateCampaignRequest) => {
    setIsUpdateLoading(true);

    const response = await updateCampaign(updatedCampaignRequest);

    if (response.success) {
      setCampaign(response?.body[0]);
      enqueueSnackbar('Campaign updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsUpdateLoading(false);
  };

  const fetchCampaign = async () => {
    if (!campaignId) {
      return;
    }

    setIsInitialLoading(true);

    const response = await getCampaign(campaignId);

    if (response.success) {
      setCampaign(response?.body);
      setCampaignStrategy(response?.body?.amazonSponsoredProductsDynamicBidding?.strategy);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsInitialLoading(false);
  };

  useEffect(() => {
    if (!campaignId) {
      return;
    }

    fetchCampaign();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDialogOpen) {
      refreshPlacementPerformance?.();
    }
  }, [isDialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Dialog open={isDialogOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>Dynamic Bidding Settings</DialogTitle>
        <DialogContent>
          {isUpdateLoading ? <LinearProgress /> : <div style={{ height: '4px' }} />}
          {isInitialLoading ? (
            <Skeleton width={'100%'} height={230} />
          ) : (
            <CampaignBiddingStrategy
              campaign={campaign}
              setCampaign={setCampaign}
              handleUpdateCampaign={handleUpdateCampaign}
              bidStrategy={campaignStrategy}
              setBidStrategy={setCampaignStrategy}
              hideHeader
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignOverviewBiddingDialog;
