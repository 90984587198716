import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Link, Menu, MenuItem, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import RenameDialog from '../../../../../shared/components/rename-dialog';
import useDialog from '../../../../../shared/hooks/use-dialog';
import { PlatformValue } from '../../../../../shared/types/platforms';
import { ExtendedGridRenderCellParams } from '../../../../../shared/types/render-cell-types';
import useAdsApi from '../../../../../shared/hooks/use-walmart-sams-club-api';

export const renderProfileNameWithActions = (retailerPlatform: PlatformValue) => (params: GridRenderCellParams) => {
  return <ProfileNameActionCell retailerPlatform={retailerPlatform} {...params} />;
};

export const ProfileNameActionCell = (params: ExtendedGridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { toggle: toggleRenameDialog, isShowing: renameDialogIsShowing } = useDialog();
  const { updateProfile } = useAdsApi();

  const handleActionMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuAnchorEl(actionMenuAnchorEl ? null : event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
    setIsHovering(false);
  };

  const handleRenameButtonClick = () => {
    toggleRenameDialog();
    handleActionMenuClose();
  };

  const open = Boolean(actionMenuAnchorEl);

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        width: '100%',
        height: '100%',
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }}
    >
      <Link
        to={`/${params.retailerPlatform}/campaigns?profileId=${params.id}`}
        sx={{ textDecoration: 'none', color: 'text.primary' }}
        component={RouterLink}
      >
        {params.value}
      </Link>
      {isHovering ? (
        <>
          {' '}
          <Box>
            <Tooltip title="Actions" placement="top">
              <IconButton onClick={handleActionMenuIconClick} size="small" color="primary">
                <MoreVertIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            id="action-menu"
            anchorEl={actionMenuAnchorEl}
            open={open}
            onClose={handleActionMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={handleRenameButtonClick}>Rename</MenuItem>
            <MenuItem
              onClick={() => {
                handleActionMenuClose();
              }}
              key="sbReviewStatusButton"
            >
              <Link
                to={`/${params.retailerPlatform}/media?profileId=${params.row.profileId}`}
                sx={{
                  textDecoration: 'none',
                  color: 'text.primary'
                }}
                component={RouterLink}
              >
                Manage Video Media
              </Link>
            </MenuItem>
          </Menu>
        </>
      ) : null}
      {renameDialogIsShowing && (
        <RenameDialog
          isOpen={renameDialogIsShowing}
          toggleRenameDialog={toggleRenameDialog}
          entityType="Profile"
          row={params.row}
          entityNameKey="name"
          currentEntityName={params.value}
          updateEntityFunction={updateProfile}
          apiRef={apiRef}
        />
      )}
    </Box>
  );
};
