import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { useSnackbar } from 'notistack';
import AmazonAddNegativeTargetingClauses from './amazon-add-negative-targeting-clauses';

interface CreateNegativeTargetingClausesDialogProps {
  open: boolean;
  toggle: () => void;
  refreshNegativeTargets?: () => void;
  isCampaignNegativeTarget: boolean;
}

const CreateNegativeTargetingClausesDialog: React.FC<CreateNegativeTargetingClausesDialogProps> = ({
  open,
  toggle,
  refreshNegativeTargets,
  isCampaignNegativeTarget
}) => {
  const [negativeTargetingClauses, setNegativeTargetingClauses] = useState<any[]>([]);

  const { createNegativeTargets, createCampaignNegativeTargets } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (negativeTargetingClauses && negativeTargetingClauses.length > 0) {
        let response;
        if (isCampaignNegativeTarget) {
          response = await createCampaignNegativeTargets(negativeTargetingClauses);
        } else {
          response = await createNegativeTargets(negativeTargetingClauses);
        }

        if (response.success) {
          enqueueSnackbar(`Negative Keywords created successfully!`, { variant: 'success' });
          refreshNegativeTargets?.();
        } else {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(`Error occurred: ${error?.message}`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      toggle();
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Container maxWidth="md">
      <Dialog open={open} disablePortal fullWidth maxWidth="lg" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <DialogTitle>Negative Product Targeting</DialogTitle>
        <DialogContent>
          <Box>
            <AmazonAddNegativeTargetingClauses
              negativeTargetingClauses={negativeTargetingClauses}
              isCampaignNegativeTarget={isCampaignNegativeTarget}
              setNegativeTargetingClauses={(negativeTargetingClauses): void => {
                setNegativeTargetingClauses(negativeTargetingClauses);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Add Negative Targets
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default CreateNegativeTargetingClausesDialog;
