import { Box, Button, Card, CardActionArea, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Page from '../../../../shared/components/page';
import { amazonRoutes } from '../../../../shared/routing/routes-objects';

type CampaignType = {
  campaignKey: string;
  title: string;
  description: string;
  imageUrl: string;
  to: string;
};

const getCampaignTypes = (): CampaignType[] => [
  {
    campaignKey: 'SP',
    title: 'Sponsored Products',
    description: 'Promote your products with targeted ads to boost visibility and sales.',
    imageUrl: 'https://example.com/sponsored-product.jpg',
    to: amazonRoutes.find((route) => route.key === 'amazon-campaign-builder-sponsored-products')?.path || ''
  },
  {
    campaignKey: 'SB',
    title: 'Sponsored Brands',
    description: 'Advertise your brand and product portfolio to increase brand awareness.',
    imageUrl: 'https://example.com/sponsored-brands.jpg',
    to: ''
  },
  {
    campaignKey: 'SBV',
    title: 'Sponsored Brands Video',
    description: 'Engage customers with video ads to showcase your products and brand story.',
    imageUrl: 'https://example.com/sponsored-brands-video.jpg',
    to: ''
  },
  {
    campaignKey: 'SD',
    title: 'Sponsored Display',
    description: 'Reach customers across the web with personalized display ads.',
    imageUrl: 'https://example.com/sponsored-display.jpg',
    to: ''
  }
];

const CampaignTypeCard: React.FC<CampaignType> = ({ campaignKey, title, description, to }) => {
  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ maxWidth: 345, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardActionArea disabled={campaignKey !== 'SP'} sx={{ flexGrow: 1 }}>
          <CardContent sx={{ flexGrow: 1 }}>
            <Box>
              <Typography gutterBottom variant="h5" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          {campaignKey === 'SP' ? (
            <Link to={`${to}${profileId ? `?profileId=${profileId}` : ''}`} style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary">
                Continue
              </Button>
            </Link>
          ) : (
            <Button variant="contained" color="primary" disabled>
              Continue
            </Button>
          )}
        </Box>
      </Card>
    </Grid>
  );
};

const CampaignTypePicker: React.FC = () => {
  return (
    <Page title="Campaign Builder">
      <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Typography variant="h4">Campaign Builder</Typography>
          <Grid container spacing={2}>
            {getCampaignTypes().map((type, index) => (
              <CampaignTypeCard key={type.campaignKey} {...type} />
            ))}
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
};

export default CampaignTypePicker;
