import { Button } from '@mui/material';
import useDialog from '../../../shared/hooks/use-dialog';
import { AddCampaignsToBidGroupWithGridDialog } from './add-campaigns-to-bid-group-with-grid-dialog';

interface AddCampaignsToBidGroupActionsProps {
  refreshEntities: () => void;
}

export const AddCampaignsToBidGroupActions = ({ refreshEntities }: AddCampaignsToBidGroupActionsProps) => {
  const { toggle: toggleAddCampaignsDialog, isShowing: isAddCampaignsDialogShowing, dynamicKey } = useDialog();

  return (
    <>
      <Button onClick={toggleAddCampaignsDialog}>Add Campaigns</Button>
      {isAddCampaignsDialogShowing && (
        <AddCampaignsToBidGroupWithGridDialog
          toggle={toggleAddCampaignsDialog}
          isShowing={isAddCampaignsDialogShowing}
          dynamicKey={dynamicKey}
          refreshCampaigns={refreshEntities}
        />
      )}
    </>
  );
};
