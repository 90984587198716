import { Chip, Typography } from '@mui/material';
import { GridColDef, GridEditInputCell, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import { BidOptimizationGroupStatus } from '../../types/bid-optimization-group';
import { renderStatus } from './bid-group-render-status';
import { BidGroupNameActionCell } from './render-bid-group-name';

export const BID_GROUP_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => <BidGroupNameActionCell {...params} />,
    disableColumnMenu: true
  },
  {
    field: 'status',
    headerName: 'Status',
    description:
      'If enabled, the bid group will optimize bids for the targets of campaigns daily in order to hit the ACoS target. If disabled, the bid group will not optimize bids.',
    valueOptions: [BidOptimizationGroupStatus.Enabled, BidOptimizationGroupStatus.Disabled],
    minWidth: 90,
    renderCell: renderStatus,
    disableColumnMenu: true
  },
  {
    field: 'acosTarget',
    headerName: 'ACoS Target',
    description: 'The target ACoS the bid group aims to optimize campaign bids to achieve',
    minWidth: 110,
    align: 'right',
    valueFormatter: (params) => `${params.value}%`,
    editable: true,
    cellClassName: 'numeric-edit-cell',
    disableColumnMenu: true,
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <GridEditInputCell
          {...params}
          sx={{
            border: 1,
            borderColor: 'success.main',
            borderRadius: '4px'
          }}
          inputProps={{ style: { textAlign: 'right' } }}
        />
      );
    }
  },
  {
    field: 'acosMonthToDate',
    headerName: 'ACoS MTD',
    description: 'The ACoS for the bid group for the current month to date',
    minWidth: 100,
    align: 'right',
    valueFormatter: (params) => (params.value ? `${params.value}%` : null),
    disableColumnMenu: true
  },
  {
    field: 'acosMonthToDateDelta',
    headerName: '𝚫%',
    description: '(ACoS Target - ACoS MTD) / ACoS Target',
    minWidth: 100,
    align: 'right',
    renderCell: (params: GridRenderCellParams) => {
      const { value } = params;

      if (value === null || value === undefined) {
        return null;
      }

      return (
        <Typography variant="body2" color={value >= 20 || value <= -20 ? 'error' : 'primary'}>{`${
          value > 0 ? '+' : ''
        }${value}%`}</Typography>
      );
    },
    disableColumnMenu: true
  },
  {
    field: 'acosLastThreeDays',
    headerName: 'ACoS L3D',
    description: 'The ACoS for the bid group for the last 3 days',
    minWidth: 100,
    align: 'right',
    valueFormatter: (params) => (params.value ? `${params.value}%` : null),
    disableColumnMenu: true
  },
  {
    field: 'lastBidOptimizationAt',
    headerName: 'Last Optimization Run',
    description: 'The date and time the last optimization ran',
    type: 'string',
    minWidth: 150,
    valueFormatter: ({ value }) => (value ? format(new Date(value), 'MM/dd/yyyy hh:mm a') : ''),
    disableColumnMenu: true
  },
  {
    field: 'lastBidOptimizationStatus',
    headerName: 'Last Optimization Status',
    description: 'The status of the last optimization run',
    type: 'string',
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const status = params.value;

      if (!status) {
        return null;
      }

      return <Chip variant="outlined" label={status} color={status === 'Success' ? 'primary' : 'error'} />;
    },
    disableColumnMenu: true
  },
  {
    field: 'updatedTargetsCount',
    headerName: 'Targets Updated',
    description: 'Number of targets updated when the last optimization ran',
    type: 'number',
    minWidth: 150,
    disableColumnMenu: true
  },
  { field: 'id', headerName: 'ID', minWidth: 100, disableColumnMenu: true }
];
