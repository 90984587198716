import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { KeywordApplyConfirmationDialogProps } from '../../types/keyword-harvesting';

function KeywordApplyConfirmationDialog(props: KeywordApplyConfirmationDialogProps) {
  const { isOpen, onClose, onApply, profileId, campaignId } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleApply = () => {
    onApply();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Apply Harvested Keywords</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to apply the harvested keywords to the campaign?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={() => {
            handleApply();
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default KeywordApplyConfirmationDialog;
