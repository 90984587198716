import { FC } from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

export interface MediaVideoPlayerModalProps {
  videoUrl: string | undefined;
  onClose: () => void;
  open: boolean;
}

export const MediaVideoPlayerModal: FC<MediaVideoPlayerModalProps> = (props) => {
  const { videoUrl, onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={'md'}>
      <DialogTitle>Sponsored Video</DialogTitle>
      <DialogContent>
        <video width="100%" height="100%" controls preload="auto">
          <source src={videoUrl} type="video/mp4" />
        </video>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
