import { Card, CardContent, Collapse, Grid, Typography } from '@mui/material';
import { CustomTypeOptionRadioButton } from '../../../../shared/components/custom-type-option-radio-button';
import { CampaignMode, TargetingMode } from '../../../types/campaign-builder-request';

interface TargetingSettingsProps {
  campaignMode: CampaignMode;
  setCampaignMode: (campaignMode: CampaignMode) => void;
  targetingMode: TargetingMode;
  setTargetingMode: (targetingMode: TargetingMode) => void;
}

export const TargetingSettings = (props: TargetingSettingsProps) => {
  const { campaignMode, setCampaignMode, targetingMode, setTargetingMode } = props;

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Targeting Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <CustomTypeOptionRadioButton
              typeOptionValue={'auto'}
              typeOptionLabel={'Automatic'}
              checked={campaignMode === CampaignMode.AUTO}
              handleClick={(): void => setCampaignMode(CampaignMode.AUTO)}
              sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTypeOptionRadioButton
              typeOptionValue={'manual'}
              typeOptionLabel={'Manual'}
              checked={campaignMode === CampaignMode.MANUAL}
              handleClick={(): void => setCampaignMode(CampaignMode.MANUAL)}
              sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
            />
          </Grid>
        </Grid>
        <Collapse in={campaignMode === CampaignMode.MANUAL} sx={{ mt: 2 }}>
          <Typography gutterBottom variant="body1" component="div">
            Manual Targeting Type
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomTypeOptionRadioButton
                typeOptionValue={'keyword'}
                typeOptionLabel={'Keyword'}
                checked={targetingMode === TargetingMode.KEYWORD}
                handleClick={(): void => setTargetingMode(TargetingMode.KEYWORD)}
                sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTypeOptionRadioButton
                typeOptionValue={'product'}
                typeOptionLabel={'Product Targeting'}
                checked={targetingMode === TargetingMode.PRODUCT}
                handleClick={(): void => setTargetingMode(TargetingMode.PRODUCT)}
                sx={{ mb: 2, mt: 2 }}
              />
            </Grid>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
};
