import { Month } from '../utilities/date-utilities';

export interface MonthlyBudget {
  [Month.January]: number;
  [Month.February]: number;
  [Month.March]: number;
  [Month.April]: number;
  [Month.May]: number;
  [Month.June]: number;
  [Month.July]: number;
  [Month.August]: number;
  [Month.September]: number;
  [Month.October]: number;
  [Month.November]: number;
  [Month.December]: number;
}

export interface UpdateProfileMonthlyBudgetRequest extends MonthlyBudget {
  profileId: number | string;
  year: number;
}

export interface CampaignBudget {
  campaignId: number;
  year: number;
  [key: string]: number; // Index signature
}

export const listOfMonths: Month[] = Object.values(Month);

export const defaultMonthAmounts = {
  january: 0,
  february: 0,
  march: 0,
  april: 0,
  may: 0,
  june: 0,
  july: 0,
  august: 0,
  september: 0,
  october: 0,
  november: 0,
  december: 0
};

export const getPreciseFloatingPointSum = (values: any[], numberOfDecimals?: number) => {
  let result = 0.0;

  if (!numberOfDecimals) {
    numberOfDecimals = 2;
  }

  values.forEach((value) => {
    result = parseFloat((result + value).toFixed(numberOfDecimals));
  });

  return result;
};

export const getDisplayPercent = (value: number, total: number) => {
  if (value === 0 || total === 0) return 0;

  return Number(((value / total) * 100 - 100).toFixed(2));
};

export const getPacingPercent = (value: number, total: number) => {
  if (value === 0 || total === 0) return 100;
  return Number(((value / total) * 100).toFixed(2));
};

export const getPercent = (value: number, total: number) => {
  if (value === 0 || total === 0) return 0;
  return Number(((value / total) * 100).toFixed(2));
};

export interface UpdateCampaignMonthlyBudgetRequest {
  campaignBudgets: CampaignBudget[];
}

export function daysInThisMonth() {
  var now = new Date();

  // return 0th day of the next month, which is interpreted as the last day of the current month.
  return Number(new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate());
}

export function daysElapsed() {
  var now = new Date();
  return now.getDate();
}

export function calculateDailyBudget(monthlyBudget: number, currentMonthSpend: number) {
  return Number((monthlyBudget - currentMonthSpend) / (daysInThisMonth() - daysElapsed() + 1));
}

export const MIN_DAILY_BUDGET = 50;
