import Badge from '@mui/material/Badge';
import { unstable_composeClasses as composeClasses, unstable_useId as useId } from '@mui/utils';
import {
  GridPreferencePanelsValue,
  getDataGridUtilityClass,
  gridPreferencePanelStateSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector
} from '@mui/x-data-grid-premium';
import PropTypes from 'prop-types';
import * as React from 'react';
import { GridIconButtonContainer } from './custom-grid-icon-button-container';

const useUtilityClasses = (ownerState: any) => {
  const { classes } = ownerState;
  const slots = {
    icon: ['filterIcon']
  };
  return composeClasses(slots, getDataGridUtilityClass, classes);
};

function CustomGridColumnHeaderFilterIconButton(props: any) {
  const { counter: counterFromProps, field, onClick } = props;
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const ownerState = { ...props, classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const preferencePanel = useGridSelector(apiRef, gridPreferencePanelStateSelector);
  const labelId = useId();
  const panelId = useId();

  const counter = counterFromProps || 0;
  const columnHeaderParams = apiRef.current.getColumnHeaderParams(field);
  const columnIsFilterable = columnHeaderParams.colDef.filterable;

  const toggleFilter = React.useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      const { open, openedPanelValue } = gridPreferencePanelStateSelector(apiRef.current.state);
      if (open && openedPanelValue === GridPreferencePanelsValue.filters) {
        apiRef.current.hideFilterPanel();
      } else {
        apiRef.current.showFilterPanel(field, panelId, labelId);
      }
      if (onClick) {
        onClick(apiRef.current.getColumnHeaderParams(field), event);
      }
    },
    [apiRef, field, onClick, panelId, labelId]
  );

  if (!columnIsFilterable) {
    return null;
  }

  const open = preferencePanel.open && preferencePanel.labelId === labelId;

  const iconButton = (
    <rootProps.slots.baseIconButton
      counter={counter}
      id={labelId}
      onClick={toggleFilter}
      color={'default'}
      aria-label={apiRef.current.getLocaleText('columnHeaderFiltersLabel')}
      size="small"
      tabIndex={-1}
      aria-haspopup="menu"
      aria-expanded={open}
      aria-controls={open ? panelId : undefined}
      {...rootProps.slotProps?.baseIconButton}
    >
      <rootProps.slots.columnFilteredIcon className={classes.icon} color={'default'} fontSize="small" />
    </rootProps.slots.baseIconButton>
  );

  return (
    <rootProps.slots.baseTooltip
      title={apiRef.current.getLocaleText('columnHeaderFiltersTooltipActive')(counter)}
      enterDelay={1000}
      {...rootProps.slotProps?.baseTooltip}
    >
      <GridIconButtonContainer>
        <Badge badgeContent={counter} color="default">
          {iconButton}
        </Badge>
      </GridIconButtonContainer>
    </rootProps.slots.baseTooltip>
  );
}

if (process.env.NODE_ENV !== 'production') {
  CustomGridColumnHeaderFilterIconButton.propTypes = {
    counter: PropTypes.number,
    field: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };
}

export { CustomGridColumnHeaderFilterIconButton };
