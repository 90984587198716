import { getGridSingleSelectOperators, getGridStringOperators, GridColDef } from '@mui/x-data-grid-premium';
import { getMatchType } from '../../../types/campaign';
import { METRIC_COLUMNS } from './metric-columns';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';

export const KEYWORD_COLUMNS: GridColDef[] = [
  {
    field: 'campaignId',
    headerName: 'Campaign Id',
    width: 150,
    type: 'string'
  },
  {
    field: 'searchTerm',
    headerName: 'Keyword',
    width: 150,
    type: 'string'
  },
  {
    field: 'matchType',
    headerName: 'Match Type',
    width: 150,
    editable: false,
    align: 'left',
    valueFormatter: ({ value }) => getMatchType(value) || '--'
  },
  {
    field: 'suggested',
    headerName: 'Suggested By CitrusAd',
    width: 150,
    editable: false,
    align: 'left'
  },
  {
    field: 'treatAsCustom',
    headerName: 'Treat As Custom',
    width: 150,
    editable: false,
    align: 'left'
  },
  {
    field: 'maxBid',
    headerName: 'Bid Amount',
    width: 150,
    editable: true,
    align: 'left',
    valueFormatter: renderAsCurrencyFormatter
  },

  ...METRIC_COLUMNS
];
