import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useEffect, useState } from 'react';
import useSharedApi from './use-shared-api';
import { IGridConfigSettingItem } from '../utilities/grid-config-settings';
import {
  DEFAULT_ROW_COUNT,
  Filter,
  Pageable,
  Sort,
  buildPageableFilters,
  convertGridFilterModelToCustomFilters,
  processSortsFromGrid
} from '../types/pageable';

export const useGridConfig = (props: { setting: IGridConfigSettingItem; breadCrumbParams: any }) => {
  const { setting } = props;
  const [settingsLoading, setSettingsLoading] = useState(true);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const { getUserSetting, updateUserSetting } = useSharedApi();

  useEffect(() => {
    initializeSettings({ breadcrumbParams: props.breadCrumbParams });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchGridConfig = async () => {
    let loadedGridConfig: GridInitialStatePremium;

    const response = await getUserSetting(setting.SettingsKey);

    if (response.success && response.body && typeof response.body === 'string') {
      const gridConfig = JSON.parse(response.body);
      loadedGridConfig = gridConfig;
    } else {
      loadedGridConfig = setting.DefaultSetting;
    }

    return loadedGridConfig;
  };

  const saveGridConfig = async (gridConfig: GridInitialStatePremium) => {
    const response = await updateUserSetting(setting.SettingsKey, JSON.stringify(gridConfig));

    return response;
  };

  const initializeSettings = (props: { breadcrumbParams: any[] }) => {
    fetchGridConfig().then((loadedGridConfig) => {
      let updatedFilters: Filter[] = [];
      let updatedSorts: Sort[] = [];

      if (loadedGridConfig?.filter?.filterModel) {
        const filtersFromConfig: Filter[] = convertGridFilterModelToCustomFilters(loadedGridConfig.filter.filterModel);

        updatedFilters = updatedFilters.concat(filtersFromConfig);
      }

      if (loadedGridConfig?.sorting?.sortModel) {
        const sortsFromConfig: Sort[] = processSortsFromGrid(loadedGridConfig.sorting.sortModel);

        updatedSorts = updatedSorts.concat(sortsFromConfig);
      }

      if (props.breadcrumbParams) {
        updatedFilters = updatedFilters.concat(buildPageableFilters({ ...props.breadcrumbParams }));
      }

      const initialPageable: Pageable = {
        filters: updatedFilters,
        sorts: updatedSorts,
        limit: loadedGridConfig?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
        offset: loadedGridConfig?.pagination?.paginationModel?.page || 0
      };

      setInitialSettings({
        pageable: initialPageable,
        config: loadedGridConfig
      });

      setSettingsLoading(false);
    });
  };

  return {
    initialSettings,
    settingsLoading,
    saveGridConfig
  };
};
