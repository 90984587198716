import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  LinearProgress,
  Link,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAdsApi } from '../../hooks/use-walmart-sams-club-api';
import { SBProfileBuilder } from '../../../walmart/pages/campaigns/create-campaign-form/campaign-settings-inputs';
import { ReviewStatusChip } from '../sponsored-video-media/media/review/review-status-chip';
import { orderByReviewIdDesc } from '../../types/walmart-sams-club/review';
import { ManageSBProfilesProps, SBProfile, SBProfileBuilderRequest } from '../../types/walmart-sams-club/sb-profile';
import {
  INITIAL_CREATE_FORM_VALUES,
  SB_PROFILE_FORM_VALIDATION_SCHEMA
} from '../../../walmart/components/grid-components/sb-profile-form-config';
import { ManageSBProfiles } from './manage-sb-profiles';
import { useCurrentRetailerPlatform } from '../../hooks/use-current-retailer-platform';

export const SponsoredBrandsProfileDialog = (props: any) => {
  const { toggle, isShowing, dynamicKey, profileId, campaignId, campaignName, refreshParent, ...other } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstProfile, setFirstProfile] = useState<SBProfile>();
  const [secondProfile, setSecondProfile] = useState<SBProfile>();
  const [review, setReview] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentDialogState, setCurrentDialogState] = useState('loading');

  const { enqueueSnackbar } = useSnackbar();
  const retailerPlatform = useCurrentRetailerPlatform();
  const { getSbaProfiles, buildSbaProfile, getSbaReviews: walmartGetSbaReviews } = useAdsApi(retailerPlatform);

  const fetchReview = async () => {
    const response = await walmartGetSbaReviews(profileId, campaignId, null);

    if (response?.success) {
      const reviewArray = response?.body;
      reviewArray.sort(orderByReviewIdDesc);

      let review = reviewArray[0];
      setReview(review);
    }
  };

  const initialize = async () => {
    setIsLoading(true);

    const sbaProfileResponse = await getSbaProfiles(profileId, campaignId);

    if (!sbaProfileResponse.success) {
      setCurrentDialogState('genericError');
      setIsLoading(false);
      return;
    }

    if (sbaProfileResponse?.body?.length === 0) {
      setCurrentDialogState('create');
      setIsLoading(false);
      return;
    }

    if (!!sbaProfileResponse?.body[0]) {
      setFirstProfile(sbaProfileResponse?.body[0]);
    }

    if (!!sbaProfileResponse?.body[1]) {
      setSecondProfile(sbaProfileResponse?.body[1]);
    }

    await fetchReview();

    setCurrentDialogState('manage');
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isShowing) {
      return;
    }

    initialize();
  }, [isShowing]); // eslint-disable-line react-hooks/exhaustive-deps

  const walmartValidation = (response: any) => {
    var isSuccessful = false;
    var walmartErrorMessage = '';

    if (response?.body?.sbaProfile?.code !== 'Success') {
      walmartErrorMessage += response?.body?.sbaProfile?.details || '';
    }

    if (response?.body?.sbaProfileImage?.code !== 'Success') {
      if (walmartErrorMessage !== '') {
        walmartErrorMessage += '\n';
      }
      walmartErrorMessage += response?.body?.sbaProfileImage?.details || '';
    }

    if (walmartErrorMessage !== '') {
      setErrorMessage(walmartErrorMessage);
      setCurrentDialogState('walmartError');
      return;
    }

    isSuccessful = true;

    return isSuccessful;
  };

  const formik = useFormik({
    initialValues: INITIAL_CREATE_FORM_VALUES,
    validationSchema: SB_PROFILE_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values, helpers): Promise<void> => {
      setIsSubmitting(true);

      try {
        const requestObject: SBProfileBuilderRequest = {
          searchAmpName: values.searchAmpName,
          headlineText: values.headlineText,
          clickUrl: values.clickUrl,
          logoDataUri: values.logoDataUri
        };

        const response = await buildSbaProfile(profileId, campaignId, requestObject);

        if (response.success) {
          const isSuccessful = walmartValidation(response);

          if (!isSuccessful) {
            setIsSubmitting(false);
            return;
          }

          if (refreshParent) {
            refreshParent();
          }

          toggle();
          enqueueSnackbar('SB Profile successfully created!', { variant: 'success' });
        } else {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
        }
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar('Error creating SB Profile', { variant: 'error' });

        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
      setIsSubmitting(false);
    }
  });

  const manageProps: ManageSBProfilesProps = {
    firstProfile,
    secondProfile,
    profileId,
    campaignId,
    adGroupId: firstProfile?.adGroupId || -1,
    setCurrentDialogState,
    setIsSubmitting,
    setErrorMessage,
    refreshParent: initialize,
    review
  };

  const dialogContents = [
    {
      state: 'loading',
      content: (
        <Box
          sx={{
            height: '300px',
            display: 'flex'
          }}
        />
      )
    },
    {
      state: 'noAds',
      content: (
        <>
          {' '}
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={6}>
              <Box
                sx={{
                  mt: 3
                }}
              >
                <Grid container direction="row" spacing={2} justifyContent="center">
                  <Grid item>
                    {' '}
                    <ReportProblemIcon color="warning" />
                  </Grid>
                  <Grid item>
                    {' '}
                    <Typography variant="h6">Profile Unavailable</Typography>
                  </Grid>
                </Grid>
                <Box sx={{ my: 3 }}>
                  {' '}
                  <Typography>It looks like this campaign doesn't have any items yet.</Typography>
                  <Typography sx={{ my: 0.5 }}>Ad items are required in order to set up an SBA Profile.</Typography>
                  <Typography sx={{ mt: 2 }}>Would you like to set some up for this campaign?</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link
                    to={`/${retailerPlatform}/ads/create?profileId=${profileId}&campaignId=${campaignId}`}
                    component={RouterLink}
                  >
                    <Button variant="outlined">Create Ads</Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )
    },
    {
      state: 'genericError',
      content: (
        <>
          {' '}
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              <Grid container direction="row" spacing={2} justifyContent="center">
                <Grid item>
                  {' '}
                  <ReportProblemIcon color="warning" />
                </Grid>
                <Grid item>
                  {' '}
                  <Typography variant="h6">An Error Occured</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ my: 4 }}>
                <Grid item>
                  <Typography>
                    Sorry, something went wrong while retreiving the profile for this campaign. Please try again.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
    },
    {
      state: 'walmartError',
      content: (
        <>
          {' '}
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              <Grid container direction="row" spacing={2} justifyContent="center">
                <Grid item>
                  {' '}
                  <ReportProblemIcon color="warning" />
                </Grid>
                <Grid item>
                  {' '}
                  <Typography variant="h6">An Error Occured While Creating the Profile</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ my: 4 }}>
                <Grid item>
                  <Typography>{errorMessage}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
    },
    {
      state: 'manage',
      content: <ManageSBProfiles {...manageProps} />
    },
    {
      state: 'create',
      content: (
        <Box sx={{ py: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <SBProfileBuilder formik={formik} />
          </form>
        </Box>
      )
    }
  ];

  const dialogActions = [
    {
      state: 'loading',
      actions: <Button onClick={toggle}>Done</Button>
    },
    {
      state: 'noAds',
      actions: <Button onClick={toggle}>Close</Button>
    },
    {
      state: 'genericError',
      actions: (
        <>
          <Button onClick={toggle}>Cancel</Button>
          <Button onClick={() => initialize()}>Try Again</Button>
        </>
      )
    },
    {
      state: 'walmartError',
      actions: (
        <>
          <Button onClick={toggle}>Cancel</Button>
          <Button onClick={() => initialize()} variant="contained">
            Try Again
          </Button>
        </>
      )
    },
    {
      state: 'manage',
      actions: <Button onClick={toggle}>Done</Button>
    },
    {
      state: 'create',
      actions: (
        <>
          <Button onClick={toggle}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
            onClick={() => formik.handleSubmit()}
          >
            Create Profile
          </Button>
        </>
      )
    }
  ];

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="md" sx={{ transition: 'height 1s' }}>
      <DialogTitle>SBA Profile - {campaignName}</DialogTitle>
      <DialogContent key={dynamicKey}>
        {isLoading && (
          <Box sx={{ zIndex: '9999', position: 'relative', width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box display="flex" justifyContent={'space-between'} alignContent="center">
          <Box display={'flex'}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              Review Status:
            </Typography>
            <ReviewStatusChip status={review?.reviewStatus || ''} />
          </Box>
          <Box>
            <Link
              to={`/${retailerPlatform}/review?profileId=${profileId}&campaignId=${campaignId}`}
              target="_blank"
              component={RouterLink}
            >
              <Button variant="outlined" size="small">
                Manage Review
              </Button>
            </Link>
          </Box>
        </Box>
        <Fade in={!isLoading}>
          <div>{dialogContents.find((item) => item.state === currentDialogState)?.content}</div>
        </Fade>
      </DialogContent>
      <DialogActions>{dialogActions.find((item) => item.state === currentDialogState)?.actions}</DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
