import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import useAmazonApi from '../../hooks/use-amazon-api';

interface DeleteBudgetGroupDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  refreshBudgetsView: () => void;
}

export const DeleteBudgetGroupDialog = (props: DeleteBudgetGroupDialogProps) => {
  const { toggle, isShowing, dynamicKey, refreshBudgetsView } = props;
  const { deleteBudgetGroups } = useAmazonApi();

  const apiRef = useGridApiContext();

  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);

  const handleSaveButtonClick = async () => {
    setBackDropOpen(true);

    const selectedBudgetGroupIds = Array.from(apiRef.current.getSelectedRows().values()).map((row) => row.campaignBudgetGroupId);

    const response = await deleteBudgetGroups(selectedBudgetGroupIds);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    if (response.success) {
      enqueueSnackbar('Budget group(s) deleted successfully', { variant: 'success' });
    }

    apiRef.current.setRowSelectionModel([]);

    setBackDropOpen(false);
    await refreshBudgetsView();
    toggle();
  };

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="sm" sx={{ transition: 'height 1s' }}>
      <DialogTitle>Create Budget Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to delete the selected budget groups?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSaveButtonClick} variant="contained" color="primary">
          Yes I'm sure
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
