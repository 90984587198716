import React, { useState } from 'react';
import { ProductMetadataRequest } from '../../../../types/product-metadata';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { CampaignBuilderItem } from '../../../../types/campaign-builder-item';
import { State } from '../../../../types/campaign-builder-request';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import {
  allRefinementsMatch,
  buildAddedTargetListItemFromCatalogItem,
  buildAddedTargetListItemFromRecommendation
} from './targeting-utils';
import { on } from 'events';
import { Visibility } from '@mui/icons-material';

interface AddAsinsProps {
  handleAddTargets: (asins: any[]) => void;
  targets: any[];
  existingTargets: any[];
  bid: number;
  profileId: string | null;
}

const AddAsins: React.FC<AddAsinsProps> = (props: AddAsinsProps) => {
  const { handleAddTargets, targets, existingTargets, bid, profileId } = props;
  const [inputValue, setInputValue] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { getProductMetadata, searchCatalogItems } = useAmazonApi();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAddAsins = async () => {
    if (!profileId) return;
    setIsLoading(true);

    const asins = inputValue.split(/[, \n]/).filter((asin) => asin.trim() !== '');

    const uniqueAsins = Array.from(new Set(asins));

    const validAsins: string[] = [];
    const invalidAsins: string[] = [];

    // Check ASINS via catalog search individually
    // If we send all asins, one invalid asin will cause the whole request to fail
    const checkAsins = uniqueAsins.map(async (asin) => {
      const response = await searchCatalogItems([asin]);

      if (response.success && response?.body?.length > 0) {
        validAsins.push(response.body[0]);
      } else {
        invalidAsins.push(asin);
      }
    });

    await Promise.all(checkAsins);

    // Convert valid ASINS to correct dto
    const newTargetClauses: any[] = [];

    if (matchType.exact === true) {
      validAsins.forEach((item: any) => {
        newTargetClauses.push(buildAddedTargetListItemFromCatalogItem({ ...item, matchType: 'Exact' }, 'product', bid));
      });
    }

    if (matchType.expanded === true) {
      validAsins.forEach((item: any) => {
        newTargetClauses.push(buildAddedTargetListItemFromCatalogItem({ ...item, matchType: 'Expanded' }, 'product', bid));
      });
    }

    // Filter out duplicates based on targets array
    const newDistinctTargets = newTargetClauses.filter(
      (newTarget) =>
        !targets.some(
          (prevTarget: { recommendedAsin: any; matchType: any }) =>
            prevTarget.recommendedAsin === newTarget.recommendedAsin && prevTarget.matchType === newTarget.matchType
        )
    );

    // Further filter out duplicates based on existingTargets array
    const existingAsins: any[] = [];
    const finalDistinctTargets = newDistinctTargets.filter(
      (newTarget) =>
        !existingTargets.some((existingTarget) => {
          const targetExistsOnCampaign = allRefinementsMatch(existingTarget.expression, newTarget.targetingClause.expression);

          if (targetExistsOnCampaign) {
            existingAsins.push(newTarget.recommendedAsin);
          }

          return targetExistsOnCampaign;
        })
    );

    if (existingAsins.length > 0) {
      enqueueSnackbar(`${existingAsins.length} target${existingAsins.length === 1 ? '' : 's'} already targeted.`, {
        variant: 'warning'
      });
    }

    handleAddTargets(finalDistinctTargets);

    setMessage(`${validAsins.length} / ${asins.length} ASINs successfully added.`);
    setIsLoading(false);
  };

  const [matchType, setMatchType] = useState({ exact: true, expanded: false });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMatchType({ ...matchType, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={matchType.exact} onChange={handleCheckboxChange} name="exact" />}
          label="Exact"
        />
        <FormControlLabel
          control={<Checkbox checked={matchType.expanded} onChange={handleCheckboxChange} name="expanded" />}
          label="Expanded"
        />
      </FormGroup>

      <Stack>
        <TextField
          id="outlined-multiline-static"
          label="Enter Asins"
          multiline
          rows={10}
          value={inputValue}
          onChange={handleInputChange}
          defaultValue="Enter a list of ASINs separated by comma, space, or line"
          disabled={isLoading}
        />
        <LinearProgress sx={{ visibility: isLoading ? 'visible' : 'hidden' }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 0.5 }}>
          <Button onClick={() => setInputValue('')}>Clear</Button>
          <Button onClick={handleAddAsins} variant={'contained'}>
            Add ASINs
          </Button>
        </Box>
      </Stack>
      <Typography variant="caption">{message}</Typography>
    </div>
  );
};

export default AddAsins;
