import { Typography } from '@mui/material';
import { AmazonExpressionTypes } from '../../../../../types/targeting-refinements';
import React from 'react';

const ContextualTargetCell = ({ expression }: any) => {
  if (!expression) {
    return '';
  }

  const innerText = expression.map((expressionItem: any, index: number) => {
    if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinCategorySameAs.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Category: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinSameAs.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Asin: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.similarProduct.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Similar Product</strong>
        </React.Fragment>
      );
    } else if (expressionItem.type.toLowerCase() === AmazonExpressionTypes.asinBrandSameAs.toLowerCase()) {
      return (
        <React.Fragment key={`${expressionItem.type}-${index}`}>
          <strong>Brand: </strong> {expressionItem.value}{' '}
        </React.Fragment>
      );
    }
  });

  return (
    <>
      <Typography variant="body2" color="text.primary" fontSize={11}>
        {innerText}
      </Typography>
    </>
  );
};

export default ContextualTargetCell;
