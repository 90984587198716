import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ResponseObject } from '../utilities/fetch-utilities';

interface RenameDialogProps {
  isOpen: boolean;
  toggleRenameDialog: () => void;
  entityType: string; // ie: Profile, Campaign, etc.
  entityNameKey: string; // ie: name, campaignName, etc.
  row: object;
  currentEntityName: string;
  updateEntityFunction: (params: any) => Promise<ResponseObject>;
  apiRef?: React.RefObject<any>;
  setEntity?: (entity: any) => void;
}

export default function RenameDialog(props: RenameDialogProps) {
  const {
    isOpen,
    toggleRenameDialog,
    entityType,
    row,
    entityNameKey,
    currentEntityName,
    updateEntityFunction,
    apiRef,
    setEntity
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [editedEntityName, setEditedEntityName] = useState<string>(currentEntityName);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCancelButtonClick = () => {
    setEditedEntityName(currentEntityName);
    toggleRenameDialog();
  };

  const handleEntityNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedEntityName(event.target.value);
    setIsSaveButtonDisabled(event.target.value === currentEntityName);
  };

  const handleSaveButtonClick = async () => {
    const newRow = { ...row, [entityNameKey]: editedEntityName };

    setIsLoading(true);
    setIsSaveButtonDisabled(true);

    const response = await updateEntityFunction(newRow);

    setIsLoading(false);
    setIsSaveButtonDisabled(false);

    if (response.success) {
      enqueueSnackbar(`${entityType} successfully renamed`, { variant: 'success' });

      if (!!apiRef) {
        apiRef.current.updateRows([newRow]);
      }

      if (!!setEntity) {
        setEntity((oldEntity: any) => ({ ...oldEntity, [entityNameKey]: editedEntityName }));
      }
    } else {
      enqueueSnackbar(`An error occurred when renaming the ${entityType}`, { variant: 'error' });
    }

    toggleRenameDialog();
  };

  return (
    <Dialog open={isOpen} onClose={toggleRenameDialog} fullWidth>
      <DialogTitle>Rename {entityType}</DialogTitle>
      <Box display="flex" alignItems={'center'}>
        <DialogContent>
          <TextField
            label={`${entityType} Name`}
            value={editedEntityName}
            onChange={handleEntityNameChange}
            fullWidth
            autoFocus
          />
        </DialogContent>
      </Box>
      <Box>
        <DialogActions>
          <Button onClick={handleCancelButtonClick} variant="outlined" color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleSaveButtonClick} variant="contained" color="primary" disabled={isSaveButtonDisabled}>
            {isLoading ? <CircularProgress size={20} /> : 'Save'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
