export const getOffPaceFontColor = (percent: number) => {
  if (!percent || percent === 0) return 'text.primary';
  if (percent < -30) {
    return 'error.main';
  } else if (percent < -10) {
    return 'warning.main';
  } else {
    return 'success.main';
  }
};

export const getOnPaceFontColor = (percent: number) => {
  if (percent === 0) return 'error.main';
  if (!percent) return 'text.primary';
  if (percent > 130 || percent < 70) {
    return 'error.main';
  } else if (percent > 110 || percent < 90) {
    return 'warning.main';
  } else {
    return 'success.main';
  }
};
