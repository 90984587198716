import { ArrowRight } from '@mui/icons-material';
import { Autocomplete, Backdrop, Box, Button, Card, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import type { CampaignBuilderItem, CampaignBuilderKeyword } from '../../types/campaign-builder-request';
import { ItemSelectionStep } from '../campaigns/create-campaign-form/item-selection-step';
import { AddKeywordsStep } from './add-keywords-step';
import { ADS_BUILDER_FORM_VALIDATION_SCHEMA, INITIAL_FORM_VALUES } from './create-ads-form-config';
import { Platform } from '../../../shared/types/platforms';

interface AdBuilderRequest {
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
}

export const SamsClubCreateAds: FC = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getCampaigns, walmartSamsClubBuildAds } = useAdsApi();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  let campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);

      const response = await getCampaigns();

      if (response.success) {
        setCampaigns(response.body.records);
        setSelectedCampaign(response.body.records.find((c: any) => c.campaignId === campaignId));
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }

      setIsLoading(false);
    };

    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validationSchema: ADS_BUILDER_FORM_VALIDATION_SCHEMA,
    onSubmit: async (values, helpers): Promise<void> => {
      setIsSubmitting(true);

      try {
        const requestObject: AdBuilderRequest = {
          items: values.items.map((item: CampaignBuilderItem) => ({
            itemId: item.itemId,
            itemName: item.itemName
          })),
          keywords: values.keywords
        };

        const response = await walmartSamsClubBuildAds(selectedCampaign?.profileId, selectedCampaign?.campaignId, requestObject);

        if (!response?.success) {
          response?.body?.errors.forEach((error: any) => {
            enqueueSnackbar(error, { variant: 'error' });
          });
        } else if (response?.body?.hasErrors) {
          response?.body?.errors.forEach((error: any) => {
            enqueueSnackbar(error, { variant: 'error' });
          });
        } else {
          enqueueSnackbar('Ads created successfully', { variant: 'success' });
        }

        navigate(
          `/${Platform.SAMS_CLUB.value}/ads?profileId=${selectedCampaign?.profileId}&campaignId=${selectedCampaign?.campaignId}`
        );
        setIsSubmitting(false);
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar('Error creating campaign', { variant: 'error' });

        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);

        setIsSubmitting(false);
      }
    }
  });

  const steps = [
    {
      label: 'Item Selection',
      content: <ItemSelectionStep formik={formik} stepNumber={1} profileId={selectedCampaign?.profileId} />
    },
    {
      label: 'Add Keywords',
      content: (
        <>
          {selectedCampaign?.targetingType?.toLowerCase() === 'manual' ? (
            <AddKeywordsStep
              keywords={formik.values.keywords}
              setKeywords={(keywords) => formik.setFieldValue('keywords', keywords)}
              stepNumber={2}
              selectedCampaignType={selectedCampaign?.campaignType}
            />
          ) : null}
        </>
      )
    }
  ];

  const handleCampaignChange = (event: any, value: any): void => {
    if (!value?.campaignId) {
      searchParams.delete('campaignId');
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...searchParams,
        campaignId: `${value.campaignId}`,
        profileId: `${value.profileId}`
      });
    }
  };

  useEffect(() => {
    if (campaignId) {
      setSelectedCampaign(campaigns.find((c: any) => c.campaignId === campaignId));
    }
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}
      >
        <Container maxWidth="xl">
          <form onSubmit={formik.handleSubmit} {...props}>
            <div>
              <Container maxWidth="md">
                <Typography variant="h6" sx={{ mb: 4, color: '#999' }}>
                  Create Ads
                </Typography>
                {!isLoading ? (
                  <Autocomplete
                    disablePortal
                    options={campaigns}
                    getOptionLabel={(campaign) => campaign?.name || ''}
                    onChange={handleCampaignChange}
                    value={campaigns.find((c: any) => c.campaignId === campaignId)}
                    sx={{ width: 350, mb: 2 }}
                    renderInput={(params) => (
                      <Card sx={{ p: 2 }}>
                        <TextField {...params} label="Select Campaign" />
                      </Card>
                    )}
                  />
                ) : (
                  <CircularProgress />
                )}
                {steps.map((step, index) => (
                  <Box key={step.label}>{step.content}</Box>
                ))}
                <Grid container justifyContent="flex-end">
                  <Grid item sx={{ my: 5 }}>
                    <Button
                      endIcon={<ArrowRight fontSize="small" />}
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                    >
                      Create Ads
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </form>
        </Container>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
