import { Box, TextField, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { formatAsCurrency } from '../../../../shared/utilities/numeric-format-utilities';
import { CurrencyInput } from '../../../../shared/components/inputs/currency-input';
import InlineInput from '../../../../shared/pages/campaigns/campaign-overview/inline-input';
import { formatDate } from '../../../../shared/utilities/date-utilities';
import { CAMPAIGN_STATUS_OPTIONS } from '../../../types/campaign';

interface CampaignDetailsProps {
  campaign: any;
  updateCampaign: (updatedCampaign: any) => Promise<void>;
  setCampaign: (campaign: any) => void;
}

export const CampaignDetails = (props: CampaignDetailsProps) => {
  const { campaign, updateCampaign, setCampaign } = props;

  const handleSaveInlineInput = (paramToUpdate: string) => (newValue: any) => {
    let body: any = { productCampaign: { [paramToUpdate]: newValue }, mask: paramToUpdate };

    if (paramToUpdate === 'maxBid') {
      body['productCampaign'] = {
        strategy: {
          auction: {
            maxBid: `${newValue}`
          }
        }
      };
      body['mask'] = 'strategy.auction.maxBid';
    } else if (['startTime', 'endTime'].includes(paramToUpdate)) {
      body['productCampaign'] = { [paramToUpdate]: new Date(newValue) };
    }

    const updatedCampaign = { campaignId: campaign.id, body };
    updateCampaign(updatedCampaign);
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <InlineInput
          value={campaign.name}
          onSave={handleSaveInlineInput('name')}
          labelComponentProps={{
            variant: 'h4'
          }}
          EditModeComponent={TextField}
          EditModeComponentProps={{
            size: 'small'
          }}
        />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        {campaign?.campaignType && <Box sx={{ ml: 2 }}></Box>}
        <Box sx={{ ml: 2 }}>
          <Select
            value={campaign.campaignState}
            onChange={(event) => handleSaveInlineInput('campaignState')(event.target.value)}
            name="campaignState"
            size="small"
          >
            {CAMPAIGN_STATUS_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ ml: 2 }}>
          <InlineInput
            value={formatDate(campaign.startTime)}
            onSave={handleSaveInlineInput('startTime')}
            label="Start Date"
            labelComponentProps={{
              variant: 'body2'
            }}
            EditModeComponent={DatePicker}
            EditModeComponentProps={{
              componentsProps: { actionBar: { actions: ['clear'] } },
              renderInput: (params: any) => <TextField {...params} name="startTime" size="small" />
            }}
          />
        </Box>
        <Box sx={{ ml: 2 }}>
          <InlineInput
            value={formatDate(campaign.endTime)}
            onSave={handleSaveInlineInput('endTime')}
            label="End Date"
            labelComponentProps={{
              variant: 'body2'
            }}
            EditModeComponent={DatePicker}
            EditModeComponentProps={{
              componentsProps: { actionBar: { actions: ['clear'] } },
              minDate: campaign.startTime,
              renderInput: (params: any) => <TextField {...params} name="endTime" size="small" />
            }}
          />
        </Box>
        <Box sx={{ ml: 2 }}>
          <InlineInput
            value={formatAsCurrency(campaign.maxBid)}
            onSave={handleSaveInlineInput('maxBid')}
            label="Max Bid"
            labelComponentProps={{
              variant: 'body2'
            }}
            EditModeComponent={CurrencyInput}
            EditModeComponentProps={{
              size: 'small'
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
