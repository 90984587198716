import { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { DateRangeSettings } from './campaign-settings-inputs';
import { CampaignBuilderStepLabelButton } from './step-label-button';
import { Platform } from '../../../../shared/types/platforms';
import useAdsApi from '../../../../shared/hooks/use-citrusad-api';
import { CurrencyInput } from '../../../../shared/components/inputs/currency-input';
import { CAMPAIGN_STATUS_OPTIONS, PLACEMENT_TYPES } from '../../../types/campaign';
import { isProductionFrontendDomain } from '../../../../config';

interface CampaignTypeStepProps {
  onNext?: () => void;
  onBack?: () => void;
  formik: any;
  profiles: any[];
}

export const CampaignSettingsStep: FC<CampaignTypeStepProps> = (props) => {
  const { onBack, onNext, formik, profiles, ...other } = props;
  const { getWalletsByTeamId } = useAdsApi(Platform?.CITRUS_AD?.value);

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') || '';

  const [showStep, setShowStep] = useState<boolean>(true);
  const [wallets, setWallets] = useState<any[]>([]);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const envType = isProductionFrontendDomain() ? 'production' : 'test';

  useEffect(() => {
    if (profileId) fetchWallets(profileId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWallets = async (id: string) => {
    formik.setFieldValue('teamId', id);
    const response = await getWalletsByTeamId(id);

    if (response.success) {
      setWallets(response.body.records);
    }
  };

  const handleProfileChange = (profileId: any): void => {
    if (!profileId) {
      searchParams.delete('profileId');
      setSearchParams(searchParams);
    } else {
      const paramasData = {
        ...searchParams,
        profileId: `${profileId}`
      };
      setSearchParams(paramasData);
      fetchWallets(profileId);
    }
  };

  return (
    <>
      {profiles.length > 0 ? (
        <Box sx={{ mb: showStep ? 10 : 1 }} {...other}>
          <CampaignBuilderStepLabelButton
            showStep={showStep}
            handleStepButtonClick={handleStepButtonClick}
            stepNumber={1}
            stepLabel="Campaign Details"
          />
          <Collapse in={showStep}>
            <Card>
              <List>
                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Profile/Team
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl sx={{ width: '300px' }}>
                          <Select
                            value={profileId || ''}
                            onChange={(event) => {
                              formik.setFieldValue('profileId', event.target.value);
                              handleProfileChange(event.target.value);
                            }}
                            labelId={`Profile/Team`}
                            sx={{ height: '40px' }}
                          >
                            {profiles.length > 0 &&
                              profiles.map((profile, i) => (
                                <MenuItem key={i} value={profile.profileId}>
                                  {profile.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>

                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Wallet
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl sx={{ width: '300px' }}>
                          <Select
                            value={formik.values.walletId}
                            onChange={(event) => {
                              formik.setFieldValue('walletId', event.target.value);
                            }}
                            labelId={`wallet`}
                            sx={{ height: '40px' }}
                          >
                            {wallets.length > 0 &&
                              wallets.map((profile, i) => (
                                <MenuItem key={i} value={profile.walletId}>
                                  {profile.walletName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>

                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Name
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl sx={{ width: '300px' }}>
                          <TextField
                            value={formik.values.name}
                            id="campaignName"
                            onChange={(event) => {
                              formik.setFieldValue('name', event.target.value);
                            }}
                            name={`name`}
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>

                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Date Range
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="caption">*To schedule a campaign indefinitely leave end date empty.</Typography>
                          </Grid>
                          <Grid item>
                            <DateRangeSettings formik={formik} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>

                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Placement Type
                      </Grid>
                      <Grid item xs={9}>
                        <RadioGroup
                          value={formik.values.placementId}
                          row
                          aria-labelledby="placement-type"
                          name="placement-type"
                          onChange={(event) => {
                            formik.setFieldValue('placementId', event.target.value);
                          }}
                        >
                          {PLACEMENT_TYPES[envType].map((placement) => (
                            <FormControlLabel value={placement.value} label={placement.label} control={<Radio />} />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </ListItem>
                </Box>
                <Divider />

                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Campaign State
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl sx={{ width: '300px' }}>
                          <Select
                            labelId={`campaignstate`}
                            sx={{ height: '40px' }}
                            value={formik.values.campaignState || ''}
                            onChange={(event) => {
                              formik.setFieldValue('campaignState', event.target.value);
                            }}
                          >
                            {CAMPAIGN_STATUS_OPTIONS.map((status, i) => (
                              <MenuItem key={i} value={status.value}>
                                {status.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>

                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Spend Limit
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl sx={{ width: '300px' }}>
                          <CurrencyInput
                            value={formik.values.spendLimit}
                            size="small"
                            id="spendLimit"
                            name="spendLimit"
                            sx={{ maxWidth: '180px' }}
                            onChange={(event) => {
                              formik.setFieldValue('spendLimit', event.target.value);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ListItem>
                </Box>

                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Spend Limit Type
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl sx={{ width: '300px' }}>
                          <RadioGroup
                            value={formik.values.spendLimitType}
                            row
                            name="spend-limit-type"
                            onChange={(event) => {
                              formik.setFieldValue('spendLimitType', event.target.value);
                            }}
                          >
                            <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                            <FormControlLabel value="total" control={<Radio />} label="Total" />
                          </RadioGroup>
                        </FormControl>
                        <Typography variant="caption">*CitrusAd allows setting only one spend limit type.</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Box>
              </List>
            </Card>
          </Collapse>
        </Box>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

CampaignSettingsStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func
};
