import type { ListItemProps } from '@mui/material';
import { Box, Button, Collapse, ListItem, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import StyledLink from '../../components/styled-link';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';
import { allRoutes } from '../../routing/routes-objects';

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  expanded?: boolean;
  sidebarOpen?: boolean;
  path?: string;
  title: string;
  target?: string;
  disabled?: boolean;
}

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = (props) => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    expanded: expandedProp,
    sidebarOpen,
    path,
    title,
    target,
    disabled,
    ...other
  } = props;
  const [expanded, setExpanded] = useState<boolean>(!!expandedProp);

  let [searchParams, setSearchParams] = useSearchParams();

  const getPath = (): string => {
    if (!path) {
      return '';
    }

    const searchParamEntries = Array.from(searchParams.entries());

    const queryParams = searchParamEntries
      .filter(([key, value]) =>
        [...allRoutes]
          .filter((route) => route.path.includes(path))
          .map((route) => route.allowedQueryParams || [])
          .flat() // use flat() to flatten the array of arrays into a single array
          .includes(key)
      )
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `${path}${queryParams ? `?${queryParams}` : ''}`;
  };

  const handleToggle = (): void => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  let paddingLeft = 17;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          py: 0,
          px: 2
        }}
        {...other}
      >
        <Button
          endIcon={!expanded ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
          disableRipple
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: active ? 'secondary.main' : 'neutral.300',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            },
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.400'
            },
            '& .MuiButton-endIcon': {
              color: 'neutral.400'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={expanded} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <Tooltip title={title} disableHoverListener={sidebarOpen} placement="right">
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          mb: 0.5,
          py: 0,
          px: 1
        }}
      >
        <StyledLink to={getPath()} target={target} disabled={disabled}>
          <Button
            startIcon={icon}
            endIcon={chip}
            disableRipple
            sx={{
              borderRadius: 1,
              minWidth: '0px',
              color: 'neutral.300',
              justifyContent: 'flex-start',
              pl: `${paddingLeft}px`,
              textAlign: 'left',
              textTransform: 'none',
              width: sidebarOpen ? '100%' : 'auto',
              whiteSpace: 'nowrap',
              ...(active && {
                backgroundColor: 'rgba(255,255,255, 0.08)',
                color: 'secondary.main',
                fontWeight: 'fontWeightBold'
              }),
              '& .MuiButton-startIcon': {
                color: active ? 'secondary.main' : 'neutral.400',
                mr: sidebarOpen ? '10px' : '0px'
              },
              '&:hover': {
                backgroundColor: 'rgba(255,255,255, 0.08)'
              }
            }}
          >
            {sidebarOpen ? <Box sx={{ flexGrow: 1 }}>{title}</Box> : <Box sx={{ height: '24.5px' }} />}
            {info}
          </Button>
        </StyledLink>
      </ListItem>
    </Tooltip>
  );
};

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  expanded: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

DashboardSidebarItem.defaultProps = {
  active: false,
  expanded: false
};
