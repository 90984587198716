export interface NegativeKeywordBuilderRequest {
  campaignId: number;
  adGroupId: number;
  keywordText: string;
  nativeLanguageKeyword: string;
  nativeLanguageLocale: string;
  matchType: MatchType;
  state: State;
}

export type MatchType = 'NEGATIVE_EXACT' | 'BROAD' | 'PHRASE';
export type State = 'ENABLED' | 'PAUSED' | 'ARCHIVED';

export function formatOption(option: string): string {
  const words = option.split('_');
  const formattedWords = words.map((word) => word.charAt(0) + word.slice(1).toLowerCase());
  return formattedWords.join(' ');
}
