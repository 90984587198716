import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import PerformanceGrid from '../../../../shared/components/performance-grid/performance-grid';
import useDialog from '../../../../shared/hooks/use-dialog';
import { useGridConfig } from '../../../../shared/hooks/use-grid-config';
import { usePageable } from '../../../../shared/hooks/use-pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_NEGATIVE_TARGET_COLUMNS } from '../../../components/grid-components/column-configurations/campaign-negative-target-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import CreateCampaignNegativeKeywordsDialog from './create-dialogs/create-campaign-negative-keywords-dialog';
import { DEFAULT_ROW_COUNT } from '../../../../shared/types/pageable';
import { v4 as uuidv4 } from 'uuid';
import { CampaignNegativeTarget } from '../../../types/negative-target';
import CreateNegativeTargetingClausesDialog from './create-dialogs/create-negative-targeting-clauses-dialog';

interface OverviewNegativeTargetsProps {
  campaignId: string | null;
  campaignType?: string;
}

export const OverviewCampaignNegativeTargets = (props: OverviewNegativeTargetsProps) => {
  const { campaignId, campaignType } = props;

  const {
    pageable,
    setPageable,
    setBreadcrumbParams,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePageable();
  const { enqueueSnackbar } = useSnackbar();
  const { getCampaignNegativeTargets } = useAmazonApi();

  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  const [campaignNegativeTargets, setCampaignNegativeTargets] = useState<CampaignNegativeTarget[]>([]);

  const { initialSettings, settingsLoading, saveGridConfig } = useGridConfig({
    setting: GridConfigSettings.AMAZON_OVERVIEW_NEGATIVE_TARGETS,
    breadCrumbParams: { campaignId }
  });

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    // Execute PUT here

    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbParams({ campaignId });
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialSettings) {
      return;
    }

    setPageable(initialSettings.pageable);
  }, [initialSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignNegativeTargets = async () => {
    if (!pageable) {
      return;
    }

    setIsLoading(true);

    const response = await getCampaignNegativeTargets(pageable);

    if (response.success) {
      setCampaignNegativeTargets(response?.body?.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchCampaignNegativeTargets();
  }, [pageable]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isShowing: isNegativeKeywordsDialogShowing, toggle: toggleNegativeKeywordsDialog } = useDialog();
  const { isShowing: isNegativeProductTargetsDialogShowing, toggle: toggleNegativeProductTargetsDialog } = useDialog();

  return (
    <Box my={'0.5rem'} sx={{ height: '76vh' }}>
      <Box display="flex" alignItems={'center'}>
        <Typography variant="h6">Negative Targets</Typography>
        <Box
          sx={{
            mr: 'auto'
          }}
        ></Box>
        <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleNegativeKeywordsDialog()}>
          Add Negative Keywords
        </Button>
        <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleNegativeProductTargetsDialog()}>
          Add Negative Product Targets
        </Button>
      </Box>
      {!initialSettings || settingsLoading ? (
        <DataGridPremium
          rows={[]}
          columns={CAMPAIGN_NEGATIVE_TARGET_COLUMNS}
          loading={true}
          processRowUpdate={processRowUpdate}
          initialState={{}}
          key={uuidv4()}
          disableRowGrouping
        />
      ) : (
        <PerformanceGrid
          processRowUpdate={processRowUpdate}
          loading={isLoading}
          rows={campaignNegativeTargets}
          columns={CAMPAIGN_NEGATIVE_TARGET_COLUMNS}
          initialState={initialSettings?.config}
          saveGridConfig={saveGridConfig}
          disableGridDateRangePicker={true}
          getRowId={(row: any) => row.campaignNegativeTargetId}
          handleFilterModelChange={handleFilterModelChange}
          handleSortModelChange={handleSortModelChange}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={rowCount}
        />
      )}
      {isNegativeKeywordsDialogShowing && (
        <CreateCampaignNegativeKeywordsDialog
          open={isNegativeKeywordsDialogShowing}
          toggle={toggleNegativeKeywordsDialog}
          campaignId={campaignId}
          campaignType={campaignType}
          refreshNegativeTargets={fetchCampaignNegativeTargets}
        />
      )}
      {isNegativeProductTargetsDialogShowing && (
        <CreateNegativeTargetingClausesDialog
          open={isNegativeProductTargetsDialogShowing}
          isCampaignNegativeTarget={true}
          toggle={toggleNegativeProductTargetsDialog}
          refreshNegativeTargets={fetchCampaignNegativeTargets}
        />
      )}
      {isNegativeProductTargetsDialogShowing && (
        <CreateNegativeTargetingClausesDialog
          open={isNegativeProductTargetsDialogShowing}
          isCampaignNegativeTarget={true}
          toggle={toggleNegativeProductTargetsDialog}
          refreshNegativeTargets={fetchCampaignNegativeTargets}
        />
      )}
    </Box>
  );
};
