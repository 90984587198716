import { Box, Card, Radio, SxProps, Theme, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { alpha } from '@mui/material/styles';

interface CustomTypeOptionRadioButtonProps {
  checked?: boolean;
  handleClick: () => void;
  typeOptionValue: string;
  typeOptionLabel: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const CustomTypeOptionRadioButton = forwardRef(function CustomTypeOptionRadioButton(
  props: CustomTypeOptionRadioButtonProps,
  ref
) {
  const { checked, handleClick, typeOptionValue, typeOptionLabel, disabled, sx, ...other } = props;
  return (
    <Box {...other} ref={ref}>
      <Card
        sx={[
          {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            px: 1,
            pr: 2,
            ...(checked && {
              borderColor: 'primary.main',
              borderWidth: 2,
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
              m: '-1px'
            }),
            ...(disabled && {
              borderColor: (theme) => theme.palette.action.disabled,
              borderWidth: 2,
              backgroundColor: (theme) => alpha(theme.palette.action.disabledBackground, 0.08),
              m: '-1px',
              cursor: 'not-allowed'
            })
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        onClick={disabled ? undefined : handleClick}
        variant="outlined"
      >
        <Radio checked={checked} color="primary" disabled={disabled} />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle1">{typeOptionLabel}</Typography>
        </Box>
      </Card>
    </Box>
  );
});
