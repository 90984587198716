import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import AddKeywordsWithSuggested from './add-keywords-suggested';
import useAdsApi from '../../../../shared/hooks/use-walmart-sams-club-api';
import { useSnackbar } from 'notistack';
import { Pageable } from '../../../../shared/types/pageable';

interface TargetingSettingsStepProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (keywords: any) => void;
  minBid?: number;
  selectedRows?: any;
}

export const AddKeywordsDialog: FC<TargetingSettingsStepProps> = (props) => {
  const { isOpen, handleClose, handleSave, minBid, selectedRows } = props;
  const [keywords, setKeywords] = useState<any>([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [existingKeywords, setExistingKeywords] = useState<any>([]);

  const shouldShowSuggestedKeywords = selectedRows?.length === 1;

  const { enqueueSnackbar } = useSnackbar();
  const { getSuggestedKeywords, getKeywords } = useAdsApi();

  const initialize = async () => {
    setIsLoading(true);
    const response = await getSuggestedKeywords(selectedRows[0]?.adGroupId);

    const pageable: Pageable = {
      limit: 0,
      offset: 0,
      filters: [
        {
          column: 'adGroupId',
          value: selectedRows[0]?.adGroupId.toString(),
          comparator: 'EQUALS'
        },
        {
          column: 'campaignId',
          value: selectedRows[0]?.campaignId.toString(),
          comparator: 'EQUALS'
        }
      ]
    };

    const existingKeywordsResponse = await getKeywords(pageable, null, null);

    if (response?.success) {
      setSuggestedKeywords(response?.body);
    } else {
      enqueueSnackbar('Error fetching suggested keywords', { variant: 'error' });
      setIsLoading(false);
    }

    if (existingKeywordsResponse?.success) {
      setExistingKeywords(existingKeywordsResponse?.body?.records);
    } else {
      enqueueSnackbar('Error fetching existing keywords', { variant: 'error' });
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (shouldShowSuggestedKeywords) {
      initialize();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={isOpen} maxWidth={'xl'} onClose={handleClose}>
      <DialogTitle>Add Keywords</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ maxWidth: '1000px' }}>
          Enter a bid, select match types, and enter keywords. Once finished click confirm and apply to add the keywords to your
          selected items.
        </DialogContentText>
        <AddKeywordsWithSuggested
          keywords={keywords}
          setKeywords={setKeywords}
          minBid={minBid}
          isLoading={isLoading}
          existingKeywords={existingKeywords}
          suggestedKeywords={suggestedKeywords}
          shouldShowSuggestedKeywords={shouldShowSuggestedKeywords}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleSave(keywords)} disabled={keywords.length < 1}>
          Confirm and apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
