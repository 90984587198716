import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { SettingsDrawer } from './settings-drawer';

export default function SettingsButton() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <SettingsDrawer onClose={() => setOpen(false)} open={open} />
    </>
  );
}
