import { Box, Button, List, Paper, Typography } from '@mui/material';
import { AddedTargetListItem } from './added-targets-list-item';

interface AddedTargetsProps {
  targets: any[];
  removeAllTargets: () => void;
  removeTarget: (target: any) => void;
}

export default function AddedTargets(props: AddedTargetsProps) {
  const { targets, removeAllTargets, removeTarget } = props;

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button variant="contained" onClick={removeAllTargets}>
          Remove All Targets
        </Button>
      </Box>
      <Paper variant="outlined" sx={{ overflow: 'scroll', height: '625px' }}>
        <Box sx={{ px: 2 }}>
          <Typography variant="caption">{targets?.length} targets</Typography>
        </Box>
        <List>
          {targets.map((target, index) => (
            <AddedTargetListItem
              key={index}
              target={target}
              targetType={target?.recommendedAsin !== undefined ? 'product' : 'category'}
              removeTarget={() => removeTarget(target)}
            />
          ))}
        </List>
      </Paper>
    </Box>
  );
}
