import { Box, Grid } from '@mui/material';
import { GridToolbarExport, GridToolbarProps } from '@mui/x-data-grid-premium';

export const BudgetGroupGridToolbar = (props: GridToolbarProps) => {
  const { bulkActions } = props;

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexWrap={'nowrap'}
        style={{ borderBottom: '1px solid #ccc', paddingBottom: '4px', paddingTop: '4px' }}
      >
        {bulkActions && <>{bulkActions}</>}
        <Box
          sx={{
            mr: 'auto'
          }}
        ></Box>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </Grid>
    </>
  );
};
