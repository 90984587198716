import { Circle } from '@mui/icons-material';
import { Box, CardContent, CardHeader, LinearProgress, Link, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useAmazonApi from '../../amazon/hooks/use-amazon-api';
import { renderCampaignBudgetGroupName } from '../../amazon/pages/budget/render-budget-group-name';
import { useCurrentRetailerPlatform } from '../hooks/use-current-retailer-platform';
import { formatAsCurrency } from '../utilities/numeric-format-utilities';
import { DashboardCard } from './dashboard-card';
import { getOnPaceFontColor } from './grid-components/pacing-font-color';

interface BudgetMetrics {
  currentMonthBudget: number;
  currentMonthSpend: number;
  currentMonthRemainingBudget: number;
  currentDailySpendGoal: number;
  averageDailySpendLast3Days: number;
  expectedSpendPercentOfBudget?: number;
  currentOnPacePercent?: number;
  lastMetricsDate: string;
}

interface ProfileBudgetMetrics extends BudgetMetrics {
  profileId: string;
}

interface CampaignBudgetGroupMetrics extends BudgetMetrics {
  campaignBudgetGroupId: string;
  name: string;
}

interface DashboardPacingProps {
  profileId: string | null;
}

type onPaceColor = 'success' | 'warning' | 'error';

export const DashboardBudgetPacing: FC<DashboardPacingProps> = (props) => {
  const { profileId } = props;
  const retailerPlatform = useCurrentRetailerPlatform();
  const { getProfileBudgetMetrics, getCampaignBudgetGroupMetrics } = useAmazonApi();
  const [isLoading, setIsLoading] = useState(false);
  const [profileBudgetMetrics, setProfileBudgetMetrics] = useState<ProfileBudgetMetrics>();
  const [budgetGroupMetricsArray, setBudgetGroupMetricsArray] = useState<CampaignBudgetGroupMetrics[]>([]);

  const fetchProfileBudgetMetrics = useCallback(async () => {
    if (!profileId) {
      return;
    }

    setIsLoading(true);
    const response = await getProfileBudgetMetrics(profileId);

    if (response.success) {
      setProfileBudgetMetrics(response.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  }, [getProfileBudgetMetrics, profileId]);

  const fetchCampaignBudgetGroupMetrics = useCallback(async () => {
    if (!profileId) {
      return;
    }

    setIsLoading(true);
    const response = await getCampaignBudgetGroupMetrics(profileId);

    if (response.success) {
      setBudgetGroupMetricsArray(response.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  }, [getCampaignBudgetGroupMetrics, profileId]);

  useEffect(() => {
    if (!profileId) {
      return;
    }

    fetchProfileBudgetMetrics();
    fetchCampaignBudgetGroupMetrics();
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getOnPaceGenericColor = (percent: number | undefined): onPaceColor => {
    if (!percent) {
      return 'error' as onPaceColor;
    }

    return getOnPaceFontColor(percent).replace('.main', '') as onPaceColor;
  };

  const renderLastMetricsDate = (dateString: string) => {
    const parsedDateString = dateString.split('-');
    return `${parsedDateString[1]}/${parsedDateString[2]}/${parsedDateString[0]}`;
  };

  const renderExpectedIndicatorTooltipTitle = (budgetMetrics: BudgetMetrics) => {
    return (
      <>
        {budgetMetrics.expectedSpendPercentOfBudget && (
          <Typography variant="caption" component={'p'}>
            Expected Month Spend:{' '}
            {formatAsCurrency((budgetMetrics.expectedSpendPercentOfBudget / 100) * budgetMetrics.currentMonthBudget)}
          </Typography>
        )}
        <Typography variant="caption" component={'p'}>
          Current Month Spend: {formatAsCurrency(budgetMetrics.currentMonthSpend)}
        </Typography>
      </>
    );
  };

  return (
    <DashboardCard loading={isLoading}>
      <CardHeader sx={{ p: '24px' }} title="Budget Insights" />
      <CardContent sx={{ pt: 0 }}>
        <Box display="flex" alignItems={'center'}>
          <Typography variant="button" fontSize="12px" sx={{ color: 'text.secondary', mt: 2 }}>
            Account Pacing
          </Typography>
          <Box sx={{ mr: 'auto' }}></Box>
          <Typography variant="caption" fontSize="12px" sx={{ color: 'text.secondary', mt: 2 }}>
            {profileBudgetMetrics?.lastMetricsDate &&
              `Data through ${renderLastMetricsDate(profileBudgetMetrics.lastMetricsDate)}`}
          </Typography>
        </Box>
        {profileBudgetMetrics && profileBudgetMetrics?.currentMonthBudget ? (
          <Stack
            alignItems={{
              md: 'center'
            }}
            direction={{
              xs: 'column',
              sm: 'row'
            }}
            spacing={2}
            sx={{ pt: 0 }}
          >
            <Box
              sx={{
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: {
                  xs: '100%',
                  md: '50%',
                  lg: '50%'
                }
              }}
            >
              <Stack
                component="ul"
                spacing={2}
                sx={{
                  listStyle: 'none',
                  m: 0,
                  p: 0
                }}
              >
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Stack spacing={1} sx={{ flexGrow: 1 }}>
                    {!!profileBudgetMetrics?.currentOnPacePercent && (
                      <>
                        <Typography variant="body2">
                          <Box display="inline" color={getOnPaceFontColor(profileBudgetMetrics.currentOnPacePercent)}>
                            {profileBudgetMetrics.currentOnPacePercent.toFixed()}%
                          </Box>{' '}
                          On Pace
                        </Typography>
                      </>
                    )}
                    <Stack alignItems="center" direction="row" spacing={3}>
                      <Box position={'relative'} sx={{ flexGrow: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          sx={{ flexGrow: 1 }}
                          value={Math.min(
                            (profileBudgetMetrics.currentMonthSpend / profileBudgetMetrics.currentMonthBudget) * 100,
                            100
                          )}
                          color={
                            !!profileBudgetMetrics.currentOnPacePercent
                              ? getOnPaceGenericColor(profileBudgetMetrics.currentOnPacePercent ?? 0)
                              : ('error' as onPaceColor)
                          }
                        />
                        {profileBudgetMetrics.expectedSpendPercentOfBudget && profileBudgetMetrics.currentOnPacePercent && (
                          <>
                            <Tooltip title={renderExpectedIndicatorTooltipTitle(profileBudgetMetrics)} arrow>
                              <Circle
                                sx={{
                                  position: 'absolute',
                                  top: -6,
                                  left: `${Math.min(profileBudgetMetrics.expectedSpendPercentOfBudget ?? 0, 100)}%`,
                                  height: 15,
                                  width: 15,
                                  backgroundColor: getOnPaceFontColor(profileBudgetMetrics.currentOnPacePercent ?? 0),
                                  color: getOnPaceFontColor(profileBudgetMetrics.currentOnPacePercent ?? 0),
                                  border: '2px solid white',
                                  borderRadius: '50%',
                                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
                                }}
                              />
                            </Tooltip>
                          </>
                        )}
                      </Box>
                      <Typography>{formatAsCurrency(profileBudgetMetrics.currentMonthBudget)}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: {
                  xs: '50%',
                  md: '40%',
                  lg: '40%'
                }
              }}
            >
              <DataGridPremium
                rowSelection={false}
                disableColumnMenu
                disableColumnResize
                disableRowSelectionOnClick
                disableAutosize
                hideFooter
                sx={{
                  mt: 2,
                  width: '100%',
                  '& .MuiDataGrid-cell': {
                    borderRight: 'none'
                  },
                  '& .MuiDataGrid-columnHeader': {
                    borderRight: 'none'
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    borderBottom: `1px solid black`
                  }
                }}
                rows={profileBudgetMetrics ? [profileBudgetMetrics] : []}
                getRowId={(row) => row.profileId}
                columns={[
                  {
                    field: 'averageDailySpendLast3Days',
                    headerName: 'Avg. Spend L3D',
                    valueFormatter: (params) => formatAsCurrency(params.value as number),
                    width: 150,
                    sortable: false,
                    filterable: false
                  },
                  {
                    field: 'currentDailySpendGoal',
                    headerName: 'Daily Spend Goal',
                    valueFormatter: (params) => formatAsCurrency(params.value as number),
                    width: 150,
                    sortable: false,
                    filterable: false
                  }
                ]}
              />
            </Box>
          </Stack>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 250
            }}
          >
            <Stack
              spacing={2}
              sx={{
                textAlign: 'center'
              }}
            >
              <Typography variant="body1" color="textSecondary">
                No data available.
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Have you set a monthly budget for this profile?
              </Typography>
            </Stack>
          </Box>
        )}
        {budgetGroupMetricsArray?.length > 0 ? (
          <>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="button" fontSize="12px" sx={{ color: 'text.secondary', mt: 2 }}>
                Budget Group Pacing
              </Typography>
              <Box sx={{ mr: 'auto' }}></Box>
              <Typography variant="caption" fontSize="12px" sx={{ color: 'text.secondary', mt: 2 }}>
                {budgetGroupMetricsArray.length > 0 &&
                  `Data through ${renderLastMetricsDate(budgetGroupMetricsArray[0].lastMetricsDate)}`}
              </Typography>
            </Box>
            <Box sx={{ height: 300 }}>
              <DataGridPremium
                rowSelection={false}
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter
                autosizeOnMount
                sx={{
                  mt: 2,
                  '& .MuiDataGrid-cell': {
                    borderRight: 'none'
                  },
                  '& .MuiDataGrid-columnHeader': {
                    borderRight: 'none'
                  },
                  '& .MuiDataGrid-toolbarContainer': {
                    borderBottom: `1px solid black`
                  }
                }}
                rows={budgetGroupMetricsArray}
                getRowId={(row) => row.campaignBudgetGroupId}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Name',
                    minWidth: 150,
                    renderCell: renderCampaignBudgetGroupName
                  },
                  {
                    field: 'pacing',
                    headerName: 'Pacing',
                    headerAlign: 'center',
                    renderCell: (params) => {
                      if (params.row.currentMonthBudget === 0) {
                        return (
                          <Box position={'relative'} sx={{ flexGrow: 1, textAlign: 'center' }}>
                            <Link
                              to={`/${retailerPlatform}/budgets?profileId=${profileId}`}
                              sx={{ textDecoration: 'none', color: 'text.primary' }}
                              component={RouterLink}
                            >
                              <Typography variant="caption" component="p" color="text.secondary">
                                No Budget Set
                              </Typography>
                            </Link>
                          </Box>
                        );
                      }

                      if (!params.row.currentOnPacePercent && params.row.currentOnPacePercent !== 0) {
                        return (
                          <Box position={'relative'} sx={{ flexGrow: 1, textAlign: 'center' }}>
                            <Typography variant="caption" component="p" color="text.secondary">
                              No Pacing Available
                            </Typography>
                          </Box>
                        );
                      }

                      return (
                        <Box position={'relative'} sx={{ flexGrow: 1, textAlign: 'center' }}>
                          <LinearProgress
                            variant="determinate"
                            sx={{ flexGrow: 1, alignSelf: 'center' }}
                            value={Math.min((params.row.currentMonthSpend / params.row.currentMonthBudget) * 100, 100)}
                            color={getOnPaceGenericColor(params.row.currentOnPacePercent ?? 0)}
                          />
                          <Box position="absolute" bottom={-23} width="100%" textAlign="right">
                            <Typography
                              variant="caption"
                              color={`${getOnPaceGenericColor(params.row.currentOnPacePercent ?? 0)}.light`}
                            >
                              {params.row.currentOnPacePercent?.toFixed()}%{' '}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              On Pace
                            </Typography>
                          </Box>
                          <Tooltip title={renderExpectedIndicatorTooltipTitle(params.row)} arrow>
                            <Circle
                              sx={{
                                position: 'absolute',
                                top: -3.5,
                                left: `${Math.min(params.row.expectedSpendPercentOfBudget ?? 0, 100)}%`,
                                height: 10,
                                width: 10,
                                backgroundColor: getOnPaceFontColor(params.row.currentOnPacePercent ?? 0),
                                color: getOnPaceFontColor(params.row.currentOnPacePercent ?? 0),
                                border: '2px solid white',
                                borderRadius: '50%',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
                              }}
                            />
                          </Tooltip>
                        </Box>
                      );
                    },
                    flex: 1
                  },
                  {
                    field: 'averageDailySpendLast3Days',
                    headerName: 'Avg. Spend L3D',
                    valueFormatter: (params) => formatAsCurrency(params.value as number),
                    minWidth: 135
                  },
                  {
                    field: 'currentDailySpendGoal',
                    headerName: 'Daily Spend Goal',
                    valueFormatter: (params) => formatAsCurrency(params.value as number),
                    minWidth: 135
                  }
                ]}
              />
            </Box>
          </>
        ) : null}
      </CardContent>
    </DashboardCard>
  );
};
